// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

import * as Yup from "yup";

import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";
import axios from "axios";
// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import EditCompanyUserFormikWrapper from "./EditCompanyUserFormikWrapper";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
// import EditCompanyUserErrorsBlock from "./EditCompanyUserErrorsBlock";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
import { fetchCompanyUserById } from "features/company/companyUsersActions";
import { updateCompanyUser } from "features/company/companyUsersActions";
const _objInitialValues = {
  txtName: "",
  txtLastName: "",
  txtEmail: "",
  txtPhoneNumber: "",
  txtRole: "",
};
const EditCompanyUserJWTSchema = Yup.object().shape({
  txtName: Yup.string().required("Name is required."),
  //txtLastName: Yup.string().required("Last name is required."),
  txtEmail: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email is required."),
  txtPhoneNumber: Yup.string()
    .min(6, "Phone number is too short!(min 6 characters are required)")
    .required("Phone Number is required."),
  txtRole: Yup.string().required("Role is required."),
});
const EditCompanyUserForm = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const [objInitialValues, setObjInitialValues] = useState(_objInitialValues);
  const [objCompanyUser, setObjCompanyUser] = useState({});
  const [objStates, setObjStates] = useState(objUsStates);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCompanyUser, setIsLoadingCompanyUser] = useState(false);
  const [objEditCompanyUserErrors, setObjEditCompanyUserErrors] = useState({});

  useEffect(() => {
    // setObjVerifiyEmailAddressErrors({});
    setIsLoadingCompanyUser(true);
    dispatch(fetchCompanyUserById({ objUser, id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingCompanyUser(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        setObjCompanyUser(response.data);
        let txtPhoneNumber = "";
        if (response.data.phone === null) {
          txtPhoneNumber = "";
        } else {
          txtPhoneNumber = response.data.phone;
        }
        const __ObjInitialValues = {
          txtName: response.data.name,
          txtLastName: response.data?.last_name,
          txtEmail: response.data.email,
          txtPhoneNumber,
          txtRole: response.data.role,
        };
        setObjInitialValues(__ObjInitialValues);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingCompanyUser(false);
        // showMsg("error", "Please correct the errors.");
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 404 &&
          objErrorRsp.data.status === "failed"
        ) {
          showMsg("error", "Invalid Url");
          navigate("/users");
        }
      });
  }, []);
  const onSubmitForm = async (values) => {
    const objPostData = {
      id,
      name: values.txtName,
      last_name: values.txtLastName,

      // address1: values.txtLastName,
      // address2: values.txtEmail,
      phone: values.txtPhoneNumber,
      role: values.txtRole,
    };
    setObjEditCompanyUserErrors({});
    setIsLoading(true);
    dispatch(updateCompanyUser({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        // if (response.status === 200) {
        showMsg("success", "You have updated user data successfully.");
        navigate(`/users`);
        // } else {
        //   showMsg("error", "Something went wrong, please try again");
        // }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Please correct the errors.");
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        setObjEditCompanyUserErrors(objErrors);

        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error updating user");
        }
      });
  };

  if (
    isLoadingCompanyUser ||
    Object.keys(objCompanyUser).length === 0 ||
    Object.keys(objInitialValues).length === 0 ||
    objInitialValues.txtName === ""
  ) {
    return <LoadingSpinner subClass="text-center" color="success" size="lg" />;
  }
  return (
    <Formik
      initialValues={objInitialValues}
      validationSchema={EditCompanyUserJWTSchema}
      onSubmit={onSubmitForm}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDTypography variant="h5">
              Edit {objCompanyUser.name} Information
            </MDTypography>
          </MDBox>
          <Form id={"EditCompanyUser"} autoComplete="off" pb={3} px={3}>
            <Grid container spacing={3}>
              <EditCompanyUserFormikWrapper
                values={values}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
                objStates={objStates}
              />

              {/* {Object.keys(objEditCompanyUserErrors).length > 0 ? (
                <Grid container justifyContent="space-between" pl={5} pr={5} pt={0} pb={5}>
                  <EditCompanyUserErrorsBlock
                    objEditCompanyUserErrors={objEditCompanyUserErrors}
                  />
                </Grid>
              ) : null} */}

              <MDBox
                sx={{ margin: "0 0 40px -40px" }}
                width="100%"
                display="flex"
                justifyContent="flex-end"
              >
                {isLoading === false ? (
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 82px" }}
                  >
                    Save
                  </MDButton>
                ) : (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                )}
              </MDBox>
            </Grid>
          </Form>
        </Card>
      )}
    </Formik>
  );
};

export default EditCompanyUserForm;
