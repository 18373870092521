import { useEffect, useState } from "react";

// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";
// Images
import polygonLogo from "assets/images/icons/ct-logo-portrait.svg";
import bgImage from "assets/images/signBg.jpg";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

function SignUp() {
  const [isLoading, setIsLoading] = useState(true);
  const { loginWithRedirect, loginWithPopup } = useAuth0();
  useEffect(() => {
    loginWithRedirect({
      screen_hint: "signup",
    });
  }, []);
  return (
    <IllustrationLayout illustration={bgImage} brand={polygonLogo}>
      {isLoading === false ? null : (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      )}
    </IllustrationLayout>
  );
}

export default SignUp;
