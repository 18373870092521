import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

/**assessment types */
export const get = createAsyncThunk(
  "company/getAssessmentTypes",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser, id } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/assessment-types/?per_page=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const add = createAsyncThunk(
  "company/addAssessmentTypes",
  async (values, thunkApi) => {
    try {
      const { clientId, objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/assessment-types`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const _delete = createAsyncThunk(
  "company/deleteAssessmentType",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/assessment-types/${dataFromActionBtn.assessmentTypeId}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const update = createAsyncThunk(
  "company/saveAssessmentType",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/assessment-types/${objPostData.objPostData.id}`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**asssessment scoring */
export const getAssessmentScoring = createAsyncThunk(
  "company/getAssessmentScoring",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/assessment-scoring`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateAssessmentScoring = createAsyncThunk(
  "company/updateAssessmentScoring",
  async (values, thunkApi) => {
    try {
      const { objUser, fields } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/assessment-scoring/`,
        { fields: fields }
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**asssessment item scoring */
export const getAssessmentItemScoring = createAsyncThunk(
  "company/getAssessmentItemScoring",
  async (values, thunkApi) => {
    try {
      const { objUser, template_id } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/assessment-item-scoring/${template_id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateAssessmentItemScoring = createAsyncThunk(
  "company/updateAssessmentItemScoring",
  async (values, thunkApi) => {
    try {
      const { objUser, fields, template_id } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/assessment-item-scoring/`,
        { fields: fields, template_id }
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
