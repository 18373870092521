import React from "react";
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardActionButton from "views/components/DashboardActionButton";
// import DataTable from "examples/Tables/DataTable";

import { useDispatch, useSelector } from "react-redux";

import { getApiKeys } from "../../../../../features/api-keys/apiKeysActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { showMsg } from "utils/general";
import "../settings.css";
import MDTypography from "components/MDTypography";
import { Button, Grid, Icon } from "@mui/material";

import MDButton from "components/MDButton";

import AddApiKeysForm from "./components/AddApiKeysForm";
import dataTableData from "views/pages/Dashboard/Settings/ApiKeysConfig/components/ApiKeysTable/DataTableData";
import Datatable from "./components/ApiKeysTable/DataTable";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import DashboardButton from "views/components/DashboardButtton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ApiKeysConfig = (props) => {
  const { objUser } = props;
  const [isLoadingKeys, setIsLoadingKeys] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [apiDocsUrl, setApiDocsUrl] = useState("");
  const [returnedToken, setReturnedToken] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  useEffect(() => {
    fetchApiKeys(perPage, page);
    if (window.location.origin.includes("staging.compliancerisk.io")) {
      setApiDocsUrl(
        "https://app.swaggerhub.com/apis-docs/compliancrisk-io/polygon-by_compliance_risk/4.1.5-staging-oas3"
      );
    } else {
      setApiDocsUrl(
        "https://app.swaggerhub.com/apis-docs/complianceriskio/polygon-by_compliance_risk/4.1.5-oas3"
      );
    }
  }, []);

  const onSubmitNewToken = (response) => {
    populateAPIkeysTable(response);
    setReturnedToken(response.data.token);
    setOpen(true);
  };

  const fetchApiKeys = (_per_page, _page) => {
    setIsLoadingKeys(true);
    dispatch(getApiKeys({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingKeys(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objTokensList = response.data.tokens;
        for (let index = 0; index < objTokensList.length; index++) {
          const element = objTokensList[index];
          const objTr = {};
          objTr.name = element.name;
          objTr.id = element.id;
          objTr.value = "***********************************" + element.value;
          objTr.expiry = element.expiry;
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingKeys(false);
        // const objErrorRsp = rejectedValueOrSerializedError.response;
        // console.log(rejectedValueOrSerializedError);
        // if (
        //   objErrorRsp.status === 401 &&
        //   objErrorRsp.statusText === "Unauthorized"
        // ) {
        // } else {
        //   showMsg("error", "Something went wrong, please try again.");
        // }
      });
  };

  const populateAPIkeysTable = (response) => {
    const objRows = [];
    const objTokensList = response.data.tokens;
    for (let index = 0; index < objTokensList.length; index++) {
      const element = objTokensList[index];
      const objTr = {};
      objTr.name = element.name;
      objTr.id = element.id;
      objTr.value = "***********************************" + element.value;
      objTr.expiry = element.expiry;
      objRows.push(objTr);
    }
    setTotalCount(response.data.total);
    setObjTableData({
      ...dataTableData,
      rows: objRows,
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(returnedToken);
    showMsg("info", "Token copied to clipboard");
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      {/* Terms and conditions dialog */}
      <MDBox mb={3} pr={2} sx={{ textAlign: "right" }}>
        <DashboardButton
          component={Link}
          to={apiDocsUrl}
          state={{ routeDictionary }}
          btnText="View API Docs"
          btnIcon="link"
          textColor="white"
          bgColor="success"
          target="_blank"
        />
      </MDBox>
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }}>Your new API token</DialogTitle>
        <DialogContent dividers>
          <MDTypography
            sx={{
              fontSize: "1rem",
              backgroundColor: "#eee",
              padding: "0.5rem",
              borderRadius: "8px",
            }}
          >
            {returnedToken ? returnedToken : ""}
          </MDTypography>
          <MDTypography sx={{ marginTop: "1rem", fontSize: "1rem" }}>
            Please copy your token now, as you will not be able to see it again
            once you navigate away from this dialog.
          </MDTypography>
          <MDButton
            variant="gradient"
            color="dark"
            sx={{ display: "block", marginTop: "1em" }}
            onClick={handleCopy}
          >
            <ContentCopyIcon />
            <MDTypography
              fontWeight="bold"
              sx={{ marginLeft: "0.75rem" }}
              color={"white"}
              variant="button"
            >
              Copy to clipboard
            </MDTypography>
          </MDButton>
        </DialogContent>
      </Dialog>
      <MDBox>
        <Grid spacing={2} container width="100%">
          <Grid item xs={12} lg={6}>
            <AddApiKeysForm onSubmitNewToken={onSubmitNewToken} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              {isLoadingKeys === false &&
              objTableData.rows.length > 0 &&
              totalCount > 0 ? (
                <Datatable
                  table={objTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={totalCount}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  _page={page}
                  setPage={setPage}
                  setObjTableData={setObjTableData}
                  objUser={objUser}
                />
              ) : (
                <Fragment>
                  {isLoadingKeys ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p className="empty-text-p">No API Keys added.</p>
                  )}
                </Fragment>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default ApiKeysConfig;
