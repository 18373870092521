import * as React from "react";
import Dialog from "@mui/material/Dialog";
import InviteAdminUsersDialogBody from "./InviteAdminUsersDialogBody";

const InviteAdminUsersDialog = (props) => {
  const { openInviteAdminUsersDialog, setOpenInviteAdminUsersDialog } = props;

  const handleClose = () => {
    setOpenInviteAdminUsersDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openInviteAdminUsersDialog}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
      >
        <InviteAdminUsersDialogBody setOpenInviteAdminUsersDialog={setOpenInviteAdminUsersDialog} />
      </Dialog>
    </div>
  );
};

export default InviteAdminUsersDialog;
