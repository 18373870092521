import { combineReducers } from "redux";
// import authReducers from "./auth/";
import userSlice from "./userSlice";
import userSignUpFunnelSlice from "./userSignUpFunnelSlice";
// stockPrices/
const userReducers = combineReducers({
  user: userSlice,
  userSignUpFunnelSlice,
});

export default userReducers;
