import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { showMsg } from "utils/general";
import { useDispatch } from "react-redux";
import { getPolicyDocumentWithContactIdAndGroupedCampaignId } from "features/company/campaignActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";

const PolicyAdoptionRequest = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [campaignAdoptionStatuses, setCampaignAdoptionStatuses] = useState([]);

  let { company_id, client_id, contact_id, grouped_campaign_id } = useParams();

  useEffect(() => {
    getCampaigns();
  }, []);

  async function getCampaigns() {
    dispatch(
      getPolicyDocumentWithContactIdAndGroupedCampaignId({
        companyId: company_id,
        clientId: client_id,
        contactId: contact_id,
        groupedCampaignId: grouped_campaign_id,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        setCampaignAdoptionStatuses(response.data.campaignAdoptionStatuses);
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong.");
      });
  }

  const openPolicy = (campaignId, adoptionSecrete) => {
    navigate(
      `/policy-adoption/${company_id}/${client_id}/${contact_id}/${campaignId}/${adoptionSecrete}`
    );
  };

  return (
    <MDBox sx={{ margin: "0 auto", padding: "1em" }}>
      <MDBox display="flex" gap=".6rem" alignItems="flex-start" mb={2}>
        <Card sx={{ margin: "0 auto", width: "70%" }}>
          <MDBox sx={{ padding: "1.2em" }}>
            <MDTypography textAlign="left" variant="h4" fontWeight="bold">
              Please review the policies
            </MDTypography>
          </MDBox>

          {/* loading list of policies */}
          <MDBox sx={{ padding: "1.2em" }}>
            {loading ? (
              <MDBox>
                <LoadingSpinner color="success" size="lg" />
              </MDBox>
            ) : (
              <>
                {campaignAdoptionStatuses.length > 0 ? (
                  <>
                    {campaignAdoptionStatuses.map((campaignAdoption) => {
                      return (
                        <Card style={{ marginBottom: "12px", padding: "1rem" }}>
                          <MDTypography variant="p" color="success">
                            {campaignAdoption?.campaign.campaign_name}
                          </MDTypography>
                          <MDBox
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h3>{campaignAdoption?.campaign.policy?.title}</h3>

                            <MDButton
                              onClick={() =>
                                openPolicy(
                                  campaignAdoption.campaign_id,
                                  campaignAdoption.adoption_secret
                                )
                              }
                              type="button"
                              variant="gradient"
                              color={"success"}
                              sx={{ marginTop: "1rem", padding: "5px 15px" }}
                            >
                              <MDTypography
                                fontWeight="bold"
                                color="white"
                                variant="button"
                              >
                                Review Policy
                              </MDTypography>
                            </MDButton>
                          </MDBox>
                        </Card>
                      );
                    })}
                  </>
                ) : (
                  <Card style={{ marginBottom: "12px", padding: "1rem" }}>
                    <MDBox
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4>
                        There are no more pending policies to be adopted for
                        this campaign
                      </h4>
                    </MDBox>
                  </Card>
                )}
              </>
            )}
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  );
};

export default PolicyAdoptionRequest;
