import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import MDTypography from "components/MDTypography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import PostDefaultLogo from "assets/images/icons/logo.png";

function PostGroupResource({ post }) {
  return (
    <Card sx={{ minHeight: "350px" }}>
      <CardActionArea>
        <Link to={`/peer-group/${post.id}`}>
          <CardMedia
            component="img"
            height="200"
            image={post.cover_image?.file_path || PostDefaultLogo}
            alt=""
            sx={{maxWidth:"90%", objectFit: "contain"}}
          />
          <CardContent>
            <MDTypography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
              gutterBottom
              variant="h5"
              component="div"
            >
              {post.title}
            </MDTypography>
            <MDTypography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
                minHeight: "60px",
                fontSize: "14px",
                padding: "0"
              }}
              variant="body2"
              color="secondary"
            >
              {post.description}
            </MDTypography>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
}

export default PostGroupResource;
