import { useState } from "react";

// Material Dashboard 2 PRO React examples
import PageLayout from "views/containers/PageLayout";

// Pricing page components
import Header from "views/components/Header";
import Footer from "views/components/Footer";
import MDBox from "components/MDBox";

function PricingPage(props) {
  const [tabValue, setTabValue] = useState(0);

  return (
    <PageLayout>
      <MDBox sx={{ height: "100vh" }}>
        <Header tabValue={tabValue} objUser={props.objUser}></Header>

        <Footer />
      </MDBox>
    </PageLayout>
  );
}

export default PricingPage;
