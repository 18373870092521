import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useNavigate, useLocation } from "react-router-dom";
const CustomerNameBtnTr = (props) => {
  const { value, column, row } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const routeDictionary = location.state?.routeDictionary ?? {};
  const btnOnClickCompanyName = (row) => {
    navigate(`/msp/clients/${row.id}`, {
      state: { routeDictionary },
    });
  };

  return (
    <MDButton
      variant="text"
      onClick={() => btnOnClickCompanyName(row.original)}
    >
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default CustomerNameBtnTr;
