import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DefaultNavbar from "views/components/Navbars/DefaultNavbar";

import Grid from "@mui/material/Grid";

import * as Yup from "yup";

import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showMsg } from "utils/general";
import { sendPasswordResetEmail } from "features/company/companyUsersActions.js";

import FormField from "layouts/pages/users/new-user/components/FormField";
import { Form, Formik } from "formik";

import "./ForgotPassword.css";
import { Container } from "@mui/system";
import Footer from "views/components/Footer";
import PageLayout from "views/containers/PageLayout";

import pageRoutes from "page.routes";

const EmailValidationFormJWTSchema = Yup.object().shape({
  email: Yup.string().required("Email field is Required"),
});

const ForgotPasswordPage = (props) => {
  const { objUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [objInitialValues, setObjInitialValues] = useState({
    email: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    setIsLoading(true);
    dispatch(sendPasswordResetEmail(values))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        showMsg(
          "success",
          "Email sent successful. Please check your inbox for further instructions on how to reset your password."
        );
        setEmailSent(true);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
      });
  };

  return (
    <>
      <PageLayout>
        <DefaultNavbar
          brand="ComplianceRisk.io"
          objUser={objUser}
          routes={pageRoutes}
          action={{
            type: "internal",
            route: "/sign-in",
            label: "Sign In",
            color: "dark",
          }}
          warning
          dark
        />
        <MDBox
          minHeight="60vh"
          borderRadius="xl"
          m={2}
          pt={2}
          pb={4}
          sx={{ backgroundColor: "#e7edee", height: "auto" }}
        >
          {/* Form  */}
          <Container sx={{ marginTop: "6rem" }}>
            <Grid
              my={3}
              container
              width="100%"
              justifyContent="center"
              display="flex"
            >
              <Grid item xs={12} lg={7}>
                <Card sx={{ height: "100%" }}>
                  {emailSent ? (
                    <MDBox p={4}>
                      <MDTypography
                        textAlign="left"
                        variant="h4"
                        fontWeight="bold"
                      >
                        Reset Instructions Sent!
                      </MDTypography>
                      <MDTypography
                        textAlign="left"
                        mb={3}
                        variant="p"
                        color="success"
                        sx={{ fontSize: "1rem", lineHeight: "0.2" }}
                      >
                        Please check your inbox
                      </MDTypography>
                    </MDBox>
                  ) : (
                    <Formik
                      initialValues={objInitialValues}
                      validationSchema={EmailValidationFormJWTSchema}
                      onSubmit={onSubmitForm}
                    >
                      {({ values }) => (
                        <Form id={"PasswordValidationForm"} autoComplete="off">
                          <MDBox p={3}>
                            <MDTypography
                              textAlign="left"
                              variant="h4"
                              fontWeight="bold"
                            >
                              Forgot Your Password?
                            </MDTypography>
                            <MDTypography
                              textAlign="left"
                              mb={3}
                              variant="p"
                              color="success"
                              sx={{ fontSize: "1rem", lineHeight: "0.2" }}
                            >
                              Enter your email address and we will send you
                              instructions to reset your password
                            </MDTypography>
                            <FormField
                              type="test"
                              label="Email"
                              name="email"
                              value={values.email}
                              placeholder={"Email"}
                            />
                            {isLoading == false ? (
                              <MDButton
                                type="submit"
                                color="success"
                                sx={{
                                  padding: "12px 20px",
                                  margin: "10px 5px",
                                }}
                              >
                                Submit
                              </MDButton>
                            ) : (
                              <LoadingSpinner color="success" size="lg" />
                            )}
                          </MDBox>
                        </Form>
                      )}
                    </Formik>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Container>

          {/* Form ends      */}
        </MDBox>
        <Footer />
      </PageLayout>
    </>
  );
};

export default ForgotPasswordPage;
