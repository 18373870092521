import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// @mui icons
const DomainNameBtnTr = (props) => {
  const { value } = props;

  return (
    <MDButton variant="text">
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default DomainNameBtnTr;
