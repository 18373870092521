import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { Link, useParams } from "react-router-dom";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookmarkIcon from "@mui/icons-material/Bookmark";
// import "./components/OpenedDocument.css";

const DocumentItem = (props) => {
  // const dispatch = useDispatch();
  const { policy, kbs, newPage } = props;
  // const [open, setOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  let { client_id } = useParams();

  const tags = policy.policy_tags?.split(",");
  return (
    <>
      <Grid item xs={3} lg={3} mt={5}>
        <Card
          sx={{
            padding: "0.7rem",
          }}
        >
          <MDBox sx={{ width: "100%" }}>
            <MDTypography
              variant="h5"
              style={{ textAlign: "center" }}
              p={1}
              color="dark"
            >
              {policy.title}
            </MDTypography>
            <MDTypography
              variant="p"
              style={{ display: "block", fontSize: "1rem" }}
              p={1}
              color="dark"
            >
              {policy.description}
            </MDTypography>
            <Link
              to={`/kbs/open-document/${kbs?.replace("/kbs/", "")}/${
                policy.policy_id
              }/${policy.policy_document_id}`}
              target={newPage ? "_blank" : ""}
            >
              <MDButton
                sx={{ padding: "0.7rem 1.75rem", width: "100%" }}
                // variant="gradient"
                color={"success"}
              >
                <MDTypography
                  fontWeight="bold"
                  color={"white"}
                  variant="button"
                >
                  Open Document
                </MDTypography>
              </MDButton>
            </Link>

            <MDTypography
              variant="p"
              style={{
                fontSize: "1rem",
                display: "inline-block",
                backgroundColor: "#ADD8E6",
                width: "auto",
                borderRadius: "6px",
                padding: "0.2rem",
                color: "black",
                marginTop: "1rem",
              }}
              p={1}
            >
              {policy.category_display_name}
            </MDTypography>
            <MDTypography
              variant="p"
              style={{ display: "block", padding: "0" }}
              p={1}
              color="dark"
            >
              <ul className="tags" style={{ padding: 0, marginTop: "1rem" }}>
                {tags
                  ? tags.map((tag, i) => {
                      return (
                        <li key={i} className="tag tag-display">
                          {tag}
                        </li>
                      );
                    })
                  : ""}
              </ul>
            </MDTypography>
            {policy?.rmfs && policy?.rmfs?.length == 0 ? (
              ""
            ) : (
              <MDTypography
                variant="p"
                style={{
                  backgroundColor: "#eee",
                  display: "block",
                  borderRadius: "6px",
                  fontSize: "1rem",
                }}
                p={1}
                mt={1}
                color="dark"
              >
                {policy?.rmfs?.join(", ")}
              </MDTypography>
            )}

            {/* Related docs (policies) */}
            {policy?.related_docs?.length > 0 ? (
              <Accordion sx={{ marginTop: "1.5rem" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="icon" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <MDTypography variant="p" color="dark">
                    <b> Related Documents</b>
                  </MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  {policy.related_docs.map((doc) => {
                    return (
                      <Link
                        to={`/kbs/open-document/${kbs?.replace("/kbs/", "")}/${
                          doc.linked_policy_id
                        }/${doc.policy_document_id}`}
                      >
                        <MDTypography
                          variant="p"
                          style={{ color: "#1f68e4", display: "block" }}
                        >
                          <BookmarkIcon sx={{ paddingTop: "0.2rem" }} />{" "}
                          {doc.linked_policy.title}
                        </MDTypography>
                      </Link>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ) : (
              ""
            )}
          </MDBox>
        </Card>
      </Grid>
    </>
  );
};

export default DocumentItem;
