import DefaultCell from "views/components/TableCells/DefaultCell";

import KnowledgeBaseSharesNameBtnTr from "./KnowledgeBaseSharesNameBtnTr";
const KnowledgeBaseSharesDataTableData = {
  columns: [
    {
      Header: "Link",
      accessor: "link",
      Cell: ({ value, column, row }) => (
        <KnowledgeBaseSharesNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Expiry Date",
      accessor: "expiryDate",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default KnowledgeBaseSharesDataTableData;
