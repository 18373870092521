import { dataTableData } from "views/pages/Dashboard/Audience/AudienceManagement/data/dataTableData";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  listAudiences,
  deleteAudience,
  listAssessmentRoleUsers,
  removeUserAssessmentRole,
  listRiskRegisterRoleUsers,
  removeUserRiskRegisterRole,
} from "features/company/clientAudienceActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { showMsg } from "utils/general";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardButton from "views/components/DashboardButtton";
import AudienceManagementTable from "./components/AudienceManagementTable/AudienceManagementTable";
import { types_to_desc as types } from "./data/AudienceTypes";
import AssessmentUsersManagementTable from "./components/AssessmentUsersManagementTable/AssessmentUsersManagementTable";
import MDTypography from "components/MDTypography";
import DashboardActionButton from "views/components/DashboardActionButton";
import AddToAssessmentUsersDialog from "./components/AddToAssessmentUsersDialog";
import RiskRegisterUsersManagementTable from "./components/RiskRegisterUsersManagementTable/RiskRegisterUsersManagementTable";
import AddToRiskRegisterUsersDialog from "./components/AddToRiskRegisterUsersDialog";
import { displayExceptionErrorMessage } from "utils/general";

const AudienceManagement = (props) => {
  const { objUser } = props;

  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [isLoadingMoreAudiences, setIsLoadingMoreAudiences] = useState(false);
  const [
    isLoadingMoreAssessmentAudiences,
    setIsLoadingMoreAssessmentAudiences,
  ] = useState(false);
  const [
    isLoadingMoreRiskRegisterAudiences,
    setIsLoadingMoreRiskRegisterAudiences,
  ] = useState(false);
  const [isDeletingAudience, setIsDeletingAudience] = useState(false);
  const [isDeletingAssessmentAccess, setIsDeletingAssessmentAccess] =
    useState(false);
  const [isDeletingRiskRegisterAccess, setIsDeletingRiskRegisterAccess] =
    useState(false);
  const [unAllowedAssessmentUsers, setUnAllowedAssessmentUsers] = useState([]);
  const [unAllowedRiskRegisterUsers, setUnAllowedRiskRegisterUsers] = useState(
    []
  );
  const [perPage, setPerPage] = useState(10);
  const [perAssessmentPage, setPerAssessmentPage] = useState(10);
  const [perRiskRegisterPage, setPerRiskRegisterPage] = useState(10);
  const [page, setPage] = useState(1);
  const [assessmentPage, setAssessmentPage] = useState(1);
  const [riskRegisterPage, setRiskRegisterPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalAssessmentCount, setTotalAssessmentCount] = useState(0);
  const [totalRiskRegisterCount, setTotalRiskRegisterCount] = useState(0);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [objAssessmentTableData, setObjAssessmentTableData] =
    useState(dataTableData);
  const [objRiskRegisterTableData, setObjRiskRegisterTableData] =
    useState(dataTableData);
  const [openAddToAssessmentDialog, setOpenAddToAssessmentsDialog] =
    useState(false);
  const [openAddToRiskRegisterDialog, setOpenAddToRiskRegistersDialog] =
    useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    loadMoreAudiences(perPage, page);
    if (
      (objUser?.user?.role?.name ?? "") === "company_user" ||
      (objUser?.user?.role?.name ?? "") === "admin"
    ) {
      loadMoreAssessmentUsers();
      loadMoreRiskRegisterUsers();
    }
  }, []);

  const onCloseAddToAssessmentUsersDialog = () => {
    setOpenAddToAssessmentsDialog(false);
  };

  const onAddedToAssessmentUsers = () => {
    setOpenAddToAssessmentsDialog(false);
    loadMoreAssessmentUsers();
  };

  const onCloseAddToRiskRegisterUsersDialog = () => {
    setOpenAddToRiskRegistersDialog(false);
  };

  const onAddedToRiskRegisterUsers = () => {
    setOpenAddToRiskRegistersDialog(false);
    loadMoreRiskRegisterUsers();
  };

  const loadMoreAudiences = (_per_page, _page) => {
    setIsLoadingMoreAudiences(true);
    dispatch(listAudiences({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreAudiences(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objAudiencesList = response.data.audiences;
        for (let index = 0; index < objAudiencesList.length; index++) {
          const element = objAudiencesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.audienceName = element.name;
          objTr.type = types[element.type];
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMoreAudiences(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };
  const deleteTheAudience = async (objClientData) => {
    dispatch(deleteAudience({ audienceId: objClientData.id, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 200) {
          loadMoreAudiences();
          showMsg("success", "Audience deleted successfully.");
          navigate(`/audiences`, { state: { routeDictionary } });
        } else {
          showMsg("error", "Unable to delete audience. Please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const loadMoreAssessmentUsers = (_per_page, _page) => {
    setIsLoadingMoreAssessmentAudiences(true);
    dispatch(listAssessmentRoleUsers({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreAssessmentAudiences(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objAssessmentUsersList = response.data.allowedUsers;
        for (let index = 0; index < objAssessmentUsersList.length; index++) {
          const element = objAssessmentUsersList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.email = element.email;
          objTr.name = element.name + " " + element.last_name;
          objRows.push(objTr);
        }
        setUnAllowedAssessmentUsers(response.data.unAllowedUsers);
        setTotalAssessmentCount(response.data.allowedUsers.length);
        setObjAssessmentTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMoreAssessmentAudiences(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const removeUserFromAssessmentRole = async (objClientData) => {
    dispatch(removeUserAssessmentRole({ userId: objClientData.id, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg("success", "User access removed successfully.");
          loadMoreAssessmentUsers();
        } else {
          showMsg("error", "Unable to delete audience. Please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        showMsg("error", "Something went wrong. Please try again.");
      });
  };

  const loadMoreRiskRegisterUsers = (_per_page, _page) => {
    setIsLoadingMoreRiskRegisterAudiences(true);
    dispatch(listRiskRegisterRoleUsers({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreRiskRegisterAudiences(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objAssessmentUsersList = response.data.allowedUsers;
        for (let index = 0; index < objAssessmentUsersList.length; index++) {
          const element = objAssessmentUsersList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.email = element.email;
          objTr.name = element.name + " " + element.last_name;
          objRows.push(objTr);
        }
        setUnAllowedRiskRegisterUsers(response.data.unAllowedUsers);
        setTotalRiskRegisterCount(response.data.allowedUsers.length);
        setObjRiskRegisterTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMoreRiskRegisterAudiences(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  const removeUserFromRiskRegisterRole = async (objClientData) => {
    dispatch(removeUserRiskRegisterRole({ userId: objClientData.id, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg("success", "User access removed successfully.");
          loadMoreRiskRegisterUsers();
        } else {
          showMsg("error", "Unable to delete audience. Please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <AddToAssessmentUsersDialog
        open={openAddToAssessmentDialog}
        onClose={onCloseAddToAssessmentUsersDialog}
        objUser={objUser}
        onSubmit={onAddedToAssessmentUsers}
        unAllowedUsers={unAllowedAssessmentUsers}
      />
      <AddToRiskRegisterUsersDialog
        open={openAddToRiskRegisterDialog}
        onClose={onCloseAddToRiskRegisterUsersDialog}
        objUser={objUser}
        onSubmit={onAddedToRiskRegisterUsers}
        unAllowedUsers={unAllowedRiskRegisterUsers}
      />
      <MDBox>
        <MDBox
          display="flex"
          gap=".6rem"
          alignItems="flex-end"
          justifyContent="right"
          mb={2}
        >
          <DashboardButton
            component={Link}
            to={`/audiences/create`}
            state={{ routeDictionary }}
            btnText="Add Audience"
            btnIcon="add"
            textColor="white"
            bgColor="success"
          />
        </MDBox>
        <Card>
          {isLoadingMoreAudiences === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <AudienceManagementTable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              loadMoreAudiences={loadMoreAudiences}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              objUser={objUser}
              setPage={setPage}
              setObjTableData={setObjTableData}
              deleteTheAudience={deleteTheAudience}
              isDeletingAudience={isDeletingAudience}
              setIsDeletingAudience={setIsDeletingAudience}
            />
          ) : (
            <Fragment>
              {isLoadingMoreAudiences ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>Please add some audiences first.</p>
              )}
            </Fragment>
          )}
        </Card>

        {(objUser.user.role.name === "company_user" ||
          objUser.user.role.name === "admin") && (
          <MDBox mt={5}>
            <MDBox
              display="flex"
              gap=".6rem"
              alignItems="flex-end"
              justifyContent="space-between"
              mb={2}
            >
              <MDTypography variant="h5">Assessment Contacts</MDTypography>
              <DashboardActionButton
                action={() => {
                  setOpenAddToAssessmentsDialog(true);
                }}
                btnText="Add Users"
                btnIcon="add"
                textColor="white"
                bgColor="success"
                marginTop="0px"
              />
            </MDBox>
            <Card>
              {isLoadingMoreAssessmentAudiences === false &&
              objAssessmentTableData.rows.length > 0 &&
              totalAssessmentCount > 0 ? (
                <AssessmentUsersManagementTable
                  table={objAssessmentTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={totalAssessmentCount}
                  loadMoreAudiences={loadMoreAssessmentUsers}
                  perPage={perAssessmentPage}
                  setPerPage={setPerAssessmentPage}
                  _page={assessmentPage}
                  setPage={setAssessmentPage}
                  setObjTableData={setObjAssessmentTableData}
                  removeUserFromAssessmentRole={removeUserFromAssessmentRole}
                  isDeletingAssessmentAccess={isDeletingAssessmentAccess}
                />
              ) : (
                <Fragment>
                  {isLoadingMoreAssessmentAudiences ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p>Please add some users first.</p>
                  )}
                </Fragment>
              )}
            </Card>
          </MDBox>
        )}

        {(objUser.user.role.name === "company_user" ||
          objUser.user.role.name === "admin") && (
          <MDBox mt={5}>
            <MDBox
              display="flex"
              gap=".6rem"
              alignItems="flex-end"
              justifyContent="space-between"
              mb={2}
            >
              <MDTypography variant="h5">Risk Register Contacts</MDTypography>
              <DashboardActionButton
                action={() => {
                  setOpenAddToRiskRegistersDialog(true);
                }}
                btnText="Add Users"
                btnIcon="add"
                textColor="white"
                bgColor="success"
                marginTop="0px"
              />
            </MDBox>
            <Card>
              {isLoadingMoreRiskRegisterAudiences === false &&
              objRiskRegisterTableData.rows.length > 0 &&
              totalRiskRegisterCount > 0 ? (
                <RiskRegisterUsersManagementTable
                  table={objRiskRegisterTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={totalRiskRegisterCount}
                  loadMoreAudiences={loadMoreRiskRegisterUsers}
                  perPage={perRiskRegisterPage}
                  setPerPage={setPerRiskRegisterPage}
                  _page={riskRegisterPage}
                  setPage={setRiskRegisterPage}
                  setObjTableData={setObjRiskRegisterTableData}
                  removeUserFromRiskRegisterRole={
                    removeUserFromRiskRegisterRole
                  }
                  isDeletingRiskRegisterAccess={isDeletingRiskRegisterAccess}
                />
              ) : (
                <Fragment>
                  {isLoadingMoreRiskRegisterAudiences ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p>Please add some users first.</p>
                  )}
                </Fragment>
              )}
            </Card>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default AudienceManagement;
