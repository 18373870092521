import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AddNewAudienceFormErrorLi from "./AddNewAudienceFormErrorLi";

const AddNewCustomerFormErrorsBlock = (props) => {
  const renderErrors = () =>
    props.objAddNewAudienceFormErrors
      ? Object.entries(props.objAddNewAudienceFormErrors).map(([key, value], i) => (
          <AddNewAudienceFormErrorLi key={key} value={value} />
        ))
      : null;
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity="error">{renderErrors(props.objAddNewAudienceFormErrors)}</Alert>
    </Stack>
  );
};

export default AddNewCustomerFormErrorsBlock;
