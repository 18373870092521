/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_ENDPOINT } from "components/utils/constants/misc";

const getCompanyID = (objUser) => {
  return objUser.user.company_id;
};

export const storeAddAudience = createAsyncThunk(
  "company/storeAddAudience",
  async (values, thunkApi) => {
    try {
      const { objUser, ...form } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/audiences`,
        form
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const listAudiences = createAsyncThunk(
  "company/listAudiences",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/audiences?per_page=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteAudience = createAsyncThunk(
  "company/deleteTheAudience",
  async (values, thunkApi) => {
    try {
      const { audienceId, objUser } = values;

      const res = await axios.delete(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/audiences/${audienceId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addContactToAudiences = createAsyncThunk(
  "company/addContactToAudiences",
  async (values, thunkApi) => {
    try {
      const { id, contactId, objUser } = values;

      const res = await axios.put(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/audiences/${id}/contacts/${contactId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const removeContactFromAudience = createAsyncThunk(
  "company/removeContactFromAudience",
  async (values, thunkApi) => {
    try {
      const { id, contactId, objUser } = values;

      const res = await axios.delete(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/audiences/${id}/contacts/${contactId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchContactsByAudience = createAsyncThunk(
  "company/listAudiences",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser, audience_id } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/audiences/${audience_id}/contacts?per_page=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**bulk action */
export const bulkAddContactToAudiences = createAsyncThunk(
  "company/ bulkAddContactToAudiences",
  async (values, thunkApi) => {
    try {
      const { id, contactsSelectedForBulkAction, objUser } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/audiences/${id}/contacts/bulk-add-to-audience`,
        {
          contacts: contactsSelectedForBulkAction,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const bulkRemoveContactsFromAudience = createAsyncThunk(
  "company/bulkRemoveContactsFromAudience",
  async (values, thunkApi) => {
    try {
      const { id, contactsSelectedForBulkAction, objUser } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/audiences/${id}/contacts/bulk-remove-from-audience`,
        {
          contacts: contactsSelectedForBulkAction,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const listAssessmentRoleUsers = createAsyncThunk(
  "company/listAssessmentRoleUsers",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/assessment-role/users`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const bulkAddToAssessmentRole = createAsyncThunk(
  "users/bulkAddToAssessmentRole",
  async (values, thunkApi) => {
    try {
      const { usersToAdd, objUser } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/assessment-role/bulk-add`,
        {
          user_ids: usersToAdd,
        }
      );
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const removeUserAssessmentRole = createAsyncThunk(
  "company/removeUserAssessmentRole",
  async (values, thunkApi) => {
    try {
      const { id, userId, objUser } = values;

      const res = await axios.delete(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/assessment-role/${userId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const listRiskRegisterRoleUsers = createAsyncThunk(
  "company/listRiskRegisterRoleUsers",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/risk-register-role/users`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const bulkAddToRiskRegisterRole = createAsyncThunk(
  "users/bulkAddToRiskRegisterRole",
  async (values, thunkApi) => {
    try {
      const { usersToAdd, objUser } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/risk-register-role/bulk-add`,
        {
          user_ids: usersToAdd,
        }
      );
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const removeUserRiskRegisterRole = createAsyncThunk(
  "company/removeUserRiskRegisterRole",
  async (values, thunkApi) => {
    try {
      const { id, userId, objUser } = values;

      const res = await axios.delete(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/risk-register-role/${userId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);