import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { createNewTemplateClone } from "features/company/templatesActions";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
const objInitialValues = { txtTemplateTitle: "" };
const AddNewTemplateFormJWTSchema = Yup.object().shape({
  txtTemplateTitle: Yup.string().required("The Name is required."),
});

function CloneTemplateDialog({
  open,
  onClose,
  objTemplate,
  onSubmit,
  objUser,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmitCloneForm = async (values) => {
    const objPostData = {
      name: values.txtTemplateTitle,
      global_template_id: objTemplate.id,
      agreed_to_terms: true,
      acknowledged_copyright: true,
      acknowledged_disclaimer: true,
    };

    setIsLoading(true);
    dispatch(createNewTemplateClone({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit(response.data.id);
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(rejectedValueOrSerializedError.response);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
        // console.log(
        //   "rejectedValueOrSerializedError",
        //   rejectedValueOrSerializedError
        // );
      });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Import Document Template As</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewTemplateFormJWTSchema}
        onSubmit={onSubmitCloneForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddNewTemplateForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Name"
                  name="txtTemplateTitle"
                  value={values.txtTemplateTitle}
                  placeholder={"Document name"}
                  error={errors.txtTemplateTitle && touched.txtTemplateTitle}
                  success={
                    values.txtTemplateTitle.length > 0 &&
                    !errors.txtTemplateTitle
                  }
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtTemplateTitle" />
                  </MDTypography>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Clone
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default CloneTemplateDialog;
