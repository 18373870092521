const pageRoutes = [
  {
    name: "home",
    route: "/sign-in",
  },
  {
    name: "sign up",
    route: "/sign-up",
  },
  {
    name: "log in",
    route: "/sign-in",
  },
  {
    name: "forgot password",
    route: "/forgot-password",
  },
];

export default pageRoutes;
