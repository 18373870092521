/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";

export default styled(Avatar)(({ theme, ownerState }) => {
  const { palette, functions, typography, boxShadows } = theme;
  const { shadow, bgColor, size } = ownerState;

  const { gradients, transparent, white } = palette;
  const { pxToRem, linearGradient } = functions;
  const { size: fontSize, fontWeightRegular } = typography;

  // backgroundImage value
  const backgroundValue =
    bgColor === "transparent"
      ? transparent.main
      : linearGradient(gradients[bgColor].main, gradients[bgColor].state);

  // size value
  let sizeValue;

  switch (size) {
    case "xs":
      sizeValue = {
        width: pxToRem(24),
        height: pxToRem(24),
        fontSize: fontSize.xs,
      };
      break;
    case "sm":
      sizeValue = {
        width: pxToRem(36),
        height: pxToRem(36),
        fontSize: fontSize.sm,
      };
      break;
    case "lg":
      sizeValue = {
        width: pxToRem(58),
        height: pxToRem(58),
        fontSize: fontSize.sm,
      };
      break;
    case "xl":
      sizeValue = {
        width: pxToRem(74),
        height: pxToRem(74),
        fontSize: fontSize.md,
      };
      break;
    case "xxl":
      sizeValue = {
        width: pxToRem(110),
        height: pxToRem(110),
        fontSize: fontSize.md,
      };
      break;
    default: {
      sizeValue = {
        width: pxToRem(48),
        height: pxToRem(48),
        fontSize: fontSize.md,
      };
    }
  }

  return {
    background: backgroundValue,
    color: white.main,
    fontWeight: fontWeightRegular,
    boxShadow: boxShadows[shadow],
    ...sizeValue,
  };
});
