// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";

// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";

const AddApiKeysFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue } = props;

  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h4"
          fontWeight="bold"
        >
          Create New API Keys
        </MDTypography>
        <MDBox mt={2}>
          <Grid container xs={12} justifyContent="space-between" px={5}>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter token name"
                name="name"
                value={values.name}
                placeholder={"Token name"}
                error={errors.name && touched.name}
                success={values.name.length > 0 && !errors.name}
              />
            </Grid>
            <Grid item xs={12} md={12} spacing={3}>
              <DateTimePicker
                // slotProps={{
                //   textField: {
                //     error: false,
                //   },
                // }}

                label="Enter expiry date (optional)"
                disablePast={true}
                value={values.expiry_date}
                onChange={(value) => {
                  setFieldValue("expiry_date", value);
                }}
                name="expiry_date"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
              {/* <FormField
                type="text"
                label="Enter expiry date"
                name="expiry_date"
                value={values.expiry_date}
                placeholder={"Public key"}
                error={errors.expiry_date && touched.expiry_date}
                success={values.expiry_date.length > 0 && !errors.expiry_date}
              /> */}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default AddApiKeysFormFormikWrapper;
