// prop-types is a library for typechecking of props

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// @mui icons
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useMaterialUIController } from "context";
import HelpIcon from "@mui/icons-material/Help";

const CustomerNameBtnTr = (props) => {
  const { value, column, row, selectRMF } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const routeDictionary = location.state?.routeDictionary ?? {};
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const setRMF = (rmfData) => {
    selectRMF(row.original);
  };

  return (
    // <MDButton variant="text" onClick={() => btnOnClickClientName(row.original)}>
    <MDButton variant="text" onClick={() => setRMF()}>
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
        <Tooltip title={row.original.rmfNotes}>
          <IconButton
            sx={
              darkMode
                ? { fontSize: "1.2rem" }
                : { opacity: "0.4", fontSize: "1.2rem" }
            }
            pb={2}
          >
            <HelpIcon color="icon" />
          </IconButton>
        </Tooltip>
      </MDTypography>
    </MDButton>
  );
};

export default CustomerNameBtnTr;
