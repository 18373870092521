import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from "context";
import MDTypography from "components/MDTypography";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setDeviceWidth(window.innerWidth);
  };

  useEffect(() => {
    setLayout(dispatch, "dashboard");
    window.addEventListener("resize", handleResize);
  }, [pathname]);

  return (
    <>
      {deviceWidth > 767 ? (
        <MDBox
          sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
            p: 3,
            position: "relative",

            [breakpoints.up("xl")]: {
              marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
              transition: transitions.create(["margin-left", "margin-right"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
              }),
            },
          })}
        >
          {children}
        </MDBox>
      ) : (
        <MDBox
          bgColor="dark"
          variant="gradient"
          p={4}
          sx={{
            height: "100vh",
            width: "100vw",
            position: "fixed",
            display: "flex",
          }}
        >
          <MDBox sx={{ alignSelf: "center" }}>
            <MDBox textAlign="center">
              <ReportProblemIcon
                color="white"
                display="block"
                style={{ height: "50px", color: "#207dc7", width: "3.5em" }}
              />
            </MDBox>
            <MDBox textAlign="center" mt={1}>
              <MDTypography
                color="white"
                mb={2}
                sx={{ fontSize: "18px", fontWeight: "bold" }}
              >
                Warning!
              </MDTypography>
              <MDTypography color="white">
                Compliance Scorecard is not optimized for use on a mobile
                device. Please login on a Desktop or Laptop sized device
                instead.
                <br />
                <br />
                Thank you!
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
