import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { createNewCampaign } from "features/company/policiesActions";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import * as Yup from "yup";

import { Formik, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
const objInitialValues = {
  drpAudience: null,
  txtTitle: "",
  txtDescription: "",
  drpStatus: "",
  drpReminderTime: null,
};
const AddNewCampaignFormJWTSchema = Yup.object().shape({
  drpAudience: Yup.object().shape({
    id: Yup.string().required("An Aundience is required."),
    label: Yup.string().required("An Aundience is required."),
  }),
  txtTitle: Yup.string().required("Field is required."),
  txtDescription: Yup.string().max(50, "Field is too big (max size is 50)"),
});

function CreateNewCampaignDialog({
  open,
  onClose,
  onSubmit,
  objUser,
  clientId,
  policyId,
  policyDocumentId,
  audiences,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmitForm = async (values) => {
    const objPostData = {
      audience_id: values.drpAudience.id,
      campaign_name: values.txtTitle,
      description: values.txtDescription,
      adoption_status: "Draft", //values.drpStatus,
      reminder_time: values.drpReminderTime
        ? parseInt(values.drpReminderTime)
        : null,
      policy_id: policyId,
      policy_document_id: policyDocumentId,
    };
    setIsLoading(true);
    dispatch(
      createNewCampaign({
        objPostData,
        objUser,
        clientId,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit();
        } else {
          showMsg("error", "Something went wrong, please try again");
          onClose();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
        onClose();
      });
  };

  const addNewAudience = () => {
    navigate(`/audiences/create`);
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Create Adoption Campaign</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewCampaignFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddNewCampaignForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={1.5}>
                <Autocomplete
                  autoFocus
                  name="drpAudience"
                  disableClearable
                  value={values.drpAudience}
                  options={audiences}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="standard"
                      label="Audience Group"
                      fullWidth
                    />
                  )}
                  onChange={(event, value) => {
                    if (value === null) {
                      setFieldValue("drpAudience", "");
                    } else {
                      setFieldValue("drpAudience", value);
                    }
                  }}
                />
                {audiences.length == 0 ? (
                  <MDBox mb={1.5}>
                    <MDButton variant="text">
                      <MDBox
                        color="success"
                        display="flex"
                        alignItems="center"
                        onClick={addNewAudience}
                      >
                        <AddCircleIcon></AddCircleIcon>
                        <MDTypography
                          variant="text"
                          fontWeight="medium"
                          color="success"
                          sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
                        >
                          Add new audience
                        </MDTypography>
                      </MDBox>
                    </MDButton>
                  </MDBox>
                ) : (
                  ""
                )}

                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage
                      name="drpAudience"
                      render={(msg) => {
                        return <div>Field is required.</div>;
                      }}
                    />
                  </MDTypography>
                </MDBox>
              </MDBox>
              <FormField
                type="text"
                label="Adoption Campaign Title"
                name="txtTitle"
                value={values.txtTitle}
                error={errors.txtTitle && touched.txtTitle}
                success={values.txtTitle.length > 0 && !errors.txtTitle}
              />
              <FormField
                type="text"
                label="Adoption Campaign Description"
                name="txtDescription"
                value={values.txtDescription}
                error={errors.txtDescription && touched.txtDescription}
                success={
                  values.txtDescription.length > 0 && !errors.txtDescription
                }
              />
              <MDBox mb={1.5}>
                <Autocomplete
                  name="drpStatus"
                  disableClearable
                  options={["Draft", "Sent", "Adopted", "Expired"]}
                  value="Draft"
                  disabled
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="standard"
                      label="Status"
                      fullWidth
                    />
                  )}
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage
                      name="drpStatus"
                      render={(msg) => {
                        return <div>Field is required.</div>;
                      }}
                    />
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mb={1.5}>
                <Autocomplete
                  name="drpReminderTime"
                  value={values.drpReminderTime}
                  options={["1 Day", "3 Days", "7 Days"]}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="standard"
                      label="Reminder Time"
                      fullWidth
                    />
                  )}
                  onChange={(event, value) => {
                    if (value === null || value === "") {
                      setFieldValue("drpReminderTime", "");
                    } else {
                      setFieldValue("drpReminderTime", value);
                    }
                  }}
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage
                      name="drpReminderTime"
                      render={(msg) => {
                        return <div>Field is required.</div>;
                      }}
                    />
                  </MDTypography>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Send Notifications
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default CreateNewCampaignDialog;
