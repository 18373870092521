import DefaultCell from "views/components/TableCells/DefaultCell";
import TemplateNameBtnTr from "./TemplateNameBtnTr";
import TemplateDeleteBtnTr from "./TemplateDeleteBtnTr";

const TemplatesDataTableData = (objUser, reloadFunc) => ({
  columns: [
    {
      Header: "Name",
      accessor: "name",
      width: "25%",
      Cell: ({ value, column, row }) => (
        <TemplateNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      width: "30%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "30%",
    },
    {
      Header: "Type",
      accessor: "subscription_category",
      width: "15%",
    },
    {
      Header: "Owner",
      accessor: "created_by",
      width: "15%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ value, column, row }) => (
        <TemplateDeleteBtnTr
          value={value}
          column={column}
          row={row}
          reloadFunc={reloadFunc}
          objUser={objUser}
        />
      ),
    },
  ],
  rows: [],
});

export default TemplatesDataTableData;
