import MDTypography from "components/MDTypography";
import React from "react";

const index = () => {
  return (
    <>
      <MDTypography variant="h6" sx={{fontSize: "12px"}}>Images should meet the following guidelines:</MDTypography>
      <MDTypography
        variant="p"
        sx={{
          marginTop: "0.4rem",
          display: "block",
        }}
        color="dark"
      >
        <ul style={{ fontSize: "0.8rem" }}>
          <li>
            <b>File type: </b> PNG, JPG
          </li>
          <li>
            <b>Max upload size: </b> 250KB
          </li>
          <li>
            <b>Dimensions (Max image height): </b> 500px
          </li>
        </ul>
      </MDTypography>
    </>
  );
};

export default index;
