/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";

/***Blocked domains */
export const getBlockedDomains = createAsyncThunk(
  "company/getBlockedDomains",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/blocked-domains`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addBD = createAsyncThunk(
  "company/addBD",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/blocked-domains`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteBlockedDomain = createAsyncThunk(
  "company/deleteBlockedDomain",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;

      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/blocked-domains/${dataFromActionBtn.id}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveBDChanges = createAsyncThunk(
  "company/saveBDChanges",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/blocked-domains`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**allowed multiple msp signups */
export const getMDDomains = createAsyncThunk(
  "company/getMDDomains",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/allowed-multiple-signups`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addMD = createAsyncThunk(
  "company/addMD",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/allowed-multiple-signups`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteMDDomain = createAsyncThunk(
  "company/deleteMDDomain",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;

      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/allowed-multiple-signups/${dataFromActionBtn.id}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveMDChanges = createAsyncThunk(
  "company/saveMDChanges",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/allowed-multiple-signups`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
