/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import Grid from "@mui/material/Grid";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import MDTypography from "components/MDTypography";
import { useMemo, memo } from "react";
import { Pie } from "react-chartjs-2";
Chart.register(ArcElement, Tooltip, Legend);

const AssessmentGamePieChart = memo(
  ({
    data,
    options,
    title,
    onClickTitle,
    total,
    eventID,
    status,
    possible_score,
    score,
    trustCenterPage,
  }) => {
    const percent = useMemo(() => {
      if (data.datasets[0].data[6] === 0) {
        return 100;
      } else {
        return (total / (total + data.datasets[0].data[6])) * 100;
      }
    }, [data]);
    return (
      <>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={trustCenterPage ? 6 : 3}
          pl={0}
          pt={0}
        >
          <MDTypography
            sx={{
              marginBottom: "0.5rem",
              cursor: onClickTitle !== null ? "pointer" : "default",
              "&:hover": {
                textDecoration: onClickTitle !== null ? "underline" : "none",
              },
            }}
            fontWeight="bold"
            variant="h5"
            textAlign="center"
            color="dark"
            onClick={onClickTitle ?? (() => {})}
          >
            {title}
          </MDTypography>
          <Pie data={data} options={options} />
          <MDTypography
            sx={{ marginBottom: "0.5rem" }}
            fontWeight="bold"
            variant="h6"
            textAlign="center"
            color="dark"
          >
            {eventID === null ? (
              "Assessment Not Started"
            ) : (
              <>
                {percent === 100 ? (
                  status === "completed" ? (
                    <>
                      Score: {((score / possible_score) * 100.0).toFixed(2)} %
                    </>
                  ) : (
                    <>Submission Required</>
                  )
                ) : (
                  <>{percent.toFixed(2)}% Completed</>
                )}
              </>
            )}
          </MDTypography>
        </Grid>
      </>
    );
  }
);

AssessmentGamePieChart.defaultProps = {
  onClickTitle: null,
  trustCenterPage: false,
};

export default AssessmentGamePieChart;
