import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
const AssessmentEventsNameBtnTr = (props) => {
  const { value, row, takeTheAssessment, viewAssessmentVersionsPage } = props;
  return (
    <MDButton
      variant="text"
      onClick={
        row.original.isImported
          ? () => takeTheAssessment(row.original)
          : () => viewAssessmentVersionsPage(row.original)
      }
    >
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default AssessmentEventsNameBtnTr;
