import { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { createNewEventBulk } from "features/company/assessmentsActions";
import { get } from "features/assessmentConfig/assessmentConfigActions";
import Papa from "papaparse";
import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import { TextField } from "@mui/material";
import { getAssessmentItemScoring } from "features/assessmentConfig/assessmentConfigActions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const objInitialValues = {
  txtName: "",
  dateCompleted: "",
  drpSource: { id: "", name: "" },
  drpTemplateType: { id: "", name: "" },
};

const ImportTemplateCSVFormJWTSchema = Yup.object().shape({
  txtName: Yup.string().required("The Name is required."),
  dateCompleted: Yup.string().required("The Date is required."),
  drpSource: Yup.object().shape({
    id: Yup.string().required("The Event Source is required."),
    name: Yup.string().required("The Event Source is required."),
  }),
  drpTemplateType: Yup.object().shape({
    id: Yup.string().required("The Template Type is required."),
    name: Yup.string().required("The Template Type is required."),
  }),
});

function ImportEventCSVDialog({ open, onClose, onSubmit, objUser }) {
  const [isLoading, setIsLoading] = useState(false);
  const [importArray, setImportArray] = useState([]);
  const [step, setStep] = useState(1);
  const [templateTypes, setTemplateTypes] = useState([]);
  const [sources, setSources] = useState([{ id: 1, name: "Kaseya" }]);
  const [eventSource, setEventSource] = useState("Kesaya");
  const dispatch = useDispatch();
  const formRef = useRef();
  const [itemScores, setItemScores] = useState({});

  useEffect(() => {
    setIsLoading(true);

    setStep(1);

    // fetch template types
    dispatch(get({ _per_page: 100, _page: 0, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setTemplateTypes(
          originalPromiseResult.data.assessment_types.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(rejectedValueOrSerializedError.response);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
      });

    // fetch item scoring
    dispatch(getAssessmentItemScoring({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const results = originalPromiseResult.data.assessment_item_scoring;
        setItemScores(results);
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", objErrorRsp?.data?.message);
          showMsg("error", objErrorRsp?.data?.error_code);
        }
      });
  }, []);

  const onSubmitForm = async (values) => {
    let tempArray = importArray;
    if (eventSource === "Kaseya") {
      tempArray = tempArray.map((item) => {
        const splitIndex = item.Description.indexOf(":");
        const itemPart = item.Description.substring(0, splitIndex).trim();
        const explanationPart = item.Description.substring(
          splitIndex + 1
        ).trim();
        return {
          ...item,
          control_id: item["CMMC 2.0 Level 2"],
          item: itemPart,
          explanation: explanationPart,
          sequence: 1,
          score:
            item.Response == "Yes, Fully"
              ? parseInt(
                  itemScores.find((itemScore) => {
                    return itemScore.name == "Satisfactory";
                  }).code
                )
              : item.Response == "Yes, Partially"
              ? parseInt(
                  itemScores.find((itemScore) => {
                    return itemScore.name == "Needs Attention";
                  }).code
                )
              : item.Response == "Unsure"
              ? parseInt(
                  itemScores.find((itemScore) => {
                    return itemScore.name == "Not Applicable";
                  }).code
                )
              : item.Response == "No"
              ? parseInt(
                  itemScores.find((itemScore) => {
                    return itemScore.name == "At Risk";
                  }).code
                )
              : 0,
        };
      });
    }

    const objPostData = {
      name: values.txtName,
      dateCompleted: values.dateCompleted,
      templateId: "NULIEID",
      source: values.drpSource.name,
      import_array: tempArray,
      template_type: values.drpTemplateType.id,
    };

    if (importArray.length === 0) {
      showMsg("error", "Empty file. Please select a CSV file to import.");
      return;
    }
    setIsLoading(true);
    dispatch(createNewEventBulk({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit(response.data.id);
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(rejectedValueOrSerializedError.response);
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.message
        );
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.error_code
        );
      });
  };

  function handleOnFileChange(e) {
    parseCSV(e.target.files[0]);
  }

  function parseCSV(file) {
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          let tempArray = results.data.filter(
            (item) => !Object.values(item).every((x) => x === null || x === "")
          );

          setImportArray(tempArray);
        },
        error: function (err, file, inputElem, reason) {
          console.log("Error:", err, reason);
        },
      });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth={true}>
        <DialogTitle sx={{ pb: 0 }}>Import Event</DialogTitle>
        <Formik
          initialValues={objInitialValues}
          validationSchema={ImportTemplateCSVFormJWTSchema}
          onSubmit={onSubmitForm}
          innerRef={formRef}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id="AddNewTemplateForm" autoComplete="off">
              <DialogContent>
                <MDBox mb={0} mt={1}>
                  <Field
                    type="text"
                    label="Name"
                    name="txtName"
                    value={values.txtName}
                    placeholder={"Event name v1"}
                    error={errors.txtName && touched.txtName}
                    success={values.txtName.length > 0 && !errors.txtName}
                    as={MDInput}
                    variant="standard"
                    fullWidth
                    autoFocus
                  />
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      <ErrorMessage name="txtName" />
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox mb={0} mt={2}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Date Completed"
                      value={values.dateCompleted}
                      onChange={(value) => {
                        setFieldValue("dateCompleted", value);
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>

                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      <ErrorMessage name="dateCompleted" />
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox mb={0} mt={1}>
                  <Autocomplete
                    name="drpSource.name"
                    disableClearable
                    value={values.drpSource}
                    options={sources}
                    getOptionLabel={(option) => (option ? option.name : "")}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Source"
                        fullWidth
                      />
                    )}
                    onChange={(event, value) => {
                      if (value === null) {
                        setEventSource("");
                        setFieldValue("drpSource", "");
                      } else {
                        setEventSource(value.name);
                        setFieldValue("drpSource", value);
                      }
                    }}
                  />
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      <ErrorMessage name="drpSource.name" />
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox mb={0} mt={0}>
                  <Autocomplete
                    name="drpTemplateType.name"
                    disableClearable
                    value={values.drpTemplateType}
                    options={templateTypes}
                    getOptionLabel={(option) => (option ? option.name : "")}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Event Type"
                        fullWidth
                      />
                    )}
                    onChange={(event, value) => {
                      if (value === null) {
                        setFieldValue("drpTemplateType", "");
                      } else {
                        setFieldValue("drpTemplateType", value);
                      }
                    }}
                  />
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      <ErrorMessage name="drpTemplateType.name" />
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox mb={0} mt={0}>
                  <MDInput
                    type={"file"}
                    onChange={(e) => handleOnFileChange(e)}
                    accept={".csv"}
                    label="CSV File"
                    variant={"standard"}
                    autoFocus
                  />
                </MDBox>
              </DialogContent>
              <DialogActions>
                {isLoading === false ? (
                  <>
                    <MDButton
                      type="submit"
                      color="success"
                      sx={{ padding: "12px 12px" }}
                    >
                      Import
                    </MDButton>
                    <MDButton
                      type="button"
                      variant="outlined"
                      color="dark"
                      sx={{ padding: "12px 12px" }}
                      onClick={onClose}
                    >
                      Cancel
                    </MDButton>
                  </>
                ) : (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
export default ImportEventCSVDialog;
