/* eslint-disable */
import { useEffect, useState, useRef, useMemo } from "react";
import { Link, Navigate } from "react-router-dom";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import MDTypography from "components/MDTypography";
import { Editor } from "@tinymce/tinymce-react";
import { TINYMC_API_KEY } from "components/utils/constants/misc";
import { useDispatch } from "react-redux";

import {
  getPolicyDataToReview,
  getPolicyDocumentPDF,
  approvePolicyReviewDocument,
  rejectPolicyReviewDocument,
  sendDraftAutosave,
  sendComments,
  getPolicyVersion,
  fetchPolicyDocument,
  fetchPolicyDocumentIfNewer,
} from "features/company/policiesActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showMsg } from "utils/general";
import Grid from "@mui/material/Grid";
import RejectPolicyDocumentDialog from "./RejectPolicyDocumentDialog";
import { saveSelection, restoreSelection } from "./selectionUtils";

const Policies = (props) => {
  const { objUser } = props;
  let { policyId, policyReviewId } = useParams();
  const objReviewPolicyDefault = {
    policy_title: "",
    policy_status: "",
    policy_creator: "",
    document_version: "",
    policy_reason: null,
  };

  const [isLoadingValidateReviewStatus, setIsLoadingValidateReviewStatus] =
    useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [isSoleApproverReviewer, setIsSoleApproverReviewer] = useState(false);
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [objReviewPolicy, setObjReviewPolicy] = useState(
    objReviewPolicyDefault
  );
  const [objPolicyDocument, setObjPolicyDocument] = useState({});
  const [documentTimestamp, setDocumentTimestamp] = useState(Date.now());

  const canDownloadPDFS =
    objUser.user && objUser.user.company.can_download_pdfs == "yes"
      ? true
      : false;

  const downloadDocument = () => {
    const params = {
      policy_id: policyId,
      review_id: policyReviewId,
    };
    downloadPolicyDocumentPDF(objUser, params);
  };

  const onClickApproveDocument = () => {
    const params = {
      policy_id: policyId,
      review_id: policyReviewId,
    };
    approveDocument(objUser, params);
  };

  const onClickRejectDocument = () => {
    setOpenRejectDialog(true);
  };

  useEffect(() => {
    const params = {
      policy_id: policyId,
      review_id: policyReviewId,
    };
    validateReviewStatus(objUser, params);
  }, []);

  const downloadPolicyDocumentPDF = (objUser, params) => {
    setIsLoading(true);
    dispatch(getPolicyDocumentPDF({ objUser, params }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;

        if (response.status === 200) {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));

          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.setAttribute("download", policyReviewId + ".pdf");
          document.body.appendChild(alink);
          alink.click();
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong, please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validateReviewStatus = (objUser, params) => {
    setIsLoadingValidateReviewStatus(true);
    dispatch(getPolicyDataToReview({ objUser, params }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;

        setObjReviewPolicy(response.data);
        const version = response.data.document_version.major_version;
        if (response.data.isSoleApproverReviewer) {
          setIsSoleApproverReviewer(response.data.isSoleApproverReviewer);
        }
        return dispatch(fetchPolicyDocument({ objUser, policyId, version }));
      })
      .then(unwrapResult)
      .then((response) => {
        let originalDocumentSections = response.data.document_sections;
        response.data.document_sections = originalDocumentSections.sort(
          (a, b) =>
            a.owning_global_section.order - b.owning_global_section.order
        );
        setObjPolicyDocument(response.data);
        setDocumentTimestamp(response.data.updated_at);
        if (typeof response.data.policy.extra.versions !== "undefined") {
          window["getDocumentVersions"] = () =>
            response.data.policy.extra.versions;
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // console.log(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong");
        navigate("/home");
      })
      .finally(() => {
        setIsLoadingValidateReviewStatus(false);
      });
  };

  const rejectDocument = (postData) => {
    setIsLoading(true);
    const params = {
      policy_id: policyId,
      review_id: policyReviewId,
    };
    setOpenRejectDialog(false);
    dispatch(rejectPolicyReviewDocument({ objUser, params, postData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        // console.log("### rejectDocument Response", response);

        if (response.status === 200) {
          navigate("/pending-reviews");
          showMsg("success", "Policy review rejected successfully");
          // validateReviewStatus(objUser, params);
        } else {
          showMsg("error", "Something went wrong");
          validateReviewStatus(objUser, params);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong");
        validateReviewStatus(objUser, params);
      });
  };

  const approveDocument = (objUser, params) => {
    setIsLoading(true);
    dispatch(approvePolicyReviewDocument({ objUser, params }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;

        if (response.status === 200) {
          validateReviewStatus(objUser, params);
        } else {
          showMsg("error", "Something went wrong");
          validateReviewStatus(objUser, params);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong");
        validateReviewStatus(objUser, params);
      });
  };

  const saveDocument = () => {
    dispatch(
      sendComments({
        document: editorRef.current.getContent(),
        objUser,
        policyId,
        policyDocumentId: objPolicyDocument.id,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        if (response.status !== 200) {
          showMsg("error", "Error while saving the comments");
        }
      })
      .catch(() => {
        showMsg("error", "Error while saving the comments");
      });
  };

  const fetchDocumentVersion = (
    major_version,
    minor_version,
    patch_version
  ) => {
    const objPostData = { major_version, minor_version, patch_version };
    return dispatch(getPolicyVersion({ objPostData, objUser, policyId }))
      .then(unwrapResult)
      .then((response) => response.data.document)
      .catch((e) => {
        console.log(e);
        showMsg("error", "Error retrieving policy. Try again later");
      });
  };

  const compareDocumentVersionsWorker = useMemo(
    () =>
      new Worker(
        new URL(
          "../CreatePolicyDocument/components/compareDocumentVersions.worker.js",
          import.meta.url
        )
      ),
    []
  );

  const compareDocumentVersions = (before, after) =>
    new Promise((resolve, reject) => {
      compareDocumentVersionsWorker.onmessage = (e) => {
        resolve(
          `<div class='diff'><div class='before'>${e.data}</div><div class='after'>${e.data}</div></div>`
        );
      };
      compareDocumentVersionsWorker.onerror = (e) => {
        reject(e);
      };
      compareDocumentVersionsWorker.postMessage([before, after]);
    });

  const onCloseRejectDocumentDialog = () => {
    setOpenRejectDialog(false);
  };

  // const refreshDocument = () => {
  //   const version = objReviewPolicy.document_version.major_version;
  //   return dispatch(
  //     fetchPolicyDocumentIfNewer({
  //       objUser,
  //       policyId,
  //       version,
  //       timestamp: documentTimestamp,
  //     })
  //   )
  //     .then(unwrapResult)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         const tinyDocument = editorRef?.current?.getBody()?.getRootNode();

  //         if (tinyDocument) {
  //           const selection = saveSelection(tinyDocument);
  //           editorRef?.current?.setContent(response.data?.document ?? "");
  //           restoreSelection(tinyDocument, selection);
  //           setDocumentTimestamp(response.data.updated_at);
  //         }
  //       }
  //     })
  //     .catch((e) => {
  //       if (e?.response?.status !== 304) {
  //         console.log(e);
  //         showMsg("error", "Error retrieving policy. Try again later");
  //       }
  //     });
  // };

  // useEffect(() => {
  //   const refreshTimeout = setInterval(refreshDocument, 60 * 1000);
  //   return () => clearInterval(refreshTimeout);
  // }, [objReviewPolicy, documentTimestamp]);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <RejectPolicyDocumentDialog
        open={openRejectDialog}
        onClose={onCloseRejectDocumentDialog}
        rejectDocumentAction={rejectDocument}
      />
      <MDBox>
        <Card>
          {isLoadingValidateReviewStatus === false &&
          typeof objReviewPolicy.policy_title !== "undefined" ? (
            <Fragment>
              <MDBox px={5} textAlign="left" mt={4}>
                <Grid
                  container
                  justifyContent="space-between"
                  columnSpacing={2}
                >
                  <Grid item xs={12} pt={0}>
                    <MDBox mb={0}>
                      <MDBox mb={0}>
                        <MDTypography variant="h5" fontWeight="bold">
                          Requested Authorizations
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="body2" color="text">
                        Waiting for all contacts to approve the{" "}
                        {objPolicyDocument.policy.policy_type == "asset"
                          ? "asset list"
                          : "policy"}{" "}
                        document.
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox px={5} textAlign="left" mt={3}>
                <Grid container justifyContent="start">
                  <Grid item xs={12} lg={6} pt={0}>
                    <Grid container justifyContent="start" spacing={3}>
                      <Grid item xs={12} lg={6} pt={0}>
                        <MDBox mb={0}>
                          <MDBox mb={0}>
                            <MDTypography variant="body2" fontWeight="bold">
                              {objPolicyDocument.policy.policy_type == "asset"
                                ? "Asset List"
                                : "Policy"}
                            </MDTypography>
                          </MDBox>
                          <MDTypography variant="body2" color="text">
                            {objReviewPolicy.policy_title ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} lg={6} pt={0}>
                        <MDBox mb={0}>
                          <MDBox mb={0}>
                            <MDTypography variant="body2" fontWeight="bold">
                              Version
                            </MDTypography>
                          </MDBox>
                          <MDTypography variant="body2" color="text">
                            {objReviewPolicy.document_version
                              ? `${objReviewPolicy.document_version.major_version}.${objReviewPolicy.document_version.minor_version}.${objReviewPolicy.document_version.patch_version}`
                              : ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} lg={6} pt={0}>
                        <MDBox mb={0}>
                          <MDBox mb={0}>
                            <MDTypography variant="body2" fontWeight="bold">
                              Status
                            </MDTypography>
                          </MDBox>
                          <MDTypography
                            variant="body2"
                            color={objReviewPolicy.policy_status_class ?? ""}
                          >
                            {objReviewPolicy.policy_status_label ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} lg={6} pt={0}>
                        <MDBox mb={0}>
                          <MDBox mb={0}>
                            <MDTypography variant="body2" fontWeight="bold">
                              Creator
                            </MDTypography>
                          </MDBox>
                          <MDTypography variant="body2" color="text">
                            {objReviewPolicy.policy_creator ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      {objReviewPolicy.policy_status === "rejected" ? (
                        <Fragment>
                          <Grid item xs={12} lg={12} pt={0}>
                            <MDBox mb={0}>
                              <MDBox mb={0}>
                                <MDTypography variant="body2" fontWeight="bold">
                                  Reason
                                </MDTypography>
                              </MDBox>
                              <MDTypography variant="body2" color="text">
                                {objReviewPolicy.policy_reason ?? ""}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        </Fragment>
                      ) : (
                        <span></span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
              {/* dynamically loading sections */}
              <MDBox mt={"20px"} px={5}>
                {objReviewPolicy.policy_doc_type === "oscal" ? (
                  <>
                    {objPolicyDocument.document_sections.map((section) => {
                      return (
                        <>
                          {section.section_content == "" ||
                          section.section_content == null ? (
                            ""
                          ) : (
                            <div key={section.id}>
                              <MDTypography
                                sx={{ marginBottom: "10px", marginTop: "20px" }}
                                variant="body2"
                                fontWeight="bold"
                              >
                                {section.owning_global_section.title}
                              </MDTypography>
                              <Editor
                                apiKey={TINYMC_API_KEY}
                                onInit={(evt, editor) => {
                                  editorRef.current = editor;
                                  editor
                                    .getBody()
                                    .setAttribute("contenteditable", false);
                                  editor.on("PLGCommentChange", (e) => {
                                    saveDocument();
                                  });
                                }}
                                initialValue={section.section_content}
                                init={{
                                  branding: false,
                                  plugins: "autoresize",
                                  autoresize_bottom_margin: 0,
                                  max_height: 500,
                                  menubar: false,

                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                }}
                                plugins={[]}
                                toolbar={[
                                  "polygonVersionBrowser | plg_addcomment plg_showcomments",
                                ]}
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <MDTypography variant="body2" fontWeight="bold">
                      Document Text
                    </MDTypography>
                    <Editor
                      apiKey={TINYMC_API_KEY}
                      onInit={(evt, editor) => {
                        editorRef.current = editor;
                        editor.getBody().setAttribute("contenteditable", false);
                        editor.on("PLGCommentChange", (e) => {
                          saveDocument();
                        });
                      }}
                      initialValue={objPolicyDocument.document ?? ""}
                      init={{
                        branding: false,
                        height: 500,
                        menubar: false,
                        external_plugins: {
                          polygonVersionBrowser:
                            process.env.PUBLIC_URL +
                            "/versionBrowserPlugin/versionBrowserPlugin.js",
                          polygonComments:
                            process.env.PUBLIC_URL +
                            "/PLGCommentsPlugin/PLGCommentsPlugin.js",
                        },
                        polygonVersionBrowser_versionsCallback:
                          "getDocumentVersions",
                        polygonVersionBrowser_fetchVersionCallback:
                          fetchDocumentVersion,
                        polygonVersionBrowser_compareVersionCallback:
                          compareDocumentVersions,
                        polygonComments_author: objUser.user.id,
                        polygonComments_author_name: objUser.user.name,
                        // polygonComments_refreshContent: refreshDocument,
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                      plugins={[]}
                      toolbar={[
                        "polygonVersionBrowser | plg_addcomment plg_showcomments",
                      ]}
                    />
                  </>
                )}
              </MDBox>
              {isLoading === false ? (
                <MDBox
                  sx={{ padding: "0 40px 40px 40px" }}
                  // width="100%"
                  display="flex"
                  justifyContent="flex-start"
                >
                  {objReviewPolicy.policy_status === "pending" ? (
                    <Grid container justifyContent="start" mt={5} spacing={2}>
                      {canDownloadPDFS ? (
                        <></>
                      ) : (
                        // <Grid item xs={12} lg={4} xl={3} pt={0}>
                        //   <MDButton
                        //     onClick={downloadDocument}
                        //     // disabled={true}
                        //     type="button"
                        //     variant="gradient"
                        //     color="dark"
                        //     sx={{
                        //       padding: "12px 20px",
                        //       display: "block",
                        //       width: "100%",
                        //     }}
                        //   >
                        //     Download Document
                        //   </MDButton>
                        // </Grid>
                        ""
                      )}
                      <Grid item xs={12} lg={4} xl={3} pt={0}>
                        <MDButton
                          // disabled={true}
                          display="block"
                          onClick={onClickRejectDocument}
                          type="button"
                          variant="gradient"
                          color="error"
                          sx={{
                            padding: "12px 20px",
                            display: "block",
                            width: "100%",
                          }}
                        >
                          Reject
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} lg={4} xl={3} pt={0}>
                        <MDButton
                          // disabled={true}
                          display="block"
                          onClick={onClickApproveDocument}
                          type="button"
                          // variant="gradient"
                          color="success"
                          disabled={objReviewPolicy.rejected_count > 0}
                          sx={{
                            padding: "12px 20px",
                            display: "block",
                            width: "100%",
                          }}
                        >
                          {objReviewPolicy.rejected_count > 0
                            ? "Already rejected by another reviewer"
                            : isSoleApproverReviewer
                            ? "Review and Approve"
                            : "Approve"}
                        </MDButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                    // <Grid container justifyContent="start" mt={5} spacing={2}>
                    //   <Grid item xs={12} lg={4} pt={0}>
                    //     <MDButton
                    //       onClick={downloadDocument}
                    //       // disabled={true}
                    //       type="button"
                    //       variant="gradient"
                    //       color="dark"
                    //       sx={{
                    //         padding: "12px 20px",
                    //         display: "block",
                    //         width: "100%",
                    //       }}
                    //     >
                    //       Download Document
                    //     </MDButton>
                    //   </Grid>
                    // </Grid>
                  )}
                </MDBox>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </Fragment>
          ) : (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default Policies;
