import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { unwrapResult } from "@reduxjs/toolkit";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  getCyberCNSAssets,
  getPreviousSyncInformation,
  importCyberCNSAssets,
  saveSyncFrequencySettingsForConnectSecureHardwareAssets,
} from "features/connectSecure/connectSecurev4Actions";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import assetImageLogo from "assets/images/asset-preview.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import { Box, FormControl, InputLabel, MenuItem, Pagination, Select } from "@mui/material";

const CyberCNSAssetSyncSection = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [frequencyType, setFrequencyType] = useState("");
  const [isLoadingSyncInfo, setIsLoadingSyncInfo] = useState(false);
  const [isSavingSyncSettings, setIsSavingSyncSettings] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [syncRecords, setSyncRecords] = useState(null);
  const [isFetchingAssets, setIsFetchingAssets] = useState(false);
  const [isImportingAssets, setIsImportingAssets] = useState(false);
  const [totalAssetsFetched, setTotalAssetsFetched] = useState(0);
  const [assetData, setAssetData] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchPreviousSyncInformation();
  }, []);

  const fetchPreviousSyncInformation = async () => {
    setIsLoadingSyncInfo(true);
    dispatch(getPreviousSyncInformation())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingSyncInfo(false);
        const response = originalPromiseResult;
        setSyncRecords(response.data);
        setSelectedValue(
          response.data.frequencyOfSync == ","
            ? ""
            : response.data.frequencyOfSync
        );
        setFrequencyType(response.data.frequencyOfSync.split(",")[0]);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingSyncInfo(false);
        showMsg("error", "Recent sync records could not be fetched");
        console.error(rejectedValueOrSerializedError);
      });
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const saveSyncSettings = async () => {
    setIsSavingSyncSettings(true);
    dispatch(
      saveSyncFrequencySettingsForConnectSecureHardwareAssets({
        selectedValue,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsSavingSyncSettings(false);
        showMsg("success", "Sync frequency settings updated");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsSavingSyncSettings(false);
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error saving changes");
        }
      });
  };

  const fetchAssetsFromCyberCNS = async () => {
    setIsFetchingAssets(true);
    dispatch(getCyberCNSAssets())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        const assetsResponseData = response.data.data;
        const totalAssets = response.data.total;

        if (assetsResponseData.length > 0) {
          setAssetData(assetsResponseData);
          setTotalAssetsFetched(totalAssets);
        } else {
          showMsg("info", "No assets were found");
        }

        setIsFetchingAssets(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingAssets(false);
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Error fetching assets from CyberCNS");
      });
  };

  const importAssets = async () => {
    setIsImportingAssets(true);
    dispatch(importCyberCNSAssets({ assetData, totalAssetsFetched }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        navigate(
          `/asset-lists/${originalPromiseResult.data.finalPolicyObject.id}/${originalPromiseResult.data.updatedMajorVersion}`
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsImportingAssets(false);
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error importing assets");
        }
      });
  };

  const openAssetDocument = async () => {
    navigate(
      `/policies/${syncRecords.policyId}/${syncRecords.documentLastMajorVersion}`
    );
  };

  const pageCount = Math.ceil(totalAssetsFetched / pageSize);

  const paginatedAssetData = useMemo(() => {
    return assetData.slice((page - 1) * pageSize, page * pageSize);
  }, [assetData, page, pageSize]);

   const handlePageChange = (event, value) => {
     setPage(value);
     // Scroll to top of the table when page changes
     window.scrollTo({ top: 0, behavior: "smooth" });
   };

   const entriesStart = (page - 1) * pageSize + 1;
   const entriesEnd = Math.min(page * pageSize, totalAssetsFetched);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid my={3} container width="100%" spacing={2}>
        <Grid item xs={12} lg={12}>
          <MDBox bgColor="success" borderRadius="lg" mb={1} textAlign="center">
            <MDTypography variant="h4" color="white">
              {syncRecords ? syncRecords.clientName : "--"} using ConnectSecure
              v4
            </MDTypography>
          </MDBox>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Card
            sx={{
              height: "100%",
              width: "100%",
              padding: "1rem",
              maxHeight: "600px",
            }}
          >
            <MDTypography variant="h6" color="success">
              Frequency of sync (select one option)
            </MDTypography>

            <Grid container>
              <Grid xs={6}>
                <label htmlFor={"weekly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"weekly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "weekly" }}
                      onChange={() => {
                        setFrequencyType("weekly");
                      }}
                      id={"weekly"}
                      checked={frequencyType === "weekly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Weekly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
              <Grid xs={6}>
                <label htmlFor={"monthly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"monthly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "monthly" }}
                      onChange={() => {
                        setFrequencyType("monthly");
                      }}
                      id={"monthly"}
                      checked={frequencyType === "monthly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Monthly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
              <Grid xs={6}>
                <label htmlFor={"bimonthly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"bimonthly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "bimonthly" }}
                      onChange={() => {
                        setFrequencyType("bimonthly");
                      }}
                      id={"bimonthly"}
                      checked={frequencyType === "bimonthly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Bi-monthly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
              <Grid xs={6}>
                <label htmlFor={"quarterly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"quarterly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "quarterly" }}
                      onChange={() => {
                        setFrequencyType("quarterly");
                      }}
                      id={"quarterly"}
                      checked={frequencyType === "quarterly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Quarterly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
            </Grid>

            {frequencyType == "weekly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Weekly">Weekly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Weekly"
                    label="Weekly"
                    id="Weekly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="weekly,Monday">Monday</MenuItem>
                    <MenuItem value="weekly,Tuesday">Tuesday</MenuItem>
                    <MenuItem value="weekly,Wednesday">Wednesday</MenuItem>
                    <MenuItem value="weekly,Thursday">Thursday</MenuItem>
                    <MenuItem value="weekly,Friday">Friday</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}

            {frequencyType == "monthly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Monthly">Monthly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Monthly"
                    label="Monthly"
                    id="Monthly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="monthly,1">
                      1st day of every Month
                    </MenuItem>
                    <MenuItem value="monthly,15">
                      15th day of every Month
                    </MenuItem>
                    <MenuItem value="monthly,31">
                      Last day of every Month
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}

            {frequencyType == "bimonthly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Bimonthly">Bi-Monthly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Bimonthly"
                    label="Bimonthly"
                    id="Bimonthly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="bimonthly,1">
                      1st day every two Months
                    </MenuItem>
                    <MenuItem value="bimonthly,15">
                      15th day every two Months
                    </MenuItem>
                    <MenuItem value="bimonthly,31">
                      Last day every two months
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}

            {frequencyType == "quarterly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Quarterly">Quarterly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Quarterly"
                    label="Quarterly"
                    id="Quarterly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="quarterly,1">
                      1st day of January, April, July and October
                    </MenuItem>
                    <MenuItem value="quarterly,15">
                      15th day of January, April, July and October
                    </MenuItem>
                    <MenuItem value="quarterly,31">
                      Last day of January, April, July and October
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}
            {isSavingSyncSettings || isLoadingSyncInfo ? (
              <MDBox mt={3}>
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              </MDBox>
            ) : (
              <MDButton
                type="submit"
                color="success"
                disabled={syncRecords && syncRecords.lastSyncAt == null}
                onClick={saveSyncSettings}
                sx={{ padding: "12px 20px", marginTop: "1rem" }}
              >
                {syncRecords && syncRecords.lastSyncAt == null
                  ? "Initial asset sync required"
                  : "Save Sync Frequency Settings"}
              </MDButton>
            )}

            {syncRecords ? (
              <>
                {" "}
                <MDTypography variant="h6" color="dark" mt={4}>
                  Last sync:{" "}
                  {syncRecords.lastSyncAt
                    ? syncRecords.lastSyncAt
                    : "No initial sync yet"}
                </MDTypography>
                <MDTypography variant="h6" color="dark" mt={1}>
                  {syncRecords.numberOfAssetsSynced
                    ? "Synced " +
                      syncRecords.numberOfAssetsSynced +
                      " asset records"
                    : "No records synced"}
                </MDTypography>
                <MDTypography variant="h6" color="success" mt={1}>
                  {syncRecords.documentLastMajorVersion ? (
                    <MDButton
                      onClick={openAssetDocument}
                      variant="gradient"
                      color="dark"
                    >
                      View document{" "}
                    </MDButton>
                  ) : (
                    "N/A"
                  )}
                </MDTypography>
              </>
            ) : (
              ""
            )}
          </Card>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Card
            sx={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {assetData.length == 0 ? (
              <>
                {/* preview assets image and button */}
                <MDBox mb={2}>
                  <img src={assetImageLogo} width="90px" alt="" />
                </MDBox>
                <MDBox>
                  <MDButton
                    type="submit"
                    color="success"
                    onClick={fetchAssetsFromCyberCNS}
                    sx={{ padding: "12px 20px", margin: "0px 5px" }}
                  >
                    Sync | Preview and Import Assets
                  </MDButton>
                </MDBox>
                {isFetchingAssets ? (
                  <MDBox mt={2}>
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  </MDBox>
                ) : (
                  ""
                )}
                {/* preview assets image and button end*/}
              </>
            ) : (
              <TableContainer sx={{ padding: "1rem" }}>
                {isImportingAssets ? (
                  <LoadingSpinner color="success" size="lg" />
                ) : (
                  <MDButton
                    type="submit"
                    color="success"
                    onClick={importAssets}
                    sx={{ padding: "12px 20px", margin: "0px 5px" }}
                  >
                    Import Assets
                  </MDButton>
                )}
                <Table sx={{ color: "text.main" }}>
                  <TableRow>
                    <TableCell>
                      <b>Hardware Name</b>
                    </TableCell>
                    <TableCell>
                      <b>IP </b>
                    </TableCell>
                    <TableCell>
                      <b>Hostname</b>
                    </TableCell>
                    <TableCell>
                      <b>Manufacturer Serial Number</b>
                    </TableCell>
                    <TableCell>
                      <b>Security Grade</b>
                    </TableCell>
                    <TableCell>
                      <b>AD Check</b>
                    </TableCell>
                    <TableCell>
                      <b>Asset Type</b>
                    </TableCell>
                    <TableCell>
                      <b>Hardware Model</b>
                    </TableCell>
                    <TableCell>
                      <b>OS Name</b>
                    </TableCell>
                    <TableCell>
                      <b>OS Version</b>
                    </TableCell>
                    <TableCell>
                      <b>Created</b>
                    </TableCell>
                    <TableCell>
                      <b>Memory</b>
                    </TableCell>
                    <TableCell>
                      <b>CPU Core</b>
                    </TableCell>
                  </TableRow>

                  <TableBody>
                    {paginatedAssetData.map((asset, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            "& th, & td": {
                              color: "text.main",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {asset.name == "" ? "-" : asset?.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {asset?.ip}
                          </TableCell>
                          <TableCell>{asset?.host_name}</TableCell>
                          <TableCell>
                            {asset?.serial_number == ""
                              ? "-"
                              : asset?.serial_number}
                          </TableCell>
                          <TableCell>
                            {asset?.security_grade == ""
                              ? "-"
                              : asset?.security_grade}
                          </TableCell>
                          <TableCell>
                            {asset?.ad_check == "" ? "-" : asset?.ad_check}
                          </TableCell>
                          <TableCell>
                            {" "}
                            {asset?.asset_type == "" ? "-" : asset?.asset_type}
                          </TableCell>
                          <TableCell>
                            {" "}
                            {asset?.hardware_model == ""
                              ? "-"
                              : asset?.hardware_model}
                          </TableCell>
                          <TableCell>{asset?.os_name}</TableCell>
                          <TableCell>{asset?.os_version}</TableCell>
                          <TableCell>{asset?.created}</TableCell>
                          <TableCell>{asset.physical_memory}</TableCell>
                          <TableCell>{asset?.cpu_core}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {/* Pagination Controls */}
                {pageCount > 1 && (
                  <Box display="flex" justifyContent="space-between" my={2}>
                    <MDBox mb={{ xs: 3, sm: 0 }}>
                      <MDTypography
                        variant="button"
                        color="secondary"
                        fontWeight="regular"
                      >
                        Showing {entriesStart} to {entriesEnd} of{" "}
                        {totalAssetsFetched} entries
                      </MDTypography>
                    </MDBox>
                    <Pagination
                      count={pageCount}
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                    />
                  </Box>
                )}
              </TableContainer>
            )}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default CyberCNSAssetSyncSection;
