import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import EditConnectWiseClientFormErrorLi from "./EditConnectWiseClientFormErrorLi";

const EditConnectWiseClientFormErrorsBlock = (props) => {
  // return (
  //   <Stack sx={{ width: "100%" }} spacing={2}>
  //     <Alert severity="error">This is an error alert — check it out!</Alert>
  //   </Stack>
  // );

  const renderErrors = () =>
    props.objEditConnectWiseClientFormErrors
      ? Object.entries(props.objEditConnectWiseClientFormErrors).map(
          ([key, value], i) => (
            <EditConnectWiseClientFormErrorLi key={key} value={value} />
          )
        )
      : null;
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity="error">
        {renderErrors(props.objEditConnectWiseClientFormErrors)}
      </Alert>
    </Stack>
  );
};

export default EditConnectWiseClientFormErrorsBlock;
