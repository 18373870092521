import DefaultCell from "views/components/TableCells/DefaultCell";
import TemplateNameBtnTr from "./TemplateNameBtnTr";
import TemplateApproveBtnTr from "./TemplateApproveBtnTr";
import TemplateRejectBtnTr from "./TemplateRejectBtnTr";
import MDBox from "components/MDBox";

const SharedTemplatesDataTableData = (objUser, reloadFunc) => ({
  columns: [
    {
      Header: "Name",
      accessor: "name",
      width: "25%",
      Cell: ({ value, column, row }) => (
        <TemplateNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      width: "30%",
    },
    {
      Header: "Owner",
      accessor: "created_by",
      width: "15%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      width: "15%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ value, column, row }) => (
        <MDBox display="flex">
          <TemplateApproveBtnTr
            value={value}
            column={column}
            row={row}
            reloadFunc={reloadFunc}
            objUser={objUser}
          />
          <TemplateRejectBtnTr
            value={value}
            column={column}
            row={row}
            reloadFunc={reloadFunc}
            objUser={objUser}
          />
        </MDBox>
      ),
    },
  ],
  rows: [],
});

export default SharedTemplatesDataTableData;
