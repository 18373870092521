/**
 * This component is used to display a modal with a list of the available the merge tags
 */

import { useState } from "react";
import MDTypography from "components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const MergeTagsInfo = ( { mergeTags } ) => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
        <Tooltip title="Available Merge Tags" placement="bottom" onClick={handleClickOpen}>
            <InfoOutlined color="icon" sx={{ ml: "0.5em"}}/>
        </Tooltip>
        <Dialog onClose={handleClose} open={open} fullWidth={false} maxWidth="sm">
            <DialogTitle sx={{ m: 0, p: 2 }}>Available Merge Tags</DialogTitle>
            <DialogContent dividers>
                <ul>
                {mergeTags?.global?.map((tag) => {
                    return <li key={ `global-${tag.name}` }>
                        <MDTypography variant="span" fontWeight="bold">
                            &#123;&#123; {tag.name} &#125;&#125;
                        </MDTypography>: {tag.description}</li>;
                })}
                {mergeTags?.company?.map((tag) => {
                    return <li key={ `company-${tag.name}` }>
                        <MDTypography variant="span" fontWeight="bold">
                            &#123;&#123; {tag.name} &#125;&#125;
                        </MDTypography>{tag.description?": "+tag.description:""}</li>;
                })}
                </ul>
            </DialogContent>
        </Dialog>
        </>
    );
};

export default MergeTagsInfo;
