// prop-types is a library for typechecking of props

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// @mui icons
import { useNavigate, useLocation } from "react-router-dom";
const CustomerNameBtnTr = (props) => {
  const { value, column, row } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const routeDictionary = location.state?.routeDictionary ?? {};
  const btnOnClickClientName = (objClientData) => {
    routeDictionary[objClientData.element.id] = value;
    // navigate(`/clients/${objClientData.element.id}/contacts`, { state: { routeDictionary } });
    navigate(`/clients/${objClientData.element.id}/policies`, {
      state: { routeDictionary },
    });
  };

  return (
    // <MDButton variant="text" onClick={() => btnOnClickClientName(row.original)}>
    <MDButton variant="text">
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default CustomerNameBtnTr;
