// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { approveAssessmentTemplateShare } from "features/company/superAdminActions";
import { useConfirm } from "material-ui-confirm";
import { showMsg } from "utils/general";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";

const TemplateApproveBtnTr = (props) => {
  const { value, column, row, reloadFunc, objUser } = props;
  const [isApproving, setIsApproving] = useState(false);
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const approveTemplateAction = async () => {
    try {
      await confirm({
        description:
          "Are you sure you want to approve this template share request? ",
        confirmationButtonProps: {
          color: "success",
          variant: "contained",
          sx: {
            backgroundColor: "#4CAF50",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1b5e20",
              color: "#fff",
              boxShadow:
                "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
        cancellationButtonProps: {
          color: "error",
          variant: "contained",
          sx: {
            backgroundColor: "#d32f2f",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#d32f2f",
              color: "#fff",
              boxShadow:
                "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
      });
    } catch (error) {
      return;
    }

    setIsApproving(true);
    try {
      await dispatch(
        approveAssessmentTemplateShare({ objUser, templateId: row.original.id })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          showMsg("success", "Template share request approved.");
          reloadFunc();
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsApproving(false);
          console.log(rejectedValueOrSerializedError.response);
          showMsg("error", "Error sharing template. Please, try again.");
        });
      // } catch (error) {
      //   console.log(error);
      //   showMsg("error", "Error deleting template. Please, try again.");
    } finally {
      setIsApproving(false);
    }
  };

  return isApproving ? (
    <LoadingSpinner subClass="text-center" color="success" size="sm" />
  ) : (
    <>
      {(row.original.status == "Pending Approval" ||
        row.original.status == "Denied") && (
        <MDButton
          onClick={approveTemplateAction}
          variant="gradient"
          color="success"
          sx={{ marginLeft: "1rem" }}
        >
          <MDBox color="white" display="flex" alignItems="center">
            <Icon sx={{ fontWeight: "bold" }}>check</Icon>
            <MDTypography
              variant="p"
              fontWeight="medium"
              color="white"
              sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
            >
              Approve
            </MDTypography>
          </MDBox>
        </MDButton>
      )}
    </>
  );
};

export default TemplateApproveBtnTr;
