// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// @mui icons
import { useLocation, useNavigate } from "react-router-dom";
const TemplateNameBtnTr = (props) => {
  const { value, row } = props;
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const btnOnClickTemplateName = (objTemplateData) => {
    routeDictionary[objTemplateData.element.id] = value;
    navigate(`/assessments/templates/${objTemplateData.element.id}`, {
      state: { routeDictionary },
    });
  };

  const disabled =
    row.original.subscription_category === "plus" &&
    row.original.subscription_package !== "plus";

  return (
    <MDButton
      variant="text"
      onClick={() => btnOnClickTemplateName(row.original)}
      disabled={disabled}
    >
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default TemplateNameBtnTr;
