import React from "react";
import { Pagination } from "@mui/material";

function PaginationComponent(props) {
  const { totalPages, currentPage, onPageChange } = props;

  return (
    <Pagination count={totalPages} page={currentPage} onChange={onPageChange} />
  );
}

export default PaginationComponent;
