import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import SaveConnectWiseApiKeysForm from "./components/SaveConnectWiseApiKeysForm";

import Tooltip from "@mui/material/Tooltip";
import borders from "assets/theme/base/borders";
import masterCardLogo from "assets/images/logos/mastercard.png";
const SaveConnectWiseApiKeys = (props) => {
  const { borderWidth, borderColor } = borders;
  const navigate = useNavigate();
  const { objUser } = props;
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const btnOnClickYesImportCustomers = () => {
    navigate("/connections/connectwise");
  };
  useEffect(() => {
    if (
      objUser.user.role.name != "admin" &&
      objUser.user.role.name != "company_user"
    ) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      {/* <Card> */}
      <Grid
        my={3}
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Grid item xs={12} lg={12}>
          <SaveConnectWiseApiKeysForm />
        </Grid>
      </Grid>
      {/* </Card> */}
    </DashboardLayout>
  );
};

export default SaveConnectWiseApiKeys;
