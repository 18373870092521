import { useState } from "react";
import { useDispatch } from "react-redux";
import { getWispPDF } from "features/wisps/wispActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";

function useWispPDFDownloader() {
  const dispatch = useDispatch();
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);

  const downloadWispPDF = (objUser = null, wispId, params) => {
    setIsLoadingPDF(true);

    dispatch(getWispPDF({ objUser, wispId, params }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingPDF(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          const exportType = params?.exportType ?? "pdf";
          //  console.log(params?.pdf_display_settings);
          if (exportType == "pdf") {
            link.setAttribute("download", `${wispId}.pdf`);
          } else {
            link.setAttribute("download", `${wispId}.docx`);
          }
          document.body.appendChild(link);
          link.click();
        } else {
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingPDF(false);
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  return { downloadWispPDF, isLoadingPDF };
}

export default useWispPDFDownloader;
