/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// ProductsList page components
import DefaultCell from "views/components/TableCells/DefaultCell";

const dataTableData = {
  columns: [
    {
      Header: "name",
      accessor: "customerName",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "address",
      accessor: "address",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "city",
      accessor: "city",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "state",
      accessor: "state",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "zip",
      accessor: "zip",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],

  // rows: [
  //   {
  //     customerName: "John Michael",
  //     address: "8502 Preston Rd. Inglewood, Maine 98380",
  //     city: "Maine",
  //     state: "United States",
  //     zip: "43431",
  //   },
  //   {
  //     customerName: "Alexa Liras",
  //     address: "3517 W. Gray St. Utica, Pennsylvania 57867",
  //     city: "Pennsylvania",
  //     state: "United States",
  //     zip: "93021",
  //   },
  //   {
  //     customerName: "Laurent Perrier",
  //     address: "3891 Ranchview Dr. Richardson, California 62639",
  //     city: "California",
  //     state: "United States",
  //     zip: "10392",
  //   },
  //   {
  //     customerName: "Michael Levi",
  //     address: "2464 Royal Ln. Mesa, New Jersey 45463",
  //     city: "New Jersey",
  //     state: "United States",
  //     zip: "34002",
  //   },
  //   {
  //     customerName: "Richard Gran",
  //     address: "2715 Ash Dr. San Jose, South Dakota 83475",
  //     city: "South Dakota",
  //     state: "United States",
  //     zip: "91879",
  //   },
  //   {
  //     customerName: "Miriam Eric",
  //     address: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
  //     city: "Illinois",
  //     state: "United States",
  //     zip: "23042",
  //   },
  //   {
  //     customerName: "Miriam Eric",
  //     address: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
  //     city: "Illinois",
  //     state: "United States",
  //     zip: "23042",
  //   },
  //   {
  //     customerName: "Miriam Eric",
  //     address: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
  //     city: "Illinois",
  //     state: "United States",
  //     zip: "23042",
  //   },
  //   {
  //     customerName: "Miriam Eric",
  //     address: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
  //     city: "Illinois",
  //     state: "United States",
  //     zip: "23042",
  //   },
  //   {
  //     customerName: "Miriam Eric",
  //     address: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
  //     city: "Illinois",
  //     state: "United States",
  //     zip: "23042",
  //   },
  //   {
  //     customerName: "Miriam Eric",
  //     address: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
  //     city: "Illinois",
  //     state: "United States",
  //     zip: "23042",
  //   },
  // ],
};

export default dataTableData;
