export const objAllIndustries = [
  { label: "Banking", id: "BA" },
  { label: "Colleges or Universities", id: "CO" },
  { label: "Financial Services", id: "FS" },
  { label: "Contracting, Data Warehousing", id: "CD" },
  { label: "Healthcare", id: "HC" },
  { label: "Hospitals", id: "HP" },
  { label: "Industrial Manufacturing", id: "IM" },
  { label: "Manufacturing", id: "MG" },
  { label: "MSPs", id: "MP" },
  { label: "MSSPs", id: "MS" },
  { label: "Municipalities", id: "MS" },
  { label: "Municipalities - Federal", id: "MF" },
  { label: "Professional Services", id: "PS" },
  { label: "Real Estate Commercial", id: "RC" },
  { label: "Retail", id: "RL" },
  { label: "Schools", id: "SC" },
  { label: "Services - Misc", id: "SM" },
  { label: "Social Services", id: "SS" },
  { label: "Software Companies", id: "SM" },
  { label: "Technology", id: "TG" },
  { label: "Other", id: "OT" },
];
