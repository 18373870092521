import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

export const fetchClientLeaderboard = createAsyncThunk(
  "leaderboard/fetchClientLeaderboard",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
          `${BACKEND_API_URL}${VERSION_SERVER}/gamification/client-leaderboard`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
