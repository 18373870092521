import React, { Fragment } from "react";
const SignUpCreateNewOrganizationFormErrorLi = (props) => {
  return (
    <Fragment>
      {props.value}
      <br />
    </Fragment>
  );
};
export default SignUpCreateNewOrganizationFormErrorLi;
