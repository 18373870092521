import MDBox from "components/MDBox";
// @mui icons
import { Fragment, useState, useEffect } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";

import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import { companyUsersList } from "features/company/companiesActions";
import { updateTaskListItem } from "features/company/taskListActions";

const TaskListItemResponsibilityDropdown = (props) => {
  const { row, objUser, options, field } = props;

  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [taskListItemsUsers, setTaskListItemsUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (taskListItemsUsers.length > 0) {
      const foundUser = taskListItemsUsers
        .map((user) => ({
          label: `${user.name} ${user.last_name}`,
          value: user.id,
        }))
        .find((option) => option.value === row.original.responsible_user_id);
      setCurrentValue(foundUser || null);
    }
  }, [taskListItemsUsers, row.original.responsible_user_id]);

  useEffect(() => {
    setIsFetchingUsers(true);
    const getUsers = async () => {
      try {
        const response = await dispatch(
          companyUsersList({
            objUser,
            _per_page: 1000,
            _page: 1,
          })
        );
        if (response.payload.status === 200) {
          setTaskListItemsUsers(response.payload.data.users);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      } catch (error) {
        showMsg("error", "Something went wrong, please try again");
      } finally {
        setIsFetchingUsers(false);
      }
    };
    getUsers();
  }, [dispatch, objUser]);

  const update = (value) => {
    setLoading(true);
    const objData = {
      responsible_user_id: value.value,
      id: row.original.id,
    };

    dispatch(
      updateTaskListItem({
        objUser,
        objData,
        taskListId: row.original.task_list_id,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 201) {
          setLoading(false);
          showMsg("success", "TaskListLItem updated successfully");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized ");
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading || isFetchingUsers ? (
        <LoadingSpinner subClass="text-center" color="success" size="sm" />
      ) : (
        <MDBox sx={{ width: "140px" }}>
          <Autocomplete
            name="value"
            disableClearable
            value={currentValue}
            options={
              taskListItemsUsers
                ? taskListItemsUsers.map((user) => ({
                    label: `${user.name} ${user.last_name}`,
                    value: user.id,
                  }))
                : []
            }
            getOptionLabel={(option) =>
              option ? option.label : "Unknown User"
            }
            renderInput={(params) => (
              <MDInput {...params} variant="standard" label="" fullWidth />
            )}
            onChange={(event, value) => {
              console.log(value, event.target.value);
              setCurrentValue(value);
              update(value);
            }}
          />
        </MDBox>
      )}
    </Fragment>
  );
};

export default TaskListItemResponsibilityDropdown;
