import React, { Fragment } from "react";
// import Alert from "@mui/material/Alert";
// import Stack from "@mui/material/Stack";
const EditConnectWiseClientFormErrorLi = (props) => {
  return (
    <Fragment>
      {props.value}
      <br />
    </Fragment>
  );
};
export default EditConnectWiseClientFormErrorLi;
