/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_ENDPOINT } from "components/utils/constants/misc";

const getCompanyID = (objUser) => {
  return objUser.user.company_id;
};

export const createNewPolicy = createAsyncThunk(
  "policy/create",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deployPoliciesToClients = createAsyncThunk(
  "policy/create",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/deploy`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deletePolicy = createAsyncThunk(
  "policy/delete",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, objPostData } = values;

      const res = await axios.delete(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/${policyId}`,
        {
          data: objPostData,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchPolicy = createAsyncThunk(
  "policy/create",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/${policyId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const sendDraftAutosave = createAsyncThunk(
  "policy/sendDraftAutosave",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, ...objPostData } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policyId}/editor-delta`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const markDocumentSectionAsComplete = createAsyncThunk(
  "policy/markDocumentSectionAsComplete",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, ...sectionToUpdate } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policyId}/mark-section-as-complete`,
        sectionToUpdate.sectionToUpdate
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const markAllDocumentSectionsAsComplete = createAsyncThunk(
  "policy/markAllDocumentSectionsAsComplete",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, policyDocId, completed } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policyId}/mark-all-sections-as-complete`,
        { policyDocId: policyDocId, completed: completed }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const sendComments = createAsyncThunk(
  "policy/sendComments",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, policyDocumentId, ...objPostData } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policyId}/documents/${policyDocumentId}/editor-comments`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updatePolicy = createAsyncThunk(
  "policy/updatePolicy",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, ...objPostData } = values;

      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/${policyId}/`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const newVersion = createAsyncThunk(
  "policy/new-version",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policyId}/versions`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const copyAsNewVersion = createAsyncThunk(
  "policy/copyAsNewVersion",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, row } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policyId}/versions/duplicate-version/${row.id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPolicyVersion = createAsyncThunk(
  "policy/getPolicyVersion",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, objPostData } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policyId}/editor-delta`,
        { params: objPostData }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getSectionVersion = createAsyncThunk(
  "policy/getSectionVersion",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, objPostData } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policyId}/section-delta`,
        { params: objPostData }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const sendSectionDraftAutosave = createAsyncThunk(
  "policy/sendSectionDraftAutosave",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, ...objPostData } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policyId}/section-delta`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPolicies = createAsyncThunk(
  "policies/get",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/?per_page=${_per_page}&page=${_page}`
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const policyDocumentSaveReviewers = createAsyncThunk(
  "policy/save-reviewers",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/${
          objPostData.policy_id
        }/documents/${objPostData.policy_document_id}/reviewers`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPolicyDataToReview = createAsyncThunk(
  "policy/get-data-to-review",
  async (values, thunkApi) => {
    try {
      const { objUser, params } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/${
          params.policy_id
        }/review/${params.review_id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPolicyDocumentPDF = createAsyncThunk(
  "policy/get-data-to-review-pdf",
  async (values, thunkApi) => {
    try {
      const { objUser, params } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/${
          params.policy_id
        }/review/${params.review_id}/pdf`,
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPolicyPreview = createAsyncThunk(
  "policy/get-policy-preview",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(values.objUser)}/policies/${
          values.policyId
        }/documents/${values.policyDocumentId}/preview`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPolicyDraftDocumentPDF = createAsyncThunk(
  "policy/get-data-to-review-pdf",
  async (values, thunkApi) => {
    try {
      const { objUser, params } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/${
          params.policy_id
        }/documents/${params.document_id}/draft-pdf`,
        { responseType: "blob", params: params.pdf_display_settings }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const approvePolicyReviewDocument = createAsyncThunk(
  "policy/policy-review-approve",
  async (values, thunkApi) => {
    try {
      const { objUser, params } = values;

      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/${
          params.policy_id
        }/review/${params.review_id}/approve`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const rejectPolicyReviewDocument = createAsyncThunk(
  "policy/policy-review-reject",
  async (values, thunkApi) => {
    // return false;
    try {
      const { objUser, params, postData } = values;

      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/${
          params.policy_id
        }/review/${params.review_id}/reject`,
        postData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const policyDocumentUpdateState = createAsyncThunk(
  "policy/save-reviewers",
  async (values, thunkApi) => {
    try {
      const { objUser, policy_id, objPostData } = values;

      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policy_id}/documents/${
          objPostData.policy_document_id
        }/update-state`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const documentUpdateState = createAsyncThunk(
  "policy/document-update-state",
  async (values, thunkApi) => {
    try {
      const { objUser, params } = values;

      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/policies/${
          params.policy_id
        }/documents/${params.policy_document_id}/update-state`,
        params
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchPolicyDocument = createAsyncThunk(
  "policy/create",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, version } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policyId}/versions/${version}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchPolicyDocumentIfNewer = createAsyncThunk(
  "policy/create",
  async (values, thunkApi) => {
    try {
      const { objUser, policyId, version } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/policies/${policyId}/versions/${version}/if-newer?timestamp=${
          values.timestamp
        }`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getCampaigns = createAsyncThunk(
  "policy/get-campaigns",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/campaigns/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getCampaignsByPolicyDocument = createAsyncThunk(
  "policy/get-campaigns-by-policy-document",
  async (values, thunkApi) => {
    try {
      const { objUser, objPolicyDocument } = values;
      // `${BASE_ENDPOINT}${this_campaign_server}/policyDocument/${objPolicyDocument}`
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/campaigns/policyDocument/${objPolicyDocument}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewCampaign = createAsyncThunk(
  "policy/create-campaign",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/campaigns/`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const loadCampaign = createAsyncThunk(
  "policy/load-campaign",
  async (values, thunkApi) => {
    try {
      const { objUser, campaignId } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/campaigns/${campaignId}/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const resendCampaign = createAsyncThunk(
  "policy/resend-campaign",
  async (values, thunkApi) => {
    try {
      const { objUser, campaignId, contactId } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/campaigns/${campaignId}/resend`,
        { contactId }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
