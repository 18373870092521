import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import { convertToClientUser } from "features/company/companiesActions";
const objInitialFormValues = {
  txtRoleRegisterAccess: 0,
  txtAssessmentAccess: 0,
};

function ConvertToClientUserDialog({
  open,
  onClose,
  contactId,
  objInitialValues,
  objUser,
  setIsMSPUserContact,
  setIsClientUserContact,
  setAssessmentAccess,
  setRiskRegisterAccess,
}) {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [currentClient, setCurrentClient] = useState("");
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const objPostData = {
      contactId,
      assessment_access: values.txtAssessmentAccess,
      risk_register_access: values.txtRoleRegisterAccess,
      clientId: currentClient.id,
    };
    setIsSubmiting(true);
    dispatch(convertToClientUser({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsSubmiting(false);
        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg("success", "User changed to Client User successfully");
          setIsClientUserContact(true);
          setIsMSPUserContact(false);
          setAssessmentAccess(values.txtAssessmentAccess);
          setRiskRegisterAccess(values.txtRoleRegisterAccess);
          onClose();
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsSubmiting(false);
        showMsg("error", "Please correct the errors.");
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        if (objErrors !== undefined) {
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      });
  };

  useEffect(() => {
    let current_client = objUser.user.list_of_clients.find((item) => {
      return item.id === objUser.user.current_client_id;
    });
    setCurrentClient(current_client);
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Convert to Client User</DialogTitle>
      <Formik initialValues={objInitialFormValues} onSubmit={onSubmitForm}>
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddToAudienceForm" autoComplete="off">
            <DialogContent>
              <Grid container>
                <Grid xs={12}>
                  <MDTypography
                    // fontSize={{ xs: "1rem", sm: "1rem" }}
                    // fontWeight="light"
                    color="secondary"
                  >
                    Are you sure you want to convert{" "}
                    {objInitialValues.txtFirstName}{" "}
                    {objInitialValues.txtLastName} ({objInitialValues.txtEmail})
                    to a Client User for {currentClient.name}? They will be able
                    to access information for only this client.
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sx={{ ml: 2 }}>
                  <MDBox mb={0} mt={0}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.txtAssessmentAccess === 1}
                          name="assessmentAccess"
                        />
                      }
                      label="Assessment Access"
                      onChange={() => {
                        if (values.txtAssessmentAccess === 1) {
                          setFieldValue("txtAssessmentAccess", 0);
                        } else {
                          setFieldValue("txtAssessmentAccess", 1);
                        }
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sx={{ ml: 2 }}>
                  <MDBox mb={0} mt={0}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.txtRoleRegisterAccess === 1}
                          name="riskRegisterAccess"
                        />
                      }
                      label="Risk Register Access"
                      onChange={() => {
                        if (values.txtRoleRegisterAccess === 1) {
                          setFieldValue("txtRoleRegisterAccess", 0);
                        } else {
                          setFieldValue("txtRoleRegisterAccess", 1);
                        }
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {isSubmiting === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Ok
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default ConvertToClientUserDialog;
