import { createSlice } from "@reduxjs/toolkit";
import { setStripeSession, retrievePaymentMethodFromSession } from "./stripeCheckOutActions";
// import { getUserDetails, setStripeSession,  } from "./userActions";

// initialize userToken from local storage
// const userToken = localStorage.getItem("userToken") ? localStorage.getItem("userToken") : null;

const initialState = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
};

const stripeCheckOutSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // signupUser: (state,action) => {
    //   localStorage.removeItem("userToken"); // delete token from storage
    //   state.loading = false;
    //   state.userInfo = null;
    //   state.userToken = null;
    //   state.error = null;
    // },
  },
  extraReducers: {
    // register user
    [setStripeSession.pending]: (state) => {
      state.loading = true;
      state.objSetStripeSessionData = null;
    },
    [setStripeSession.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objSetStripeSessionData = payload;
    },
    [setStripeSession.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objSetStripeSessionData = payload;
    },
    //
    [retrievePaymentMethodFromSession.pending]: (state) => {
      state.loading = true;
      state.objRetrievePaymentMethodFromSessionData = null;
    },
    [retrievePaymentMethodFromSession.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objRetrievePaymentMethodFromSessionData = payload;
    },
    [retrievePaymentMethodFromSession.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objRetrievePaymentMethodFromSessionData = payload;
    },
  },
});

// export const { logout } = stripeCheckOutSlice.actions;

export default stripeCheckOutSlice.reducer;
