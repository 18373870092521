// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

import * as Yup from "yup";

import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate, useLocation } from "react-router-dom";
// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";
import axios from "axios";
// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import EditClientContactByClientContactIdFormFormikWrapper from "./EditClientContactByClientContactIdFormFormikWrapper";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import EditClientContactByClientContactIdFormErrorsBlock from "./EditClientContactByClientContactIdFormErrorsBlock";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
// import { storeAddCustomerClient } from "features/company/clientActions";
import { updateClientContactByClientId } from "features/company/clientContactsActions";
import ConvertToMSPUserDialog from "./ConvertToMSPUserDialog";
import ConvertToClientUserDialog from "./ConvertToClientUserDialog";

const EditClientContactByClientContactIdForm = (props) => {
  const {
    client_contact_id,
    objUser,
    isClientUserContact,
    isMSPUserContact,
    objInitialValues,
    EditClientContactByClientContactIdFormJWTSchema,
    setIsMSPUserContact,
    setIsClientUserContact,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [objStates, setObjStates] = useState(objUsStates);
  const [isLoading, setIsLoading] = useState(false);
  const [riskRegisterAccess, setRiskRegisterAccess] = useState(
    objInitialValues.txtRoleRegisterAccess
  );
  const [assessmentAccess, setAssessmentAccess] = useState(
    objInitialValues.txtAssessmentAccess
  );
  const [openConvertToMSPUserDialog, setOpenConvertToMSPUserDialog] =
    useState(false);
  const [openConvertToClientUserDialog, setOpenConvertToClientUserDialog] =
    useState(false);
  const [
    objEditClientContactByClientContactIdFormErrors,
    setObjEditClientContactByClientContactIdFormErrors,
  ] = useState({});

  const onSubmitForm = async (values) => {
    const objPostData = {
      id: client_contact_id,
      first_name: values.txtFirstName,
      last_name: values.txtLastName,
      email: values.txtEmail,
      title: values.txtTitle,
      phone: values.txtPhone,
      address1: values.txtAddress1,
      city: values.txtCity,
      state: values.drpState,
      zip: values.txtZip,
      risk_register_access: values.txtRoleRegisterAccess,
      assessment_access: values.txtAssessmentAccess,
    };
    setObjEditClientContactByClientContactIdFormErrors({});
    setIsLoading(true);
    dispatch(updateClientContactByClientId({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;

        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg("success", "Your Contact has been updated successfully.");
          navigate(`/contacts`, {
            state: { routeDictionary },
          });
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 404 &&
          objErrorRsp.statusText === "Not Found"
        ) {
          showMsg("error", "Invalid Url");
          navigate(`/contacts`, {
            state: { routeDictionary },
          });
        } else {
          setObjEditClientContactByClientContactIdFormErrors(objErrors);
          showMsg("error", "Please correct the errors.");
        }
        // handle result here
      });
  };

  const closeConvertToMSPUserDialog = () => {
    setOpenConvertToMSPUserDialog(false);
  };

  const closeConvertToClientUserDialog = () => {
    setOpenConvertToClientUserDialog(false);
  };

  return (
    <>
      <ConvertToMSPUserDialog
        open={openConvertToMSPUserDialog}
        onClose={closeConvertToMSPUserDialog}
        objInitialValues={objInitialValues}
        contactId={client_contact_id}
        setIsMSPUserContact={setIsMSPUserContact}
        setIsClientUserContact={setIsClientUserContact}
        objUser={props.objUser}
      />

      <ConvertToClientUserDialog
        open={openConvertToClientUserDialog}
        onClose={closeConvertToClientUserDialog}
        objInitialValues={objInitialValues}
        contactId={client_contact_id}
        setIsMSPUserContact={setIsMSPUserContact}
        setIsClientUserContact={setIsClientUserContact}
        objUser={props.objUser}
        setAssessmentAccess={setAssessmentAccess}
        setRiskRegisterAccess={setRiskRegisterAccess}
      />
      <Formik
        initialValues={objInitialValues}
        validationSchema={EditClientContactByClientContactIdFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form
            id={"EditClientContactByClientContactIdForm"}
            autoComplete="off"
          >
            <Card sx={{ height: "100%" }}>
              <MDBox>
                <EditClientContactByClientContactIdFormFormikWrapper
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  objStates={objStates}
                  isClientUserContact={isClientUserContact}
                  assessmentAccess={assessmentAccess}
                  riskRegisterAccess={riskRegisterAccess}
                />

                {objEditClientContactByClientContactIdFormErrors !==
                  undefined &&
                Object.keys(objEditClientContactByClientContactIdFormErrors)
                  .length > 0 ? (
                  <Grid
                    container
                    justifyContent="space-between"
                    pl={5}
                    pr={5}
                    pt={0}
                    pb={5}
                  >
                    <EditClientContactByClientContactIdFormErrorsBlock
                      objEditClientContactByClientContactIdFormErrors={
                        objEditClientContactByClientContactIdFormErrors
                      }
                    />
                  </Grid>
                ) : null}

                {isLoading === false ? (
                  <MDBox
                    sx={{ margin: "0 0 40px -40px" }}
                    width="100%"
                    display="flex"
                    justifyContent={
                      isClientUserContact | isMSPUserContact
                        ? "space-between"
                        : "flex-end"
                    }
                  >
                    {isClientUserContact && (
                      <MDButton
                        type="button"
                        // variant="gradient"
                        color="info"
                        sx={{ padding: "12px 82px", marginLeft: "80px" }}
                        onClick={() => setOpenConvertToMSPUserDialog(true)}
                      >
                        Convert to MSP User
                      </MDButton>
                    )}
                    {isMSPUserContact && (
                      <MDButton
                        type="button"
                        // variant="gradient"
                        color="info"
                        sx={{ padding: "12px 82px", marginLeft: "80px" }}
                        onClick={() => setOpenConvertToClientUserDialog(true)}
                      >
                        Convert to Client User
                      </MDButton>
                    )}
                    <MDButton
                      type="submit"
                      // variant="gradient"
                      color="success"
                      sx={{ padding: "12px 82px" }}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                ) : (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                )}
              </MDBox>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditClientContactByClientContactIdForm;
