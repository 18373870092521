import { useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormField from "layouts/pages/users/new-user/components/FormField";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { setMergeTagValues } from "features/company/mergeTagsActions";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import { TextField } from "@mui/material";

const AddNewPolicyFormJWTSchema = Yup.object().shape({
  txtValue: Yup.string().required("The Value is required."),
});

function EditTagsDialog({ open, onClose, onSubmit, objUser, mergeTag }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const onSubmitForm = async (values) => {
    setIsLoading(true);
    const objPostData = {
      id: mergeTag.id,
      value: values.txtValue,
    };
    dispatch(setMergeTagValues({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit(response.data);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  const objInitialValues = useMemo(
    () => ({
      name: mergeTag?.name,
      description: mergeTag?.description,
      txtValue: mergeTag?.value ?? "",
    }),
    [mergeTag]
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Edit Merge Tag Value</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewPolicyFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="ShareKBSForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <FormField
                  type="text"
                  label={values.description ?? values.name}
                  name="txtValue"
                  value={values.txtValue}
                  placeholder={values.description ?? values.name}
                  error={errors.txtValue && touched.txtValue}
                  success={values.txtValue?.length > 0 && !errors.txtValue}
                />
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Update
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default EditTagsDialog;
