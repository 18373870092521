import MDBox from "components/MDBox";
import { Fragment, useState } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import FormField from "layouts/applications/wizard/components/FormField";

import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import { updateTaskListItem } from "features/company/taskListActions";

const TaskListItemTextField = (props) => {
  const { row, objUser, field } = props;
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState(row.original[field]);
  const dispatch = useDispatch();

  let timerId;

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);

    clearTimeout(timerId);

    timerId = setTimeout(
      () => {
        update(newValue);
      },
      field.includes("date") ? 5000 : 1500
    );
  };

  const update = (value) => {
    let taskListItemId = row.original.id;
    const objData = {
      [field]: value,
      id: taskListItemId,
    };

    dispatch(
      updateTaskListItem({
        objUser,
        objData,
        taskListId: row.original.task_list_id,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 201) {
          setLoading(false);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized ");
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <>
        {loading ? (
          <LoadingSpinner subClass="text-center" color="success" size="sm" />
        ) : (
          <MDBox>
            {field.includes("date") ? (
              <FormField
                inputProps={{ type: "date" }}
                value={currentValue}
                onChange={handleInputChange}
              />
            ) : (
              <FormField
                sx={{ width: "250px" }}
                inputProps={{ type: "text" }}
                value={currentValue}
                onChange={handleInputChange}
              />
            )}
          </MDBox>
        )}
      </>
    </Fragment>
  );
};

export default TaskListItemTextField;
