import { Fragment } from "react";
// react-router-dom components
import { Link as RouterLink } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// import polygonLogo from "assets/images/icons/logo.png";
import polygonLogo from "assets/images/icons/ct-logo-portrait.svg";

import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

const Auth0VerifyEmailAddressSection = (props) => {
  const { isLoading, isError, isSuccess } = props;
  return (
    <Fragment>
      <Card sx={{ marginBottom: "2rem" }}>
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          p="5rem 1rem"
        >
          <MDBox
            sx={{
              width: 200,
              height: 150,
            }}
            mb={5}
            component="img"
            src={polygonLogo}
            alt="Compliance Scorecard logo"
          />

          {isLoading ? (
            <Fragment>
              <MDTypography
                fontSize={{ xs: "1rem", sm: "1.5rem" }}
                fontWeight="regular"
                color="black"
                mt={3}
              >
                We are verifying your email, Please wait while we do that!
              </MDTypography>
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            </Fragment>
          ) : null}
          {isError && isLoading === false ? (
            <MDAlert color="error" dismissible>
              The email verification URL is invalid!
            </MDAlert>
          ) : null}
          {isSuccess && isLoading === false ? (
            <MDAlert color="success" dismissible>
              <MDTypography
                fontWeight="regular"
                color="white"
                textAlign="center"
                // mt="auto"
                mb={4}
              >
                Your email has been verified successfully, You will be
                redirected to setup organization page shortly, Please{" "}
                <MDTypography
                  component={RouterLink}
                  to="/setup-account/step-one"
                  color="info"
                  underline="always"
                >
                  Click here
                </MDTypography>
                , if you have not been redirected shortly.
              </MDTypography>
            </MDAlert>
          ) : null}
        </MDBox>
      </Card>
    </Fragment>
  );
};

export default Auth0VerifyEmailAddressSection;
