import { Fragment, useEffect, useMemo, useState } from "react";
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Autocomplete, Icon } from "@mui/material";

import FormField from "layouts/applications/wizard/components/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardActionButton from "views/components/DashboardActionButton";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DefaultCell from "views/components/TableCells/DefaultCell";
import { useMaterialUIController } from "context";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";

import ActionBtnTr from "./ActionBtnTr";
import TaskListItemTextField from "./TaskListItemTextField";
import TaskListItemResponsibilityDropdown from "./TaskListItemResponsibilityDropdown";
import TaskListItemDropdown from "./TaskListItemDropdown";
import TaskListItemTitleBtnTr from "./TaskListItemTitleBtnTr";
import { createTaskListItem } from "features/company/taskListActions";
import TaskListItemPriorityLevelDropdown from "./TaskListItemPriorityLevelDropdown";

const TaskListItemTable = ({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  totalCount,
  objUser,
  loadMoreItems,
  taskListId,
  perPage,
  _page,
  setPage,
  deleteObj,
  setCurrentTaskListItem,
  setTaskListItemDialogOpen,
  taskListItemsUsers,
  isFetchingUsers,
}) => {
  const [data, setData] = useState(table.rows);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newRow, setNewRow] = useState({
    title: "",
    description: "",
    start_date: "",
    due_date: "",
    responsible_user_id: "",
    priority_level: "",
    status: "",
  });

  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        width: "20%",
        Cell: ({ value, column, row }) => (
          <MDBox
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            <DefaultCell value={value ?? ""} column={column} row={row} />
          </MDBox>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        width: "30%",
        Cell: ({ value, column, row }) => (
          <MDBox
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            {row.original?.status === "Completed" ? (
              <DefaultCell value={value ?? ""} column={column} row={row} />
            ) : (
              <TaskListItemTextField
                value={value ?? ""}
                column={column}
                row={row}
                objUser={objUser}
                field={"description"}
              />
            )}
          </MDBox>
        ),
      },
      // {
      //   Header: "Start Date",
      //   accessor: "start_date",
      //   width: "10%",
      //   Cell: ({ value, column, row }) => (
      //     <>
      //       {row.original?.status === "Completed" ? (
      //         <DefaultCell value={value ?? ""} column={column} row={row} />
      //       ) : (
      //         <TaskListItemTextField
      //           value={value ?? ""}
      //           column={column}
      //           row={row}
      //           objUser={objUser}
      //           field={"start_date"}
      //         />
      //       )}
      //     </>
      //   ),
      // },
      {
        Header: "Due Date",
        accessor: "due_date",
        width: "15%",
        Cell: ({ value, column, row }) => (
          <>
            {row.original?.status === "Completed" ? (
              <DefaultCell value={value ?? ""} column={column} row={row} />
            ) : (
              <TaskListItemTextField
                value={value ?? ""}
                column={column}
                row={row}
                objUser={objUser}
                field={"due_date"}
              />
            )}
          </>
        ),
      },
      {
        Header: "Responsible User",
        accessor: "responsible_user",
        width: "15%",
        Cell: ({ value, column, row }) => (
          <>
            {row.original?.status === "Completed" ? (
              <DefaultCell
                value={row.original?.responsible_user || ""}
                column={column}
                row={row}
              />
            ) : (
              <TaskListItemResponsibilityDropdown
                value={value ? value.name : ""}
                column={column}
                row={row}
                objUser={objUser}
                options={taskListItemsUsers}
                field={"responsible_user"}
              />
            )}
          </>
        ),
      },
      {
        Header: "Priority Level",
        accessor: "priority_level",
        width: "10%",
        Cell: ({ value, column, row }) => (
          <MDBox sx={{ width: "90px" }}>
            {row.original?.status === "Completed" ? (
              <DefaultCell value={value ?? ""} column={column} row={row} />
            ) : (
              <TaskListItemPriorityLevelDropdown
                value={value ?? ""}
                column={column}
                row={row}
                objUser={objUser}
                options={["Low", "Medium", "High", "Highest"]}
                loadMoreItems={loadMoreItems}
                field={"priority_level"}
              />
            )}
          </MDBox>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        width: "10%",
        Cell: ({ value, column, row }) => (
          <MDBox sx={{ width: "110px" }}>
            {row.original.status === "Completed" ? (
              <DefaultCell value={value ?? ""} column={column} row={row} />
            ) : (
              <TaskListItemDropdown
                value={value ?? ""}
                column={column}
                row={row}
                objUser={objUser}
                options={[
                  "Todo",
                  "In Progress",
                  "Under Review",
                  "Completed",
                  "Blocked",
                ]}
                loadMoreItems={loadMoreItems}
                field={"status"}
              />
            )}
          </MDBox>
        ),
      },
      {
        Header: "Actions",
        accessor: "action",
        width: "10%",
        Cell: ({ value, row }) => (
          <ActionBtnTr
            value={value}
            row={row}
            deleteObj={deleteObj}
            setCurrentTaskListItem={setCurrentTaskListItem}
            setTaskListItemDialogOpen={setTaskListItemDialogOpen}
          />
        ),
      },
    ],
    []
  );

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asc";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const handleNewRowChange = (field, value) => {
    setNewRow((prevRow) => ({
      ...prevRow,
      [field]: value,
    }));
  };

  const handleSubmitNewRow = async (values) => {
    const objData = {
      ...newRow,
    };

    setIsLoading(true);
    dispatch(createTaskListItem({ objUser, objData, taskListId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          const addedRow = response.data;
          setData((prevData) => [...prevData, addedRow]);
          setNewRow({
            title: "",
            description: "",
            start_date: "",
            due_date: "",
            responsible_user: "",
            priority_level: "",
            status: "",
          });
          setIsAddingRow(false);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        ) ;
      });
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <Table {...getTableProps()} sx={{ tableLayout: "fixed", width: "100%" }}>
        <MDBox component="thead" sx={{ paddingLeft: 0 }}>
          {headerGroups.map((headerGroup) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              sx={{ paddingLeft: 0 }}
            >
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  key={column.id}
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()} sx={{ marginLeft: 0 }}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <Fragment key={key}>
                <TableRow {...row.getRowProps()} sx={{ marginLeft: 0 }}>
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      key={cell.column.id}
                      noBorder={noEndBorder && data.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              </Fragment>
            );
          })}
          {isLoading ? (
            <LoadingSpinner subClass="text-center" color="success" size="sm" />
          ) : (
            isAddingRow && (
              <TableRow>
                <DataTableBodyCell>
                  <FormField
                    inputProps={{ type: "text" }}
                    value={newRow.title}
                    placeholder="Task List Title"
                    onChange={(e) =>
                      handleNewRowChange("title", e.target.value)
                    }
                  />
                </DataTableBodyCell>
                <DataTableBodyCell>
                  <FormField
                    inputProps={{ type: "text" }}
                    value={newRow.description}
                    placeholder="Task List Description"
                    onChange={(e) =>
                      handleNewRowChange("description", e.target.value)
                    }
                  />
                </DataTableBodyCell>
                <DataTableBodyCell>
                  <FormField
                    inputProps={{ type: "date" }}
                    value={newRow.start_date}
                    onChange={(e) =>
                      handleNewRowChange("start_date", e.target.value)
                    }
                  />
                </DataTableBodyCell>
                <DataTableBodyCell>
                  <FormField
                    inputProps={{ type: "date" }}
                    value={newRow.due_date}
                    onChange={(e) =>
                      handleNewRowChange("due_date", e.target.value)
                    }
                  />
                </DataTableBodyCell>
                <DataTableBodyCell>
                  <Autocomplete
                    disableClearable
                    options={
                      taskListItemsUsers
                        ? taskListItemsUsers.map((user) => ({
                            label: `${user.name} ${user.last_name}`,
                            value: user.id,
                          }))
                        : []
                    }
                    value={
                      taskListItemsUsers
                        ? taskListItemsUsers
                            .map((user) => ({
                              label: `${user.name} ${user.last_name}`,
                              value: user.id,
                            }))
                            .find(
                              (option) =>
                                option.value === newRow.responsible_user_id
                            ) || null
                        : null
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    onChange={(event, newValue) =>
                      handleNewRowChange(
                        "responsible_user_id",
                        newValue ? newValue.value : null
                      )
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        placeholder="Responsible User"
                        sx={{ width: "100px" }}
                      />
                    )}
                  />
                </DataTableBodyCell>
                <DataTableBodyCell>
                  <Autocomplete
                    disableClearable
                    options={["Low", "Medium", "High", "Highest"]}
                    value={
                      newRow.priority_level.charAt(0).toUpperCase() +
                      newRow.priority_level.slice(1)
                    }
                    onChange={(event, value) =>
                      handleNewRowChange("priority_level", value.toLowerCase())
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        placeholder="Priority Level"
                        sx={{ width: "90px" }}
                      />
                    )}
                  />
                </DataTableBodyCell>
                <DataTableBodyCell>
                  <Autocomplete
                    options={[
                      "ToDo",
                      "In Progress",
                      "Under Review",
                      "Completed",
                      "Blocked",
                    ]}
                    value={newRow.status}
                    onChange={(event, value) =>
                      handleNewRowChange("status", value)
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        placeholder="Status"
                        sx={{ width: "110px" }}
                      />
                    )}
                  />
                </DataTableBodyCell>
                <DataTableBodyCell>
                  {isLoading ? (
                    <LoadingSpinner size="lg" />
                  ) : (
                    <>
                      <MDButton
                        type="submit"
                        color="success"
                        sx={{ padding: "12px 12px", marginRight: "10px" }}
                        onClick={handleSubmitNewRow}
                      >
                        Save
                      </MDButton>
                      <MDButton
                        type="button"
                        variant="outlined"
                        color="dark"
                        sx={{ padding: "12px 12px" }}
                        onClick={() => setIsAddingRow(false)}
                      >
                        Cancel
                      </MDButton>
                    </>
                  )}
                </DataTableBodyCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      <MDBox display="flex" justifyContent="end" mt={2}>
        <DashboardActionButton
          action={() => setIsAddingRow(true)}
          btnText="Add New TaskList Item"
          btnIcon="add"
          textColor="white"
          bgColor="success"
        />
      </MDBox>
    </TableContainer>
  );
};

TaskListItemTable.defaultProps = {
  entriesPerPage: { defaultValue: 25, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};
export default TaskListItemTable;
