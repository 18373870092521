import { useState, useEffect } from "react";

// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";
// Image
import bgImage from "assets/images/signBg.jpg";

import polygonLogo from "assets/images/icons/ct-logo-portrait.svg";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const { loginWithRedirect, loginWithPopup } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, []);
  return (
    <IllustrationLayout illustration={bgImage} brand={polygonLogo}>
      {isLoading === false ? null : (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      )}
    </IllustrationLayout>
  );
}

export default SignIn;
