/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ActionBtnTr = (props) => {
  const { row } = props;
  const location = useLocation();
  const routeDictionary = location.state?.routeDictionary ?? {};
  const navigate = useNavigate();

  const btnOnClickConfigureConnector = (data) => {
    routeDictionary[data.id] = props.value;
    let url = "";
    if (data.target === "assets") {
      url = `/settings/ms-graph-assets-connect/${data.id}`;
    } else {
      url = `/settings/ms-graph-email-connect/${data.id}`;
    }
    navigate(url, {
      state: { routeDictionary },
    });
  };

  return (
    <Fragment>
      <MDButton
        color="success"
        onClick={() => btnOnClickConfigureConnector(row.original)}
      >
        <MDTypography
          variant="p"
          fontWeight="medium"
          color="white"
          sx={{ lineHeight: 0, color: "white" }}
        >
          Configure
        </MDTypography>
      </MDButton>
    </Fragment>
  );
};

export default ActionBtnTr;
