// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const SignUpFunnelHeader = (props) => {
  return (
    <MDBox mb={5} textAlign="center">
      <MDTypography variant="h4" fontWeight="bold">
        Setup Your Account
      </MDTypography>
      <MDTypography mb={4} textAlign="center" variant="body2" color="text">
        This information will let us know more about you.
      </MDTypography>
    </MDBox>
  );
};

export default SignUpFunnelHeader;
