import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function ToolTip({ info}) {

  return (
    <Tooltip title={info} placement="right-end">
      <IconButton sx={{ paddingTop: "2px" }}>
        <HelpOutlineIcon color="icon" fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

export default ToolTip;
