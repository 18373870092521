import { Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DefaultCell from "views/components/TableCells/DefaultCell";

const ConnectSecureClientsDataTableData = (objUser, reloadFunc) => ({
  columns: [
    {
      Header: "name",
      accessor: "name",
      Cell: ({ value, column, row }) => (
        <>
          {row.original.duplicate ? (
            <MDBox>
              <DefaultCell value={value} />
              <Typography component="div" fontSize={12} color="red">
                This client is already present
              </Typography>
            </MDBox>
          ) : (
            <DefaultCell value={value} />
          )}
        </>
      ),
    },
    {
      Header: "address",
      accessor: "address_1",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "city",
      accessor: "city",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "state",
      accessor: "state",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "zip",
      accessor: "zip",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
});

export default ConnectSecureClientsDataTableData;
