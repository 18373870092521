import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

// import avater images
import googleAavter from "assets/images/icons/google-avatar.svg";
import microsoftAavter from "assets/images/icons/microsoft-avater.svg";

function Auth() {
  return (
    <>
      <Divider sx={{ height: "100%", opacity: 0.5 }} spacing={2} light>
        Or
      </Divider>
      <MDBox
        component={Link}
        to=""
        display="flex"
        alignItems="center"
        border="0.01rem solid rgb(204, 204, 204)"
        color="black"
        bgColor="white"
        variant="gradient"
        borderRadius="lg"
        opacity={1}
        p={1}
        mb={1}
      >
        <MDAvatar src={googleAavter} alt="Google-Avatar" variant="circular" size="md" />
        <MDTypography pl={2} variant="button" color="black">
          Continue with Google
        </MDTypography>
      </MDBox>
      <MDBox
        component={Link}
        to=""
        display="flex"
        alignItems="center"
        border="0.01rem solid rgb(204, 204, 204)"
        color="black"
        bgColor="white"
        variant="gradient"
        borderRadius="lg"
        opacity={1}
        p={1}
      >
        <MDAvatar src={microsoftAavter} alt="Microsoft-Avatar" variant="circular" size="md" />
        <MDTypography pl={2} variant="button" color="black">
          Continue with Microsoft
        </MDTypography>
      </MDBox>
    </>
  );
}
export default Auth;
