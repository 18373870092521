/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import { createSlice } from "@reduxjs/toolkit";
import {
  inviteUserToCompany,
  validateUserInvitationToCompany,
  acceptUserInvitationToCompany,
  companyUsersList,
  clearCompanyUsersList,
  deleteCompanyUser,
  deleteCompanyUserFromStore,
} from "./companiesActions";

const initialState = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
};

const companiesSlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    // signupUser: (state,action) => {
    //   localStorage.removeItem("userToken"); // delete token from storage
    //   state.loading = false;
    //   state.userInfo = null;
    //   state.userToken = null;
    //   state.error = null;
    // },
  },
  extraReducers: {
    // inviteUserToCompany
    [inviteUserToCompany.pending]: (state) => {
      state.loading = true;
    },
    [inviteUserToCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objInviteUserToCompanyFormSuccess = payload;
    },
    [inviteUserToCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objInviteUserToCompanyFormSuccess = payload;
    },

    // validateUserInvitationToCompany
    [validateUserInvitationToCompany.pending]: (state) => {
      state.loading = true;
    },
    [validateUserInvitationToCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objValidateUserInvitationToCompanyFormSuccess = payload;
    },
    [validateUserInvitationToCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objValidateUserInvitationToCompanyFormSuccess = payload;
    },

    // acceptUserInvitationToCompany
    [acceptUserInvitationToCompany.pending]: (state) => {
      state.loading = true;
    },
    [acceptUserInvitationToCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objAcceptUserInvitationToCompanyFormSuccess = payload;
    },
    [acceptUserInvitationToCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objAcceptUserInvitationToCompanyFormSuccess = payload;
    },

    //

    // companyUsersList
    [companyUsersList.pending]: (state) => {
      state.loading = true;
    },
    [companyUsersList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objCompanyUsersListData = payload.data.users;
    },
    [companyUsersList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objCompanyUsersListData = payload;
    },

    // clearCompanyUsersList
    [clearCompanyUsersList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objCompanyUsersListData = payload;
    },
    [clearCompanyUsersList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objCompanyUsersListData = payload;
    },

    // deleteCompanyUser
    [deleteCompanyUser.pending]: (state) => {
      state.loading = true;
    },
    [deleteCompanyUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objDeleteCompanyUserSuccess = payload;
    },
    [deleteCompanyUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objDeleteCompanyUserSuccess = payload;
    },

    // deleteCompanyUserFromStore
    [deleteCompanyUserFromStore.fulfilled]: (state, action) => {
      state.loading = false;

      let objCompanyUser = action.payload;
      const objNewCompanyUsersListData =
        state.objCompanyUsersListData.data.data.data.filter(
          (item) => item.id !== objCompanyUser.id
        );
      state.objCompanyUsersListData.data.data.data = objNewCompanyUsersListData;
    },
    [deleteCompanyUserFromStore.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objCompanyUsersListData = payload;
    },
  },
});

// export const { logout } = companiesSlice.actions;

export default companiesSlice.reducer;
