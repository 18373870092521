import Card from "@mui/material/Card";
import { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";

import { unwrapResult } from "@reduxjs/toolkit";
import { fetchGlobalTemplates } from "features/company/superAdminActions";
import { Fragment } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg, standardDateFormat } from "utils/general";
import dataTableData from "views/pages/Dashboard/SuperAdmin/GlobalTemplates/components/TemplatesDataTableData";
import "./companyTemplates.css";
import TemplatesDatatable from "./components/TemplatesDataTable";
import CreateNewTemplateDialog from "./components/createNewTemplateDialog";
import { displayExceptionErrorMessage } from "utils/general";

const Templates = (props) => {
  const { objUser } = props;
  const [openNewTemplateDialog, setOpenNewTemplateDialog] = useState(false);
  const [isLoadingClientTemplates, setIsLoadingClientTemplates] =
    useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
    const [perPage, setPerPage] = useState(2);
    const [page, setPage] = useState(10);

  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  const btnOnClickNewTemplate = () => {
    setOpenNewTemplateDialog(true);
  };

  const onCloseNewTemplateDialog = () => {
    setOpenNewTemplateDialog(false);
  };

  const onSubmitNewTemplateDialog = (templateId) => {
    setOpenNewTemplateDialog(false);
    showMsg("success", "New Template added successfully");
    navigate(`/super-admin/templates/${templateId}`, {
      state: { routeDictionary },
    });
  };

  useEffect(() => {
    if (objUser.user.is_super_admin != 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    fetchTemplates();
  }, []);

  const getCurrentRMFList = (rmfsArray) => {
    return rmfsArray
      .filter((item) => {
        return item.selected === 1;
      })
      .map((item) => {
        return item.owning_rmf.name;
      })
      .join(", ");
  };

  const fetchTemplates = () => {
    setIsLoadingClientTemplates(true);
    dispatch(fetchGlobalTemplates({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingClientTemplates(false);
        const response = originalPromiseResult;

        const objRows = [];
        const objClientTemplatesList = response.data.templates.sort(
          (a, b) =>
            Date.parse(a.created_at ?? 0) - Date.parse(b.created_at ?? 0)
        );
        for (let index = 0; index < objClientTemplatesList.length; index++) {
          const element = objClientTemplatesList[index];
          const objTr = {};
          objTr.name = element.name;
          objTr.type = element.global_template_type;
          objTr.created_by = element?.created_by;
          objTr.last_modified_at = standardDateFormat(element.last_modified_at);
          objTr.status = element.status;
          objTr.current_rmfs = getCurrentRMFList(
            element.risk_management_frameworks
          );
          objTr.element = element;
          objRows.push(objTr);
        }

        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingClientTemplates(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <CreateNewTemplateDialog
        open={openNewTemplateDialog}
        onClose={onCloseNewTemplateDialog}
        onSubmit={onSubmitNewTemplateDialog}
        objUser={props.objUser}
      />
      <MDBox>
        <MDBox display="flex" gap=".6rem" alignItems="flex-start" mb={2}>
          <DashboardActionButton
            action={btnOnClickNewTemplate}
            btnText="Add Document"
            btnIcon="add"
            textColor="white"
            bgColor="success"
          />
        </MDBox>
        <Card>
          {isLoadingClientTemplates === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <TemplatesDatatable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              setObjTableData={setObjTableData}
            />
          ) : (
            <Fragment>
              {isLoadingClientTemplates ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p className="empty-text-p">
                  No available global templates yet.
                </p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default Templates;
