import MDTypography from "components/MDTypography";
// @mui icons
import Icon from "@mui/material/Icon";
import {  Link } from "react-router-dom";
import MDBox from "components/MDBox";
const KnowledgeBaseSharesNameBtnTr = (props) => {
  const { value, column, row } = props;

  return (
    <Link to={value} target="_blank">
      <MDBox display="flex" alignItems="center">
        <MDTypography
          variant="text"
          fontWeight="medium"
          color="success"
          sx={{ lineHeight: 0 }}
        >
          {value}
        </MDTypography>
        <Icon sx={{ marginLeft: "10px" }} color="success">
          link
        </Icon>
      </MDBox>
    </Link>
  );
};

export default KnowledgeBaseSharesNameBtnTr;
