import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { COMPANIES_SERVER } from "components/utils/constants/misc";

export const storeCompany = createAsyncThunk(
  "userSignUpFunnel/storeCompany",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(`${BACKEND_API_URL}${COMPANIES_SERVER}`, values);
      // return res.data;
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const setPersonalCompany = createAsyncThunk(
  "userSignUpFunnel/setPersonalCompany",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(`${BACKEND_API_URL}${COMPANIES_SERVER}/set-personal`, values);
      // return res.data;
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
