import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { useConfirm } from "material-ui-confirm";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import { useState } from "react";
import {
  resendMSPInvite,
  cancelInvite,
} from "features/company/companyUsersActions";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

const InvitesActionBtnTr = (props) => {
  const confirm = useConfirm();
  const {
    row,
    objUser,
  } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const btnOnClickOpenResendInviteConfirmation = (data) => {
    confirm({
      description: "This will resend the invite email to the user",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        resendMSPUserInvite_(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  /**resend Invite */
  const resendMSPUserInvite_ = (data) => {
    setLoading(true);
    dispatch(
      resendMSPInvite({
        inviteId: data.element.id,
        objUser,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setLoading(false);
        showMsg("success", "Invite resent.");
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
        if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  /**cancel Invite */
  const cancelInvite_ = (data) => {
    setLoading(true);
    dispatch(
      cancelInvite({
        inviteId: data.element.id,
        objUser,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setLoading(false);
        showMsg("success", "Invite cancelled.");
        window.location.reload();
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
        if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  const btnOnClickOpenCancelInviteConfirmation = (data) => {
    confirm({
      description:
        "This will cancel the invite sent to the user. Any previous invite emails sent will be counted as invalid",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        cancelInvite_(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <MDBox display="flex" gap="3rem">
      {loading ? (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      ) : (
        <>
          <MDButton
            onClick={() => btnOnClickOpenResendInviteConfirmation(row.original)}
            sx={{ paddingLeft: "0" }}
          >
            <MDBox color="dark" display="flex" alignItems="center">
              <Icon sx={{ fontWeight: "bold" }}>timer</Icon>
              <MDTypography
                variant="p"
                fontWeight="medium"
                color="text.dark"
                sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
              >
                Resend Invite
              </MDTypography>
            </MDBox>
          </MDButton>
          <MDButton
            onClick={() => btnOnClickOpenCancelInviteConfirmation(row.original)}
          >
            <MDBox color="primary" display="flex" alignItems="center">
              <Icon sx={{ fontWeight: "bold" }}>cancel</Icon>
              <MDTypography
                variant="p"
                fontWeight="medium"
                color="text.dark"
                sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
              >
                Cancel Invite
              </MDTypography>
            </MDBox>
          </MDButton>
        </>
      )}
    </MDBox>
  );
};

export default InvitesActionBtnTr;
