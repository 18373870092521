import React, { Fragment } from "react";
// import Alert from "@mui/material/Alert";
// import Stack from "@mui/material/Stack";
const EditClientContactByClientContactIdFormErrorLi = (props) => {
  // console.log("props in EditClientContactByClientContactIdFormErrorLi");
  // console.log("props in EditClientContactByClientContactIdFormErrorLi");
  // console.log("props in EditClientContactByClientContactIdFormErrorLi");
  // console.log(props);
  return (
    <Fragment>
      {props.value}
      <br />
    </Fragment>
  );
};
export default EditClientContactByClientContactIdFormErrorLi;
