import DefaultCell from "views/components/TableCells/DefaultCell";
import TemplateNameBtnTr from "./TemplateNameBtnTr";
import TemplateActionBtnTr from "./TemplateActionBtnTr";
import TemplateStatusTr from "./TemplateStatusTr";
import { IconButton, Tooltip } from "@mui/material";

const GlobalTemplatesDataTableData = {
  columns: [
    {
      Header: "Status",
      accessor: "created_at",
      align: "center",
      width: "10%",
      Cell: ({ value, column, row }) => (
        <TemplateStatusTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      width: "35%",
      Cell: ({ value, column, row }) => (
        <TemplateNameBtnTr value={value} column={column} row={row} />
      ),
    },
    // {
    //   Header: "Type",
    //   accessor: "global_template_type",
    //   width: "12%",
    //   Cell: ({ value }) => <DefaultCell value={value} />,
    // },
    {
      Header: "Policy Packs (RMFs)",
      accessor: "current_rmfs",
      width: "15%",
      // Cell: ({ value }) => <DefaultCell value={value} />,
      Cell: ({ value }) => (
        <>
          <Tooltip title={value} placement="top-end">
            <DefaultCell
              value={value.length > 20 ? value.slice(0, 20) + "..." : value}
            />
            <IconButton
              sx={{ opacity: "0.4", fontSize: "1.2rem" }}
              pb={2}
            ></IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      Header: "Action",
      Cell: ({ value, column, row }) => (
        <TemplateActionBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Last updated at",
      accessor: "last_modified_at",
      width: "12%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default GlobalTemplatesDataTableData;
