import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { unwrapResult } from "@reduxjs/toolkit";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  getCyberCNSAssets,
  getPreviousSyncInformation,
  importCyberCNSAssets,
  saveSyncFrequencySettingsForConnectSecureHardwareAssets,
} from "features/connectSecure/connectSecureActions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import assetImageLogo from "assets/images/asset-preview.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const CyberCNSAssetSyncSection = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [frequencyType, setFrequencyType] = useState("");
  const [isLoadingSyncInfo, setIsLoadingSyncInfo] = useState(false);
  const [isSavingSyncSettings, setIsSavingSyncSettings] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [syncRecords, setSyncRecords] = useState(null);
  const [isFetchingAssets, setIsFetchingAssets] = useState(false);
  const [isImportingAssets, setIsImportingAssets] = useState(false);
  const [totalAssetsFetched, setTotalAssetsFetched] = useState(0);
  const [assetData, setAssetData] = useState([]);

  useEffect(() => {
    fetchPreviousSyncInformation();
  }, []);

  const fetchPreviousSyncInformation = async () => {
    setIsLoadingSyncInfo(true);
    dispatch(getPreviousSyncInformation())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingSyncInfo(false);
        const response = originalPromiseResult;
        setSyncRecords(response.data);
        setSelectedValue(
          response.data.frequencyOfSync == ","
            ? ""
            : response.data.frequencyOfSync
        );
        setFrequencyType(response.data.frequencyOfSync.split(",")[0]);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingSyncInfo(false);
        showMsg("error", "Recent sync records could not be fetched");
        console.error(rejectedValueOrSerializedError);
      });
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const saveSyncSettings = async () => {
    setIsSavingSyncSettings(true);
    dispatch(
      saveSyncFrequencySettingsForConnectSecureHardwareAssets({
        // id,
        selectedValue,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsSavingSyncSettings(false);
        showMsg("success", "Sync frequency settings updated");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsSavingSyncSettings(false);
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error saving changes");
        }
      });
  };

  const fetchAssetsFromCyberCNS = async () => {
    setIsFetchingAssets(true);
    dispatch(getCyberCNSAssets())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        const assetsResponseData = response.data.data;
        const totalAssets = response.data.total;

        if (assetsResponseData.length > 0) {
          setAssetData(assetsResponseData);
          setTotalAssetsFetched(totalAssets);
        } else {
          showMsg("info", "No assets were found");
        }

        setIsFetchingAssets(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingAssets(false);
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Error fetching assets from CyberCNS");
      });
  };

  const importAssets = async () => {
    setIsImportingAssets(true);
    dispatch(importCyberCNSAssets({ assetData, totalAssetsFetched }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        navigate(
          `/asset-lists/${originalPromiseResult.data.finalPolicyObject.id}/${originalPromiseResult.data.updatedMajorVersion}`
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsImportingAssets(false);
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error importing assets");
        }
      });
  };

  const openAssetDocument = async () => {
    navigate(
      `/policies/${syncRecords.policyId}/${syncRecords.documentLastMajorVersion}`
    );
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid my={3} container width="100%" spacing={2}>
        <Grid item xs={12} lg={12}>
          <MDBox bgColor="success" borderRadius="lg" mb={1} textAlign="center">
            <MDTypography variant="h4" color="white">
              {syncRecords ? syncRecords.clientName : "--"} using ConnectSecure
            </MDTypography>
          </MDBox>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Card
            sx={{
              height: "100%",
              width: "100%",
              padding: "1rem",
              maxHeight: "600px",
            }}
          >
            <MDTypography variant="h6" color="success">
              Frequency of sync (select one option)
            </MDTypography>

            <Grid container>
              <Grid xs={6}>
                <label htmlFor={"weekly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"weekly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "weekly" }}
                      onChange={() => {
                        setFrequencyType("weekly");
                      }}
                      id={"weekly"}
                      checked={frequencyType === "weekly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Weekly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
              <Grid xs={6}>
                <label htmlFor={"monthly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"monthly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "monthly" }}
                      onChange={() => {
                        setFrequencyType("monthly");
                      }}
                      id={"monthly"}
                      checked={frequencyType === "monthly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Monthly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
              <Grid xs={6}>
                <label htmlFor={"bimonthly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"bimonthly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "bimonthly" }}
                      onChange={() => {
                        setFrequencyType("bimonthly");
                      }}
                      id={"bimonthly"}
                      checked={frequencyType === "bimonthly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Bi-monthly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
              <Grid xs={6}>
                <label htmlFor={"quarterly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"quarterly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "quarterly" }}
                      onChange={() => {
                        setFrequencyType("quarterly");
                      }}
                      id={"quarterly"}
                      checked={frequencyType === "quarterly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Quarterly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
            </Grid>

            {frequencyType == "weekly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Weekly">Weekly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Weekly"
                    label="Weekly"
                    id="Weekly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="weekly,Monday">Monday</MenuItem>
                    <MenuItem value="weekly,Tuesday">Tuesday</MenuItem>
                    <MenuItem value="weekly,Wednesday">Wednesday</MenuItem>
                    <MenuItem value="weekly,Thursday">Thursday</MenuItem>
                    <MenuItem value="weekly,Friday">Friday</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}

            {frequencyType == "monthly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Monthly">Monthly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Monthly"
                    label="Monthly"
                    id="Monthly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="monthly,1">
                      1st day of every Month
                    </MenuItem>
                    <MenuItem value="monthly,15">
                      15th day of every Month
                    </MenuItem>
                    <MenuItem value="monthly,31">
                      Last day of every Month
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}

            {frequencyType == "bimonthly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Bimonthly">Bi-Monthly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Bimonthly"
                    label="Bimonthly"
                    id="Bimonthly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="bimonthly,1">
                      1st day every two Months
                    </MenuItem>
                    <MenuItem value="bimonthly,15">
                      15th day every two Months
                    </MenuItem>
                    <MenuItem value="bimonthly,31">
                      Last day every two months
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}

            {frequencyType == "quarterly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Quarterly">Quarterly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Quarterly"
                    label="Quarterly"
                    id="Quarterly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="quarterly,1">
                      1st day of January, April, July and October
                    </MenuItem>
                    <MenuItem value="quarterly,15">
                      15th day of January, April, July and October
                    </MenuItem>
                    <MenuItem value="quarterly,31">
                      Last day of January, April, July and October
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}
            {isSavingSyncSettings || isLoadingSyncInfo ? (
              <MDBox mt={3}>
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              </MDBox>
            ) : (
              <MDButton
                type="submit"
                // variant="gradient"
                color="success"
                disabled={syncRecords && syncRecords.lastSyncAt == null}
                onClick={saveSyncSettings}
                sx={{ padding: "12px 20px", marginTop: "1rem" }}
              >
                {syncRecords && syncRecords.lastSyncAt == null
                  ? "Initial asset sync required"
                  : "Save Sync Frequency Settings"}
              </MDButton>
            )}

            {syncRecords ? (
              <>
                {" "}
                <MDTypography variant="h6" color="dark" mt={4}>
                  Last sync:{" "}
                  {syncRecords.lastSyncAt
                    ? syncRecords.lastSyncAt
                    : "No initial sync yet"}
                </MDTypography>
                <MDTypography variant="h6" color="dark" mt={1}>
                  {syncRecords.numberOfAssetsSynced
                    ? "Synced " +
                      syncRecords.numberOfAssetsSynced +
                      " asset records"
                    : "No records synced"}
                </MDTypography>
                <MDTypography variant="h6" color="success" mt={1}>
                  {syncRecords.documentLastMajorVersion ? (
                    // <Link
                    //   to={`/policies/${syncRecords.policyId}/version/${syncRecords.documentLastMajorVersion}`}
                    // >
                    <MDButton
                      onClick={openAssetDocument}
                      variant="gradient"
                      color="dark"
                    >
                      View document{" "}
                    </MDButton>
                  ) : (
                    // </Link>
                    "N/A"
                  )}
                </MDTypography>
              </>
            ) : (
              ""
            )}
          </Card>
          <>
            {/* <MDBox>
                <LoadingSpinner subClass="text-center" color="success" size="lg" />
                <MDTypography sx={{ textAlign: "center" }}>
                  Loading Keys
                </MDTypography>
              </MDBox> */}
          </>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Card
            sx={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {assetData.length == 0 ? (
              <>
                {/* preview assets image and button */}
                <MDBox mb={2}>
                  <img src={assetImageLogo} width="90px" alt="" />
                </MDBox>
                <MDBox>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    onClick={fetchAssetsFromCyberCNS}
                    sx={{ padding: "12px 20px", margin: "0px 5px" }}
                  >
                    Sync | Preview and Import Assets
                  </MDButton>
                </MDBox>
                {isFetchingAssets ? (
                  <MDBox mt={2}>
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  </MDBox>
                ) : (
                  ""
                )}
                {/* preview assets image and button end*/}
              </>
            ) : (
              <TableContainer sx={{ padding: "1rem" }}>
                {isImportingAssets ? (
                  <LoadingSpinner color="success" size="lg" />
                ) : (
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    onClick={importAssets}
                    sx={{ padding: "12px 20px", margin: "0px 5px" }}
                  >
                    Import Assets
                  </MDButton>
                )}
                <Table sx={{ color: "text.main" }}>
                  <TableRow>
                    <TableCell>
                      <b>Hardware Name</b>
                    </TableCell>
                    <TableCell>
                      <b>IP </b>
                    </TableCell>
                    <TableCell>
                      <b>Virtual</b>
                    </TableCell>
                    <TableCell>
                      <b>Public</b>
                    </TableCell>
                    <TableCell>
                      <b>Hostname</b>
                    </TableCell>
                    <TableCell>
                      <b>High Value Asset</b>
                    </TableCell>
                    <TableCell>
                      <b>Manufacturer Serial Number</b>
                    </TableCell>
                    <TableCell>
                      <b>MAC Address (es)</b>
                    </TableCell>
                    <TableCell>
                      <b>BIOS UUID/GUID</b>
                    </TableCell>
                    <TableCell>
                      <b>Asset Category</b>
                    </TableCell>
                    <TableCell>
                      <b>Asset Type</b>
                    </TableCell>
                    <TableCell>
                      <b>Hardware Make</b>
                    </TableCell>
                    <TableCell>
                      <b>Hardware Model</b>
                    </TableCell>
                    <TableCell>
                      <b>OS Name</b>
                    </TableCell>
                    <TableCell>
                      <b>OS Version</b>
                    </TableCell>
                    <TableCell>
                      <b>Date Device Added to System Boundary</b>
                    </TableCell>
                    <TableCell>
                      <b>Device Operator</b>
                    </TableCell>
                    <TableCell>
                      <b>Device Manager</b>
                    </TableCell>
                    <TableCell>
                      <b>Memory</b>
                    </TableCell>
                    <TableCell>
                      <b>CPU Speed</b>
                    </TableCell>
                    <TableCell>
                      <b>Drive Space</b>
                    </TableCell>
                    <TableCell>
                      <b>Hosting Environment</b>
                    </TableCell>
                    <TableCell>
                      <b>Location</b>
                    </TableCell>
                  </TableRow>

                  <TableBody>
                    {assetData.map((asset, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            "& th, & td": {
                              color: "text.main",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {asset.visibleName == "" ? "-" : asset?.visibleName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {asset.host?.ip}
                          </TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>{asset.host?.host_name}</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            {asset.host?.serial_number == ""
                              ? "-"
                              : asset.host?.serial_number}
                          </TableCell>
                          <TableCell>
                            {asset.host?.mac == "" ? "-" : asset.host?.mac}
                          </TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>
                            {asset.host?.asset_type == ""
                              ? "-"
                              : asset.host?.asset_type}
                          </TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>{asset.os?.full_name}</TableCell>
                          <TableCell>{asset.os?.version}</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>{asset.host.physical_memory}</TableCell>
                          <TableCell>
                            {asset.windows11_check?.processor_speed?.evidence}
                          </TableCell>
                          <TableCell>
                            {asset.windows11_check?.disk_free_space?.evidence}
                          </TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  {/* <TableBody>
                      {assetData.map((asset, i) => {
                        return (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {asset._id}
                            </TableCell>
                            <TableCell>
                              {asset.visibleName == "" ? "-" : asset?.visibleName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {asset.host.ip}
                            </TableCell>
                            <TableCell>
                              {asset.security_reportcard ? (
                                <>
                                  Antivirus:{" "}
                                  {asset.security_reportcard?.antiVirus}
                                  <LinearProgress
                                    variant="determinate"
                                    color="primary"
                                    value={0}
                                    sx={{
                                      marginBottom: "0.3rem",
                                      width: `${
                                        asset.security_reportcard?.antiVirus *
                                          10 ?? 0
                                      }%`,
                                    }}
                                  />
                                  L.Firewall:{" "}
                                  {asset.security_reportcard?.localFirewall}
                                  <LinearProgress
                                    variant="determinate"
                                    color="success"
                                    value={0}
                                    sx={{
                                      marginBottom: "0.3rem",
                                      width: `${
                                        asset.security_reportcard?.localFirewall *
                                          10 ?? 0
                                      }%`,
                                    }}
                                  />
                                  Insecure LPs:
                                  {
                                    asset.security_reportcard
                                      ?.insecureListeningPorts
                                  }
                                  <LinearProgress
                                    color="info"
                                    variant="determinate"
                                    value={0}
                                    sx={{
                                      width: `${
                                        asset.security_reportcard
                                          ?.insecureListeningPorts * 10 ?? 0
                                      }%`,
                                    }}
                                  />
                                </>
                              ) : (
                                "-"
                              )}
  
                          
                            </TableCell>
                            <TableCell>
                              <p>
                                NTLMV1 -
                                {asset.compliance_reportcard?.NTLMV1 ?? "-"}
                              </p>
                              <p>
                                LLMNR -{asset.compliance_reportcard?.LLMNR ?? "-"}
                              </p>
                              <p>
                                NBTNS -{asset.compliance_reportcard?.NBTNS ?? "-"}
                              </p>
                              <p>
                                SMBV1Server -
                                {asset.compliance_reportcard?.SMBV1Server ?? "-"}
                              </p>
                              <p>
                                SMBV1Client -
                                {asset.compliance_reportcard?.SMBV1Client ?? "-"}
                              </p>
                            </TableCell>
                            <TableCell>{asset.c}</TableCell>
                            <TableCell>{asset.u}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody> */}
                </Table>
              </TableContainer>
            )}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default CyberCNSAssetSyncSection;
