import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as Yup from "yup";
import { useState } from "react";
import { Formik, Form } from "formik";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useLocation } from "react-router-dom";
import AddNewAudienceFormFormikWrapper from "./AddNewAudienceFormFormikWrapper";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import AddNewAudienceFormErrorsBlock from "./AddNewAudienceFormErrorsBlock";
import { storeAddAudience } from "features/company/clientAudienceActions";
import { desc_to_types as types } from "../../AudienceManagement/data/AudienceTypes";
const objInitialValues = {
  txtAudienceName: "",
  drpAudienceType: "Adopters",
};
const AddNewAudienceFormJWTSchema = Yup.object().shape({
  txtAudienceName: Yup.string().required("Audience Name is required."),
  drpAudienceType: Yup.string().required("Audience Type is required."),
});
const AddNewAudienceForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [isLoading, setIsLoading] = useState(false);
  const [objAddNewAudienceFormErrors, setObjAddNewAudienceFormErrors] =
    useState({});
  const onSubmitForm = async (values) => {
    const objPostData = {
      name: values.txtAudienceName,
      type: types[values.drpAudienceType],
      objUser: props.objUser,
    };
    setObjAddNewAudienceFormErrors({});
    setIsLoading(true);
    dispatch(storeAddAudience(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Your Audience has been added successfully.");
          if (response.data.id) {
            routeDictionary[response.data.id] = response.data.name;
            navigate(`/audiences/${response.data.id}/`, {
              state: { routeDictionary },
            });
          } else {
            navigate(`/audiences/`, {
              state: { routeDictionary },
            });
          }
        } else {
          showMsg("error", "Unable to add audience. Please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Please correct the errors.");
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        setObjAddNewAudienceFormErrors(objErrors);

        // handle result here
      });
  };
  return (
    <Formik
      initialValues={objInitialValues}
      validationSchema={AddNewAudienceFormJWTSchema}
      onSubmit={onSubmitForm}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form id={"AddNewAudienceForm"} autoComplete="off">
          <Card sx={{ height: "100%" }}>
            <MDBox>
              <AddNewAudienceFormFormikWrapper
                values={values}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
              />

              {Object.keys(objAddNewAudienceFormErrors).length > 0 ? (
                <Grid
                  container
                  justifyContent="space-between"
                  pl={5}
                  pr={5}
                  pt={0}
                  pb={5}
                >
                  <AddNewAudienceFormErrorsBlock
                    objAddNewAudienceFormErrors={objAddNewAudienceFormErrors}
                  />
                </Grid>
              ) : null}

              <MDBox
                sx={{ margin: "0 0 40px -40px" }}
                width="100%"
                display="flex"
                justifyContent="flex-end"
              >
                {isLoading === false ? (
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 82px" }}
                  >
                    Add
                  </MDButton>
                ) : (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                )}
              </MDBox>
            </MDBox>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewAudienceForm;
