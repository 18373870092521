import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useState, memo } from "react";
import { Autocomplete, Grid } from "@mui/material";

const VendorToolResponsibilityRadio = memo(
  ({ question_id, responsibility, vendor, tool, onChangeResponsibility }) => {
    const [open, setOpen] = useState(false);
    const [vendors, setVendors] = useState([
      "LionGard",
      "Connectwise",
      "Nodeware",
      "MS Graph",
      "ConnectSecure",
      "Other",
    ]);
    const [vendorDialogOption, setVendorDialogOption] = useState(
      vendors.includes(vendor) ? vendor : "Other"
    );
    const [otherVendor, setOtherVendor] = useState(
      !vendors.includes(vendor) ? vendor : ""
    );
    const [toolDialogOption, setToolDialogOption] = useState(tool ?? "");

    const onChangeVendor = (value) => {
      if (value != "Other") {
        onChangeResponsibility(
          question_id,
          responsibility,
          value,
          toolDialogOption
        );
      }
    };

    const onChangeTool = (event) => {
      setToolDialogOption(event.target.value);
      onChangeResponsibility(
        question_id,
        responsibility,
        vendorDialogOption,
        event.target.value
      );
    };

    return (
      <>
        <MDTypography mt={1}>Vendor/Tool</MDTypography>
        <Grid container gap={2}>
          <Grid
            item
            xs={
              vendorDialogOption == "Other" ||
              !vendors.includes(vendorDialogOption)
                ? 8
                : 12
            }
          >
            <Autocomplete
              disableClearable
              value={vendorDialogOption}
              options={vendors}
              onChange={(event, newValue) => {
                setVendorDialogOption(newValue);
                setOtherVendor("");
                onChangeVendor(newValue);
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label="Vendor"
                  fullWidth
                />
              )}
            />
          </Grid>
          {(vendorDialogOption == "Other" ||
            !vendors.includes(vendorDialogOption)) && (
            <Grid item xs={3}>
              <MDInput
                label="Vendor"
                value={otherVendor}
                onChange={(e) => {
                  setOtherVendor(e.target.value);
                  onChangeVendor(e.target.value);
                }}
                fullWidth={true}
                variant={"standard"}
                autoFocus
              />
            </Grid>
          )}
        </Grid>

        <MDInput
          label="Tool"
          value={toolDialogOption}
          onChange={onChangeTool}
          fullWidth={true}
          variant={"standard"}
          sx={{ marginBottom: "10px", marginTop: "10px" }}
        />
      </>
    );
  }
);

export default VendorToolResponsibilityRadio;
