import Card from "@mui/material/Card";
import { useEffect, useMemo, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";

import { unwrapResult } from "@reduxjs/toolkit";
import { getCompanyTemplates } from "features/company/templatesActions";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg, standardDateFormat } from "utils/general";
import globalDataTableData from "views/pages/Dashboard/CompanyTemplates/components/GlobalTemplatesDataTableData";
import localDataTableData from "views/pages/Dashboard/CompanyTemplates/components/LocalTemplatesDataTableData";
import localDataTableClientsData from "views/pages/Dashboard/CompanyTemplates/components/LocalTemplatesClientsDataTableData";
import "./companyTemplates.css";
import TemplatesDatatable from "./components/TemplatesDataTable";
import TemplatesDatatableLite from "./components/TemplatesDataTableLite";
import TemplatesDatatablePlus from "./components/TemplatesDataTablePlus";
import CreateNewTemplateDialog from "./components/createNewTemplateDialog";
import HelpIcon from "@mui/icons-material/Help";
import { IconButton, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import AgreeToTermsForBulkImportDialog from "./components/AgreeToTermsForBulkImportDialog";
import TemplatesDataTableMSP from "./components/TemplatesDataTableMSP";
import TransferDocumentDialog from "./components/TransferDocumentDialog";
import DeployPolicyDialog from "./components/DeployPolicyDialog";
import TemplatesDataTableClient from "./components/TemplatesDataTableClient";
import { useMaterialUIController } from "context";

const Templates = (props) => {
  const { objUser } = props;
  const [openNewTemplateDialog, setOpenNewTemplateDialog] = useState(false);
  const [showMSPImportOptions, setShowMSPImportOptions] = useState(false);
  const [showClientImportOptions, setShowClientImportOptions] = useState(false);
  const [transferTo, setTransferTo] = useState(null);
  const [deployTo, setDeployTo] = useState(null);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [isLoadingClientTemplates, setIsLoadingClientTemplates] =
    useState(false);
  const [objLocalTableData, setObjLocalTableData] = useState(
    localDataTableClientsData(objUser, () => fetchTemplates(10, 1))
  );
  const [objLocalMSPTableData, setObjLocalMSPTableData] = useState(
    localDataTableData(objUser, () => fetchTemplates(10, 1))
  );
  const [objLocalClientTableData, setObjLocalClientTableData] = useState(
    localDataTableData(objUser, () => fetchTemplates(10, 1))
  );
  const [objGlobalLiteTableData, setObjGlobalLiteTableData] =
    useState(globalDataTableData);
  const [objGlobalPlusTableData, setObjGlobalPlusTableData] =
    useState(globalDataTableData);
  const [localPerPage, setLocalPerPage] = useState(20);
  const [localPage, setLocalPage] = useState(1);
  const [localTotalCount, setLocalTotalCount] = useState(0);
  const [localTotalMSPCount, setLocalMSPTotalCount] = useState(0);
  const [localTotalClientCount, setLocalClientTotalCount] = useState(0);
  const [globalLitePerPage, setGlobalLitePerPage] = useState(20);
  const [globalLitePage, setGlobalLitePage] = useState(1);
  const [globalLiteTotalCount, setGlobalLiteTotalCount] = useState(0);
  const [globalPlusPerPage, setGlobalPlusPerPage] = useState(20);
  const [globalPlusPage, setGlobalPlusPage] = useState(1);
  const [globalPlusTotalCount, setGlobalPlusTotalCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [currentClient, setCurrentClient] = useState({
    id: "",
    name: "",
    status: "",
    city: "",
    state: "",
    status_label: "",
  });

  /**bulk action vars */
  const [isBulkImportLoading, setIsBulkImportLoading] = useState(false);
  const [
    liteTemplatesSelectedForBulkImport,
    setLiteTemplatesSelectedForBulkImport,
  ] = useState([]);

  const [
    plusTemplatesSelectedForBulkImport,
    setPlusTemplatesSelectedForBulkImport,
  ] = useState([]);

  const [mspWideDocumentsToTransfer, setMSPWideDocumentsToTransfer] = useState(
    []
  );
  const [clientDocumentsToTransfer, setClientDocumentsToTransfer] = useState(
    []
  );

  const hasPlusSubscription = useMemo(
    () => objUser?.user?.company?.subscription_package === "plus",
    [objUser]
  );

  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [openDeployDialog, setOpenDeployDialog] = useState(false);

  const btnOnClickNewClientTemplate = () => {
    setTransferTo("client");
    setOpenNewTemplateDialog(true);
  };

  const btnOnClickNewMSPTemplate = () => {
    setTransferTo("MSP");
    setOpenNewTemplateDialog(true);
  };

  const onCloseNewTemplateDialog = () => {
    setOpenNewTemplateDialog(false);
  };

  const btnOnClickOpenBulkTermsDialog = () => {
    setOpenTermsDialog(true);
  };

  const onCloseTermsDialog = () => {
    setOpenTermsDialog(false);
  };

  const btnOnClickOpenTransferDialog = (transfer_to) => {
    setTransferTo(transfer_to);
    setOpenTransferDialog(true);
  };

  const onCloseTransferDialog = () => {
    setTransferTo(null);
    setOpenTransferDialog(false);
  };

  const btnOnClickOpenDeployDialog = (deploy_to) => {
    setDeployTo(deploy_to);
    setOpenDeployDialog(true);
  };

  const onCloseDeployDialog = () => {
    setDeployTo(null);
    setOpenDeployDialog(false);
  };

  const onSubmitNewTemplateDialog = (templateId) => {
    setOpenNewTemplateDialog(false);
    showMsg("success", "New Template added successfully");
    // window.location.reload();
    navigate(`/template-library/${templateId}`, {
      state: { routeDictionary },
    });
  };

  const getCurrentRMFList = (rmfsArray) => {
    return rmfsArray
      .filter((item) => {
        return item.selected === 1;
      })
      .map((item) => {
        return item.owning_rmf.name;
      })
      .join(", ");
  };

  const fetchTemplates = (_per_page, _page) => {
    setIsLoadingClientTemplates(true);
    let current_client;
    if (objUser.user.role.name == "client_user") {
      current_client = objUser.user.list_of_clients;
    } else {
      current_client = objUser.user.list_of_clients.find((item) => {
        return item.id === objUser.user.current_client_id;
      });
    }
    dispatch(getCompanyTemplates({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingClientTemplates(false);
        const response = originalPromiseResult;

        const objLocalRows = [];
        const objLocalRowsMSP = [];
        const objLocalRowsClient = [];
        const objGlobalLiteRows = [];
        const objGlobalPlusRows = [];
        const objClientTemplatesList = response.data.templates;
        for (let index = 0; index < objClientTemplatesList.length; index++) {
          const element = objClientTemplatesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.name = element.name;
          objTr.created_by = element?.created_by;
          objTr.created_at = new Date(element.created_at);
          objTr.last_modified_at_raw = new Date(element.last_modified_at);
          objTr.last_modified_at = standardDateFormat(element.last_modified_at);
          objTr.element = element;
          objTr.is_global_template = element?.is_global_template;
          objTr.client_id = element?.client_id;
          objTr.current_rmfs = getCurrentRMFList(
            element.risk_management_frameworks
          );
          objTr.description = "";
          objTr.objUser = objUser;
          if (objTr.is_global_template) {
            objTr.global_template_type = element?.global_template_type;
            objTr.subscription_package = response.data?.subscription_package;
            if (objTr.global_template_type === "Plus") {
              objGlobalPlusRows.push(objTr);
            } else {
              objGlobalLiteRows.push(objTr);
            }
          } else {
            if (
              objTr.client_id == current_client.id ||
              objTr.client_id == null
            ) {
              if (objUser.user.role.name == "client_user") {
                objLocalRows.push(objTr);
              } else {
                if (objTr.client_id == null) {
                  objLocalRowsMSP.push(objTr);
                }
                if (objTr.client_id == current_client.id) {
                  objLocalRowsClient.push(objTr);
                }
              }
            }
          }
        }

        setLocalTotalCount(objLocalRows.length); // There is no pagination on the backend, so that is ok
        setObjLocalTableData({
          ...localDataTableClientsData(objUser, () =>
            fetchTemplates(_per_page, _page)
          ),
          rows: objLocalRows,
        });
        setLocalMSPTotalCount(objLocalRowsMSP.length); // There is no pagination on the backend, so that is ok
        setObjLocalMSPTableData({
          ...localDataTableData(objUser, () =>
            fetchTemplates(_per_page, _page)
          ),
          rows: objLocalRowsMSP,
        });
        setLocalClientTotalCount(objLocalRowsClient.length); // There is no pagination on the backend, so that is ok
        setObjLocalClientTableData({
          ...localDataTableData(objUser, () =>
            fetchTemplates(_per_page, _page)
          ),
          rows: objLocalRowsClient,
        });
        setGlobalLiteTotalCount(objGlobalLiteRows.length); // There is no pagination on the backend, so that is ok
        setObjGlobalLiteTableData({
          ...globalDataTableData,
          rows: objGlobalLiteRows,
        });
        setGlobalPlusTotalCount(objGlobalPlusRows.length); // There is no pagination on the backend, so that is ok
        setObjGlobalPlusTableData({
          ...globalDataTableData,
          rows: objGlobalPlusRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingClientTemplates(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  const onSubmit = () => {
    setLiteTemplatesSelectedForBulkImport([]);
    setPlusTemplatesSelectedForBulkImport([]);
    fetchTemplates(localPerPage, localPage);
  };

  const onTransfer = () => {
    setMSPWideDocumentsToTransfer([]);
    setClientDocumentsToTransfer([]);
    fetchTemplates(localPerPage, localPage);
  };

  const onDeploy = () => {
    setMSPWideDocumentsToTransfer([]);
    setClientDocumentsToTransfer([]);
  };

  useEffect(() => {
    if (objUser.user.role.name == "client_user") {
      setCurrentClient(objUser.user.list_of_clients);
    } else {
      let current_client = objUser.user.list_of_clients.find((item) => {
        return item.id === objUser.user.current_client_id;
      });
      setCurrentClient(current_client);
    }
    fetchTemplates(localPerPage, localPage);
  }, [objUser]);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs title="Template Library" />
      <CreateNewTemplateDialog
        open={openNewTemplateDialog}
        onClose={onCloseNewTemplateDialog}
        onSubmit={onSubmitNewTemplateDialog}
        objUser={props.objUser}
        transferTo={transferTo}
      />
      <AgreeToTermsForBulkImportDialog
        open={openTermsDialog}
        onClose={onCloseTermsDialog}
        objUser={objUser}
        onSubmit={onSubmit}
        globalTemplateIds={[
          ...liteTemplatesSelectedForBulkImport,
          ...plusTemplatesSelectedForBulkImport,
        ]}
      />
      <TransferDocumentDialog
        open={openTransferDialog}
        onClose={onCloseTransferDialog}
        objUser={objUser}
        onSubmit={onTransfer}
        transferTo={transferTo}
        mspWideDocumentsToTransfer={mspWideDocumentsToTransfer}
        clientDocumentsToTransfer={clientDocumentsToTransfer}
      />
      <DeployPolicyDialog
        open={openDeployDialog}
        onClose={onCloseDeployDialog}
        objUser={objUser}
        onSubmit={onDeploy}
        deployTo={deployTo}
        mspWideDocumentsToTransfer={mspWideDocumentsToTransfer}
        clientDocumentsToTransfer={clientDocumentsToTransfer}
      />
      <MDBox>
        {objUser.user.role.name == "client_user" && (
          <Card
            position="relative"
            sx={{ padding: "32px", marginBottom: "1rem" }}
          >
            <MDBox
              mb={1}
              textAlign="left"
              sx={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h3">
                {currentClient.name} Document Templates
                <Tooltip title="This is a list of both your custom added and imported templates. You can add new  templates or modify exiting ones.">
                  <IconButton
                    sx={
                      darkMode
                        ? { fontSize: "1.2rem" }
                        : { opacity: "0.4", fontSize: "1.2rem" }
                    }
                    pb={2}
                  >
                    <HelpIcon color="icon" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
              {objUser.user.role.name !== "client_user" && (
                <DashboardActionButton
                  action={btnOnClickNewClientTemplate}
                  btnText={`Create Template For ${currentClient.name}`}
                  btnIcon="add"
                  textColor="white"
                  bgColor="success"
                  sx={{ margin: "0" }}
                />
              )}
            </MDBox>
            {isLoadingClientTemplates === false &&
            objLocalTableData.rows.length > 0 &&
            localTotalCount > 0 ? (
              <TemplatesDatatable
                table={objLocalTableData}
                entriesPerPage={true}
                canSearch
                totalCount={localTotalCount}
                perPage={localPerPage}
                setPerPage={setLocalPerPage}
                _page={localPage}
                setPage={setLocalPage}
                setObjTableData={setObjLocalTableData}
                objUser={objUser}
              />
            ) : (
              <Fragment>
                {isLoadingClientTemplates ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <p className="empty-text-p">
                    Company does not have any templates.
                  </p>
                )}
              </Fragment>
            )}
          </Card>
        )}

        {objUser.user.role.name !== "client_user" && (
          <Card
            position="relative"
            sx={{ padding: "32px", marginBottom: "1rem" }}
          >
            <MDBox
              mb={1}
              textAlign="left"
              sx={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h3">
                {currentClient.name} Templates
                <Tooltip title="This is a list of both your custom added and imported templates. You can add new document templates or modify exiting ones.">
                  <IconButton
                    sx={
                      darkMode
                        ? { fontSize: "1.2rem" }
                        : { opacity: "0.4", fontSize: "1.2rem" }
                    }
                    pb={2}
                  >
                    <HelpIcon color="icon" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
              {objUser.user.role.name !== "client_user" && (
                <DashboardActionButton
                  action={btnOnClickNewClientTemplate}
                  btnText={`Create Template For ${currentClient.name}`}
                  btnIcon="add"
                  textColor="white"
                  bgColor="success"
                  sx={{ margin: "0" }}
                />
              )}
            </MDBox>
            {isLoadingClientTemplates === false &&
            objLocalClientTableData.rows.length > 0 &&
            localTotalClientCount > 0 ? (
              <TemplatesDataTableClient
                table={objLocalClientTableData}
                entriesPerPage={true}
                canSearch
                totalCount={localTotalClientCount}
                perPage={localPerPage}
                setPerPage={setLocalPerPage}
                _page={localPage}
                setPage={setLocalPage}
                setObjTableData={setObjLocalClientTableData}
                objUser={objUser}
                setClientDocumentsToTransfer={setClientDocumentsToTransfer}
                clientDocumentsToTransfer={clientDocumentsToTransfer}
              />
            ) : (
              <Fragment>
                {isLoadingClientTemplates ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <p className="empty-text-p">
                    Company does not have any templates.
                  </p>
                )}
              </Fragment>
            )}
          </Card>
        )}
        {objUser.user.role.name !== "client_user" && (
          <Card
            position="relative"
            sx={{ padding: "32px", marginBottom: "1rem" }}
          >
            <MDBox
              mb={1}
              textAlign="left"
              sx={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h3">
                MSP-Wide Templates
                <Tooltip title="This is a list of both your custom added and imported templates. You can add new document templates or modify exiting ones.">
                  <IconButton
                    sx={
                      darkMode
                        ? { fontSize: "1.2rem" }
                        : { opacity: "0.4", fontSize: "1.2rem" }
                    }
                    pb={2}
                    color="text.main"
                  >
                    <HelpIcon color="icon" />
                  </IconButton>
                </Tooltip>
              </MDTypography>
              <DashboardActionButton
                action={btnOnClickNewMSPTemplate}
                btnText="Create An MSP-Wide Template"
                btnIcon="add"
                textColor="white"
                bgColor="success"
                sx={{ margin: "0" }}
              />
            </MDBox>
            {isLoadingClientTemplates === false &&
            objLocalMSPTableData.rows.length > 0 &&
            localTotalMSPCount > 0 ? (
              <TemplatesDataTableMSP
                table={objLocalMSPTableData}
                entriesPerPage={true}
                canSearch
                totalCount={localTotalMSPCount}
                perPage={localPerPage}
                setPerPage={setLocalPerPage}
                _page={localPage}
                setPage={setLocalPage}
                setObjTableData={setObjLocalMSPTableData}
                objUser={objUser}
                // move scope actions
                setMSPWideDocumentsToTransfer={setMSPWideDocumentsToTransfer}
                mspWideDocumentsToTransfer={mspWideDocumentsToTransfer}
              />
            ) : (
              <Fragment>
                {isLoadingClientTemplates ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <p className="empty-text-p">
                    There are no MSP-Wide Templates available.
                  </p>
                )}
              </Fragment>
            )}
          </Card>
        )}
        {objUser.user.role.name !== "client_user" && (
          <Card
            position="relative"
            sx={{ padding: "32px", marginBottom: "1rem" }}
          >
            <MDBox
              mb={1}
              textAlign="left"
              sx={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <MDTypography variant="h3">Global Lite Templates</MDTypography>
            </MDBox>
            {isLoadingClientTemplates === false &&
            objGlobalLiteTableData.rows.length > 0 &&
            globalLiteTotalCount > 0 ? (
              <TemplatesDatatableLite
                table={objGlobalLiteTableData}
                entriesPerPage={true}
                canSearch
                totalCount={globalLiteTotalCount}
                perPage={globalLitePerPage}
                setPerPage={setGlobalLitePerPage}
                _page={globalLitePage}
                setPage={setGlobalLitePage}
                setObjTableData={setObjGlobalLiteTableData}
                objUser={objUser}
                // bulk actions
                setLiteTemplatesSelectedForBulkImport={
                  setLiteTemplatesSelectedForBulkImport
                }
                liteTemplatesSelectedForBulkImport={
                  liteTemplatesSelectedForBulkImport
                }
              />
            ) : (
              <Fragment>
                {isLoadingClientTemplates ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <p className="empty-text-p">
                    There are no Global Templates available in your subscription
                    tier.
                  </p>
                )}
              </Fragment>
            )}
          </Card>
        )}
        {objUser.user.role.name !== "client_user" && (
          <Card
            position="relative"
            sx={{ padding: "32px", marginBottom: "1rem" }}
          >
            <MDBox
              mb={1}
              textAlign="left"
              sx={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <MDTypography variant="h3">Global Plus Templates</MDTypography>
            </MDBox>
            {isLoadingClientTemplates === false &&
            objGlobalPlusTableData.rows.length > 0 &&
            globalPlusTotalCount > 0 ? (
              <TemplatesDatatablePlus
                table={objGlobalPlusTableData}
                entriesPerPage={true}
                canSearch
                totalCount={globalPlusTotalCount}
                perPage={globalPlusPerPage}
                setPerPage={setGlobalPlusPerPage}
                _page={globalPlusPage}
                setPage={setGlobalPlusPage}
                setObjTableData={setObjGlobalPlusTableData}
                objUser={objUser}
                // bulk actions
                setPlusTemplatesSelectedForBulkImport={
                  setPlusTemplatesSelectedForBulkImport
                }
                plusTemplatesSelectedForBulkImport={
                  plusTemplatesSelectedForBulkImport
                }
                hasPlusSubscription={hasPlusSubscription}
              />
            ) : (
              <Fragment>
                {isLoadingClientTemplates ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <p className="empty-text-p">
                    There are no Global Templates available in your subscription
                    tier.
                  </p>
                )}
              </Fragment>
            )}
          </Card>
        )}
      </MDBox>

      {plusTemplatesSelectedForBulkImport.length > 0 ||
      liteTemplatesSelectedForBulkImport.length > 0 ? (
        <MDButton
          // variant="gradient"
          color="dark"
          onClick={() => btnOnClickOpenBulkTermsDialog()}
          sx={{
            position: "fixed",
            bottom: "3rem",
            right: "3rem",
            padding: "20px",
          }}
          aria-label="Scroll to top"
          title="Scroll to top"
        >
          Import To My Document Templates
        </MDButton>
      ) : (
        ""
      )}

      {clientDocumentsToTransfer.length == 0 &&
      plusTemplatesSelectedForBulkImport.length == 0 &&
      liteTemplatesSelectedForBulkImport.length == 0 ? (
        <>
          {mspWideDocumentsToTransfer.length > 0 ? (
            <MDBox
              // variant="gradient"
              bgColor="#1a74bb"
              color="white"
              display="flex"
              alignItems="center"
              onClick={() => setShowMSPImportOptions(!showMSPImportOptions)}
              sx={{
                position: "fixed",
                bottom: "3rem",
                right: "3rem",
                padding: "15px",
                cursor: "pointer",
                boxShadow: "1px 1px 10px #000",
              }}
              aria-label="Scroll to top"
              title="Scroll to top"
            >
              <MDTypography
                color="white"
                variant="button"
                textTransform="uppercase"
                fontWeight="medium"
                mr={1}
                sx={{ fontSize: "14px" }}
              >
                2 Document Actions Available
              </MDTypography>
              <ArrowDropUpIcon />
            </MDBox>
          ) : (
            ""
          )}

          {mspWideDocumentsToTransfer.length > 0 && showMSPImportOptions ? (
            <MDButton
              // variant="gradient"
              color="dark"
              onClick={() => btnOnClickOpenTransferDialog("client")}
              sx={{
                position: "fixed",
                bottom: "10rem",
                right: "3rem",
                padding: "15px",
              }}
              aria-label="Scroll to top"
              title="Scroll to top"
            >
              Transfer to {currentClient.name} {} Document Templates
            </MDButton>
          ) : (
            ""
          )}

          {mspWideDocumentsToTransfer.length > 0 && showMSPImportOptions ? (
            <MDButton
              // variant="gradient"
              color="dark"
              onClick={() => btnOnClickOpenDeployDialog("all clients")}
              sx={{
                position: "fixed",
                bottom: "6.8rem",
                right: "3rem",
                padding: "15px",
              }}
              aria-label="Scroll to top"
              title="Scroll to top"
            >
              Deploy{" "}
              {mspWideDocumentsToTransfer.length > 0
                ? " Policies "
                : " Policy "}{" "}
              to all Clients
            </MDButton>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {clientDocumentsToTransfer.length > 0 ? (
        <MDBox
          // variant="gradient"
          bgColor="#1a74bb"
          color="white"
          display="flex"
          alignItems="center"
          onClick={() => setShowClientImportOptions(!showClientImportOptions)}
          sx={{
            position: "fixed",
            bottom: "3rem",
            right: "3rem",
            padding: "15px",
            cursor: "pointer",
            boxShadow: "1px 1px 10px #000",
          }}
          aria-label="Scroll to top"
          title="Scroll to top"
        >
          <MDTypography
            color="white"
            variant="button"
            textTransform="uppercase"
            fontWeight="medium"
            mr={1}
            sx={{ fontSize: "14px" }}
          >
            2 Document Actions Available
          </MDTypography>
          <ArrowDropUpIcon />
        </MDBox>
      ) : (
        ""
      )}

      {clientDocumentsToTransfer.length > 0 && showClientImportOptions ? (
        <MDButton
          // variant="gradient"
          color="dark"
          onClick={() => btnOnClickOpenTransferDialog("MSP")}
          sx={{
            position: "fixed",
            bottom: "10rem",
            right: "3rem",
            padding: "15px",
          }}
          aria-label="Scroll to top"
          title="Scroll to top"
        >
          Transfer to MSP-Wide Documents
        </MDButton>
      ) : (
        ""
      )}

      {clientDocumentsToTransfer.length > 0 && showClientImportOptions ? (
        <MDButton
          // variant="gradient"
          color="dark"
          onClick={() => btnOnClickOpenDeployDialog("client")}
          sx={{
            position: "fixed",
            bottom: "6.8rem",
            right: "3rem",
            padding: "15px",
          }}
          aria-label="Scroll to top"
          title="Scroll to top"
        >
          Deploy{" "}
          {clientDocumentsToTransfer.length > 0 ? " Policies " : " Policy "} to{" "}
          {currentClient.name}
        </MDButton>
      ) : (
        ""
      )}
    </DashboardLayout>
  );
};

export default Templates;
