import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import DashboardActionButton from "views/components/DashboardActionButton";
import { Alert } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

const configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_MS_GRAPH_APP_ID}`,
    authority: "https://login.microsoftonline.com/common",
  },
};

const msAlInstance = new PublicClientApplication(configuration);

function Index({ open, onClose, objUser }) {
  const navigate = useNavigate();
  async function openExistingSessionsForLogout() {
    await msAlInstance.initialize();
    msAlInstance.logoutPopup();
  }

  function proceed() {
    navigate(
      `/settings/ms-graph-email-connect/${objUser.user.current_client_id}`
    );
  }

  return (
    <MsalProvider instance={msAlInstance}>
      <Dialog open={open} onClose={onClose} fullWidth={true}>
        <DialogTitle sx={{ pb: 0 }}>Before Your Proceed</DialogTitle>
        <DialogContent>
          <MDBox mt={2}>
            <Alert icon={<InfoIcon fontSize="inherit" />} severity="primary">
              Please make sure to check and logout of all existing MS account
              sessions in order to avoid authentication conflicts for your
              different companies. If you haven't previously logged in, you can
              ignore this alert and proceed. <br />
              <br />
              <DashboardActionButton
                action={openExistingSessionsForLogout}
                btnText="Check sessions"
                btnIcon="link"
                textColor="white"
                bgColor="primary"
              />
            </Alert>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            onClick={proceed}
            color="success"
            sx={{ padding: "12px 12px" }}
          >
            Proceed to configure MS Graph
          </MDButton>
          <MDButton
            type="button"
            variant="outlined"
            color="dark"
            sx={{ padding: "12px 12px" }}
            onClick={onClose}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
    </MsalProvider>
  );
}
export default Index;
