// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";

// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";

const CreateClientContactByClientIdFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue, objStates } = props;

  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h4"
          fontWeight="bold"
        >
          Add New Client Contact
        </MDTypography>

        <MDBox mt={2}>
          <Grid container xs={12} justifyContent="space-between" p={5}>
            <Grid container xs={12} md={12} spacing={3}>
              <Grid item xs={4}>
                <FormField
                  type="text"
                  label="First Name"
                  name="txtFirstName"
                  value={values.txtFirstName}
                  placeholder={"First Name"}
                  error={errors.txtFirstName && touched.txtFirstName}
                  success={
                    values.txtFirstName.length > 0 && !errors.txtFirstName
                  }
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <FormField
                  type="text"
                  label="Last Name"
                  name="txtLastName"
                  value={values.txtLastName}
                  placeholder={"Last Name"}
                  error={errors.txtLastName && touched.txtLastName}
                  success={values.txtLastName.length > 0 && !errors.txtLastName}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <FormField
                  type="email"
                  label="Email"
                  name="txtEmail"
                  value={values.txtEmail}
                  placeholder={"Email"}
                  error={errors.txtEmail && touched.txtEmail}
                  success={values.txtEmail.length > 0 && !errors.txtEmail}
                  required
                />
              </Grid>
            </Grid>
            <Grid container xs={12} md={12} spacing={3}>
              <Grid item xs={4}>
                <FormField
                  type="text"
                  label="Title"
                  name="txtTitle"
                  value={values.txtTitle}
                  placeholder={"Title"}
                  error={errors.txtTitle && touched.txtTitle}
                  success={values.txtTitle.length > 0 && !errors.txtTitle}
                />
              </Grid>
              <Grid item xs={4}>
                <MDBox>
                  <FormField
                    type="text"
                    label="Phone"
                    name="txtPhone"
                    value={values.txtPhone}
                    placeholder={"Phone"}
                    error={errors.txtPhone && touched.txtPhone}
                    success={values.txtPhone.length > 0 && !errors.txtPhone}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container xs={12} md={12} spacing={3}>
              {/* <Grid item xs={4}>
                <FormField
                  type="text"
                  label="City"
                  name="txtCity"
                  value={values.txtCity}
                  placeholder={"City"}
                  error={errors.txtCity && touched.txtCity}
                  success={values.txtCity.length > 0 && !errors.txtCity}
                />
              </Grid> */}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default CreateClientContactByClientIdFormFormikWrapper;
