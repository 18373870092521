// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// @mui icons
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloneTemplateDialog from "./CloneTemplateDialog";
const TemplateActionBtnTr = (props) => {
  const { row } = props;
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const [openCloneTemplateDialog, setOpenCloneTemplateDialog] = useState(false);

  const onSubmitCloneTemplateDialog = (templateId) => {
    setOpenCloneTemplateDialog(false);
    navigate(`/assessments/templates/${templateId}`, {
      state: { routeDictionary },
    });
    window.location.reload();
  };

  const btnOnClickCloneTemplate = () => {
    setOpenCloneTemplateDialog(true);
  };

  const onClosCloneTemplateDialog = () => {
    setOpenCloneTemplateDialog(false);
  };

  const disabled =
    row.original.subscription_category === "plus" &&
    row.original.subscription_package !== "plus";

  return (
    <>
      <CloneTemplateDialog
        open={openCloneTemplateDialog}
        onClose={onClosCloneTemplateDialog}
        objTemplate={row.original.element}
        onSubmit={onSubmitCloneTemplateDialog}
        objUser={row.original.objUser}
      />
      {row.original.is_global_template ? (
        <MDButton
          color="success"
          onClick={btnOnClickCloneTemplate}
          disabled={disabled}
        >
          <MDTypography
            color="white"
            sx={{ lineHeight: 0, fontSize: "0.7rem", fontWeight: "bold" }}
          >
            Import into My Templates
          </MDTypography>
        </MDButton>
      ) : (
        ""
      )}
    </>
  );
};

export default TemplateActionBtnTr;
