import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { useConfirm } from "material-ui-confirm";
import {
  bulkAddContactToAudiences,
  listAudiences,
} from "features/company/clientAudienceActions";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
const objInitialValues = { sltAudience: "" };
const AddToAudienceFormJWTSchema = Yup.object().shape({
  sltAudience: Yup.string().required("The audience is required."),
});

function BulkAddToAudienceDialog({
  open,
  onClose,
  onSubmit,
  setContactsSelectedForBulkAction,
  contactsSelectedForBulkAction,
  setBulkActionResultNotices,
  handleClickOpen,
  objUser,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [audienceList, setAudienceList] = useState([]);
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    setIsSubmiting(true);

    dispatch(
      bulkAddContactToAudiences({
        id: values.sltAudience,
        contactsSelectedForBulkAction,
        objUser,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Selected contacts added successfully.");
          onSubmit();
          const notAddedContactsArray = response.data.not_added_contacts;
          setBulkActionResultNotices(response.data.not_added_contacts);
          setContactsSelectedForBulkAction([]);
          if (notAddedContactsArray.length > 0) {
            handleClickOpen();
          }
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        const response = rejectedValueOrSerializedError.response;
        if (response.data && response.data.message) {
          showMsg("error", response.data.message);
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .finally(() => setIsSubmiting(false));
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(listAudiences({ _per_page: 100, _page: 1, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objAudiencesList = response.data.audiences;
        for (let index = 0; index < objAudiencesList.length; index++) {
          const element = objAudiencesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.label = element.name;
          objRows.push(objTr);
        }
        setAudienceList(objRows);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again.");
        onClose();
      });
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>
        Add Selected Contacts to Audience
      </DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddToAudienceFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddToAudienceForm" autoComplete="off">
            <DialogContent>
              {isLoading === false ? (
                <MDBox mb={0} mt={0}>
                  <Autocomplete
                    options={audienceList}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Audience"
                      />
                    )}
                    onChange={(event, value) => {
                      if (value === null) {
                        setFieldValue("sltAudience", "");
                      } else {
                        setFieldValue("sltAudience", value.id);
                      }
                    }}
                  />
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      <ErrorMessage name="sltAudience" />
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogContent>
            <DialogActions>
              {isSubmiting === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Add
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default BulkAddToAudienceDialog;
