// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";

// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";

const SaveApiKeysFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue } = props;
  //console.log(values);
  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDBox display="flex">
          <MDTypography
            px={5}
            textAlign="left"
            mb={1}
            variant="h5"
            fontWeight="bold"
            sx={{ paddingRight: "0px" }}
          >
            Configure SharePoint Evidence Locker
          </MDTypography>
        </MDBox>
        <MDBox mt={2}>
          <Grid container xs={12} justifyContent="space-between" px={5}>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your SharePoint Site Id"
                name="site_id"
                value={values.site_id}
                placeholder={"SharePoint Site Id"}
                error={errors.site_id && touched.site_id}
                success={values.site_id.length > 0 && !errors.site_id}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default SaveApiKeysFormFormikWrapper;
