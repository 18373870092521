/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";

/**company peer group actions */
export const createPeerGroupPost = createAsyncThunk(
  "peerGroup/create",
  async (values, thunkApi) => {
    try {
      const { objPostData } = values;
      const formData = new FormData();
      for (const [key, value] of Object.entries(objPostData)) {
        if (value instanceof FileList || value instanceof Array) {
          for (let i = 0; i < value.length; i++) {
            formData.append(key, value[i]);
          }
        } else {
          formData.append(key, value);
        }
      }

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/peer-group-post/`,
        formData,
        { "Content-Type": "multipart/form-data" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchPeerGroupPost = createAsyncThunk(
  "peerGroup/fetch",
  async (thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/peer-group-post/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchPeerGroupPostSuper = createAsyncThunk(
  "peerGroup/fetchSuper",
  async (thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-peer-group-post/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updatePeerGroupPost = createAsyncThunk(
  "peerGroup/update",
  async (values, thunkApi) => {
    try {
      const { objPostData } = values;

      const formData = new FormData();
      for (const [key, value] of Object.entries(objPostData)) {
        if (value instanceof FileList || value instanceof Array) {
          for (let i = 0; i < value.length; i++) {
            formData.append(key, value[i]);
          }
        } else {
          formData.append(key, value);
        }
      }

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/peer-group-post/${objPostData.id}/`,
        formData,
        { "Content-Type": "multipart/form-data" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchPeerGroupPostDetails = createAsyncThunk(
  "peerGroup/details",
  async (values, thunkApi) => {
    try {
      const { post_id } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/peer-group-post/${post_id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deletePeerGroupPost = createAsyncThunk(
  "peerGroup/details",
  async (values, thunkApi) => {
    try {
      const { post_id } = values;
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/peer-group-post/${post_id}`
      );
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
