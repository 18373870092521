import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardActionButton from "views/components/DashboardActionButton";
import { unwrapResult } from "@reduxjs/toolkit";
import CreatePolicyDocumentStepAdoptionTable from "views/pages/Dashboard/Policies/CreatePolicyDocument/components/CreatePolicyDocumentStepAdoptionTable";
import dataTableData from "./data/dataTableData";
import { listAudiences } from "features/company/clientAudienceActions";
import CreateNewCampaignDialog from "./CreateNewCampaignDialog";
import ShowCampaignDialog from "./ShowCampaignDialog";
import { getCampaignsByPolicyDocument } from "features/company/policiesActions";
import MDTypography from "components/MDTypography";

const CreatePolicyDocumentStepAdoption = (props) => {
  const { objUser, objPolicyDocument } = props;
  let { clientId } = useParams();
  const [openNewCampaignDialog, setOpenNewCampaignDialog] = useState(false);
  const [showCampaignDialogSelectedId, setShowCampaignDialogSelectedId] =
    useState(0);
  const [isLoadingMoreCampaigns, setIsLoadingMoreCampaigns] = useState(false);
  const [audiences, setAudiences] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [objTableData, setObjTableData] = useState(
    dataTableData((campaign) => {
      onClickCampaignName(campaign);
    })
  );
  const dispatch = useDispatch();

  const allowedStatuses = ["Published"];
  let allowedStep = false;

  if (
    typeof objPolicyDocument.state !== "undefined" &&
    allowedStatuses.includes(objPolicyDocument.state) &&
    objPolicyDocument.is_max_major_version == true &&
    objPolicyDocument.policy.policy_type == "default"
  ) {
    allowedStep = true;
  }

  useEffect(() => {
    dispatch(
      listAudiences({
        _per_page: 100,
        _page: 1,
        objUser,
        id: objPolicyDocument.policy.client_id,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        const list = [];
        response.data.audiences
          .filter((el) => el.type === "POLICY_ADOPTERS")
          .forEach((element) => {
            const objTr = {};
            objTr.id = element.id;
            objTr.label = element.name;
            list.push(objTr);
          });
        setAudiences(list);
      })
      .catch((rejectedValueOrSerializedError) => {
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  }, [objUser, objPolicyDocument]);

  useEffect(() => {
    loadMoreCampaigns(perPage, page);
  }, [page, perPage]);

  const loadMoreCampaigns = (_per_page, _page) => {
    setIsLoadingMoreCampaigns(true);
    dispatch(
      getCampaignsByPolicyDocument({
        objUser,
        clientId: objPolicyDocument.policy.client_id,
        objPolicyDocument: objPolicyDocument.id,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        const objRows = [];
        response.data.forEach((element) => {
          objRows.push({
            audience: element.audience,
            created_at: new Date(element.created_at).toLocaleDateString(),
            campaign_name: element.campaign_name,
            adoption_status: element.adoption_status,
            campaign_id: element.campaign_id,
          });
        });
        setTotalCount(objRows.length);
        setObjTableData({
          ...objTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong, please try again.");
      })
      .finally(() => setIsLoadingMoreCampaigns(false));
  };
  const onClickNewCampaign = () => {
    setOpenNewCampaignDialog(true);
  };
  const onCloseNewCampaignDialog = () => {
    setOpenNewCampaignDialog(false);
  };
  const onSubmitNewCampaignDialog = (campaign) => {
    setOpenNewCampaignDialog(false);
    loadMoreCampaigns(perPage, page);
  };
  const onClickCampaignName = (campaign) => {
    setShowCampaignDialogSelectedId(campaign.campaign_id);
  };
  const onCloseShowCampaignDialog = () => {
    setShowCampaignDialogSelectedId(0);
  };
  return (
    <MDBox>
      <MDBox display="flex" gap=".6rem" alignItems="flex-start" mb={2}>
        <CreateNewCampaignDialog
          open={openNewCampaignDialog}
          onClose={onCloseNewCampaignDialog}
          onSubmit={onSubmitNewCampaignDialog}
          objUser={objUser}
          audiences={audiences}
          clientId={clientId}
          policyId={objPolicyDocument.policy.id}
          policyDocumentId={objPolicyDocument.id}
        />
        <ShowCampaignDialog
          open={showCampaignDialogSelectedId !== 0}
          onClose={onCloseShowCampaignDialog}
          objUser={objUser}
          clientId={clientId}
          campaignId={showCampaignDialogSelectedId}
        />
        {allowedStep === true ? (
          <DashboardActionButton
            action={onClickNewCampaign}
            btnText="Create Adoption Campaign"
            btnIcon="add"
            textColor="white"
            bgColor="success"
          />
        ) : (
          ""
        )}
      </MDBox>
      {/* <Card> */}
      {allowedStep === true ? (
        <Fragment>
          {isLoadingMoreCampaigns === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <CreatePolicyDocumentStepAdoptionTable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              loadMoreCampaigns={loadMoreCampaigns}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              setObjTableData={setObjTableData}
            />
          ) : (
            <Fragment>
              {isLoadingMoreCampaigns ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>Please add some Adoption Campaigns first.</p>
              )}
            </Fragment>
          )}{" "}
        </Fragment>
      ) : (
        <Fragment>
          <MDBox className="step-content-wrapper" textAlign="left" py={2}>
            <Grid container justifyContent="space-between" columnSpacing={2}>
              {objPolicyDocument.policy.policy_type == "default" ? (
                <MDBox mb={0}>
                  <MDBox mb={0}>
                    <MDTypography variant="h5" fontWeight="bold">
                      Policy document not published
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="body2" color="text">
                    Adoption campaigns not accessible until policy is published
                  </MDTypography>
                </MDBox>
              ) : (
                <MDBox mb={0}>
                  <MDBox mb={0}>
                    <MDTypography variant="h5" fontWeight="bold">
                      {objPolicyDocument.policy.policy_type == "asset"
                        ? "Asset List"
                        : "Policy"}{" "}
                      document belongs to an asset list and has already been
                      published
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="body2" color="text">
                    No adoption campaigns are required
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
          </MDBox>
        </Fragment>
      )}
      {/* </Card> */}
    </MDBox>
  );
};

CreatePolicyDocumentStepAdoption.propTypes = {
  loadPolicy: PropTypes.func,
  objUser: PropTypes.object,
  objPolicy: PropTypes.object,
  setActiveStep: PropTypes.func,
};

export default CreatePolicyDocumentStepAdoption;
