import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { unwrapResult } from "@reduxjs/toolkit";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { addContactToAudiences } from "features/company/clientAudienceActions";
import { fetchClientContactsById } from "features/company/clientContactsActions";
import { useConfirm } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

import * as Yup from "yup";
// formik components
import { ErrorMessage, Form, Formik } from "formik";
import InviteClientUserDialog from "views/pages/Dashboard/Customers/ShowClientContactsById/components/InviteClientUserDialog";
const objInitialValues = { stlContact: "" };
const AddContactFormJWTSchema = Yup.object().shape({
  stlContact: Yup.object()
    .shape({
      id: Yup.string().required(),
      label: Yup.string().required(),
      invited: Yup.boolean().required(),
    })
    .required("The contact is required."),
});

function AddContactDialog({
  open,
  onClose,
  onSubmit,
  currentContacts,
  audienceId,
  inviteContact,
  objUser,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [askInviteContact, setAskInviteContact] = useState(false);
  const [contactToInvite, setContactToInvite] = useState({});
  const [contactList, setContactList] = useState([]);
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    setIsSubmiting(true);
    dispatch(
      addContactToAudiences({
        id: audienceId,
        contactId: values.stlContact.id,
        objUser,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Contact added successfully.");
        } else if (response.status === 409) {
          let contact = {
            element: {
              id: values.stlContact.id,
            },
          };
          setContactToInvite(contact);
          setAskInviteContact(true);
        } else {
          showMsg("error", response.data.message);
        }
        // onSubmit();
      })
      .catch((rejectedValueOrSerializedError) => {
        const response = rejectedValueOrSerializedError.response;
        if (response.status === 409) {
          let contact = {
            element: {
              id: values.stlContact.id,
            },
          };
          setContactToInvite(contact);
          setAskInviteContact(true);
          // onClose();
        } else if (response.data && response.data.message) {
          showMsg("error", response.data.message);
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .finally(() => setIsSubmiting(false));
  };

  // const askInviteContact = (data) => {
  //   if (data.invited) {
  //     showMsg(
  //       "info",
  //       "Awaiting Contact to accept its invitation to be a System User."
  //     );
  //   } else {
  //     confirm({
  //       description:
  //         "To be on this Audience, this Contact needs to be a System User. Invite Contact?",
  //       confirmationButtonProps: {
  //         color: "success",
  //         variant: "contained",
  //         sx: {
  //           backgroundColor: "#4CAF50",
  //           color: "#fff",
  //           "&:hover": {
  //             backgroundColor: "#1b5e20",
  //             color: "#fff",
  //             boxShadow:
  //               "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
  //           },
  //         },
  //       },
  //       cancellationButtonProps: {
  //         color: "error",
  //         variant: "contained",
  //         sx: {
  //           backgroundColor: "#d32f2f",
  //           color: "#fff",
  //           "&:hover": {
  //             backgroundColor: "#d32f2f",
  //             color: "#fff",
  //             boxShadow:
  //               "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
  //           },
  //         },
  //       },
  //     })
  //       .then(() => {
  //         inviteContact(data);
  //       })
  //       .catch(() => {
  //         /* ... */
  //       });
  //   }
  // };

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchClientContactsById({ _per_page: 100, _page: 1, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objContactList = response.data.contacts;
        for (let index = 0; index < objContactList.length; index++) {
          const element = objContactList[index];
          if (currentContacts.findIndex((x) => x.id === element.id) > -1) {
            continue;
          }
          const objTr = {};
          objTr.id = element.id;
          objTr.label = element.first_name + " " + element.last_name;
          objTr.invited =
            typeof element.invites !== "undefined" &&
            Array.isArray(element.invites) &&
            element.invites.length > 0;
          objRows.push(objTr);
        }
        setContactList(objRows);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again.");
        onClose();
      });
  }, [objUser, currentContacts]);

  return (
    <>
      {!askInviteContact ? (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
          <DialogTitle sx={{ pb: 0 }}>Add Contact</DialogTitle>
          <Formik
            initialValues={objInitialValues}
            validationSchema={AddContactFormJWTSchema}
            onSubmit={onSubmitForm}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id="AddContactForm" autoComplete="off">
                <DialogContent>
                  {isLoading === false ? (
                    <MDBox mb={0} mt={0}>
                      <Autocomplete
                        options={contactList}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label="Contact"
                          />
                        )}
                        onChange={(event, value) => {
                          if (value === null) {
                            setFieldValue("stlContact", "");
                          } else {
                            setFieldValue("stlContact", value);
                          }
                        }}
                      />
                      <MDBox mt={0.75}>
                        <MDTypography
                          component="div"
                          variant="caption"
                          color="error"
                          fontWeight="regular"
                        >
                          <ErrorMessage
                            name="stlContact"
                            render={(msg) => {
                              return <div>The contact is required</div>;
                            }}
                          />
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  ) : (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  {isSubmiting === false ? (
                    <>
                      <MDButton
                        type="submit"
                        // variant="gradient"
                        color="success"
                        sx={{ padding: "12px 12px" }}
                      >
                        Add
                      </MDButton>
                      <MDButton
                        type="button"
                        variant="outlined"
                        color="dark"
                        sx={{ padding: "12px 12px" }}
                        onClick={onClose}
                      >
                        Cancel
                      </MDButton>
                    </>
                  ) : (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  )}
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      ) : (
        <InviteClientUserDialog
          open={askInviteContact}
          onClose={() => {
            setAskInviteContact(false);
            onClose();
          }}
          onSubmit={() => setAskInviteContact(false)}
          contact={contactToInvite}
          inviteContact={inviteContact}
          objUser={objUser}
        />
      )}
    </>
  );
}
export default AddContactDialog;
