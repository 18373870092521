import { useState } from "react";
import { useDispatch } from "react-redux";
import { getPolicyPreview } from "features/company/policiesActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";

function usePreviewer() {
  const dispatch = useDispatch();
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [policyObj, setPolicyObj] = useState(null);

  const previewDoc = (objUser = null, policyId, policyDocumentId) => {
    setIsLoadingPreview(true);

    dispatch(getPolicyPreview({ objUser, policyId, policyDocumentId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingPreview(false);
        const response = originalPromiseResult;
        // let policyResp = response.data.policyResp;
        // let document_sections = templateResp.template.document_sections ?? [];

        // if (document_sections.length > 0) {
        //   document_sections = document_sections.sort(
        //     (a, b) =>
        //       a.owning_global_section.order - b.owning_global_section.order
        //   );
        // }

        // templateResp.template.document_sections = document_sections;

        setPolicyObj(response.data.policyResp);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingPreview(false);
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Error fetching preview. Please try again");
      });
  };

  return { previewDoc, isLoadingPreview, policyObj, setPolicyObj };
}

export default usePreviewer;
