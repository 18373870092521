import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import Papa from "papaparse";
import MDTypography from "components/MDTypography";

function NewBlankAssetPolicyDialog({ open, type, onClose, onSubmit }) {
  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialData] = useState([]);
  function handleOnFileChange(e) {
    parseCSV(e.target.files[0]);
  }

  function parseCSV(file) {
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setInitialData(
            results.data.filter(
              (item) =>
                !Object.values(item).every((x) => x === null || x === "")
            )
          );
        },
        error: function (err, file, inputElem, reason) {
          console.log("Error:", err, reason);
        },
      });
    }
  }
  return (
    <Dialog
      onClose={() => {
        setInitialData([]);
        onClose();
      }}
      open={open}
      fullWidth={true}
    >
      <DialogTitle sx={{ pb: 0 }}>
        Import data into {type} Asset List
      </DialogTitle>
      <DialogContent dividers>
        <MDTypography variant="h6" fontWeight="bold">
          To set the initial data of the Asset List, use this{" "}
          <a href="/assetTemplate.csv" target="_blank">
            Sample template
          </a>
        </MDTypography>
        <MDBox mb={0} mt={0}>
          <MDInput
            type={"file"}
            onChange={(e) => handleOnFileChange(e)}
            accept={".csv"}
            label="CSV Data"
            variant={"standard"}
            autoFocus
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        {isLoading === false ? (
          <>
            <MDButton
              onClick={() => onSubmit(initialData, type, setIsLoading)}
              // variant="gradient"
              color="success"
              sx={{ padding: "12px 12px" }}
            >
              Create
            </MDButton>
            <MDButton
              type="button"
              variant="outlined"
              color="dark"
              sx={{ padding: "12px 12px" }}
              onClick={onClose}
            >
              Cancel
            </MDButton>
          </>
        ) : (
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default NewBlankAssetPolicyDialog;
