// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { Fragment, useEffect, useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useParams } from "react-router-dom";

import { unwrapResult } from "@reduxjs/toolkit";
import { fetchSsp } from "features/ssp/sspActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDTypography from "components/MDTypography";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { useMaterialUIController } from "context";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  FormControlLabel,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import DashboardActionButton from "views/components/DashboardActionButton";
import useSspPDFDownloader from "../../useSspPDFDownloader";
import tinycolor from "tinycolor2";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { CallMade } from "@mui/icons-material";
import PDFDownloadSettingsDialog from "views/pages/Dashboard/CompanyConfig/components/PDFDownloadSettingsDialog";

const Index = (props) => {
  const { objUser } = props;
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isLoadingSsp, setIsLoadingSsp] = useState(false);
  const [sspData, setSspData] = useState(null);
  const [sspCategories, setSspCategories] = useState([]);
  const [sspObj, setSspObj] = useState(null);
  const [itemScores, setItemScores] = useState({});
  const [oscalSectionsForCCATable, setOscalSectionsForCCATable] = useState([]);
  const [controller] = useMaterialUIController();
  const { downloadPDF, isLoadingPDF } = useSspPDFDownloader();
  const { darkMode } = controller;

  useEffect(() => {
    loadCCA();
  }, []);

  const loadCCA = () => {
    setIsLoadingSsp(true);
    dispatch(fetchSsp({ id, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingSsp(false);
        setSspData(originalPromiseResult.data.sspData);
        setSspCategories(Object.keys(originalPromiseResult.data.sspData));
        setSspObj(originalPromiseResult.data.sspObj);
        setOscalSectionsForCCATable(
          originalPromiseResult.data.oscalSectionsForCCATable
        );
        const scores = {};
        originalPromiseResult.data?.itemScores?.forEach((itemScore) => {
          const color = tinycolor(itemScore.color);
          scores[itemScore.code] = {
            color: color.toString(),
            text_color: itemScore.text_color,
            name: itemScore.name,
            value: itemScore.value,
            possible_points: itemScore.possible_points,
          };
        });
        setItemScores(scores);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingSsp(false);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", objErrorRsp?.data?.message);
          showMsg("error", objErrorRsp?.data?.error_code);
        }
      });
  };

  const policySectionIsInOscalSectionsToBeIncluded = (
    policyDocumentSectionId
  ) => {
    const oscalSectionsToBeIncluded = [...oscalSectionsForCCATable];
    const section = oscalSectionsToBeIncluded.find((oscSection) => {
      return oscSection.owning_global_section.id === policyDocumentSectionId;
    });

    return section != undefined && section;
  };

  const removeTagsAndStyles = (htmlString) => {
    let cleanText = htmlString.replace(/<[^>]*>/g, "");
    cleanText = cleanText.replace(/ style="[^"]*"/g, "");

    return cleanText;
  };

  const conformityCheck = (itemScores, optionChosenScore) => {
    if (
      optionChosenScore !== 0 &&
      parseInt(optionChosenScore) === parseInt(itemScores?.possible_points)
    ) {
      return "met";
    } else if (optionChosenScore === 0) {
      return "not applicable";
    } else {
      return "not met";
    }
  };

  function isValidURL(string) {
    const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
    return regex.test(string);
  }

  /**pdf display settings */
  const [displayPdfSettingsValue, setDisplayPdfSettingValue] = useState(
    objUser?.user?.company?.pdf_display_settings ?? null
  );
  const [openPdfSettingsDialog, setOpenPdfSettingsDialog] = useState(false);
  const onclosePdfSettingsDialog = () => {
    setOpenPdfSettingsDialog(false);
  };
  /**end pdf display settings */

  const btnClickDownloadWispPDF = (dataObj = null) => {
    downloadPDF(objUser, sspObj.id, displayPdfSettingsValue);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardBreadcrumbs />
        <PDFDownloadSettingsDialog
          openPdfSettingsDialog={openPdfSettingsDialog}
          onclosePdfSettingsDialog={onclosePdfSettingsDialog}
          displayPdfSettingsValue={displayPdfSettingsValue}
          setDisplayPdfSettingValue={setDisplayPdfSettingValue}
          startDownload={btnClickDownloadWispPDF}
          dataObj={null}
          isLoadingPDF={isLoadingPDF}
        />
        <Grid container width="100%" spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox
              display="flex"
              gap=".6rem"
              alignItems="right"
              justifyContent="right"
              mb={2}
            >
              {/* {isLoadingPDF ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : ( */}
              <DashboardActionButton
                // action={btnClickDownloadWispPDF}
                action={() => setOpenPdfSettingsDialog(true)}
                btnText="Download Document"
                btnIcon="download"
                textColor="white"
                bgColor="success"
              />
              {/* )} */}
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                width: "100%",
                padding: "1rem",
              }}
            >
              {isLoadingSsp ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <>
                  {sspData === null ? (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="p" fontWeight="regular">
                          CCA not found
                        </MDTypography>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <Grid container>
                        <Grid item xs={12}>
                          <MDTypography
                            mb={3}
                            color="dark"
                            variant="h1"
                            sx={{
                              fontSize: "5rem",
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            CCA For {sspObj?.assessmentEventName}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12}>
                          <MDTypography
                            mb={3}
                            variant="p"
                            sx={{
                              fontSize: "2rem",
                              marginTop: 0,
                              paddingTop: 0,
                              color: "#1f456e",
                            }}
                          >
                            Control Conformity Report
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12}>
                          <h4
                            style={{
                              fontSize: "2.2rem",
                              fontWeight: "normal",
                              display: "block",
                              marginTop: "1.7rem",
                              paddingTop: 0,
                              color: "#00356b",
                            }}
                          >
                            Change Control
                          </h4>
                        </Grid>
                        <Grid item xs={12} mb={5}>
                          <Table
                            style={{ color: darkMode ? "white" : "black" }}
                          >
                            <TableRow
                              sx={{
                                "& td": {
                                  border: "1px solid #ccc",
                                  fontSize: "1rem",
                                },
                              }}
                            >
                              <TableCell>
                                <b>Version</b>
                              </TableCell>
                              <TableCell>
                                <b>Description</b>
                              </TableCell>
                              <TableCell>
                                <b>Creator</b>
                              </TableCell>
                            </TableRow>

                            <TableBody>
                              <TableRow
                                sx={{
                                  "& td": {
                                    fontSize: "1.3rem",
                                  },
                                  "&:last-child td, &:last-child th": {
                                    border: "1px solid #ccc",
                                  },
                                  "& th, & td": {
                                    color: "text.main",
                                  },
                                }}
                              >
                                <TableCell>
                                  {sspObj?.assessmentEventVersion}.0
                                </TableCell>
                                <TableCell>
                                  {sspObj?.assessmentEventName}
                                </TableCell>
                                <TableCell>
                                  {sspObj?.assessmentCreator}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>

                        <Grid item xs={12} mb={5}>
                          <div
                            style={{ color: darkMode && "whitesmoke" }}
                            dangerouslySetInnerHTML={{
                              __html: `<div class='dangerousHTMLDIVClass'>${sspObj?.intro_description}</div>`,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} mb={5}>
                          <hr
                            style={{
                              border: "0.5px solid black",
                              width: "100%",
                            }}
                          />
                        </Grid>
                        {sspCategories.map((category, index) => {
                          return (
                            <Grid item xs={12} pt={0} key={index} mb={3}>
                              <Accordion
                                defaultExpanded={true}
                                sx={
                                  darkMode
                                    ? { backgroundColor: "#2f3958" }
                                    : { backgroundColor: "#fff" }
                                }
                              >
                                <AccordionSummary
                                  sx={{
                                    flexDirection: "row-reverse",
                                    backgroundColor: "#ededed",
                                  }}
                                  expandIcon={<ExpandMoreIcon color="icon" />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <MDTypography variant="h4" p={1}>
                                    Category Name:{" "}
                                    <span style={{ fontWeight: "normal" }}>
                                      {category}
                                    </span>
                                  </MDTypography>
                                </AccordionSummary>

                                <AccordionDetails>
                                  {sspData[category].map(
                                    (control, index, array) => {
                                      return (
                                        <MDBox
                                          sx={{
                                            paddingLeft: "0.6rem",
                                            paddingRight: "0.6rem",
                                          }}
                                        >
                                          <MDTypography
                                            style={{
                                              padding: 0,
                                              marginTop: 0,
                                              paddingTop: `${
                                                index === 0 ? "1rem" : 0
                                              }`,
                                            }}
                                            variant="h4"
                                          >
                                            Control:{" "}
                                            <span
                                              style={{ fontWeight: "normal" }}
                                            >
                                              {control.item}
                                            </span>
                                          </MDTypography>

                                          <Table
                                            sx={{
                                              color: "text.black",
                                              marginTop: "1.5rem",
                                            }}
                                          >
                                            <TableRow
                                              sx={{
                                                "& td": {
                                                  border: "1px solid #ccc",
                                                  fontSize: "1rem",
                                                },
                                              }}
                                            >
                                              <TableCell
                                                sx={{
                                                  backgroundColor: "#323333",
                                                  color: "#fff",
                                                }}
                                              >
                                                <b>CONFORMITY</b>
                                              </TableCell>
                                              <TableCell>
                                                <MDBox
                                                  sx={{
                                                    display: "flex",
                                                    gap: 2,
                                                    paddingLeft: "0.6rem",
                                                  }}
                                                >
                                                  <FormControlLabel
                                                    sx={{ marginRight: 0 }}
                                                    control={
                                                      <Checkbox
                                                        checked={
                                                          conformityCheck(
                                                            itemScores[
                                                              control
                                                                ?.selected_option
                                                                ?.score
                                                            ],
                                                            control
                                                              ?.selected_option
                                                              ?.score
                                                          ) === "met"
                                                        }
                                                        icon={
                                                          <CheckBoxOutlineBlankOutlinedIcon
                                                            style={{
                                                              borderColor:
                                                                "#0b2e4a",
                                                            }}
                                                          />
                                                        }
                                                      />
                                                    }
                                                    label="MET"
                                                  />
                                                  <FormControlLabel
                                                    sx={{ marginRight: 0 }}
                                                    control={
                                                      <Checkbox
                                                        checked={
                                                          conformityCheck(
                                                            itemScores[
                                                              control
                                                                ?.selected_option
                                                                ?.score
                                                            ],
                                                            control
                                                              ?.selected_option
                                                              ?.score
                                                          ) === "not met"
                                                        }
                                                        icon={
                                                          <CheckBoxOutlineBlankOutlinedIcon
                                                            style={{
                                                              borderColor:
                                                                "#0b2e4a",
                                                            }}
                                                          />
                                                        }
                                                      />
                                                    }
                                                    label="NOT MET"
                                                  />
                                                  <FormControlLabel
                                                    sx={{ marginRight: 0 }}
                                                    control={
                                                      <Checkbox
                                                        checked={
                                                          conformityCheck(
                                                            itemScores[
                                                              control
                                                                ?.selected_option
                                                                ?.score
                                                            ],
                                                            control
                                                              ?.selected_option
                                                              ?.score
                                                          ) === "not applicable"
                                                        }
                                                        icon={
                                                          <CheckBoxOutlineBlankOutlinedIcon
                                                            style={{
                                                              borderColor:
                                                                "#0b2e4a",
                                                            }}
                                                          />
                                                        }
                                                      />
                                                    }
                                                    label="NOT APPLICABLE"
                                                  />
                                                </MDBox>
                                              </TableCell>
                                            </TableRow>
                                            <TableRow
                                              sx={{
                                                "& td": {
                                                  border: "1px solid #ccc",
                                                  fontSize: "1rem",
                                                },
                                              }}
                                            >
                                              <TableCell
                                                sx={{
                                                  backgroundColor: "#323333",
                                                  color: "#fff",
                                                }}
                                              >
                                                <b>ITEM SCORE</b>
                                              </TableCell>
                                              <TableCell>
                                                <Chip
                                                  label={
                                                    itemScores[
                                                      control?.selected_option
                                                        ?.score
                                                    ]?.name || "N/A"
                                                  }
                                                  variant="outlined"
                                                  sx={{
                                                    backgroundColor:
                                                      itemScores[
                                                        control?.selected_option
                                                          ?.score
                                                      ]?.color,
                                                    color:
                                                      itemScores[
                                                        control?.selected_option
                                                          ?.score
                                                      ]?.text_color,
                                                  }}
                                                />
                                              </TableCell>
                                            </TableRow>
                                            <TableRow
                                              sx={{
                                                "& td": {
                                                  border: "1px solid #ccc",
                                                  fontSize: "1rem",
                                                },
                                              }}
                                            >
                                              <TableCell
                                                sx={{
                                                  backgroundColor: "#323333",
                                                  color: "#fff",
                                                }}
                                              >
                                                <b>ASSESSMENT SCORE</b>
                                              </TableCell>
                                              <TableCell>
                                                <Chip
                                                  label={`Score: ${
                                                    itemScores[
                                                      control?.selected_option
                                                        ?.score
                                                    ]?.value || "N/A"
                                                  } / ${
                                                    itemScores[
                                                      control?.selected_option
                                                        ?.score
                                                    ]?.possible_points || "N/A"
                                                  }`}
                                                  variant="outlined"
                                                  sx={{
                                                    color:
                                                      itemScores[
                                                        control?.selected_option
                                                          ?.score
                                                      ]?.color,
                                                    borderColor:
                                                      itemScores[
                                                        control?.selected_option
                                                          ?.score
                                                      ]?.color,
                                                  }}
                                                />
                                              </TableCell>
                                            </TableRow>
                                            <TableRow
                                              sx={{
                                                "& td": {
                                                  border: "1px solid #ccc",
                                                  fontSize: "1rem",
                                                },
                                              }}
                                            >
                                              <TableCell
                                                style={{
                                                  color:
                                                    darkMode && "whitesmoke",
                                                }}
                                              >
                                                <b>Control owner</b>
                                              </TableCell>
                                              <TableCell>
                                                <b style={{ color: "red" }}>
                                                  {control?.ctrl_owner ? (
                                                    <>
                                                      {
                                                        control?.ctrl_owner
                                                          ?.name
                                                      }
                                                      {
                                                        control?.ctrl_owner
                                                          ?.last_name
                                                      }
                                                      (
                                                      {
                                                        control?.ctrl_owner
                                                          ?.email
                                                      }
                                                      )
                                                    </>
                                                  ) : (
                                                    "-"
                                                  )}
                                                </b>
                                              </TableCell>
                                            </TableRow>
                                            <TableRow
                                              sx={{
                                                "& td": {
                                                  border: "1px solid #ccc",
                                                  fontSize: "1rem",
                                                },
                                              }}
                                            >
                                              <TableCell
                                                style={{
                                                  color:
                                                    darkMode && "whitesmoke",
                                                }}
                                              >
                                                <b>Compliance score</b>
                                              </TableCell>
                                              <TableCell>
                                                <b style={{ color: "red" }}>
                                                  {control?.sprs_score || "-"}
                                                </b>
                                              </TableCell>
                                            </TableRow>
                                          </Table>

                                          {/* implementation statement */}
                                          {control?.implementation_statement &&
                                          control?.implementation_statement !==
                                            "" ? (
                                            <>
                                              <MDTypography
                                                style={{
                                                  padding: 0,
                                                  paddingTop: "1.3rem",
                                                  fontWeight: "normal",
                                                }}
                                                variant="h4"
                                              >
                                                Implementation Statement:
                                              </MDTypography>
                                              <MDTypography
                                                style={{
                                                  padding: 0,
                                                  fontWeight: "normal",
                                                  color: "black",
                                                }}
                                                variant="h5"
                                              >
                                                {
                                                  control?.implementation_statement
                                                }
                                              </MDTypography>
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {/* evidence location */}
                                          {control?.evidence_location &&
                                          control?.evidence_location !== "" ? (
                                            <>
                                              <MDTypography
                                                style={{
                                                  padding: 0,
                                                  paddingTop: "1.3rem",
                                                  fontWeight: "normal",
                                                }}
                                                variant="h4"
                                              >
                                                Evidence Location:
                                              </MDTypography>
                                              {control.evidence_location_type ==
                                                "text" &&
                                              !isValidURL(
                                                control.evidence_location
                                              ) ? (
                                                <MDTypography
                                                  style={{
                                                    padding: 0,
                                                    fontWeight: "normal",
                                                    color: "black",
                                                  }}
                                                  variant="h5"
                                                >
                                                  {control.evidence_location}
                                                </MDTypography>
                                              ) : (
                                                <a
                                                  href={
                                                    control.evidence_location
                                                  }
                                                  target="_blank"
                                                >
                                                  <MDButton
                                                    variant="gradient"
                                                    color="dark"
                                                    sx={{
                                                      padding: "5px 15px",
                                                      marginTop: "0.3rem",
                                                    }}
                                                  >
                                                    <Fragment>
                                                      <Icon fontSize="large">
                                                        <CallMade />
                                                      </Icon>
                                                      <MDTypography
                                                        fontWeight="bold"
                                                        sx={{
                                                          marginLeft: "0.75rem",
                                                        }}
                                                        variant="button"
                                                        color="white"
                                                      >
                                                        View Evidence
                                                      </MDTypography>
                                                    </Fragment>
                                                  </MDButton>
                                                </a>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {/* responsibility */}
                                          {control?.responsibility &&
                                          control?.responsibility !== "" ? (
                                            <>
                                              <MDTypography
                                                style={{
                                                  padding: 0,
                                                  paddingTop: "1.3rem",
                                                  fontWeight: "normal",
                                                }}
                                                variant="h4"
                                              >
                                                Responsibility:
                                              </MDTypography>
                                              <MDTypography
                                                style={{
                                                  padding: 0,
                                                  fontWeight: "normal",
                                                  color: "black",
                                                }}
                                                variant="h5"
                                              >
                                                {control.responsibility}
                                              </MDTypography>
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {/* notes */}
                                          {control?.question_notes &&
                                          control?.question_notes !== "" ? (
                                            <>
                                              <MDTypography
                                                style={{
                                                  padding: 0,
                                                  paddingTop: "1.3rem",
                                                  fontWeight: "normal",
                                                }}
                                                variant="h4"
                                              >
                                                Notes:
                                              </MDTypography>
                                              <MDTypography
                                                style={{
                                                  padding: 0,
                                                  fontWeight: "normal",
                                                  color: "black",
                                                }}
                                                variant="h5"
                                              >
                                                {control?.question_notes}
                                              </MDTypography>
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {/* statements and their sections */}

                                          {/* policy statements */}
                                          {control.selected_policy_statements
                                            .length === 0 ? (
                                            ""
                                          ) : (
                                            <>
                                              <MDTypography
                                                style={{
                                                  padding: 0,
                                                  paddingTop: "1.3rem",
                                                  fontWeight: "normal",
                                                }}
                                                variant="h4"
                                              >
                                                Policy Statements:
                                              </MDTypography>

                                              <Table
                                                sx={{
                                                  color: "text.black",
                                                  marginTop: "5px",
                                                }}
                                              >
                                                <TableRow
                                                  sx={{
                                                    "& td": {
                                                      border: "1px solid #ccc",
                                                      fontSize: "1.2rem",
                                                      backgroundColor: "#000",
                                                      color: "#fff",
                                                    },
                                                  }}
                                                >
                                                  <TableCell>Title</TableCell>
                                                  {oscalSectionsForCCATable.map(
                                                    (oscalSection) => {
                                                      return (
                                                        <TableCell>
                                                          {
                                                            oscalSection
                                                              ?.owning_global_section
                                                              ?.title
                                                          }
                                                        </TableCell>
                                                      );
                                                    }
                                                  )}
                                                </TableRow>

                                                <TableBody>
                                                  {control.selected_policy_statements.map(
                                                    (selected_pol) => {
                                                      return (
                                                        <TableRow
                                                          sx={{
                                                            "& td": {
                                                              fontSize:
                                                                "1.1rem",
                                                            },
                                                            "& td, & th": {
                                                              border:
                                                                "1px solid #ccc",
                                                            },
                                                          }}
                                                        >
                                                          <TableCell>
                                                            {selected_pol
                                                              ?.owning_policy
                                                              ?.kb_article_link ? (
                                                              <a
                                                                href={
                                                                  selected_pol
                                                                    .owning_policy
                                                                    .kb_article_link
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                style={{
                                                                  color:
                                                                    "#547b80",
                                                                }}
                                                              >
                                                                <b>
                                                                  <u>
                                                                    {
                                                                      selected_pol
                                                                        .owning_policy
                                                                        .title
                                                                    }
                                                                  </u>
                                                                </b>
                                                              </a>
                                                            ) : (
                                                              <>
                                                                {
                                                                  selected_pol
                                                                    .owning_policy
                                                                    .title
                                                                }
                                                              </>
                                                            )}
                                                          </TableCell>

                                                          {selected_pol.owning_policy ? (
                                                            <>
                                                              {selected_pol
                                                                .owning_policy
                                                                .doc_type ===
                                                              "oscal" ? (
                                                                <>
                                                                  {selected_pol.owning_policy_document.documentSections.map(
                                                                    (
                                                                      section
                                                                    ) => {
                                                                      return policySectionIsInOscalSectionsToBeIncluded(
                                                                        section
                                                                          .owning_global_section
                                                                          .id
                                                                      ) ? (
                                                                        <TableCell
                                                                          key={
                                                                            section.id
                                                                          }
                                                                        >
                                                                          {section.section_content ==
                                                                            "" ||
                                                                          section.section_content ==
                                                                            null ? (
                                                                            ""
                                                                          ) : (
                                                                            <div
                                                                              dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                  section
                                                                                    .section_content
                                                                                    .length >
                                                                                  300
                                                                                    ? removeTagsAndStyles(
                                                                                        section.section_content
                                                                                      ).substring(
                                                                                        0,
                                                                                        300
                                                                                      ) +
                                                                                      "..."
                                                                                    : removeTagsAndStyles(
                                                                                        section.section_content
                                                                                      ),
                                                                              }}
                                                                            />
                                                                          )}
                                                                        </TableCell>
                                                                      ) : (
                                                                        ""
                                                                      );
                                                                    }
                                                                  )}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  {/* if its not an OSCAL document, then simply show empty table cells */}
                                                                  {oscalSectionsForCCATable.map(
                                                                    (
                                                                      oscalSection
                                                                    ) => {
                                                                      return (
                                                                        <TableCell>
                                                                          -
                                                                        </TableCell>
                                                                      );
                                                                    }
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </TableRow>
                                                      );
                                                    }
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </>
                                          )}

                                          {/* procedure statements */}
                                          {control.selected_procedure_statements
                                            .length === 0 ? (
                                            ""
                                          ) : (
                                            <>
                                              {/* new */}
                                              <MDTypography
                                                style={{
                                                  padding: 0,
                                                  paddingTop: "1.3rem",
                                                  marginTop: "2rem",
                                                  fontWeight: "normal",
                                                }}
                                                variant="h4"
                                              >
                                                SOP Statements:
                                              </MDTypography>

                                              <Table
                                                sx={{
                                                  color: "text.black",
                                                  marginTop: "5px",
                                                }}
                                              >
                                                <TableRow
                                                  sx={{
                                                    "& td": {
                                                      border: "1px solid #ccc",
                                                      fontSize: "1.2rem",
                                                      backgroundColor: "#000",
                                                      color: "#fff",
                                                    },
                                                  }}
                                                >
                                                  <TableCell>Title</TableCell>
                                                  {oscalSectionsForCCATable.map(
                                                    (oscalSection) => {
                                                      return (
                                                        <TableCell>
                                                          {
                                                            oscalSection
                                                              ?.owning_global_section
                                                              ?.title
                                                          }
                                                        </TableCell>
                                                      );
                                                    }
                                                  )}
                                                </TableRow>

                                                <TableBody>
                                                  {control.selected_procedure_statements.map(
                                                    (selected_proc) => {
                                                      return (
                                                        <TableRow
                                                          sx={{
                                                            "& td": {
                                                              fontSize:
                                                                "1.1rem",
                                                            },
                                                            "& td, & th": {
                                                              border:
                                                                "1px solid #ccc",
                                                            },
                                                          }}
                                                        >
                                                          <TableCell>
                                                            {selected_proc
                                                              ?.owning_procedure
                                                              ?.kb_article_link ? (
                                                              <a
                                                                href={
                                                                  selected_proc
                                                                    .owning_procedure
                                                                    .kb_article_link
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                style={{
                                                                  color:
                                                                    "#547b80",
                                                                }}
                                                              >
                                                                <b>
                                                                  <u>
                                                                    {
                                                                      selected_proc
                                                                        .owning_procedure
                                                                        .title
                                                                    }
                                                                  </u>
                                                                </b>
                                                              </a>
                                                            ) : (
                                                              <>
                                                                {
                                                                  selected_proc
                                                                    .owning_procedure
                                                                    .title
                                                                }
                                                              </>
                                                            )}
                                                          </TableCell>

                                                          {selected_proc.owning_procedure ? (
                                                            <>
                                                              {selected_proc
                                                                .owning_procedure
                                                                .doc_type ===
                                                              "oscal" ? (
                                                                <>
                                                                  {selected_proc.owning_policy_document.documentSections.map(
                                                                    (
                                                                      section
                                                                    ) => {
                                                                      return policySectionIsInOscalSectionsToBeIncluded(
                                                                        section
                                                                          .owning_global_section
                                                                          .id
                                                                      ) ? (
                                                                        <TableCell
                                                                          key={
                                                                            section.id
                                                                          }
                                                                        >
                                                                          {section.section_content ==
                                                                            "" ||
                                                                          section.section_content ==
                                                                            null ? (
                                                                            ""
                                                                          ) : (
                                                                            <div
                                                                              dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                  section
                                                                                    .section_content
                                                                                    .length >
                                                                                  300
                                                                                    ? removeTagsAndStyles(
                                                                                        section.section_content
                                                                                      ).substring(
                                                                                        0,
                                                                                        300
                                                                                      ) +
                                                                                      "..."
                                                                                    : removeTagsAndStyles(
                                                                                        section.section_content
                                                                                      ),
                                                                              }}
                                                                            />
                                                                          )}
                                                                        </TableCell>
                                                                      ) : (
                                                                        ""
                                                                      );
                                                                    }
                                                                  )}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  {/* if its not an OSCAL document, then simply show empty table cells */}
                                                                  {oscalSectionsForCCATable.map(
                                                                    (
                                                                      oscalSection
                                                                    ) => {
                                                                      return (
                                                                        <TableCell>
                                                                          -
                                                                        </TableCell>
                                                                      );
                                                                    }
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </TableRow>
                                                      );
                                                    }
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </>
                                          )}

                                          {index === array.length - 1 ? (
                                            ""
                                          ) : (
                                            <hr
                                              style={{
                                                border: "0.4px solid #c1c6c9",
                                                display: "block",
                                                margin: "2rem 0",
                                                width: "100%",
                                              }}
                                            />
                                          )}
                                        </MDBox>
                                      );
                                    }
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  );
};

export default Index;
