import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  FormControlLabel,
} from "@mui/material";
import FormField from "layouts/pages/users/new-user/components/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import {
  createTaskList,
  updateTaskList,
} from "features/company/taskListActions";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { standardDateFormat } from "utils/general";

const TaskListFormSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  due_date: Yup.date().required("Due date is required"),
  priority_level: Yup.number().required("Priority level is required"),
});

const TaskListItemNewDialog = ({
  open,
  onClose,
  onSubmit,
  objUser,
  taskList,
  taskLists,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const [taskListList, setTaskListList] = useState(taskLists);

  useEffect(() => {
    if (taskLists) {
      setTaskListList(taskLists.filter((item) => item.id !== taskList?.id));
    }
  }, [taskLists, taskList]);

  const onSubmitForm = async (values) => {
    const objData = {
      title: values.title,
      description: values.description,
      due_date: values.due_date,
      priority_level: values.priority_level,
    };
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Task List Item</DialogTitle>
      <Formik
        initialValues={{
          title: taskList?.title || "",
          description: taskList?.description || "",
          due_date: taskList?.due_date || "",
          priority_level: taskList?.priority_level || "",
        }}
        validationSchema={TaskListFormSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form id="TaskListForm" autoComplete="off">
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Title"
                    name="title"
                    value={values.title}
                    placeholder="Task List Title"
                    error={errors.title && touched.title}
                    success={values.title?.length > 0 && !errors.title}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Description"
                    name="description"
                    value={values.description}
                    placeholder="Task List Description"
                    error={errors.description && touched.description}
                    success={
                      values.description?.length > 0 && !errors.description
                    }
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="date"
                    label="Due Date"
                    name="due_date"
                    value={values.due_date}
                    error={errors.due_date && touched.due_date}
                    success={values.due_date?.length > 0 && !errors.due_date}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Priority Level"
                    name="priority_level"
                    value={values.priority_level}
                    error={errors.priority_level && touched.priority_level}
                    success={
                      values.priority_level?.length > 0 &&
                      !errors.priority_level
                    }
                    disabled={!editMode}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {!isLoading ? (
                <>
                  {editMode ? (
                    <MDButton
                      type="submit"
                      color="success"
                      disabled={!editMode}
                    >
                      Update
                    </MDButton>
                  ) : null}
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    onClick={onClose}
                  >
                    {editMode ? "Cancel" : "Close"}
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner size="lg" />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default TaskListItemNewDialog;
