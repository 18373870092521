import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate, useLocation } from "react-router-dom";
const TemplateNameBtnTr = (props) => {
  const { value, column, row } = props;
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const btnOnClickTemplateName = (objTemplateData) => {
    routeDictionary[objTemplateData.element.id] = value;
    navigate(
      `/clients/${objTemplateData.element.client_id}/templates/${objTemplateData.element.id}`,
      { state: { routeDictionary } }
    );
  };

  return (
    <MDButton
      variant="text"
      onClick={() => btnOnClickTemplateName(row.original)}
    >
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default TemplateNameBtnTr;
