import {  useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { desc_to_types } from "../AssessmentEventsTable/RiskMatrixLevels";
import Grid from "@mui/material/Grid";
import { addToRiskMatrix } from "features/company/assessmentsActions.js";
import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage } from "formik";
import { useConfirm } from "material-ui-confirm";
import { bulkAddToRiskMatrix } from "features/company/assessmentsActions";
const objInitialValues = { impact: "1", probability: "1" };
const AddNewRiskMatrixFormJWTSchema = Yup.object().shape({
  impact: Yup.string().required("The Impact is required."),
  probability: Yup.string().required("The Probability is required."),
});

function AddToRiskMatrixDialog({
  open,
  onClose,
  onSubmit,
  objUser,
  eventId,
  itemId,
  selectedQuestions,
}) {
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [isSubmittingEvent, setIsSubmittingEvent] = useState(false);
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    let questionIds = selectedQuestions;
    if (questionIds.includes(itemId)) {
      questionIds = questionIds.filter((item) => item !== itemId);
    }
    const objPostData = {
      impact: parseInt(values.impact),
      probability: parseInt(values.probability),
      ...(itemId ? { itemId } : { questionIds }),
    };
    setIsSubmittingEvent(true);

    dispatch(itemId? addToRiskMatrix({ objPostData, objUser, eventId }) : bulkAddToRiskMatrix({ objPostData, objUser, eventId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        onSubmit();
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized");
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        }
      })
      .finally(() => {
        setIsSubmittingEvent(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Add New To Risk Matrix</DialogTitle>
      {isLoadingTemplates === true ? (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      ) : (
        <Formik
          initialValues={objInitialValues}
          validationSchema={AddNewRiskMatrixFormJWTSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id="AddNewTemplateForm" autoComplete="off">
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item mb={0} mt={0} sm={6}>
                    <Autocomplete
                      name="impact"
                      disableClearable
                      value={values.impact}
                      options={Object.keys(desc_to_types)}
                      getOptionLabel={(option) => (option ? option : "")}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="standard"
                          label="Impact Level (1-Low to 5-High)"
                          fullWidth
                        />
                      )}
                      onChange={(event, value) => {
                        if (value === null) {
                          setFieldValue("impact", "");
                        } else {
                          setFieldValue("impact", value);
                        }
                      }}
                    />
                    <MDBox mt={0.75}>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="error"
                        fontWeight="regular"
                      >
                        <ErrorMessage name="impact" />
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item mb={0} mt={0} sm={6}>
                    <Autocomplete
                      name="probability"
                      disableClearable
                      value={values.probability}
                      options={Object.keys(desc_to_types)}
                      getOptionLabel={(option) => (option ? option : "")}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="standard"
                          label="Probability Level (1-Low to 5-High)"
                          fullWidth
                        />
                      )}
                      onChange={(event, value) => {
                        if (value === null) {
                          setFieldValue("probability", "");
                        } else {
                          setFieldValue("probability", value);
                        }
                      }}
                    />
                    <MDBox mt={0.75}>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="error"
                        fontWeight="regular"
                      >
                        <ErrorMessage name="probability" />
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                {isSubmittingEvent === false ? (
                  <>
                    <MDButton
                      type="submit"
                      color="success"
                      sx={{ padding: "12px 12px" }}
                    >
                      Add
                    </MDButton>
                    <MDButton
                      type="button"
                      variant="outlined"
                      color="dark"
                      sx={{ padding: "12px 12px" }}
                      onClick={onClose}
                    >
                      Cancel
                    </MDButton>
                  </>
                ) : (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
}

export default AddToRiskMatrixDialog;
