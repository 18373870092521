import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const PolicyRedirect = () => {
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/policies`, { state: { routeDictionary } });
  });

  return <div>PolicyRedirect</div>;
};

export default PolicyRedirect;
