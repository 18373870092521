import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
// formik components
import { Form, Formik } from "formik";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  sendToZappier,
  saveExtraInfo,
} from "features/company/insuranceActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDTypography from "components/MDTypography";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
import { objAllIndustries } from "helpers/CountryHelpers/IndustryHelpers";
import { objAllAggregates } from "helpers/CountryHelpers/AggregateHelpers";
import InfoFormFormikWrapper from "./InfoFormFormikWrapper";
import SecurityFormFormikWrapper from "./SecurityFormFormikWrapper";
import AdditionalFormFormikWrapper from "./AdditionalFormFormikWrapper";
import PartnerFormFormikWrapper from "./PartnerFormFormikWrapper";

const roles = [
  "Licenced Insurance Agent",
  "MSP or MSSP",
  "Internal IT",
  "An applicant looking for a policy through FifthWall Soltution",
];

const InfoForm = (props) => {
  const { objUser, setSubmitted, extraInfo } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [generalInfo, setGeneralInfo] = useState({});
  const [securityInfo, setSecurityInfo] = useState({});
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [partnerInfo, setPartnerInfo] = useState({});
  const [roleFormFiller, setRoleFormFiller] = useState(true);
  const [objStates, setObjStates] = useState(objUsStates);
  const [objIndustries, setObjIndustries] = useState(objAllIndustries);
  const [objAggregates, setObjAggregates] = useState(objAllAggregates);
  const [objInfoFormErrors, setObjInfoFormErrors] = useState({});
  const [MFAchecklist, setMFAchecklist] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [flag_excludedactivities, setflag_excludedactivities] = useState([]);

  const [objInitialGeneralValues, setObjInitialGeneralValues] = useState({
    gtn_mfa: extraInfo?.gtn_mfa ?? "",
    gtn_mfa_vendor: extraInfo?.gtn_mfa_vendor ?? "",
    gtn_mfa_tool: extraInfo?.gtn_mfa_tool ?? "",
    gtn_security_awareness: extraInfo?.gtn_security_awareness ?? "",
    gtn_security_awareness_vendor:
      extraInfo?.gtn_security_awareness_vendor ?? "",
    gtn_security_awareness_tool: extraInfo?.gtn_security_awareness_tool ?? "",
    gtn_seperate_backup: extraInfo?.gtn_seperate_backup ?? "",
    gtn_seperate_backup_vendor: extraInfo?.gtn_seperate_backup_vendor ?? "",
    gtn_seperate_backup_tool: extraInfo?.gtn_seperate_backup_tool ?? "",
    gtn_endpoint_managed: extraInfo?.gtn_endpoint_managed ?? "",
    gtn_endpoint_managed_vendor: extraInfo?.gtn_endpoint_managed_vendor ?? "",
    gtn_endpoint_managed_tool: extraInfo?.gtn_endpoint_managed_tool ?? "",
    gtn_vulnerability: extraInfo?.gtn_vulnerability ?? "",
    gtn_vulnerability_vendor: extraInfo?.gtn_vulnerability_vendor ?? "",
    gtn_vulnerability_tool: extraInfo?.gtn_vulnerability_tool ?? "",
    role_formfiller: "",
    howDid: "",
    namedinsured: "",
    contactfirstname: "",
    contactlastname: "",
    contactemail: "",
    contacttitle: "",
    contactphone: "",
    extension: "",
    flag_subsidiary: "",
    subsidiaryentity: "",
    flag_currentcyberpolicy: extraInfo?.active_cyber_policy ?? "",
    file_currentcyberpolicy: "",
    entitycountry: "",
    entityaddress: "",
    entitycity: "",
    entitystate: "",
    entityzipcode: "",
    entitywebsite: "",
    industry: extraInfo?.industry ?? "",
    naicscode: extraInfo?.naics_code ?? "",
    flag_excludedactivities: "",
    flag_franchise: "",
    entityestablishyear: "",
    numberemployees: "",
    revenue_priorFY: "",
    revenue_estnextFY: "",
    percentagedomesticsales: "",
    flag_losslast3years: "",
    desiredAggregate: "",
    isThe: "",
    estlossimpact: "",
    notes_MFA: "",
  });

  const [objInitialSecurityValues, setObjInitialSecurityValues] = useState({
    flag_formalbackups: "",
    flag_BUsegregation: "",
    notes_backups: "",
    flag_EDR_MDR: "",
    flag_centralantivirussys: "",
    notes_edr_ngav: "",
    vulnmanagementdays: "",
    notes_patching: "",
    flag_cybertraining: "",
    flag_cybertrainingphishingsim: "No",
    flag_wiretransferdualtransfer: "",
    notes_awarenessTraining: "",
    flag_protectpropertyprivacyrights: "",
  });

  const [objInitialAdditionalValues, setObjInitialAdditionalValues] = useState({
    flag_recordsthirdparty: "",
    estnumberofrecordsPII: "",
    estnumberofrecordsPHI: "",
    flag_paymentcard: "",
    estnumberofrecordsPCI: "",
    estnumberbiometricrecords: "",
    flag_PCIcompliance: "",
    flag_PCIcompliance_outsourced: "",
    thirdpartydataservicing: "",
    flag_247SOC: "",
    domainadminserviceaccts: "",
    flag_RDPviaVPNonly: "",
    flag_localadminrights: "",
    flag_pam: "",
    flag_pamallprivilegedaccts: "",
    pamprovider: "",
    flag_pammfa: "",
    notes_pam: "",
    flag_MFArestricted: "",
    flag_vulnscans: "",
    EDRalldomaincontrollers: "",
    flag_bringyourowndevice: "",
    flag_DNS: "",
    flag_macrodisable: "",
    flag_SIEM: "",
    flag_encryptionenforced: "",
    flag_weeklyalerts_reports: "",
    backupfrequency: "",
    flag_fullencryptedbackups: "",
    flag_lessthan48hrrestore: "",
    flag_BUsimmutable: "",
    notes_backups_restoration: "",
    flag_approvedIR: "",
    notes_IR: "",
    flag_self_hostedExchange: "",
    flag_auditexchangeservers: "",
    flag_emailfilterphishing_ransomware: "",
    flag_firewallbestpractices: "",
    flag_3rdpartysensitiveinfostorage: "",
    flag_3rdpartybaseline: "",
  });

  const [objInitialPartnerValues, setObjInitialPartnerValues] = useState({
    agentcontact: "",
    agentContact: "",
    agencyName: "",
    MSPfirstcontact: "",
    MSPlastcontact: "",
    MSPemail: "",
    MSPsource: "",
    securityContact: "",
    MSPphone: "",
    additionalNotes: "",
  });

  const GeneralFormJWTSchema = useMemo(() => {
    let schema = Yup.object().shape({
      role_formfiller: Yup.string().required("This field is required."),
    });

    if (roleFormFiller == "Licenced Insurance Agent") {
      schema = Yup.object().shape({
        role_formfiller: Yup.string().required("This field is required."),
        namedinsured: Yup.string().required("This field is required."),
        contactfirstname: Yup.string().required("This field is required."),
        contactlastname: Yup.string().required("This field is required."),
        contactemail: Yup.string().email().required("This field is required."),
        contactphone: Yup.string().required("This field is required."),
        flag_subsidiary: Yup.string().required("This field is required."),
        entityaddress: Yup.string().required("This field is required."),
        entitycity: Yup.string().required("This field is required."),
        entitystate: Yup.string().required("This field is required."),
        entityzipcode: Yup.string()
          .required("This field is required.")
          .min(4, "Please enter a valid zip code."),
        entitywebsite: Yup.string().required("This field is required."),
        industry: Yup.string().required("This field is required."),
        naicscode: Yup.string().required("This field is required."),
        flag_franchise: Yup.string().required("This field is required."),
        entityestablishyear: Yup.string().required("This field is required."),
        numberemployees: Yup.number().required("This field is required."),
        revenue_priorFY: Yup.number().required("This field is required."),
        revenue_estnextFY: Yup.number().required("This field is required."),
        flag_losslast3years: Yup.string().required("This field is required."),
        percentagedomesticsales: Yup.number()
          .required("This field is required.")
          .max(100, "Percentage cannot exceed 100"),
      });
    } else if (
      roleFormFiller == "Internal IT" ||
      roleFormFiller ==
        "An applicant looking for a policy through FifthWall Soltution"
    ) {
      schema = Yup.object().shape({
        role_formfiller: Yup.string().required("This field is required."),
        howDid: Yup.string().required("This field is required."),
        namedinsured: Yup.string().required("This field is required."),
        contactfirstname: Yup.string().required("This field is required."),
        contactlastname: Yup.string().required("This field is required."),
        contactemail: Yup.string().email().required("This field is required."),
        contactphone: Yup.string().required("This field is required."),
        flag_subsidiary: Yup.string().required("This field is required."),
        entityaddress: Yup.string().required("This field is required."),
        entitycity: Yup.string().required("This field is required."),
        entitystate: Yup.string().required("This field is required."),
        entityzipcode: Yup.string()
          .required("This field is required.")
          .min(4, "Please enter a valid zip code."),
        entitywebsite: Yup.string().required("This field is required."),
        industry: Yup.string().required("This field is required."),
        naicscode: Yup.string().required("This field is required."),
        flag_franchise: Yup.string().required("This field is required."),
        entityestablishyear: Yup.string().required("This field is required."),
        numberemployees: Yup.number().required("This field is required."),
        revenue_priorFY: Yup.number().required("This field is required."),
        revenue_estnextFY: Yup.number().required("This field is required."),
        flag_losslast3years: Yup.string().required("This field is required."),
        percentagedomesticsales: Yup.number()
          .required("This field is required.")
          .max(100, "Percentage cannot exceed 100"),
      });
    } else if (roleFormFiller == "MSP or MSSP") {
      schema = Yup.object().shape({
        role_formfiller: Yup.string().required("This field is required."),
        contactphone: Yup.string().required("This field is required."),
        entityaddress: Yup.string().required("This field is required."),
        entitycity: Yup.string().required("This field is required."),
        entitystate: Yup.string().required("This field is required."),
        entityzipcode: Yup.string()
          .required("This field is required.")
          .min(4, "Please enter a valid zip code."),
        namedinsured: Yup.string().required("This field is required."),
        contactfirstname: Yup.string().required("This field is required."),
        contactlastname: Yup.string().required("This field is required."),
        contactemail: Yup.string().email().required("This field is required."),
        entitywebsite: Yup.string().required("This field is required."),
        flag_losslast3years: Yup.string().required("This field is required."),
        percentagedomesticsales: Yup.number()
          .required("This field is required.")
          .max(100, "Percentage cannot exceed 100"),
      });
    }

    return schema;
  }, [roleFormFiller]);

  const SecurityFormJWTSchema = useMemo(() => {
    let schema = Yup.object().shape({
      role_formfiller: Yup.string().required("This field is required."),
    });

    if (roleFormFiller == "Licenced Insurance Agent") {
      schema = Yup.object().shape({
        flag_formalbackups: Yup.string().required("This field is required."),
        flag_BUsegregation: Yup.string().required("This field is required."),
        flag_EDR_MDR: Yup.string().required("This field is required."),
        flag_centralantivirussys: Yup.string().required(
          "This field is required."
        ),
        vulnmanagementdays: Yup.string().required("This field is required."),
        flag_cybertraining: Yup.string().required("This field is required."),
        flag_cybertrainingphishingsim: Yup.string().required(
          "This field is required."
        ),
        flag_wiretransferdualtransfer: Yup.string().required(
          "This field is required."
        ),
        flag_protectpropertyprivacyrights: Yup.string().required(
          "This field is required."
        ),
      });
    } else if (
      roleFormFiller == "Internal IT" ||
      roleFormFiller ==
        "An applicant looking for a policy through FifthWall Soltution"
    ) {
      schema = Yup.object().shape({
        flag_formalbackups: Yup.string().required("This field is required."),
        flag_BUsegregation: Yup.string().required("This field is required."),
        flag_EDR_MDR: Yup.string().required("This field is required."),
        flag_centralantivirussys: Yup.string().required(
          "This field is required."
        ),
        vulnmanagementdays: Yup.string().required("This field is required."),
        flag_cybertraining: Yup.string().required("This field is required."),
        flag_cybertrainingphishingsim: Yup.string().required(
          "This field is required."
        ),
        flag_wiretransferdualtransfer: Yup.string().required(
          "This field is required."
        ),
        flag_protectpropertyprivacyrights: Yup.string().required(
          "This field is required."
        ),
      });
    } else if (roleFormFiller == "MSP or MSSP") {
      schema = Yup.object().shape({
        flag_formalbackups: Yup.string().required("This field is required."),
        flag_BUsegregation: Yup.string().required("This field is required."),
        flag_EDR_MDR: Yup.string().required("This field is required."),
        flag_centralantivirussys: Yup.string().required(
          "This field is required."
        ),
        vulnmanagementdays: Yup.string().required("This field is required."),
        flag_cybertraining: Yup.string().required("This field is required."),
        flag_cybertrainingphishingsim: Yup.string().required(
          "This field is required."
        ),
      });
    }

    return schema;
  }, [roleFormFiller]);

  const PartnerFormJWTSchema = useMemo(() => {
    let schema = Yup.object().shape({
      role_formfiller: Yup.string().required("This field is required."),
    });

    if (roleFormFiller == "Licenced Insurance Agent") {
      schema = Yup.object().shape({
        agentcontact: Yup.string().required("This field is required."),
        agentContact: Yup.string().email().required("This field is required."),
        agencyName: Yup.string().required("This field is required."),
      });
    } else if (roleFormFiller == "Internal IT") {
      schema = Yup.object().shape({
        agentcontact: Yup.string().required("This field is required."),
        agentContact: Yup.string().email().required("This field is required."),
        agencyName: Yup.string().required("This field is required."),
        MSPfirstcontact: Yup.string().required("This field is required."),
        MSPlastcontact: Yup.string().required("This field is required."),
        MSPemail: Yup.string().email().required("This field is required."),
        MSPsource: Yup.string().required("This field is required."),
        securityContact: Yup.string().required("This field is required."),
      });
    } else if (
      roleFormFiller == "MSP or MSSP" ||
      roleFormFiller ==
        "An applicant looking for a policy through FifthWall Soltution"
    ) {
      schema = Yup.object().shape({
        MSPfirstcontact: Yup.string().required("This field is required."),
        MSPlastcontact: Yup.string().required("This field is required."),
        MSPemail: Yup.string().email().required("This field is required."),
        MSPsource: Yup.string().required("This field is required."),
        securityContact: Yup.string().required("This field is required."),
      });
    }

    return schema;
  }, [roleFormFiller]);

  const onStepOneDone = async (values) => {
    if (MFAchecklist.length == 0 || flag_excludedactivities.length == 0) {
      return;
    }
    let objPostData;
    if (roleFormFiller === "Licenced Insurance Agent") {
      objPostData = {
        gtn_mfa: values.gtn_mfa,
        gtn_mfa_vendor: values.gtn_mfa_vendor,
        gtn_mfa_tool: values.gtn_mfa_tool,
        gtn_security_awareness: values.gtn_security_awareness,
        gtn_security_awareness_vendor: values.gtn_security_awareness_vendor,
        gtn_security_awareness_tool: values.gtn_security_awareness_tool,
        gtn_seperate_backup: values.gtn_seperate_backup,
        gtn_seperate_backup_vendor: values.gtn_seperate_backup_vendor,
        gtn_seperate_backup_tool: values.gtn_seperate_backup_tool,
        gtn_endpoint_managed: values.gtn_endpoint_managed,
        gtn_endpoint_managed_vendor: values.gtn_endpoint_managed_vendor,
        gtn_endpoint_managed_tool: values.gtn_endpoint_managed_tool,
        gtn_vulnerability: values.gtn_vulnerability,
        gtn_vulnerability_vendor: values.gtn_vulnerability_vendor,
        gtn_vulnerability_tool: values.gtn_vulnerability_tool,
        role_formfiller: values.role_formfiller,
        namedinsured: values.namedinsured,
        contactname: values.contactfirstname + " " + values.contactlastname,
        contactemail: values.contactemail,
        contacttitle: values.contacttitle,
        contactphone: values.contactphone,
        extension: values.extension,
        flag_subsidiary: values.flag_subsidiary,
        subsidiaryentity: values.subsidiaryentity,
        flag_currentcyberpolicy: values.flag_currentcyberpolicy,
        file_currentcyberpolicy: selectedFiles,
        entitycountry: values.entitycountry,
        entityaddress: values.entityaddress,
        entitycity: values.entitycity,
        entitystate: values.entitystate,
        entityzipcode: values.entityzipcode,
        entitywebsite: values.entitywebsite,
        industry: values.industry,
        naicscode: values.naicscode,
        flag_excludedactivities: flag_excludedactivities,
        flag_franchise: values.flag_franchise,
        entityestablishyear: values.entityestablishyear,
        numberemployees: values.numberemployees,
        revenue_priorFY: values.revenue_priorFY,
        revenue_estnextFY: values.revenue_estnextFY,
        percentagedomesticsales: values.percentagedomesticsales,
        flag_losslast3years: values.flag_losslast3years,
        estlossimpact: values.estlossimpact,
        MFAchecklist: MFAchecklist,
        notes_MFA: values.notes_MFA,
        desiredAggregate: values.desiredAggregate,
        isThe: values.isThe,
      };
    } else if (
      roleFormFiller == "Internal IT" ||
      roleFormFiller ==
        "An applicant looking for a policy through FifthWall Soltution"
    ) {
      objPostData = {
        gtn_mfa: values.gtn_mfa,
        gtn_mfa_vendor: values.gtn_mfa_vendor,
        gtn_mfa_tool: values.gtn_mfa_tool,
        gtn_security_awareness: values.gtn_security_awareness,
        gtn_security_awareness_vendor: values.gtn_security_awareness_vendor,
        gtn_security_awareness_tool: values.gtn_security_awareness_tool,
        gtn_seperate_backup: values.gtn_seperate_backup,
        gtn_seperate_backup_vendor: values.gtn_seperate_backup_vendor,
        gtn_seperate_backup_tool: values.gtn_seperate_backup_tool,
        gtn_endpoint_managed: values.gtn_endpoint_managed,
        gtn_endpoint_managed_vendor: values.gtn_endpoint_managed_vendor,
        gtn_endpoint_managed_tool: values.gtn_endpoint_managed_tool,
        gtn_vulnerability: values.gtn_vulnerability,
        gtn_vulnerability_vendor: values.gtn_vulnerability_vendor,
        gtn_vulnerability_tool: values.gtn_vulnerability_tool,
        role_formfiller: values.role_formfiller,
        howDid: values.howDid,
        namedinsured: values.namedinsured,
        contactfirstname: values.contactfirstname,
        contactlastname: values.contactlastname,
        contactemail: values.contactemail,
        contacttitle: values.contacttitle,
        contactphone: values.contactphone,
        extension: values.extension,
        flag_subsidiary: values.flag_subsidiary,
        subsidiaryentity: values.subsidiaryentity,
        flag_currentcyberpolicy: values.flag_currentcyberpolicy,
        file_currentcyberpolicy: selectedFiles,
        entitycountry: values.entitycountry,
        entityaddress: values.entityaddress,
        entitycity: values.entitycity,
        entitystate: values.entitystate,
        entityzipcode: values.entityzipcode,
        entitywebsite: values.entitywebsite,
        industry: values.industry,
        naicscode: values.naicscode,
        flag_excludedactivities: flag_excludedactivities,
        flag_franchise: values.flag_franchise,
        entityestablishyear: values.entityestablishyear,
        numberemployees: values.numberemployees,
        revenue_priorFY: values.revenue_priorFY,
        revenue_estnextFY: values.revenue_estnextFY,
        percentagedomesticsales: values.percentagedomesticsales,
        flag_losslast3years: values.flag_losslast3years,
        estlossimpact: values.estlossimpact,
        MFAchecklist: MFAchecklist,
        notes_MFA: values.notes_MFA,
        desiredAggregate: values.desiredAggregate,
        isThe: values.isThe,
      };
    } else if (roleFormFiller === "MSP or MSSP") {
      objPostData = {
        gtn_mfa: values.gtn_mfa,
        gtn_mfa_vendor: values.gtn_mfa_vendor,
        gtn_mfa_tool: values.gtn_mfa_tool,
        gtn_security_awareness: values.gtn_security_awareness,
        gtn_security_awareness_vendor: values.gtn_security_awareness_vendor,
        gtn_security_awareness_tool: values.gtn_security_awareness_tool,
        gtn_seperate_backup: values.gtn_seperate_backup,
        gtn_seperate_backup_vendor: values.gtn_seperate_backup_vendor,
        gtn_seperate_backup_tool: values.gtn_seperate_backup_tool,
        gtn_endpoint_managed: values.gtn_endpoint_managed,
        gtn_endpoint_managed_vendor: values.gtn_endpoint_managed_vendor,
        gtn_endpoint_managed_tool: values.gtn_endpoint_managed_tool,
        gtn_vulnerability: values.gtn_vulnerability,
        gtn_vulnerability_vendor: values.gtn_vulnerability_vendor,
        gtn_vulnerability_tool: values.gtn_vulnerability_tool,
        role_formfiller: values.role_formfiller,
        contactphone: values.contactphone,
        entityaddress: values.entityaddress,
        entitycity: values.entitycity,
        entitystate: values.entitystate,
        entityzipcode: values.entityzipcode,
        namedinsured: values.namedinsured,
        contactname: values.contactfirstname + " " + values.contactlastname,
        contactemail: values.contactemail,
        subsidiaryentity: values.subsidiaryentity,
        entitywebsite: values.entitywebsite,
        industry: values.industry,
        naicscode: values.naicscode,
        flag_excludedactivities: flag_excludedactivities,
        flag_franchise: values.flag_franchise,
        percentagedomesticsales: values.percentagedomesticsales,
        flag_losslast3years: values.flag_losslast3years,
        estlossimpact: values.estlossimpact,
        MFAchecklist: MFAchecklist,
        notes_MFA: values.notes_MFA,
      };
    }
    setGeneralInfo(objPostData);
    setStep(2);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const onStepTwoDone = (values) => {
    let objPostData;
    if (
      roleFormFiller === "Licenced Insurance Agent" ||
      roleFormFiller == "Internal IT" ||
      roleFormFiller ==
        "An applicant looking for a policy through FifthWall Soltution"
    ) {
      objPostData = {
        flag_formalbackups: values.flag_formalbackups,
        flag_BUsegregation: values.flag_BUsegregation,
        notes_backups: values.notes_backups,
        flag_EDR_MDR: values.flag_EDR_MDR,
        flag_centralantivirussys: values.flag_centralantivirussys,
        notes_edr_ngav: values.notes_edr_ngav,
        vulnmanagementdays: values.vulnmanagementdays,
        notes_patching: values.notes_patching,
        flag_cybertraining: values.flag_cybertraining,
        flag_cybertrainingphishingsim: values.flag_cybertrainingphishingsim,
        flag_wiretransferdualtransfer: values.flag_wiretransferdualtransfer,
        notes_awarenessTraining: values.notes_awarenessTraining,
        flag_protectpropertyprivacyrights:
          values.flag_protectpropertyprivacyrights,
      };
    } else if (roleFormFiller === "MSP or MSSP") {
      objPostData = {
        flag_formalbackups: values.flag_formalbackups,
        flag_BUsegregation: values.flag_BUsegregation,
        notes_backups: values.notes_backups,
        flag_EDR_MDR: values.flag_EDR_MDR,
        flag_centralantivirussys: values.flag_centralantivirussys,
        notes_edr_ngav: values.notes_edr_ngav,
        vulnmanagementdays: values.vulnmanagementdays,
        notes_patching: values.notes_patching,
        flag_cybertraining: values.flag_cybertraining,
        flag_cybertrainingphishingsim: values.flag_cybertrainingphishingsim,
        notes_awarenessTraining: values.notes_awarenessTraining,
      };
    }
    setSecurityInfo(objPostData);
    setStep(3);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const onStepThreeDone = (values) => {
    let objPostData;
    if (
      roleFormFiller === "Licenced Insurance Agent" ||
      roleFormFiller == "Internal IT" ||
      roleFormFiller ==
        "An applicant looking for a policy through FifthWall Soltution"
    ) {
      objPostData = {
        flag_recordsthirdparty: values.flag_recordsthirdparty,
        estnumberofrecordsPII: values.estnumberofrecordsPII,
        estnumberofrecordsPHI: values.estnumberofrecordsPHI,
        flag_paymentcard: values.flag_paymentcard,
        estnumberofrecordsPCI: values.estnumberofrecordsPCI,
        estnumberbiometricrecords: values.estnumberbiometricrecords,
        flag_PCIcompliance: values.flag_PCIcompliance,
        flag_PCIcompliance_outsourced: values.flag_PCIcompliance_outsourced,
        thirdpartydataservicing: values.thirdpartydataservicing,
        flag_247SOC: values.flag_247SOC,
        domainadminserviceaccts: values.domainadminserviceaccts,
        flag_RDPviaVPNonly: values.flag_RDPviaVPNonly,
        flag_localadminrights: values.flag_localadminrights,
        flag_pam: values.flag_pam,
        flag_pamallprivilegedaccts: values.flag_pamallprivilegedaccts,
        pamprovider: values.pamprovider,
        flag_pammfa: values.flag_pammfa,
        notes_pam: values.notes_pam,
        flag_MFArestricted: values.flag_MFArestricted,
        flag_vulnscans: values.flag_vulnscans,
        EDRalldomaincontrollers: values.EDRalldomaincontrollers,
        flag_bringyourowndevice: values.flag_bringyourowndevice,
        flag_DNS: values.flag_DNS,
        flag_macrodisable: values.flag_macrodisable,
        flag_SIEM: values.flag_SIEM,
        flag_encryptionenforced: values.flag_encryptionenforced,
        flag_weeklyalerts_reports: values.flag_weeklyalerts_reports,
        backupfrequency: values.backupfrequency,
        flag_fullencryptedbackups: values.flag_fullencryptedbackups,
        flag_lessthan48hrrestore: values.flag_lessthan48hrrestore,
        flag_BUsimmutable: values.flag_BUsimmutable,
        notes_backups_restoration: values.notes_backups_restoration,
        flag_approvedIR: values.flag_approvedIR,
        notes_IR: values.notes_IR,
        flag_self_hostedExchange: values.flag_self_hostedExchange,
        flag_auditexchangeservers: values.flag_auditexchangeservers,
        flag_emailfilterphishing_ransomware:
          values.flag_emailfilterphishing_ransomware,
        flag_firewallbestpractices: values.flag_firewallbestpractices,
        flag_3rdpartysensitiveinfostorage:
          values.flag_3rdpartysensitiveinfostorage,
        flag_3rdpartybaseline: values.flag_3rdpartybaseline,
      };
    } else if (roleFormFiller === "MSP or MSSP") {
      objPostData = {
        flag_recordsthirdparty: values.flag_recordsthirdparty,
        estnumberofrecordsPII: values.estnumberofrecordsPII,
        estnumberofrecordsPHI: values.estnumberofrecordsPHI,
        flag_paymentcard: values.flag_paymentcard,
        estnumberofrecordsPCI: values.estnumberofrecordsPCI,
        estnumberbiometricrecords: values.estnumberbiometricrecords,
        flag_PCIcompliance: values.flag_PCIcompliance,
        flag_PCIcompliance_outsourced: values.flag_PCIcompliance_outsourced,
        thirdpartydataservicing: values.thirdpartydataservicing,
        flag_247SOC: values.flag_247SOC,
        domainadminserviceaccts: values.domainadminserviceaccts,
        flag_RDPviaVPNonly: values.flag_RDPviaVPNonly,
        flag_localadminrights: values.flag_localadminrights,
        flag_pam: values.flag_pam,
        flag_pamallprivilegedaccts: values.flag_pamallprivilegedaccts,
        pamprovider: values.pamprovider,
        flag_pammfa: values.flag_pammfa,
        notes_pam: values.notes_pam,
        flag_MFArestricted: values.flag_MFArestricted,
        flag_vulnscans: values.flag_vulnscans,
        EDRalldomaincontrollers: values.EDRalldomaincontrollers,
        flag_bringyourowndevice: values.flag_bringyourowndevice,
        flag_DNS: values.flag_DNS,
        flag_macrodisable: values.flag_macrodisable,
        flag_SIEM: values.flag_SIEM,
        flag_encryptionenforced: values.flag_encryptionenforced,
        backupfrequency: values.backupfrequency,
        flag_fullencryptedbackups: values.flag_fullencryptedbackups,
        flag_lessthan48hrrestore: values.flag_lessthan48hrrestore,
        flag_BUsimmutable: values.flag_BUsimmutable,
        notes_backups_restoration: values.notes_backups_restoration,
        flag_approvedIR: values.flag_approvedIR,
        notes_IR: values.notes_IR,
        flag_self_hostedExchange: values.flag_self_hostedExchange,
        flag_auditexchangeservers: values.flag_auditexchangeservers,
        flag_emailfilterphishing_ransomware:
          values.flag_emailfilterphishing_ransomware,
        flag_firewallbestpractices: values.flag_firewallbestpractices,
        flag_3rdpartysensitiveinfostorage:
          values.flag_3rdpartysensitiveinfostorage,
        flag_3rdpartybaseline: values.flag_3rdpartybaseline,
      };
    }
    setAdditionalInfo(objPostData);
    setStep(4);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const saveFormExtraInfo = (values) => {
    let objPostData = {
      gtn_mfa: values.gtn_mfa,
      gtn_mfa_vendor: values.gtn_mfa_vendor,
      gtn_mfa_tool: values.gtn_mfa_tool,
      gtn_security_awareness: values.gtn_security_awareness,
      gtn_security_awareness_vendor: values.gtn_security_awareness_vendor,
      gtn_security_awareness_tool: values.gtn_security_awareness_tool,
      gtn_seperate_backup: values.gtn_seperate_backup,
      gtn_seperate_backup_vendor: values.gtn_seperate_backup_vendor,
      gtn_seperate_backup_tool: values.gtn_seperate_backup_tool,
      gtn_endpoint_managed: values.gtn_endpoint_managed,
      gtn_endpoint_managed_vendor: values.gtn_endpoint_managed_vendor,
      gtn_endpoint_managed_tool: values.gtn_endpoint_managed_tool,
      gtn_vulnerability: values.gtn_vulnerability,
      gtn_vulnerability_vendor: values.gtn_vulnerability_vendor,
      gtn_vulnerability_tool: values.gtn_vulnerability_tool,
      flag_currentcyberpolicy: values.flag_currentcyberpolicy,
      naicscode: values.naicscode,
      industry: values.industry,
    };

    dispatch(saveExtraInfo({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Form Submitted Successfully.");
          setStep(1);
          setGeneralInfo({});
          setSecurityInfo({});
          setAdditionalInfo({});
          setPartnerInfo({});
          setMFAchecklist([]);
          setflag_excludedactivities([]);
          setSelectedFiles([]);
          setSubmitted(true);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  const onSubmitForm = async (values) => {
    let objPostData;

    if (
      roleFormFiller === "Licenced Insurance Agent" ||
      roleFormFiller == "Internal IT"
    ) {
      objPostData = {
        agentcontact: values.agentcontact,
        agentContact: values.agentContact,
        agencyName: values.agencyName,
        MSPcontact: values.MSPfirstcontact + " " + values.MSPlastcontact,
        MSPemail: values.MSPemail,
        MSPsource: values.MSPsource,
        securityContact: values.securityContact,
        MSPphone: values.MSPphone,
        additionalNotes: values.additionalNotes,
      };
    } else if (
      roleFormFiller === "MSP or MSSP" ||
      roleFormFiller ==
        "An applicant looking for a policy through FifthWall Soltution"
    ) {
      objPostData = {
        MSPcontact: values.MSPfirstcontact + " " + values.MSPlastcontact,
        MSPemail: values.MSPemail,
        MSPsource: values.MSPsource,
        securityContact: values.securityContact,
        MSPphone: values.MSPphone,
        additionalNotes: values.additionalNotes,
      };
    }

    setPartnerInfo(objPostData);

    objPostData = {
      ...generalInfo,
      ...securityInfo,
      ...additionalInfo,
      ...objPostData,
    };

    objPostData["flag_EDR-MDR"] = securityInfo.flag_EDR_MDR;
    objPostData["flag_protectproperty-privacyrights"] =
      securityInfo.flag_protectpropertyprivacyrights;
    objPostData["flag_self-hostedExchange"] =
      additionalInfo.flag_self_hostedExchange;
    objPostData["flag_emailfilterphishing-ransomware"] =
      additionalInfo.flag_emailfilterphishing_ransomware;
    objPostData.referencepartner = "compliancerisk.io";

    setObjInfoFormErrors({});
    setIsLoading(true);

    dispatch(sendToZappier({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        saveFormExtraInfo(generalInfo);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(rejectedValueOrSerializedError);
        const objErrors = rejectedValueOrSerializedError?.response?.errors;
        if (typeof objErrors !== "undefined" && objErrors !== null) {
          setObjInfoFormErrors(objErrors);
          showMsg("error", "Please correct the errors.");
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      });
  };

  const scrollToInput = (inputName) => {
    const inputElement = document.querySelector(`input[name="${inputName}"]`);
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "center" });
      inputElement.focus();
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      {isLoading === false ? (
        <>
          {step == 1 && (
            <Formik
              initialValues={
                generalInfo.role_formfiller
                  ? { ...objInitialGeneralValues, ...generalInfo }
                  : objInitialGeneralValues
              }
              validationSchema={GeneralFormJWTSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
              }}
              enableReinitialize
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form
                  id={"InfoForm"}
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!values.role_formfiller) {
                      scrollToInput("role_formfiller");
                      return;
                    }
                    if (Object.keys(errors).length > 0) {
                      const firstErrorKey = Object.keys(errors)[0];
                      scrollToInput(firstErrorKey);
                    } else {
                      onStepOneDone(values);
                    }
                  }}
                >
                  <MDBox sx={{ height: "100%" }}>
                    <MDBox>
                      <InfoFormFormikWrapper
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        objStates={objStates}
                        objIndustries={objIndustries}
                        objAggregates={objAggregates}
                        roles={roles}
                        roleFormFiller={roleFormFiller}
                        setRoleFormFiller={setRoleFormFiller}
                        MFAchecklist={MFAchecklist}
                        setMFAchecklist={setMFAchecklist}
                        setSelectedFiles={setSelectedFiles}
                        selectedFiles={selectedFiles}
                        flag_excludedactivities={flag_excludedactivities}
                        setflag_excludedactivities={setflag_excludedactivities}
                      />

                      <MDBox
                        sx={{ margin: "0 0 40px -40px" }}
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        {isLoading === false ? (
                          <MDButton
                            type="submit"
                            color="success"
                            sx={{ padding: "12px 82px" }}
                          >
                            Next
                          </MDButton>
                        ) : (
                          <LoadingSpinner
                            subClass="text-center"
                            color="success"
                            size="lg"
                          />
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Form>
              )}
            </Formik>
          )}
          {step == 2 && (
            <Formik
              initialValues={
                securityInfo.flag_formalbackups
                  ? { ...objInitialSecurityValues, ...securityInfo }
                  : objInitialSecurityValues
              }
              validationSchema={SecurityFormJWTSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
              }}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                validateForm,
                setFieldValue,
              }) => (
                <Form
                  id={"InfoForm2"}
                  autoComplete="off"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const formErrors = await validateForm(); // Ensure validation runs

                    if (Object.keys(formErrors).length > 0) {
                      const firstErrorKey = Object.keys(formErrors)[0];
                      scrollToInput(firstErrorKey);
                    } else {
                      onStepTwoDone(values);
                    }
                  }}
                >
                  <MDBox sx={{ height: "100%" }}>
                    <MDBox>
                      <SecurityFormFormikWrapper
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        roleFormFiller={roleFormFiller}
                      />

                      <MDBox
                        sx={{ margin: "0 0 40px" }}
                        px={4}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {isLoading === false ? (
                          <>
                            <MDButton
                              type="button"
                              color="dark"
                              sx={{ padding: "12px 82px" }}
                              onClick={() => {
                                setStep(1);
                              }}
                            >
                              Back
                            </MDButton>
                            <MDButton
                              type="submit"
                              color="success"
                              sx={{ padding: "12px 82px" }}
                            >
                              Next
                            </MDButton>
                          </>
                        ) : (
                          <LoadingSpinner
                            subClass="text-center"
                            color="success"
                            size="lg"
                          />
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Form>
              )}
            </Formik>
          )}
          {step == 3 && (
            <Formik
              initialValues={{
                ...objInitialAdditionalValues,
                ...additionalInfo,
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
              }}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                validateForm,
                setFieldValue,
              }) => (
                <Form
                  id={"InfoForm3"}
                  autoComplete="off"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const formErrors = await validateForm(); // Ensure validation runs

                    if (Object.keys(formErrors).length > 0) {
                      const firstErrorKey = Object.keys(formErrors)[0];
                      scrollToInput(firstErrorKey);
                    } else {
                      onStepThreeDone(values);
                    }
                  }}
                >
                  <MDBox sx={{ height: "100%" }}>
                    <MDBox>
                      <AdditionalFormFormikWrapper
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        roleFormFiller={roleFormFiller}
                      />

                      <MDBox
                        sx={{ margin: "0 0 40px" }}
                        px={4}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {isLoading === false ? (
                          <>
                            <MDButton
                              type="button"
                              color="dark"
                              sx={{ padding: "12px 82px" }}
                              onClick={() => {
                                setStep(2);
                              }}
                            >
                              Back
                            </MDButton>
                            <MDButton
                              type="submit"
                              color="success"
                              sx={{ padding: "12px 82px" }}
                            >
                              Next
                            </MDButton>
                          </>
                        ) : (
                          <LoadingSpinner
                            subClass="text-center"
                            color="success"
                            size="lg"
                          />
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Form>
              )}
            </Formik>
          )}
          {step == 4 && (
            <Formik
              initialValues={{ ...objInitialPartnerValues, ...partnerInfo }}
              validationSchema={PartnerFormJWTSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
              }}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                validateForm,
                setFieldValue,
              }) => (
                <Form
                  id={"InfoForm"}
                  autoComplete="off"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const formErrors = await validateForm(); // Ensure validation runs

                    if (Object.keys(formErrors).length > 0) {
                      const firstErrorKey = Object.keys(formErrors)[0];
                      scrollToInput(firstErrorKey);
                    } else {
                      onSubmitForm(values);
                    }
                  }}
                >
                  <MDBox sx={{ height: "100%" }}>
                    <MDBox>
                      <PartnerFormFormikWrapper
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        roleFormFiller={roleFormFiller}
                      />

                      <MDBox
                        sx={{ margin: "0 0 40px" }}
                        px={4}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {isLoading === false ? (
                          <>
                            <MDButton
                              type="button"
                              color="dark"
                              sx={{ padding: "12px 82px" }}
                              onClick={() => {
                                setStep(3);
                              }}
                            >
                              Back
                            </MDButton>
                            <MDButton
                              type="submit"
                              color="success"
                              sx={{ padding: "12px 82px" }}
                            >
                              Submit
                            </MDButton>
                          </>
                        ) : (
                          <LoadingSpinner
                            subClass="text-center"
                            color="success"
                            size="lg"
                          />
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Form>
              )}
            </Formik>
          )}
        </>
      ) : (
        <MDBox>
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
          <MDTypography sx={{ textAlign: "center" }}>Loading Info</MDTypography>
        </MDBox>
      )}
    </>
  );
};

export default InfoForm;
