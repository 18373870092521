import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { Fragment, useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { TINYMC_API_KEY } from "components/utils/constants/misc";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getPolicies,
  getApprovers,
  generateWisp,
} from "features/wisps/wispActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDTypography from "components/MDTypography";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { standardDateFormat } from "utils/general";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import borders from "assets/theme/base/borders";
import MergeTagsInfo from "views/components/MergeTagsInfo";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PoliciesManagementTable from "./PoliciesTable/ManagementTable";
import dataTableData from "./PoliciesTable/DataTableData";
import { useMaterialUIController } from "context";
import InfoIcon from "@mui/icons-material/Info";
import MDInput from "components/MDInput";

const Index = (props) => {
  const { borderWidth, borderColor } = borders;
  const navigate = useNavigate();
  const { objUser } = props;
  // const { id } = useParams();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const dispatch = useDispatch();
  const confirm = useConfirm();

  /**Policies table */
  const [isLoadingPolicies, setIsLoadingPolicies] = useState(false);
  const [trackedPolicies, setTrackedPolicies] = useState([]);
  const [overviewOscalSections, setOverviewOscalSections] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [selectedApprover, setSelectedApprover] = useState(null);
  const [
    overviewOscalSectionsForHelperText,
    setOverviewOscalSectionsForHelperText,
  ] = useState("");
  const [listOfMergeTags, setListOfMergeTags] = useState([]);
  const [objPoliciesTableData, setObjPoliciesTableData] =
    useState(dataTableData);
  const [totalPoliciesCount, setTotalPoliciesCount] = useState(0);
  const [perPagePolicy, setPerPagePolicy] = useState(100);
  const [pagePolicies, setPagePolicies] = useState(1);

  const [chooseOrderScreen, setChooseOrderScreen] = useState(false);
  const [step, setStep] = useState(1);

  /**re-order */
  const [sections, setSections] = useState([]);
  const [selectedIndexToSave, setSelectedIndexToSave] = useState(null);

  /**Generating WISP*/
  const [wispName, setWispName] = useState("");
  const [open, setOpen] = useState(false);
  const [isGeneratingWisp, setIsGeneratingWisp] = useState(false);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchPolicies();
    fetchApprovers();
  }, []);

  const fetchPolicies = (rowOriginal) => {
    setIsLoadingPolicies(true);
    dispatch(getPolicies({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingPolicies(false);
        const response = originalPromiseResult;

        const objRows = [];
        const objClientTemplatesList = response.data.policies;
        const overviewOscalSections = response.data.overview_sections;
        const modifiedSections = overviewOscalSections.map((section) => {
          return { ...section, section_content: null };
        });

        /**merge tags */
        const mergeTags = response.data.merge_tags;
        const mergeTagsList = [
          {
            title: "Global",
            menu:
              mergeTags?.global?.map((tag) => ({
                value: tag.name,
                title: tag.description,
              })) ?? [],
          },
        ];
        if (mergeTags?.company?.length > 0) {
          mergeTagsList.push({
            title: "Company",
            menu:
              mergeTags?.company?.map((tag) => ({
                value: tag.name,
                title: tag.description ?? tag.name,
              })) ?? [],
          });
        }

        /** */

        for (let index = 0; index < objClientTemplatesList.length; index++) {
          const element = objClientTemplatesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.title = element.title;
          objTr.category = element.category
            ? element.category.display_name
            : "";
          objTr.created_at = standardDateFormat(element.published_at);
          objTr.selected = false;
          objTr.order = index + 1;
          // objTr.type = element.global_template_type;
          // objTr.created_by = element?.created_by;
          // objTr.last_modified_at = standardDateFormat(element.last_modified_at);
          // objTr.status = element.status;
          // objTr.current_rmfs = getCurrentRMFList(
          //   element.risk_management_frameworks
          // );
          // objTr.selected = checkIfCurrentRMFIsPreselected(
          //   element.policy_packs,
          //   rowOriginal
          // );
          objTr.element = element;
          objRows.push(objTr);
        }

        setTrackedPolicies(objRows);
        setTotalPoliciesCount(response.data.total);
        setObjPoliciesTableData({
          ...dataTableData,
          rows: objRows,
        });
        setOverviewOscalSections(modifiedSections);
        setOverviewOscalSectionsForHelperText(
          response.data.overview_sections_for_helper_text
        );
        setListOfMergeTags(mergeTagsList);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingPolicies(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  const fetchApprovers = (rowOriginal) => {
    dispatch(getApprovers({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setApprovers(response.data.approvers);
      })
      .catch((rejectedValueOrSerializedError) => {
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong when fetching approvers, please try again."
        );
      });
  };

  //   const navigateToCreateNewWisp = () => {
  //     navigate(`/wisps/new`);
  //   };

  const handleUpdateOfOscalSections = (field, index, newValue) => {
    let updatedSections = [...overviewOscalSections];
    updatedSections[index][field] = newValue;
    setOverviewOscalSections(updatedSections);
  };

  // console.log(overviewOscalSections);

  const btnOnClickChoosePolicyOrder = () => {
    const currentPolicies = [...trackedPolicies];
    const selectedPols = currentPolicies.filter(
      (item) => item.selected === true
    );

    if (selectedPols.length > 0) {
      setSections(selectedPols);
      // setChooseOrderScreen(true);
      setStep(2);
    } else {
      showMsg("info", "Please select policies to include in your WISP");
    }
  };

  const handleReodering = (direction, selectedIndex) => {
    const indexToMoveTo =
      direction === "move_up" ? selectedIndex - 1 : selectedIndex + 1;

    if (indexToMoveTo < 0 || indexToMoveTo > sections.length - 1) {
      // Invalid index to move, do nothing
      return;
    }

    setSelectedIndexToSave(indexToMoveTo);
    const updatedSections = [...sections];
    const selectedOrder = updatedSections[selectedIndex].order;

    // Swap sections
    [
      updatedSections[selectedIndex].order,
      updatedSections[indexToMoveTo].order,
    ] = [updatedSections[indexToMoveTo].order, selectedOrder];

    const sortedUpdatedSections = updatedSections.sort(
      (a, b) => a.order - b.order
    );

    setSections(sortedUpdatedSections);
  };

  const btnOnClickOpenModalForInputtingWispName = () => {
    if (selectedApprover) {
      setOpen(true);
    } else {
      showMsg("info", "No approver selected");
    }
  };

  const btnOnClickGenerateWisp = () => {
    if (wispName == "") {
      showMsg("info", "Please provide a name for this WISP");
      return false;
    } else {
      setIsGeneratingWisp(true);
      dispatch(
        generateWisp({
          objUser,
          sections,
          overviewOscalSections,
          selectedApprover,
          wispName,
        })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsLoadingPolicies(false);
          const response = originalPromiseResult;
          const wispId = response.data.wisp.id;
          //   const parentWispId = response.data.wisp.parent_wisp_id ?? wispId;
          routeDictionary[wispId] = response.data.wisp.name;
          navigate("/wisp/" + wispId + "/" + wispId, {
            state: { routeDictionary },
          });
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsGeneratingWisp(false);
          // const objErrorRsp = rejectedValueOrSerializedError.response;
          console.log(rejectedValueOrSerializedError);
          showMsg("error", "Something went wrong, please try again.");
        });
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardBreadcrumbs />

        {/* Generated WISP Dialog */}
        <Dialog open={open} onClose={onClose} fullWidth={true}>
          <DialogTitle sx={{ pb: 0 }}>WISP Name</DialogTitle>

          <DialogContent>
            <MDBox mt={2}>
              <TextField
                label="Enter WISP Name"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  width: "100%",
                }}
                onChange={(e) => setWispName(e.target.value)}
                InputLabelProps={{
                  sx: {
                    fontWeight: 700,
                  },
                }}
                InputProps={{
                  sx: {
                    fontWeight: 700,
                  },
                }}
              />
            </MDBox>
          </DialogContent>
          <DialogActions>
            {isGeneratingWisp === false ? (
              <>
                <MDButton
                  type="submit"
                  onClick={() => btnOnClickGenerateWisp()}
                  color="success"
                  sx={{ padding: "12px 12px" }}
                >
                  Generate
                </MDButton>
                <MDButton
                  type="button"
                  variant="outlined"
                  color="dark"
                  sx={{ padding: "12px 12px" }}
                  onClick={onClose}
                >
                  Cancel
                </MDButton>
              </>
            ) : (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            )}
          </DialogActions>
        </Dialog>

        {/* selecting policies */}
        <Grid
          container
          width="100%"
          display={step === 1 ? "block" : "none"}
          spacing={2}
        >
          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoadingPolicies ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <>
                  {totalPoliciesCount == 0 ? (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="p" fontWeight="regular">
                          You don't have any published policies yet
                        </MDTypography>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <MDBox
                        mb={2}
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                          paddingLeft: "2rem",
                          paddingTop: "2rem",
                          width: "100%",
                        }}
                      >
                        <MDTypography
                          // sx={{ backgroundColor: "red" }}
                          variant="p"
                          fontWeight="bold"
                          color="primary"
                        >
                          Step 1: Select policies for WISP
                        </MDTypography>
                      </MDBox>
                      <PoliciesManagementTable
                        table={objPoliciesTableData}
                        entriesPerPage={true}
                        canSearch
                        totalCount={totalPoliciesCount}
                        // loadMoreRMF={loadMoreRMF}
                        // perPage={perPageRMF}
                        // setPerPage={setPerPageRMF}
                        _page={pagePolicies}
                        setPage={setPagePolicies}
                        // setObjRMFTableData={setObjPoliciesTableData}
                        setTrackedPolicies={setTrackedPolicies}
                        trackedPolicies={trackedPolicies}
                      />
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>

        {/* re-ordering the selected policies */}
        <Grid
          container
          width="100%"
          display={step === 2 ? "block" : "none"}
          spacing={2}
        >
          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <MDBox
                mb={2}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  paddingLeft: "2rem",
                  paddingTop: "2rem",
                  width: "100%",
                }}
              >
                <MDTypography
                  // sx={{ backgroundColor: "red" }}
                  variant="p"
                  fontWeight="bold"
                  color="primary"
                >
                  Step 2: Choose policy order
                </MDTypography>
              </MDBox>

              {sections.map((section, index) => {
                return (
                  <MDBox
                    className="sectionBox"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "2rem",
                      width: "70%",
                      paddingLeft: "2rem",
                    }}
                    key={section.id}
                  >
                    {/* section content */}
                    <Card
                      className={`sectionContent`}
                      sx={{
                        // marginTop: "20px",
                        marginTop: "24px",
                        padding: "20px",
                        width: "80%",
                        border: "2px solid #eee",
                        borderColor:
                          index === selectedIndexToSave
                            ? "rgb(11, 46, 74)"
                            : "#eee",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          backgroundColor:
                            index === selectedIndexToSave
                              ? "rgb(11, 46, 74)"
                              : "#fff",
                          color:
                            index === selectedIndexToSave ? "#fff" : "#000",
                          width: "30px",
                          height: "30px",
                          lineHeight: "30px",
                          borderRadius: "50%",
                          border: "1px solid black",
                          textAlign: "center",
                          verticalAlign: "middle",
                          display: "block",
                        }}
                      >
                        {index + 1}
                      </span>
                      {/* title */}
                      <MDBox>
                        <MDTypography color="dark" fontWeight="bold">
                          {section.title}
                        </MDTypography>
                      </MDBox>

                      {/* Reference Material*/}
                      <MDBox></MDBox>
                    </Card>

                    {/* re-ordering */}
                    <MDBox
                      className="sectionReordering"
                      sx={{
                        width: "20%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        gap: "15px",
                        // gap: "30px",
                        fontSize: "1.5rem",
                        paddingTop: "22px",
                        // paddingTop: "15px",
                      }}
                    >
                      <ArrowUpwardIcon
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#50C878",
                          color: "#fff",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          "&:hover": {
                            backgroundColor: "#ccc",
                          },
                          "&:active": {
                            backgroundColor: "#000",
                          },
                        }}
                        onClick={() => handleReodering("move_up", index)}
                      ></ArrowUpwardIcon>
                      <ArrowDownwardIcon
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#FF7F7F",
                          color: "#fff",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          "&:hover": {
                            backgroundColor: "#ccc",
                          },
                          "&:active": {
                            backgroundColor: "#000",
                          },
                        }}
                        onClick={() => handleReodering("move_down", index)}
                      ></ArrowDownwardIcon>
                    </MDBox>
                  </MDBox>
                );
              })}
            </Card>
          </Grid>
        </Grid>

        {/* Inputting oscal sections data */}
        <Grid
          container
          width="100%"
          display={step === 3 ? "block" : "none"}
          spacing={2}
        >
          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
              }}
            >
              <MDBox
                mb={2}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                  paddingTop: "2rem",
                  width: "100%",
                }}
              >
                <MDTypography
                  variant="p"
                  fontWeight="bold"
                  color="primary"
                  sx={{ marginBottom: "1rem" }}
                >
                  Step 3: Enter your overall{" "}
                  {overviewOscalSectionsForHelperText} sections for the WISP as
                  a whole.
                </MDTypography>
              </MDBox>

              <MDBox sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
                {overviewOscalSections.map((section, index) => {
                  return (
                    <MDBox style={{ marginBottom: "3rem" }} key={index}>
                      {/* Accordion start */}
                      <Accordion defaultExpanded={index === 0}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon color="icon" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            flexDirection: "row-reverse",
                            borderBottom: "1px solid #ababab",
                            background: darkMode ? "#2f3958" : "#ededed",
                          }}
                        >
                          <MDTypography variant="h6" p={1}>
                            {section.title}
                          </MDTypography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            backgroundColor: darkMode ? "#2f3958" : "#e5e9ec",
                          }}
                        >
                          {/* About section */}
                          {/* {showReferenceMaterial && (
                            <Accordion
                              style={{
                                marginBottom: "25px",
                                marginTop: "16px",
                                borderRadius: "5px",
                                boxShadow: "none",
                              }}
                              sx={
                                darkMode
                                  ? { backgroundColor: "#fff" }
                                  : { backgroundColor: "#fff" }
                              }
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon color="icon" />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <MDTypography
                                  variant="h6"
                                  style={{
                                    fontSize: "15px",
                                    color: "grey",
                                  }}
                                >
                                  Reference Material
                                </MDTypography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <MDBox>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: section.section_about,
                                    }}
                                  />
                                </MDBox>
                              </AccordionDetails>
                            </Accordion>
                          )} */}

                          <MDBox mt={2}>
                            {/* <MergeTagsInfo mergeTags={mergeTags} /> */}

                            <Editor
                              // disabled={isClientUser}
                              // onInit={(evt, editor) => {
                              //   if (
                              //     section.owning_global_section.title ===
                              //     "Policy"
                              //   ) {
                              //     editorRef.current = editor;
                              //   }
                              // }}
                              key={index + section.id}
                              apiKey={TINYMC_API_KEY}
                              value={section.section_content}
                              init={{
                                branding: false,
                                height: 500,
                                menubar: false,
                                advcode_inline: true,
                                automatic_uploads: true,
                                mergetags_list: listOfMergeTags,
                              }}
                              onEditorChange={(newValue, editor) => {
                                handleUpdateOfOscalSections(
                                  "section_content",
                                  index,
                                  newValue
                                );
                              }}
                              plugins={[
                                "anchor",
                                "autolink",
                                "advcode",
                                "link",
                                "lists",
                                "searchreplace",
                                "table",
                                "visualblocks",
                                "wordcount",
                                "checklist",
                                "mediaembed",
                                "casechange",
                                "export",
                                "formatpainter",
                                "pageembed",
                                "linkchecker",
                                "a11ychecker",
                                "tinymcespellchecker",
                                "permanentpen",
                                "powerpaste",
                                "advtable",
                                "advcode",
                                "editimage",
                                "tableofcontents",
                                "footnotes",
                                "mergetags",
                                "autocorrect",
                                "fullscreen",
                                "inlinecss",
                              ]}
                              toolbar={[
                                "fullscreen undo redo | blocks fontfamily fontsize | " +
                                  "bold italic underline strikethrough | removeformat code",
                                "link table mergetags | spellcheckdialog a11ycheck | align lineheight | " +
                                  "checklist numlist bullist indent outdent ",
                              ]}
                            />
                          </MDBox>
                        </AccordionDetails>
                      </Accordion>
                    </MDBox>
                  );
                })}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* Selecting WISP approver */}
        <Grid
          container
          width="100%"
          display={step === 4 ? "block" : "none"}
          spacing={2}
        >
          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
              }}
            >
              <MDBox
                mb={2}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                  paddingTop: "2rem",
                  width: "100%",
                }}
              >
                <MDTypography
                  variant="p"
                  fontWeight="bold"
                  color="primary"
                  sx={{ marginBottom: "1rem" }}
                >
                  Step 4: Select WISP Approver
                </MDTypography>
              </MDBox>

              <MDBox sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
                <Grid item xs={12} pt={0}>
                  <MDBox mb={3}>
                    <MDBox mb={2} display="inline-block">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize"
                      >
                        Approver
                      </MDTypography>
                    </MDBox>
                    <Autocomplete
                      // defaultValue={approvers.length > 0 ? approvers[0] : ""}
                      options={approvers}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <MDInput {...params} variant="standard" />
                      )}
                      onChange={(event, value) => {
                        if (value === null) {
                          setSelectedApprover("");
                        } else {
                          setSelectedApprover(value);
                        }
                      }}
                    />
                  </MDBox>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* buttons */}
        <MDBox
          sx={{
            position: "fixed",
            bottom: "3rem",
            right: "4rem",
            display: "flex",
            flexDirection: "column",
            width: "18%",
            gap: 2,
          }}
        >
          <MDButton
            color="info"
            onClick={() => btnOnClickChoosePolicyOrder()}
            sx={{
              padding: "20px",
              display: step === 1 ? "block" : "none",
            }}
          >
            Step 2: Choose policy order
          </MDButton>
          <MDButton
            color="info"
            onClick={() => setStep(3)}
            sx={{
              padding: "20px",
              display: step === 2 ? "block" : "none",
            }}
          >
            Step 3: Additional sections info
          </MDButton>
          <MDButton
            color="info"
            onClick={() => setStep(4)}
            sx={{
              padding: "20px",
              display: step === 3 ? "block" : "none",
            }}
          >
            Step 4: Add an approver
          </MDButton>
          <MDButton
            color="success"
            onClick={() => btnOnClickOpenModalForInputtingWispName()}
            sx={{
              padding: "20px",
              display: step === 4 ? "block" : "none",
            }}
          >
            Step 5: Generate WISP
          </MDButton>
          <MDButton
            color="primary"
            onClick={() => setStep(1)}
            sx={{
              padding: "20px",
              display: step !== 1 ? "block" : "none",
            }}
          >
            Back to policy selection
          </MDButton>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Index;
