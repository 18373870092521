// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import { useConfirm } from "material-ui-confirm";
import { Fragment } from "react";

const DomainActionBtnTr = (props) => {
  const { row, editDomain, deleteDomain } = props;

  const confirm = useConfirm();

  const btnOnClickDeleteDomain = (data) => {
    confirm({
      description:
        "This domain will be removed from previously created policies",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteDomain(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  const btnOnClickEditDomain = (data) => {
    editDomain(data);
  };

  return (
    <Fragment>
      <MDButton onClick={() => btnOnClickEditDomain(row.original)}>
        <MDBox color="dark" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.dark"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            Edit
          </MDTypography>
        </MDBox>
      </MDButton>

      <MDButton
        onClick={() => btnOnClickDeleteDomain(row.original)}
        variant="gradient"
        sx={{ marginLeft: "1rem" }}
      >
        <MDBox color="error" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="error"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            Delete
          </MDTypography>
        </MDBox>
      </MDButton>
    </Fragment>
  );
};

export default DomainActionBtnTr;
