import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { Accordion, AccordionSummary, Checkbox, Grid } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { createNewTemplateClone } from "features/company/templatesActions";
import TermsandConditions from "../../../SignUpFunnel/SignUpSelectOrganizationType/components/TermsandConditions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMaterialUIController } from "context";

function PreviewTemplateDialog({
  objTemplate,
  showReferenceMaterial,
  open,
  onClose,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"xl"}>
      <DialogTitle sx={{ pb: 5 }}>Document Preview</DialogTitle>
      <DialogContent>
        <Grid>
          <Grid item xs={12}>
            {objTemplate?.doc_type === "oscal" ? (
              objTemplate?.document_sections.map((section, index) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    lg={12}
                    rowSpacing={3}
                    key={index}
                    mb={5}
                  >
                    <Grid item xs={12} pt={0}>
                      <MDTypography variant="h6">
                        {section.owning_global_section.title}
                      </MDTypography>

                      {/* About section */}
                      {/* {showReferenceMaterial && (
                        <>
                          <MDTypography
                            variant="h6"
                            style={{
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            Reference Material:
                          </MDTypography>

                          <MDBox>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `<div class='dangerousHTMLDIVClass'>${
                                  section.section_about
                                    ? section.section_about
                                    : ""
                                }</div>`,
                              }}
                            />
                          </MDBox>
                        </>
                      )} */}

                      <MDBox mt={2}>
                        <div
                          style={{
                            width: "100%",
                            maxHeight: "500px",
                            overflow: "auto",
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#eee",
                          }}
                        >
                          <div
                            style={{ color: darkMode ? "white" : "" }}
                            dangerouslySetInnerHTML={{
                              __html: `<div class='dangerousHTMLDIVClass'>${
                                section.section_content
                                  ? section.section_content
                                  : ""
                              }</div>`,
                            }}
                          />
                        </div>
                      </MDBox>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <>
                <MDBox>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<div class='dangerousHTMLDIVClass'>${objTemplate?.document}</div>`,
                    }}
                  />
                </MDBox>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onClose}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default PreviewTemplateDialog;
