/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import { useConfirm } from "material-ui-confirm";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useWispPDFDownloader from "../../useWispPDFDownloader";

const ActionBtnTr = (props) => {
  const { row, column, value, deleteObj } = props;
  const { downloadWispPDF, isLoadingPDF } = useWispPDFDownloader();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const confirm = useConfirm();

  const nagivateToViewCampaignPage = (dataObj) => {
    routeDictionary[dataObj.campaign_id] = dataObj.name;
    navigate(`/adoption-campaigns/${dataObj.campaign_id}`, {
      state: { routeDictionary },
    });
  };

  const btnOnClickDeleteObj = (data) => {
    confirm({
      description: "Do you want to delete this campaign?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteObj(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  // const btnClickDownloadWispPDF = (dataObj) => {
  //   downloadWispPDF(null, dataObj.id);
  // };

  return (
    <Fragment>
      <MDButton
        onClick={() => nagivateToViewCampaignPage(row.original)}
        disabled={row.original.deleted === "yes"}
      >
        <MDBox color="info" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>visibility</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.info"
            sx={{ lineHeight: 0}}
          >
          </MDTypography>
        </MDBox>
      </MDButton>

      {/* <MDButton
        onClick={() => btnClickDownloadWispPDF(row.original)}
        sx={{ marginLeft: "1rem" }}
      >
        <MDBox color="info" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.primary"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            {isLoadingPDF ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            ) : (
              "  Download"
            )}
          </MDTypography>
        </MDBox>
      </MDButton> */}

      <MDButton
        onClick={() => btnOnClickDeleteObj(row.original)}
        variant="gradient"
        sx={{ marginLeft: "1rem" }}
      >
        <MDBox color="error" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="error"
            sx={{ lineHeight: 0 }}
          >
          </MDTypography>
        </MDBox>
      </MDButton>
    </Fragment>
  );
};

export default ActionBtnTr;
