// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

import { Fragment } from "react";

const StripeCheckoutSuccessSection = (props) => {
  const { isLoading } = props;

  return (
    <Fragment>
      <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
        {isLoading ? (
          <MDBox mt={2} width="100%" display="flex" justifyContent="center">
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          </MDBox>
        ) : null}
      </MDBox>
    </Fragment>
  );
};

export default StripeCheckoutSuccessSection;
