import React, { useEffect, useRef, useState } from "react";
import { auth } from "../../features/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import AutoLagoutTimer from "./AutoLagoutTimer";

const AuthenticationCheck = ({
  Component,
  checkAuth,
  isRedirect,
  isCheckEmailVerification = true,
  page_name = "",
  isCheckCompanyOrganization = false,
  redirectToBillingIfNotSubscribedTheFirstTime = true,
  clientPermissionRequired,
}) => {
  const dispatch = useDispatch();
  const _isMounted = useRef(true); // Initial value _isMounted = true
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let lastLocation = useLocation();

  const { pathname } = lastLocation;

  useEffect(() => {
    /**
     * TODO temporary fix for the case when the auth request was made before setting the access token
     */
    loadAuthData();
    let token = localStorage.getItem("token");
    return () => {
      _isMounted.current = false;
    };
  }, [page_name]);

  const { objUser } = useSelector((state) => ({
    objUser: state.auth.user.objUser,
  }));

  const loadAuthData = async () => {
    setLoading(true);

    const objUserAuthResp = await dispatch(auth());
    const objUserAuth = objUserAuthResp.payload;

    if (checkAuth && (!objUserAuth.authorized || !objUserAuth.user)) {
      if (isRedirect) {
        navigate(`/sign-in?redirectTo=${lastLocation.pathname}`);
        return false;
      }
    } else if (checkAuth && objUserAuth.authorized) {
      if (isCheckEmailVerification) {
        if (objUserAuth.user.email_verified_at === null) {
          navigate("/email-verification");
        }
      }
      if (isCheckCompanyOrganization) {
        if (
          objUserAuth.user.email_verified_at !== null &&
          objUserAuth.user.company === null &&
          objUserAuth.user?.role?.name !== "auditor"
        ) {
          if (objUserAuth.user.accepted_tos) {
            navigate("/setup-account/step-two");
          } else {
            navigate("/setup-account/step-one");
          }
        }
      }
      if (
        clientPermissionRequired &&
        !clientPermissionRequired.some((item) =>
          objUserAuth?.user?.client_feature_access.includes(item)
        ) &&
        objUserAuth?.user?.role.name !== "admin" &&
        objUserAuth?.user?.role.name !== "company_user"
      ) {
        navigate("/dashboard");
      }
      if (
        redirectToBillingIfNotSubscribedTheFirstTime &&
        objUserAuth?.user?.company
      ) {
        if (
          objUserAuth.user.company.status === "PENDING_SUBSCRIPTION" ||
          objUserAuth.user.company.status === "ON_HOLD"
        ) {
          // allow peer group page to be accessed without subscription
          if (
            pathname.includes("/peer-group") ||
            pathname.includes("/msp-users")
          ) {
          } else {
            navigate("/billing");
          }
        }
      }
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingSpinner subClass="text-center" color="lightblueColor" />;
  }

  if (
    loading === false &&
    checkAuth &&
    objUser !== undefined &&
    Object.keys(objUser).length > 0
  ) {
    if (objUser.status !== undefined && objUser.status === "success") {
      return <AutoLagoutTimer ComposedClass={Component} objUser={objUser} />;
    } else {
      return <Component objUser={objUser} />;
    }
  } else {
    return <LoadingSpinner subClass="text-center" color="lightblueColor" />;
  }
};

export default AuthenticationCheck;
