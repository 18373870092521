import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Authentication layout components
import { VERSION_SERVER } from "components/utils/constants/misc";
// Other authentication methods components
// Images
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Editor } from "@tinymce/tinymce-react";
import MDButton from "components/MDButton";
import { TINYMC_API_KEY } from "components/utils/constants/misc";
import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MergeTagsInfo from "views/components/MergeTagsInfo";
import NewBlankAssetPolicyDialog from "./newBlankAssetPolicyDialog";
import "./CreatePolicyDocumentStepDraftFormikWrapper.css";
import { showMsg } from "utils/general";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const CreatePolicyDocumentStepDraftFormikWrapper = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    objStates,
    documentType,
    policySectionsToSave,
    handleUpdatePolicySectionsToSave,
    markSectionAsComplete,
    savingSectionIndex,
    isSavingSection,
    editorDocument,
    setEditorDocument,
    handleAutoSave,
    handleSectionAutoSave,
    editorRef,
    readOnly,
    objUser,
    objPolicyDocument,
    showEditor,
    btnClickSectionSave,
    editorsToShow,
    documentVersions,
    documentSectionVersions,
    mergeTags,
  } = props;
  const [selectedTabs, setSelectedTabs] = useState({});
  const [isImportCSVDialogOpen, setIsImportCSVDialogOpen] = useState(false);
  const [isSavingDocument, setIsSavingDocument] = useState(false);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    // You can't send a closure to the tinymce editor, otherwise it won't be updated. It will become stale.
    // So we need to put the documentVersions function in the window object.
    window["getDocumentVersions"] = () => documentVersions;
    window["getSectionVersions"] = () => documentSectionVersions;
  }, [documentVersions, documentSectionVersions]);

  const mergeTagsList = [
    {
      title: "Global",
      menu:
        mergeTags?.global?.map((tag) => ({
          value: tag.name,
          title: tag.description,
        })) ?? [],
    },
  ];
  if (mergeTags?.company?.length > 0) {
    mergeTagsList.push({
      title: "Company",
      menu:
        mergeTags?.company?.map((tag) => ({
          value: tag.name,
          title: tag.description ?? tag.name,
        })) ?? [],
    });
  }

  function capitalizeEveryWord(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const handleTabChange = (tabValue, newValue) => {
    setSelectedTabs((prevState) => ({
      ...prevState,
      [tabValue]: newValue,
    }));
  };

  const createAssetTable = (initialData, type, setIsLoading) => {
    if (initialData.length === 0) {
      setIsImportCSVDialogOpen(false);
      return;
    }
    let table = "<table><thead><tr>";
    for (let key in initialData[0]) {
      table += `<th>${key}</th>`;
    }
    table += "</tr></thead><tbody>";
    for (let i = 0; i < initialData.length; i++) {
      table += "<tr>";
      for (let key in initialData[i]) {
        table += `<td>${initialData[i][key]}</td>`;
      }
      table += "</tr>";
    }
    table += "</tbody></table>";
    editorRef.current.insertContent(table);
    setIsImportCSVDialogOpen(false);
  };

  const saveContentNoOscal = () => {
    setIsSavingDocument(true);
    handleAutoSave(
      editorRef.current,
      () => {
        setIsSavingDocument(false);
        showEditor(0);
      },
      () => {
        setIsSavingDocument(false);
        showMsg("error", "Error", "Error saving content");
      }
    );
  };

  // autosave after every thirty seconds
  const autoSave = () => {
    setInterval(() => {
      handleAutoSave(
        editorRef.current,
        () => {
          // setIsSavingDocument(false);
          // showEditor(0);
        },
        () => {
          // setIsSavingDocument(false);
          // showMsg("error", "Error", "Error saving content");
        }
      );
    }, 30000); // 30 seconds delay
  };

  // useEffect(() => {
  //   autoSave();
  // }, []);

  return (
    <MDBox my={3} textAlign="left">
      <MDBox textAlign="left" mx="auto" mt={2}>
        <Grid
          container
          justifyContent="space-between"
          columnSpacing={2}
          rowSpacing={3}
        >
          <Grid item xs={12} md={12} pt={0}>
            <MDBox mb={0}>
              <MDBox
                mb={0}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography
                  variant="p"
                  sx={{ color: "grey", opacity: "0.7" }}
                >
                  Edit <i>{capitalizeEveryWord(values.policy_name)}</i>
                </MDTypography>
                <MDTypography variant="body2" color="text" textAlign="right">
                  Version {values.version}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={0} pt={2} pb={5}>
        <Grid container justifyContent="space-between" columnSpacing={5}>
          {documentType === "oscal" ? (
            <>
              {policySectionsToSave.map((section, index) => {
                const tabValue = `tab-${index}`;
                const defaultTab =
                  selectedTabs[tabValue] || `${tabValue}-document`;
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    lg={12}
                    rowSpacing={3}
                    key={index}
                    mb={5}
                  >
                    <Grid item xs={12} pt={0}>
                      {/* accordion start */}
                      <Accordion
                      // sx={
                      //   darkMode
                      //     ? { backgroundColor: "#2f3958" }
                      //     : { backgroundColor: "#ededed" }
                      // }
                      >
                        <AccordionSummary
                          sx={{
                            flexDirection: "row-reverse",
                            borderBottom: "1px solid #ababab",
                            background: darkMode ? "#2f3958" : "#ededed",
                          }}
                          expandIcon={<ExpandMoreIcon color="icon" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <MDTypography variant="h6" p={1}>
                            {section.owning_global_section.title}
                          </MDTypography>
                          {section.completed == 1 ? (
                            <MDTypography
                              sx={{
                                marginLeft: "auto",
                                color: "#22A06B",
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                              p={1}
                            >
                              <CheckIcon fontSize="medium" /> Section Marked
                              Complete
                            </MDTypography>
                          ) : (
                            <MDTypography
                              sx={{
                                marginLeft: "auto",
                                color: "#AA4A44",
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                              p={1}
                            >
                              <ClearIcon fontSize="medium" /> Section Not
                              Complete
                            </MDTypography>
                          )}
                          {/* */}
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            backgroundColor: darkMode ? "#2f3958" : "#e5e9ec",
                          }}
                        >
                          {/* tab panels */}
                          <MDBox mt={2}>
                            <TabPanel
                              value={`${tabValue}-document`}
                              index={selectedTabs[tabValue] || defaultTab}
                            >
                              {/* reference material button/section starts */}
                              {objPolicyDocument.created_from_global_template ===
                              true ? (
                                <>
                                  {section.section_about && (
                                    <Accordion
                                      style={{
                                        marginBottom: "25px",
                                        borderRadius: "5px",
                                        boxShadow: "none",
                                      }}
                                      sx={
                                        darkMode
                                          ? { backgroundColor: "#fff" }
                                          : { backgroundColor: "#fff" }
                                      }
                                    >
                                      <AccordionSummary
                                        // sx={{ flexDirection: "row-reverse" }}
                                        expandIcon={
                                          <ExpandMoreIcon color="icon" />
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <MDTypography
                                          variant="h6"
                                          style={{
                                            fontSize: "15px",
                                            color: "grey",
                                          }}
                                        >
                                          Reference Material
                                        </MDTypography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <MDBox>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              // __html: section.owning_global_section.about,
                                              __html: `<div class='dangerousHTMLDIVClass'>${section.section_about}</div>`,
                                            }}
                                          />
                                        </MDBox>
                                      </AccordionDetails>
                                    </Accordion>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              <MDBox
                                sx={{
                                  position: "relative",
                                  width: "100%",
                                }}
                              >
                                {/* reference material button/section end */}
                                {/* editor starts */}

                                {/* <MergeTagsInfo mergeTags={mergeTags} /> */}
                                {/* when editing */}

                                <div
                                  style={{
                                    display: `${
                                      editorsToShow.includes(section.id)
                                        ? "block"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  <Editor
                                    key={index + section.id}
                                    apiKey={TINYMC_API_KEY}
                                    value={section.section_content}
                                    init={{
                                      branding: false,
                                      height: 300,
                                      menubar: false,
                                      advcode_inline: true,
                                      automatic_uploads: true,
                                      mergetags_list: mergeTagsList,
                                      external_plugins: {
                                        // polygonSectionAutosave:
                                        //   process.env.PUBLIC_URL +
                                        //   "/autoSaveSectionPlugin/autoSaveSectionPlugin.js",
                                        polygonSectionVersionsBrowser:
                                          process.env.PUBLIC_URL +
                                          "/sectionVersionsBrowserPlugin/sectionVersionsBrowserPlugin.js",
                                      },
                                      //autosave
                                      // polygonSectionAutosave_autosaveCall:
                                      //   handleSectionAutoSave,
                                      // polygonSectionAutosave_interval: "30s",
                                      polygonSectionAutosave_ask_before_unload: false,
                                      polygonSectionAutosave_sectionIDCallback:
                                        section.id,

                                      //versions
                                      polygonSectionVersionsBrowser_versionsCallback:
                                        "getSectionVersions",
                                      polygonSectionVersionsBrowser_fetchVersionCallback:
                                        props.fetchSectionVersion,
                                      polygonSectionVersionsBrowser_sectionIDCallback:
                                        section.section_id,
                                      polygonSectionVersionsBrowser_compareVersionCallback:
                                        props.compareDocumentVersions,
                                    }}
                                    onEditorChange={(newValue, editor) => {
                                      handleUpdatePolicySectionsToSave(
                                        "section_content",
                                        index,
                                        newValue
                                      );
                                    }}
                                    plugins={[
                                      // "preview",
                                      "anchor",
                                      "autolink",
                                      // "charmap",
                                      "advcode",
                                      // "emoticons",
                                      // "image",
                                      "link",
                                      "lists",
                                      "searchreplace",
                                      "table",
                                      "visualblocks",
                                      "wordcount",
                                      "checklist",
                                      "mediaembed",
                                      "casechange",
                                      "export",
                                      "formatpainter",
                                      "pageembed",
                                      "linkchecker",
                                      "a11ychecker",
                                      "tinymcespellchecker",
                                      "permanentpen",
                                      "powerpaste",
                                      "advtable",
                                      "advcode",
                                      "editimage",
                                      "tableofcontents",
                                      "footnotes",
                                      "mergetags",
                                      "autocorrect",
                                      // "typography",
                                      "fullscreen",
                                      "inlinecss",
                                    ]}
                                    toolbar={[
                                      "fullscreen undo redo | blocks fontfamily fontsize | " +
                                        "bold italic underline strikethrough | removeformat code | polygonSectionVersionsBrowser",
                                      "link table mergetags | spellcheckdialog a11ycheck | align lineheight | " +
                                        "checklist numlist bullist indent outdent | plg_addcomment plg_showcomments",
                                    ]}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    display: `${
                                      editorsToShow.includes(section.id)
                                        ? "none"
                                        : "block"
                                    }`,
                                  }}
                                >
                                  <div
                                    style={{
                                      color: darkMode ? "white" : "",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      // __html: section.owning_global_section.about,
                                      __html: `<div class='dangerousHTMLDIVClass'>${
                                        section.section_content ?? ""
                                      }</div>`,
                                    }}
                                  />

                                  {/* <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "50px",
                                      // borderRadius: "6px",
                                      // border: "1px solid #000",
                                      // backgroundColor: "#fff",
                                      marginBottom: "10px",
                                      marginRight: "10px",
                                      color: "#344767",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => showEditor(section.id)}
                                  >
                                    <EditIcon /> Edit
                                  </span> */}
                                </div>

                                {/* editor ends */}
                                {/* Save changes button */}
                              </MDBox>

                              {isSavingSection &&
                              savingSectionIndex === index ? (
                                <MDBox
                                  sx={{
                                    marginTop: "20px",
                                  }}
                                >
                                  <LoadingSpinner color="success" size="lg" />
                                </MDBox>
                              ) : (
                                <MDBox
                                  sx={{
                                    display: "flex",
                                    gap: 2,
                                  }}
                                >
                                  <MDButton
                                    color="dark"
                                    onClick={() => showEditor(section.id)}
                                    sx={{
                                      marginTop: "20px",
                                      display: `${
                                        editorsToShow.includes(section.id)
                                          ? "none"
                                          : "block"
                                      }`,
                                    }}
                                    // disabled={section.completed === 1}
                                  >
                                    Edit Section Content
                                  </MDButton>
                                  <MDButton
                                    color="dark"
                                    onClick={() =>
                                      btnClickSectionSave(
                                        index,
                                        section.id,
                                        section.section_content
                                      )
                                    }
                                    sx={{
                                      marginTop: "20px",
                                      display: `${
                                        editorsToShow.includes(section.id)
                                          ? "block"
                                          : "none"
                                      }`,
                                    }}
                                    // disabled={section.completed === 1}
                                  >
                                    Save Section Content
                                  </MDButton>
                                  <MDButton
                                    color="info"
                                    onClick={() =>
                                      markSectionAsComplete(index, section.id)
                                    }
                                    sx={{
                                      marginTop: "20px",
                                    }}
                                    disabled={section.completed === 1}
                                  >
                                    Mark As Complete
                                  </MDButton>
                                </MDBox>
                              )}
                            </TabPanel>

                            {/* {objPolicyDocument.created_from_global_template ===
                              true ? (
                                <Tab
                                  p={4}
                                  label="Reference Material"
                                  value={`${tabValue}-about`}
                                  index={selectedTabs[tabValue] || defaultTab}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      // __html: section.owning_global_section.about,
                                      __html: section.section_about,
                                    }}
                                  />
                                </TabPanel>
                              ) : (
                                ""
                              )} */}
                          </MDBox>
                        </AccordionDetails>
                      </Accordion>
                      {/* accordion end */}
                    </Grid>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid container item xs={12} lg={12} rowSpacing={3} mb={5}>
              <Grid
                item
                container
                xs={12}
                pt={0}
                pl={2}
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <MergeTagsInfo mergeTags={mergeTags} />
                {objPolicyDocument?.policy?.policy_type === "asset" &&
                  objPolicyDocument?.policy?.connector === "custom" && (
                    <>
                      <NewBlankAssetPolicyDialog
                        open={isImportCSVDialogOpen}
                        type={objPolicyDocument?.policy?.assets_type_imported}
                        onClose={() => setIsImportCSVDialogOpen(false)}
                        onSubmit={createAssetTable}
                      />
                      <MDButton
                        onClick={() => setIsImportCSVDialogOpen(true)}
                        variant="outlined"
                        color="info"
                        sx={{ padding: "12px 12px" }}
                      >
                        Import CSV
                      </MDButton>
                    </>
                  )}
              </Grid>
              <Grid item xs={12} pt={0}>
                <div
                  style={{
                    display: `${editorsToShow.includes(0) ? "block" : "none"}`,
                  }}
                >
                  <Editor
                    disabled={readOnly}
                    apiKey={TINYMC_API_KEY}
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                    }}
                    onEditorChange={(newValue, editor) => {
                      setEditorDocument(newValue);
                    }}
                    // initialValue={values.document}
                    value={editorDocument}
                    init={{
                      branding: false,
                      height: 500,
                      menubar: false,
                      advcode_inline: true,
                      automatic_uploads: true,
                      images_upload_url:
                        process.env.REACT_APP_BACKEND_API_URL +
                        VERSION_SERVER +
                        "/upload-images-to-server",
                      external_plugins: {
                        // polygonAutosave:
                        //   process.env.PUBLIC_URL +
                        //   "/autoSaveMCEPlugin/autoSaveMCEPlugin.js",
                        polygonVersionBrowser:
                          process.env.PUBLIC_URL +
                          "/versionBrowserPlugin/versionBrowserPlugin.js",
                        polygonComments:
                          process.env.PUBLIC_URL +
                          "/PLGCommentsPlugin/PLGCommentsPlugin.js",
                      },
                      // polygonAutosave_autosaveCall: handleAutoSave,
                      // polygonAutosave_interval: "30s",
                      // polygonAutosave_ask_before_unload: false,
                      polygonVersionBrowser_versionsCallback:
                        "getDocumentVersions",
                      polygonVersionBrowser_fetchVersionCallback:
                        props.fetchDocumentVersion,
                      polygonVersionBrowser_compareVersionCallback:
                        props.compareDocumentVersions,
                      polygonComments_author: objUser.user.id,
                      polygonComments_author_name: objUser.user.name,
                      end_container_on_empty_block: "span",
                      //noneditable_class: "mce-annotation",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      mergetags_list: mergeTagsList,
                    }}
                    plugins={[
                      // "preview",
                      "anchor",
                      "autolink",
                      // "charmap",
                      "advcode",
                      // "emoticons",
                      // "image",
                      "link",
                      "lists",
                      "searchreplace",
                      "table",
                      "visualblocks",
                      "wordcount",
                      "checklist",
                      "mediaembed",
                      "casechange",
                      "export",
                      "formatpainter",
                      "pageembed",
                      "linkchecker",
                      "a11ychecker",
                      "tinymcespellchecker",
                      "permanentpen",
                      "powerpaste",
                      "advtable",
                      "advcode",
                      "editimage",
                      "tableofcontents",
                      "footnotes",
                      "mergetags",
                      "autocorrect",
                      // "typography",
                      "fullscreen",
                      "inlinecss",
                    ]}
                    toolbar={[
                      "fullscreen undo redo | blocks fontfamily fontsize | " +
                        "bold italic underline strikethrough | removeformat code | polygonVersionBrowser",
                      "link table mergetags | spellcheckdialog a11ycheck | align lineheight | " +
                        "checklist numlist bullist indent outdent | plg_addcomment plg_showcomments",
                    ]}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    maxHeight: "500px",
                    padding: "5px",
                    borderRadius: "5px",
                    display: `${editorsToShow.includes(0) ? "none" : "block"}`,
                    overflow: "auto",
                  }}
                >
                  <div
                    style={{ color: darkMode ? "white" : "" }}
                    dangerouslySetInnerHTML={{
                      // __html: section.owning_global_section.about,
                      __html: editorDocument,
                    }}
                  />
                </div>
              </Grid>
              {isSavingDocument ? (
                <MDBox
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LoadingSpinner color="success" size="lg" />
                </MDBox>
              ) : (
                <MDBox
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <MDButton
                    color="dark"
                    onClick={() => showEditor(0)}
                    sx={{
                      marginTop: "20px",
                      display: `${
                        editorsToShow.includes(0) ? "none" : "block"
                      }`,
                    }}
                  >
                    Edit Section Content
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={saveContentNoOscal}
                    disabled={isSavingDocument}
                    sx={{
                      marginTop: "20px",
                      display: `${
                        editorsToShow.includes(0) ? "block" : "none"
                      }`,
                    }}
                  >
                    Save{" "}
                    {objPolicyDocument.policy.policy_type == "asset"
                      ? "Asset List"
                      : "Policy"}{" "}
                    Content
                  </MDButton>
                </MDBox>
              )}
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default CreatePolicyDocumentStepDraftFormikWrapper;
