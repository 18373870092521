import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const NameBtnTr = (props) => {
  const { value, row } = props;

  return (
    <MDButton variant="text">
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="dark"
        sx={{ lineHeight: 0, display: "flex", alignItems: "center", gap: 1 }}
      >
        {value}
        {row.original.deleted === "yes" ? (
          <>
            {" "}
            <Tooltip title="This adoption campaign contains a policy that has been deleted">
              <IconButton>
                <InfoIcon color="warning" fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          ""
        )}
      </MDTypography>
    </MDButton>
  );
};

export default NameBtnTr;
