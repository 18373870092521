import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const SlackConnect = (props) => {
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const { objUser, target, scope } = props;
  const { client_id, team_id, channel_id } = useParams();

  useEffect(() => {
    const slackOathState = JSON.stringify({
      routeDictionary,
      client_id,
      target: "slack",
      channel_id,
    });

    const slackOathUrl =
      "https://slack.com/oauth/v2/authorize" +
      `?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}` +
      `&scope=channels:read,chat:write,channels:join,chat:write.public,chat:write.customize` +
      `&state=${encodeURI(slackOathState)}` +
      `&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI_FOR_SLACK}`;
    window.location.href = slackOathUrl;
  }, []);

  return <div>index</div>;
};

// const MSTeamsConnect = (props) => {
//   return (
//     <MsGraphConnect
//       {...props}
//       target="msteams"
//       scope={[
//         "Group.ReadWrite.All",
//         // "files.read",
//         // "files.read.all",
//         // "files.readwrite",
//         // "files.readwrite.all",
//         // "UserAuthenticationMethod.read.all",
//         // "Sites.ReadWrite.All",
//       ]}
//     />
//   );
// };

export { SlackConnect };
