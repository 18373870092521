import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import {
  addContactToAudiences,
  listAudiences,
} from "features/company/clientAudienceActions";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
const objInitialValues = { txtRoleRegisterAccess: 0, txtAssessmentAccess: 0 };
const AddToAudienceFormJWTSchema = Yup.object().shape({
  // sltAudience: Yup.string().required("The audience is required."),
});

function InviteClientUserDialog({
  open,
  onClose,
  onSubmit,
  contact,
  inviteContact,
  objUser,
}) {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    // setIsSubmiting(true);
    if (typeof contact.element === "undefined") {
      showMsg("error", "First select a contact");
      onClose();
      return;
    }
    inviteContact(contact, values);
    onClose();
  };

  useEffect(() => {}, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Add to Audience</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddToAudienceFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddToAudienceForm" autoComplete="off">
            <DialogContent>
              <Grid container>
                <Grid item xs={6}>
                  <MDBox mb={0} mt={0}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.txtAssessmentAccess === 1}
                          name="assessmentAccess"
                        />
                      }
                      label="Assessment Access"
                      onChange={() => {
                        if (values.txtAssessmentAccess === 1) {
                          setFieldValue("txtAssessmentAccess", 0);
                        } else {
                          setFieldValue("txtAssessmentAccess", 1);
                        }
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <MDBox mb={0} mt={0}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.txtRoleRegisterAccess === 1}
                          name="riskRegisterAccess"
                        />
                      }
                      label="Risk Register Access"
                      onChange={() => {
                        if (values.txtRoleRegisterAccess === 1) {
                          setFieldValue("txtRoleRegisterAccess", 0);
                        } else {
                          setFieldValue("txtRoleRegisterAccess", 1);
                        }
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {isSubmiting === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Ok
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default InviteClientUserDialog;
