import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import Grid from "@mui/material/Grid";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { addRiskRegisterItem } from "features/company/riskRegisterActions.js";
import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage } from "formik";
import { desc_to_types } from "views/pages/Dashboard/AssessmentDashboard/AssessmentReports/components/AssessmentEventsTable/RiskMatrixLevels";
const objInitialValues = {
  drpRiskLevel: "",
  txtItemName: "",
  impact: "",
  probability: "",
};
const AddNewItemFormJWTSchema = Yup.object().shape({
  drpRiskLevel: Yup.string().required("The risk level is required."),
  txtItemName: Yup.string().required("The item name is required."),
  impact: Yup.string().required("The Impact is required."),
  probability: Yup.string().required("The Probability is required."),
});

function CreateNewItemDialog({ open, onClose, onSubmit, objUser, riskLevels }) {
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [isSubmittingEvent, setIsSubmittingEvent] = useState(false);
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    setIsSubmittingEvent(true);
    const objPostData = {
      eventName: "Manually Inserted",
      itemName: values.txtItemName,
      riskLevel: values.drpRiskLevel,
      priorityLevel: parseInt(values.impact) * parseInt(values.probability),
    };
    dispatch(addRiskRegisterItem({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        onSubmit();
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized");
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        }
      })
      .finally(() => {
        setIsSubmittingEvent(false);
      });
  };

  useEffect(() => {}, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Add New Risk Register Item</DialogTitle>
      {isLoadingEvents === true ? (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      ) : (
        <Formik
          initialValues={objInitialValues}
          validationSchema={AddNewItemFormJWTSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id="AddNewItemForm" autoComplete="off">
              <DialogContent>
                <MDBox mb={0} mt={0}>
                  <FormField
                    type="text"
                    label="Item Name"
                    name="txtItemName"
                    value={values.txtItemName}
                    placeholder={"Item Name"}
                    error={errors.txtItemName && touched.txtItemName}
                    success={
                      values.txtItemName.length > 0 && !errors.txtItemName
                    }
                  />
                </MDBox>
                <MDBox mb={0} mt={0}>
                  <Autocomplete
                    name="drpRiskLevel"
                    disableClearable
                    value={values.drpRiskLevel}
                    options={riskLevels}
                    getOptionLabel={(option) => (option ? option : "")}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Risk Level"
                        fullWidth
                      />
                    )}
                    onChange={(event, value) => {
                      if (value === null) {
                        setFieldValue("drpRiskLevel", "");
                      } else {
                        setFieldValue("drpRiskLevel", value);
                      }
                    }}
                  />
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      <ErrorMessage name="drpItem" />
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox>
                  <Grid container spacing={3}>
                    <Grid item mb={0} mt={0} sm={6}>
                      <Autocomplete
                        name="impact"
                        disableClearable
                        value={values.impact}
                        options={Object.keys(desc_to_types)}
                        getOptionLabel={(option) => (option ? option : "")}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label="Impact Level (1-Low to 5-High)"
                            fullWidth
                          />
                        )}
                        onChange={(event, value) => {
                          if (value === null) {
                            setFieldValue("impact", "");
                          } else {
                            setFieldValue("impact", value);
                          }
                        }}
                      />
                      <MDBox mt={0.75}>
                        <MDTypography
                          component="div"
                          variant="caption"
                          color="error"
                          fontWeight="regular"
                        >
                          <ErrorMessage name="impact" />
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item mb={0} mt={0} sm={6}>
                      <Autocomplete
                        name="probability"
                        disableClearable
                        value={values.probability}
                        options={Object.keys(desc_to_types)}
                        getOptionLabel={(option) => (option ? option : "")}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label="Probability Level (1-Low to 5-High)"
                            fullWidth
                          />
                        )}
                        onChange={(event, value) => {
                          if (value === null) {
                            setFieldValue("probability", "");
                          } else {
                            setFieldValue("probability", value);
                          }
                        }}
                      />
                      <MDBox mt={0.75}>
                        <MDTypography
                          component="div"
                          variant="caption"
                          color="error"
                          fontWeight="regular"
                        >
                          <ErrorMessage name="probability" />
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </DialogContent>
              <DialogActions>
                {isSubmittingEvent === false ? (
                  <>
                    <MDButton
                      type="submit"
                      color="success"
                      sx={{ padding: "12px 12px" }}
                    >
                      Add
                    </MDButton>
                    <MDButton
                      type="button"
                      variant="outlined"
                      color="dark"
                      sx={{ padding: "12px 12px" }}
                      onClick={onClose}
                    >
                      Cancel
                    </MDButton>
                  </>
                ) : (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
}
export default CreateNewItemDialog;
