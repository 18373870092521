import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import SignUpCreateNewOrganizationFormErrorLi from "./SignUpCreateNewOrganizationFormErrorLi";

const SignUpCreateNewOrganizationFormErrorsBlock = (props) => {

  const renderErrors = () =>
    props.objCreateNewOrganizationErrors
      ? Object.entries(props.objCreateNewOrganizationErrors).map(
          ([key, value], i) => (
            <SignUpCreateNewOrganizationFormErrorLi key={key} value={value} />
          )
        )
      : null;
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity="error">
        {renderErrors(props.objCreateNewOrganizationErrors)}
      </Alert>
    </Stack>
  );
};

export default SignUpCreateNewOrganizationFormErrorsBlock;
