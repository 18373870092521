import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import { useConfirm } from "material-ui-confirm";
import { Fragment } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import useSspPDFDownloader from "../../useSspPDFDownloader";

const ActionBtnTr = (props) => {
  const { row, editObj, isDeletingWisp, deleteObj } = props;
  const { downloadPDF, isLoadingPDF } = useSspPDFDownloader();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const confirm = useConfirm();

  const nagivateToViewSspPage = (dataObj) => {
    routeDictionary[dataObj.id] = dataObj.name;
    navigate(`/auditor-reports/${dataObj.id}`, {
      state: { routeDictionary },
    });
  };

  const btnOnClickEditObj = (data) => {
    editObj(data);
  };

  const btnOnClickDeleteObj = (data) => {
    confirm({
      description: "Do you want to delete this Auditor Report?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteObj(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  const btnClickDownloadWispPDF = (dataObj) => {
    downloadPDF(null, dataObj.id);
  };

  return (
    <Fragment>
      <MDButton onClick={() => nagivateToViewSspPage(row.original)}>
        <MDBox color="dark" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>visibility</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.dark"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            View
          </MDTypography>
        </MDBox>
      </MDButton>

      {/* <MDButton
        onClick={() => btnClickDownloadWispPDF(row.original)}
        sx={{ marginLeft: "1rem" }}
      >
        <MDBox color="info" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.primary"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            {isLoadingPDF ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            ) : (
              "  Download"
            )}
          </MDTypography>
        </MDBox>
      </MDButton> */}

      {isDeletingWisp ? (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      ) : (
        <MDButton
          onClick={() => btnOnClickDeleteObj(row.original)}
          variant="gradient"
          sx={{ marginLeft: "1rem" }}
        >
          <MDBox color="error" display="flex" alignItems="center">
            <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
            <MDTypography
              variant="p"
              fontWeight="medium"
              color="error"
              sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
            >
              Delete
            </MDTypography>
          </MDBox>
        </MDButton>
      )}
    </Fragment>
  );
};

export default ActionBtnTr;
