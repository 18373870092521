/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import Pagination from "@mui/material/Pagination";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import DefaultCell from "views/components/TableCells/DefaultCell";
import ClientContactsDataTableData from "./ContactsByAudienceTableData";
import ContactsByAudienceActionBtnTr from "./ContactsByAudienceActionBtnTr";
import { Checkbox } from "@mui/material";

function ContactsByAudienceTable({
  // id,
  audience_id,
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  totalCount,
  loadMoreContactsByAudience,
  perPage,
  setPerPage,
  _page,
  setPage,
  setObjTableData,
  isDeletingFromAudience,
  deleteFromAudience,
  objSelectedClientContact,
  setContactsSelectedForBulkAction,
  contactsSelectedForBulkAction,
}) {
  const handleSelectRow = (event, row) => {
    if (event.target.checked) {
      setContactsSelectedForBulkAction([
        ...contactsSelectedForBulkAction,
        row.original.element.id,
      ]);
    } else {
      setContactsSelectedForBulkAction(
        contactsSelectedForBulkAction.filter(
          (contactId) => contactId !== row.original.element.id
        )
      );
    }
  };

  const objColumns = [
    ...ClientContactsDataTableData.columns,
    {
      Header: "action",
      accessor: "action",
      // Cell: ({ value, column, row }) => {
      //   return <div onClick={() => console.log(row.original)}>Click Me</div>;
      // },
      Cell: ({ value, column, row }) => (
        <ContactsByAudienceActionBtnTr
          value={value}
          column={column}
          row={row}
          deleteFromAudience={deleteFromAudience}
          isDeletingFromAudience={isDeletingFromAudience}
          objSelectedClientContact={objSelectedClientContact}
        />
      ),
    },
  ];
  // const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const defaultValue = perPage;

  const columns = useMemo(
    () => objColumns,
    [table, isDeletingFromAudience, objSelectedClientContact]
    // [table, isActivatingClient, objSelectedClient, isDeletingClient, isDeActivatingClient]
  );
  // const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(
    () => table.rows,
    [table, objSelectedClientContact, isDeletingFromAudience]
  );
 const entries = entriesPerPage.entries
   ? entriesPerPage.entries.map((el) => el.toString())
   : ["10", "25", "50", "100"];
   
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: _page - 1 },
      // initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: totalCount,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const onPageSelect = (pageNo) => {
    // alert(pageNo);
    setPage(pageNo);
    loadMoreContactsByAudience(audience_id, perPage, pageNo);
  };
  const handleChange = (event, value) => {
    setPage(value);
    loadMoreContactsByAudience(audience_id, perPage, value);
  };
  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => {
        onPageSelect(Number(option) + 1);
        gotoPage(Number(option));
      }}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              <DataTableHeadCell padding="checkbox" width={"6%"}>
                <Checkbox
                  indeterminate={
                    contactsSelectedForBulkAction.length > 0 &&
                    contactsSelectedForBulkAction.length < rows.length
                  }
                  checked={contactsSelectedForBulkAction.length === rows.length}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setContactsSelectedForBulkAction(
                        rows.map((row) => row.original.element.id)
                      );
                    } else {
                      setContactsSelectedForBulkAction([]);
                    }
                  }}
                  inputProps={{ "aria-label": "select all contacts" }}
                />
              </DataTableHeadCell>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                <DataTableBodyCell
                  noBorder={noEndBorder && rows.length - 1 === key}
                >
                  <Checkbox
                    checked={contactsSelectedForBulkAction.includes(
                      row.original.element.id
                    )}
                    onChange={(event) => handleSelectRow(event, row)}
                  />
                </DataTableBodyCell>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        <MDBox mb={{ xs: 12, sm: 0 }}>
          <Pagination
            count={Math.ceil(totalCount / perPage)}
            page={_page}
            onChange={handleChange}
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          />
        </MDBox>

        {/* {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
            defaultPage={6}
            siblingCount={0}
            boundaryCount={2}
          >
            
            {canPreviousPage && (
              <MDPagination
                item
                onClick={() => {
                  onPageSelect(_page - 1);
                  previousPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            
            {renderPagination}
            {canNextPage && (
              <MDPagination
                item
                onClick={() => {
                  onPageSelect(_page + 1);
                  nextPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
            
          </MDPagination>
        )} */}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of ClientContactsDataByIdTable
ContactsByAudienceTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the ClientContactsDataByIdTable
// ClientContactsDataByIdTable.propTypes = {
//   entriesPerPage: PropTypes.oneOfType([
//     PropTypes.shape({
//       defaultValue: PropTypes.number,
//       entries: PropTypes.arrayOf(PropTypes.number),
//     }),
//     PropTypes.bool,
//   ]),
//   canSearch: PropTypes.bool,
//   showTotalEntries: PropTypes.bool,
//   table: PropTypes.objectOf(PropTypes.array).isRequired,
//   pagination: PropTypes.shape({
//     variant: PropTypes.oneOf(["contained", "gradient"]),
//     color: PropTypes.oneOf([
//       "primary",
//       "secondary",
//       "info",
//       "success",
//       "warning",
//       "error",
//       "dark",
//       "light",
//     ]),
//   }),
//   isSorted: PropTypes.bool,
//   noEndBorder: PropTypes.bool,
// };

export default ContactsByAudienceTable;
