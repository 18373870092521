import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

export const saveConnectWiseApiKeys = createAsyncThunk(
  "company/saveConnectWiseApiKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/connectwise/link`,
        values
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getConnectWiseApiKeys = createAsyncThunk(
  "company/getConnectWiseApiKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/connectwise/link?client_id=${values.client_id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const removeApiKeys = createAsyncThunk(
  "company/removeApiKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/connectwise/link`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
