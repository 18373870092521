import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import Grid from "@mui/material/Grid";
// formik components
import { Formik, Form } from "formik";
import { convertToMSPUser } from "features/company/companiesActions";

function ConvertToMSPUserDialog({
  open,
  onClose,
  contactId,
  objInitialValues,
  objUser,
  setIsMSPUserContact,
  setIsClientUserContact,
}) {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const objPostData = {
      contactId,
    };
    setIsSubmiting(true);
    dispatch(convertToMSPUser({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsSubmiting(false);
        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg("success", "User changed to MSP User successfully");
          setIsClientUserContact(false);
          setIsMSPUserContact(true);
          onClose();
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsSubmiting(false);
        showMsg("error", "Please correct the errors.");
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        if (objErrors !== undefined) {
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      });
  };

  useEffect(() => {}, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Convert to MSP User</DialogTitle>
      <Formik initialValues={objInitialValues} onSubmit={onSubmitForm}>
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddToAudienceForm" autoComplete="off">
            <DialogContent>
              <Grid container>
                <MDTypography color="secondary">
                  Are you sure you want to convert{" "}
                  {objInitialValues.txtFirstName} {objInitialValues.txtLastName}{" "}
                  ({objInitialValues.txtEmail}) to an MSP User? They will be
                  able to access information for ALL Companies associated with
                  your MSP.
                </MDTypography>
              </Grid>
            </DialogContent>
            <DialogActions>
              {isSubmiting === false ? (
                <>
                  <MDButton
                    type="submit"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Ok
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default ConvertToMSPUserDialog;
