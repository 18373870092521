import { memo } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";

const ResponsibilityRadio = memo(
  ({
    question_id,
    responsibility,
    onChangeResponsibility,
    value,
    description,
    vendor,
    tool,
  }) => {
    return (
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => onChangeResponsibility(question_id, value, vendor, tool)}
        >
          <ListItemIcon>
            <Radio
              checked={responsibility === value}
              value={value}
              name="responsibility"
              inputProps={{ "aria-label": value }}
            />
          </ListItemIcon>
          <ListItemText primary={description} sx= {{ color:"text.main" }} />
        </ListItemButton>
      </ListItem>
    );
  }
);

export default ResponsibilityRadio;
