import NameBtnTr from "./NameBtnTr";
const CategoriesDataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "assessmentTypeName",
      Cell: ({ value, column, row }) => (
        <NameBtnTr value={value} column={column} row={row} />
      ),
    },
  ],
  rows: [],
};

export default CategoriesDataTableData;
