import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// @mui icons
import { useLocation, useNavigate } from "react-router-dom";
const TaskListTitleBtnTr = (props) => {
  const { value, row } = props;

  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const btnOnClickTitle = (dataObj) => {
    routeDictionary[dataObj.task_list_id] = dataObj.title;
    navigate(`/task-lists/${dataObj.id}`, {
      state: { routeDictionary },
    });
  };

  return (
    <MDButton variant="text" onClick={() => btnOnClickTitle(row.original)}>
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default TaskListTitleBtnTr;
