import queryString from "query-string";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import PageLayout from "views/containers/PageLayout";
import { unwrapResult } from "@reduxjs/toolkit";

import StripeCheckoutSuccessSection from "./components/StripeCheckoutSuccessSection";
import { retrievePaymentMethodFromSession } from "features/stripe/stripeCheckOutActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";

const StripeCheckoutSuccess = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = queryString.parse(window.location.search);

  useEffect(() => {
    fetchStripeCheckOutSessionById(urlParams.session_id);
  }, []);
  const fetchStripeCheckOutSessionById = (stripe_session_id) => {
    setIsLoading(true);
    const objPostData = {
      session_id: stripe_session_id,
    };
    dispatch(retrievePaymentMethodFromSession(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);

        const response = originalPromiseResult;

        if (response.data.status === "success") {
          showMsg("success", "Payment has been collected successfully");
          navigate("/home");
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Invalid url.");
        navigate("/setup-account/step-one");

        console.log(rejectedValueOrSerializedError);
      });
  };

  return (
    <PageLayout background="default">
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox>
                  <StripeCheckoutSuccessSection isLoading={isLoading} />
                  <MDBox
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  ></MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
};

export default StripeCheckoutSuccess;
