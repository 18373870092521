import { updatePoam } from "features/company/riskRegisterActions";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// @mui icons
import { Fragment, useState } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import FormField from "layouts/applications/wizard/components/FormField";

import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";

const PoamTextField = (props) => {
  const { row, objUser, field } = props;
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState(row.original[field]);
  const dispatch = useDispatch();

  let timerId;

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);

    clearTimeout(timerId);

    timerId = setTimeout(
      () => {
        update(newValue);
      },
      field.includes("date") ? 5000 : 1500
    );
  };

  const update = (value) => {
    let poamId = row.original.id;
    const objPostData = {
      field: field,
      value,
    };

    dispatch(updatePoam({ objUser, objPostData, poamId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 201) {
          setLoading(false);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized ");
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {objUser.user.role.name !== "admin" ||
      objUser.user.role.name !== "company_user" ||
      objUser.user?.permissions?.risk_register_access == 1 ? (
        <>
          {loading ? (
            <LoadingSpinner subClass="text-center" color="success" size="sm" />
          ) : (
            <MDBox sx={{ width: "100px" }}>
              {field.includes("date") ? (
                <FormField
                  inputProps={{ type: "date" }}
                  value={currentValue}
                  onChange={handleInputChange}
                />
              ) : (
                <FormField
                  inputProps={{ type: "text" }}
                  value={currentValue}
                  onChange={handleInputChange}
                />
              )}
            </MDBox>
          )}
        </>
      ) : (
        <>{currentValue}</>
      )}
    </Fragment>
  );
};

export default PoamTextField;
