// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import { useConfirm } from "material-ui-confirm";
import { Fragment, useState } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import useSspPDFDownloader from "../../useSspPDFDownloader";
import PDFDownloadSettingsDialog from "views/pages/Dashboard/CompanyConfig/components/PDFDownloadSettingsDialog";

const ActionBtnTr = (props) => {
  const { row, isDeletingWisp, deleteObj, objUser } = props;
  const { downloadPDF, isLoadingPDF } = useSspPDFDownloader();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const confirm = useConfirm();

  const nagivateToViewSspVersionsPage = (dataObj) => {
    routeDictionary[dataObj.id] = dataObj.name;
    navigate(`/assessments/CCA/${dataObj.id}`, {
      state: { routeDictionary },
    });
  };

  const btnOnClickDeleteObj = (data) => {
    confirm({
      description: "Do you want to delete this CCA?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteObj(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  /**pdf display settings */
  const [displayPdfSettingsValue, setDisplayPdfSettingValue] = useState(
    objUser?.user?.company?.pdf_display_settings ?? null
  );
  const [openPdfSettingsDialog, setOpenPdfSettingsDialog] = useState(false);
  const onclosePdfSettingsDialog = () => {
    setOpenPdfSettingsDialog(false);
  };
  /**end pdf display settings */

  const btnClickDownloadWispPDF = (dataObj) => {
    downloadPDF(null, dataObj.id, displayPdfSettingsValue);
  };

  return (
    <Fragment>
      <PDFDownloadSettingsDialog
        openPdfSettingsDialog={openPdfSettingsDialog}
        onclosePdfSettingsDialog={onclosePdfSettingsDialog}
        displayPdfSettingsValue={displayPdfSettingsValue}
        setDisplayPdfSettingValue={setDisplayPdfSettingValue}
        startDownload={btnClickDownloadWispPDF}
        dataObj={row.original}
        isLoadingPDF={isLoadingPDF}
      />
      <MDButton onClick={() => nagivateToViewSspVersionsPage(row.original)}>
        <MDBox color="dark" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>visibility</Icon>
        </MDBox>
      </MDButton>

      {/* <MDButton
        // onClick={() => btnClickDownloadWispPDF(row.original)}
        onClick={() => {
          setOpenPdfSettingsDialog(true);
        }}
        sx={{ marginLeft: "1rem" }}
      >
        <MDBox color="info" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.primary"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            {isLoadingPDF
              ? // <LoadingSpinner
                //   subClass="text-center"
                //   color="success"
                //   size="lg"
                // />
                ""
              : ""}
          </MDTypography>
        </MDBox>
      </MDButton> */}
      {(objUser.user.role.name == "company_user" ||
        objUser.user.role.name == "admin") && (
        <>
          {isDeletingWisp ? (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          ) : (
            <MDButton
              onClick={() => btnOnClickDeleteObj(row.original)}
              variant="gradient"
              sx={{ marginLeft: "1rem" }}
            >
              <MDBox color="error" display="flex" alignItems="center">
                <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
              </MDBox>
            </MDButton>
          )}
        </>
      )}
    </Fragment>
  );
};

export default ActionBtnTr;
