import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "helpers/Form/Formik/FormField";
import { Fragment } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import InviteAdminUsersFormErrorsBlock from "./InviteAdminUsersFormErrorsBlock";

const SignUpCreateNewOrganizationFormikWrapper = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    handleBack,
    objInviteAdminUsersFormErrors,
    isLoading,
    objStates,
  } = props;

  return (
    <Fragment>
      {Object.keys(objInviteAdminUsersFormErrors).length > 0 ? (
        <Grid container direction="row" justifyContent="space-between" pl={5} pr={5} pt={5}>
          <InviteAdminUsersFormErrorsBlock
            objInviteAdminUsersFormErrors={objInviteAdminUsersFormErrors}
          />
        </Grid>
      ) : null}
      <Grid container direction="row" justifyContent="space-between" p={5}>
        <Grid container spacing={2}>
          <Grid
            item
            md={8}
            sm={12}
            sx={{
              "@media (max-width: 490px)": {
                width: "100%",
              },
            }}
          >
            <FormField
              type="text"
              label="Email Address"
              name="txtEmailAddress"
              value={values.txtEmailAddress}
              placeholder={"Email Address"}
              variant="outlined"
              error={errors.txtEmailAddress && touched.txtEmailAddress}
              success={values.txtEmailAddress.length > 0 && !errors.txtEmailAddress}
            />
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
            sx={{
              "@media (max-width: 490px)": {
                width: "100%",
              },
            }}
          >
            {isLoading === false ? (
              <MDButton
                type="submit"
                disabled={isSubmitting}
                color="success"
                fullWidth={true}
              >
                Send Invite
              </MDButton>
            ) : (
              <MDBox mt={2} width="100%" display="flex" justifyContent="center">
                <LoadingSpinner subClass="text-center" color="success" size="lg" />
              </MDBox>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SignUpCreateNewOrganizationFormikWrapper;
