import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import InfoIcon from "@mui/icons-material/Info";

const SaveApiKeysFormFormikWrapper = (props) => {
  const { values, connector, errors, touched, setFieldValue } = props;
  //console.log(values);
  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h5"
          fontWeight="bold"
        >
          Add Your {connector} API Keys
        </MDTypography>
        <MDBox mt={2}>
          <Grid container xs={12} justifyContent="space-between" px={5}>
            <MDBox mb={2}>
              <Alert icon={<InfoIcon fontSize="inherit" />} severity="success">
                <a
                  target="_blank"
                  href="https://scribehow.com/shared/Add_Compliance_Scorecard_API_in_NinjaRMM__GX-WrvhkQtyRKFoaN77bcw"
                >
                  How to get your ClientID and Client Secret
                </a>{" "}
              </Alert>
            </MDBox>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your Client ID"
                name="client_id"
                value={values.client_id}
                placeholder={"Access key"}
                error={errors.client_id && touched.client_id}
                success={values.client_id.length > 0 && !errors.client_id}
              />
            </Grid>

            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="password"
                label="Enter Your Client Secret"
                name="client_secrete"
                value={values.client_secrete}
                placeholder={"client_secrete"}
                error={errors.client_secrete && touched.client_secrete}
                success={
                  values.client_secrete.length > 0 && !errors.client_secrete
                }
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default SaveApiKeysFormFormikWrapper;
