import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AcceptUserCompanyInviteFormErrorLi from "./AcceptUserCompanyInviteFormErrorLi";

const AcceptUserCompanyInviteFormErrorsBlock = (props) => {
  const renderErrors = () =>
    props.objAcceptUserCompanyInviteFormErrors
      ? Object.entries(props.objAcceptUserCompanyInviteFormErrors).map(
          ([key, value], i) => (
            <AcceptUserCompanyInviteFormErrorLi key={key} value={value} />
          )
        )
      : null;
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity="error">
        {renderErrors(props.objAcceptUserCompanyInviteFormErrors)}
      </Alert>
    </Stack>
  );
};

export default AcceptUserCompanyInviteFormErrorsBlock;
