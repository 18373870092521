import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";

const InfoFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue, objIndustries } = props;

  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h4"
          fontWeight="bold"
        >
          Edit Client
        </MDTypography>
        <MDBox mt={2}>
          <MDTypography
            px={5}
            textAlign="left"
            mb={1}
            variant="h6"
            fontWeight="bold"
          >
            2. Extra Information
          </MDTypography>
          <Grid container spacing={3} justifyContent="space-between" p={5}>
            <Grid item xs={12} lg={12}>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Multi-Factor Authentication
                  </MDTypography>
                  <label htmlFor={"gtn_mfa_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"gtn_mfa"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("gtn_mfa", "Yes");
                        }}
                        id={"gtn_mfa_Yes"}
                        checked={values.gtn_mfa === "Yes"}
                        error={errors.gtn_mfa && touched.gtn_mfa}
                        success={values.gtn_mfa.length > 0 && !errors.gtn_mfa}
                        // disabled={disabled}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="h6" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"gtn_mfa_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"gtn_mfa"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("gtn_mfa", "No");
                        }}
                        id={"gtn_mfa_No"}
                        checked={values.gtn_mfa === "No"}
                        error={errors.gtn_mfa && touched.gtn_mfa}
                        success={values.gtn_mfa.length > 0 && !errors.gtn_mfa}
                        // disabled={disabled}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="h6" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  {errors.gtn_mfa && touched.gtn_mfa ? (
                    <FormHelperText error>{errors.gtn_mfa}</FormHelperText>
                  ) : null}
                </Grid>
                {values.gtn_mfa == "Yes" && (
                  <Grid item xs={6} lg={6}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Multi-Factor Authentication Vendor and Tool
                    </MDTypography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Vendor"
                          name="gtn_mfa_vendor"
                          value={values.gtn_mfa_vendor ?? ""}
                          placeholder={"Vendor"}
                          error={
                            errors.gtn_mfa_vendor && touched.gtn_mfa_vendor
                          }
                          success={
                            values.gtn_mfa_vendor.length > 0 &&
                            !errors.gtn_mfa_vendor
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Tool"
                          name="gtn_mfa_tool"
                          value={values.gtn_mfa_tool ?? ""}
                          placeholder={"Tool"}
                          error={errors.gtn_mfa_tool && touched.gtn_mfa_tool}
                          success={
                            values.gtn_mfa_tool?.length > 0 &&
                            !errors.gtn_mfa_tool
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Security Awareness Training & Testing
                  </MDTypography>
                  <label htmlFor={"gtn_security_awareness_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"gtn_security_awareness"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("gtn_security_awareness", "Yes");
                        }}
                        id={"gtn_security_awareness_Yes"}
                        checked={values.gtn_security_awareness === "Yes"}
                        error={
                          errors.gtn_security_awareness &&
                          touched.gtn_security_awareness
                        }
                        success={
                          values.gtn_security_awareness.length > 0 &&
                          !errors.gtn_security_awareness
                        }
                        // disabled={disabled}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="h6" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"gtn_security_awareness_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"gtn_security_awareness"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("gtn_security_awareness", "No");
                        }}
                        id={"gtn_security_awareness_No"}
                        checked={values.gtn_security_awareness === "No"}
                        error={
                          errors.gtn_security_awareness &&
                          touched.gtn_security_awareness
                        }
                        success={
                          values.gtn_security_awareness.length > 0 &&
                          !errors.gtn_security_awareness
                        }
                        // disabled={disabled}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="h6" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  {errors.gtn_security_awareness &&
                  touched.gtn_security_awareness ? (
                    <FormHelperText error>
                      {errors.gtn_security_awareness}
                    </FormHelperText>
                  ) : null}
                </Grid>
                {values.gtn_security_awareness === "Yes" && (
                  <Grid item xs={6} lg={6}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Security Awareness Training & Testing Vendor and Tool
                    </MDTypography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Vendor"
                          name="gtn_security_awareness_vendor"
                          value={values.gtn_security_awareness_vendor ?? ""}
                          placeholder={"Vendor"}
                          error={
                            errors.gtn_security_awareness_vendor &&
                            touched.gtn_security_awareness_vendor
                          }
                          success={
                            values.gtn_security_awareness_vendor.length > 0 &&
                            !errors.gtn_security_awareness_vendor
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Tool"
                          name="gtn_security_awareness_tool"
                          value={values.gtn_security_awareness_tool ?? ""}
                          placeholder={"Tool"}
                          error={
                            errors.gtn_security_awareness_tool &&
                            touched.gtn_security_awareness_tool
                          }
                          success={
                            values.gtn_security_awareness_tool?.length > 0 &&
                            !errors.gtn_security_awareness_tool
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Separate Backups
                  </MDTypography>
                  <label htmlFor={"gtn_seperate_backup_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"gtn_seperate_backup"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("gtn_seperate_backup", "Yes");
                        }}
                        id={"gtn_seperate_backup_Yes"}
                        checked={values.gtn_seperate_backup === "Yes"}
                        error={
                          errors.gtn_seperate_backup &&
                          touched.gtn_seperate_backup
                        }
                        success={
                          values.gtn_seperate_backup.length > 0 &&
                          !errors.gtn_seperate_backup
                        }
                        // disabled={disabled}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="h6" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"gtn_seperate_backup_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"gtn_seperate_backup"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("gtn_seperate_backup", "No");
                        }}
                        id={"gtn_seperate_backup_No"}
                        checked={values.gtn_seperate_backup === "No"}
                        error={
                          errors.gtn_seperate_backup &&
                          touched.gtn_seperate_backup
                        }
                        success={
                          values.gtn_seperate_backup.length > 0 &&
                          !errors.gtn_seperate_backup
                        }
                        // disabled={disabled}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="h6" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  {errors.gtn_seperate_backup && touched.gtn_seperate_backup ? (
                    <FormHelperText error>
                      {errors.gtn_seperate_backup}
                    </FormHelperText>
                  ) : null}
                </Grid>
                {values.gtn_seperate_backup === "Yes" && (
                  <Grid item xs={6} lg={6}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Separate Backups Vendor and Tool
                    </MDTypography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Vendor"
                          name="gtn_seperate_backup_vendor"
                          value={values.gtn_seperate_backup_vendor ?? ""}
                          placeholder={"Vendor"}
                          error={
                            errors.gtn_seperate_backup_vendor &&
                            touched.gtn_seperate_backup_vendor
                          }
                          success={
                            values.gtn_seperate_backup_vendor.length > 0 &&
                            !errors.gtn_seperate_backup_vendor
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Tool"
                          name="gtn_seperate_backup_tool"
                          value={values.gtn_seperate_backup_tool ?? ""}
                          placeholder={"Tool"}
                          error={
                            errors.gtn_seperate_backup_tool &&
                            touched.gtn_seperate_backup_tool
                          }
                          success={
                            values.gtn_seperate_backup_tool?.length > 0 &&
                            !errors.gtn_seperate_backup_tool
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Endpoint Detection & Response/Managed Detection & Response
                  </MDTypography>
                  <label htmlFor={"gtn_endpoint_managed_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"gtn_endpoint_managed"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("gtn_endpoint_managed", "Yes");
                        }}
                        id={"gtn_endpoint_managed_Yes"}
                        checked={values.gtn_endpoint_managed === "Yes"}
                        error={
                          errors.gtn_endpoint_managed &&
                          touched.gtn_endpoint_managed
                        }
                        success={
                          values.gtn_endpoint_managed.length > 0 &&
                          !errors.gtn_endpoint_managed
                        }
                        // disabled={disabled}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="h6" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"gtn_endpoint_managed_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"gtn_endpoint_managed"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("gtn_endpoint_managed", "No");
                        }}
                        id={"gtn_endpoint_managed_No"}
                        checked={values.gtn_endpoint_managed === "No"}
                        error={
                          errors.gtn_endpoint_managed &&
                          touched.gtn_endpoint_managed
                        }
                        success={
                          values.gtn_endpoint_managed.length > 0 &&
                          !errors.gtn_endpoint_managed
                        }
                        // disabled={disabled}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="h6" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  {errors.gtn_endpoint_managed &&
                  touched.gtn_endpoint_managed ? (
                    <FormHelperText error>
                      {errors.gtn_endpoint_managed}
                    </FormHelperText>
                  ) : null}
                </Grid>
                {values.gtn_endpoint_managed === "Yes" && (
                  <Grid item xs={6} lg={6}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Endpoint Detection & Response/Managed Detection & Response
                      Vendor and Tool
                    </MDTypography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Vendor"
                          name="gtn_endpoint_managed_vendor"
                          value={values.gtn_endpoint_managed_vendor ?? ""}
                          placeholder={"Vendor"}
                          error={
                            errors.gtn_endpoint_managed_vendor &&
                            touched.gtn_endpoint_managed_vendor
                          }
                          success={
                            values.gtn_endpoint_managed_vendor.length > 0 &&
                            !errors.gtn_endpoint_managed_vendor
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Tool"
                          name="gtn_endpoint_managed_tool"
                          value={values.gtn_endpoint_managed_tool ?? ""}
                          placeholder={"Tool"}
                          error={
                            errors.gtn_endpoint_managed_tool &&
                            touched.gtn_endpoint_managed_tool
                          }
                          success={
                            values.gtn_endpoint_managed_tool?.length > 0 &&
                            !errors.gtn_endpoint_managed_tool
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Vulnerability Management
                  </MDTypography>
                  <label htmlFor={"gtn_vulnerability_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"gtn_vulnerability"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("gtn_vulnerability", "Yes");
                        }}
                        id={"gtn_vulnerability_Yes"}
                        checked={values.gtn_vulnerability === "Yes"}
                        error={
                          errors.gtn_vulnerability && touched.gtn_vulnerability
                        }
                        success={
                          values.gtn_vulnerability.length > 0 &&
                          !errors.gtn_vulnerability
                        }
                        // disabled={disabled}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="h6" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"gtn_vulnerability_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"gtn_vulnerability"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("gtn_vulnerability", "No");
                        }}
                        id={"gtn_vulnerability_No"}
                        checked={values.gtn_vulnerability === "No"}
                        error={
                          errors.gtn_vulnerability && touched.gtn_vulnerability
                        }
                        success={
                          values.gtn_vulnerability.length > 0 &&
                          !errors.gtn_vulnerability
                        }
                        // disabled={disabled}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="h6" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  {errors.gtn_vulnerability && touched.gtn_vulnerability ? (
                    <FormHelperText error>
                      {errors.gtn_vulnerability}
                    </FormHelperText>
                  ) : null}
                </Grid>
                {values.gtn_vulnerability === "Yes" && (
                  <Grid item xs={6} lg={6}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Vulnerability Management Vendor and Tool
                    </MDTypography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Vendor"
                          name="gtn_vulnerability_vendor"
                          value={values.gtn_vulnerability_vendor ?? ""}
                          placeholder={"Vendor"}
                          error={
                            errors.gtn_vulnerability_vendor &&
                            touched.gtn_vulnerability_vendor
                          }
                          success={
                            values.gtn_vulnerability_vendor.length > 0 &&
                            !errors.gtn_vulnerability_vendor
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Tool"
                          name="gtn_vulnerability_tool"
                          value={values.gtn_vulnerability_tool ?? ""}
                          placeholder={"Tool"}
                          error={
                            errors.gtn_vulnerability_tool &&
                            touched.gtn_vulnerability_tool
                          }
                          success={
                            values.gtn_vulnerability_tool?.length > 0 &&
                            !errors.gtn_vulnerability_tool
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant have an active cyber policy in force?
              </MDTypography>
              <label htmlFor={"flag_currentcyberpolicy_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_currentcyberpolicy"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_currentcyberpolicy", "Yes");
                    }}
                    id={"flag_currentcyberpolicy_Yes"}
                    checked={values.flag_currentcyberpolicy === "Yes"}
                    error={
                      errors.flag_currentcyberpolicy &&
                      touched.flag_currentcyberpolicy
                    }
                    success={
                      values.flag_currentcyberpolicy.length > 0 &&
                      !errors.flag_currentcyberpolicy
                    }
                    // disabled={disabled}
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_currentcyberpolicy_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_currentcyberpolicy"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_currentcyberpolicy", "No");
                    }}
                    id={"flag_currentcyberpolicy_No"}
                    checked={values.flag_currentcyberpolicy === "No"}
                    error={
                      errors.flag_currentcyberpolicy &&
                      touched.flag_currentcyberpolicy
                    }
                    success={
                      values.flag_currentcyberpolicy.length > 0 &&
                      !errors.flag_currentcyberpolicy
                    }
                    // disabled={disabled}
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="h6" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Autocomplete
                    options={objIndustries}
                    name="industry"
                    value={
                      objIndustries.find(
                        (industry) => industry.label === values.industry
                      ) || null
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Industry/Sector"
                      />
                    )}
                    onChange={(event, value) => {
                      if (value === null) {
                        setFieldValue("industry", "");
                      } else {
                        setFieldValue("industry", value.label);
                      }
                    }}
                    getOptionLabel={(option) => (option ? option.label : "")}
                  />
                  {errors.industry && touched.industry ? (
                    <FormHelperText error>{errors.industry}</FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <FormField
                    type="text"
                    label="Industry NAICS Code"
                    name="naicscode"
                    value={values.naicscode ?? ""}
                    placeholder={"Industry NAICS Code"}
                    error={errors.naicscode && touched.naicscode}
                    success={values.naicscode.length > 0 && !errors.naicscode}
                  />
                  {/* <Autocomplete
                    options={objStates}
                    name="naicscode"
                    value={
                      objStates.find(
                        (code) => code.label === values.naicscode
                      ) || null
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Industry NAICS Code"
                      />
                    )}
                    onChange={(event, value) => {
                      if (value === null) {
                        setFieldValue("naicscode", "");
                      } else {
                        setFieldValue("naicscode", value.label);
                      }
                    }}
                    getOptionLabel={(option) => (option ? option.label : "")}
                  />
                  {errors.naicscode && touched.naicscode ? (
                    <FormHelperText error>{errors.naicscode}</FormHelperText>
                  ) : null} */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default InfoFormFormikWrapper;
