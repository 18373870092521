import DefaultCell from "views/components/TableCells/DefaultCell";
import TemplateNameBtnTr from "./TemplateNameBtnTr";
import TemplateActionBtnTr from "./TemplateActionBtnTr";
import { IconButton, Tooltip } from "@mui/material";

const GlobalTemplatesDataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "name",
      width: "50%",
      Cell: ({ value, column, row }) => (
        <TemplateNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      width: "30%",
      Cell: ({ value }) => (
        <>
          <Tooltip title={value} placement="top-end">
            <DefaultCell
              value={
                value && value.length > 40 ? value.slice(0, 40) + "..." : value
              }
            />
            <IconButton
              sx={{ opacity: "0.4", fontSize: "1.2rem" }}
              pb={2}
            ></IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      Header: "Owner",
      accessor: "created_by",
      width: "15%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      Cell: ({ value, column, row }) => (
        <TemplateActionBtnTr value={value} column={column} row={row} />
      ),
    },
  ],
  rows: [],
};

export default GlobalTemplatesDataTableData;
