import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import EditClientContactByClientContactIdFormErrorLi from "./EditClientContactByClientContactIdFormErrorLi";

const EditClientContactByClientContactIdFormErrorsBlock = (props) => {
  const renderErrors = () =>
    props.objEditClientContactByClientContactIdFormErrors
      ? Object.entries(
          props.objEditClientContactByClientContactIdFormErrors
        ).map(([key, value], i) => (
          <EditClientContactByClientContactIdFormErrorLi
            key={key}
            value={value}
          />
        ))
      : null;
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity="error">
        {renderErrors(props.objEditClientContactByClientContactIdFormErrors)}
      </Alert>
    </Stack>
  );
};

export default EditClientContactByClientContactIdFormErrorsBlock;
