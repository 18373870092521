import { lineHeight } from "@mui/system";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// @mui icons
import { useLocation, useNavigate } from "react-router-dom";
const PolicyNameBtnTr = (props) => {
  const { value, row } = props;

  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const btnOnClickClientName = (objClientData) => {
    routeDictionary[objClientData.element.id] = value;
    navigate(`/policies/${objClientData.element.id}`, {
      state: { routeDictionary },
    });
  };

  return (
    <MDButton
      variant="text"
      onClick={() => btnOnClickClientName(row.original)}
      sx={{
        whiteSpace: "normal",
        wordWrap: "break-word",
        maxWidth: "200px",
      }}
    >
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 1.5 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default PolicyNameBtnTr;
