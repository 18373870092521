import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { deployPoliciesToClients } from "features/company/policiesActions";

function DeployPolicyDialog({
  objUser,
  open,
  onClose,
  clientDocumentsToTransfer,
  mspWideDocumentsToTransfer,
  onSubmit,
  deployTo,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [currentClient, setCurrentClient] = useState({
    id: "",
    name: "",
    status: "",
    city: "",
    state: "",
    status_label: "",
  });

  const proceedToDeployPolicies = () => {
    const objPostData = {
      documents:
        deployTo == "client"
          ? clientDocumentsToTransfer
          : mspWideDocumentsToTransfer,
      client_id: deployTo == "client" ? currentClient.id : null,
    };
    setIsLoading(true);
    dispatch(deployPoliciesToClients({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        showMsg(
          "success",
          `Policies deployed to ${
            deployTo == "client" ? currentClient.name + " " : "all Clients "
          } successfully!`
        );
        onClose();
        setIsLoading(false);
        onSubmit();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  useEffect(() => {
    if (objUser.user.role.name == "client_user") {
      setCurrentClient(objUser.user.list_of_clients);
    } else {
      let current_client = objUser.user.list_of_clients.find((item) => {
        return item.id === objUser.user.current_client_id;
      });
      setCurrentClient(current_client);
    }
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>
        Deploy {mspWideDocumentsToTransfer.length > 1 ? "Policies" : "Policy"}{" "}
        to {deployTo == "client" ? currentClient.name + " " : "all Clients "}
      </DialogTitle>
      <DialogContent>
        <MDTypography variant="body2" color="secondary" mt={1}>
          {deployTo == "client"
            ? `Deploying
          ${
            clientDocumentsToTransfer.length > 1
              ? "these policies"
              : "this policy"
          } 
          will automatically import 
          ${clientDocumentsToTransfer.length > 1 ? "them" : "it"} into the "Our
          Policies" section of 
          ${currentClient.name}.`
            : `Deploying 
          ${
            mspWideDocumentsToTransfer.length > 1
              ? "these policies"
              : "this policy"
          } 
          will automatically import 
          ${mspWideDocumentsToTransfer.length > 1 ? "them" : "it"} into the "Our
          Policies" section ofall your Clients.`}
        </MDTypography>
      </DialogContent>
      <DialogActions>
        {isLoading === false ? (
          <MDButton
            onClick={proceedToDeployPolicies}
            // variant="gradient"
            color="success"
            sx={{ padding: "12px 12px" }}
          >
            Proceed
          </MDButton>
        ) : (
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
        )}
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default DeployPolicyDialog;
