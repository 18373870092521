// @mui material components
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import { TextField } from "@mui/material";

const InfoFormFormikWrapper = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    objStates,
    objIndustries,
    objAggregates,
    roles,
    roleFormFiller,
    setRoleFormFiller,
    MFAchecklist,
    setMFAchecklist,
    flag_excludedactivities,
    setflag_excludedactivities,
    selectedFiles,
    setSelectedFiles,
  } = props;

  const handleSelectMFA = (event, value) => {
    if (event.target.checked) {
      setMFAchecklist([...MFAchecklist, value]);
    } else {
      setMFAchecklist(MFAchecklist.filter((MFA) => MFA !== value));
    }
  };

  const handleSelectActivities = (event, value) => {
    if (event.target.checked) {
      setflag_excludedactivities([...flag_excludedactivities, value]);
    } else {
      setflag_excludedactivities(
        flag_excludedactivities.filter((activity) => activity !== value)
      );
    }
  };

  const setNewFiles = (files) => {
    let currentFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (selectedFiles.some((file) => file.name === files[i].name)) {
      } else {
        currentFiles.push(files[i]);
      }
    }
    setSelectedFiles([...selectedFiles, ...currentFiles]);
  };

  const removeFile = (value) => {
    setSelectedFiles(selectedFiles.filter((file) => file !== value));
  };

  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography px={5} variant="h4" fontWeight="regular" my={3}>
          With this cyber insurance intake form, you can determine applicant
          eligibility across 40+ cyber carriers with 99% accuracy.
        </MDTypography>
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h5"
          fontWeight="bold"
        >
          General Information
        </MDTypography>
        <MDBox>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            p={5}
            pt={2}
          >
            <Grid item xs={12} lg={12}>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Multi-Factor Authentication
                  </MDTypography>
                  <label htmlFor={"gtn_mfa_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"gtn_mfa"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("gtn_mfa", "Yes");
                        }}
                        id={"gtn_mfa_Yes"}
                        checked={values.gtn_mfa === "Yes"}
                        error={errors.gtn_mfa && touched.gtn_mfa}
                        success={values.gtn_mfa.length > 0 && !errors.gtn_mfa}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"gtn_mfa_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"gtn_mfa"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("gtn_mfa", "No");
                        }}
                        id={"gtn_mfa_No"}
                        checked={values.gtn_mfa === "No"}
                        error={errors.gtn_mfa && touched.gtn_mfa}
                        success={values.gtn_mfa.length > 0 && !errors.gtn_mfa}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                {values.gtn_mfa == "Yes" && (
                  <Grid item xs={6} lg={6}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Multi-Factor Authentication Vendor and Tool
                    </MDTypography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Vendor"
                          name="gtn_mfa_vendor"
                          value={values.gtn_mfa_vendor ?? ""}
                          placeholder={"Vendor"}
                          error={
                            errors.gtn_mfa_vendor && touched.gtn_mfa_vendor
                          }
                          success={
                            values.gtn_mfa_vendor.length > 0 &&
                            !errors.gtn_mfa_vendor
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Tool"
                          name="gtn_mfa_tool"
                          value={values.gtn_mfa_tool ?? ""}
                          placeholder={"Tool"}
                          error={errors.gtn_mfa_tool && touched.gtn_mfa_tool}
                          success={
                            values.gtn_mfa_tool?.length > 0 &&
                            !errors.gtn_mfa_tool
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Security Awareness Training & Testing
                  </MDTypography>
                  <label htmlFor={"gtn_security_awareness_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"gtn_security_awareness"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("gtn_security_awareness", "Yes");
                        }}
                        id={"gtn_security_awareness_Yes"}
                        checked={values.gtn_security_awareness === "Yes"}
                        error={
                          errors.gtn_security_awareness &&
                          touched.gtn_security_awareness
                        }
                        success={
                          values.gtn_security_awareness.length > 0 &&
                          !errors.gtn_security_awareness
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"gtn_security_awareness_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"gtn_security_awareness"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("gtn_security_awareness", "No");
                        }}
                        id={"gtn_security_awareness_No"}
                        checked={values.gtn_security_awareness === "No"}
                        error={
                          errors.gtn_security_awareness &&
                          touched.gtn_security_awareness
                        }
                        success={
                          values.gtn_security_awareness.length > 0 &&
                          !errors.gtn_security_awareness
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                {values.gtn_security_awareness === "Yes" && (
                  <Grid item xs={6} lg={6}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Security Awareness Training & Testing Vendor and Tool
                    </MDTypography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Vendor"
                          name="gtn_security_awareness_vendor"
                          value={values.gtn_security_awareness_vendor ?? ""}
                          placeholder={"Vendor"}
                          error={
                            errors.gtn_security_awareness_vendor &&
                            touched.gtn_security_awareness_vendor
                          }
                          success={
                            values.gtn_security_awareness_vendor.length > 0 &&
                            !errors.gtn_security_awareness_vendor
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Tool"
                          name="gtn_security_awareness_tool"
                          value={values.gtn_security_awareness_tool ?? ""}
                          placeholder={"Tool"}
                          error={
                            errors.gtn_security_awareness_tool &&
                            touched.gtn_security_awareness_tool
                          }
                          success={
                            values.gtn_security_awareness_tool?.length > 0 &&
                            !errors.gtn_security_awareness_tool
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Separate Backups
                  </MDTypography>
                  <label htmlFor={"gtn_seperate_backup_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"gtn_seperate_backup"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("gtn_seperate_backup", "Yes");
                        }}
                        id={"gtn_seperate_backup_Yes"}
                        checked={values.gtn_seperate_backup === "Yes"}
                        error={
                          errors.gtn_seperate_backup &&
                          touched.gtn_seperate_backup
                        }
                        success={
                          values.gtn_seperate_backup.length > 0 &&
                          !errors.gtn_seperate_backup
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"gtn_seperate_backup_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"gtn_seperate_backup"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("gtn_seperate_backup", "No");
                        }}
                        id={"gtn_seperate_backup_No"}
                        checked={values.gtn_seperate_backup === "No"}
                        error={
                          errors.gtn_seperate_backup &&
                          touched.gtn_seperate_backup
                        }
                        success={
                          values.gtn_seperate_backup.length > 0 &&
                          !errors.gtn_seperate_backup
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                {values.gtn_seperate_backup === "Yes" && (
                  <Grid item xs={6} lg={6}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Separate Backups Vendor and Tool
                    </MDTypography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Vendor"
                          name="gtn_seperate_backup_vendor"
                          value={values.gtn_seperate_backup_vendor ?? ""}
                          placeholder={"Vendor"}
                          error={
                            errors.gtn_seperate_backup_vendor &&
                            touched.gtn_seperate_backup_vendor
                          }
                          success={
                            values.gtn_seperate_backup_vendor.length > 0 &&
                            !errors.gtn_seperate_backup_vendor
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Tool"
                          name="gtn_seperate_backup_tool"
                          value={values.gtn_seperate_backup_tool ?? ""}
                          placeholder={"Tool"}
                          error={
                            errors.gtn_seperate_backup_tool &&
                            touched.gtn_seperate_backup_tool
                          }
                          success={
                            values.gtn_seperate_backup_tool?.length > 0 &&
                            !errors.gtn_seperate_backup_tool
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Endpoint Detection & Response/Managed Detection & Response
                  </MDTypography>
                  <label htmlFor={"gtn_endpoint_managed_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"gtn_endpoint_managed"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("gtn_endpoint_managed", "Yes");
                        }}
                        id={"gtn_endpoint_managed_Yes"}
                        checked={values.gtn_endpoint_managed === "Yes"}
                        error={
                          errors.gtn_endpoint_managed &&
                          touched.gtn_endpoint_managed
                        }
                        success={
                          values.gtn_endpoint_managed.length > 0 &&
                          !errors.gtn_endpoint_managed
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"gtn_endpoint_managed_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"gtn_endpoint_managed"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("gtn_endpoint_managed", "No");
                        }}
                        id={"gtn_endpoint_managed_No"}
                        checked={values.gtn_endpoint_managed === "No"}
                        error={
                          errors.gtn_endpoint_managed &&
                          touched.gtn_endpoint_managed
                        }
                        success={
                          values.gtn_endpoint_managed.length > 0 &&
                          !errors.gtn_endpoint_managed
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                {values.gtn_endpoint_managed === "Yes" && (
                  <Grid item xs={6} lg={6}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Endpoint Detection & Response/Managed Detection & Response
                      Vendor and Tool
                    </MDTypography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Vendor"
                          name="gtn_endpoint_managed_vendor"
                          value={values.gtn_endpoint_managed_vendor ?? ""}
                          placeholder={"Vendor"}
                          error={
                            errors.gtn_endpoint_managed_vendor &&
                            touched.gtn_endpoint_managed_vendor
                          }
                          success={
                            values.gtn_endpoint_managed_vendor.length > 0 &&
                            !errors.gtn_endpoint_managed_vendor
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Tool"
                          name="gtn_endpoint_managed_tool"
                          value={values.gtn_endpoint_managed_tool ?? ""}
                          placeholder={"Tool"}
                          error={
                            errors.gtn_endpoint_managed_tool &&
                            touched.gtn_endpoint_managed_tool
                          }
                          success={
                            values.gtn_endpoint_managed_tool?.length > 0 &&
                            !errors.gtn_endpoint_managed_tool
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Vulnerability Management
                  </MDTypography>
                  <label htmlFor={"gtn_vulnerability_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"gtn_vulnerability"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("gtn_vulnerability", "Yes");
                        }}
                        id={"gtn_vulnerability_Yes"}
                        checked={values.gtn_vulnerability === "Yes"}
                        error={
                          errors.gtn_vulnerability && touched.gtn_vulnerability
                        }
                        success={
                          values.gtn_vulnerability.length > 0 &&
                          !errors.gtn_vulnerability
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"gtn_vulnerability_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"gtn_vulnerability"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("gtn_vulnerability", "No");
                        }}
                        id={"gtn_vulnerability_No"}
                        checked={values.gtn_vulnerability === "No"}
                        error={
                          errors.gtn_vulnerability && touched.gtn_vulnerability
                        }
                        success={
                          values.gtn_vulnerability.length > 0 &&
                          !errors.gtn_vulnerability
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                {values.gtn_vulnerability === "Yes" && (
                  <Grid item xs={6} lg={6}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Vulnerability Management Vendor and Tool
                    </MDTypography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Vendor"
                          name="gtn_vulnerability_vendor"
                          value={values.gtn_vulnerability_vendor ?? ""}
                          placeholder={"Vendor"}
                          error={
                            errors.gtn_vulnerability_vendor &&
                            touched.gtn_vulnerability_vendor
                          }
                          success={
                            values.gtn_vulnerability_vendor.length > 0 &&
                            !errors.gtn_vulnerability_vendor
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type="text"
                          label="Tool"
                          name="gtn_vulnerability_tool"
                          value={values.gtn_vulnerability_tool ?? ""}
                          placeholder={"Tool"}
                          error={
                            errors.gtn_vulnerability_tool &&
                            touched.gtn_vulnerability_tool
                          }
                          success={
                            values.gtn_vulnerability_tool?.length > 0 &&
                            !errors.gtn_vulnerability_tool
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Which best describes the role of the person completing this
                form?
              </MDTypography>
              {roles.map((role) => (
                <label htmlFor={role} key={role}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="1rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={role}
                      name={"role_formfiller"}
                      inputProps={{ "aria-label": role }}
                      onChange={() => {
                        setFieldValue("role_formfiller", role);
                        setRoleFormFiller(role);
                      }}
                      id={role}
                      checked={values.role_formfiller === role}
                      error={errors.role_formfiller && touched.role_formfiller}
                      success={
                        values.role_formfiller.length > 0 &&
                        !errors.role_formfiller
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {role}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              ))}
              {errors.role_formfiller && touched.role_formfiller ? (
                <FormHelperText error>{errors.role_formfiller}</FormHelperText>
              ) : null}
            </Grid>
            {(roleFormFiller === "Internal IT" ||
              roleFormFiller ===
                "An applicant looking for a policy through FifthWall Soltution") && (
              <Grid item xs={12} lg={12}>
                <MDTypography variant="h6" fontWeight="regular">
                  How did you hear about FifthWall Solutions?
                </MDTypography>
                <label htmlFor={"howDid_MSP"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"Through my MSP"}
                      name={"howDid"}
                      inputProps={{ "aria-label": "MSP" }}
                      onChange={() => {
                        setFieldValue("howDid", "Through my MSP");
                      }}
                      id={"howDid_MSP"}
                      checked={values.howDid === "Through my MSP"}
                      error={errors.howDid && touched.howDid}
                      success={values.howDid.length > 0 && !errors.howDid}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Through my MSP
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                <label htmlFor={"howDid_agent"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"Through my agent"}
                      name={"howDid"}
                      inputProps={{ "aria-label": "agent" }}
                      onChange={() => {
                        setFieldValue("howDid", "Through my agent");
                      }}
                      id={"howDid_agent"}
                      checked={values.howDid === "Through my agent"}
                      error={errors.howDid && touched.howDid}
                      success={values.howDid.length > 0 && !errors.howDid}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Through my agent
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                <label htmlFor={"howDid_own"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"I came across FifthWall for my own insurance"}
                      name={"howDid"}
                      inputProps={{ "aria-label": "own" }}
                      onChange={() => {
                        setFieldValue(
                          "howDid",
                          "I came across FifthWall for my own insurance"
                        );
                      }}
                      id={"howDid_own"}
                      checked={
                        values.howDid ===
                        "I came across FifthWall for my own insurance"
                      }
                      error={errors.howDid && touched.howDid}
                      success={values.howDid.length > 0 && !errors.howDid}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        I came across FifthWall for my own insurance
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                {errors.howDid && touched.howDid ? (
                  <FormHelperText error>{errors.howDid}</FormHelperText>
                ) : null}
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormField
                    type="text"
                    label="Applicant Entity Name"
                    name="namedinsured"
                    value={values.namedinsured ?? ""}
                    placeholder={"Applicant Entity Name"}
                    error={errors.namedinsured && touched.namedinsured}
                    success={
                      values.namedinsured.length > 0 && !errors.namedinsured
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormField
                        type="text"
                        label="Applicant Contact First Name"
                        name="contactfirstname"
                        value={values.contactfirstname ?? ""}
                        placeholder={"Applicant Contact First Name"}
                        error={
                          errors.contactfirstname && touched.contactfirstname
                        }
                        success={
                          values.contactfirstname?.length > 0 &&
                          !errors.contactfirstname
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormField
                        type="text"
                        label="Applicant Contact Last Name"
                        name="contactlastname"
                        value={values.contactlastname ?? ""}
                        placeholder={"Applicant Contact Last Name"}
                        error={
                          errors.contactlastname && touched.contactlastname
                        }
                        success={
                          values.contactlastname?.length > 0 &&
                          !errors.contactlastname
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                {roleFormFiller !== "MSP or MSSP" && (
                  <Grid item xs={6}>
                    <FormField
                      type="text"
                      label="Applicant Title"
                      name="contacttitle"
                      value={values.contacttitle ?? ""}
                      placeholder={"Applicant Title"}
                      error={errors.contacttitle && touched.contacttitle}
                      success={
                        values.contacttitle.length > 0 && !errors.contacttitle
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <FormField
                    type="text"
                    label="Applicant Contact Email"
                    name="contactemail"
                    value={values.contactemail ?? ""}
                    placeholder={"Applicant Contact Email"}
                    error={errors.contactemail && touched.contactemail}
                    success={
                      values.contactemail?.length > 0 && !errors.contactemail
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormField
                    type="tel"
                    label="Phone Number"
                    name="contactphone"
                    value={values.contactphone ?? ""}
                    placeholder={"Phone Number"}
                    error={errors.contactphone && touched.contactphone}
                    success={
                      values.contactphone.length > 0 && !errors.contactphone
                    }
                  />
                </Grid>
                {roleFormFiller !== "MSP or MSSP" && (
                  <Grid item xs={6}>
                    <FormField
                      type="number"
                      label="Extension"
                      name="extension"
                      value={values.extension ?? ""}
                      placeholder={"Extension"}
                      error={errors.extension && touched.extension}
                      success={
                        values.extension?.length > 0 && !errors.extension
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {roleFormFiller !== "MSP or MSSP" && (
              <Grid item xs={12} lg={12}>
                <MDTypography variant="h6" fontWeight="regular">
                  Does the applicant own or control any subsidiary entities by
                  50% or more?
                </MDTypography>
                <label htmlFor={"flag_subsidiary_Yes"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"Yes"}
                      name={"flag_subsidiary"}
                      inputProps={{ "aria-label": "Yes" }}
                      onChange={() => {
                        setFieldValue("flag_subsidiary", "Yes");
                      }}
                      id={"flag_subsidiary_Yes"}
                      checked={values.flag_subsidiary === "Yes"}
                      error={errors.flag_subsidiary && touched.flag_subsidiary}
                      success={
                        values.flag_subsidiary.length > 0 &&
                        !errors.flag_subsidiary
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Yes
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                <label htmlFor={"flag_subsidiary_No"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"No"}
                      name={"flag_subsidiary"}
                      inputProps={{ "aria-label": "No" }}
                      onChange={() => {
                        setFieldValue("flag_subsidiary", "No");
                      }}
                      id={"flag_subsidiary_No"}
                      checked={values.flag_subsidiary === "No"}
                      error={errors.flag_subsidiary && touched.flag_subsidiary}
                      success={
                        values.flag_subsidiary.length > 0 &&
                        !errors.flag_subsidiary
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        No
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                <label htmlFor={"flag_subsidiary_I don't know"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"I don't know"}
                      name={"flag_subsidiary"}
                      inputProps={{ "aria-label": "I don't know" }}
                      onChange={() => {
                        setFieldValue("flag_subsidiary", "I don't know");
                      }}
                      id={"flag_subsidiary_I don't know"}
                      checked={values.flag_subsidiary === "I don't know"}
                      error={errors.flag_subsidiary && touched.flag_subsidiary}
                      success={
                        values.flag_subsidiary.length > 0 &&
                        !errors.flag_subsidiary
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        I don't know
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                {errors.flag_subsidiary && touched.flag_subsidiary ? (
                  <FormHelperText error>
                    {errors.flag_subsidiary}
                  </FormHelperText>
                ) : null}
              </Grid>
            )}
            {(values.flag_subsidiary === "Yes" ||
              roleFormFiller === "MSP or MSSP") && (
              <Grid item xs={12} lg={12}>
                <TextField
                  label="Subsidiary Entity(s) if applicable:"
                  fullWidth
                  multiline
                  minRows={8}
                  name="subsidiaryentity"
                  value={values.subsidiaryentity ?? ""}
                  placeholder={"Type here..."}
                  onChange={(e) => {
                    setFieldValue("subsidiaryentity", e.target.value);
                  }}
                  error={errors.subsidiaryentity && touched.subsidiaryentity}
                  success={
                    values.subsidiaryentity?.length > 0 &&
                    !errors.subsidiaryentity
                  }
                />
                <MDTypography variant="caption" fontWeight="regular">
                  Attach a list of all subsidiaries, affiliated companies or
                  entities owned by the Applicant and include a description of
                  (1) the nature of operations of each subsidiary, affiliated
                  company or entity, (2) its relationship to the Applicant and
                  (3) the percentage of ownership by the Applicant.
                </MDTypography>
              </Grid>
            )}
            {roleFormFiller !== "MSP or MSSP" && (
              <>
                <Grid item xs={12} lg={12}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Does the applicant have an active cyber policy in force?
                  </MDTypography>
                  <label htmlFor={"flag_currentcyberpolicy_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"flag_currentcyberpolicy"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("flag_currentcyberpolicy", "Yes");
                        }}
                        id={"flag_currentcyberpolicy_Yes"}
                        checked={values.flag_currentcyberpolicy === "Yes"}
                        error={
                          errors.flag_currentcyberpolicy &&
                          touched.flag_currentcyberpolicy
                        }
                        success={
                          values.flag_currentcyberpolicy.length > 0 &&
                          !errors.flag_currentcyberpolicy
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"flag_currentcyberpolicy_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"flag_currentcyberpolicy"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("flag_currentcyberpolicy", "No");
                        }}
                        id={"flag_currentcyberpolicy_No"}
                        checked={values.flag_currentcyberpolicy === "No"}
                        error={
                          errors.flag_currentcyberpolicy &&
                          touched.flag_currentcyberpolicy
                        }
                        success={
                          values.flag_currentcyberpolicy.length > 0 &&
                          !errors.flag_currentcyberpolicy
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <MDTypography variant="h6" fontWeight="regular" mb={2}>
                    Please upload policy if available:
                  </MDTypography>
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .csv, .txt, .rtf, .html, .zip, .mp3, .wma, .mpg, .flv, .avi, .jpg, .jpeg, .png, .gif"
                    multiple
                    onChange={(event) => {
                      if (event.target.files.length > 0) {
                        setNewFiles(Array.from(event.target.files));
                      }
                    }}
                  />
                  {selectedFiles.map((file, index) => (
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      my={1}
                      key={index}
                    >
                      <Tooltip title="Remove this file." placement="bottom">
                        <MDButton
                          variant="gradient"
                          color="error"
                          iconOnly={true}
                          size="small"
                          onClick={() => removeFile(file)}
                        >
                          <Icon>delete</Icon>
                        </MDButton>
                      </Tooltip>
                      <MDTypography
                        variant="caption"
                        fontWeight="regular"
                        color={"text"}
                        sx={{
                          textDecoration: "none",
                          marginLeft: "10px",
                        }}
                      >
                        {file.name}
                      </MDTypography>
                    </MDBox>
                  ))}
                </Grid>
                <Grid item xs={12} lg={12}>
                  <label htmlFor={"entitycountry"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        name={"entitycountry"}
                        inputProps={{ "aria-label": "entitycountry" }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFieldValue("entitycountry", "Yes");
                          } else {
                            setFieldValue("entitycountry", "No");
                          }
                        }}
                        id={"entitycountry"}
                        checked={values.entitycountry === "Yes"}
                        error={errors.entitycountry && touched.entitycountry}
                        success={
                          values.entitycountry.length > 0 &&
                          !errors.entitycountry
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="h6" fontWeight="regular">
                          Applicant is domiciled in the USA
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
              </>
            )}
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormField
                    type="text"
                    label="Entity Location Street Address"
                    name="entityaddress"
                    value={values.entityaddress ?? ""}
                    placeholder={"Entity Location Street Address"}
                    error={errors.entityaddress && touched.entityaddress}
                    success={
                      values.entityaddress.length > 0 && !errors.entityaddress
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormField
                    type="text"
                    label="Entity City"
                    name="entitycity"
                    value={values.entitycity ?? ""}
                    placeholder={"Entity City"}
                    error={errors.entitycity && touched.entitycity}
                    success={
                      values.entitycity?.length > 0 && !errors.entitycity
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Autocomplete
                    options={objStates}
                    name="entitystate"
                    value={
                      objStates.find(
                        (objstate) => objstate.id === values.entitystate
                      ) || null
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Entity State"
                      />
                    )}
                    onChange={(event, value) => {
                      if (value === null) {
                        setFieldValue("entitystate", "");
                      } else {
                        setFieldValue("entitystate", value.id);
                      }
                    }}
                    getOptionLabel={(option) => (option ? option.id : "")}
                  />
                  {errors.entitystate && touched.entitystate ? (
                    <FormHelperText error>{errors.entitystate}</FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <FormField
                    type="text"
                    label="Entity Zip Code"
                    name="entityzipcode"
                    value={values.entityzipcode ?? ""}
                    placeholder={"Entity Zip Code"}
                    error={errors.entityzipcode && touched.entityzipcode}
                    success={
                      values.entityzipcode?.length > 0 && !errors.entityzipcode
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormField
                    type="text"
                    label="Primary Entity Website"
                    name="entitywebsite"
                    value={values.entitywebsite ?? ""}
                    placeholder={"Primary Entity Website"}
                    error={errors.entitywebsite && touched.entitywebsite}
                    success={
                      values.entitywebsite.length > 0 && !errors.entitywebsite
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Autocomplete
                    options={objIndustries}
                    name="industry"
                    value={
                      objIndustries.find(
                        (industry) => industry.label === values.industry
                      ) || null
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Industry/Sector"
                      />
                    )}
                    onChange={(event, value) => {
                      if (value === null) {
                        setFieldValue("industry", "");
                      } else {
                        setFieldValue("industry", value.label);
                      }
                    }}
                    getOptionLabel={(option) => (option ? option.label : "")}
                  />
                  {errors.industry && touched.industry ? (
                    <FormHelperText error>{errors.industry}</FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <FormField
                    type="text"
                    label="Industry NAICS Code"
                    name="naicscode"
                    value={values.naicscode ?? ""}
                    placeholder={"Industry NAICS Code"}
                    error={errors.naicscode && touched.naicscode}
                    success={values.naicscode.length > 0 && !errors.naicscode}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular" my={2}>
                Do any of the applicant's operations include the following?
                (select all that apply)
              </MDTypography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <label htmlFor={"flag_excludedactivities1"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"Adult Content"}
                        name={"flag_excludedactivities"}
                        inputProps={{ "aria-label": "Adult Content" }}
                        onChange={(event) =>
                          handleSelectActivities(event, event.target.value)
                        }
                        id={"flag_excludedactivities1"}
                        checked={flag_excludedactivities.includes(
                          "Adult Content"
                        )}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Adult Content
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"flag_excludedactivities2"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"Cannabis"}
                        name={"flag_excludedactivities"}
                        inputProps={{ "aria-label": "Cannabis" }}
                        onChange={(event) =>
                          handleSelectActivities(event, event.target.value)
                        }
                        id={"flag_excludedactivities2"}
                        checked={flag_excludedactivities.includes("Cannabis")}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Cannabis
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"flag_excludedactivities3"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"Cryptocurrency or Blockchain"}
                        name={"flag_excludedactivities"}
                        inputProps={{
                          "aria-label": "Cryptocurrency or Blockchain",
                        }}
                        onChange={(event) =>
                          handleSelectActivities(event, event.target.value)
                        }
                        id={"flag_excludedactivities3"}
                        checked={flag_excludedactivities.includes(
                          "Cryptocurrency or Blockchain"
                        )}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Cryptocurrency or Blockchain
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"flag_excludedactivities4"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"Gambling"}
                        name={"flag_excludedactivities"}
                        inputProps={{ "aria-label": "Gambling" }}
                        onChange={(event) =>
                          handleSelectActivities(event, event.target.value)
                        }
                        id={"flag_excludedactivities4"}
                        checked={flag_excludedactivities.includes("Gambling")}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Gambling
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"flag_excludedactivities5"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"Debt Collection Agency"}
                        name={"flag_excludedactivities"}
                        inputProps={{ "aria-label": "Debt Collection Agency" }}
                        onChange={(event) =>
                          handleSelectActivities(event, event.target.value)
                        }
                        id={"flag_excludedactivities5"}
                        checked={flag_excludedactivities.includes(
                          "Debt Collection Agency"
                        )}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Debt Collection Agency
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"flag_excludedactivities6"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"Managed IT Services Provider (MSP or MSSP)"}
                        name={"flag_excludedactivities"}
                        inputProps={{
                          "aria-label":
                            "Managed IT Services Provider (MSP or MSSP)",
                        }}
                        onChange={(event) =>
                          handleSelectActivities(event, event.target.value)
                        }
                        id={"flag_excludedactivities6"}
                        checked={flag_excludedactivities.includes(
                          "Managed IT Services Provider (MSP or MSSP)"
                        )}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Managed IT Services Provider (MSP or MSSP)
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"flag_excludedactivities7"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={
                          "Payment Processing (e.g. as a payment processor, merchant, acquirer, or point of sale system vendor)"
                        }
                        name={"flag_excludedactivities"}
                        inputProps={{
                          "aria-label":
                            "Payment Processing (e.g. as a payment processor, merchant, acquirer, or point of sale system vendor)",
                        }}
                        onChange={(event) =>
                          handleSelectActivities(event, event.target.value)
                        }
                        id={"flag_excludedactivities7"}
                        checked={flag_excludedactivities.includes(
                          "Payment Processing (e.g. as a payment processor, merchant, acquirer, or point of sale system vendor)"
                        )}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Payment Processing (e.g. as a payment processor,
                          merchant, acquirer, or point of sale system vendor)
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"flag_excludedactivities8"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"None of these apply to the applicant"}
                        name={"flag_excludedactivities"}
                        inputProps={{
                          "aria-label": "None of these apply to the applicant",
                        }}
                        onChange={(event) =>
                          handleSelectActivities(event, event.target.value)
                        }
                        id={"flag_excludedactivities8"}
                        checked={flag_excludedactivities.includes(
                          "None of these apply to the applicant"
                        )}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          None of these apply to the applicant
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                {flag_excludedactivities.length == 0 ? (
                  <FormHelperText error>This Field is required</FormHelperText>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Is the applicant a franchisee or franchisor?
              </MDTypography>
              <label htmlFor={"flag_franchise_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_franchise"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_franchise", "Yes");
                    }}
                    id={"flag_franchise_Yes"}
                    checked={values.flag_franchise === "Yes"}
                    error={errors.flag_franchise && touched.flag_franchise}
                    success={
                      values.flag_franchise.length > 0 && !errors.flag_franchise
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_franchise_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_franchise"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_franchise", "No");
                    }}
                    id={"flag_franchise_No"}
                    checked={values.flag_franchise === "No"}
                    error={errors.flag_franchise && touched.flag_franchise}
                    success={
                      values.flag_franchise.length > 0 && !errors.flag_franchise
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_franchise && touched.flag_franchise ? (
                <FormHelperText error>{errors.flag_franchise}</FormHelperText>
              ) : null}
            </Grid>
            {roleFormFiller !== "MSP or MSSP" && (
              <>
                <Grid item xs={12} lg={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormField
                        type="number"
                        label="Year Established"
                        name="entityestablishyear"
                        value={values.entityestablishyear ?? ""}
                        placeholder={"Year Established"}
                        error={
                          errors.entityestablishyear &&
                          touched.entityestablishyear
                        }
                        success={
                          values.entityestablishyear.length > 0 &&
                          !errors.entityestablishyear
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormField
                        type="number"
                        label="Number of Employees"
                        name="numberemployees"
                        value={values.numberemployees ?? ""}
                        placeholder={"Number of Employees"}
                        error={
                          errors.numberemployees && touched.numberemployees
                        }
                        success={
                          values.numberemployees.length > 0 &&
                          !errors.numberemployees
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormField
                        type="number"
                        label="Revenue - Prior Fiscal Year"
                        name="revenue_priorFY"
                        value={values.revenue_priorFY ?? ""}
                        placeholder={"Revenue - Prior Fiscal Year"}
                        error={
                          errors.revenue_priorFY && touched.revenue_priorFY
                        }
                        success={
                          values.revenue_priorFY?.length > 0 &&
                          !errors.revenue_priorFY
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                {roleFormFiller !== "MSP or MSSP" && (
                  <Grid item xs={6}>
                    <FormField
                      type="number"
                      label="Revenue - Est. Current Fiscal Year"
                      name="revenue_estnextFY"
                      value={values.revenue_estnextFY ?? ""}
                      placeholder={"Revenue - Est. Current Fiscal Year"}
                      error={
                        errors.revenue_estnextFY && touched.revenue_estnextFY
                      }
                      success={
                        values.revenue_estnextFY.length > 0 &&
                        !errors.revenue_estnextFY
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <FormField
                    type="number"
                    label="Percentage Domestic Sales"
                    name="percentagedomesticsales"
                    value={values.percentagedomesticsales ?? ""}
                    placeholder={"Percentage Domestic Sales"}
                    error={
                      errors.percentagedomesticsales &&
                      touched.percentagedomesticsales
                    }
                    success={
                      values.percentagedomesticsales?.length > 0 &&
                      !errors.percentagedomesticsales
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Have any of the applicants principals, partners, directors, risk
                managers, or employees during the last three (3) years,
                sustained any loss, filed a claim, or had any claim made against
                them, whether insured or not, involving the type of insurance(s)
                being requested in this application?
              </MDTypography>
              <label htmlFor={"flag_losslast3years_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_losslast3years"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_losslast3years", "Yes");
                    }}
                    id={"flag_losslast3years_Yes"}
                    checked={values.flag_losslast3years === "Yes"}
                    error={
                      errors.flag_losslast3years && touched.flag_losslast3years
                    }
                    success={
                      values.flag_losslast3years.length > 0 &&
                      !errors.flag_losslast3years
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_losslast3years_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_losslast3years"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_losslast3years", "No");
                    }}
                    id={"flag_losslast3years_No"}
                    checked={values.flag_losslast3years === "No"}
                    error={
                      errors.flag_losslast3years && touched.flag_losslast3years
                    }
                    success={
                      values.flag_losslast3years.length > 0 &&
                      !errors.flag_losslast3years
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_losslast3years_I don't know"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"I don't know"}
                    name={"flag_losslast3years"}
                    inputProps={{ "aria-label": "I don't know" }}
                    onChange={() => {
                      setFieldValue("flag_losslast3years", "I don't know");
                    }}
                    id={"flag_losslast3years_I don't know"}
                    checked={values.flag_losslast3years === "I don't know"}
                    error={
                      errors.flag_losslast3years && touched.flag_losslast3years
                    }
                    success={
                      values.flag_losslast3years.length > 0 &&
                      !errors.flag_losslast3years
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      I don't know
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_losslast3years && touched.flag_losslast3years ? (
                <FormHelperText error>
                  {errors.flag_losslast3years}
                </FormHelperText>
              ) : null}
            </Grid>
            {values.flag_losslast3years === "Yes" && (
              <Grid item xs={12} lg={12}>
                <MDTypography variant="h6" fontWeight="regular" my={2}>
                  If yes, what is or was the expected total financial impact to
                  the applicant and its insurer?
                </MDTypography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormField
                      type="number"
                      label=""
                      name="estlossimpact"
                      value={values.estlossimpact ?? ""}
                      placeholder={""}
                      error={errors.estlossimpact && touched.estlossimpact}
                      success={
                        values.estlossimpact.length > 0 && !errors.estlossimpact
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular" my={2}>
                Please select all applications where the Applicant has MFA
                (multi factor authentication) enabled:
              </MDTypography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <label htmlFor={"MFAchecklist1"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"Email Access (i.e Office 365 or G-Suite)"}
                        name={"MFAchecklist"}
                        inputProps={{
                          "aria-label":
                            "Email Access (i.e Office 365 or G-Suite)",
                        }}
                        onChange={(event) =>
                          handleSelectMFA(event, event.target.value)
                        }
                        id={"MFAchecklist1"}
                        checked={MFAchecklist.includes(
                          "Email Access (i.e Office 365 or G-Suite)"
                        )}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Email Access (i.e Office 365 or G-Suite)
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"MFAchecklist2"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"Remote Network Access"}
                        name={"MFAchecklist"}
                        inputProps={{ "aria-label": "Remote Network Access" }}
                        onChange={(event) =>
                          handleSelectMFA(event, event.target.value)
                        }
                        id={"MFAchecklist2"}
                        checked={MFAchecklist.includes("Remote Network Access")}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Remote Network Access
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"MFAchecklist3"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"Privileged Users"}
                        name={"MFAchecklist"}
                        inputProps={{ "aria-label": "Privileged Users" }}
                        onChange={(event) =>
                          handleSelectMFA(event, event.target.value)
                        }
                        id={"MFAchecklist3"}
                        checked={MFAchecklist.includes("Privileged Users")}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Privileged Users
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"MFAchecklist4"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"Cloud-Based and 3rd-Party Applications"}
                        name={"MFAchecklist"}
                        inputProps={{
                          "aria-label":
                            "Cloud-Based and 3rd-Party Applications",
                        }}
                        onChange={(event) =>
                          handleSelectMFA(event, event.target.value)
                        }
                        id={"MFAchecklist4"}
                        checked={MFAchecklist.includes(
                          "Cloud-Based and 3rd-Party Applications"
                        )}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Cloud-Based and 3rd-Party Applications
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"MFAchecklist5"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"Backup System Access"}
                        name={"MFAchecklist"}
                        inputProps={{ "aria-label": "Backup System Access" }}
                        onChange={(event) =>
                          handleSelectMFA(event, event.target.value)
                        }
                        id={"MFAchecklist5"}
                        checked={MFAchecklist.includes("Backup System Access")}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Backup System Access
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor={"MFAchecklist6"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        value={"I don't know"}
                        name={"MFAchecklist"}
                        inputProps={{ "aria-label": "I don't know" }}
                        onChange={(event) =>
                          handleSelectMFA(event, event.target.value)
                        }
                        id={"MFAchecklist6"}
                        checked={MFAchecklist.includes("I don't know")}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          I don't know
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>

                {MFAchecklist.length == 0 ? (
                  <FormHelperText error>This Field is required</FormHelperText>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                label="Additional Notes - MFA"
                fullWidth
                multiline
                minRows={8}
                name="notes_MFA"
                value={values.notes_MFA ?? ""}
                placeholder={"Type here..."}
                onChange={(e) => {
                  setFieldValue("notes_MFA", e.target.value);
                }}
                error={errors.notes_MFA && touched.notes_MFA}
                success={values.notes_MFA?.length > 0 && !errors.notes_MFA}
              />
              <MDTypography variant="caption" fontWeight="regular">
                Optional
              </MDTypography>
            </Grid>
            {roleFormFiller !== "MSP or MSSP" && (
              <>
                <Grid item xs={12} lg={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={objAggregates}
                        name="desiredAggregate"
                        value={
                          objAggregates.find(
                            (aggregate) =>
                              aggregate.label === values.desiredAggregate
                          ) || null
                        }
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label="Desired Aggregate Limit"
                          />
                        )}
                        onChange={(event, value) => {
                          if (value === null) {
                            setFieldValue("desiredAggregate", "");
                          } else {
                            setFieldValue("desiredAggregate", value.label);
                          }
                        }}
                        getOptionLabel={(option) =>
                          option ? option.label : ""
                        }
                      />
                      {errors.desiredAggregate && touched.desiredAggregate ? (
                        <FormHelperText error>
                          {errors.desiredAggregate}
                        </FormHelperText>
                      ) : null}
                      <MDTypography variant="caption" fontWeight="regular">
                        Most applicants require $1M in coverage.
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>
                {(values.desiredAggregate == "$2M" ||
                  values.desiredAggregate == "$3M" ||
                  values.desiredAggregate == "$5M" ||
                  values.desiredAggregate == "$10M") && (
                  <Grid item xs={12} lg={12}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Is the above requested limit due to a contract
                      requirement?
                    </MDTypography>
                    <label htmlFor={"isThe_Yes"}>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        gap="0.5rem"
                        sx={{ cursor: "pointer" }}
                      >
                        <Radio
                          value={"Yes"}
                          name={"isThe"}
                          inputProps={{ "aria-label": "Yes" }}
                          onChange={() => {
                            setFieldValue("isThe", "Yes");
                          }}
                          id={"isThe_Yes"}
                          checked={values.isThe === "Yes"}
                          error={errors.isThe && touched.isThe}
                          success={values.isThe.length > 0 && !errors.isThe}
                        />
                        <MDBox sx={{ flexGrow: 1 }}>
                          <MDTypography variant="body2" fontWeight="regular">
                            Yes
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </label>
                    <label htmlFor={"isThe_No"}>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        gap="0.5rem"
                        sx={{ cursor: "pointer" }}
                      >
                        <Radio
                          value={"No"}
                          name={"isThe"}
                          inputProps={{ "aria-label": "No" }}
                          onChange={() => {
                            setFieldValue("isThe", "No");
                          }}
                          id={"isThe_No"}
                          checked={values.isThe === "No"}
                          error={errors.isThe && touched.isThe}
                          success={values.isThe.length > 0 && !errors.isThe}
                        />
                        <MDBox sx={{ flexGrow: 1 }}>
                          <MDTypography variant="body2" fontWeight="regular">
                            No
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </label>
                    {errors.isThe && touched.isThe ? (
                      <FormHelperText error>{errors.isThe}</FormHelperText>
                    ) : null}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default InfoFormFormikWrapper;
