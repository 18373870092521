// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import { Fragment, useState } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
const RiskMatrixItemEditBtnTr = (props) => {
  const { objUser, openEditMatrixDialogHandler, itemId } = props;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Fragment>
      {!isLoading ? (
        <MDButton
          onClick={() => openEditMatrixDialogHandler(itemId)}
          variant="gradient"
          disabled={
            objUser.user.role.name != "company_user" &&
            objUser.user.role.name != "admin"
          }
          color="secondary"
        >
          <MDBox color="white" display="flex" alignItems="center">
            <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
            <MDTypography
              variant="p"
              fontWeight="medium"
              color="white"
              sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
            >
              Edit
            </MDTypography>
          </MDBox>
        </MDButton>
      ) : (
        <LoadingSpinner subClass="text-center" color="error" size="lg" />
      )}
    </Fragment>
  );
};

export default RiskMatrixItemEditBtnTr;
