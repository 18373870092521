import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import { useMemo } from "react";

const TemplateStatusTr = (props) => {
  const { value: created_at, row } = props;
  const status = useMemo(() => {
    const last_week = new Date();
    last_week.setDate(last_week.getDate() - 7);
    last_week.setHours(0, 0, 0, 0);
    return {
      isNew: created_at > last_week,
      isUpdated: row.original.last_modified_at_raw > last_week,
    };
  }, [row, created_at]);

  return (
    <>
      {status.isNew ? (
        <MDBadge badgeContent="New" size="sm" color="success" />
      ) : status.isUpdated ? (
        <MDBadge badgeContent="Updated" size="sm" color="success" />
      ) : (
        ""
      )}
    </>
  );
};

export default TemplateStatusTr;
