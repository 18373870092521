import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// formik components
import { Form, Formik } from "formik";
import * as Yup from "yup";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
} from "@mui/material";
import Icon from "@mui/material/Icon";

import { unwrapResult } from "@reduxjs/toolkit";
import {
  fetchPolicy,
  updatePolicy,
  newVersion,
  copyAsNewVersion,
} from "features/company/policiesActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { Grid } from "@mui/material";
import EditPolicyFormFormikWrapper from "./components/EditPolicyFormFormikWrapper";
import VersionsDatatable from "./components/VersionsDatatable";
import MDTypography from "components/MDTypography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMaterialUIController } from "context";

const _objInitialValues = {
  title: "",
  category: "",
  complianceRiskScore: "",
  referenceMaterialUrl: "",
  policyTags: "",
  shortDescription: "",
  notes: "",
  intendedAudience: "",
  relatedDocument: "",
  kbArticleLink: "",
  kbArticleData: "",
  latestDocumentState: "",
  knowledgeBase: "",
  includeInKnowledgebase: "",
  // documentTemplate: "",
  riskManagementFrameworks: "",
  timeReminder: "",
  timeReminderPeriod: "",
  numberOfDaysBeforeToSendReminder: 1,
  reminderEmails: "",
};

const EditPolicyFormJWTSchema = Yup.object().shape({
  title: Yup.string().required("Field is required."),
  category: Yup.object().shape({
    id: Yup.string().required(),
  }),
  owner: Yup.object().shape({
    id: Yup.string().required(),
  }),
  // documentTemplate: Yup.object().shape({
  //   id: Yup.string(),
  // }),
  complianceRiskScore: Yup.string(),
  referenceMaterialUrl: Yup.string(),
  policyTags: Yup.array().of(Yup.string()).required("Field is required."),
  shortDescription: Yup.string().required("Field is required"),
  notes: Yup.string(),
  intendedAudience: Yup.string(),
  relatedDocument: Yup.string(),
  //reminderEmails: Yup.array().of(Yup.string()).required("Field is required."),
  // kbArticleLink: Yup.string(),
  knowledgeBase: Yup.string(),
  // timeReminderPeriod: Yup.date()
  //   .min(new Date(), "Date must be in the future")
  //   .required("Field is required."),
  //.nullable(true),
  // documentTemplate: Yup.string().required("Field is required."),
  //riskManagementFrameworks: Yup.string().required("Field is required."),
});

const EditPolicy = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { policyId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(_objInitialValues);
  const [objPolicy, setObjPolicy] = useState({});
  const [documentVersions, setDocumentVersions] = useState([]);
  const formRef = useRef();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [lnkedPolicies, setLnkedPolicies] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    loadPolicy();
  }, []);

  const loadPolicy = async () => {
    setIsLoading(true);
    dispatch(fetchPolicy({ objUser, policyId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        try {
          if (response.status !== 200) {
            throw new Error("Get policy request failed.");
          }

          if (
            typeof response.data === "undefined" ||
            typeof response.data.id === "undefined"
          ) {
            throw new Error("Policy data is not valid");
          }
          setObjPolicy(response.data);

          let sorted_rmfs = response.data.extra.risk_management_frameworks;

          if (sorted_rmfs.length > 0) {
            sorted_rmfs = response.data.extra.risk_management_frameworks.sort(
              (a, b) => {
                const nameA = a.rmf.name.toLowerCase();
                const nameB = b.rmf.name.toLowerCase();

                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              }
            );
          }

          const _objInitialValues = {
            title: response.data.title,
            category: response.data.extra.categories.find(
              (el) => el.id === response.data.category_id
            ),
            owner: response.data.extra.contacts.find(
              (el) => el.id === response.data.owner
            ),
            // documentTemplate: response.data.extra.templates.find(
            //   (el) => el.id === response.data.document_template
            // ),
            policyCreator: response.data.created_by ?? "",
            complianceRiskScore: response.data.compliance_risk ?? "",
            referenceMaterialUrl: response.data.reference_material_url ?? "",
            policyTags: response.data.tags ?? [], // TODO
            shortDescription: response.data.description ?? "",
            notes: response.data.notes ?? "",
            intendedAudience: response.data.intended_audience ?? "",
            relatedDocument: response.data.related_document ?? "",
            kbArticleLink: response.data.kb_article_link ?? "",
            kbArticleData: response.data.extra.kb_article_data,
            latestDocumentState: response.data.latest_document
              ? response.data.latest_document.state
              : "Draft",
            knowledgeBase: response.data.knowledge_base ?? "",
            includeInKnowledgebase:
              response.data.include_in_knowledgebase === "include"
                ? true
                : false,
            timeReminder: response.data.time_reminder ?? "",
            timeReminderPeriod: response.data.time_reminder_period ?? "",
            reminderEmails: response.data.reminder_emails,
            numberOfDaysBeforeToSendReminder:
              response.data.number_of_days_before_to_send_reminder,
            //documentTemplate: response.data.document_template ?? "",
            // riskManagementFrameworks:
            //   response.data.extra.risk_management_frameworks, // TODO
            riskManagementFrameworks: sorted_rmfs,
            linkedPolicies: response.data.extra.linked_policies,
            publishedPolicies: response.data.extra.published_policies,
          };

          setDocumentVersions(response.data.extra.versions);
          setLnkedPolicies(
            response.data.extra.linked_policies.map((policy) => {
              return policy.linked_policy_id;
            })
          );

          setFormInitialValues(_objInitialValues);
          // if (typeof objPolicy.extra.versions !== "undefined") {
          //   setDocumentVersions(objPolicy.extra.versions);
          // }
        } catch (error) {
          console.log("FETCH POLICY ERROR", error);
          showMsg("error", "Something went wrong.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 404 &&
          objErrorRsp.data.status === "failed"
        ) {
          showMsg("error", "Invalid Url");
          navigate(`/policies/`, {
            state: { routeDictionary },
          });
        }
      });
  };

  const onSubmitForm = async (value) => {
    await saveForm(value);
    //navigate(`/clients/${clientId}/policies`, { state: { routeDictionary } });
  };

  const saveForm = (currentValues) => {
    try {
      EditPolicyFormJWTSchema.validateSync(currentValues);
    } catch (error) {
      console.log(error);
      return;
    }

    if (currentValues.timeReminder == "") {
      showMsg("info", "Please choose a review date");
      return false;
    }

    if (currentValues.reminderEmails.length == 0) {
      showMsg("info", "Please provide an email to be notified for review");
      return false;
    }

    setIsSaving(true);

    const objPostData = {
      title: currentValues.title,
      category_id: currentValues.category ? currentValues.category.id : 0,
      owner:
        currentValues.owner != "" && currentValues.owner != null
          ? currentValues.owner.id
          : null,
      // compliance_risk_score: currentValues.complianceRiskScore,
      compliance_risk_score: null,
      reference_material_url: currentValues.referenceMaterialUrl,
      description: currentValues.shortDescription,
      notes: currentValues.notes,
      intended_audience: currentValues.intendedAudience,
      related_document: currentValues.relatedDocument,
      kb_article_link: currentValues.kbArticleLink,
      knowledge_base: currentValues.knowledgeBase,
      include_in_knowledgebase:
        currentValues.includeInKnowledgebase === true
          ? "include"
          : "no_include",
      time_reminder: currentValues.timeReminder,
      time_reminder_period: currentValues.timeReminderPeriod,
      reminder_emails: currentValues.reminderEmails,
      number_of_days_before_to_send_reminder:
        currentValues.numberOfDaysBeforeToSendReminder,
      risk_management_frameworks: currentValues.riskManagementFrameworks,
      tags: currentValues.policyTags,
      linked_policies: lnkedPolicies,
    };

    if (currentValues.notes.length > 500) {
      showMsg("info", "Notes must be less than 500 characters");
      setIsSaving(false);
      return;
    }
    return dispatch(updatePolicy({ objPostData, objUser, policyId }))
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 200) {
          setFormInitialValues(currentValues);
          showMsg("success", "Policy saved successfully");
        } else {
          showMsg("Error saving policy. Try again later");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        showMsg("error", rejectedValueOrSerializedError.response.data.debug);
        // console.log("Error saving policy");
        // console.log(rejectedValueOrSerializedError);
      })
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    const savePolicyTimeout = setInterval(() => {
      if (formRef.current?.values !== formInitialValues) {
        saveForm(formRef.current.values);
      }
    }, 5 * 60 * 1000);
    return () => clearInterval(savePolicyTimeout);
  });

  const onClickAddVersion = () => {
    setIsSaving(true);
    return dispatch(newVersion({ objUser, policyId }))
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          navigate(`/policies/${policyId}/${response.data.major_version}`, {
            state: { routeDictionary },
          });
        } else {
          showMsg("Error creating new version. Try again later");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        showMsg("Error", "Error creating new version. Try again later");
      })
      .finally(() => setIsSaving(false));
  };

  const duplicateAsNewVersion = (row) => {
    setIsSaving(true);
    return dispatch(copyAsNewVersion({ objUser, policyId, row }))
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          navigate(`/policies/${policyId}/${response.data.major_version}`, {
            state: { routeDictionary },
          });
        } else {
          showMsg("Error creating new version. Try again later");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        showMsg("Error", "Error creating new version. Try again later");
        console.log(rejectedValueOrSerializedError);
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid
        my={3}
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Grid item xs={12} lg={12}>
          <Card sx={{ height: "100%" }}>
            {isLoading === false &&
            Object.keys(formInitialValues).length > 0 &&
            formInitialValues.title !== "" ? (
              <Formik
                initialValues={formInitialValues}
                validationSchema={EditPolicyFormJWTSchema}
                innerRef={formRef}
                onSubmit={onSubmitForm}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                  <Form id="EditPolicyForm" autoComplete="off">
                    <MDBox>
                      {/* {objPolicy.policy_type == "default" ? ( */}
                      <EditPolicyFormFormikWrapper
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        categories={objPolicy.extra.categories}
                        contacts={objPolicy.extra.contacts}
                        templates={objPolicy.extra.templates}
                        lnkedPolicies={lnkedPolicies}
                        setLnkedPolicies={setLnkedPolicies}
                        objPolicy={objPolicy}
                      />
                      {/* ) : (
                        ""
                      )} */}
                    </MDBox>
                    <MDBox
                      px={5}
                      sx={{ marginTop: "2rem" }}
                      mb={2}
                      textAlign="left"
                      mx="auto"
                    >
                      <Accordion
                        defaultExpanded={true}
                        style={{
                          marginBottom: "25px",
                          borderRadius: "5px",
                        }}
                        sx={
                          darkMode
                            ? { backgroundColor: "#f0f0f091" }
                            : { backgroundColor: "#f0f0f0" }
                        }
                      >
                        <AccordionSummary
                          // sx={{ flexDirection: "row-reverse" }}
                          expandIcon={<ExpandMoreIcon color="icon" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <MDTypography
                            variant="h6"
                            style={{
                              fontSize: "15px",
                              color: darkMode ? "whitefix" : "#344767",
                            }}
                          >
                            List of All{" "}
                            {objPolicy.policy_type == "asset"
                              ? "Asset List"
                              : "Policy"}{" "}
                            Versions
                          </MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <VersionsDatatable
                            policyId={policyId}
                            objUser={objUser}
                            rows={documentVersions}
                            duplicateAsNewVersion={duplicateAsNewVersion}
                            canSearch
                            objPolicy={objPolicy}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </MDBox>
                    {!isSaving ? (
                      <MDBox
                        sx={{ margin: "0 0 40px -40px" }}
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <MDButton
                          type="button"
                          onClick={onClickAddVersion}
                          // variant="gradient"
                          color="success"
                          sx={{ padding: "12px 12px" }}
                        >
                          <Icon fontSize="large">add</Icon>
                          Add version
                        </MDButton>
                        {/* {objPolicy.policy_type == "default" ? ( */}
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="dark"
                          sx={{ padding: "12px 12px" }}
                          style={{ marginLeft: "16px" }}
                        >
                          Save
                        </MDButton>
                        {/* ) : (
                          ""
                        )} */}
                      </MDBox>
                    ) : (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="lg"
                      />
                    )}
                  </Form>
                )}
              </Formik>
            ) : (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default EditPolicy;
