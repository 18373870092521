import DefaultCell from "views/components/TableCells/DefaultCell";

const DataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Latest Version",
      accessor: "version",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Created by",
      accessor: "created_by",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default DataTableData;
