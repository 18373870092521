import GlobalTemplateNameBtnTr from "./GlobalTemplateNameBtnTr";
const TemplatesDataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "name",
      width: "23%",
      Cell: ({ value, column, row }) => (
        <GlobalTemplateNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Type",
      accessor: "type",
      width: "23%",
    },
    {
      Header: "Frameworks",
      accessor: "current_rmfs",
      width: "15%",
    },
    {
      Header: "Created By",
      accessor: "created_by",
      width: "15%",
    },
    {
      Header: "Last updated at",
      accessor: "last_modified_at",
      width: "12%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "12%",
    },
  ],
  rows: [],
};

export default TemplatesDataTableData;
