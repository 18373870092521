import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { addRMF } from "features/company/policyPackActions";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Autocomplete } from "@mui/material";
const objInitialValues = {
  txtRMFName: "",
};
const AddNewCategoryFormJWTSchema = Yup.object().shape({
  txtRMFName: Yup.string().required(
    "The risk management framework name is required."
  ),
});

function CreateNewRMFDialog({ open, onClose, onSubmit, objUser }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const objPostData = {
      name: values.txtRMFName,
    };

    //console.log(objPostData);
    setIsLoading(true);
    dispatch(addRMF({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          showMsg(
            "success",
            "New Risk Management Framework added successfully"
          );
          onSubmit(response);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>
        Add New Risk Management Framework
      </DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewCategoryFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddNewRMFForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Framework Name"
                  name="txtRMFName"
                  value={values.txtRMFName}
                  placeholder={"Name"}
                  error={errors.txtRMFName && touched.txtRMFName}
                  success={values.txtRMFName.length > 0 && !errors.txtRMFName}
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtRMFName" />
                  </MDTypography>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Add
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default CreateNewRMFDialog;
