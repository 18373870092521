import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { addBD } from "features/company/superAdminSiteSecurityActions";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
const objInitialValues = {
  txtDomain: "",
};
const AddNewSchema = Yup.object().shape({
  txtDomain: Yup.string().required("Domain is required"),
});

function CreateNewDialog({ open, onClose, onSubmit, objUser }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const objPostData = {
      domain: values.txtDomain,
    };
    setIsLoading(true);
    dispatch(addBD({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          showMsg("success", "Domain successfully added to blocked domains");
          onSubmit(response);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Add A Domain To The Blocklist</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddNewForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Domain"
                  name="txtDomain"
                  value={values.txtDomain}
                  placeholder={"Name"}
                  error={errors.txtDomain && touched.txtDomain}
                  success={values.txtDomain.length > 0 && !errors.txtDomain}
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtDomain" />
                  </MDTypography>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Add
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default CreateNewDialog;
