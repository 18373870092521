import React from "react";

const TermsandConditions = () => {
  return (
    <>
      <h2>1. AGREEMENT TO TERMS</h2>
      <p>
        These Terms of Service constitute a legally binding agreement made
        between you, whether personally or on behalf of an entity ("you") and
        Compliancerisk.io, Inc., a Delaware corporation ("Company", "we", "us",
        or "our"), concerning your access to and use of the
        https://go.compliancerisk.io/platform as well as any other media form,
        media channel, mobile website, or mobile application related, linked, or
        otherwise connected thereto (collectively, the "Site"). You agree that
        by accessing the Site or using the platform, you have read, understood,
        and agree to be bound by all of these Terms of Service, , which are
        incorporated into these Terms of Service. IF YOU DO NOT AGREE WITH ALL
        OF THESE TERMS OF SERVICE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
        THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
      </p>
      <p>
        Supplemental terms and conditions or documents that may be posted on the
        Site from time to time are hereby expressly incorporated herein by
        reference. We reserve the right, in our sole discretion, to make changes
        or modifications to the website or platform Terms of Service from time
        to time. We will alert you about any website changes by updating the
        "Last updated" date of these Terms of Service, and you waive any right
        to receive specific notice of each such change. Platform clients will be
        notified by email if the Terms of Service are amended. Please ensure
        that you check the applicable Terms every time you use our Site or
        Platform so that you understand which Terms apply. You will be subject
        to and will be deemed to have been made aware of and to have accepted,
        the changes in any revised Terms of Service by your continued use of the
        Site after the date such revised Terms of Service are posted or emailed.
      </p>
      <p>
        The information provided on the Site is not intended for distribution to
        or use by any person or entity in any jurisdiction or country where such
        distribution or use would be contrary to law or regulation or which
        would subject us to any registration requirement within such
        jurisdiction or country. Accordingly, those persons who choose to access
        the Site from other locations do so on their own initiative and are
        solely responsible for compliance with local laws, if and to the extent
        local laws are applicable.
      </p>
      <p>
        The Site is intended for users who are at least 18 years old. Persons
        under the age of 18 are not permitted to use or register for the Site.
      </p>
      <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>
        Unless otherwise indicated, the Site is our proprietary property and all
        document templates, lists, source code, databases, functionality,
        software, website designs, audio, video, text, photographs, and graphics
        on the Site (collectively, the "Content") and the trademarks, service
        marks, and logos contained therein (the "Marks") are owned or controlled
        by us or licensed to us, and are protected by copyright and trademark
        laws and various other intellectual property rights and unfair
        competition laws of the United States, international copyright laws, and
        international conventions. The Content and the Marks are provided on the
        Site "AS IS" for your information and personal use only. Except as
        expressly provided in these Terms of Service, no part of the Site and no
        Content or Marks may be copied, reproduced, aggregated, republished,
        uploaded, posted, publicly displayed, encoded, translated, transmitted,
        distributed, sold, licensed, or otherwise exploited for any commercial
        purpose whatsoever, without our express prior written permission.
      </p>
      <p>
        Provided that you are eligible to use the Site, you are granted a
        limited license to access and use the Site to which you have properly
        gained access solely for your personal, non-commercial use. We reserve
        all rights not expressly granted to you in and to the Site, the Content
        and the Marks.
      </p>
      <h2>3. USER REPRESENTATIONS</h2>
      <p>
        By using the Site, you represent and warrant that: (1) all registration
        information you submit will be true, accurate, current, and complete;
        (2) you will maintain the accuracy of such information and promptly
        update such registration information as necessary; (3) you have the
        legal capacity and you agree to comply with these Terms of Service; (4)
        you are not a minor in the jurisdiction in which you reside; (5) you
        will not access the Site through automated or non-human means, whether
        through a bot, script or otherwise; (6) you will not use the Site for
        any illegal or unauthorized purpose; and (7) your use of the Site will
        not violate any applicable law or regulation.
      </p>
      <p>
        If you provide any information that is untrue, inaccurate, not current,
        or incomplete, we have the right to suspend or terminate your account
        and refuse all current or future use of the Site (or any portion
        thereof).
      </p>
      <h2>4. USER REGISTRATION</h2>
      <p>
        You may be required to register with the Site. You agree to keep your
        password confidential and will be responsible for all use of your
        account and password. We reserve the right to remove, reclaim, or change
        a username you select if we determine, in our sole discretion, that such
        username is inappropriate, obscene, or otherwise objectionable.
      </p>
      <h2>5. FEES AND PAYMENT</h2>
      <p>
        You can access various paid services for you and/or your customers
        through the Site. The Company provides certain service plans wherein you
        can provide access to such services to your customers through your
        account, where you would be an authorized reseller (the "Reseller").
        Each customer registered through such Reseller account is referred to as
        a customer "Seat Credit." By selecting a service plan, you agree to pay
        to the Company the subscription fees indicated for that service for
        yourself and/or each Seat Credit, as may be applicable. Payments will be
        charged on a pre-paid basis for each month on the day you sign up for a
        plan and will cover the use of that service for the subscription period
        as indicated. Fees will be prorated based on the subscription/ billing
        start date. Fees are not refundable.
      </p>
      <p>
        Unless you notify the Company before the end of the applicable renewal
        cycle that you want to cancel a plan or cancel access for any Seat
        Credit, your applicable plan subscription will automatically renew and
        you authorize us to collect the then-applicable invoiced monthly (for
        either the monthly or annual) subscription fee for such service (as well
        as any taxes) using any credit card or other payment mechanism we have
        on record for you.
      </p>
      <p>
        If you are a Reseller and maintain a minimum of one Seat Credit per
        month for the applicable service plan, you may be eligible for certain
        free "not for resale" services for your internal corporate use, which
        use shall not be shared with your customer.
      </p>
      <p>We accept the following forms of payment:</p>
      <ul style={{ listStyle: "outside" }}>
        <li> Stripe</li>
      </ul>
      <p>
        You agree to provide current, complete, and accurate purchase and
        account information for all purchases made via the Site. You further
        agree to promptly update account and payment information, including
        email address, payment method, and payment card expiration date, so that
        we can complete your transactions and contact you as needed. We bill you
        through an online billing account for purchases made via the Site. Sales
        tax will be added to the price of purchases as deemed required by us. We
        may change prices at any time. All payments shall be in U.S. dollars.
      </p>
      <p>
        You agree to pay all charges or fees at the prices then in effect for
        your purchases, and you authorize us to charge your chosen payment
        provider for any such amounts upon making your purchase. If your
        purchase is subject to recurring charges, then you consent to our
        charging your payment method on a recurring basis without requiring your
        prior approval for each recurring charge, until you notify us of your
        cancellation.
      </p>
      <p>
        We reserve the right to correct any errors or mistakes in pricing, even
        if we have already requested or received payment. We also reserve the
        right to refuse any order placed through the Site.
      </p>
      <h2>6. NOT FOR RESALE LICENSE</h2>
      <p>
        If you are an authorized Reseller and maintain a minimum of one Seat
        Credit per month for the applicable service plan, then you may have
        access to certain "Not for Resale" licenses on the Site. If a license is
        identified as "Not for Resale" then, notwithstanding any contrary
        provision in this Agreement, you may only use the license if you are a
        current authorized Reseller and then only for demonstration purposes in
        support of your customers. Not for Resale license may not be used for
        customer training unless otherwise agreed by us, the Company. This
        license may be terminated by the Company at any time, with or without
        cause. NOTWITHSTANDING ANY CONTRARY PROVISION IN THIS AGREEMENT, NOT FOR
        RESALE LICENSE IS PROVIDED "AS-IS" WITHOUT SUPPORT OR WARRANTY OF ANY
        KIND, EXPRESS OR IMPLIED.
      </p>
      <h2>7. CANCELLATION</h2>
      <p>
        You can cancel your subscription at any time by contacting our client
        services division at clientservices@compliancerisk.io. Your cancellation
        will take effect at the end of the current paid term. If you are
        unsatisfied with our services, please email us at
        clientservices@compliancerisk.io or call us at 6038195368.
      </p>
      <h2>8. PROHIBITED ACTIVITIES</h2>
      <p>
        You may not access or use the Site for any purpose other than that for
        which we make the Site available. The Site may not be used in connection
        with any commercial endeavors except those that are specifically
        endorsed or approved by us.
      </p>
      <p>As a user of the Site, you agree not to:</p>
      <ul style={{ listStyle: "outside" }}>
        <li>
          Systematically retrieve data or other content from the Site to create
          or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </li>
        <li>
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </li>
        <li>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Site, including features that prevent or restrict the
          use or copying of any Content or enforce limitations on the use of the
          Site and/or the Content contained therein.
        </li>
        <li>
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Site.
        </li>
        <li>
          Use any information obtained from the Site to harass, abuse, or harm
          another person.
        </li>
        <li>
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </li>
        <li>
          Use the Site in a manner inconsistent with any applicable laws or
          regulations.
        </li>
        <li>Engage in unauthorized framing of or linking to the Site.</li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming (continuous posting of repetitive text), that
          interferes with any party's uninterrupted use and enjoyment of the
          Site or modifies, impairs, disrupts, alters, or interferes with the
          use, features, functions, operation, or maintenance of the Site.
        </li>
        <li>
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </li>
        <li>
          Delete the copyright or other proprietary rights notice from any
          Content.
        </li>
        <li>
          Attempt to impersonate another user or person or use the username of
          another user.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) any material
          that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats ("gifs"), 1x1 pixels, web bugs, cookies, or other
          similar devices (sometimes referred to as "spyware" or "passive
          collection mechanisms" or "pcms").
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Site or the
          networks or services connected to the Site.
        </li>
        <li>
          Harass, annoy, intimidate, or threaten any of our employees or agents
          engaged in providing any portion of the Site to you.
        </li>
        <li>
          Attempt to bypass any measures of the Site designed to prevent or
          restrict access to the Site, or any portion of the Site.
        </li>
        <li>
          Copy or adapt the Site's software, including but not limited to PHP,
          HTML, JavaScript, or other code.
        </li>
        <li>
          Except as permitted by applicable law, decipher, decompile,
          disassemble, or reverse engineer or otherwise attempt to discover the
          source code, object code or underlying structure, ideas or algorithms
          of the Services or any software comprising or in any way making up a
          part of the Site.
        </li>
        <li>
          Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Site, or using
          or launching any unauthorized script or other software.
        </li>
        <li>
          Use a buying agent or purchasing agent to make purchases on the Site.
        </li>
        <li>
          Make any unauthorized use of the Site, including collecting usernames
          and/or email addresses of users by electronic or other means for the
          purpose of sending unsolicited email, or creating user accounts by
          automated means or under false pretenses.
        </li>
        <li>
          Use the Site as part of any effort to compete with us or otherwise use
          the Site and/or the Content for any revenue-generating endeavor or
          commercial enterprise.
        </li>
        <li>Use the Site to advertise or offer to sell goods and services.</li>
      </ul>
      <h2>9. USER GENERATED CONTRIBUTIONS</h2>
      <p>
        The Site may invite you to chat, contribute to, or participate in blogs,
        message boards, online forums, and other functionality, and may provide
        you with the opportunity to create, submit, post, display, transmit,
        perform, publish, distribute, or broadcast content and materials to us
        or on the Site, including but not limited to text, writings, video,
        audio, photographs, graphics, comments, suggestions, or personal
        information or other material (collectively, "Contributions").
        Contributions may be viewable by other users of the Site and through
        third-party websites. As such, any Contributions you transmit may be
        treated as non-confidential and non-proprietary. You retain your
        intellectual property ownership rights over content you submit to us for
        publication on our website.
      </p>
      <p>
        When you use our website or its associated services to post, upload,
        share, or otherwise transmit content covered by intellectual property
        rights, you grant to us a non-exclusive, royalty-free, transferable,
        sub-licensable, worldwide license to use, distribute, modify, run, copy,
        publicly display, translate, or otherwise create derivative works of
        your content in a manner that is consistent with your privacy
        preferences and our Privacy Policy.
      </p>
      <p>
        The license you grant us can be terminated at any time by deleting your
        content or account. However, to the extent that we (or our partners)
        have used your content in connection with commercial or sponsored
        content, the license will continue until the relevant commercial or post
        has been discontinued by us.
      </p>
      <p>
        You give us permission to use your username, company name, and other
        identifying information associated with your account in a manner that is
        consistent with your privacy preferences, and our Privacy Policy. When
        you create or make available any Contributions, you thereby represent
        and warrant that:
      </p>
      <ul style={{ listStyle: "outside" }}>
        <li>
          The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </li>
        <li>
          You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Site, and other users of the Site to use your Contributions in
          any manner contemplated by the Site and these Terms of Service.
        </li>
        <li>
          You have the written consent, release, and/or permission of each and
          every identifiable individual person in your Contributions to use the
          name or likeness of each and every such identifiable individual person
          to enable inclusion and use of your Contributions in any manner
          contemplated by the Site and these Terms of Service.
        </li>
        <li>Your Contributions are not false, inaccurate, or misleading.</li>
        <li>
          Your Contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation.
        </li>
        <li>
          Your Contributions are not obscene, lewd, lascivious, filthy, violent,
          harassing, libelous, slanderous, or otherwise objectionable (as
          determined by us).
        </li>
        <li>
          Your Contributions do not ridicule, mock, disparage, intimidate, or
          abuse anyone.
        </li>
        <li>
          Your Contributions are not used to harass or threaten (in the legal
          sense of those terms) any other person and to promote violence against
          a specific person or class of people.
        </li>
        <li>
          Your Contributions do not violate any applicable law, regulation, or
          rule.
        </li>
        <li>
          Your Contributions do not violate the privacy or publicity rights of
          any third party.
        </li>
        <li>
          Your Contributions do not violate any applicable law concerning child
          pornography, or otherwise intended to protect the health or well-being
          of minors.
        </li>
        <li>
          Your Contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical handicap.
        </li>
        <li>
          Your Contributions do not otherwise violate, or link to material that
          violates, any provision of these Terms of Service, or any applicable
          law or regulation.
        </li>
      </ul>
      <p>
        Any use of the Site in violation of the foregoing violates these Terms
        of Service and may result in, among other things, termination, or
        suspension of your rights to use the Site.
      </p>
      <h2>10. CONTRIBUTION LICENSE</h2>
      <p>
        By posting your Contributions to any part of the Site, you automatically
        grant, and you represent and warrant that you have the right to grant,
        to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
        transferable, royalty-free, fully paid, worldwide right, and license to
        host, use, copy, reproduce, disclose, sell, resell, publish, broadcast,
        retitle, archive, store, cache, publicly perform, publicly display,
        reformat, translate, transmit, excerpt (in whole or in part), and
        distribute such Contributions (including, without limitation, your image
        and voice) for any purpose, commercial, advertising, or otherwise, and
        to prepare derivative works of, or incorporate into other works, such
        Contributions, and grant and authorize sublicenses of the foregoing. The
        use and distribution may occur in any media formats and through any
        media channels.
      </p>
      <p>
        This license will apply to any form, media, or technology now known or
        hereafter developed, and includes our use of your name, company name,
        and franchise name, as applicable, and any of the trademarks, service
        marks, trade names, logos, and personal and commercial images you
        provide. You waive all moral rights in your Contributions, and you
        warrant that moral rights have not otherwise been asserted in your
        Contributions.&nbsp;
      </p>
      <p>
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights, or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area on the Site. You are solely
        responsible for your Contributions to the Site, and you expressly agree
        to exonerate us from any and all responsibility and to refrain from any
        legal action against us regarding your Contributions.
      </p>
      <p>
        We have the right, in our sole and absolute discretion, (1) to edit,
        redact, or otherwise change any Contributions; (2) to re-categorize any
        Contributions to place them in more appropriate locations on the Site;
        and (3) to pre-screen or delete any Contributions at any time and for
        any reason, without notice. We have no obligation to monitor your
        Contributions.
      </p>
      <h2>11. GUIDELINES FOR REVIEWS</h2>
      <p>
        We may provide you areas on the Site to leave reviews or ratings. When
        posting a review, you must comply with the following criteria: (1) you
        should have firsthand experience with the person/entity being reviewed;
        (2) your reviews should not contain offensive profanity, or abusive,
        racist, offensive, or hate language; (3) your reviews should not contain
        discriminatory references based on religion, race, gender, national
        origin, age, marital status, sexual orientation, or disability; (4) your
        reviews should not contain references to illegal activity; (5) you
        should not be affiliated with competitors if posting negative reviews;
        (6) you should not make any conclusions as to the legality of conduct;
        (7) you may not post any false or misleading statements; and (8) you may
        not organize a campaign encouraging others to post reviews, whether
        positive or negative.
      </p>
      <p>
        We may accept, reject, or remove reviews in our sole discretion. We have
        absolutely no obligation to screen reviews or to delete reviews, even if
        anyone considers reviews objectionable or inaccurate. Reviews are not
        endorsed by us, and do not necessarily represent our opinions or the
        views of any of our affiliates or partners. We do not assume liability
        for any review or for any claims, liabilities, or losses resulting from
        any review. By posting a review, you&nbsp;hereby grant to us a
        perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
        assignable, and sublicensable right and license to reproduce, modify,
        translate, transmit by any means, display, perform, and/or distribute
        all content relating to reviews.
      </p>
      <h2>12. SUBMISSIONS</h2>
      <p>
        You acknowledge and agree that any questions, comments, suggestions,
        ideas, feedback, or other information regarding the Site ("Submissions")
        provided by you to us are non-confidential and shall become our sole
        property. We shall own exclusive rights, including all intellectual
        property rights, and shall be entitled to the unrestricted use and
        dissemination of these Submissions for any lawful purpose, commercial or
        otherwise, without acknowledgment or compensation to you. You hereby
        waive all moral rights to any such Submissions, and you hereby warrant
        that any such Submissions are original with you or that you have the
        right to submit such Submissions. You agree there shall be no recourse
        against us for any alleged or actual infringement or misappropriation of
        any proprietary right in your Submissions.
      </p>
      <h2>13. SITE MANAGEMENT</h2>
      <p>
        We reserve the right, but not the obligation, to: (1) monitor the Site
        for violations of these Terms of Service; (2) take appropriate legal
        action against anyone who, in our sole discretion, violates the law or
        these Terms of Service, including without limitation, reporting such
        user to law enforcement authorities; (3) in our sole discretion and
        without limitation, refuse, restrict access to, limit the availability
        of, or disable (to the extent technologically feasible) any of your
        Contributions or any portion thereof; (4) in our sole discretion and
        without limitation, notice, or liability, to remove from the Site or
        otherwise disable all files and content that are excessive in size or
        are in any way burdensome to our systems; and (5) otherwise manage the
        Site in a manner designed to protect our rights and property and to
        facilitate the proper functioning of the Site.
      </p>
      <h2>14. PRIVACY POLICY</h2>
      <p>
        We care about data privacy and security. Please review our{" "}
        <a
          href="https://compliancerisk.io/category/policies/"
          target="_blank"
          title="link to our policies"
        >
          Privacy Policy: https://compliancerisk.io/category/policies/.
        </a>
        By using the Site, you agree to be bound by our Privacy Policy, which is
        incorporated into these Terms of Service. Please be advised the Site is
        hosted in the United States. If you access the Site from any other
        region of the world with laws or other requirements governing personal
        data collection, use, or disclosure that differ from applicable laws in
        the United States, then through your continued use of the Site, you are
        transferring your data to the United States, and you agree to have your
        data transferred to and processed in the United States.
      </p>
      <h2>15. DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</h2>
      <h2>Notifications</h2>
      <p>
        We respect the intellectual property rights of others. If you believe
        that any material available on or through the Site infringes upon any
        copyright you own or control, please immediately notify our Designated
        Copyright Agent using the contact information provided below (a
        "Notification"). A copy of your Notification will be sent to the person
        who posted or stored the material addressed in the Notification. Please
        be advised that pursuant to federal law you may be held liable for
        damages if you make material misrepresentations in a Notification. Thus,
        if you are not sure that material located on or linked to by the Site
        infringes your copyright, you should consider first contacting an
        attorney.
      </p>
      <p>
        All Notifications should meet the requirements of DMCA 17 U.S.C.
        512(c)(3) and include the following information: (1) A physical or
        electronic signature of a person authorized to act on behalf of the
        owner of an exclusive right that is allegedly infringed; (2)
        identification of the copyrighted work claimed to have been infringed,
        or, if multiple copyrighted works on the Site are covered by the
        Notification, a representative list of such works on the Site; (3)
        identification of the material that is claimed to be infringing or to be
        the subject of infringing activity and that is to be removed or access
        to which is to be disabled, and information reasonably sufficient to
        permit us to locate the material; (4) information reasonably sufficient
        to permit us to contact the complaining party, such as an address,
        telephone number, and, if available, an email address at which the
        complaining party may be contacted; (5) a statement that the complaining
        party has a good faith belief that use of the material in the manner
        complained of is not authorized by the copyright owner, its agent, or
        the law; and (6) a statement that the information in the notification is
        accurate, and under penalty of perjury, that the complaining party is
        authorized to act on behalf of the owner of an exclusive right that is
        allegedly infringed upon.
      </p>
      <h2>Counter Notification</h2>
      <p>
        If you believe your own copyrighted material has been removed from the
        Site as a result of a mistake or misidentification, you may submit a
        written counter notification to our Designated Copyright Agent using the
        contact information provided below (a "Counter Notification"). To be an
        effective Counter Notification under the DMCA, your Counter Notification
        must include substantially the following: (1) identification of the
        material that has been removed or disabled and the location at which the
        material appeared before it was removed or disabled; (2) a statement
        that you consent to the jurisdiction of the Federal District Court in
        which your address is located, or if your address is outside the United
        States, for any judicial district in which we are located; (3) a
        statement that you will accept service of process from the party that
        filed the Notification or the party's agent; (4) your name, address, and
        telephone number; (5) a statement under penalty of perjury that you have
        a good faith belief that the material in question was removed or
        disabled as a result of a mistake or misidentification of the material
        to be removed or disabled; and (6) your physical or electronic
        signature.
      </p>
      <p>
        If you send us a valid, written Counter Notification meeting the
        requirements described above, we will restore your removed or disabled
        material, unless we first receive notice from the party filing the
        Notification informing us that such party has filed a court action to
        restrain you from engaging in infringing activity related to the
        material in question. Please note that if you materially misrepresent
        that the disabled or removed content was removed by mistake or
        misidentification, you may be liable for damages, including costs and
        attorney's fees. Filing a false Counter Notification constitutes
        perjury.
      </p>
      Designated Copyright Agent: <br />
      Timothy M. Golden <br />
      Compliancerisk.io, Inc. <br />
      Attn: Copyright Agent <br />
      19 Lisa Beth Circle <br />
      Dover, NH 03820 <br />
      legal@compliancerisk.io
      <h2>16. TERM AND TERMINATION</h2>
      <p>
        These Terms of Service shall remain in full force and effect while you
        use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF
        SERVICE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
        NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING
        BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
        REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
        WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF SERVICE OR OF ANY
        APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION
        IN THE SITE OR DELETE YOUR&nbsp;ACCOUNT AND&nbsp;ANY CONTENT OR
        INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
        DISCRETION.
      </p>
      <p>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party. In addition to terminating or
        suspending your account, we reserve the right to take appropriate legal
        action, including without limitation pursuing civil, criminal, and
        injunctive redress.
      </p>
      <h2>17. MODIFICATIONS AND INTERRUPTIONS</h2>
      <p>
        We reserve the right to change, modify, or remove the contents of the
        Site at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Site. We also reserve the right to modify or discontinue all or part of
        the Site without notice at any time. We will not be liable to you or any
        third party for any modification, price change, suspension, or
        discontinuance of the Site.
      </p>
      <p>
        We cannot guarantee the Site will be available at all times. We may
        experience hardware, software, or other problems or need to perform
        maintenance related to the Site, resulting in interruptions, delays, or
        errors. We reserve the right to change, revise, update, suspend,
        discontinue, or otherwise modify the Site at any time or for any reason
        without notice to you. You agree that we have no liability whatsoever
        for any loss, damage, or inconvenience caused by your inability to
        access or use the Site during any downtime or discontinuance of the
        Site. Nothing in these Terms of Service will be construed to obligate us
        to maintain and support the Site or to supply any corrections, updates,
        or releases in connection therewith.
      </p>
      <h2>18. GOVERNING LAW</h2>
      <p>
        These Terms of Service and your use of the Site are governed by and
        construed in accordance with the laws of the State of New Hampshire,
        without regard to its conflict of law principles.
      </p>
      <h2>19. DISPUTE RESOLUTION</h2>
      <h2>Informal Negotiations</h2>
      <p>
        To expedite resolution and control the cost of any dispute, controversy,
        or claim related to these Terms of Service (each "Dispute" and
        collectively, the "Disputes") brought by either you or us (individually,
        a "Party" and collectively, the "Parties"), the Parties agree to first
        attempt to negotiate any Dispute (except those Disputes expressly
        provided below) informally for at least ninety (90) days before
        initiating arbitration. Such informal negotiations commence upon written
        notice from one Party to the other Party.
      </p>
      <h2>Binding Arbitration</h2>
      <p>
        If the Parties are unable to resolve a Dispute through informal
        negotiations, the Dispute (except those Disputes expressly excluded
        below) will be finally and exclusively resolved by binding arbitration.
        YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO
        SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced
        and conducted under the Commercial Arbitration Rules of the American
        Arbitration Association ("AAA") and, where appropriate, the AAA's
        Supplementary Procedures for Consumer Related Disputes ("AAA Consumer
        Rules"), both of which are available at the AAA website www.adr.org.
        Your arbitration fees and your share of arbitrator compensation shall be
        governed by the AAA Consumer Rules and, where appropriate, limited by
        the AAA Consumer Rules.&nbsp;The arbitration may be conducted in person,
        through the submission of documents, by phone, or online. The arbitrator
        will make a decision in writing but need not provide a statement of
        reasons unless requested by either Party. The arbitrator must follow
        applicable law, and any award may be challenged if the arbitrator fails
        to do so. Except where otherwise required by the applicable AAA rules or
        applicable law, the arbitration will take place in New Hampshire, United
        States. Except as otherwise provided herein, the Parties may litigate in
        court to compel arbitration, stay proceedings pending arbitration, or to
        confirm, modify, vacate, or enter judgment on the award entered by the
        arbitrator.
      </p>
      <p>
        If for any reason, a Dispute proceeds in court rather than arbitration,
        the Dispute shall be commenced or prosecuted in the state and federal
        courts&nbsp;located in United States, New Hampshire, and the Parties
        hereby consent to, and waive all defenses of lack of personal
        jurisdiction, and forum non conveniens with respect to venue and
        jurisdiction in such state and federal courts. Application of the United
        Nations Convention on Contracts for the International Sale of Goods and
        the Uniform Computer Information Transaction Act (UCITA) are excluded
        from these Terms of Service.
      </p>
      <p>
        In no event shall any Dispute brought by either Party related in any way
        to the Site be commenced more than one (1) years after the cause of
        action arose. If this provision is found to be illegal or unenforceable,
        then neither Party will elect to arbitrate any Dispute falling within
        that portion of this provision found to be illegal or unenforceable and
        such Dispute shall be decided by a court of competent jurisdiction
        within the courts listed for jurisdiction above, and the Parties agree
        to submit to the personal jurisdiction of that court.
      </p>
      <h2>Restrictions</h2>
      <p>
        The Parties agree that any arbitration shall be limited to the Dispute
        between the Parties individually. To the full extent permitted by law,
        (a) no arbitration shall be joined with any other proceeding; (b) there
        is no right or authority for any Dispute to be arbitrated on a
        class-action basis or to utilize class action procedures; and (c) there
        is no right or authority for any Dispute to be brought in a purported
        representative capacity on behalf of the general public or any other
        persons.
      </p>
      <h2>Exceptions to Informal Negotiations and Arbitration</h2>
      <p>
        The Parties agree that the following Disputes are not subject to the
        above provisions concerning informal negotiations and binding
        arbitration: (a) any Disputes seeking to enforce or protect, or
        concerning the validity of, any of the intellectual property rights of a
        Party; (b) any Dispute related to, or arising from, allegations of
        theft, piracy, invasion of privacy, or unauthorized use; and (c) any
        claim for injunctive relief. If this provision is found to be illegal or
        unenforceable, then neither Party will elect to arbitrate any Dispute
        falling within that portion of this provision found to be illegal or
        unenforceable and such Dispute shall be decided by a court of competent
        jurisdiction within the courts listed for jurisdiction above, and the
        Parties agree to submit to the personal jurisdiction of that court.
      </p>
      <h2>20. CORRECTIONS</h2>
      <p>
        There may be information on the Site that contains typographical errors,
        inaccuracies, or omissions, including descriptions, pricing,
        availability, and various other information. We reserve the right to
        correct any errors, inaccuracies, or omissions and to change or update
        the information on the Site at any time, without prior notice.
      </p>
      <h2>21. DISCLAIMER</h2>
      <p>
        THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
        YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE
        FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
        IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING,
        WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
        FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE'S CONTENT
        OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
        LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
        INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
        OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
        AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
        STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
        FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH
        MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
        ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
        DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
        POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT
        WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
        SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY
        HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
        ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
        WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
        USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </p>
      <p>
        Any information and/or sample documents or template policy documents
        provided by Compliancerisk.io, Inc. ("Compliancerisk.io") on
        https://compliancerisk.io/ or by any other means are for informational
        purposes only and is not intended to constitute or substitute for
        obtaining (i) legal advice from an attorney or (ii) accounting, tax, or
        financial advice from a professional accountant.
      </p>
      <p>
        Compliancerisk.io is not a law firm or professional accounting firm and
        is not providing legal advice or accounting, tax, or financial advice.
        All information (including sample / template policy documents) available
        on or downloaded from our website, https://compliancerisk.io/, is
        provided without any warranty, express or implied, including as to their
        legal effect and completeness. The information should be used as a guide
        and modified to meet your own individual needs and the laws of your
        state. Your use of any information or sample / template policy document
        is at your own risk. Compliancerisk.io and any of its employees,
        contractors, or attorneys who participated in providing the information
        expressly disclaim any warranty: they are not creating or entering into
        any attorney-client relationship or accountant-client relationship by
        providing information or this sample / template policy documents to you.
      </p>
      <p>
        Communications between you and Compliancerisk.io is only protected by
        our Privacy Policy, and NOT protected by the attorney-client privilege.
        Our policies are stored and maintained here:
        <a href="https://compliancerisk.io/category/policies/" target="_blank">
          https://compliancerisk.io/category/policies/
        </a>
      </p>
      <h2>22. LIMITATIONS OF LIABILITY</h2>
      <p>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
        PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
        USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
        LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF
        THE ACTION, WILL AT ALL TIMES BE LIMITED TO $500.00 USD. CERTAIN US
        STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
        WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE
        LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
        MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
      </p>
      <h2>23. INDEMNIFICATION</h2>
      <p>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of: (1) your
        Contributions; (2) breach of these Terms of Service; (3) any breach of
        your representations and warranties set forth in these Terms of Service;
        (4) any overt harmful act toward any other user of the Site with whom
        you connected via the Site. Notwithstanding the foregoing, we reserve
        the right, at your expense, to assume the exclusive defense and control
        of any matter for which you are required to indemnify us, and you agree
        to cooperate, at your expense, with our defense of such claims. We will
        use reasonable efforts to notify you of any such claim, action, or
        proceeding which is subject to this indemnification upon becoming aware
        of it.
      </p>
      <h2>24. USER DATA</h2>
      <p>
        We will maintain certain data that you transmit to the Site for the
        purpose of managing the performance of the Site, as well as data
        relating to your use of the Site. Although we perform regular routine
        backups of data, you are solely responsible for all data that you
        transmit or that relates to any activity you have undertaken using the
        Site. You agree that we shall have no liability to you for any loss or
        corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.
      </p>
      <h2>25. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
      <p>
        Visiting the Site, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Site, satisfy any legal requirement that such communication be in
        writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE SITE. You hereby waive any rights or requirements under
        any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.
      </p>
      <h2>26. CALIFORNIA USERS AND RESIDENTS</h2>
      <p>
        If any complaint with us is not satisfactorily resolved, you can contact
        the Complaint Assistance Unit of the Division of Consumer Services of
        the California Department of Consumer Affairs in writing at 1625 North
        Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
        at (800) 952-5210 or (916) 445-1254.
      </p>
      <h2>27. MISCELLANEOUS</h2>
      <p>
        These Terms of Service and any policies or operating rules posted by us
        on the Site or in respect to the Site constitute the entire agreement
        and understanding between you and us. Our failure to exercise or enforce
        any right or provision of these Terms of Service shall not operate as a
        waiver of such right or provision. These Terms of Service operate to the
        fullest extent permissible by law. We may assign any or all of our
        rights and obligations to others at any time. We shall not be
        responsible or liable for any loss, damage, delay, or failure to act
        caused by any cause beyond our reasonable control. If any provision or
        part of a provision of these Terms of Service is determined to be
        unlawful, void, or unenforceable, that provision or part of the
        provision is deemed severable from these Terms of Service and does not
        affect the validity and enforceability of any remaining provisions.
        There is no joint venture, partnership, employment, or agency
        relationship created between you and us as a result of these Terms of
        Service or use of the Site. You agree that these Terms of Service will
        not be construed against us by virtue of having drafted them. You hereby
        waive any and all defenses you may have based on the electronic form of
        these Terms of Service and the lack of signing by the parties hereto to
        execute these Terms of Service.
      </p>
      <h2>28. CONTACT US</h2>
      <p>
        To resolve a complaint regarding the Site or to receive further
        information regarding use of the Site, please contact us at:
      </p>
      <p>Compliancerisk.io, Inc.</p>
      <p>Dover, NH 03820</p>
      <p>United States</p>
      <p>legal@compliancerisk.io</p>
    </>
  );
};

export default TermsandConditions;
