import { useMemo, useState } from "react";

// react-table components
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";

import DefaultCell from "views/components/TableCells/DefaultCell";
import AssessmentEventsNameBtnTr from "./AssessmentEventsNameBtnTr";
import Autocomplete from "@mui/material/Autocomplete";

function AssessmentEventsTable({
  canSearch,
  entriesPerPage,
  showTotalEntries,
  table,
  isSorted,
  noEndBorder,
  takeTheAssessment,
  viewAssessmentVersionsPage,
}) {
  const status = {
    in_progress: "In Progress",
    completed: "Completed",
  };
  const objColumns = [
    {
      Header: "name",
      accessor: "name",
      Cell: ({ value, column, row }) => (
        <AssessmentEventsNameBtnTr
          value={value}
          column={column}
          row={row}
          takeTheAssessment={takeTheAssessment}
          viewAssessmentVersionsPage={viewAssessmentVersionsPage}
        />
      ),
    },
    {
      Header: "Version",
      accessor: "version",
      Cell: ({ value, column, row }) => (
        <DefaultCell value={value} column={column} row={row} />
      ),
    },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value, column, row }) => (
        <DefaultCell value={status[value] ?? ""} column={column} row={row} />
      ),
    },
    {
      Header: "risk level",
      accessor: "riskLevel",
      Cell: ({ value, column, row }) => (
        <DefaultCell value={value ?? ""} column={column} row={row} />
      ),
    },
    {
      Header: "created by",
      accessor: "createdBy",
      Cell: ({ value, column, row }) => (
        <DefaultCell value={value ?? ""} column={column} row={row} />
      ),
    },
    {
      Header: "date started",
      accessor: "startDate",
      Cell: ({ value, column, row }) => (
        <DefaultCell value={value ?? ""} column={column} row={row} />
      ),
    },
    {
      Header: "date completed",
      accessor: "completedOn",
      Cell: ({ value, column, row }) => (
        <DefaultCell value={value ?? ""} column={column} row={row} />
      ),
    },
  ];
  const columns = useMemo(() => objColumns, [table, takeTheAssessment]);
  const data = useMemo(() => table.rows, [table]);
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "25", "50", "100"];
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    setPageSize,
    setGlobalFilter,
    state: { pageSize, globalFilter },
  } = tableInstance;

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);
  const setEntriesPerPage = (value) => setPageSize(value);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Showing {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of AssessmentEventsTable
AssessmentEventsTable.defaultProps = {
  canSearch: false,
  showTotalEntries: true,
  isSorted: true,
  noEndBorder: false,
};

export default AssessmentEventsTable;
