import EditItemDialog from "./EditItemDialog";
import { useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import MDTypography from "components/MDTypography";

function AddToRiskMatrixBtn({ row, objUser }) {
  const [showEdit, setShowEdit] = useState(false);
  return (
    <>
      <MDButton
        onClick={() => setShowEdit(true)}
        variant="gradient"
        size="small"
        sx={{ background: "#007BFF" }}
      >
        <MDBox color="white" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="white"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            Risk Matrix
          </MDTypography>
        </MDBox>
      </MDButton>
      <EditItemDialog
        objUser={objUser}
        open={showEdit}
        row={row}
        onClose={() => setShowEdit(false)}
      />
    </>
  );
}

export default AddToRiskMatrixBtn;
