import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getPreviousSyncInformation,
  getConnectorAssets,
  importConnectorAssets,
  saveSyncFrequencySettings,
} from "features/lionGuard/actions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDTypography from "components/MDTypography";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import assetImageLogo from "assets/images/asset-preview.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { displayExceptionErrorMessage } from "utils/general";

const AssetSyncSection = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [frequencyType, setFrequencyType] = useState("");
  const [isLoadingSyncInfo, setIsLoadingSyncInfo] = useState(false);
  const [isSavingSyncSettings, setIsSavingSyncSettings] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [syncRecords, setSyncRecords] = useState(null);
  const [isFetchingAssets, setIsFetchingAssets] = useState(false);
  const [isImportingAssets, setIsImportingAssets] = useState(false);
  const [totalAssetsFetched, setTotalAssetsFetched] = useState(0);
  const [assetData, setAssetData] = useState([]);

  useEffect(() => {
    fetchPreviousSyncInformation();
  }, []);

  const fetchPreviousSyncInformation = async () => {
    setIsLoadingSyncInfo(true);
    dispatch(getPreviousSyncInformation())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingSyncInfo(false);
        const response = originalPromiseResult;
        setSyncRecords(response.data);
        setSelectedValue(
          response.data.frequencyOfSync == ","
            ? ""
            : response.data.frequencyOfSync
        );
        setFrequencyType(response.data.frequencyOfSync.split(",")[0]);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingSyncInfo(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const saveSyncSettings = async () => {
    setIsSavingSyncSettings(true);
    dispatch(
      saveSyncFrequencySettings({
        selectedValue,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsSavingSyncSettings(false);
        showMsg("success", "Sync frequency settings updated");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsSavingSyncSettings(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const fetchAssetsFromConnector = async () => {
    setIsFetchingAssets(true);
    dispatch(getConnectorAssets())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        const assetsResponseData = response.data;
        const totalAssets = response.data.length;

        if (assetsResponseData.length > 0) {
          setAssetData(assetsResponseData);
          setTotalAssetsFetched(totalAssets);
        } else {
          showMsg("info", "No assets were found");
        }

        setIsFetchingAssets(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingAssets(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const importAssets = async () => {
    setIsImportingAssets(true);
    let modifiedAssetData = assetData.map((asset, i) => {
      return {
        ID: asset.ID,
        Name: asset.Name,
        Environment: {
          ServiceProviderID: asset?.Environment?.ServiceProviderID,
          ExpiresOn: asset?.Environment?.ExpiresOn,
          LastKeyRotation: asset?.Environment?.LastKeyRotation,
        },
        Launchpoint: {
          ID: asset?.Launchpoint?.ID,
        },
        Inspector: {
          Name: asset?.Inspector?.Name,
          Logo: asset?.Inspector?.Logo,
          PublishedStatus: asset?.Inspector?.PublishedStatus,
        },
      };
    });

    dispatch(importConnectorAssets({ modifiedAssetData, totalAssetsFetched }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        navigate(
          `/asset-lists/${originalPromiseResult.data.finalPolicyObject.id}/${originalPromiseResult.data.updatedMajorVersion}`
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsImportingAssets(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const openAssetDocument = async () => {
    navigate(
      `/policies/${syncRecords.policyId}/${syncRecords.documentLastMajorVersion}`
    );
  };

  return (
    <>
      <DashboardLayout>
        <DashboardBreadcrumbs />
        <Grid my={3} container width="100%" spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox
              bgColor="success"
              borderRadius="lg"
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" color="white">
                {syncRecords ? syncRecords.clientName : "--"} using Liongard
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Card
              sx={{
                height: "100%",
                width: "100%",
                padding: "1rem",
                maxHeight: "600px",
              }}
            >
              <MDTypography variant="h6" color="success">
                Frequency of sync (select one option)
              </MDTypography>
              <Grid container>
                <Grid xs={6}>
                  <label htmlFor={"weekly"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="5px"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"weekly"}
                        name={"frequencyType"}
                        inputProps={{ "aria-label": "weekly" }}
                        onChange={() => {
                          setFrequencyType("weekly");
                        }}
                        id={"weekly"}
                        checked={frequencyType === "weekly"}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Weekly
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid xs={6}>
                  <label htmlFor={"monthly"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="5px"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"monthly"}
                        name={"frequencyType"}
                        inputProps={{ "aria-label": "monthly" }}
                        onChange={() => {
                          setFrequencyType("monthly");
                        }}
                        id={"monthly"}
                        checked={frequencyType === "monthly"}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Monthly
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid xs={6}>
                  <label htmlFor={"bimonthly"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="5px"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"bimonthly"}
                        name={"frequencyType"}
                        inputProps={{ "aria-label": "bimonthly" }}
                        onChange={() => {
                          setFrequencyType("bimonthly");
                        }}
                        id={"bimonthly"}
                        checked={frequencyType === "bimonthly"}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Bi-monthly
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
                <Grid xs={6}>
                  <label htmlFor={"quarterly"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="5px"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"quarterly"}
                        name={"frequencyType"}
                        inputProps={{ "aria-label": "quarterly" }}
                        onChange={() => {
                          setFrequencyType("quarterly");
                        }}
                        id={"quarterly"}
                        checked={frequencyType === "quarterly"}
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Quarterly
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
              </Grid>

              {frequencyType == "weekly" && (
                <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="Weekly">Weekly on</InputLabel>
                    <Select
                      sx={{ height: "40px" }}
                      labelId="Weekly"
                      label="Weekly"
                      id="Weekly"
                      value={selectedValue}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="weekly,Monday">Monday</MenuItem>
                      <MenuItem value="weekly,Tuesday">Tuesday</MenuItem>
                      <MenuItem value="weekly,Wednesday">Wednesday</MenuItem>
                      <MenuItem value="weekly,Thursday">Thursday</MenuItem>
                      <MenuItem value="weekly,Friday">Friday</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
              )}

              {frequencyType == "monthly" && (
                <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="Monthly">Monthly on</InputLabel>
                    <Select
                      sx={{ height: "40px" }}
                      labelId="Monthly"
                      label="Monthly"
                      id="Monthly"
                      value={selectedValue}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="monthly,1">
                        1st day of every Month
                      </MenuItem>
                      <MenuItem value="monthly,15">
                        15th day of every Month
                      </MenuItem>
                      <MenuItem value="monthly,31">
                        Last day of every Month
                      </MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
              )}

              {frequencyType == "bimonthly" && (
                <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="Bimonthly">Bi-Monthly on</InputLabel>
                    <Select
                      sx={{ height: "40px" }}
                      labelId="Bimonthly"
                      label="Bimonthly"
                      id="Bimonthly"
                      value={selectedValue}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="bimonthly,1">
                        1st day every two Months
                      </MenuItem>
                      <MenuItem value="bimonthly,15">
                        15th day every two Months
                      </MenuItem>
                      <MenuItem value="bimonthly,31">
                        Last day every two months
                      </MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
              )}

              {frequencyType == "quarterly" && (
                <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="Quarterly">Quarterly on</InputLabel>
                    <Select
                      sx={{ height: "40px" }}
                      labelId="Quarterly"
                      label="Quarterly"
                      id="Quarterly"
                      value={selectedValue}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="quarterly,1">
                        1st day of January, April, July and October
                      </MenuItem>
                      <MenuItem value="quarterly,15">
                        15th day of January, April, July and October
                      </MenuItem>
                      <MenuItem value="quarterly,31">
                        Last day of January, April, July and October
                      </MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
              )}

              {isSavingSyncSettings || isLoadingSyncInfo ? (
                <MDBox mt={3}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <MDButton
                  type="submit"
                  color="success"
                  disabled={syncRecords && syncRecords.lastSyncAt == null}
                  onClick={saveSyncSettings}
                  sx={{ padding: "12px 20px", marginTop: "1rem" }}
                >
                  {syncRecords && syncRecords.lastSyncAt == null
                    ? "Initial asset sync required"
                    : "Save Sync Frequency Settings"}
                </MDButton>
              )}

              {syncRecords ? (
                <>
                  {" "}
                  <MDTypography variant="h6" color="dark" mt={4}>
                    Last sync:{" "}
                    {syncRecords.lastSyncAt
                      ? syncRecords.lastSyncAt
                      : "No initial sync yet"}
                  </MDTypography>
                  <MDTypography variant="h6" color="dark" mt={1}>
                    {syncRecords.numberOfAssetsSynced
                      ? "Synced " +
                        syncRecords.numberOfAssetsSynced +
                        " asset records"
                      : "No records synced"}
                  </MDTypography>
                  <MDTypography variant="h6" color="success" mt={1}>
                    {syncRecords.documentLastMajorVersion ? (
                      <MDButton
                        onClick={openAssetDocument}
                        variant="gradient"
                        color="dark"
                      >
                        View document
                      </MDButton>
                    ) : (
                      // </Link>
                      "N/A"
                    )}
                  </MDTypography>
                </>
              ) : (
                ""
              )}
            </Card>
            <></>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card
              sx={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {assetData.length == 0 ? (
                <>
                  {/* preview assets image and button */}
                  <MDBox mb={2}>
                    <img src={assetImageLogo} width="90px" alt="" />
                  </MDBox>
                  <MDBox>
                    <MDButton
                      type="submit"
                      // variant="gradient"
                      color="success"
                      onClick={fetchAssetsFromConnector}
                      sx={{ padding: "12px 20px", margin: "0px 5px" }}
                    >
                      Sync | Preview and Import System Assets
                    </MDButton>
                  </MDBox>
                  {isFetchingAssets ? (
                    <MDBox mt={2}>
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="lg"
                      />
                    </MDBox>
                  ) : (
                    ""
                  )}
                  {/* preview assets image and button end*/}
                </>
              ) : (
                <>
                  <TableContainer sx={{ padding: "1rem" }}>
                    {isImportingAssets ? (
                      <LoadingSpinner color="success" size="lg" />
                    ) : (
                      <MDButton
                        type="submit"
                        color="success"
                        onClick={importAssets}
                        sx={{ padding: "12px 20px", margin: "0px 5px" }}
                      >
                        Import Assets
                      </MDButton>
                    )}
                    <Table>
                      <TableRow>
                        <TableCell>
                          <b>System ID</b>
                        </TableCell>
                        <TableCell>
                          <b>System Name</b>
                        </TableCell>
                        <TableCell>
                          <b>Service Provider ID</b>
                        </TableCell>
                        <TableCell>
                          <b>Launchpoint ID</b>
                        </TableCell>
                        <TableCell>
                          <b>Published Status</b>
                        </TableCell>
                        <TableCell>
                          <b>Environment Expires On</b>
                        </TableCell>
                        <TableCell>
                          <b>Last Key Rotation</b>
                        </TableCell>
                      </TableRow>

                      <TableBody>
                        {assetData.map((asset, i) => {
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {asset.ID ?? "-"}
                              </TableCell>
                              <TableCell>{asset.Name ?? "-"}</TableCell>
                              <TableCell component="th" scope="row">
                                {asset.Environment
                                  ? asset.Environment.ServiceProviderID
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {asset.Launchpoint ? asset.Launchpoint.ID : "-"}
                              </TableCell>

                              <TableCell>
                                {asset.Inspector
                                  ? asset.Inspector.PublishedStatus
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {asset.Environment
                                  ? asset.Environment.ExpiresOn
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {asset.Environment
                                  ? asset.Environment.LastKeyRotation
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  );
};

export default AssetSyncSection;
