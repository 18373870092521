import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
const CampaignNameBtrTr = (props) => {
  const { value, row, onClick } = props;

  return (
    <MDButton variant="outlined" onClick={() => onClick(row.original)}>
      <MDBox color="success" display="flex" alignItems="center">
        <MDTypography
          variant="text"
          fontWeight="medium"
          color="success"
          sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
        >
          {value}
        </MDTypography>
      </MDBox>
    </MDButton>
  );
};

export default CampaignNameBtrTr;
