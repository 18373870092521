import DefaultCell from "views/components/TableCells/DefaultCell";
const DataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Latest Version",
      accessor: "version",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Last modified",
      accessor: "last_modified",
      width: "30%",
    },
    {
      Header: "Created by",
      accessor: "created_by",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default DataTableData;
