// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";

// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";

const EditCompanyUserFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue, objStates } = props;
  return (
    <Fragment>
      <Grid container spacing={5} justifyContent="space-between" p={6}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Name"
                name="txtName"
                value={values.txtName}
                placeholder={"Name"}
                error={errors.txtName && touched.txtName}
                success={values.txtName.length > 0 && !errors.txtName}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Phone Number"
                name="txtPhoneNumber"
                value={values.txtPhoneNumber}
                placeholder={"Phone Number"}
                error={errors.txtPhoneNumber && touched.txtPhoneNumber}
                success={
                  values.txtPhoneNumber.length > 0 && !errors.txtPhoneNumber
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <FormField
                  type="text"
                  label="Email"
                  name="txtEmail"
                  value={values.txtEmail}
                  placeholder={"Email"}
                  error={errors.txtEmail && touched.txtEmail}
                  success={values.txtEmail.length > 0 && !errors.txtEmail}
                  disabled={true}
                />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Last Name"
                name="txtLastName"
                value={values.txtLastName}
                placeholder={"Last Name"}
                error={errors.txtLastName && touched.txtLastName}
                success={values.txtLastName?.length > 0 && !errors.txtLastName}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <FormField
                type="text"
                label="Role"
                name="txtRole"
                value={values.txtRole}
                placeholder={"Role"}
                error={errors.txtRole && touched.txtRole}
                success={values.txtRole.length > 0 && !errors.txtRole}
                disabled={true}
              /> */}
              <Autocomplete
                disableClearable
                value={values.txtRole}
                disabled={values.txtRole == "client_user"}
                options={["admin", "company_user"]}
                getOptionLabel={(option) => (option ? option : "")}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="Role"
                    fullWidth
                  />
                )}
                onChange={(event, value) => {
                  if (value === null) {
                    setFieldValue("txtRole", "");
                  } else {
                    setFieldValue("txtRole", value);
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EditCompanyUserFormikWrapper;
