import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BACKEND_API_URL,
  USER_SERVER,
  VERSION_SERVER,
} from "components/utils/constants/misc";

/**
 * Deprecated. App uses Auth0 for login.
 */
export const userLogin = createAsyncThunk(
  "user/login",
  async (values, thunkApi) => {
    return false;
    try {
      // configure header's Content-Type as JSON
      const res = await axios.post(
        `${BACKEND_API_URL}${USER_SERVER}/login`,
        values
      );
      // return res.data;
      // localStorage.setItem('userToken', data.userToken)
      return res;

      // store user's token in local storage
    } catch (error) {
      // return custom error message from API if any
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**
 * Deprecated. App uses Auth0 for signup.
 */
export const registerUser = createAsyncThunk(
  "user/register",
  async (values, thunkApi) => {
    return false;
    try {
      // const config = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };
      const res = await axios.post(
        `${BACKEND_API_URL}${USER_SERVER}/register`,
        values
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
      // if (error.response && error.response.data.message) {
      //   return thunkApi.rejectWithValue(error.response.data.message);
      // } else {
      //   return thunkApi.rejectWithValue(error.message);
      // }
    }
  }
);

/**
 * App auth function. This is called on each page request.
 * Sends access token to backend api to validate it and returns app user data.
 */
export const auth = createAsyncThunk("user/auth", async (values, thunkApi) => {
  try {
    // const config = {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // };

    const res = await axios.get(`${BACKEND_API_URL}/api/v3/auth/users`);
    // return res.data;
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
    // if (error.response && error.response.status === 429) {
    //   // Retry the request after a delay
    //   await new Promise((resolve) => setTimeout(resolve, 2000));
    //   return thunkApi.dispatch(auth());
    // } else {
    //   // Reject with error if it's not a 429 error
    //   return thunkApi.rejectWithValue(error);
    // }
    // if (error.response && error.response.data.message) {
    //   return thunkApi.rejectWithValue(error.response.data.message);
    // } else {
    //   return thunkApi.rejectWithValue(error.message);
    // }
  }
});

/**
 * Deprecated. App uses Auth0 for logout.
 */
export const logout = createAsyncThunk(
  "user/logout",
  async (values, thunkApi) => {
    return false;
    // try {
    //   // const config = {
    //   //   headers: {
    //   //     "Content-Type": "multipart/form-data",
    //   //   },
    //   // };
    //   const res = await axios.post(
    //     `${BACKEND_API_URL}${VERSION_SERVER}/auth/logout`,
    //     values
    //   );
    //   // return res.data;
    //   return res.data;
    //   // return Promise.resolve(res.data);
    // } catch (error) {
    //   return thunkApi.rejectWithValue(error);
    //   // if (error.response && error.response.data.message) {
    //   //   return thunkApi.rejectWithValue(error.response.data.message);
    //   // } else {
    //   //   return thunkApi.rejectWithValue(error.message);
    //   // }
    // }
  }
);

export const resendVerificationEmail = createAsyncThunk(
  "user/resendVerificationEmail",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${USER_SERVER}/users/resend-verification-email`
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const verifiyEmailAddress = createAsyncThunk(
  "user/verifiyEmailAddress",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${USER_SERVER}/verify-email`,
        values
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const authUpdateUser = createAsyncThunk(
  "user/authUpdateUser",
  async (values, thunkApi) => {
    try {
      const { objPostData, objUser } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/users/${objPostData.id}`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateAuthUserData = createAsyncThunk(
  "user/updateAuthUserData",
  async (values, thunkApi) => {
    try {
      const objUserData = {
        status: "success",
        user: values,
        isAuth: true,
        authorized: true,
        error: false,
      };
      return objUserData;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
