import DefaultCell from "views/components/TableCells/DefaultCell";
import CustomerStatusBtnTr from "./CustomerStatusBtnTr";
const CustomerManagementDataTableData = {
  columns: [
    {
      Header: "name",
      accessor: "companyName",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "company email",
      accessor: "companyEmail",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "company phone",
      accessor: "companyPhone",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "date joined",
      accessor: "dateJoined",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ value, column, row }) => (
        <CustomerStatusBtnTr value={value} column={column} row={row} />
      ),
    },
  ],
  rows: [],
};

export default CustomerManagementDataTableData;
