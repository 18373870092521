import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { createNewTemplate } from "features/company/assessmentsActions.js";
import { get } from "features/assessmentConfig/assessmentConfigActions";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
const objInitialValues = { txtTemplateTitle: "", type: {id: "",name: ""}, txtDescription: "" };
const AddNewTemplateFormJWTSchema = Yup.object().shape({
  txtTemplateTitle: Yup.string().required("The Name is required."),
  type: Yup.object().shape({
    id: Yup.string().required("The Type is required."),
    name: Yup.string()
  }),
});

function CreateNewTemplateDialog({ open, onClose, onSubmit, objUser }) {
  const [isLoading, setIsLoading] = useState(false);
  const [ types, setTypes ] = useState([]);

  const dispatch = useDispatch();
  const onSubmitForm = async (values) => {
    const objPostData = {
      name: values.txtTemplateTitle,
      type: values.type.id,
      description: values.txtDescription,
    };
    setIsLoading(true);
    dispatch(createNewTemplate({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit(response.data.id, response.data.name);
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
      });
  };
  const fetchTypes = async () => {
    setIsLoading(true);
    dispatch(get({ _per_page:100, _page:1, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const types = [{
          id: "",
          name: "",
        }].concat(originalPromiseResult?.data?.assessment_types?.map((type) => {
          return {
            id: type.id,
            name: type.name,
          };
        }) ?? []);

        setTypes(types);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", "Something went wrong loading the template types. Please try again.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchTypes();
  }, []);
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Add New Document</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewTemplateFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddNewTemplateForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Name"
                  name="txtTemplateTitle"
                  value={values.txtTemplateTitle}
                  placeholder={"Template name"}
                  error={errors.txtTemplateTitle && touched.txtTemplateTitle}
                  success={
                    values.txtTemplateTitle.length > 0 &&
                    !errors.txtTemplateTitle
                  }
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtTemplateTitle" />
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Description"
                  name="txtDescription"
                  value={values.txtDescription}
                  placeholder={"Description"}
                  error={errors.txtDescription && touched.txtDescription}
                  success={
                    values.txtDescription.length > 0 &&
                    !errors.txtDescription
                  }
                  as={MDInput}
                  variant="standard"
                  fullWidth
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtDescription" />
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mb={0} mt={0}>
                <Autocomplete
                  disableClearable
                  name="type"
                  options={types}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="standard"
                      label="Type"
                      name="type"
                      fullWidth
                    />
                  )}
                  value={values.type}
                  onChange={(event, newValue) => {
                    setFieldValue("type", newValue);
                  }}
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="type.id" />
                  </MDTypography>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Add
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default CreateNewTemplateDialog;
