import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Fragment } from "react";
import "./CreatePolicyDocumentStepReviewFormikWrapper.css";
import { ErrorMessage } from "formik";

const CreatePolicyDocumentStepReviewFormikWrapper = (props) => {
  const { setFieldValue, objPolicyDocument } = props;

  let approverOptions = [];
  let reviewerOptions = [];
  let legalOptions = [];

  const audiencesURL = `/audiences`;

  if (
    typeof objPolicyDocument.policy.extra !== "undefined" &&
    typeof objPolicyDocument.policy.extra.action_select_options !== "undefined"
  ) {
    if (
      typeof objPolicyDocument.policy.extra.action_select_options
        .POLICY_APPROVERS !== "undefined" &&
      Array.isArray(
        objPolicyDocument.policy.extra.action_select_options.POLICY_APPROVERS
      ) &&
      objPolicyDocument.policy.extra.action_select_options.POLICY_APPROVERS
        .length > 0
    ) {
      approverOptions =
        objPolicyDocument.policy.extra.action_select_options.POLICY_APPROVERS;
    }
  }

  if (
    typeof objPolicyDocument.policy.extra !== "undefined" &&
    typeof objPolicyDocument.policy.extra.action_select_options !== "undefined"
  ) {
    if (
      typeof objPolicyDocument.policy.extra.action_select_options
        .POLICY_REVIEWERS !== "undefined" &&
      Array.isArray(
        objPolicyDocument.policy.extra.action_select_options.POLICY_REVIEWERS
      ) &&
      objPolicyDocument.policy.extra.action_select_options.POLICY_REVIEWERS
        .length > 0
    ) {
      reviewerOptions =
        objPolicyDocument.policy.extra.action_select_options.POLICY_REVIEWERS;
    }
  }

  if (
    typeof objPolicyDocument.policy.extra !== "undefined" &&
    typeof objPolicyDocument.policy.extra.action_select_options !== "undefined"
  ) {
    if (
      typeof objPolicyDocument.policy.extra.action_select_options
        .POLICY_LEGAL_REVIEWERS !== "undefined" &&
      Array.isArray(
        objPolicyDocument.policy.extra.action_select_options
          .POLICY_LEGAL_REVIEWERS
      ) &&
      objPolicyDocument.policy.extra.action_select_options
        .POLICY_LEGAL_REVIEWERS.length > 0
    ) {
      legalOptions =
        objPolicyDocument.policy.extra.action_select_options
          .POLICY_LEGAL_REVIEWERS;
    }
  }

  // Populate fields when reviewers are set already
  let defaultApprover = "";
  let defaultReviewers = [];
  let defaultLegal = "";
  let disabledFields = false;

  if (
    (typeof objPolicyDocument.state !== "undefined" &&
      objPolicyDocument.state != "In Review" &&
      typeof objPolicyDocument.reviewers !== "undefined") ||
    objPolicyDocument.is_max_major_version == false
  ) {
    disabledFields = true;

    if (
      typeof objPolicyDocument.reviewers.approval !== "undefined" &&
      Array.isArray(objPolicyDocument.reviewers.approval) &&
      objPolicyDocument.reviewers.approval.length > 0
    ) {
      defaultApprover = objPolicyDocument.reviewers.approval[0];
    }

    if (
      typeof objPolicyDocument.reviewers.review !== "undefined" &&
      Array.isArray(objPolicyDocument.reviewers.review) &&
      objPolicyDocument.reviewers.review.length > 0
    ) {
      defaultReviewers = objPolicyDocument.reviewers.review;
    }

    if (
      typeof objPolicyDocument.reviewers.legal !== "undefined" &&
      Array.isArray(objPolicyDocument.reviewers.legal) &&
      objPolicyDocument.reviewers.legal.length > 0
    ) {
      defaultLegal = objPolicyDocument.reviewers.legal[0];
    }
  }

  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDBox px={5} textAlign="left" mx="auto" mt={4}>
          <Grid container justifyContent="space-between" columnSpacing={2}>
            <Grid item xs={12} pt={0}>
              <MDBox mb={0}>
                <MDBox mb={0}>
                  <MDTypography variant="h5" fontWeight="bold">
                    Review{" "}
                    {objPolicyDocument.policy.policy_type == "asset"
                      ? "Asset Lists"
                      : "Policies"}{" "}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={0} pt={2} px={5} pb={5}>
          <Grid container justifyContent="space-between" columnSpacing={8}>
            <Grid item xs={12} lg={6} pt={0}>
              <Grid container>
                {/* Approver */}
                <Grid item xs={12} pt={0}>
                  <MDBox mb={3}>
                    <MDBox mb={2} display="inline-block">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize"
                      >
                        Approver
                      </MDTypography>
                    </MDBox>
                    <Autocomplete
                      disabled={disabledFields}
                      defaultValue={defaultApprover}
                      options={approverOptions}
                      renderInput={(params) => (
                        <MDInput {...params} variant="standard" />
                      )}
                      onChange={(event, value) => {
                        if (value === null) {
                          setFieldValue("approvers", "");
                        } else {
                          setFieldValue("approvers", value);
                        }
                      }}
                    />
                    <MDBox mt={0.75}>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="error"
                        fontWeight="regular"
                      >
                        <ErrorMessage
                          name="approvers"
                          render={(msg) => {
                            return <div>Approver required.</div>;
                          }}
                        />
                      </MDTypography>
                    </MDBox>

                    <Fragment>
                      {approverOptions.length === 0 ? (
                        <MDBox mb={2}>
                          <MDTypography
                            fontWeight="bold"
                            sx={{ marginLeft: "0" }}
                            color="dark"
                            variant="caption"
                          >
                            Add approvers in the{" "}
                            <a className="tooltip-link" href={audiencesURL}>
                              Audiences Page
                            </a>
                          </MDTypography>
                        </MDBox>
                      ) : null}
                    </Fragment>
                  </MDBox>
                </Grid>

                {objPolicyDocument.policy.policy_type === "default" ? (
                  <>
                    {/* Reviewers */}
                    <Grid item xs={12} pt={0}>
                      <MDBox mb={3}>
                        <MDBox mb={2} display="inline-block">
                          <MDTypography
                            component="label"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            textTransform="capitalize"
                          >
                            Reviewers
                          </MDTypography>
                        </MDBox>
                        <Autocomplete
                          disabled={disabledFields}
                          defaultValue={defaultReviewers}
                          multiple
                          id="tags-standard"
                          options={reviewerOptions}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" />
                          )}
                          onChange={(event, value) => {
                            if (value.length === 0) {
                              setFieldValue("reviewers", "");
                            } else {
                              setFieldValue("reviewers", value);
                            }
                          }}
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage
                              name="reviewers"
                              render={(msg) => {
                                return <div>Reviewer required.</div>;
                              }}
                            />
                          </MDTypography>
                        </MDBox>
                        <Fragment>
                          {reviewerOptions.length === 0 ? (
                            <MDBox mb={2}>
                              <MDTypography
                                fontWeight="bold"
                                sx={{ marginLeft: "0" }}
                                color="dark"
                                variant="caption"
                              >
                                Add reviewers in the{" "}
                                <a className="tooltip-link" href={audiencesURL}>
                                  Audiences Page
                                </a>
                              </MDTypography>
                            </MDBox>
                          ) : null}
                        </Fragment>
                      </MDBox>
                    </Grid>

                    {/* legal */}
                    <Grid item xs={12} pt={0}>
                      <MDBox mb={3}>
                        <MDBox mb={2} display="inline-block">
                          <MDTypography
                            component="label"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            textTransform="capitalize"
                          >
                            Legal Reviewer (Optional)
                          </MDTypography>
                        </MDBox>
                        <Autocomplete
                          disabled={disabledFields}
                          defaultValue={defaultLegal}
                          options={legalOptions}
                          renderInput={(params) => (
                            <MDInput {...params} variant="standard" />
                          )}
                          onChange={(event, value) => {
                            console.log(
                              "value in Legal Reviewer Autocomplete",
                              value
                            );
                            if (value === null) {
                              setFieldValue("legalReviewers", "");
                            } else {
                              setFieldValue("legalReviewers", value);
                            }
                          }}
                        />

                        <Fragment>
                          {legalOptions.length === 0 ? (
                            <MDBox mb={2}>
                              <MDTypography
                                fontWeight="bold"
                                sx={{ marginLeft: "0" }}
                                color="dark"
                                variant="caption"
                              >
                                Add legal reviewers in the{" "}
                                <a className="tooltip-link" href={audiencesURL}>
                                  Audiences Page
                                </a>
                              </MDTypography>
                            </MDBox>
                          ) : null}
                        </Fragment>
                      </MDBox>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {/* <SelectReviewersMultiple /> */}
    </Fragment>
  );
};

export default CreatePolicyDocumentStepReviewFormikWrapper;
