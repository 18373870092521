/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import AssessmentCharts from "views/components/GameCharts/AssessmentCharts";
import AssetTable from "views/components/GameCharts/AssetTable";
import PolicyCharts from "views/components/GameCharts/PolicyCharts";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import "./dashboard-styles.css";

import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import { Link } from "react-router-dom";
import DashboardButton from "views/components/DashboardButtton";
import InsuranceCharts from "views/components/GameCharts/InsuranceCharts";
import SatCharts from "views/components/GameCharts/SatCharts";
function PolygonDashboard({ objUser }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [cookies, setCookie] = useCookies(["hasSeenHolidayMsg"]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const onClose = () => {
    setCookie("hasSeenHolidayMsg", "yes", {
      path: "/",
    });
    setOpen(false);
  };

  useEffect(() => {
    if (objUser.user.role.name === "peer_group_admin") {
      navigate(`/peer-group`);
    }
    if (objUser.user.role.name === "auditor") {
      navigate(`/auditor-reports`);
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs objUser={objUser} />

      <Grid container>
        <Grid item xs={6}>
          <MDTypography
            sx={{
              marginBottom: "0.5rem",
            }}
            fontWeight="bold"
            variant="h4"
            color="dark"
          >
            Welcome to Compliance Scorecard!
          </MDTypography>
        </Grid>
        {!objUser.user?.company?.has_peer_group_subscription ||
          typeof objUser.user?.company?.has_peer_group_subscription ===
            "undefined" ||
          (objUser.user?.company?.has_peer_group_subscription === false && (
            <Grid item xs={6}>
              <MDBox
                textAlign="right"
                sx={{ marginTop: "-20px", marginBottom: "20px" }}
              >
                <DashboardButton
                  component={Link}
                  to={`/billing`}
                  btnIcon="add"
                  btnText="Signup for Peer Group"
                  textColor="white"
                  bgColor="dark"
                />
              </MDBox>
            </Grid>
          ))}
      </Grid>
      <Grid spacing={2} container>
        {/* gamification */}
        <Grid item xs={12} lg={12}>
          <Grid spacing={2} container>
            {/* Assessment Scorecard  */}
            <Grid item xs={12}>
              <AssessmentCharts objUser={objUser} />
            </Grid>

            {/* Asset Management Scorecard */}
            <Grid item xs={12}>
              <AssetTable objUser={objUser} />
            </Grid>

            {/* Policy Scorecard */}
            <Grid item xs={12}>
              <PolicyCharts objUser={objUser} darkMode={darkMode} />
            </Grid>

            {/* Insurance Scorecard  */}
            <Grid item xs={12}>
              <InsuranceCharts objUser={objUser} />
            </Grid>

            {/* SAT Scorecard  */}
            <Grid item xs={12}>
              <SatCharts objUser={objUser} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default PolygonDashboard;
