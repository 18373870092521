/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_ENDPOINT } from "components/utils/constants/misc";

const getCompanyID = (objUser) => {
  return objUser.user.company_id;
};

export const getAdoptionCampaigns = createAsyncThunk(
  "policy/get-campaigns",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/adoption-campaigns/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPolicies = createAsyncThunk(
  "company/getPolicies",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/adoption-campaigns/policies/all-published`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAudiences = createAsyncThunk(
  "company/getAudiences",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/adoption-campaigns/audiences/adoption`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewAdoptionCampaign = createAsyncThunk(
  "policy/create-campaign",
  async (values, thunkApi) => {
    try {
      const {
        objUser,
        selectedAudience,
        campaignName,
        campaignDescription,
        reminderTime,
        sections,
      } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/adoption-campaigns/`,
        {
          selectedAudience,
          campaignName,
          campaignDescription,
          reminderTime,
          sections,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const _deleteAdoptionCampaign = createAsyncThunk(
  "policy/delete-adoption-campaign",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/adoption-campaigns/delete/selected`,
        {
          ...dataFromActionBtn,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const loadAdoptionCampaign = createAsyncThunk(
  "policy/load-campaign",
  async (values, thunkApi) => {
    try {
      const { objUser, campaignId } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/campaigns/${campaignId}/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const resendCampaignToAllNotAdopted = createAsyncThunk(
  "policy/resend-campaign-to-all",
  async (values, thunkApi) => {
    try {
      const { objUser, campaignId } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/adoption-campaigns/${campaignId}/resend-to-all`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const exportAdoptersListToCsv = createAsyncThunk(
  "policy/export-list-to-csv",
  async (values, thunkApi) => {
    try {
      const { objUser, campaignId, campaignData } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/adoption-campaigns/${campaignId}/export-adopters-list-to-csv`,
        {
          campaignData,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
