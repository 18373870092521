import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import EditConnectWiseClientForm from "./components/EditConnectWiseClientForm";

const EditConnectWiseClient = (props) => {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      {/* <Card> */}
      <Grid my={3} container justifyContent="center" alignItems="center" width="100%">
        <Grid item xs={12} lg={12}>
          <EditConnectWiseClientForm objUser={props.objUser} />
        </Grid>
      </Grid>
      {/* </Card> */}
    </DashboardLayout>
  );
};

export default EditConnectWiseClient;
