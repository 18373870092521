import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import { useMemo } from "react";

const StatusTr = (props) => {
  const { value, column, row } = props;

  return (
    <>
      {row.original.first_msp_contact === row.original.id ? (
        <MDBadge badgeContent="MSP" size="sm" color="dark" />
      ) : (
        <>
          {row.original.member === true ? (
            <MDBadge badgeContent="User" size="sm" color="success" />
          ) : (
            <MDBadge badgeContent="Contact" size="sm" color="info" />
          )}
        </>
      )}
    </>
  );
};

export default StatusTr;
