import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  BACKEND_API_URL,
  CLIENTS_SERVER,
  VERSION_SERVER,
} from "components/utils/constants/misc";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showMsg } from "utils/general";
import { storeClientContactByClientId } from "features/company/clientContactsActions.js";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import { Autocomplete, FormControl, TextField } from "@mui/material";

const RetrieveTenantIdFromMsGraphMSTeams = (props) => {
  const { objUser } = props;
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const state = JSON.parse(query.get("state"));
  const routeDictionary = state.routeDictionary;
  const target = state.target;
  const [authCode, setAuthCode] = useState(query.get("code"));
  const [loading, setLoading] = useState(false);
  const [connectionSet, setConnectionSet] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [membersToAdd, setMembersToAdd] = useState([]);

  const membersAutocompleteGetLabelFunction = useCallback(
    (option) =>
      option?.displayName ??
      membersList.find((member) => member.id === option)?.displayName ??
      "",
    [membersList]
  );

  const membersAutocompleteOnChangeFunction = useCallback((e, value) => {
    let formattedValue = value.map((val) => {
      return val;
    });

    formattedValue = Array.from(new Set(formattedValue));
    setMembersToAdd(value);
  }, []);

  const saveMentions = async () => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/notification-center/save-mentions`,
        {
          type: "msteams",
          mentions: membersToAdd,
        }
      );
      setLoading(false);
      showMsg("success", "Mentions added successfully");
      navigate("/notification-center", { state: { routeDictionary } });
    } catch (error) {
      setLoading(false);
      if (error.response.data.message) {
        showMsg("error", error.response.data.message);
      } else {
        showMsg("error", "Something went wrong");
      }
    }
  };

  useEffect(() => {
    if (authCode) {
      setLoading(true);
      const saveMsGraphTenantId = async () => {
        try {
          /**
           * This is not actually saving the auth code
           * Instead, we are passing the authcode to the backend, and using that to retrieve
           * and store a refresh token for all susequent calls
           */
          const res = await axios.post(
            `${BACKEND_API_URL}${VERSION_SERVER}/ms-graph/save-auth-code`,
            {
              auth_code: authCode,
              client_id: state.client_id,
              target,
              team_id: state.team_id,
              channel_id: state.channel_id,
              redirect_uri:
                process.env.REACT_APP_MS_GRAPH_REDIRECT_URI_FOR_MSTEAMS,
            }
          );
          setMembersList(res.data.value);
          setLoading(false);
          setConnectionSet(true);
          showMsg("success", "Connection completed successfully");
        } catch (error) {
          setLoading(false);
          if (error.response.data.message) {
            showMsg("error", error.response.data.message);
          } else {
            showMsg("error", "Something went wrong");
          }
        }
      };
      saveMsGraphTenantId();
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox>
        <Card>
          <MDBox p={2}>
            {loading ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            ) : (
              <>
                {authCode && connectionSet ? (
                  <>
                    <p style={{ textAlign: "center" }}>
                      MSTeams notifications configured successfully
                    </p>
                    {/* <Box display="flex" gap={1} alignItems="center"> */}
                    <FormControl sx={{ width: "100%", marginTop: "18px" }}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={membersList}
                        getOptionLabel={membersAutocompleteGetLabelFunction}
                        // defaultValue={formattedCurrentProcedureStatementValues}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            // label={
                            //   max_points
                            //     ? "* Procedure Statements"
                            //     : "Procedure Statements"
                            // }
                            label={
                              "Add channel members to mention in notifications"
                            }
                            placeholder="Channel Members"
                          />
                        )}
                        onChange={membersAutocompleteOnChangeFunction}
                      />
                    </FormControl>
                    {/* </Box> */}
                    <MDBox
                      p={2}
                      display="flex"
                      justifyContent="flex-end"
                      gap={1}
                    >
                      <Link to={"/notification-center"}>
                        <MDButton variant="contained" color="info">
                          Go back
                        </MDButton>
                      </Link>

                      <MDButton
                        // variant="gradient"
                        color="success"
                        onClick={saveMentions}
                        disabled={membersToAdd.length == 0}
                      >
                        Add
                      </MDButton>
                    </MDBox>
                  </>
                ) : (
                  <>
                    <p style={{ textAlign: "center", color: "red" }}>
                      Connection was not successful. Please try again
                    </p>
                  </>
                )}
              </>
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};
export default RetrieveTenantIdFromMsGraphMSTeams;
