import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardActionButton from "views/components/DashboardActionButton";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import CreateClientContactByClientIdForm from "./components/CreateClientContactByClientIdForm";
import DashboardButton from "views/components/DashboardButtton";

const CreateClientContactByClientId = (props) => {
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  // const importContactsFromMsGraph = () => {
  //   const msgraphOathState = JSON.stringify({ id, routeDictionary });
  //   const msgraphOathUrl =
  //     "https://login.microsoftonline.com/common/adminconsent" +
  //     `?client_id=${process.env.REACT_APP_MS_GRAPH_APP_ID}` +
  //     `&state=${encodeURI(msgraphOathState)}` +
  //     `&redirect_uri=${process.env.REACT_APP_MS_GRAPH_REDIRECT_URI}`;
  //   window.location.href = msgraphOathUrl;
  // };
  // const importContactsFromConnectWise = () => {
  //   navigate("/clients/:id/contacts/import-from-connectwise");
  // };

  const GoToMSGraphAutoContactsImportConfigSection = () => {
    navigate(`/contacts/ms-graph-contacts-auto-import-config`);
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox
        display="flex"
        gap=".6rem"
        alignItems="flex-start"
        justifyContent="right"
        mb={2}
      >
        {/* <DashboardActionButton
          component={Link}
          action={importContactsFromMsGraph}
          btnText="Import contacts from Microsoft Graph"
          btnIcon="add"
          textColor="white"
          bgColor="success"
        /> */}
        <DashboardActionButton
          component={Link}
          action={GoToMSGraphAutoContactsImportConfigSection}
          btnText="Microsoft Graph Contacts Import Config"
          btnIcon="settings"
          textColor="white"
          bgColor="success"
        />
        {/* <DashboardButton
          component={Link}
          to={`/clients/${id}/audiences/`}
          // state={{ routeDictionary }}
          btnText="Manage Audiences"
          btnIcon="peopleAlt"
          textColor="white"
          bgColor="dark"
        /> */}
        {/* <DashboardButton
          component={Link}
          to={`/clients/${id}/policies/`}
          // state={{ routeDictionary }}
          btnText="Manage Policies"
          btnIcon="description"
          textColor="white"
          bgColor="dark"
        />
        <DashboardButton
          component={Link}
          to={`/clients/${id}/templates/`}
          // state={{ routeDictionary }}
          btnText="Policy Templates"
          btnIcon="content_copy"
          textColor="white"
          bgColor="dark"
        /> */}
      </MDBox>
      {/* <Card> */}
      <Grid
        my={3}
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Grid item xs={12} lg={12}>
          <CreateClientContactByClientIdForm objUser={props.objUser} />
        </Grid>
      </Grid>
      {/* </Card> */}
    </DashboardLayout>
  );
};

export default CreateClientContactByClientId;
