import { createSlice } from "@reduxjs/toolkit";
import { storeCompany, setPersonalCompany } from "./userSignUpFunnelActions";

// initialize userToken from local storage
// const userToken = localStorage.getItem("userToken") ? localStorage.getItem("userToken") : null;

const initialState = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
};

const userSignUpFunnelSlice = createSlice({
  name: "userSignUpFunnel",
  initialState,
  reducers: {
    // signupUser: (state,action) => {
    //   localStorage.removeItem("userToken"); // delete token from storage
    //   state.loading = false;
    //   state.userInfo = null;
    //   state.userToken = null;
    //   state.error = null;
    // },
  },
  extraReducers: {
    // Store Company
    [storeCompany.pending]: (state) => {
      state.loading = true;
      state.objSignUpFunnelStoreCompanyFormSuccess = null;
    },
    [storeCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objSignUpFunnelStoreCompanyFormSuccess = payload;
    },
    [storeCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objSignUpFunnelStoreCompanyFormSuccess = payload;
    },
    // setPersonalCompany
    [setPersonalCompany.pending]: (state) => {
      state.loading = true;
      state.objSignUpFunnelSetPersonalCompanyFormSuccess = null;
    },
    [setPersonalCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objSignUpFunnelSetPersonalCompanyFormSuccess = payload;
    },
    [setPersonalCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objSignUpFunnelSetPersonalCompanyFormSuccess = payload;
    },
  },
});

// export const { logout } = userSignUpFunnelSlice.actions;

export default userSignUpFunnelSlice.reducer;
