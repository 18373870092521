import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
const NameBtnTr = (props) => {
  const { value, column, row, selectRMF } = props;

  const setRMF = (rmfData) => {
    selectRMF(row.original);
  };

  return (
    <MDButton variant="text" onClick={() => setRMF()}>
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default NameBtnTr;
