import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { desc_to_types } from "../../AudienceManagement/data/AudienceTypes";

const AddNewAudienceFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue } = props;
  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h4"
          fontWeight="bold"
        >
          Add New Audience
        </MDTypography>
        <MDBox mt={2}>
          <Grid container justifyContent="space-between" p={5}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormField
                  type="text"
                  label="Audience Name"
                  name="txtAudienceName"
                  value={values.txtAudienceName}
                  placeholder={"Audience Name"}
                  error={errors.txtAudienceName && touched.txtAudienceName}
                  success={
                    values.txtAudienceName.length > 0 && !errors.txtAudienceName
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={Object.keys(desc_to_types)}
                  value={values.drpAudienceType}
                  getOptionDisabled={(option) =>
                    option != "Adopters" ? true : false
                  }
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" label="Type" />
                  )}
                  onChange={(event, value) => {
                    if (value === null) {
                      setFieldValue("drpAudienceType", "");
                    } else {
                      setFieldValue("drpAudienceType", value);
                    }
                  }}
                />
                {errors.drpAudienceType && touched.drpAudienceType ? (
                  <FormHelperText error>
                    {errors.drpAudienceType}
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default AddNewAudienceFormFormikWrapper;
