import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useParams } from "react-router-dom";
import { showMsg } from "utils/general";
import { acceptAuditorInvite } from "features/auditorReports/auditorReportActions";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";

const AcceptAuditorInvite = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  let { invite_token } = useParams();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    acceptInvite();
  }, []);

  const acceptInvite = () => {
    const objPostData = { token: invite_token };
    dispatch(acceptAuditorInvite({ objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setLoading(false);
        showMsg("success", "Invite accepted successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
        setIsError(true);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error inviting a new auditor");
        }
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs title="Accept Auditor Invite" />
      <MDBox>
        <Card>
          <MDBox p={2}>
            {loading ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            ) : (
              <>
                {!isError ? (
                  <>
                    <p style={{ textAlign: "center" }}>
                      Invitation to the company accepted successfully. You can
                      now switch to the new company from the sidebar by clicking
                      on the dropdown menu.
                    </p>
                    <p style={{ textAlign: "center" }}>
                      <Link to={"/auditor-reports"}>
                        <MDButton variant="contained" color="success">
                          Back to auditor reports page
                        </MDButton>
                      </Link>
                    </p>
                  </>
                ) : (
                  <>
                    <p style={{ textAlign: "center", color: "red" }}>
                      Invitation acceptance was not successful. Please reload
                      the page and try again
                    </p>
                  </>
                )}
              </>
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};
export default AcceptAuditorInvite;
