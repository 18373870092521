/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import {
  COMPANIES_SERVER,
  VERSION_SERVER,
  CLIENTS_SERVER,
  BASE_ENDPOINT,
} from "components/utils/constants/misc";

const getCompanyID = (objUser) => {
  return objUser.user.company_id;
};

export const saveComplianceTogglesChanges = createAsyncThunk(
  "company/saveComplianceTogglesChanges",
  async (values, thunkApi) => {
    try {
      const { currentToggles } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/trust-center/compliance-toggles`,
        { currentToggles }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveDocumentsTogglesChanges = createAsyncThunk(
  "company/saveDocumentsTogglesChanges",
  async (values, thunkApi) => {
    try {
      const { currentToggles } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/trust-center/document-toggles`,
        { currentToggles }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveOverviewChanges = createAsyncThunk(
  "company/saveOverviewChanges",
  async (values, thunkApi) => {
    try {
      const { overview } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/trust-center/overview`,
        { overview }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const savePointOfContactChanges = createAsyncThunk(
  "company/savePointOfContactChanges",
  async (values, thunkApi) => {
    try {
      const { contactsToAdd } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/trust-center/points-of-contact`,
        { contactsToAdd }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPublicData = createAsyncThunk(
  "get/getPublicData",
  async (values, thunkApi) => {
    try {
      const { clientId } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/trust-center/${clientId}`
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
