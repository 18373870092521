import { createSlice } from "@reduxjs/toolkit";
import { saveConnectWiseApiKeys } from "./connectWiseActions";

const initialState = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
};

const connectWiseSlice = createSlice({
  name: "connectWise",
  initialState,
  reducers: {
    // signupUser: (state,action) => {
    //   localStorage.removeItem("userToken"); // delete token from storage
    //   state.loading = false;
    //   state.userInfo = null;
    //   state.userToken = null;
    //   state.error = null;
    // },
  },
  extraReducers: {
    // saveConnectWiseApiKeys
    [saveConnectWiseApiKeys.pending]: (state) => {
      state.loading = true;
    },
    [saveConnectWiseApiKeys.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objSaveConnectWiseApiKeysFormSuccess = payload;
    },
    [saveConnectWiseApiKeys.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objSaveConnectWiseApiKeysFormSuccess = payload;
    },
    //
  },
});

// export const { logout } = connectWiseSlice.actions;

export default connectWiseSlice.reducer;
