/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";

const AccountInfoFormFormikWrapper = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    objStates,
    objCountries,
    objUser,
  } = props;
  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h5"
          fontWeight="bold"
        >
          {objUser.user.role.name === "company_user" ||
          objUser.user.role.name === "admin"
            ? "MSP "
            : "Company "}
          Details
        </MDTypography>
        <MDBox mt={2}>
          <Grid container spacing={3} justifyContent="space-between" p={5}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="MSP Name"
                    name="name"
                    value={values.name ?? ""}
                    placeholder={"MSP Name"}
                    error={errors.name && touched.name}
                    success={values.name.length > 0 && !errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Address 1"
                    name="address1"
                    value={values.address1 ?? ""}
                    placeholder={"Address 1"}
                    error={errors.address1 && touched.address1}
                    success={values.address1?.length > 0 && !errors.address1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDBox mt={-1.625}>
                    <FormField
                      type="text"
                      label="Address 2"
                      name="address2"
                      value={values.address2 ?? ""}
                      placeholder={"Address 2"}
                      error={errors.address2 && touched.address2}
                      success={values.address2?.length > 0 && !errors.address2}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="City"
                    name="city"
                    value={values.city ?? ""}
                    placeholder={"City"}
                    error={errors.city && touched.city}
                    success={values.city?.length > 0 && !errors.city}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {values.country === "United States of America" && (
                      <Grid item xs={6}>
                        <Autocomplete
                          options={objStates}
                          name="state"
                          value={
                            objStates.find(
                              (state) => state.label === values.state
                            ) || null
                          }
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              variant="standard"
                              label="State"
                            />
                          )}
                          onChange={(event, value) => {
                            if (value === null) {
                              setFieldValue("state", "");
                            } else {
                              setFieldValue("state", value.label);
                            }
                          }}
                          getOptionLabel={(option) =>
                            option ? option.label : ""
                          }
                        />
                        {errors.state && touched.state ? (
                          <FormHelperText error>{errors.state}</FormHelperText>
                        ) : null}
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={
                        values.country === "United States of America" ? 6 : 12
                      }
                    >
                      <Autocomplete
                        options={objCountries}
                        name="country"
                        value={
                          objCountries.find(
                            (country) => country.label === values.country
                          ) || null
                        }
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label="Country"
                          />
                        )}
                        onChange={(event, value) => {
                          if (value === null) {
                            setFieldValue("country", "");
                          } else {
                            setFieldValue("country", value.label);
                          }
                        }}
                        getOptionLabel={(option) =>
                          option ? option.label : ""
                        }
                      />
                      {errors.state && touched.state ? (
                        <FormHelperText error>{errors.state}</FormHelperText>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Zip"
                    name="zip"
                    value={values.zip ?? ""}
                    placeholder={"Zip"}
                    error={errors.zip && touched.zip}
                    success={values.zip?.length > 0 && !errors.zip}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default AccountInfoFormFormikWrapper;
