import { useEffect } from "react";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// page layout components
import CoverLayout from "views/containers/CoverLayout";

// Images
import bgImage from "assets/images/polygon-website-backdrop.jpg";
import EmailVerificationSection from "./components/EmailVerificationSection";

const EmailVerification = (props) => {
  const { objUser } = props;
  const navigate = useNavigate();
  useEffect(() => {
    if (
      typeof objUser.user !== "undefined" &&
      typeof objUser.user.email_verified_at !== "undefined"
    ) {
      const objAuthUser = objUser.user;

      // Only admin users can verify their email
      if (objAuthUser.role.name === "admin") {
        // If email already verified figure out next step
        if (objAuthUser.email_verified_at !== null) {
          if (objAuthUser.company === null) {
            navigate("/setup-account/step-one");
          } else if (objAuthUser.company.status === "PENDING_SUBSCRIPTION") {
            navigate("/billing");
          } else {
            navigate("/home");
          }
        }
      } else {
        navigate("/home");
      }
    }
  }, []);
  return (
    <CoverLayout image={bgImage}>
      <MDBox display="flex" flexDirection="column" height="100%">
        <EmailVerificationSection objUser={objUser} />
      </MDBox>
    </CoverLayout>
  );
};

export default EmailVerification;
