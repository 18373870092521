import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { auth } from "features/user/userActions";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
const Auth0CallBack = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    user,
    isAuthenticated,
    error,
    isLoading,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [isAuthLoading, setIsAuthLoading] = useState(false);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });

        const maxRetry = 10;
        let count = 0;
        let flag = false;
        function checkFlag() {
          count++;
          if (
            flag &&
            (accessToken === null || typeof accessToken !== "string")
          ) {
            window.setTimeout(
              checkFlag,
              1000
            ); /* this checks the flag every 1000 milliseconds*/
          } else {
            flag = true;
          }

          if (count >= maxRetry) {
            flag = true;
          }
        }
        checkFlag();

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        localStorage.setItem("token", accessToken);
        localStorage.setItem("login_type", "auth0");
        localStorage.setItem("is_remember_me", false);

        dispatch(auth())
          .then(unwrapResult)
          .then((originalPromiseResultAuth) => {
            setIsAuthLoading(false);

            const objAuthUser = originalPromiseResultAuth.user;

            // TODO Review cases

            // If no auth0 user or no auth user go to home
            if (
              typeof user === "undefined" ||
              user === null ||
              typeof user.complianceriskLoginsCount === "undefined" ||
              typeof objAuthUser.role !== "undefined" ||
              typeof objAuthUser.role.name !== "undefined"
            ) {
              navigate("/");
              return;
            }

            // User logged in but not admin user. (Not admin user cant create companies and dont need to verify email)
            if (objAuthUser.role.name !== "admin") {
              navigate("/home");
              return;
            }

            // Just to make extra sure that the user is admin
            if (objAuthUser.role.name === "admin") {
              // Not verified? got to email verification page
              if (objAuthUser.email_verified_at === null) {
                navigate("/email-verification");

                // User is admin but no company? go to create company page
              } else if (objAuthUser.company === null) {
                if (objAuthUser.accepted_tos) {
                  navigate("/setup-account/step-two");
                } else {
                  navigate("/setup-account/step-one");
                }

                // User is admin and has company but no subscription? go to billing page
              } else if (
                objAuthUser.company.status === "PENDING_SUBSCRIPTION" ||
                objAuthUser.company.status === "ON_HOLD"
              ) {
                navigate("/billing");
              } else {
                navigate("/home");
              }
              return;
            }
            navigate("/");
          })
          .catch((rejectedValueOrSerializedErrorAuth) => {
            setIsAuthLoading(false);
            navigate("/");
          });
      } catch (e) {
        navigate("/");
      }
    };

    /**
     * TODO temporary fix for the case when the auth request was made before setting the access token
     */
    setTimeout(getUserMetadata, 5000);
  }, [getAccessTokenSilently, user?.sub]);

  return (
    <Grid
      my={3}
      container
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Grid item xs={12} lg={12} p={5}>
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      </Grid>
    </Grid>
  );
};

export default Auth0CallBack;
