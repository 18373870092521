import { Card } from "@mui/material";
import { useEffect } from "react";
import MDBox from "components/MDBox";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutContainer = (props) => {
  const { logout } = useAuth0();
  const btnOnClickLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("is_remember_me");
    localStorage.removeItem("login_type");
    localStorage.clear();

    logout({
      returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
      localOnly: false,
    });
  };

  useEffect(() => {
    btnOnClickLogout();
  });
  return (
    <Card position="relative" sx={{ padding: "32px", marginBottom: "1rem" }}>
      <MDBox
        sx={{
          padding: "0 0 32px 32px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      </MDBox>
    </Card>
  );
};

export default LogoutContainer;
