import DefaultCell from "views/components/TableCells/DefaultCell";
import TemplateNameBtnTr from "./TemplateNameBtnTr";
import TemplateDeleteBtnTr from "./TemplateDeleteBtnTr";
import TemplateShareBtnTr from "./TemplateShareBtnTr";
import MDBox from "components/MDBox";
import TemplateUnshareBtnTr from "./TemplateUnshareBtnTr";
import ToolTip from "./ToolTip";
import { IconButton, Tooltip } from "@mui/material";

const TemplatesDataTableData = (objUser, reloadFunc) => ({
  columns: [
    {
      Header: "Name",
      accessor: "name",
      width: "25%",
      Cell: ({ value, column, row }) => (
        <TemplateNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      width: "30%",
      Cell: ({ value }) => (
        <>
          <Tooltip title={value} placement="top-end">
            <DefaultCell
              value={
                value && value.length > 20 ? value.slice(0, 20) + "..." : value
              }
            />
            <IconButton
              sx={{ opacity: "0.4", fontSize: "1.2rem" }}
              pb={2}
            ></IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      Header: "Owner",
      accessor: "created_by",
      width: "15%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      width: "15%",
      Cell: ({ value, column, row }) => (
        <>
          {row.original.original_template_id ? (
            <ToolTip info={"Can't share imported template"} />
          ) : (
            <DefaultCell value={value} />
          )}
        </>
      ),
    },
    ...(objUser.user.role.name == "company_user" ||
    objUser.user.role.name == "admin"
      ? [
          {
            Header: "Action",
            accessor: "id",
            Cell: ({ value, column, row }) => (
              <MDBox display="flex">
                <TemplateDeleteBtnTr
                  value={value}
                  column={column}
                  row={row}
                  reloadFunc={reloadFunc}
                  objUser={objUser}
                />
                {row.original?.shareRequest?.status == "Approved" ? (
                  <TemplateUnshareBtnTr
                    value={value}
                    column={column}
                    row={row}
                    reloadFunc={reloadFunc}
                    objUser={objUser}
                  />
                ) : (
                  <TemplateShareBtnTr
                    value={value}
                    column={column}
                    row={row}
                    reloadFunc={reloadFunc}
                    objUser={objUser}
                  />
                )}
              </MDBox>
            ),
          },
        ]
      : []),
  ],
  rows: [],
});

export default TemplatesDataTableData;
