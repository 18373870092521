import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { unwrapResult } from "@reduxjs/toolkit";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { deleteRiskRegisterItemBulk } from "features/company/riskRegisterActions.js";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
// formik components
import { Form, Formik } from "formik";
const objInitialValues = {
  reason: "",
};

function BulkDeleteDialog({ open, onClose, onSubmit, objUser, findingsToAdd }) {
  const [isSubmittingEvent, setIsSubmittingEvent] = useState(false);
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    setIsSubmittingEvent(true);
    dispatch(deleteRiskRegisterItemBulk({ itemIds: findingsToAdd, reason: values.reason, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        onSubmit();
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized");
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        }
      })
      .finally(() => {
        setIsSubmittingEvent(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Delete multiple risk findings</DialogTitle>
      
        <Formik
          initialValues={objInitialValues}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form id="AddNewItemForm" autoComplete="off">
              <DialogContent>
                <MDTypography variant="body1" color="textSecondary">
                    Are you sure you want to delete the selected items?
                </MDTypography>
                <MDBox mb={0} mt={0}>
                  <FormField
                    type="text"
                    label="Reason"
                    name="reason"
                    value={values.reason}
                    placeholder={"Reason for deletion"}
                    error={errors.reason && touched.reason}
                    success={
                      values.reason.length > 0 && !errors.reason
                    }
                  />
                </MDBox>
              </DialogContent>
              <DialogActions>
                {isSubmittingEvent === false ? (
                  <>
                    <MDButton
                      type="submit"
                      color="success"
                      sx={{ padding: "12px 12px" }}
                    >
                      Remove
                    </MDButton>
                    <MDButton
                      type="button"
                      variant="outlined"
                      color="dark"
                      sx={{ padding: "12px 12px" }}
                      onClick={onClose}
                    >
                      Cancel
                    </MDButton>
                  </>
                ) : (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
    </Dialog>
  );
}
export default BulkDeleteDialog;
