import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardActionButton from "views/components/DashboardActionButton";
// import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "./data/dataTableData";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  deleteCategoryData,
  getRMF,
  deleteRMFData,
  uploadDisplayLogo,
  fetchDisplayLogo,
  deleteClientReportLogo,
  uploadReportLogo,
  fetchReportLogo,
  fetchEmailDeliverySettings,
  updateEmailDeliverySettings,
  deleteClientDisplayLogo,
  fetchPublicLinkPassword,
  updatePublicLinkPassword,
  updatePublicLinkPasswordActiveStatus,
} from "../../../../features/company/CompanyConfigActions";
import { auth } from "../../../../features/user/userActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CategoriesManagementTable from "./components/CategoriesTable/CategoriesManagementTable";
import RMFManagementTable from "./components/RMFTable/RMFManagementTable";
import { showMsg } from "utils/general";
import "./ClientConfig.css";
import MDTypography from "components/MDTypography";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Popper,
  Radio,
  RadioGroup,
} from "@mui/material";
import CreateNewCategoryDialog from "./components/CategoriesTable/createNewCategoryDialog";
import EditCategoryDialog from "./components/CategoriesTable/EditCategoryDialog";
import CreateNewRMFDialog from "./components/RMFTable/createNewRMFDialog";
import EditRMFDialog from "./components/RMFTable/EditRMFDialog";
import LogoutFromExistingSessionsDialog from "../Settings/MsGraphEmailConnect/components/LogoutFromExistingSessionsPrompt";
import MDButton from "components/MDButton";
import { CameraAlt } from "@mui/icons-material";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "views/components/TableCells/DefaultCell";
import {
  fetchMergeTagValues,
  setMergeTagValues,
} from "../../../../features/company/mergeTagsActions";
import LogoUploadRequirements from "views/components/LogoUploadRequirements";
import EditTagsDialog from "./components/EditTagsDialog";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useConfirm } from "material-ui-confirm";
import ToolTip from "./components/ToolTip";
import { Formik, Form, ErrorMessage, Field } from "formik";
import FormField from "layouts/pages/users/new-user/components/FormField";
import * as Yup from "yup";
import moment from "moment";

const objInitialValues = {
  password: "",
  passwordConfirmation: "",
};

const PublicPasswordFormJWTSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters long."),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match.")
    .required("Password confirmation is required."),
});

const CompanyConfig = (props) => {
  const { objUser } = props;

  /**categories */
  const [isLoadingMoreCategories, setIsLoadingMoreCategories] = useState(false);
  const [isDeletingCategory, setIsDeletingCategory] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [openNewCategoryDialog, setOpenNewCategoryDialog] = useState(false);
  const [openEditCategoryDialog, setOpenEditCategoryDialog] = useState(false);
  const [editCategoryDialogData, setEditCategoryDialogData] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  /**RMF */
  const [isLoadingMoreRMF, setIsLoadingMoreRMF] = useState(false);
  const [isDeletingRMF, setIsDeletingRMF] = useState(false);
  const [objRMFTableData, setObjRMFTableData] = useState(dataTableData);
  const [totalRMFCount, setTotalRMFCount] = useState(0);
  const [openNewRMFDialog, setOpenNewRMFDialog] = useState(false);
  const [openEditRMFDialog, setOpenEditRMFDialog] = useState(false);
  const [editRMFDialogData, setEditRMFDialogData] = useState({});
  const [perPageRMF, setPerPageRMF] = useState(10);
  const [pageRMF, setPageRMF] = useState(1);

  /**Logos */
  const [file, setFile] = useState(null);
  const [reportFile, setReportFile] = useState(null);
  const [isUploadingDisplayLogo, setIsUploadingDisplayLogo] = useState(false);
  const [isFetchingDisplayLogo, setIsFetchingDisplayLogo] = useState(true);
  const [displayLogoURL, setDisplayLogoURL] = useState(null);
  const [isUploadingReportLogo, setIsUploadingReportLogo] = useState(false);
  const [isFetchingReportLogo, setIsFetchingReportLogo] = useState(true);
  const [reportLogoURL, setReportLogoURL] = useState(null);
  const confirm = useConfirm();

  /*Email delivery settings*/
  const [isFetchingEmailSettings, setIsFetchingEmailSettings] = useState(true);
  const [emailDeliverySettingValue, setEmailDeliverySettingValue] =
    useState("SMTP");
  const [refreshTokenValue, setRefreshTokenValue] = useState(null);
  const [openMsGraphLogoutDialog, setOpenMsGraphLogoutDialog] = useState(false);

  /* Merge Tags */
  const [isFetchingMergeTags, setIsFetchingMergeTags] = useState(false);
  const [openMergeTagDialog, setOpenMergeTagDialog] = useState(false);
  const [mergeTagsDataTableData, setMergeTagsDataTableData] = useState({});
  const [currentMergeTag, setCurrentMergeTag] = useState(null);

  // Public Password
  // const [publicLinkPassword, setPublicLinkPassword] = useState(null);
  // const [publicLinkPasswordActivated, setPublicLinkPasswordActivated] =
  //   useState(false);
  // const [isUpdatingPublicPassword, setIsUpdatingPublicPassword] =
  //   useState(false);

  const isAdmin =
    objUser.user && objUser.user.role
      ? objUser.user.role.name == "admin"
      : false;

  const isMSPUser =
    objUser.user && objUser.user.role
      ? objUser.user.role.name == "company_user"
      : false;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenPopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const openPopper = Boolean(anchorEl);
  const id = openPopper ? "simple-popper" : undefined;

  /**categories **/
  const btnOnClickNewCategory = () => {
    setOpenNewCategoryDialog(true);
  };

  const onCloseNewCategoryDialog = () => {
    setOpenNewCategoryDialog(false);
  };

  const onSubmitNewCategoryDialog = (response) => {
    populateCategoriesTableData(response);
    setOpenNewCategoryDialog(false);
  };

  const editCategory = (dataFromActionBtn) => {
    setEditCategoryDialogData(dataFromActionBtn);
    setOpenEditCategoryDialog(true);
  };

  const onCloseEditCategoryDialog = () => {
    setOpenEditCategoryDialog(false);
  };

  const onSubmitEditCategoryDialog = (response) => {
    populateCategoriesTableData(response);
    setOpenEditCategoryDialog(false);
  };

  const loadMoreCategories = (_per_page, _page) => {
    setIsLoadingMoreCategories(true);
    dispatch(getCategories({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreCategories(false);
        populateCategoriesTableData(originalPromiseResult);
      })
      .catch((err) => {
        setIsLoadingMoreCategories(false);

        console.error("loadMoreCategories error:", err);

        const objErrorRsp = err.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.error("User is not logged in");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  const populateCategoriesTableData = (response) => {
    const objRows = [];
    const objCategoriesList = response.data.categories;
    for (let index = 0; index < objCategoriesList.length; index++) {
      const element = objCategoriesList[index];
      const objTr = {};
      objTr.categoryName = element.display_name;
      objTr.categoryId = element.id;
      // objTr.displayName = element.display_name;
      objRows.push(objTr);
    }
    setTotalCount(response.data.total);
    setObjTableData({
      ...dataTableData,
      rows: objRows,
    });
  };

  const deleteCategory = async (dataFromActionBtn) => {
    setIsDeletingCategory(true);
    dispatch(deleteCategoryData({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingCategory(false);

        const response = originalPromiseResult;
        if (response.status == 200 || response.status == 201) {
          showMsg("success", "Category deleted");
          populateCategoriesTableData(response);
          // window.location.reload();
        }
      })
      .catch((err) => {
        setIsDeletingCategory(false);
        console.error("delete category error:", err);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  /**RMFs */
  const btnOnClickNewRMF = () => {
    setOpenNewRMFDialog(true);
  };

  const onCloseNewRMFDialog = () => {
    setOpenNewRMFDialog(false);
  };

  const onSubmitNewRMFDialog = (response) => {
    populateRMFTableData(response);
    setOpenNewRMFDialog(false);
  };

  const editRMF = (dataFromActionBtn) => {
    setEditRMFDialogData(dataFromActionBtn);
    setOpenEditRMFDialog(true);
  };

  const onCloseEditRMFDialog = () => {
    setOpenEditRMFDialog(false);
  };

  const onSubmitEditRMFDialog = (response) => {
    populateRMFTableData(response);
    setOpenEditRMFDialog(false);
  };

  const loadMoreRMF = (_per_page, _page) => {
    setIsLoadingMoreRMF(true);
    dispatch(getRMF({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreRMF(false);
        populateRMFTableData(originalPromiseResult);
      })
      .catch((err) => {
        setIsLoadingMoreRMF(false);

        console.error("loadMoreRMF failure", err);

        const objErrorRsp = err.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.error("User is not logged in");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  const populateRMFTableData = (response) => {
    const objRows = [];
    const objRMFsList = response.data.rmfs;
    for (let index = 0; index < objRMFsList.length; index++) {
      const element = objRMFsList[index];
      const objTr = {};
      objTr.rmfName = element.name;
      objTr.rmfId = element.id;
      objTr.type_name = element.is_global == 1 ? "Global" : "Company";
      objTr.type = element.is_global;
      // objTr.displayName = element.display_name;
      objRows.push(objTr);
    }
    setTotalRMFCount(response.data.total);
    setObjRMFTableData({
      ...dataTableData,
      rows: objRows,
    });
  };

  const deleteRMF = async (dataFromActionBtn) => {
    setIsDeletingRMF(true);
    dispatch(deleteRMFData({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingRMF(false);

        const response = originalPromiseResult;
        if (response.status == 200 || response.status == 201) {
          showMsg("success", "Risk Management Framework deleted");
          populateRMFTableData(response);
          // window.location.reload();
        }
      })
      .catch((err) => {
        setIsDeletingRMF(false);
        console.error("problem detected while deleting RMF", err);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  useEffect(() => {
    loadMoreCategories(perPage, page);
    loadMoreRMF(perPageRMF, pageRMF);
    loadDisplayLogo();
    loadReportLogo();
    loadEmailDeliverySettings();
  }, []);

  /**Email delivery settings */
  const loadEmailDeliverySettings = async () => {
    dispatch(fetchEmailDeliverySettings({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // setIsFetchingDisplayLogo(false);
        const response = originalPromiseResult;
        // if (response.data.display_logo) {
        //   setDisplayLogoURL(response.data.display_logo);
        // }

        setEmailDeliverySettingValue(response.data.email_delivery_preference);
        setRefreshTokenValue(response.data.ms_graph_refresh_token);
        setIsFetchingEmailSettings(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingEmailSettings(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your email delivery settings, please try again."
        );
      });
  };

  /**update email settings*/

  const btnOnClickSaveEmailPreferencesChanges = async () => {
    setIsFetchingEmailSettings(true);
    dispatch(
      updateEmailDeliverySettings({ objUser, emailDeliverySettingValue })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingEmailSettings(false);
        const response = originalPromiseResult;
        //  setEmailDeliverySettingValue(response.data.email_delivery_preference);
        showMsg("success", "Email delivery preferences saved successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingEmailSettings(false);
        console.log(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg(
            "error",
            "Something went wrong as we tried to UPDATE your email delivery settings, please try again."
          );
        }
      });
  };

  /**file uploads */

  const handleDisplayLogoInputChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const btnClickUploadDisplayLogo = async () => {
    if (!file) {
      showMsg("error", "Please select an image to upload");
      return;
    }
    setIsUploadingDisplayLogo(true);
    dispatch(uploadDisplayLogo({ objUser, file }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsUploadingDisplayLogo(false);
        setFile(null);
        const response = originalPromiseResult;
        showMsg("success", "Logo updated");
        setDisplayLogoURL(response.data.display_logo);
        window.location.reload();
      })
      .catch((err) => {
        setIsUploadingDisplayLogo(false);
        setFile(null);

        console.error("cannot upload display logo:", err);
        if (err.response.data.errors) {
          console.error("details:", err.response.data.errors);

          err.response.data.errors.forEach((error) => {
            showMsg("error", error);
          });
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  const loadDisplayLogo = async () => {
    dispatch(fetchDisplayLogo({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingDisplayLogo(false);
        const response = originalPromiseResult;
        if (response.data.display_logo) {
          setDisplayLogoURL(response.data.display_logo);
        }
      })
      .catch((err) => {
        console.error("cannot upload display logo:", err);
        setIsFetchingDisplayLogo(false);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your display logo, please try again."
        );
      });
  };

  const deleteDisplayLogo = async () => {
    setIsUploadingDisplayLogo(true);
    dispatch(deleteClientDisplayLogo({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsUploadingDisplayLogo(false);
        const response = originalPromiseResult;
        showMsg("success", "Logo Deleted");
        window.location.reload();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUploadingDisplayLogo(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your display logo, please try again."
        );
      });
  };

  const btnOnClickDeleteDisplayLogo = () => {
    confirm({
      description: "Are you sure, You want to delete this logo?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteDisplayLogo();
      })
      .catch(() => {
        /* ... */
      });
  };

  /**Report logo */
  const handleReportLogoInputChange = (e) => {
    if (e.target.files) {
      setReportFile(e.target.files[0]);
    }
  };

  const btnClickUploadReportLogo = async () => {
    if (!reportFile) {
      showMsg("error", "Please select an image to upload");
      return;
    }
    setIsUploadingReportLogo(true);
    dispatch(uploadReportLogo({ objUser, reportFile }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsUploadingReportLogo(false);
        setReportFile(null);
        const response = originalPromiseResult;
        showMsg("success", "Logo updated");
        setReportLogoURL(response.data.report_logo);
      })
      .catch((err) => {
        setIsUploadingReportLogo(false);
        setReportFile(null);
        console.error("fail to upload report logo", err);
        if (err.response.data.errors) {
          err.response.data.errors.forEach((error) => {
            showMsg("error", error);
          });
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  const loadReportLogo = async () => {
    dispatch(fetchReportLogo({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingReportLogo(false);
        const response = originalPromiseResult;
        if (response.data.report_logo) {
          setReportLogoURL(response.data.report_logo);
        }
      })
      .catch((err) => {
        setIsFetchingReportLogo(false);
        console.error("failed fetching report logo", err);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your reports logo, please try again."
        );
      });
  };

  const deleteReportLogo = async () => {
    setIsUploadingReportLogo(true);
    dispatch(deleteClientReportLogo({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsUploadingReportLogo(false);
        const response = originalPromiseResult;
        showMsg("success", "Logo Deleted");
        setReportLogoURL(null);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUploadingReportLogo(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your report logo, please try again."
        );
      });
  };

  const btnOnClickDeleteReportLogo = () => {
    confirm({
      description: "Are you sure, You want to delete this report logo?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteReportLogo();
      })
      .catch(() => {
        /* ... */
      });
  };

  /**email settings */
  const handleEmailDeliveryRadioChange = (event) => {
    setEmailDeliverySettingValue(event.target.value);
  };

  const onCloseMsGraphLogoutDialog = () => {
    setOpenMsGraphLogoutDialog(false);
  };

  /* merge tags */
  const mergeTagsTableData = (editAction) => ({
    columns: [
      { Header: "Name", accessor: "name" },
      { Header: "Description", accessor: "description" },
      { Header: "Type", accessor: "type" },
      {
        Header: "Value",
        accessor: "value",
        Cell: ({ value }) =>
          value ? (
            <DefaultCell value={value} />
          ) : (
            <MDTypography
              color="info"
              fontWeight="light"
              fontStyle="italic"
              variant="caption"
            >
              No value set
            </MDTypography>
          ),
      },
      {
        Header: "Actions",
        accessor: "id",
        align: "right",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.type == "Global" ? (
                "Can't modify Global Tags"
              ) : (
                <MDButton onClick={() => editAction(row.original)}>
                  <MDBox color="dark" display="flex" alignItems="center">
                    <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                    <MDTypography
                      variant="p"
                      fontWeight="medium"
                      color="text.dark"
                      sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
                    >
                      Edit
                    </MDTypography>
                  </MDBox>
                </MDButton>
              )}
            </>
          );
        },
      },
    ],
    rows: [],
  });

  const loadMoreTags = () => {
    setIsFetchingMergeTags(true);
    dispatch(fetchMergeTagValues({ objUser }))
      .then(unwrapResult)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Get marge tags request failed.");
        }

        setMergeTagsDataTableData({
          ...mergeTagsTableData(editTag),
          // rows: response.data.sort((a, b) => a.name.localeCompare(b.name)),
          rows: response.data.sort((a, b) => {
            const nameComparison = a.type.localeCompare(b.type);
            return nameComparison !== 0
              ? nameComparison
              : a.name.localeCompare(b.name);
          }),
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log("Fetch mergeTags", rejectedValueOrSerializedError);
        showMsg("error", "Error fetching merge tags");
      })
      .finally(() => {
        setIsFetchingMergeTags(false);
      });
  };

  const editTag = (tag) => {
    setCurrentMergeTag(tag);
    setOpenMergeTagDialog(true);
  };

  const onTagDialogClose = () => {
    setOpenMergeTagDialog(false);
  };

  const onTagDialogSubmit = (response) => {
    setOpenMergeTagDialog(false);
    loadMoreTags();
  };

  useEffect(() => {
    loadMoreTags();
  }, []);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDTypography fontWeight="bold" variant="h5">
        Logo Customization
      </MDTypography>
      {/* categories crud */}
      <CreateNewCategoryDialog
        open={openNewCategoryDialog}
        onClose={onCloseNewCategoryDialog}
        onSubmit={onSubmitNewCategoryDialog}
        objUser={props.objUser}
      />
      <EditCategoryDialog
        open={openEditCategoryDialog}
        onClose={onCloseEditCategoryDialog}
        onSubmit={onSubmitEditCategoryDialog}
        objUser={props.objUser}
        editCategoryDialogData={editCategoryDialogData}
      />

      {/* <LogoutFromExistingSessionsDialog
        open={openMsGraphLogoutDialog}
        onClose={onCloseMsGraphLogoutDialog}
        objUser={props.objUser}
      /> */}

      {/* RMF crud dialogs */}
      <CreateNewRMFDialog
        open={openNewRMFDialog}
        onClose={onCloseNewRMFDialog}
        onSubmit={onSubmitNewRMFDialog}
        objUser={props.objUser}
      />
      <EditRMFDialog
        open={openEditRMFDialog}
        onClose={onCloseEditRMFDialog}
        onSubmit={onSubmitEditRMFDialog}
        objUser={props.objUser}
        editRMFDialogData={editRMFDialogData}
      />
      <MDBox>
        <MDBox display="flex" gap=".6rem" alignItems="flex-start" mb={0}>
          <Grid spacing={2} container width="100%">
            <Grid item xs={12} lg={4}>
              <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
                <MDBox display="flex" alignItems="flex-start">
                  <MDTypography sx={{ marginBottom: "1rem" }} variant="h4">
                    Display Logo
                  </MDTypography>
                  <Tooltip title="Upload a custom company logo to be displayed in the dashboard.">
                    <IconButton>
                      <HelpOutlineIcon color="icon" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </MDBox>
                <LogoUploadRequirements />
                <MDBox mb={2}>
                  {isFetchingDisplayLogo ? (
                    <LoadingSpinner subClass="text-center" size="lg" />
                  ) : (
                    <>
                      {displayLogoURL ? (
                        <div>
                          <img
                            src={displayLogoURL}
                            style={{ maxWidth: "100%", height: "auto" }}
                            alt="Display logo"
                          />
                          <MDButton
                            onClick={btnOnClickDeleteDisplayLogo}
                            variant="gradient"
                            sx={{ marginLeft: "1rem" }}
                          >
                            <MDBox
                              color="error"
                              display="flex"
                              alignItems="center"
                            >
                              <Icon sx={{ fontWeight: "bold" }}>delete</Icon>{" "}
                            </MDBox>
                          </MDButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {/* <img
                    src={displayLogoURL}
                    style={{ maxWidth: "100%", height: "auto" }}
                    alt="Display logo"/>
                  </> */}
                </MDBox>

                <input type="file" onChange={handleDisplayLogoInputChange} />

                <div>{file && `${file.name} - ${file.type}`}</div>

                <MDButton
                  variant="gradient"
                  onClick={btnClickUploadDisplayLogo}
                  color="info"
                  sx={{ padding: "5px 15px", marginTop: "1rem" }}
                >
                  {isUploadingDisplayLogo ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="white"
                      size="lg"
                    />
                  ) : (
                    <Fragment>
                      <Icon fontSize="large">
                        <CameraAlt />
                      </Icon>
                      <MDTypography
                        fontWeight="bold"
                        sx={{ marginLeft: "0.75rem" }}
                        variant="button"
                        color="white"
                      >
                        Upload Display Logo
                      </MDTypography>
                    </Fragment>
                  )}
                </MDButton>
              </Card>
            </Grid>
            {/* Report logo */}
            <Grid item xs={12} lg={4}>
              <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
                <MDBox display="flex" alignItems="flex-start">
                  <MDTypography sx={{ marginBottom: "1rem" }} variant="h4">
                    Reports/PDFs Logo
                  </MDTypography>
                  <Tooltip title="This logo will be embedded on your reports and PDFs.">
                    <IconButton>
                      <HelpOutlineIcon color="icon" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </MDBox>
                <LogoUploadRequirements />
                <MDBox mb={2}>
                  {isFetchingReportLogo ? (
                    <LoadingSpinner subClass="text-center" size="lg" />
                  ) : (
                    <>
                      {reportLogoURL ? (
                        <div>
                          <img
                            src={reportLogoURL}
                            style={{ maxWidth: "100%", height: "auto" }}
                            alt="Report logo"
                          />
                          <MDButton
                            onClick={btnOnClickDeleteReportLogo}
                            variant="gradient"
                            sx={{ marginLeft: "1rem" }}
                          >
                            <MDBox
                              color="error"
                              display="flex"
                              alignItems="center"
                            >
                              <Icon sx={{ fontWeight: "bold" }}>delete</Icon>{" "}
                            </MDBox>
                          </MDButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </MDBox>

                <input type="file" onChange={handleReportLogoInputChange} />

                <div>
                  {reportFile && `${reportFile.name} - ${reportFile.type}`}
                </div>

                <MDButton
                  variant="gradient"
                  onClick={btnClickUploadReportLogo}
                  color="info"
                  sx={{ padding: "5px 15px", marginTop: "1rem" }}
                >
                  {isUploadingReportLogo ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="white"
                      size="lg"
                    />
                  ) : (
                    <Fragment>
                      <Icon fontSize="large">
                        <CameraAlt />
                      </Icon>
                      <MDTypography
                        fontWeight="bold"
                        sx={{ marginLeft: "0.75rem" }}
                        variant="button"
                        color="white"
                      >
                        Upload Reports/PDFs Logo
                      </MDTypography>
                    </Fragment>
                  )}
                </MDButton>
              </Card>
            </Grid>
          </Grid>
        </MDBox>

        <Grid spacing={2} container width="100%">
          {/* <Grid item xs={12}>
            <MDTypography
              fontWeight="bold"
              variant="h5"
              sx={{ marginTop: "1.5rem" }}
              display="flex"
              alignItems="flex-start"
            >
              Public Link Password
              <ToolTip
                info={
                  "Password protect all content accessible with a public link."
                }
              />
            </MDTypography>

            <MDBox>
              <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
                {isUpdatingPublicPassword ? (
                  <LoadingSpinner subClass="text-center" size="lg" />
                ) : (
                  <Grid container width="100%" spacing={2}>
                    <Grid item xs={6}>
                      <MDBox mb={2}>
                        <Formik
                          initialValues={objInitialValues}
                          validationSchema={PublicPasswordFormJWTSchema}
                          onSubmit={updatePublicPassword}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                          }) => (
                            <Form id="AddNewPostForm" autoComplete="off">
                              <MDBox display="flex" alignItems="center">
                                <Checkbox
                                  disabled={!publicLinkPassword}
                                  checked={publicLinkPasswordActivated}
                                  onChange={(event) => {
                                    updatePasswordActiveStatus(
                                      event.target.checked
                                    );
                                  }}
                                />
                                <MDTypography variant="button">
                                  Require password
                                </MDTypography>
                              </MDBox>

                              <Grid container spacing={3}>
                                <Grid item xs={6}>
                                  <FormField
                                    type="password"
                                    label="Enter New Password"
                                    name="password"
                                    // value={logoutTimer}
                                    // onChange={(event) => {
                                    //   setLogoutTimer(event.target.value);
                                    // }}
                                    placeholder={"***********"}
                                    error={errors.password && touched.password}
                                    success={
                                      values.password.length > 0 &&
                                      !errors.password
                                    }
                                    variant="standard"
                                    fullWidth
                                    autoFocus
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormField
                                    type="password"
                                    label="Confirm New Password"
                                    name="passwordConfirmation"
                                    // value={logoutTimer}
                                    // onChange={(event) => {
                                    //   setLogoutTimer(event.target.value);
                                    // }}
                                    placeholder={"***********"}
                                    error={
                                      errors.passwordConfirmation &&
                                      touched.passwordConfirmation
                                    }
                                    success={
                                      values.passwordConfirmation.length > 0 &&
                                      !errors.passwordConfirmation
                                    }
                                    variant="standard"
                                    fullWidth
                                    autoFocus
                                  />
                                </Grid>
                              </Grid>
                              <DashboardActionButton
                                // action={updatePublicPassword}
                                type="submit"
                                btnText="Save Changes"
                                btnIcon="save"
                                textColor="white"
                                bgColor="success"
                              />
                            </Form>
                          )}
                        </Formik>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6}>
                      <MDBox mb={2}>
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          {publicLinkPassword && (
                            <MDTypography variant="button">
                              Last Updated by{" "}
                              {publicLinkPassword.updatedBy.name}{" "}
                              {publicLinkPassword.updatedBy.last_name} {" on "}
                              {moment(
                                publicLinkPassword?.formatted_updated_at
                              ).format("lll")}
                            </MDTypography>
                          )}
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </Card>
            </MDBox>
          </Grid> */}
          <Grid item xs={12} lg={6}>
            <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
              <MDBox display="flex" alignItems="flex-start">
                <MDTypography style={{ marginBottom: "1rem" }} variant="h4">
                  Email Delivery Preferences
                </MDTypography>
                <Tooltip title="Specify what method you want to send your emails.">
                  <IconButton>
                    <HelpOutlineIcon color="icon" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDBox>
              {isFetchingEmailSettings ? (
                <LoadingSpinner subClass="text-center" size="lg" />
              ) : (
                <MDBox mb={2}>
                  <RadioGroup
                    name="emailDelSetting"
                    value={emailDeliverySettingValue}
                    onChange={handleEmailDeliveryRadioChange}
                  >
                    <Grid spacing={2} container width="100%">
                      <Grid item xs={12} lg={6}>
                        <FormControlLabel
                          className="emailPreferenceRadioDiv"
                          value="SMTP"
                          control={<Radio />}
                          label={
                            <div
                              onClick={() =>
                                setEmailDeliverySettingValue("SMTP")
                              }
                            >
                              SMTP
                            </div>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControlLabel
                          className="emailPreferenceRadioDiv"
                          value="MS_GRAPH"
                          sx={{
                            cursor: refreshTokenValue
                              ? "pointer !important"
                              : "not-allowed !important",
                          }}
                          control={
                            <Radio
                              sx={{
                                cursor: refreshTokenValue
                                  ? "pointer"
                                  : "not-allowed",
                              }}
                            />
                          }
                          label={
                            <div
                              onClick={() =>
                                setEmailDeliverySettingValue(
                                  refreshTokenValue ? "MS_GRAPH" : "SMTP"
                                )
                              }
                              style={{
                                cursor: refreshTokenValue
                                  ? "pointer"
                                  : "not-allowed",
                              }}
                            >
                              MS Graph API
                            </div>
                          }
                          disabled={!refreshTokenValue}
                        />

                        <Link
                          to={`/settings/ms-graph-email-connect/${objUser.user.current_client_id}`}
                        >
                          {refreshTokenValue ? (
                            <MDTypography
                              variant="p"
                              fontWeight="bold"
                              pl={0}
                              color="link"
                              //  onClick={() => setOpenMsGraphLogoutDialog(true)}
                              sx={{
                                fontSize: "0.8rem",
                                cursor: "pointer",
                                // color: "#3993de",
                              }}
                            >
                              <Icon sx={{ fontWeight: "bold" }}>settings</Icon>{" "}
                              Reconfigure MS graph for email
                            </MDTypography>
                          ) : (
                            <MDTypography
                              variant="p"
                              fontWeight="bold"
                              pl={0}
                              color="warning"
                              // onClick={() => setOpenMsGraphLogoutDialog(true)}
                              sx={{
                                fontSize: "0.8rem",
                                cursor: "pointer",
                              }}
                            >
                              {/* <Link to={`/settings/ms-graph-email-connect`}> */}
                              Configure MS graph for email to use this option
                            </MDTypography>
                          )}
                        </Link>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  <DashboardActionButton
                    action={btnOnClickSaveEmailPreferencesChanges}
                    btnText="Save Changes"
                    btnIcon="save"
                    textColor="white"
                    bgColor="success"
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card
              sx={{ marginTop: "16px" }}
              className="fixedHeightRMFCategoryCard"
            >
              <MDBox
                p={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <MDTypography
                  variant="h5"
                  fontWeight="bold"
                  display="flex"
                  alignItems="flex-start"
                >
                  Categories
                  <Tooltip title="The list of categories that can be assigned to a policy for this company.">
                    <IconButton>
                      <HelpOutlineIcon color="icon" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </MDTypography>

                {isAdmin && (
                  <Fragment>
                    <DashboardActionButton
                      action={btnOnClickNewCategory}
                      btnText="Add Category"
                      btnIcon="add"
                      textColor="white"
                      bgColor="success"
                      marginTop="0px"
                    />
                  </Fragment>
                )}
              </MDBox>

              {isLoadingMoreCategories === false &&
              objTableData.rows.length > 0 &&
              totalCount > 0 &&
              isDeletingCategory == false ? (
                <CategoriesManagementTable
                  table={objTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={totalCount}
                  loadMoreCategories={loadMoreCategories}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  _page={page}
                  setPage={setPage}
                  setObjTableData={setObjTableData}
                  deleteCategory={deleteCategory}
                  editCategory={editCategory}
                />
              ) : (
                <Fragment>
                  {isLoadingMoreCategories || isDeletingCategory == true ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p>Please add some categories first.</p>
                  )}
                </Fragment>
              )}
            </Card>
          </Grid>
        </Grid>
        {/* end email Preferences */}

        <Grid
          spacing={2}
          container
          width="100%"
          sx={{ alignItems: "flex-start" }}
        >
          <Grid item xs={12} lg={12}>
            <Card sx={{ marginTop: "16px" }}>
              <MDBox p={2}>
                <MDTypography
                  variant="h5"
                  fontWeight="bold"
                  display="flex"
                  alignItems="flex-start"
                >
                  Merge Tags{" "}
                  <IconButton
                    sx={{ paddingTop: "2px" }}
                    aria-describedby={id}
                    type="button"
                    // onMouseEnter={handleOpenPopper}
                    onClick={handleOpenPopper}
                  >
                    <HelpOutlineIcon color="icon" fontSize="small" />
                  </IconButton>
                  <Popper
                    id={id}
                    open={openPopper}
                    anchorEl={anchorEl}
                    placement={"right"}
                  >
                    <Box
                      sx={{
                        width: "300px",
                        borderRadius: "10px",
                        color: "#fff",
                        p: 1,
                        background: "#100c08",
                      }}
                    >
                      {isMSPUser || isAdmin ? (
                        <>
                          <span>
                            {" "}
                            Edit the values for dynamic content to be used
                            within policies. These values are different PER
                            client. Add new merge tags for all clients{" "}
                            <a href="/msp-config" style={{ color: "white" }}>
                              <u>here</u>
                            </a>
                            .
                          </span>
                        </>
                      ) : (
                        "Edit the values for dynamic content to be used within policies. Contact your MSP to create new Merge Tags."
                      )}
                    </Box>
                  </Popper>
                </MDTypography>
              </MDBox>

              {!isFetchingMergeTags &&
              mergeTagsDataTableData?.rows?.length > 0 ? (
                <DataTable
                  table={mergeTagsDataTableData}
                  entriesPerPage={true}
                  showTotalEntries={false}
                  isSorted={false}
                />
              ) : (
                <Fragment>
                  {isFetchingMergeTags ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <MDTypography variant="p" mx={2}>
                      Please add some merge tags first.
                    </MDTypography>
                  )}
                </Fragment>
              )}
            </Card>
            <EditTagsDialog
              open={openMergeTagDialog}
              onClose={onTagDialogClose}
              onSubmit={onTagDialogSubmit}
              objUser={objUser}
              mergeTag={currentMergeTag}
            />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default CompanyConfig;
