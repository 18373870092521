import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
import { Fragment, useState } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { cancelMSPAccount } from "features/company/superAdminActions";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";

const objInitialValues = {
  txtDelete: "",
};
const AddNewSchema = Yup.object().shape({
  txtDelete: Yup.string()
    .required("This is required")
    .oneOf(["cancel"], "The value must be 'cancel'"),
});

const CustomerStatusBtnTr = (props) => {
  const { value, column, row } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedMSP, setSelectedMSP] = useState("");
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    console.log(row);
    const objPostData = {
      company_id: row.original.id,
    };
    setIsLoading(true);
    dispatch(cancelMSPAccount({ objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          showMsg("success", "MSP account has been cancelled successfully");
          window.location.reload();
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  const btnOnClickOpenAcccountCancellationConfirmationDialog = (row) => {
    setOpen(true);
    setSelectedMSP(row.companyName);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose} fullWidth={true}>
        <DialogTitle sx={{ pb: 0 }}>
          Are you sure you want to cancel {selectedMSP}'s MSP account?
        </DialogTitle>

        <DialogContent>
          <MDTypography
            variant="p"
            fontWeight={"bold"}
            sx={{ marginTop: "1em !important", display: "block" }}
            color="primary"
          >
            This action CANNOT be reversed and will do the following:
          </MDTypography>

          <li style={{ marginTop: "20px" }}>
            Cancel and stop their stripe billing
          </li>
          <li>Disabled all auth0 accounts associated with the MSP</li>
          <li>Disables all clients access</li>
          <li>Deletes all contacts associated with the MSP</li>
          <li>An cancellation email will be sent to the MSP</li>

          <Formik
            initialValues={objInitialValues}
            validationSchema={AddNewSchema}
            onSubmit={onSubmitForm}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id="AddNewForm" autoComplete="off">
                <DialogContent>
                  <MDBox mb={0} mt={0}>
                    <Field
                      type="text"
                      label="Please type 'cancel' in the field below to confirm"
                      name="txtDelete"
                      value={values.txtDelete}
                      placeholder={"Type 'cancel'"}
                      error={errors.txtDelete && touched.txtDelete}
                      success={values.txtDelete.length > 0 && !errors.txtDelete}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                      autoFocus
                    />
                    <MDBox mt={0.75}>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="error"
                        fontWeight="regular"
                      >
                        <ErrorMessage name="txtDelete" />
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </DialogContent>
                <DialogActions>
                  {isLoading === false ? (
                    <>
                      <MDButton
                        type="submit"
                        color="primary"
                        sx={{ padding: "12px 12px" }}
                      >
                        Cancel Account
                      </MDButton>
                      <MDButton
                        type="button"
                        variant="outlined"
                        color="dark"
                        sx={{ padding: "12px 12px" }}
                        onClick={onClose}
                      >
                        Close
                      </MDButton>
                    </>
                  ) : (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  )}
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <MDButton
        onClick={() =>
          btnOnClickOpenAcccountCancellationConfirmationDialog(row.original)
        }
      >
        <MDBox color="error" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>cancel</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="error"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            Cancel Account
          </MDTypography>
        </MDBox>
      </MDButton>
    </Fragment>
  );
};

export default CustomerStatusBtnTr;
