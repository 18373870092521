// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import { TextField } from "@mui/material";

const SecurityFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue, roleFormFiller } = props;

  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h5"
          fontWeight="bold"
        >
          Core Security Controls
        </MDTypography>
        <MDTypography px={5} variant="body2" fontWeight="regular" my={3}>
          *The following questions are most closely related to eligibility and
          rate.{" "}
          <b>
            All questions below require a response to receive an indication of
            pricing.
          </b>{" "}
          The applicant will then need to confirm final responses with the
          carrier for a bindable quote. If led, please feel free to use the
          notes to further explain the security controls you have in place.*
        </MDTypography>
        <MDBox>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            p={5}
            pt={2}
          >
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant have formal processes for backing up at least
                weekly, archiving and restoring sensitive data and critical
                systems?
              </MDTypography>
              <label htmlFor={"flag_formalbackups_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_formalbackups"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_formalbackups", "Yes");
                    }}
                    id={"flag_formalbackups_Yes"}
                    checked={values.flag_formalbackups === "Yes"}
                    error={
                      errors.flag_formalbackups && touched.flag_formalbackups
                    }
                    success={
                      values.flag_formalbackups.length > 0 &&
                      !errors.flag_formalbackups
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_formalbackups_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_formalbackups"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_formalbackups", "No");
                    }}
                    id={"flag_formalbackups_No"}
                    checked={values.flag_formalbackups === "No"}
                    error={
                      errors.flag_formalbackups && touched.flag_formalbackups
                    }
                    success={
                      values.flag_formalbackups.length > 0 &&
                      !errors.flag_formalbackups
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_formalbackups && touched.flag_formalbackups ? (
                <FormHelperText error>
                  {errors.flag_formalbackups}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Are the applicant’s backups disconnected/segregated from the
                network (segmenting between geographic locations, business
                units, and databases containing PII/PHI/PCI with at least 3
                segmented copies) either by a fully discrete system, domain &
                network segregation, or a cloud storage system that alerts and
                issues are remediated weekly?
              </MDTypography>
              <label htmlFor={"flag_BUsegregation_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_BUsegregation"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_BUsegregation", "Yes");
                    }}
                    id={"flag_BUsegregation_Yes"}
                    checked={values.flag_BUsegregation === "Yes"}
                    error={
                      errors.flag_BUsegregation && touched.flag_BUsegregation
                    }
                    success={
                      values.flag_BUsegregation.length > 0 &&
                      !errors.flag_BUsegregation
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_BUsegregation_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_BUsegregation"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_BUsegregation", "No");
                    }}
                    id={"flag_BUsegregation_No"}
                    checked={values.flag_BUsegregation === "No"}
                    error={
                      errors.flag_BUsegregation && touched.flag_BUsegregation
                    }
                    success={
                      values.flag_BUsegregation.length > 0 &&
                      !errors.flag_BUsegregation
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_BUsegregation && touched.flag_BUsegregation ? (
                <FormHelperText error>
                  {errors.flag_BUsegregation}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <TextField
                label="Additional Notes - Backups"
                fullWidth
                multiline
                minRows={8}
                name="notes_backups"
                value={values.notes_backups ?? ""}
                placeholder={"Type here..."}
                onChange={(e) => {
                  setFieldValue("notes_backups", e.target.value);
                }}
                error={errors.notes_backups && touched.notes_backups}
                success={
                  values.notes_backups?.length > 0 && !errors.notes_backups
                }
              />
              <MDTypography variant="caption" fontWeight="regular">
                Optional
              </MDTypography>
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant use a 24/7 staffed and managed
                Endpoint/Managed Detection and Response (EDR/MDR) that includes
                centralized monitoring and logging of all endpoint activity
                across the applicant's enterprise?
              </MDTypography>
              <label htmlFor={"flag_EDR_MDR_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_EDR_MDR"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_EDR_MDR", "Yes");
                    }}
                    id={"flag_EDR_MDR_Yes"}
                    checked={values.flag_EDR_MDR === "Yes"}
                    error={errors.flag_EDR_MDR && touched.flag_EDR_MDR}
                    success={
                      values.flag_EDR_MDR.length > 0 && !errors.flag_EDR_MDR
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_EDR_MDR_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_EDR_MDR"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_EDR_MDR", "No");
                    }}
                    id={"flag_EDR_MDR_No"}
                    checked={values.flag_EDR_MDR === "No"}
                    error={errors.flag_EDR_MDR && touched.flag_EDR_MDR}
                    success={
                      values.flag_EDR_MDR.length > 0 && !errors.flag_EDR_MDR
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_EDR_MDR && touched.flag_EDR_MDR ? (
                <FormHelperText error>{errors.flag_EDR_MDR}</FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant deploy a centrally managed next gen antivirus
                solution (NGAV) to all endpoints, including alerting for
                endpoints failing to communicate or update?
              </MDTypography>
              <label htmlFor={"flag_centralantivirussys_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_centralantivirussys"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_centralantivirussys", "Yes");
                    }}
                    id={"flag_centralantivirussys_Yes"}
                    checked={values.flag_centralantivirussys === "Yes"}
                    error={
                      errors.flag_centralantivirussys &&
                      touched.flag_centralantivirussys
                    }
                    success={
                      values.flag_centralantivirussys.length > 0 &&
                      !errors.flag_centralantivirussys
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_centralantivirussys_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_centralantivirussys"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_centralantivirussys", "No");
                    }}
                    id={"flag_centralantivirussys_No"}
                    checked={values.flag_centralantivirussys === "No"}
                    error={
                      errors.flag_centralantivirussys &&
                      touched.flag_centralantivirussys
                    }
                    success={
                      values.flag_centralantivirussys.length > 0 &&
                      !errors.flag_centralantivirussys
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_centralantivirussys &&
              touched.flag_centralantivirussys ? (
                <FormHelperText error>
                  {errors.flag_centralantivirussys}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <TextField
                label="Additional Notes - EDR and NGAV"
                fullWidth
                multiline
                minRows={8}
                name="notes_edr_ngav"
                value={values.notes_edr_ngav ?? ""}
                placeholder={"Type here..."}
                onChange={(e) => {
                  setFieldValue("notes_edr_ngav", e.target.value);
                }}
                error={errors.notes_edr_ngav && touched.notes_edr_ngav}
                success={
                  values.notes_edr_ngav?.length > 0 && !errors.notes_edr_ngav
                }
              />
              <MDTypography variant="caption" fontWeight="regular">
                Optional
              </MDTypography>
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                How frequently does the applicant implement critical or high
                category security, anti-virus and malware patches received from
                commercial software vendors onto all of its servers, laptops,
                desktops, routers, firewalls, phones and other physical devices?
              </MDTypography>
              <Grid container>
                <Grid item xs={4}>
                  <label htmlFor={"vulnmanagementdays_1-3 days"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"1-3 days"}
                        name={"vulnmanagementdays"}
                        inputProps={{ "aria-label": "1-3 days" }}
                        onChange={() => {
                          setFieldValue("vulnmanagementdays", "1-3 days");
                        }}
                        id={"vulnmanagementdays_1-3 days"}
                        checked={values.vulnmanagementdays === "1-3 days"}
                        error={
                          errors.vulnmanagementdays &&
                          touched.vulnmanagementdays
                        }
                        success={
                          values.vulnmanagementdays.length > 0 &&
                          !errors.vulnmanagementdays
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          1-3 days
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"vulnmanagementdays_8-30 days"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"8-30 days"}
                        name={"vulnmanagementdays"}
                        inputProps={{ "aria-label": "8-30 days" }}
                        onChange={() => {
                          setFieldValue("vulnmanagementdays", "8-30 days");
                        }}
                        id={"vulnmanagementdays_8-30 days"}
                        checked={values.vulnmanagementdays === "8-30 days"}
                        error={
                          errors.vulnmanagementdays &&
                          touched.vulnmanagementdays
                        }
                        success={
                          values.vulnmanagementdays.length > 0 &&
                          !errors.vulnmanagementdays
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          8-30 days
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"vulnmanagementdays_61 days or longer"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"61 days or longer"}
                        name={"vulnmanagementdays"}
                        inputProps={{ "aria-label": "61 days or longer" }}
                        onChange={() => {
                          setFieldValue(
                            "vulnmanagementdays",
                            "61 days or longer"
                          );
                        }}
                        id={"vulnmanagementdays_61 days or longer"}
                        checked={
                          values.vulnmanagementdays === "61 days or longer"
                        }
                        error={
                          errors.vulnmanagementdays &&
                          touched.vulnmanagementdays
                        }
                        success={
                          values.vulnmanagementdays.length > 0 &&
                          !errors.vulnmanagementdays
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          61 days or longer
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>

                <Grid item xs={4}>
                  <label htmlFor={"vulnmanagementdays_4-7 days"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"4-7 days"}
                        name={"vulnmanagementdays"}
                        inputProps={{ "aria-label": "4-7 days" }}
                        onChange={() => {
                          setFieldValue("vulnmanagementdays", "4-7 days");
                        }}
                        id={"vulnmanagementdays_4-7 days"}
                        checked={values.vulnmanagementdays === "4-7 days"}
                        error={
                          errors.vulnmanagementdays &&
                          touched.vulnmanagementdays
                        }
                        success={
                          values.vulnmanagementdays.length > 0 &&
                          !errors.vulnmanagementdays
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          4-7 days
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"vulnmanagementdays_31-60 days"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"31-60 days"}
                        name={"vulnmanagementdays"}
                        inputProps={{ "aria-label": "31-60 days" }}
                        onChange={() => {
                          setFieldValue("vulnmanagementdays", "31-60 days");
                        }}
                        id={"vulnmanagementdays_31-60 days"}
                        checked={values.vulnmanagementdays === "31-60 days"}
                        error={
                          errors.vulnmanagementdays &&
                          touched.vulnmanagementdays
                        }
                        success={
                          values.vulnmanagementdays.length > 0 &&
                          !errors.vulnmanagementdays
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          31-60 days
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"vulnmanagementdays_Never/not regularly"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Never/not regularly"}
                        name={"vulnmanagementdays"}
                        inputProps={{ "aria-label": "Never/not regularly" }}
                        onChange={() => {
                          setFieldValue(
                            "vulnmanagementdays",
                            "Never/not regularly"
                          );
                        }}
                        id={"vulnmanagementdays_Never/not regularly"}
                        checked={
                          values.vulnmanagementdays === "Never/not regularly"
                        }
                        error={
                          errors.vulnmanagementdays &&
                          touched.vulnmanagementdays
                        }
                        success={
                          values.vulnmanagementdays.length > 0 &&
                          !errors.vulnmanagementdays
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Never/not regularly
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
              </Grid>
              {errors.vulnmanagementdays && touched.vulnmanagementdays ? (
                <FormHelperText error>
                  {errors.vulnmanagementdays}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <TextField
                label="Additional Notes - Patching"
                fullWidth
                multiline
                minRows={8}
                name="notes_patching"
                value={values.notes_patching ?? ""}
                placeholder={"Type here..."}
                onChange={(e) => {
                  setFieldValue("notes_patching", e.target.value);
                }}
                error={errors.notes_patching && touched.notes_patching}
                success={
                  values.notes_patching?.length > 0 && !errors.notes_patching
                }
              />
              <MDTypography variant="caption" fontWeight="regular">
                Optional
              </MDTypography>
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant have an employee security awareness training
                program?
              </MDTypography>
              <label htmlFor={"flag_cybertraining_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_cybertraining"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_cybertraining", "Yes");
                    }}
                    id={"flag_cybertraining_Yes"}
                    checked={values.flag_cybertraining === "Yes"}
                    error={
                      errors.flag_cybertraining && touched.flag_cybertraining
                    }
                    success={
                      values.flag_cybertraining.length > 0 &&
                      !errors.flag_cybertraining
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_cybertraining_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_cybertraining"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_cybertraining", "No");
                    }}
                    id={"flag_cybertraining_No"}
                    checked={values.flag_cybertraining === "No"}
                    error={
                      errors.flag_cybertraining && touched.flag_cybertraining
                    }
                    success={
                      values.flag_cybertraining.length > 0 &&
                      !errors.flag_cybertraining
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>

              {errors.flag_cybertraining && touched.flag_cybertraining ? (
                <FormHelperText error>
                  {errors.flag_cybertraining}
                </FormHelperText>
              ) : null}
            </Grid>
            {values.flag_cybertraining === "Yes" && (
              <Grid item xs={12} lg={12}>
                <MDTypography variant="h6" fontWeight="regular">
                  If yes, does this include phishing simulation that tests all
                  users, including management, on at least a monthly basis?
                </MDTypography>
                <label htmlFor={"flag_cybertrainingphishingsim_Yes"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"Yes"}
                      name={"flag_cybertrainingphishingsim"}
                      inputProps={{ "aria-label": "Yes" }}
                      onChange={() => {
                        setFieldValue("flag_cybertrainingphishingsim", "Yes");
                      }}
                      id={"flag_cybertrainingphishingsim_Yes"}
                      checked={values.flag_cybertrainingphishingsim === "Yes"}
                      error={
                        errors.flag_cybertrainingphishingsim &&
                        touched.flag_cybertrainingphishingsim
                      }
                      success={
                        values.flag_cybertrainingphishingsim.length > 0 &&
                        !errors.flag_cybertrainingphishingsim
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Yes
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                <label htmlFor={"flag_cybertrainingphishingsim_No"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"No"}
                      name={"flag_cybertrainingphishingsim"}
                      inputProps={{ "aria-label": "No" }}
                      onChange={() => {
                        setFieldValue("flag_cybertrainingphishingsim", "No");
                      }}
                      id={"flag_cybertrainingphishingsim_No"}
                      checked={values.flag_cybertrainingphishingsim === "No"}
                      error={
                        errors.flag_cybertrainingphishingsim &&
                        touched.flag_cybertrainingphishingsim
                      }
                      success={
                        values.flag_cybertrainingphishingsim.length > 0 &&
                        !errors.flag_cybertrainingphishingsim
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        No
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                {errors.flag_cybertrainingphishingsim &&
                touched.flag_cybertrainingphishingsim ? (
                  <FormHelperText error>
                    {errors.flag_cybertrainingphishingsim}
                  </FormHelperText>
                ) : null}
              </Grid>
            )}

            {roleFormFiller !== "MSP or MSSP" && (
              <Grid item xs={12} lg={12}>
                <MDTypography variant="h6" fontWeight="regular">
                  Does the applicant have a formal wire transfer
                  fraud/policy/process, with executive sponsorship, to require
                  additional confirmation/security for all payments over $25K?
                </MDTypography>
                <label htmlFor={"flag_wiretransferdualtransfer_Yes"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"Yes"}
                      name={"flag_wiretransferdualtransfer"}
                      inputProps={{ "aria-label": "Yes" }}
                      onChange={() => {
                        setFieldValue("flag_wiretransferdualtransfer", "Yes");
                      }}
                      id={"flag_wiretransferdualtransfer_Yes"}
                      checked={values.flag_wiretransferdualtransfer === "Yes"}
                      error={
                        errors.flag_wiretransferdualtransfer &&
                        touched.flag_wiretransferdualtransfer
                      }
                      success={
                        values.flag_wiretransferdualtransfer.length > 0 &&
                        !errors.flag_wiretransferdualtransfer
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Yes
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                <label htmlFor={"flag_wiretransferdualtransfer_No"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"No"}
                      name={"flag_wiretransferdualtransfer"}
                      inputProps={{ "aria-label": "No" }}
                      onChange={() => {
                        setFieldValue("flag_wiretransferdualtransfer", "No");
                      }}
                      id={"flag_wiretransferdualtransfer_No"}
                      checked={values.flag_wiretransferdualtransfer === "No"}
                      error={
                        errors.flag_wiretransferdualtransfer &&
                        touched.flag_wiretransferdualtransfer
                      }
                      success={
                        values.flag_wiretransferdualtransfer.length > 0 &&
                        !errors.flag_wiretransferdualtransfer
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        No
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                {errors.flag_wiretransferdualtransfer &&
                touched.flag_wiretransferdualtransfer ? (
                  <FormHelperText error>
                    {errors.flag_wiretransferdualtransfer}
                  </FormHelperText>
                ) : null}
              </Grid>
            )}

            <Grid item xs={12} lg={12}>
              <TextField
                label="Additional Notes - Awareness Training"
                fullWidth
                multiline
                minRows={8}
                name="notes_awarenessTraining"
                value={values.notes_awarenessTraining ?? ""}
                placeholder={"Type here..."}
                onChange={(e) => {
                  setFieldValue("notes_awarenessTraining", e.target.value);
                }}
                error={
                  errors.notes_awarenessTraining &&
                  touched.notes_awarenessTraining
                }
                success={
                  values.notes_awarenessTraining?.length > 0 &&
                  !errors.notes_awarenessTraining
                }
              />
              <MDTypography variant="caption" fontWeight="regular">
                Optional
              </MDTypography>
            </Grid>

            {roleFormFiller !== "MSP or MSSP" && (
              <Grid item xs={12} lg={12}>
                <MDTypography variant="h6" fontWeight="regular">
                  Does the applicant enforce procedures to remove content
                  (including third party content) that may infringe or violate
                  any intellectual property or privacy right?
                </MDTypography>
                <label htmlFor={"flag_protectpropertyprivacyrights_Yes"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"Yes"}
                      name={"flag_protectpropertyprivacyrights"}
                      inputProps={{ "aria-label": "Yes" }}
                      onChange={() => {
                        setFieldValue(
                          "flag_protectpropertyprivacyrights",
                          "Yes"
                        );
                      }}
                      id={"flag_protectpropertyprivacyrights_Yes"}
                      checked={
                        values.flag_protectpropertyprivacyrights === "Yes"
                      }
                      error={
                        errors.flag_protectpropertyprivacyrights &&
                        touched.flag_protectpropertyprivacyrights
                      }
                      success={
                        values.flag_protectpropertyprivacyrights.length > 0 &&
                        !errors.flag_protectpropertyprivacyrights
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Yes
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                <label htmlFor={"flag_protectpropertyprivacyrights_No"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"No"}
                      name={"flag_protectpropertyprivacyrights"}
                      inputProps={{ "aria-label": "No" }}
                      onChange={() => {
                        setFieldValue(
                          "flag_protectpropertyprivacyrights",
                          "No"
                        );
                      }}
                      id={"flag_protectpropertyprivacyrights_No"}
                      checked={
                        values.flag_protectpropertyprivacyrights === "No"
                      }
                      error={
                        errors.flag_protectpropertyprivacyrights &&
                        touched.flag_protectpropertyprivacyrights
                      }
                      success={
                        values.flag_protectpropertyprivacyrights.length > 0 &&
                        !errors.flag_protectpropertyprivacyrights
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        No
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                {errors.flag_protectpropertyprivacyrights &&
                touched.flag_protectpropertyprivacyrights ? (
                  <FormHelperText error>
                    {errors.flag_protectpropertyprivacyrights}
                  </FormHelperText>
                ) : null}
              </Grid>
            )}
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default SecurityFormFormikWrapper;
