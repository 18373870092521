// ProductsList page components
import DefaultCell from "views/components/TableCells/DefaultCell";

const ClientContactsDataTableData = {
  columns: [
    {
      Header: "first name",
      accessor: "firstname",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "last name",
      accessor: "lastname",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "email",
      accessor: "email",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default ClientContactsDataTableData;
