import DefaultCell from "views/components/TableCells/DefaultCell";
import PostActionCell from "./PostActionCell";
import PostNameBtnTr from "./PostNameBtnTr";
import VideoCell from "./VideoCell";

const dataTableData = (onClickName) => ({
  columns: [
    {
      Header: "Title",
      accessor: "title",
      width: "50%",
      Cell: ({ value, row }) => (
        <PostNameBtnTr value={value} row={row} onClickName={onClickName} />
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      width: "30%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Content Type",
      accessor: "content_type",
      width: "15%",
      Cell: ({ value }) => (
        <DefaultCell value={value} sx={{ textTransform: "capitalize" }} />
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      width: "15%",
      Cell: ({ value }) => (
        <DefaultCell value={value} sx={{ textTransform: "capitalize" }} />
      ),
    },
    {
      Header: "Video",
      accessor: "video_url",
      width: "15%",
      Cell: ({ value, row }) => <VideoCell value={value} row={row} />,
    },
    {
      Header: "Owner",
      accessor: "created_by",
      width: "15%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ value, column, row }) => (
        <PostActionCell value={value} column={column} row={row} />
      ),
    },
  ],
  rows: [],
});

export default dataTableData;

