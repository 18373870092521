/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import { createSlice, current } from "@reduxjs/toolkit";
import {
  fetchClientContactsById,
  clearClientContactsById,
  storeClientContactByClientId,
  updateClientContactByClientId,
  fetchClientContactByContactId,
  deleteClientContactByContactId,
  deleteClientContactByContactIdfromStore,
} from "./clientContactsActions";

const initialState = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
};

const clientContactsSlice = createSlice({
  name: "clientContacts",
  initialState,
  reducers: {},
  extraReducers: {
    // fetchClientContactsById
    [fetchClientContactsById.pending]: (state) => {
      state.loading = true;
    },
    [fetchClientContactsById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objfetchClientContactsByIdData = payload;
    },
    [fetchClientContactsById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objfetchClientContactsByIdData = payload;
    },

    // clearClientContactsById
    [clearClientContactsById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objfetchClientContactsByIdData = payload;
    },
    [clearClientContactsById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objfetchClientContactsByIdData = payload;
    },

    // storeClientContactByClientId
    [storeClientContactByClientId.pending]: (state) => {
      state.loading = true;
    },
    [storeClientContactByClientId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objStoreClientContactByIdFormSuccess = payload;
    },
    [storeClientContactByClientId.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objStoreClientContactByIdFormSuccess = payload;
    },

    // updateClientContactByClientId
    [updateClientContactByClientId.pending]: (state) => {
      state.loading = true;
    },
    [updateClientContactByClientId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objUpdateClientContactByIdFormSuccess = payload;
    },
    [updateClientContactByClientId.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objUpdateClientContactByIdFormSuccess = payload;
    },

    // fetchClientContactByContactId
    [fetchClientContactByContactId.pending]: (state) => {
      state.loading = true;
    },
    [fetchClientContactByContactId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objFetchClientContactByContactIdData = payload;
    },
    [fetchClientContactByContactId.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objFetchClientContactByContactIdData = payload;
    },
    // deleteClientContactByContactId
    [deleteClientContactByContactId.pending]: (state) => {
      state.loading = true;
    },
    [deleteClientContactByContactId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objDeleteClientContactByContactIdData = payload;
    },
    [deleteClientContactByContactId.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objDeleteClientContactByContactIdData = payload;
    },
    // deleteClientContactByContactIdfromStore
    [deleteClientContactByContactIdfromStore.fulfilled]: (state, action) => {
      state.loading = false;

      let objCurrentClientContact = action.payload;

      const objNewClientContactsData =
        state.objfetchClientContactsByIdData.data.data.data.filter(
          (item) => item.id !== objCurrentClientContact.id
        );

      state.objfetchClientContactsByIdData.data.data.data =
        objNewClientContactsData;
    },
    [deleteClientContactByContactIdfromStore.rejected]: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.objfetchClientContactsByIdData = payload;
    },
  },
});

// export const { logout } = clientContactsSlice.actions;

export default clientContactsSlice.reducer;
