// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// page layout components
import CoverLayout from "views/containers/CoverLayout";
// Images
import bgImage from "assets/images/polygon-website-backdrop.jpg";
import Auth0VerifyEmailAddressSection from "./components/Auth0VerifyEmailAddressSection";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { auth0VerifiyEmailAddress } from "features/user/userAuth0Actions";
const Auth0VerifyEmailAddress = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [objVerifiyEmailAddressErrors, setObjVerifiyEmailAddressErrors] =
    useState({});

  useEffect(() => {
    const objPostData = { auth_id: objUser.user.auth_id };
    setObjVerifiyEmailAddressErrors({});
    setIsLoading(true);
    dispatch(auth0VerifiyEmailAddress(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);

        const response = originalPromiseResult;

        if (response.status === 200) {
          setIsSuccess(true);
          setTimeout(() => {
            if (objUser.user.role === "admin") {
              navigate("/setup-account/step-one");
            } else if (objUser.user.role === "company_user") {
              navigate("/clients");
            } else {
              navigate("/setup-account/step-one");
            }
          }, 3000);
        } else {
          setIsError(true);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <CoverLayout image={bgImage}>
      <MDBox display="flex" flexDirection="column" height="100%">
        <Auth0VerifyEmailAddressSection
          objUser={objUser}
          isLoading={isLoading}
          isError={isError}
          objVerifiyEmailAddressErrors={objVerifiyEmailAddressErrors}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
        />
      </MDBox>
    </CoverLayout>
  );
};

export default Auth0VerifyEmailAddress;
