export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
// export const BASE_URL =
//   process.env.NODE_ENV === "production"
//     ? process.env.REACT_APP_API_URL_PRODUCTION
//     : process.env.REACT_APP_API_URL;
// export const APP_URL =
//   process.env.NODE_ENV === "production"
//     ? process.env.REACT_APP_APP_URL_PRODUCTION
//     : process.env.REACT_APP_APP_URL;

// export const USER_SERVER = process.env.USER_SERVER;
// export const COMPANIES_SERVER = process.env.COMPANIES_SERVER;
// export const CLIENTS_SERVER = process.env.CLIENTS_SERVER;

export const VERSION_SERVER = "/api/v3";
export const BASE_ENDPOINT = BACKEND_API_URL + VERSION_SERVER + "/companies";

export const USER_SERVER = VERSION_SERVER + "/auth";
export const COMPANIES_SERVER = VERSION_SERVER + "/companies";

export const CLIENTS_SERVER = COMPANIES_SERVER + "/{companyId}/clients";
export const POLICIES_SERVER = CLIENTS_SERVER + "/{clientId}/policies";
export const TEMPLATES_SERVER = CLIENTS_SERVER + "/{clientId}/templates";
export const KBS_SERVER = CLIENTS_SERVER + "/{clientId}/kbs";
export const TINYMC_API_KEY = process.env.REACT_APP_TINYMC_API_KEY;
export const INACTIVE_LIMIT = process.env.REACT_APP_INACTIVE_LIMIT;
