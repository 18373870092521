import TemplateNameBtnTr from "./TemplateNameBtnTr";
import TemplateUnshareBtnTr from "./TemplateUnshareBtnTr";
import TemplateActionBtnTr from "./TemplateActionBtnTr";

const SharedTemplatesDataTableData = (objUser, reloadFunc) => ({
  columns: [
    {
      Header: "Name",
      accessor: "name",
      width: "25%",
      Cell: ({ value, column, row }) => (
        <TemplateNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      width: "30%",
    },
    {
      Header: "Owner",
      accessor: "owner",
      width: "30%",
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ value, column, row }) => (
        <>
          {!row.original.shareRequest ? (
            <TemplateActionBtnTr value={value} column={column} row={row} />
          ) : (
            <TemplateUnshareBtnTr
              value={value}
              column={column}
              row={row}
              reloadFunc={reloadFunc}
              objUser={objUser}
            />
          )}
        </>
      ),
    },
  ],
  rows: [],
});

export default SharedTemplatesDataTableData;
