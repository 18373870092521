import DefaultCell from "views/components/TableCells/DefaultCell";
import PolicyNameBtnTr from "./PolicyNameBtnTr";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const PoliciesDataTableData = {
  columns: [
    {
      Header: "Title",
      accessor: "title",
      width: "25%",
      Cell: ({ value, column, row }) => (
        <PolicyNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Category",
      accessor: "category",
      width: "15%",
    },
    {
      Header: "Frameworks",
      accessor: "current_rmfs",
      width: "15%",
      Cell: ({ value }) => (
        <MDBox
          sx={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            maxWidth: "200px",
          }}
        >
          <DefaultCell value={value} />
        </MDBox>
      ),
    },
    {
      Header: "Date created",
      accessor: "date_created",
      width: "30%",
    },
    {
      Header: "Created By",
      accessor: "creator_name",
      Cell: ({ value }) => (
        <MDBox
          sx={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            maxWidth: "200px",
          }}
        >
          <DefaultCell value={value} />
        </MDBox>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <MDBox sx={{ color: value == "Rejected" ? "red" : "" }}>{value}</MDBox>
      ),
    },
  ],
  rows: [],
};

export default PoliciesDataTableData;
