// ProductsList page components
import DefaultCell from "views/components/TableCells/DefaultCell";

const dataTableData = {
  columns: [
    {
      Header: "name",
      accessor: "customerName",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "address",
      accessor: "address",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "city",
      accessor: "city",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "state",
      accessor: "state",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "zip",
      accessor: "zip",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default dataTableData;
