/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// @mui icons
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AgreeToTermsDialog from "./AgreeToTermsDialog";
import CloneTemplateDialog from "./CloneTemplateDialog";
const TemplateActionBtnTr = (props) => {
  const { value, column, row } = props;
  // row.original.element,id
  // row.original.is_global_template
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const [openCloneTemplateDialog, setOpenCloneTemplateDialog] = useState(false);
  const [openTermsDialog, setOpenTermsDialog] = useState(false);

  const onSubmitCloneTemplateDialog = (templateId) => {
    setOpenCloneTemplateDialog(false);
    // window.location.reload();
    navigate(`/template-library`, {
      state: { routeDictionary },
    });
    window.location.reload();
  };

  const btnOnClickCloneTemplate = () => {
    setOpenCloneTemplateDialog(true);
  };

  const onClosCloneTemplateDialog = () => {
    setOpenCloneTemplateDialog(false);
  };

  const btnOnClickOpenTermsDialog = () => {
    setOpenTermsDialog(true);
  };

  const onCloseTermsDialog = () => {
    setOpenTermsDialog(false);
  };

  const disabled =
    row.original.global_template_type === "Plus" &&
    row.original.subscription_package !== "plus";

  return (
    <>
      <AgreeToTermsDialog
        open={openTermsDialog}
        onClose={onCloseTermsDialog}
        openCloneTemplateDialog={btnOnClickCloneTemplate}
        objUser={row.original.objUser}
        objTemplate={row.original.element}
        onSubmit={onSubmitCloneTemplateDialog}
      />
      {/* <CloneTemplateDialog
        open={openCloneTemplateDialog}
        onClose={onClosCloneTemplateDialog}
        objTemplate={row.original.element}
        onSubmit={onSubmitCloneTemplateDialog}
        objUser={row.original.objUser}
      /> */}
      {row.original.is_global_template ? (
        <MDButton
          // variant="gradient"
          color="success"
          // onClick={btnOnClickCloneTemplate}
          onClick={btnOnClickOpenTermsDialog}
          disabled={disabled}
        >
          <MDTypography
            color="white"
            sx={{ lineHeight: 0, fontSize: "0.7rem", fontWeight: "bold" }}
          >
            Import to MSP
          </MDTypography>
        </MDButton>
      ) : (
        ""
      )}
    </>
  );
};

export default TemplateActionBtnTr;
