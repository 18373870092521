import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import SaveApiKeysErrorList from "./SaveApiKeysErrorList";

const SaveApiKeysFormErrorsBlock = (props) => {
  const renderErrors = () =>
    props.objSaveApiKeysFormErrors
      ? Object.entries(props.objSaveApiKeysFormErrors).map(
          ([key, value], i) => <SaveApiKeysErrorList key={key} value={value} />
        )
      : null;
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity="error">
        {renderErrors(props.objSaveConnectWiseApiKeysFormErrors)}
      </Alert>
    </Stack>
  );
};

export default SaveApiKeysFormErrorsBlock;
