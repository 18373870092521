/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_ENDPOINT } from "components/utils/constants/misc";

export const sendToZappier = createAsyncThunk(
  "company/sendToZappier",
  async (values, thunkApi) => {
    try {
      const { objPostData, objUser } = values;
      const formData = new FormData();
      for (const [key, value] of Object.entries(objPostData)) {
        if (key === "file_currentcyberpolicy" && value instanceof Array) {
          for (let i = 0; i < value.length; i++) {
            formData.append(key, value[i]);
          }
        } else {
          formData.append(key, value);
        }
      }

      const response = await fetch(
        `https://hooks.zapier.com/hooks/catch/13441415/3lgha17/`,
        {
          method: "POST",
          body: formData,
          mode: "no-cors",
        }
      );
      const data = response;
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveExtraInfo = createAsyncThunk(
  "fifthwall/create",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${objUser.user.company_id}/fifthwall`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchExtraInfo = createAsyncThunk(
  "fifthwall/fetch",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${objUser.user.company_id}/fifthwall`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateFifthWallMembership = createAsyncThunk(
  "fifthwall/updateFifthWallMembership",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.patch(
        `${BASE_ENDPOINT}/${objUser.user.company_id}/fifthwall`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
