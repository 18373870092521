import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AddNewCustomerFormErrorLi from "./AddNewCustomerFormErrorLi";

const AddNewCustomerFormErrorsBlock = (props) => {
  // return (
  //   <Stack sx={{ width: "100%" }} spacing={2}>
  //     <Alert severity="error">This is an error alert — check it out!</Alert>
  //   </Stack>
  // );

  const renderErrors = () =>
    props.objAddNewCustomerFormErrors
      ? Object.entries(props.objAddNewCustomerFormErrors).map(
          ([key, value], i) => (
            <AddNewCustomerFormErrorLi key={key} value={value} />
          )
        )
      : null;
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity="error">
        {renderErrors(props.objAddNewCustomerFormErrors)}
      </Alert>
    </Stack>
  );
};

export default AddNewCustomerFormErrorsBlock;
