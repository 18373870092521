import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { COMPANIES_SERVER } from "components/utils/constants/misc";

export const setStripeSession = createAsyncThunk(
  "stripeCheckOut/setStripeSession",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/set-stripe-session`,
        values
      );
      // return res.data;
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const retrievePaymentMethodFromSession = createAsyncThunk(
  "stripeCheckOut/retrievePaymentMethodFromSession",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/retrieve-payment-method-from-session`,
        values
      );
      // return res.data;
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
