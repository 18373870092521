import { useState } from "react";
import { useDispatch } from "react-redux";
import { getTemplatePreview } from "features/company/templatesActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";

function useTemplatePreviewer() {
  const dispatch = useDispatch();
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [templateObj, setTemplateObj] = useState(null);

  const previewTemplate = (objUser = null, objId) => {
    setIsLoadingPreview(true);

    dispatch(getTemplatePreview({ objUser, objId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingPreview(false);
        const response = originalPromiseResult;
        let templateResp = response.data.templateResp;
        let document_sections = templateResp.template.document_sections ?? [];

        if (document_sections.length > 0) {
          document_sections = document_sections.sort(
            (a, b) =>
              a.owning_global_section.order - b.owning_global_section.order
          );
        }

        templateResp.template.document_sections = document_sections;

        setTemplateObj(response.data.templateResp);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingPreview(false);
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Error fetching preview. Please try again");
      });
  };

  return { previewTemplate, isLoadingPreview, templateObj, setTemplateObj };
}

export default useTemplatePreviewer;
