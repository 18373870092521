import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import InviteAdminUsersDialog from "./components/InviteAdminUsersDialog/InviteAdminUsersDialog";
import UsersManagementListCard from "./components/UsersManagementListCard/UsersManagementListCard";
const PeerGroupAdmins = (props) => {
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [openInviteAdminUsersDialog, setOpenInviteAdminUsersDialog] =
    useState(false);
  const [cookies] = useCookies(["isAuthenticated"]);

  const btnOnClickInviteAdminUser = () => {
    setOpenInviteAdminUsersDialog(true);
  };

  useEffect(() => {
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <MDButton
            sx={{ padding: "5px 15px", marginTop: "15px" }}
            color={"success"}
            onClick={() => btnOnClickInviteAdminUser()}
          >
            <Icon fontSize="large">add</Icon>
            <MDTypography
              fontWeight="bold"
              sx={{ marginLeft: "0.75rem" }}
              color={"white"}
              variant="button"
            >
              Add Peer Group Admin
            </MDTypography>
          </MDButton>
        </MDBox>
        <UsersManagementListCard objUser={props.objUser} />
      </MDBox>
      <InviteAdminUsersDialog
        openInviteAdminUsersDialog={openInviteAdminUsersDialog}
        setOpenInviteAdminUsersDialog={setOpenInviteAdminUsersDialog}
      />
    </DashboardLayout>
  );
};

export default PeerGroupAdmins;
