import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/pages/users/new-user/components/FormField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListSubheader from "@mui/material/ListSubheader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { loadCampaign, resendCampaign } from "features/company/policiesActions";
import {
  resendCampaignToAllNotAdopted,
  exportAdoptersListToCsv,
} from "features/company/adoptionCampaignActions";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardActionButton from "views/components/DashboardActionButton";
import { Chip, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { displayExceptionErrorMessage } from "utils/general";

function Index({ objUser }) {
  const [campaignData, setCampaignData] = useState(null);
  const [sendingTo, setSendingTo] = useState([]);
  const [sendingToAll, setSendingToAll] = useState(null);
  const [exportingToCsv, setExportingToCSv] = useState(null);

  const navigate = useNavigate();

  let { campaign_id } = useParams();
  const campaignId = campaign_id;

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setCampaignData(null);
    if (campaignId === 0) return;
    dispatch(
      loadCampaign({
        objUser,
        campaignId,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        response.data.adopters_status.forEach(
          (a) => (a.name = a.first_name + " " + a.last_name)
        );
        response.data.adopters_adopted = response.data.adopters_status.filter(
          (a) => a.is_adopted
        );
        response.data.adopters_not_adopted =
          response.data.adopters_status.filter((a) => !a.is_adopted);

        setCampaignData(response.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        setCampaignData(null);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
        navigate(`/adoption-campaigns`);
      });
  }, [objUser, campaignId]);

  const resendNotif = (contactId) => () => {
    setSendingTo([...sendingTo, contactId]);
    dispatch(
      resendCampaign({
        objUser,
        campaignId,
        contactId,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        showMsg("success", "Notification resent successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        showMsg("error", "Something went wrong, please try again");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      })
      .finally(() =>
        setSendingTo((oldValue) => oldValue.filter((el) => el !== contactId))
      );
  };

  const resendToAll = () => {
    setSendingToAll(true);
    dispatch(
      resendCampaignToAllNotAdopted({
        objUser,
        campaignId,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        showMsg(
          "success",
          "Notification resent successfully to all contacts who haven't adopted"
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        showMsg("error", "Something went wrong, please try again");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      })
      .finally(() => {
        setSendingToAll(false);
      });
  };

  const exportToCsv = (output) => {
    setExportingToCSv(true);
    dispatch(
      exportAdoptersListToCsv({
        objUser,
        campaignId,
        campaignData,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setExportingToCSv(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Adopters list ` + output); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setExportingToCSv(false);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid container width="100%" spacing={2}>
        <Grid item xs={12} lg={12}>
          <MDBox
            display="flex"
            gap=".6rem"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mb={2}
          >
            <MDTypography
              variant="h4"
              sx={{ paddingTop: "1.2rem", marginRight: "auto" }}
            >
              View Campaign{" "}
              {campaignData != null
                ? "(" + campaignData.campaign_name + ")"
                : ""}
            </MDTypography>

            {exportingToCsv ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="md"
              />
            ) : (
              <DashboardActionButton
                action={exportToCsv}
                btnText="Export Adopters List To CSV"
                btnIcon="file_copy"
                textColor="white"
                bgColor="info"
              />
            )}

            {sendingToAll ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="md"
              />
            ) : (
              <DashboardActionButton
                action={resendToAll}
                btnText="Resend Email To All"
                btnIcon="send"
                textColor="white"
                bgColor="success"
                buttonDisabled={campaignData?.adoption_status === "Adopted"}
              />
            )}
          </MDBox>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Card
            sx={{
              minHeight: "300px",
              width: "100%",
              padding: "1rem",
            }}
          >
            {campaignData !== null ? (
              <>
                {campaignData.adoption_status == "Draft" ? (
                  <Chip
                    label={"Not Adopted"}
                    color="primary"
                    sx={{ width: "10%" }}
                  ></Chip>
                ) : (
                  <Chip
                    label={"Adopted"}
                    color="success"
                    sx={{ width: "10%", color: "white !important" }}
                  ></Chip>
                )}
                <br />

                <MDBox>
                  <MDTypography color="dark" variant="h6">
                    {campaignData.adopters_adopted.length} /{" "}
                    {campaignData.adopters_status.length} Adopted
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <Typography
                    component="div"
                    variant="body2"
                    color="text.secundary"
                  >
                    Contacts will be automatically reminded every
                    {campaignData.reminder_time > 1
                      ? ` ${campaignData.reminder_time} days`
                      : "day"}
                  </Typography>
                </MDBox>
                <div style={{ marginTop: "20px" }}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      position: "relative",
                      overflow: "auto",
                      "& ul": { padding: 0 },
                    }}
                    subheader={<li />}
                  >
                    {campaignData.adoption_status === "Adopted" || (
                      <>
                        <MDTypography color="primary" variant="h6" mb={1.3}>
                          Not adopted
                        </MDTypography>
                        {campaignData.adopters_not_adopted.map((contact) => (
                          <ListItem
                            key={`${contact.id}`}
                            sx={{
                              marginBottom: "1rem",
                              border: "1px solid #eee",
                              backgroundColor: "#eee",
                              borderRadius: "10px",
                              padding: "1rem",
                            }}
                            secondaryAction={
                              !sendingTo.includes(contact.id) ? (
                                <MDButton
                                  type="button"
                                  variant="outlined"
                                  color="dark"
                                  sx={{
                                    padding: { xs: "0 2px", sm: "12px 12px" },
                                    marginRight: "5px",
                                    border: { xs: "none", sm: "1px solid" },
                                  }}
                                  onClick={resendNotif(contact.id)}
                                >
                                  <Icon>send</Icon>
                                  Resend
                                </MDButton>
                              ) : (
                                <div style={{ marginRight: "10px" }}>
                                  <LoadingSpinner
                                    subClass="text-center"
                                    color="success"
                                    size="md"
                                  />
                                </div>
                              )
                            }
                          >
                            <ListItemText
                              primary={`${contact.name} (${contact.email})`}
                            />
                          </ListItem>
                        ))}
                      </>
                    )}
                    <>
                      <MDTypography color="success" variant="h6" mb={1.3}>
                        Adopted
                      </MDTypography>
                      {campaignData.adopters_adopted.map((contact) => (
                        <ListItem
                          key={`${contact.id}`}
                          sx={{
                            marginBottom: "1rem",
                            border: "2px solid #eee",
                            borderRadius: "10px",
                            padding: "1rem",
                          }}
                        >
                          <ListItemText
                            primary={`${contact.name} (${contact.email})`}
                          />
                        </ListItem>
                      ))}
                    </>
                  </List>
                </div>
              </>
            ) : (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
export default Index;
