// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";

// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";

const EditConnectWiseClientFormFormikWrapper = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    objStates,
    objProvinces,
    objCountries,
  } = props;
  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h4"
          fontWeight="bold"
        >
          Edit Client
        </MDTypography>
        <MDBox mt={2}>
          <MDTypography
            px={5}
            textAlign="left"
            mb={1}
            variant="h6"
            fontWeight="bold"
          >
            1. Basic Information
          </MDTypography>
          <Grid container spacing={3} justifyContent="space-between" p={5}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Client Name"
                    name="txtClientName"
                    value={values.txtClientName}
                    placeholder={"Client Name"}
                    error={errors.txtClientName && touched.txtClientName}
                    success={
                      values.txtClientName.length > 0 && !errors.txtClientName
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Address 1"
                    name="txtAddress1"
                    value={values.txtAddress1}
                    placeholder={"Address 1"}
                    error={errors.txtAddress1 && touched.txtAddress1}
                    success={
                      values.txtAddress1.length > 0 && !errors.txtAddress1
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDBox mt={-1.625}>
                    <FormField
                      type="text"
                      label="Address 2"
                      name="txtAddress2"
                      value={values.txtAddress2}
                      placeholder={"Address 2"}
                      error={errors.txtAddress2 && touched.txtAddress2}
                      success={
                        values.txtAddress2.length > 0 && !errors.txtAddress2
                      }
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="City"
                    name="txtCity"
                    value={values.txtCity}
                    placeholder={"City"}
                    error={errors.txtCity && touched.txtCity}
                    success={values.txtCity.length > 0 && !errors.txtCity}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {values.drpCountry === "United States of America" && (
                      <Grid item xs={6}>
                        <Autocomplete
                          // options={["State 1", "State 2", "State 3"]}
                          options={objStates}
                          defaultValue={values.drpState}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              variant="standard"
                              label="State"
                            />
                          )}
                          onChange={(event, value) => {
                            if (value === null) {
                              setFieldValue("drpState", "");
                            } else {
                              setFieldValue("drpState", value.label);
                            }
                          }}
                        />
                        {errors.drpState && touched.drpState ? (
                          <FormHelperText error>
                            {errors.drpState}
                          </FormHelperText>
                        ) : null}
                      </Grid>
                    )}
                    {values.drpCountry === "Canada" && (
                      <Grid item xs={6}>
                        <Autocomplete
                          // options={["State 1", "State 2", "State 3"]}
                          options={objProvinces}
                          defaultValue={values.drpState}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              variant="standard"
                              label="Province"
                            />
                          )}
                          onChange={(event, value) => {
                            if (value === null) {
                              setFieldValue("drpState", "");
                            } else {
                              setFieldValue("drpState", value.label);
                            }
                          }}
                        />
                        {errors.drpState && touched.drpState ? (
                          <FormHelperText error>
                            {errors.drpState}
                          </FormHelperText>
                        ) : null}
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={
                        values.drpCountry === "United States of America" ||
                        values.drpCountry === "Canada"
                          ? 6
                          : 12
                      }
                    >
                      <Autocomplete
                        options={objCountries}
                        defaultValue={values.drpCountry}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label="Country"
                          />
                        )}
                        onChange={(event, value) => {
                          setFieldValue("drpState", "");
                          if (value === null) {
                            setFieldValue("drpCountry", "");
                          } else {
                            setFieldValue("drpCountry", value.label);
                          }
                        }}
                      />
                      {errors.drpCountry && touched.drpCountry ? (
                        <FormHelperText error>
                          {errors.drpCountry}
                        </FormHelperText>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type={
                      values.drpCountry === "United States of America"
                        ? "number"
                        : "text"
                    }
                    label="Zip"
                    name="txtZip"
                    value={values.txtZip}
                    placeholder={"Zip"}
                    error={errors.txtZip && touched.txtZip}
                    success={values.txtZip.length > 0 && !errors.txtZip}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default EditConnectWiseClientFormFormikWrapper;
