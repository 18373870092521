import React, { useEffect, useRef, useState } from "react";
import { auth } from "../../features/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

const RedirectToDashboardIfLoggedInCheck = ({
  Component,
  checkAuth,
  page_name = "",
}) => {
  const dispatch = useDispatch();
  const _isMounted = useRef(true); // Initial value _isMounted = true
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadAuthData();

    return () => {
      _isMounted.current = false;
    };
  }, [page_name]);

  const { objUser } = useSelector((state) => ({
    objUser: state.auth.user.objUser,
  }));

  const loadAuthData = async () => {
    setLoading(true);
    const objUserAuthResp = await dispatch(auth());

    const objUserAuth = objUserAuthResp.payload;
    if (checkAuth && !objUserAuth.isAuth) {
    } else if (checkAuth && objUserAuth.isAuth) {
      navigate("/home");
      return false;
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingSpinner subClass="text-center" color="lightblueColor" />;
  }

  if (
    loading === false &&
    checkAuth &&
    objUser !== undefined &&
    Object.keys(objUser).length > 0
  ) {
    let { user } = objUser;

    return <Component objUser={user} />;
  } else {
    return <LoadingSpinner subClass="text-center" color="lightblueColor" />;
  }
};

export default RedirectToDashboardIfLoggedInCheck;
