import DefaultCell from "views/components/TableCells/DefaultCell";

const UsersManagementListCardDataTableData = {
  columns: [
    {
      Header: "first name",
      accessor: "user",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "last name",
      accessor: "lastName",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "role",
      accessor: "role",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default UsersManagementListCardDataTableData;
