import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import dataTableData from "views/pages/Dashboard/Companies/data/dataTableData";
import { useDispatch } from "react-redux";
import { fetchAllCompanies } from "features/company/superAdminActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CompaniesManagementTable from "./components/CompaniesManagementTable/CompaniesManagementTable";
import { showMsg } from "utils/general";
import "./Companies.css";
import MDTypography from "components/MDTypography";

import { useCookies } from "react-cookie";
import { displayExceptionErrorMessage } from "utils/general";
import GeneratePax8BillingDialog from "./components/GeneratePax8BillingDialog/GeneratePax8BillingDialog";
import DashboardActionButton from "views/components/DashboardActionButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const Companies = (props) => {
  const { objUser } = props;
  const [isFetchingCompanies, setIsFetchingCompanies] = useState(false);
  const [openNewPolicyDialog, setOpenNewPolicyDialog] = useState(false);
  const [excludeFromReports, setExcludeFromReports] = useState([]);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [perPage, setPerPage] = useState(2);
  const [page, setPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const isAdmin =
    objUser.user && objUser.user.role
      ? objUser.user.role.name == "admin"
      : false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  useEffect(() => {
    if (objUser.user.is_super_admin != 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    fetchCompanies(perPage, page);
  }, []);

  const formatPhone = (inputValue) => {
    let previousValue = inputValue;

    if (inputValue) {
      const numericValue = inputValue.replace(/[^\d]/g, ""); // Remove non-numeric characters

      if (numericValue.length === 10) {
        return `${numericValue.slice(0, 3)}-${numericValue.slice(
          3,
          6
        )}-${numericValue.slice(6)}`;
      } else if (numericValue.length === 11) {
        return `(${numericValue[0]}) ${numericValue.slice(
          1,
          4
        )}-${numericValue.slice(4, 7)}-${numericValue.slice(7)}`;
      } else if (numericValue.length > 11) {
        return previousValue;
      } else {
        return numericValue;
      }
    } else {
      return inputValue;
    }
  };

  const fetchCompanies = (_per_page, _page) => {
    setIsFetchingCompanies(true);
    dispatch(fetchAllCompanies({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingCompanies(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objCompaniesList = response.data.companies;
        const excludeArray = objCompaniesList
          .filter((company) => company.exclude_from_reports === 1)
          .map((company) => company.id);
        setExcludeFromReports(excludeArray);
        for (let index = 0; index < objCompaniesList.length; index++) {
          const element = objCompaniesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.companyName = element.name;
          objTr.companyEmail = element.email;
          objTr.status =
            element.status === "PENDING_SUBSCRIPTION"
              ? "Signed up"
              : "Has billing";
          objTr.plan = element.subscription_package;
          objTr.maxClients = element.units;
          objTr.companyPhone =
            element.users.length > 0
              ? formatPhone(element.users[0].phone)
              : "-";
          objTr.numberOfClients = element.clients_count;
          objTr.numberOfUsers = element.users_count;
          objTr.numberOfPolicies = element.policies_count;
          objTr.dateJoined = element.formatted_created_at;
          objTr.lastLogin = element.last_login;
          objTr.exclude_from_reports = element.exclude_from_reports;
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingCompanies(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const btnOnClickNewPolicy = () => {
    setOpenNewPolicyDialog(true);
  };

  const onCloseNewPolicyDialog = () => {
    setOpenNewPolicyDialog(false);
  };

  const onSubmitNewPolicyDialog = () => {
    setOpenNewPolicyDialog(false);
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <GeneratePax8BillingDialog
          open={openNewPolicyDialog}
          onClose={onCloseNewPolicyDialog}
          onSubmit={onSubmitNewPolicyDialog}
          objUser={props.objUser}
        />
      </LocalizationProvider>
      <MDBox>
        <MDBox
          display="flex"
          gap=".6rem"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <MDTypography variant="h3">MSP Stats</MDTypography>
          <DashboardActionButton
            action={btnOnClickNewPolicy}
            btnText="Generate Pax8 Billing"
            btnIcon="save"
            textColor="white"
            bgColor="success"
          />
        </MDBox>
        <Card sx={{ marginTop: "16px" }}>
          {isFetchingCompanies === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <CompaniesManagementTable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              fetchCompanies={fetchCompanies}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              setObjTableData={setObjTableData}
              excludeFromReports={excludeFromReports}
              setExcludeFromReports={setExcludeFromReports}
              objUser={objUser}
            />
          ) : (
            <Fragment>
              {isFetchingCompanies ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>No companies data</p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default Companies;
