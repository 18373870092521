// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import { TextField } from "@mui/material";

const AdditionalFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue, roleFormFiller } = props;

  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h5"
          fontWeight="bold"
        >
          Additional Security Controls
        </MDTypography>
        <MDTypography px={5} variant="body2" fontWeight="regular" my={3}>
          *Responses to the questions below will better indicate eligible carrier
          options for the applicant. The more questions answered, the more
          likely FifthWall will provide pricing from a truly eligible carrier.*
        </MDTypography>
        <MDBox>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            p={5}
            pt={2}
          >
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant collect, process, store, transmit, or have
                access to any Payment Card Information (PCI), Personally
                Identifiable Information (PII), or Protected Health Information
                (PHI) other than employees of the applicant?
              </MDTypography>
              <label htmlFor={"flag_recordsthirdparty_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_recordsthirdparty"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_recordsthirdparty", "Yes");
                    }}
                    id={"flag_recordsthirdparty_Yes"}
                    checked={values.flag_recordsthirdparty === "Yes"}
                    error={
                      errors.flag_recordsthirdparty &&
                      touched.flag_recordsthirdparty
                    }
                    success={
                      values.flag_recordsthirdparty.length > 0 &&
                      !errors.flag_recordsthirdparty
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_recordsthirdparty_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_recordsthirdparty"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_recordsthirdparty", "No");
                    }}
                    id={"flag_recordsthirdparty_No"}
                    checked={values.flag_recordsthirdparty === "No"}
                    error={
                      errors.flag_recordsthirdparty &&
                      touched.flag_recordsthirdparty
                    }
                    success={
                      values.flag_recordsthirdparty.length > 0 &&
                      !errors.flag_recordsthirdparty
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_recordsthirdparty &&
              touched.flag_recordsthirdparty ? (
                <FormHelperText error>
                  {errors.flag_recordsthirdparty}
                </FormHelperText>
              ) : null}
            </Grid>

            {values.flag_recordsthirdparty === "Yes" && (
              <>
                <Grid item xs={12} lg={12}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Estimated number of PII records?
                  </MDTypography>
                  <Grid container>
                    <Grid item xs={4}>
                      <label
                        htmlFor={"estnumberofrecordsPII_Less than 100,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"Less than 100,000"}
                            name={"estnumberofrecordsPII"}
                            inputProps={{ "aria-label": "Less than 100,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPII",
                                "Less than 100,000"
                              );
                            }}
                            id={"estnumberofrecordsPII_Less than 100,000"}
                            checked={
                              values.estnumberofrecordsPII ===
                              "Less than 100,000"
                            }
                            error={
                              errors.estnumberofrecordsPII &&
                              touched.estnumberofrecordsPII
                            }
                            success={
                              values.estnumberofrecordsPII.length > 0 &&
                              !errors.estnumberofrecordsPII
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              Less than 100,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                      <label
                        htmlFor={"estnumberofrecordsPII_500,001 - 1,000,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"500,001 - 1,000,000"}
                            name={"estnumberofrecordsPII"}
                            inputProps={{ "aria-label": "500,001 - 1,000,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPII",
                                "500,001 - 1,000,000"
                              );
                            }}
                            id={"estnumberofrecordsPII_500,001 - 1,000,000"}
                            checked={
                              values.estnumberofrecordsPII ===
                              "500,001 - 1,000,000"
                            }
                            error={
                              errors.estnumberofrecordsPII &&
                              touched.estnumberofrecordsPII
                            }
                            success={
                              values.estnumberofrecordsPII.length > 0 &&
                              !errors.estnumberofrecordsPII
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              500,001 - 1,000,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <label
                        htmlFor={"estnumberofrecordsPII_100,000 - 250,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"100,000 - 250,000"}
                            name={"estnumberofrecordsPII"}
                            inputProps={{ "aria-label": "100,000 - 250,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPII",
                                "100,000 - 250,000"
                              );
                            }}
                            id={"estnumberofrecordsPII_100,000 - 250,000"}
                            checked={
                              values.estnumberofrecordsPII ===
                              "100,000 - 250,000"
                            }
                            error={
                              errors.estnumberofrecordsPII &&
                              touched.estnumberofrecordsPII
                            }
                            success={
                              values.estnumberofrecordsPII.length > 0 &&
                              !errors.estnumberofrecordsPII
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              100,000 - 250,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                      <label
                        htmlFor={"estnumberofrecordsPII_More than 1,000,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"More than 1,000,000"}
                            name={"estnumberofrecordsPII"}
                            inputProps={{ "aria-label": "More than 1,000,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPII",
                                "More than 1,000,000"
                              );
                            }}
                            id={"estnumberofrecordsPII_More than 1,000,000"}
                            checked={
                              values.estnumberofrecordsPII ===
                              "More than 1,000,000"
                            }
                            error={
                              errors.estnumberofrecordsPII &&
                              touched.estnumberofrecordsPII
                            }
                            success={
                              values.estnumberofrecordsPII.length > 0 &&
                              !errors.estnumberofrecordsPII
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              More than 1,000,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <label
                        htmlFor={"estnumberofrecordsPII_250,001 - 500,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"250,001 - 500,000"}
                            name={"estnumberofrecordsPII"}
                            inputProps={{ "aria-label": "250,001 - 500,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPII",
                                "250,001 - 500,000"
                              );
                            }}
                            id={"estnumberofrecordsPII_250,001 - 500,000"}
                            checked={
                              values.estnumberofrecordsPII ===
                              "250,001 - 500,000"
                            }
                            error={
                              errors.estnumberofrecordsPII &&
                              touched.estnumberofrecordsPII
                            }
                            success={
                              values.estnumberofrecordsPII.length > 0 &&
                              !errors.estnumberofrecordsPII
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              250,001 - 500,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>
                  </Grid>
                  {errors.estnumberofrecordsPII &&
                  touched.estnumberofrecordsPII ? (
                    <FormHelperText error>
                      {errors.estnumberofrecordsPII}
                    </FormHelperText>
                  ) : null}
                </Grid>

                <Grid item xs={12} lg={12}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Estimated number of PHI records?
                  </MDTypography>
                  <Grid container>
                    <Grid item xs={4}>
                      <label
                        htmlFor={"estnumberofrecordsPHI_Less than 100,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"Less than 100,000"}
                            name={"estnumberofrecordsPHI"}
                            inputProps={{ "aria-label": "Less than 100,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPHI",
                                "Less than 100,000"
                              );
                            }}
                            id={"estnumberofrecordsPHI_Less than 100,000"}
                            checked={
                              values.estnumberofrecordsPHI ===
                              "Less than 100,000"
                            }
                            error={
                              errors.estnumberofrecordsPHI &&
                              touched.estnumberofrecordsPHI
                            }
                            success={
                              values.estnumberofrecordsPHI.length > 0 &&
                              !errors.estnumberofrecordsPHI
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              Less than 100,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                      <label
                        htmlFor={"estnumberofrecordsPHI_500,001 - 1,000,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"500,001 - 1,000,000"}
                            name={"estnumberofrecordsPHI"}
                            inputProps={{ "aria-label": "500,001 - 1,000,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPHI",
                                "500,001 - 1,000,000"
                              );
                            }}
                            id={"estnumberofrecordsPHI_500,001 - 1,000,000"}
                            checked={
                              values.estnumberofrecordsPHI ===
                              "500,001 - 1,000,000"
                            }
                            error={
                              errors.estnumberofrecordsPHI &&
                              touched.estnumberofrecordsPHI
                            }
                            success={
                              values.estnumberofrecordsPHI.length > 0 &&
                              !errors.estnumberofrecordsPHI
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              500,001 - 1,000,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <label
                        htmlFor={"estnumberofrecordsPHI_100,000 - 250,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"100,000 - 250,000"}
                            name={"estnumberofrecordsPHI"}
                            inputProps={{ "aria-label": "100,000 - 250,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPHI",
                                "100,000 - 250,000"
                              );
                            }}
                            id={"estnumberofrecordsPHI_100,000 - 250,000"}
                            checked={
                              values.estnumberofrecordsPHI ===
                              "100,000 - 250,000"
                            }
                            error={
                              errors.estnumberofrecordsPHI &&
                              touched.estnumberofrecordsPHI
                            }
                            success={
                              values.estnumberofrecordsPHI.length > 0 &&
                              !errors.estnumberofrecordsPHI
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              100,000 - 250,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                      <label
                        htmlFor={"estnumberofrecordsPHI_More than 1,000,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"More than 1,000,000"}
                            name={"estnumberofrecordsPHI"}
                            inputProps={{ "aria-label": "More than 1,000,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPHI",
                                "More than 1,000,000"
                              );
                            }}
                            id={"estnumberofrecordsPHI_More than 1,000,000"}
                            checked={
                              values.estnumberofrecordsPHI ===
                              "More than 1,000,000"
                            }
                            error={
                              errors.estnumberofrecordsPHI &&
                              touched.estnumberofrecordsPHI
                            }
                            success={
                              values.estnumberofrecordsPHI.length > 0 &&
                              !errors.estnumberofrecordsPHI
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              More than 1,000,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <label
                        htmlFor={"estnumberofrecordsPHI_250,001 - 500,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"250,001 - 500,000"}
                            name={"estnumberofrecordsPHI"}
                            inputProps={{ "aria-label": "250,001 - 500,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPHI",
                                "250,001 - 500,000"
                              );
                            }}
                            id={"estnumberofrecordsPHI_250,001 - 500,000"}
                            checked={
                              values.estnumberofrecordsPHI ===
                              "250,001 - 500,000"
                            }
                            error={
                              errors.estnumberofrecordsPHI &&
                              touched.estnumberofrecordsPHI
                            }
                            success={
                              values.estnumberofrecordsPHI.length > 0 &&
                              !errors.estnumberofrecordsPHI
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              250,001 - 500,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>
                  </Grid>
                  {errors.estnumberofrecordsPHI &&
                  touched.estnumberofrecordsPHI ? (
                    <FormHelperText error>
                      {errors.estnumberofrecordsPHI}
                    </FormHelperText>
                  ) : null}
                </Grid>
              </>
            )}
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the Applicant accept payment cards in exchange for goods or
                services rendered?
              </MDTypography>
              <label htmlFor={"flag_paymentcard_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_paymentcard"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_paymentcard", "Yes");
                    }}
                    id={"flag_paymentcard_Yes"}
                    checked={values.flag_paymentcard === "Yes"}
                    error={errors.flag_paymentcard && touched.flag_paymentcard}
                    success={
                      values.flag_paymentcard.length > 0 &&
                      !errors.flag_paymentcard
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label
                htmlFor={"flag_paymentcard_Yes, via an outsourced processor"}
              >
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes, via an outsourced processor"}
                    name={"flag_paymentcard"}
                    inputProps={{
                      "aria-label": "Yes, via an outsourced processor",
                    }}
                    onChange={() => {
                      setFieldValue(
                        "flag_paymentcard",
                        "Yes, via an outsourced processor"
                      );
                    }}
                    id={"flag_paymentcard_Yes, via an outsourced processor"}
                    checked={
                      values.flag_paymentcard ===
                      "Yes, via an outsourced processor"
                    }
                    error={errors.flag_paymentcard && touched.flag_paymentcard}
                    success={
                      values.flag_paymentcard.length > 0 &&
                      !errors.flag_paymentcard
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes, via an outsourced processor
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_paymentcard_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_paymentcard"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_paymentcard", "No");
                    }}
                    id={"flag_paymentcard_No"}
                    checked={values.flag_paymentcard === "No"}
                    error={errors.flag_paymentcard && touched.flag_paymentcard}
                    success={
                      values.flag_paymentcard.length > 0 &&
                      !errors.flag_paymentcard
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_paymentcard && touched.flag_paymentcard ? (
                <FormHelperText error>{errors.flag_paymentcard}</FormHelperText>
              ) : null}
            </Grid>
            {values.flag_recordsthirdparty === "Yes" && (
              <>
                <Grid item xs={12} lg={12}>
                  <MDTypography variant="h6" fontWeight="regular">
                    Estimated number of PCI records?
                  </MDTypography>
                  <Grid container>
                    <Grid item xs={4}>
                      <label
                        htmlFor={"estnumberofrecordsPCI_Less than 100,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"Less than 100,000"}
                            name={"estnumberofrecordsPCI"}
                            inputProps={{ "aria-label": "Less than 100,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPCI",
                                "Less than 100,000"
                              );
                            }}
                            id={"estnumberofrecordsPCI_Less than 100,000"}
                            checked={
                              values.estnumberofrecordsPCI ===
                              "Less than 100,000"
                            }
                            error={
                              errors.estnumberofrecordsPCI &&
                              touched.estnumberofrecordsPCI
                            }
                            success={
                              values.estnumberofrecordsPCI.length > 0 &&
                              !errors.estnumberofrecordsPCI
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              Less than 100,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                      <label
                        htmlFor={"estnumberofrecordsPCI_500,001 - 1,000,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"500,001 - 1,000,000"}
                            name={"estnumberofrecordsPCI"}
                            inputProps={{ "aria-label": "500,001 - 1,000,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPCI",
                                "500,001 - 1,000,000"
                              );
                            }}
                            id={"estnumberofrecordsPCI_500,001 - 1,000,000"}
                            checked={
                              values.estnumberofrecordsPCI ===
                              "500,001 - 1,000,000"
                            }
                            error={
                              errors.estnumberofrecordsPCI &&
                              touched.estnumberofrecordsPCI
                            }
                            success={
                              values.estnumberofrecordsPCI.length > 0 &&
                              !errors.estnumberofrecordsPCI
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              500,001 - 1,000,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <label
                        htmlFor={"estnumberofrecordsPCI_100,000 - 250,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"100,000 - 250,000"}
                            name={"estnumberofrecordsPCI"}
                            inputProps={{ "aria-label": "100,000 - 250,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPCI",
                                "100,000 - 250,000"
                              );
                            }}
                            id={"estnumberofrecordsPCI_100,000 - 250,000"}
                            checked={
                              values.estnumberofrecordsPCI ===
                              "100,000 - 250,000"
                            }
                            error={
                              errors.estnumberofrecordsPCI &&
                              touched.estnumberofrecordsPCI
                            }
                            success={
                              values.estnumberofrecordsPCI.length > 0 &&
                              !errors.estnumberofrecordsPCI
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              100,000 - 250,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                      <label
                        htmlFor={"estnumberofrecordsPCI_More than 1,000,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"More than 1,000,000"}
                            name={"estnumberofrecordsPCI"}
                            inputProps={{ "aria-label": "More than 1,000,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPCI",
                                "More than 1,000,000"
                              );
                            }}
                            id={"estnumberofrecordsPCI_More than 1,000,000"}
                            checked={
                              values.estnumberofrecordsPCI ===
                              "More than 1,000,000"
                            }
                            error={
                              errors.estnumberofrecordsPCI &&
                              touched.estnumberofrecordsPCI
                            }
                            success={
                              values.estnumberofrecordsPCI.length > 0 &&
                              !errors.estnumberofrecordsPCI
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              More than 1,000,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <label
                        htmlFor={"estnumberofrecordsPCI_250,001 - 500,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"250,001 - 500,000"}
                            name={"estnumberofrecordsPCI"}
                            inputProps={{ "aria-label": "250,001 - 500,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberofrecordsPCI",
                                "250,001 - 500,000"
                              );
                            }}
                            id={"estnumberofrecordsPCI_250,001 - 500,000"}
                            checked={
                              values.estnumberofrecordsPCI ===
                              "250,001 - 500,000"
                            }
                            error={
                              errors.estnumberofrecordsPCI &&
                              touched.estnumberofrecordsPCI
                            }
                            success={
                              values.estnumberofrecordsPCI.length > 0 &&
                              !errors.estnumberofrecordsPCI
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              250,001 - 500,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>
                  </Grid>
                  {errors.estnumberofrecordsPCI &&
                  touched.estnumberofrecordsPCI ? (
                    <FormHelperText error>
                      {errors.estnumberofrecordsPCI}
                    </FormHelperText>
                  ) : null}
                </Grid>

                <Grid item xs={12} lg={12}>
                  <MDTypography variant="h6" fontWeight="regular">
                    How many biometric information records or data (i.e
                    fingerprints, retinal scans, etc.) does the applicant
                    collect, process, store, transmit, or have access to that
                    can be used to uniquely identify a person?
                  </MDTypography>
                  <Grid container>
                    <Grid item xs={4}>
                      <label htmlFor={"estnumberbiometricrecords_No records"}>
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"No records"}
                            name={"estnumberbiometricrecords"}
                            inputProps={{ "aria-label": "No records" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberbiometricrecords",
                                "No records"
                              );
                            }}
                            id={"estnumberbiometricrecords_No records"}
                            checked={
                              values.estnumberbiometricrecords === "No records"
                            }
                            error={
                              errors.estnumberbiometricrecords &&
                              touched.estnumberbiometricrecords
                            }
                            success={
                              values.estnumberbiometricrecords.length > 0 &&
                              !errors.estnumberbiometricrecords
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              No records
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                      <label
                        htmlFor={"estnumberbiometricrecords_250,001 - 500,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"250,001 - 500,000"}
                            name={"estnumberbiometricrecords"}
                            inputProps={{ "aria-label": "250,001 - 500,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberbiometricrecords",
                                "250,001 - 500,000"
                              );
                            }}
                            id={"estnumberbiometricrecords_250,001 - 500,000"}
                            checked={
                              values.estnumberbiometricrecords ===
                              "250,001 - 500,000"
                            }
                            error={
                              errors.estnumberbiometricrecords &&
                              touched.estnumberbiometricrecords
                            }
                            success={
                              values.estnumberbiometricrecords.length > 0 &&
                              !errors.estnumberbiometricrecords
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              250,001 - 500,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <label
                        htmlFor={"estnumberbiometricrecords_Less than 100,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"Less than 100,000"}
                            name={"estnumberbiometricrecords"}
                            inputProps={{ "aria-label": "Less than 100,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberbiometricrecords",
                                "Less than 100,000"
                              );
                            }}
                            id={"estnumberbiometricrecords_Less than 100,000"}
                            checked={
                              values.estnumberbiometricrecords ===
                              "Less than 100,000"
                            }
                            error={
                              errors.estnumberbiometricrecords &&
                              touched.estnumberbiometricrecords
                            }
                            success={
                              values.estnumberbiometricrecords.length > 0 &&
                              !errors.estnumberbiometricrecords
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              Less than 100,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                      <label
                        htmlFor={
                          "estnumberbiometricrecords_500,001 - 1,000,000"
                        }
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"500,001 - 1,000,000"}
                            name={"estnumberbiometricrecords"}
                            inputProps={{ "aria-label": "500,001 - 1,000,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberbiometricrecords",
                                "500,001 - 1,000,000"
                              );
                            }}
                            id={"estnumberbiometricrecords_500,001 - 1,000,000"}
                            checked={
                              values.estnumberbiometricrecords ===
                              "500,001 - 1,000,000"
                            }
                            error={
                              errors.estnumberbiometricrecords &&
                              touched.estnumberbiometricrecords
                            }
                            success={
                              values.estnumberbiometricrecords.length > 0 &&
                              !errors.estnumberbiometricrecords
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              500,001 - 1,000,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <label
                        htmlFor={"estnumberbiometricrecords_100,000 - 250,000"}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"100,000 - 250,000"}
                            name={"estnumberbiometricrecords"}
                            inputProps={{ "aria-label": "100,000 - 250,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberbiometricrecords",
                                "100,000 - 250,000"
                              );
                            }}
                            id={"estnumberbiometricrecords_100,000 - 250,000"}
                            checked={
                              values.estnumberbiometricrecords ===
                              "100,000 - 250,000"
                            }
                            error={
                              errors.estnumberbiometricrecords &&
                              touched.estnumberbiometricrecords
                            }
                            success={
                              values.estnumberbiometricrecords.length > 0 &&
                              !errors.estnumberbiometricrecords
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              100,000 - 250,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                      <label
                        htmlFor={
                          "estnumberbiometricrecords_More than 1,000,000"
                        }
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            value={"More than 1,000,000"}
                            name={"estnumberbiometricrecords"}
                            inputProps={{ "aria-label": "More than 1,000,000" }}
                            onChange={() => {
                              setFieldValue(
                                "estnumberbiometricrecords",
                                "More than 1,000,000"
                              );
                            }}
                            id={"estnumberbiometricrecords_More than 1,000,000"}
                            checked={
                              values.estnumberbiometricrecords ===
                              "More than 1,000,000"
                            }
                            error={
                              errors.estnumberbiometricrecords &&
                              touched.estnumberbiometricrecords
                            }
                            success={
                              values.estnumberbiometricrecords.length > 0 &&
                              !errors.estnumberbiometricrecords
                            }
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              More than 1,000,000
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </label>
                    </Grid>
                  </Grid>
                  {errors.estnumberbiometricrecords &&
                  touched.estnumberbiometricrecords ? (
                    <FormHelperText error>
                      {errors.estnumberbiometricrecords}
                    </FormHelperText>
                  ) : null}
                </Grid>
              </>
            )}

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Is the applicant in compliance with PCI DSS (Payment Card
                Industry Data Security Standard)?
              </MDTypography>
              <label htmlFor={"flag_PCIcompliance_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_PCIcompliance"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_PCIcompliance", "Yes");
                    }}
                    id={"flag_PCIcompliance_Yes"}
                    checked={values.flag_PCIcompliance === "Yes"}
                    error={
                      errors.flag_PCIcompliance && touched.flag_PCIcompliance
                    }
                    success={
                      values.flag_PCIcompliance.length > 0 &&
                      !errors.flag_PCIcompliance
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_PCIcompliance_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_PCIcompliance"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_PCIcompliance", "No");
                    }}
                    id={"flag_PCIcompliance_No"}
                    checked={values.flag_PCIcompliance === "No"}
                    error={
                      errors.flag_PCIcompliance && touched.flag_PCIcompliance
                    }
                    success={
                      values.flag_PCIcompliance.length > 0 &&
                      !errors.flag_PCIcompliance
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_PCIcompliance && touched.flag_PCIcompliance ? (
                <FormHelperText error>
                  {errors.flag_PCIcompliance}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Is the applicant's outsourced processor in compliance with PCI
                DSS (Payment Card Industry Data Security Standard)?
              </MDTypography>
              <label htmlFor={"flag_PCIcompliance_outsourced_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_PCIcompliance_outsourced"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_PCIcompliance_outsourced", "Yes");
                    }}
                    id={"flag_PCIcompliance_outsourced_Yes"}
                    checked={values.flag_PCIcompliance_outsourced === "Yes"}
                    error={
                      errors.flag_PCIcompliance_outsourced &&
                      touched.flag_PCIcompliance_outsourced
                    }
                    success={
                      values.flag_PCIcompliance_outsourced.length > 0 &&
                      !errors.flag_PCIcompliance_outsourced
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_PCIcompliance_outsourced_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_PCIcompliance_outsourced"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_PCIcompliance_outsourced", "No");
                    }}
                    id={"flag_PCIcompliance_outsourced_No"}
                    checked={values.flag_PCIcompliance_outsourced === "No"}
                    error={
                      errors.flag_PCIcompliance_outsourced &&
                      touched.flag_PCIcompliance_outsourced
                    }
                    success={
                      values.flag_PCIcompliance_outsourced.length > 0 &&
                      !errors.flag_PCIcompliance_outsourced
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_PCIcompliance_outsourced &&
              touched.flag_PCIcompliance_outsourced ? (
                <FormHelperText error>
                  {errors.flag_PCIcompliance_outsourced}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant provide data processing, storage, or hosting
                services to third parties?
              </MDTypography>
              <label htmlFor={"thirdpartydataservicing_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"thirdpartydataservicing"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("thirdpartydataservicing", "Yes");
                    }}
                    id={"thirdpartydataservicing_Yes"}
                    checked={values.thirdpartydataservicing === "Yes"}
                    error={
                      errors.thirdpartydataservicing &&
                      touched.thirdpartydataservicing
                    }
                    success={
                      values.thirdpartydataservicing.length > 0 &&
                      !errors.thirdpartydataservicing
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"thirdpartydataservicing_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"thirdpartydataservicing"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("thirdpartydataservicing", "No");
                    }}
                    id={"thirdpartydataservicing_No"}
                    checked={values.thirdpartydataservicing === "No"}
                    error={
                      errors.thirdpartydataservicing &&
                      touched.thirdpartydataservicing
                    }
                    success={
                      values.thirdpartydataservicing.length > 0 &&
                      !errors.thirdpartydataservicing
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.thirdpartydataservicing &&
              touched.thirdpartydataservicing ? (
                <FormHelperText error>
                  {errors.thirdpartydataservicing}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Is the applicant's SOC monitored 24 hours a day, 7 days a week?
              </MDTypography>
              <label htmlFor={"flag_247SOC_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_247SOC"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_247SOC", "Yes");
                    }}
                    id={"flag_247SOC_Yes"}
                    checked={values.flag_247SOC === "Yes"}
                    error={errors.flag_247SOC && touched.flag_247SOC}
                    success={
                      values.flag_247SOC.length > 0 && !errors.flag_247SOC
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_247SOC_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_247SOC"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_247SOC", "No");
                    }}
                    id={"flag_247SOC_No"}
                    checked={values.flag_247SOC === "No"}
                    error={errors.flag_247SOC && touched.flag_247SOC}
                    success={
                      values.flag_247SOC.length > 0 && !errors.flag_247SOC
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_247SOC && touched.flag_247SOC ? (
                <FormHelperText error>{errors.flag_247SOC}</FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                How many service accounts with domain administrator privileges
                are in the applicant's IT environment? “Service accounts” are
                non-human privileged accounts used to execute applications,
                access local and network resources, and run automated services,
                virtual machine instances, and other processes.
              </MDTypography>
              <Grid container>
                <Grid item xs={4}>
                  <label htmlFor={"domainadminserviceaccts_>10"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={">10"}
                        name={"domainadminserviceaccts"}
                        inputProps={{ "aria-label": ">10" }}
                        onChange={() => {
                          setFieldValue("domainadminserviceaccts", ">10");
                        }}
                        id={"domainadminserviceaccts_>10"}
                        checked={values.domainadminserviceaccts === ">10"}
                        error={
                          errors.domainadminserviceaccts &&
                          touched.domainadminserviceaccts
                        }
                        success={
                          values.domainadminserviceaccts.length > 0 &&
                          !errors.domainadminserviceaccts
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          {">10"}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"domainadminserviceaccts_1-5"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"1-5"}
                        name={"domainadminserviceaccts"}
                        inputProps={{ "aria-label": "1-5" }}
                        onChange={() => {
                          setFieldValue("domainadminserviceaccts", "1-5");
                        }}
                        id={"domainadminserviceaccts_1-5"}
                        checked={values.domainadminserviceaccts === "1-5"}
                        error={
                          errors.domainadminserviceaccts &&
                          touched.domainadminserviceaccts
                        }
                        success={
                          values.domainadminserviceaccts.length > 0 &&
                          !errors.domainadminserviceaccts
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          1-5
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>

                <Grid item xs={4}>
                  <label htmlFor={"domainadminserviceaccts_6-10"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"6-10"}
                        name={"domainadminserviceaccts"}
                        inputProps={{ "aria-label": "6-10" }}
                        onChange={() => {
                          setFieldValue("domainadminserviceaccts", "6-10");
                        }}
                        id={"domainadminserviceaccts_6-10"}
                        checked={values.domainadminserviceaccts === "6-10"}
                        error={
                          errors.domainadminserviceaccts &&
                          touched.domainadminserviceaccts
                        }
                        success={
                          values.domainadminserviceaccts.length > 0 &&
                          !errors.domainadminserviceaccts
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          6-10
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"domainadminserviceaccts_0"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"0"}
                        name={"domainadminserviceaccts"}
                        inputProps={{ "aria-label": "0" }}
                        onChange={() => {
                          setFieldValue("domainadminserviceaccts", "0");
                        }}
                        id={"domainadminserviceaccts_0"}
                        checked={values.domainadminserviceaccts === "0"}
                        error={
                          errors.domainadminserviceaccts &&
                          touched.domainadminserviceaccts
                        }
                        success={
                          values.domainadminserviceaccts.length > 0 &&
                          !errors.domainadminserviceaccts
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          0
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                </Grid>
              </Grid>
              {errors.domainadminserviceaccts &&
              touched.domainadminserviceaccts ? (
                <FormHelperText error>
                  {errors.domainadminserviceaccts}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Do you only allow RDP over internal network connections (either
                direct connect on the insured network or via VPN)?
              </MDTypography>
              <label htmlFor={"flag_RDPviaVPNonly_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_RDPviaVPNonly"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_RDPviaVPNonly", "Yes");
                    }}
                    id={"flag_RDPviaVPNonly_Yes"}
                    checked={values.flag_RDPviaVPNonly === "Yes"}
                    error={
                      errors.flag_RDPviaVPNonly && touched.flag_RDPviaVPNonly
                    }
                    success={
                      values.flag_RDPviaVPNonly.length > 0 &&
                      !errors.flag_RDPviaVPNonly
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_RDPviaVPNonly_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_RDPviaVPNonly"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_RDPviaVPNonly", "No");
                    }}
                    id={"flag_RDPviaVPNonly_No"}
                    checked={values.flag_RDPviaVPNonly === "No"}
                    error={
                      errors.flag_RDPviaVPNonly && touched.flag_RDPviaVPNonly
                    }
                    success={
                      values.flag_RDPviaVPNonly.length > 0 &&
                      !errors.flag_RDPviaVPNonly
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_RDPviaVPNonly && touched.flag_RDPviaVPNonly ? (
                <FormHelperText error>
                  {errors.flag_RDPviaVPNonly}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Do any users accounts (including the IT teams’ standard user
                logins) have administrator rights on their workstations?
              </MDTypography>
              <label htmlFor={"flag_localadminrights_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_localadminrights"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_localadminrights", "Yes");
                    }}
                    id={"flag_localadminrights_Yes"}
                    checked={values.flag_localadminrights === "Yes"}
                    error={
                      errors.flag_localadminrights &&
                      touched.flag_localadminrights
                    }
                    success={
                      values.flag_localadminrights.length > 0 &&
                      !errors.flag_localadminrights
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_localadminrights_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_localadminrights"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_localadminrights", "No");
                    }}
                    id={"flag_localadminrights_No"}
                    checked={values.flag_localadminrights === "No"}
                    error={
                      errors.flag_localadminrights &&
                      touched.flag_localadminrights
                    }
                    success={
                      values.flag_localadminrights.length > 0 &&
                      !errors.flag_localadminrights
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_localadminrights && touched.flag_localadminrights ? (
                <FormHelperText error>
                  {errors.flag_localadminrights}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Do you manage privileged accounts using privileged access
                management software (PAM)?
              </MDTypography>
              <label htmlFor={"flag_pam_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_pam"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_pam", "Yes");
                    }}
                    id={"flag_pam_Yes"}
                    checked={values.flag_pam === "Yes"}
                    error={errors.flag_pam && touched.flag_pam}
                    success={values.flag_pam.length > 0 && !errors.flag_pam}
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_pam_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_pam"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_pam", "No");
                    }}
                    id={"flag_pam_No"}
                    checked={values.flag_pam === "No"}
                    error={errors.flag_pam && touched.flag_pam}
                    success={values.flag_pam.length > 0 && !errors.flag_pam}
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_pam && touched.flag_pam ? (
                <FormHelperText error>{errors.flag_pam}</FormHelperText>
              ) : null}
            </Grid>

            {values.flag_pam === "Yes" && (
              <>
                <Grid item xs={12} lg={12}>
                  <MDTypography variant="h6" fontWeight="regular">
                    If “Yes”, are all privileged accounts managed with a PAM
                    tool?
                  </MDTypography>
                  <label htmlFor={"flag_pamallprivilegedaccts_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"flag_pamallprivilegedaccts"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("flag_pamallprivilegedaccts", "Yes");
                        }}
                        id={"flag_pamallprivilegedaccts_Yes"}
                        checked={values.flag_pamallprivilegedaccts === "Yes"}
                        error={
                          errors.flag_pamallprivilegedaccts &&
                          touched.flag_pamallprivilegedaccts
                        }
                        success={
                          values.flag_pamallprivilegedaccts.length > 0 &&
                          !errors.flag_pamallprivilegedaccts
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"flag_pamallprivilegedaccts_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"flag_pamallprivilegedaccts"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("flag_pamallprivilegedaccts", "No");
                        }}
                        id={"flag_pamallprivilegedaccts_No"}
                        checked={values.flag_pamallprivilegedaccts === "No"}
                        error={
                          errors.flag_pamallprivilegedaccts &&
                          touched.flag_pamallprivilegedaccts
                        }
                        success={
                          values.flag_pamallprivilegedaccts.length > 0 &&
                          !errors.flag_pamallprivilegedaccts
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  {errors.flag_pamallprivilegedaccts &&
                  touched.flag_pamallprivilegedaccts ? (
                    <FormHelperText error>
                      {errors.flag_pamallprivilegedaccts}
                    </FormHelperText>
                  ) : null}
                </Grid>

                <Grid item xs={12} lg={12}>
                  <MDTypography variant="h6" fontWeight="regular" my={2}>
                    If "Yes", provide the name of your PAM software provider:
                  </MDTypography>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormField
                        type="text"
                        label=""
                        name="pamprovider"
                        value={values.pamprovider ?? ""}
                        placeholder={""}
                        error={errors.pamprovider && touched.pamprovider}
                        success={
                          values.pamprovider.length > 0 && !errors.pamprovider
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <MDTypography variant="h6" fontWeight="regular">
                    If "Yes", is access protected by MFA?
                  </MDTypography>
                  <label htmlFor={"flag_pammfa_Yes"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"Yes"}
                        name={"flag_pammfa"}
                        inputProps={{ "aria-label": "Yes" }}
                        onChange={() => {
                          setFieldValue("flag_pammfa", "Yes");
                        }}
                        id={"flag_pammfa_Yes"}
                        checked={values.flag_pammfa === "Yes"}
                        error={errors.flag_pammfa && touched.flag_pammfa}
                        success={
                          values.flag_pammfa.length > 0 && !errors.flag_pammfa
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          Yes
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  <label htmlFor={"flag_pammfa_No"}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        value={"No"}
                        name={"flag_pammfa"}
                        inputProps={{ "aria-label": "No" }}
                        onChange={() => {
                          setFieldValue("flag_pammfa", "No");
                        }}
                        id={"flag_pammfa_No"}
                        checked={values.flag_pammfa === "No"}
                        error={errors.flag_pammfa && touched.flag_pammfa}
                        success={
                          values.flag_pammfa.length > 0 && !errors.flag_pammfa
                        }
                      />
                      <MDBox sx={{ flexGrow: 1 }}>
                        <MDTypography variant="body2" fontWeight="regular">
                          No
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </label>
                  {errors.flag_pammfa && touched.flag_pammfa ? (
                    <FormHelperText error>{errors.flag_pammfa}</FormHelperText>
                  ) : null}
                </Grid>
              </>
            )}

            <Grid item xs={12} lg={12}>
              <TextField
                label="Additional Notes - PAM"
                fullWidth
                multiline
                minRows={8}
                name="notes_pam"
                value={values.notes_pam ?? ""}
                placeholder={"Type here..."}
                onChange={(e) => {
                  setFieldValue("notes_pam", e.target.value);
                }}
                error={errors.notes_pam && touched.notes_pam}
                success={values.notes_pam?.length > 0 && !errors.notes_pam}
              />
              <MDTypography variant="caption" fontWeight="regular">
                Optional
              </MDTypography>
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Is MFA restricted to app and token based MFA only?
              </MDTypography>
              <label htmlFor={"flag_MFArestricted_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_MFArestricted"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_MFArestricted", "Yes");
                    }}
                    id={"flag_MFArestricted_Yes"}
                    checked={values.flag_MFArestricted === "Yes"}
                    error={
                      errors.flag_MFArestricted && touched.flag_MFArestricted
                    }
                    success={
                      values.flag_MFArestricted.length > 0 &&
                      !errors.flag_MFArestricted
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_MFArestricted_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_MFArestricted"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_MFArestricted", "No");
                    }}
                    id={"flag_MFArestricted_No"}
                    checked={values.flag_MFArestricted === "No"}
                    error={
                      errors.flag_MFArestricted && touched.flag_MFArestricted
                    }
                    success={
                      values.flag_MFArestricted.length > 0 &&
                      !errors.flag_MFArestricted
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_MFArestricted && touched.flag_MFArestricted ? (
                <FormHelperText error>
                  {errors.flag_MFArestricted}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant do vulnerability scans, including internally
                on the network and any permitted cloud systems, at least
                monthly, of 50%+ of the applicant's IT assets?
              </MDTypography>
              <label htmlFor={"flag_vulnscans_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_vulnscans"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_vulnscans", "Yes");
                    }}
                    id={"flag_vulnscans_Yes"}
                    checked={values.flag_vulnscans === "Yes"}
                    error={errors.flag_vulnscans && touched.flag_vulnscans}
                    success={
                      values.flag_vulnscans.length > 0 && !errors.flag_vulnscans
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_vulnscans_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_vulnscans"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_vulnscans", "No");
                    }}
                    id={"flag_vulnscans_No"}
                    checked={values.flag_vulnscans === "No"}
                    error={errors.flag_vulnscans && touched.flag_vulnscans}
                    success={
                      values.flag_vulnscans.length > 0 && !errors.flag_vulnscans
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_vulnscans && touched.flag_vulnscans ? (
                <FormHelperText error>{errors.flag_vulnscans}</FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant's EDR solution include monitoring of all
                domain controllers?
              </MDTypography>
              <label htmlFor={"EDRalldomaincontrollers_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"EDRalldomaincontrollers"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("EDRalldomaincontrollers", "Yes");
                    }}
                    id={"EDRalldomaincontrollers_Yes"}
                    checked={values.EDRalldomaincontrollers === "Yes"}
                    error={
                      errors.EDRalldomaincontrollers &&
                      touched.EDRalldomaincontrollers
                    }
                    success={
                      values.EDRalldomaincontrollers.length > 0 &&
                      !errors.EDRalldomaincontrollers
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"EDRalldomaincontrollers_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"EDRalldomaincontrollers"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("EDRalldomaincontrollers", "No");
                    }}
                    id={"EDRalldomaincontrollers_No"}
                    checked={values.EDRalldomaincontrollers === "No"}
                    error={
                      errors.EDRalldomaincontrollers &&
                      touched.EDRalldomaincontrollers
                    }
                    success={
                      values.EDRalldomaincontrollers.length > 0 &&
                      !errors.EDRalldomaincontrollers
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.EDRalldomaincontrollers &&
              touched.EDRalldomaincontrollers ? (
                <FormHelperText error>
                  {errors.EDRalldomaincontrollers}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Can users access the network with their own device ("Bring Your
                Own Device")?
              </MDTypography>
              <label htmlFor={"flag_bringyourowndevice_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_bringyourowndevice"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_bringyourowndevice", "Yes");
                    }}
                    id={"flag_bringyourowndevice_Yes"}
                    checked={values.flag_bringyourowndevice === "Yes"}
                    error={
                      errors.flag_bringyourowndevice &&
                      touched.flag_bringyourowndevice
                    }
                    success={
                      values.flag_bringyourowndevice.length > 0 &&
                      !errors.flag_bringyourowndevice
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_bringyourowndevice_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_bringyourowndevice"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_bringyourowndevice", "No");
                    }}
                    id={"flag_bringyourowndevice_No"}
                    checked={values.flag_bringyourowndevice === "No"}
                    error={
                      errors.flag_bringyourowndevice &&
                      touched.flag_bringyourowndevice
                    }
                    success={
                      values.flag_bringyourowndevice.length > 0 &&
                      !errors.flag_bringyourowndevice
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_bringyourowndevice &&
              touched.flag_bringyourowndevice ? (
                <FormHelperText error>
                  {errors.flag_bringyourowndevice}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant use an endpoint based protective DNS service?
              </MDTypography>
              <label htmlFor={"flag_DNS_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_DNS"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_DNS", "Yes");
                    }}
                    id={"flag_DNS_Yes"}
                    checked={values.flag_DNS === "Yes"}
                    error={errors.flag_DNS && touched.flag_DNS}
                    success={values.flag_DNS.length > 0 && !errors.flag_DNS}
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_DNS_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_DNS"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_DNS", "No");
                    }}
                    id={"flag_DNS_No"}
                    checked={values.flag_DNS === "No"}
                    error={errors.flag_DNS && touched.flag_DNS}
                    success={values.flag_DNS.length > 0 && !errors.flag_DNS}
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_DNS && touched.flag_DNS ? (
                <FormHelperText error>{errors.flag_DNS}</FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Do you disable macros in the applicant's office productivity
                software by default? (E.g., Microsoft Office, Google Workspace)
              </MDTypography>
              <label htmlFor={"flag_macrodisable_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_macrodisable"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_macrodisable", "Yes");
                    }}
                    id={"flag_macrodisable_Yes"}
                    checked={values.flag_macrodisable === "Yes"}
                    error={
                      errors.flag_macrodisable && touched.flag_macrodisable
                    }
                    success={
                      values.flag_macrodisable.length > 0 &&
                      !errors.flag_macrodisable
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_macrodisable_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_macrodisable"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_macrodisable", "No");
                    }}
                    id={"flag_macrodisable_No"}
                    checked={values.flag_macrodisable === "No"}
                    error={
                      errors.flag_macrodisable && touched.flag_macrodisable
                    }
                    success={
                      values.flag_macrodisable.length > 0 &&
                      !errors.flag_macrodisable
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_macrodisable && touched.flag_macrodisable ? (
                <FormHelperText error>
                  {errors.flag_macrodisable}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant have a SIEM (Security information and event
                management) implemented that retains logs for 90+ days?
              </MDTypography>
              <label htmlFor={"flag_SIEM_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_SIEM"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_SIEM", "Yes");
                    }}
                    id={"flag_SIEM_Yes"}
                    checked={values.flag_SIEM === "Yes"}
                    error={errors.flag_SIEM && touched.flag_SIEM}
                    success={values.flag_SIEM.length > 0 && !errors.flag_SIEM}
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_SIEM_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_SIEM"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_SIEM", "No");
                    }}
                    id={"flag_SIEM_No"}
                    checked={values.flag_SIEM === "No"}
                    error={errors.flag_SIEM && touched.flag_SIEM}
                    success={values.flag_SIEM.length > 0 && !errors.flag_SIEM}
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_SIEM && touched.flag_SIEM ? (
                <FormHelperText error>{errors.flag_SIEM}</FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Is encryption enforced for all computers and mobile devices?
              </MDTypography>
              <label htmlFor={"flag_encryptionenforced_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_encryptionenforced"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_encryptionenforced", "Yes");
                    }}
                    id={"flag_encryptionenforced_Yes"}
                    checked={values.flag_encryptionenforced === "Yes"}
                    error={
                      errors.flag_encryptionenforced &&
                      touched.flag_encryptionenforced
                    }
                    success={
                      values.flag_encryptionenforced.length > 0 &&
                      !errors.flag_encryptionenforced
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_encryptionenforced_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_encryptionenforced"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_encryptionenforced", "No");
                    }}
                    id={"flag_encryptionenforced_No"}
                    checked={values.flag_encryptionenforced === "No"}
                    error={
                      errors.flag_encryptionenforced &&
                      touched.flag_encryptionenforced
                    }
                    success={
                      values.flag_encryptionenforced.length > 0 &&
                      !errors.flag_encryptionenforced
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_encryptionenforced &&
              touched.flag_encryptionenforced ? (
                <FormHelperText error>
                  {errors.flag_encryptionenforced}
                </FormHelperText>
              ) : null}
            </Grid>

            {roleFormFiller !== "MSP or MSSP" && (
              <Grid item xs={12} lg={12}>
                <MDTypography variant="h6" fontWeight="regular">
                  Does the applicant's backup process include
                  alerting/reporting, with any issues remediated weekly?
                </MDTypography>
                <label htmlFor={"flag_weeklyalerts_reports_Yes"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"Yes"}
                      name={"flag_weeklyalerts_reports"}
                      inputProps={{ "aria-label": "Yes" }}
                      onChange={() => {
                        setFieldValue("flag_weeklyalerts_reports", "Yes");
                      }}
                      id={"flag_weeklyalerts_reports_Yes"}
                      checked={values.flag_weeklyalerts_reports === "Yes"}
                      error={
                        errors.flag_weeklyalerts_reports &&
                        touched.flag_weeklyalerts_reports
                      }
                      success={
                        values.flag_weeklyalerts_reports.length > 0 &&
                        !errors.flag_weeklyalerts_reports
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Yes
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                <label htmlFor={"flag_weeklyalerts_reports_No"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"No"}
                      name={"flag_weeklyalerts_reports"}
                      inputProps={{ "aria-label": "No" }}
                      onChange={() => {
                        setFieldValue("flag_weeklyalerts_reports", "No");
                      }}
                      id={"flag_weeklyalerts_reports_No"}
                      checked={values.flag_weeklyalerts_reports === "No"}
                      error={
                        errors.flag_weeklyalerts_reports &&
                        touched.flag_weeklyalerts_reports
                      }
                      success={
                        values.flag_weeklyalerts_reports.length > 0 &&
                        !errors.flag_weeklyalerts_reports
                      }
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        No
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
                {errors.flag_weeklyalerts_reports &&
                touched.flag_weeklyalerts_reports ? (
                  <FormHelperText error>
                    {errors.flag_weeklyalerts_reports}
                  </FormHelperText>
                ) : null}
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                How frequently are backups run?
              </MDTypography>
              <label htmlFor={"backupfrequency_Daily"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Daily"}
                    name={"backupfrequency"}
                    inputProps={{ "aria-label": "Daily" }}
                    onChange={() => {
                      setFieldValue("backupfrequency", "Daily");
                    }}
                    id={"backupfrequency_Daily"}
                    checked={values.backupfrequency === "Daily"}
                    error={errors.backupfrequency && touched.backupfrequency}
                    success={
                      values.backupfrequency.length > 0 &&
                      !errors.backupfrequency
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Daily
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"backupfrequency_Weekly"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Weekly"}
                    name={"backupfrequency"}
                    inputProps={{ "aria-label": "Weekly" }}
                    onChange={() => {
                      setFieldValue("backupfrequency", "Weekly");
                    }}
                    id={"backupfrequency_Weekly"}
                    checked={values.backupfrequency === "Weekly"}
                    error={errors.backupfrequency && touched.backupfrequency}
                    success={
                      values.backupfrequency.length > 0 &&
                      !errors.backupfrequency
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Weekly
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"backupfrequency_Monthly"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Monthly"}
                    name={"backupfrequency"}
                    inputProps={{ "aria-label": "Monthly" }}
                    onChange={() => {
                      setFieldValue("backupfrequency", "Monthly");
                    }}
                    id={"backupfrequency_Monthly"}
                    checked={values.backupfrequency === "Monthly"}
                    error={errors.backupfrequency && touched.backupfrequency}
                    success={
                      values.backupfrequency.length > 0 &&
                      !errors.backupfrequency
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Monthly
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"backupfrequency_I don't know"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"I don't know"}
                    name={"backupfrequency"}
                    inputProps={{ "aria-label": "I don't know" }}
                    onChange={() => {
                      setFieldValue("backupfrequency", "I don't know");
                    }}
                    id={"backupfrequency_I don't know"}
                    checked={values.backupfrequency === "I don't know"}
                    error={errors.backupfrequency && touched.backupfrequency}
                    success={
                      values.backupfrequency.length > 0 &&
                      !errors.backupfrequency
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      I don't know
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.backupfrequency && touched.backupfrequency ? (
                <FormHelperText error>{errors.backupfrequency}</FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Are backups encrypted both in transit and at rest?
              </MDTypography>
              <label htmlFor={"flag_fullencryptedbackups_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_fullencryptedbackups"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_fullencryptedbackups", "Yes");
                    }}
                    id={"flag_fullencryptedbackups_Yes"}
                    checked={values.flag_fullencryptedbackups === "Yes"}
                    error={
                      errors.flag_fullencryptedbackups &&
                      touched.flag_fullencryptedbackups
                    }
                    success={
                      values.flag_fullencryptedbackups.length > 0 &&
                      !errors.flag_fullencryptedbackups
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_fullencryptedbackups_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_fullencryptedbackups"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_fullencryptedbackups", "No");
                    }}
                    id={"flag_fullencryptedbackups_No"}
                    checked={values.flag_fullencryptedbackups === "No"}
                    error={
                      errors.flag_fullencryptedbackups &&
                      touched.flag_fullencryptedbackups
                    }
                    success={
                      values.flag_fullencryptedbackups.length > 0 &&
                      !errors.flag_fullencryptedbackups
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_fullencryptedbackups &&
              touched.flag_fullencryptedbackups ? (
                <FormHelperText error>
                  {errors.flag_fullencryptedbackups}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Can essential functions (email, key files and applications) be
                restored in less than 48 hours in the event of a widespread
                malware or ransomware attack within the network?
              </MDTypography>
              <label htmlFor={"flag_lessthan48hrrestore_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_lessthan48hrrestore"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_lessthan48hrrestore", "Yes");
                    }}
                    id={"flag_lessthan48hrrestore_Yes"}
                    checked={values.flag_lessthan48hrrestore === "Yes"}
                    error={
                      errors.flag_lessthan48hrrestore &&
                      touched.flag_lessthan48hrrestore
                    }
                    success={
                      values.flag_lessthan48hrrestore.length > 0 &&
                      !errors.flag_lessthan48hrrestore
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_lessthan48hrrestore_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_lessthan48hrrestore"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_lessthan48hrrestore", "No");
                    }}
                    id={"flag_lessthan48hrrestore_No"}
                    checked={values.flag_lessthan48hrrestore === "No"}
                    error={
                      errors.flag_lessthan48hrrestore &&
                      touched.flag_lessthan48hrrestore
                    }
                    success={
                      values.flag_lessthan48hrrestore.length > 0 &&
                      !errors.flag_lessthan48hrrestore
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_lessthan48hrrestore &&
              touched.flag_lessthan48hrrestore ? (
                <FormHelperText error>
                  {errors.flag_lessthan48hrrestore}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Are the backups immutable?
              </MDTypography>
              <label htmlFor={"flag_BUsimmutable_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_BUsimmutable"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_BUsimmutable", "Yes");
                    }}
                    id={"flag_BUsimmutable_Yes"}
                    checked={values.flag_BUsimmutable === "Yes"}
                    error={
                      errors.flag_BUsimmutable && touched.flag_BUsimmutable
                    }
                    success={
                      values.flag_BUsimmutable.length > 0 &&
                      !errors.flag_BUsimmutable
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_BUsimmutable_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_BUsimmutable"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_BUsimmutable", "No");
                    }}
                    id={"flag_BUsimmutable_No"}
                    checked={values.flag_BUsimmutable === "No"}
                    error={
                      errors.flag_BUsimmutable && touched.flag_BUsimmutable
                    }
                    success={
                      values.flag_BUsimmutable.length > 0 &&
                      !errors.flag_BUsimmutable
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_BUsimmutable && touched.flag_BUsimmutable ? (
                <FormHelperText error>
                  {errors.flag_BUsimmutable}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <TextField
                label="Additional Notes - Backups & Restoration"
                fullWidth
                multiline
                minRows={8}
                name="notes_backups_restoration"
                value={values.notes_backups_restoration ?? ""}
                placeholder={"Type here..."}
                onChange={(e) => {
                  setFieldValue("notes_backups_restoration", e.target.value);
                }}
                error={
                  errors.notes_backups_restoration &&
                  touched.notes_backups_restoration
                }
                success={
                  values.notes_backups_restoration?.length > 0 &&
                  !errors.notes_backups_restoration
                }
              />
              <MDTypography variant="caption" fontWeight="regular">
                Optional
              </MDTypography>
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant have a written incident response
                (IR)/business continuity (BC) plan that includes specifications
                for a ransomware event, has been formally approved by
                management, including an executive sponsor/approver, and tested
                in the last 12 months?
              </MDTypography>
              <label htmlFor={"flag_approvedIR_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_approvedIR"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_approvedIR", "Yes");
                    }}
                    id={"flag_approvedIR_Yes"}
                    checked={values.flag_approvedIR === "Yes"}
                    error={errors.flag_approvedIR && touched.flag_approvedIR}
                    success={
                      values.flag_approvedIR.length > 0 &&
                      !errors.flag_approvedIR
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_approvedIR_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_approvedIR"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_approvedIR", "No");
                    }}
                    id={"flag_approvedIR_No"}
                    checked={values.flag_approvedIR === "No"}
                    error={errors.flag_approvedIR && touched.flag_approvedIR}
                    success={
                      values.flag_approvedIR.length > 0 &&
                      !errors.flag_approvedIR
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_approvedIR && touched.flag_approvedIR ? (
                <FormHelperText error>{errors.flag_approvedIR}</FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <TextField
                label="Additional Notes - Incident Response/Business Continuity"
                fullWidth
                multiline
                minRows={8}
                name="notes_IR"
                value={values.notes_IR ?? ""}
                placeholder={"Type here..."}
                onChange={(e) => {
                  setFieldValue("notes_IR", e.target.value);
                }}
                error={errors.notes_IR && touched.notes_IR}
                success={values.notes_IR?.length > 0 && !errors.notes_IR}
              />
              <MDTypography variant="caption" fontWeight="regular">
                Optional
              </MDTypography>
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant use self-hosted Microsoft Exchange servers?
              </MDTypography>
              <label htmlFor={"flag_self_hostedExchange_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_self_hostedExchange"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_self_hostedExchange", "Yes");
                    }}
                    id={"flag_self_hostedExchange_Yes"}
                    checked={values.flag_self_hostedExchange === "Yes"}
                    error={
                      errors.flag_self_hostedExchange &&
                      touched.flag_self_hostedExchange
                    }
                    success={
                      values.flag_self_hostedExchange.length > 0 &&
                      !errors.flag_self_hostedExchange
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_self_hostedExchange_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_self_hostedExchange"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_self_hostedExchange", "No");
                    }}
                    id={"flag_self_hostedExchange_No"}
                    checked={values.flag_self_hostedExchange === "No"}
                    error={
                      errors.flag_self_hostedExchange &&
                      touched.flag_self_hostedExchange
                    }
                    success={
                      values.flag_self_hostedExchange.length > 0 &&
                      !errors.flag_self_hostedExchange
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_self_hostedExchange &&
              touched.flag_self_hostedExchange ? (
                <FormHelperText error>
                  {errors.flag_self_hostedExchange}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Has administrative audit and mailbox audit logging been enabled
                on Microsoft Exchange servers?
              </MDTypography>
              <label htmlFor={"flag_auditexchangeservers_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_auditexchangeservers"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_auditexchangeservers", "Yes");
                    }}
                    id={"flag_auditexchangeservers_Yes"}
                    checked={values.flag_auditexchangeservers === "Yes"}
                    error={
                      errors.flag_auditexchangeservers &&
                      touched.flag_auditexchangeservers
                    }
                    success={
                      values.flag_auditexchangeservers.length > 0 &&
                      !errors.flag_auditexchangeservers
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_auditexchangeservers_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_auditexchangeservers"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_auditexchangeservers", "No");
                    }}
                    id={"flag_auditexchangeservers_No"}
                    checked={values.flag_auditexchangeservers === "No"}
                    error={
                      errors.flag_auditexchangeservers &&
                      touched.flag_auditexchangeservers
                    }
                    success={
                      values.flag_auditexchangeservers.length > 0 &&
                      !errors.flag_auditexchangeservers
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_auditexchangeservers &&
              touched.flag_auditexchangeservers ? (
                <FormHelperText error>
                  {errors.flag_auditexchangeservers}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant use an email filtering tool designed to
                prevent phishing or ransomware attacks, including attempts that
                use malicious attachments or links?
              </MDTypography>
              <label htmlFor={"flag_emailfilterphishing_ransomware_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_emailfilterphishing_ransomware"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue(
                        "flag_emailfilterphishing_ransomware",
                        "Yes"
                      );
                    }}
                    id={"flag_emailfilterphishing_ransomware_Yes"}
                    checked={
                      values.flag_emailfilterphishing_ransomware === "Yes"
                    }
                    error={
                      errors.flag_emailfilterphishing_ransomware &&
                      touched.flag_emailfilterphishing_ransomware
                    }
                    success={
                      values.flag_emailfilterphishing_ransomware.length > 0 &&
                      !errors.flag_emailfilterphishing_ransomware
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_emailfilterphishing_ransomware_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_emailfilterphishing_ransomware"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue(
                        "flag_emailfilterphishing_ransomware",
                        "No"
                      );
                    }}
                    id={"flag_emailfilterphishing_ransomware_No"}
                    checked={
                      values.flag_emailfilterphishing_ransomware === "No"
                    }
                    error={
                      errors.flag_emailfilterphishing_ransomware &&
                      touched.flag_emailfilterphishing_ransomware
                    }
                    success={
                      values.flag_emailfilterphishing_ransomware.length > 0 &&
                      !errors.flag_emailfilterphishing_ransomware
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_emailfilterphishing_ransomware &&
              touched.flag_emailfilterphishing_ransomware ? (
                <FormHelperText error>
                  {errors.flag_emailfilterphishing_ransomware}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Does the applicant deploy default deny all rules to firewalls
                for internet traffic, and apply security best practices when
                ports are required to be open, including a monthly external
                vulnerability scan?
              </MDTypography>
              <label htmlFor={"flag_firewallbestpractices_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_firewallbestpractices"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_firewallbestpractices", "Yes");
                    }}
                    id={"flag_firewallbestpractices_Yes"}
                    checked={values.flag_firewallbestpractices === "Yes"}
                    error={
                      errors.flag_firewallbestpractices &&
                      touched.flag_firewallbestpractices
                    }
                    success={
                      values.flag_firewallbestpractices.length > 0 &&
                      !errors.flag_firewallbestpractices
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_firewallbestpractices_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_firewallbestpractices"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_firewallbestpractices", "No");
                    }}
                    id={"flag_firewallbestpractices_No"}
                    checked={values.flag_firewallbestpractices === "No"}
                    error={
                      errors.flag_firewallbestpractices &&
                      touched.flag_firewallbestpractices
                    }
                    success={
                      values.flag_firewallbestpractices.length > 0 &&
                      !errors.flag_firewallbestpractices
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_firewallbestpractices &&
              touched.flag_firewallbestpractices ? (
                <FormHelperText error>
                  {errors.flag_firewallbestpractices}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Do any vendors of the applicant store PII, financial records, or
                other sensitive information on the applicant's behalf?
              </MDTypography>
              <label htmlFor={"flag_3rdpartysensitiveinfostorage_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_3rdpartysensitiveinfostorage"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_3rdpartysensitiveinfostorage", "Yes");
                    }}
                    id={"flag_3rdpartysensitiveinfostorage_Yes"}
                    checked={values.flag_3rdpartysensitiveinfostorage === "Yes"}
                    error={
                      errors.flag_3rdpartysensitiveinfostorage &&
                      touched.flag_3rdpartysensitiveinfostorage
                    }
                    success={
                      values.flag_3rdpartysensitiveinfostorage.length > 0 &&
                      !errors.flag_3rdpartysensitiveinfostorage
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_3rdpartysensitiveinfostorage_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_3rdpartysensitiveinfostorage"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_3rdpartysensitiveinfostorage", "No");
                    }}
                    id={"flag_3rdpartysensitiveinfostorage_No"}
                    checked={values.flag_3rdpartysensitiveinfostorage === "No"}
                    error={
                      errors.flag_3rdpartysensitiveinfostorage &&
                      touched.flag_3rdpartysensitiveinfostorage
                    }
                    success={
                      values.flag_3rdpartysensitiveinfostorage.length > 0 &&
                      !errors.flag_3rdpartysensitiveinfostorage
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_3rdpartysensitiveinfostorage &&
              touched.flag_3rdpartysensitiveinfostorage ? (
                <FormHelperText error>
                  {errors.flag_3rdpartysensitiveinfostorage}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Is a third party standard baseline (ex: CIS controls) being used
                as a baseline?
              </MDTypography>
              <label htmlFor={"flag_3rdpartybaseline_Yes"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Yes"}
                    name={"flag_3rdpartybaseline"}
                    inputProps={{ "aria-label": "Yes" }}
                    onChange={() => {
                      setFieldValue("flag_3rdpartybaseline", "Yes");
                    }}
                    id={"flag_3rdpartybaseline_Yes"}
                    checked={values.flag_3rdpartybaseline === "Yes"}
                    error={
                      errors.flag_3rdpartybaseline &&
                      touched.flag_3rdpartybaseline
                    }
                    success={
                      values.flag_3rdpartybaseline.length > 0 &&
                      !errors.flag_3rdpartybaseline
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Yes
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"flag_3rdpartybaseline_No"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"No"}
                    name={"flag_3rdpartybaseline"}
                    inputProps={{ "aria-label": "No" }}
                    onChange={() => {
                      setFieldValue("flag_3rdpartybaseline", "No");
                    }}
                    id={"flag_3rdpartybaseline_No"}
                    checked={values.flag_3rdpartybaseline === "No"}
                    error={
                      errors.flag_3rdpartybaseline &&
                      touched.flag_3rdpartybaseline
                    }
                    success={
                      values.flag_3rdpartybaseline.length > 0 &&
                      !errors.flag_3rdpartybaseline
                    }
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      No
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.flag_3rdpartybaseline && touched.flag_3rdpartybaseline ? (
                <FormHelperText error>
                  {errors.flag_3rdpartybaseline}
                </FormHelperText>
              ) : null}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default AdditionalFormFormikWrapper;
