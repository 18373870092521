import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import Typography from "@mui/material/Typography";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { saveRMFTogglesChanges } from "features/company/CompanyConfigActions.js";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

import {
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useMaterialUIController } from "context";

const RMFStatsDisplayToggle = (props) => {
  const { objUser, open, onClose } = props;
  // const [globalRMFs, setGlobalRMFs] = useState([]);
  const [currentToggles, setCurrentToggles] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const fetchRMFs = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/rmf-toggles`
      );

      const sortedToggles = res.data.current_toggles.sort((a, b) => {
        const first = a.owning_rmf.name.toLowerCase();
        const second = b.owning_rmf.name.toLowerCase();

        if (first < second) {
          return -1;
        }
        return 1;
      });

      // setGlobalRMFs(res.data.rmfs);
      setCurrentToggles(sortedToggles);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      showMsg("error", "Something went wrong, please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRMFs();
  }, []);

  const handleToggle = (i) => {
    currentToggles[i].toggle = currentToggles[i].toggle === "on" ? "off" : "on";
    setCurrentToggles([...currentToggles]);
  };

  const saveChanges = async () => {
    setLoading(true);
    dispatch(saveRMFTogglesChanges({ objUser, currentToggles }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setLoading(false);
        showMsg("success", "Changes saved successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
        console.error(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong");
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0, p: 3 }}>
        Manage Toggles
        <Tooltip title="These switches allow you to display/hide the chart of a particular Policy Pack that is shown on the Policy Scorecard dashboard chart.">
          <IconButton
            sx={
              darkMode
                ? { fontSize: "1.2rem" }
                : { opacity: "0.4", fontSize: "1.2rem" }
            }
            pb={2}
          >
            <HelpIcon color="icon" />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <MDBox>
        {loading ? (
          <Card>
            <MDBox p={2}>
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
              <p style={{ textAlign: "center" }}>Loading</p>
            </MDBox>
          </Card>
        ) : (
          <Card>
            <MDBox
              display="flex"
              alignItems="center"
              flexDirection="column"
              mb={2}
              p={3}
            >
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  position: "relative",
                  overflow: "auto",
                  maxHeight: 600,
                  "& ul": { padding: 0 },
                }}
                subheader={<li />}
              >
                {currentToggles.map((tgle, i) => {
                  return (
                    <ListItem
                      key={i}
                      disablePadding
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <ListItemButton
                        role={undefined}
                        onClick={handleToggle(i)}
                        dense
                      > */}
                      {/* <ListItemIcon> */}
                      {/* <Checkbox
                            edge="start"
                            checked={tgle.toggle === "on"}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": i }}
                          /> */}
                      <FormControlLabel
                        control={
                          <Switch
                            checked={tgle.toggle === "on"}
                            name={
                              tgle.owning_rmf
                                ? tgle.owning_rmf.name.replace(/\s/g, "")
                                : ""
                            }
                            sx={!darkMode ? { ml: 1, py: 1 } : { ml: 1 }}
                          />
                        }
                        label={tgle.owning_rmf.name}
                        key={tgle.id}
                        onChange={() => handleToggle(i)}
                      />
                      <Tooltip title={tgle.owning_rmf.notes}>
                        <IconButton
                          sx={
                            darkMode
                              ? { fontSize: "1.2rem" }
                              : { opacity: "0.4", fontSize: "1.2rem" }
                          }
                          pb={2}
                        >
                          <HelpIcon color="icon" />
                        </IconButton>
                      </Tooltip>
                      {/* </ListItemIcon> */}
                      {/* <ListItemText
                          id={i}
                          primary={tgle.owning_rmf.name}
                          secondary={tgle.toggle}
                        /> */}
                      {/* </ListItemButton> */}
                    </ListItem>
                  );
                })}
              </List>
              {/* </Card> */}
              <MDBox
                display="flex"
                alignItems="end"
                sx={{ width: "100%", marginTop: "2rem" }}
                flexDirection="row-reverse"
                gap={2}
              >
                <MDButton
                  type="button"
                  variant="gradient"
                  color="success"
                  sx={{ padding: "12px 12px" }}
                  onClick={saveChanges}
                >
                  Save changes
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        )}
      </MDBox>
    </Dialog>
  );
};
export default RMFStatsDisplayToggle;
