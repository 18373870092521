import { combineReducers } from "redux";
import userReducers from "features/user/";
import stripeReducers from "features/stripe/";
import companyReducers from "features/company/";
import connectWiseReducers from "features/connectWise/";
// import authReducers from "./auth/";
// import userReducer from "../user/userSlice";
// import userSignUpFunnelSlice from "../user/userSignUpFunnelSlice";
// stockPrices/
const rootReducer = combineReducers({
  auth: userReducers,
  stripe: stripeReducers,
  company: companyReducers,
  connectWise: connectWiseReducers,
});

export default rootReducer;
