import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { unwrapResult } from "@reduxjs/toolkit";
import { logout } from "features/user/userActions";
import { showMsg } from "utils/general";

const AutoLagoutTimer = (props) => {
  const { ComposedClass, objUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const autoLogOutUser = () => {
    const objPostData = {};
    dispatch(logout(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === "success") {
          showMsg("success", "You have been successfully logged out.");
          localStorage.removeItem("token");
          localStorage.removeItem("is_remember_me");
          let redirectUrl = "/sign-in";
          navigate(redirectUrl);
        } else {
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        localStorage.removeItem("token");
        localStorage.removeItem("is_remember_me");
        let redirectUrl = "/sign-in";
        navigate(redirectUrl);

        // handle result here
      });
  };
  const handleOnIdle = (event) => {
    // SHOW YOUR MODAL HERE AND LAGOUT
    let is_remember_me = localStorage.getItem("is_remember_me");
    is_remember_me = JSON.parse(is_remember_me);
    if (is_remember_me === false) {
      autoLogOutUser();
    }
  };
  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    timeout: 10800000, // 3 hours in milisecond
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: true,
    syncTimers: 10000,
    name: "idle-timer-app",
  });
  return <ComposedClass objUser={objUser} />;
};

export default AutoLagoutTimer;
