// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useConfirm } from "material-ui-confirm";
import { unshareAssessmentTemplate } from "features/company/assessmentsActions";
import { showMsg } from "utils/general";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { useMaterialUIController } from "context";

const TemplateUnshareBtnTr = (props) => {
  const { row, reloadFunc, objUser } = props;
  const [isUnsharing, setIsUnsharing] = useState(false);
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const unshareTemplateAction = async () => {
    try {
      await confirm({
        description: "Are you sure you want to unshare this template? ",
        confirmationButtonProps: {
          color: "success",
          variant: "contained",
          sx: {
            backgroundColor: "#4CAF50",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1b5e20",
              color: "#fff",
              boxShadow:
                "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
        cancellationButtonProps: {
          color: "error",
          variant: "contained",
          sx: {
            backgroundColor: "#d32f2f",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#d32f2f",
              color: "#fff",
              boxShadow:
                "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
      });
    } catch (error) {
      return;
    }

    setIsUnsharing(true);
    try {
      await dispatch(
        unshareAssessmentTemplate({ objUser, templateId: row.original.id })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          showMsg("success", "Template unshared successfully.");
          reloadFunc();
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsUnsharing(false);
          console.log(rejectedValueOrSerializedError.response);
          showMsg("error", "Error unsharing template. Please, try again.");
        });
    } finally {
      setIsUnsharing(false);
    }
  };

  return isUnsharing ? (
    <LoadingSpinner subClass="text-center" color="success" size="sm" />
  ) : (
    <MDButton
      onClick={unshareTemplateAction}
      variant="gradient"
      sx={{ marginLeft: "1rem" }}
      disabled={row.original.shareRequest.status != "Approved"}
    >
      <MDBox
        sx={{
          color:
            row.original.shareRequest.status !== "Approved"
              ? darkMode
                ? "gray !important"
                : "inherit"
              : "error",
        }}
        display="flex"
        alignItems="center"
      >
        <Icon sx={{ fontWeight: "bold" }}>share</Icon>
        {/* <MDTypography
          variant="p"
          fontWeight="medium"
          sx={{
            lineHeight: 0,
            marginLeft: "0.5rem",
            color:
              row.original.shareRequest.status !== "Approved"
                ? darkMode
                  ? "gray !important"
                  : "inherit"
                : "error",
          }}
        >
          Unshare
        </MDTypography> */}
      </MDBox>
    </MDButton>
  );
};

export default TemplateUnshareBtnTr;
