import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import "./settings.css";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import DashboardButton from "views/components/DashboardButtton";
import AssessmentTypesImage from "assets/images/assessment-types.png";
import AssessmentScoringImage from "assets/images/assessment-scoring.png";
import AssessmentItemScoringImage from "assets/images/assessment-item-scoring.png";

const AssessmentConfig = (props) => {
  const { objUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  useEffect(() => {
    if (
      objUser.user &&
      objUser.user.role &&
      objUser.user.role.name != "admin" &&
      objUser.user.is_super_admin != 1 &&
      objUser.user.role.name != "company_user"
    ) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />

      <MDBox>
        <Grid spacing={2} container width="100%">
          {isLoading ? (
            <Grid item xs={12} lg={23}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <MDTypography variant="p" fontWeight="regular">
                      <LoadingSpinner />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            <>
              {/* scoring */}
              <Grid item xs={12} lg={6}>
                <Grid spacing={2} container width="100%">
                  {/* top */}
                  <Grid item xs={12} lg={12}>
                    <Card>
                      <MDBox p={2}>
                        <Grid>
                          <Grid item xs={12} lg={2}>
                            <img src={AssessmentScoringImage} />
                          </Grid>
                          <MDTypography
                            variant="h5"
                            fontWeight="bold"
                          ></MDTypography>
                          <MDTypography variant="h5" fontWeight="bold">
                            Assessment Scoring
                          </MDTypography>
                          <MDTypography variant="p" fontWeight="regular">
                            Choose the score range for various levels of
                            assessments
                          </MDTypography>{" "}
                          <MDBox
                            display="flex"
                            gap=".6rem"
                            alignItems="flex-start"
                          >
                            <DashboardButton
                              component={Link}
                              to={`/assessments/config/assessment-scoring`}
                              state={{ routeDictionary }}
                              btnText="Config"
                              btnIcon="settings"
                              textColor="white"
                              bgColor="success"
                            />
                          </MDBox>
                        </Grid>
                      </MDBox>
                    </Card>
                  </Grid>

                  {/* bottom */}
                  <Grid item xs={12} lg={12}>
                    <Card>
                      <MDBox p={2}>
                        <Grid>
                          <Grid item xs={12} lg={2}>
                            <img src={AssessmentItemScoringImage} />
                          </Grid>
                          <MDTypography
                            variant="h5"
                            fontWeight="bold"
                          ></MDTypography>
                          <MDTypography variant="h5" fontWeight="bold">
                            Assessment Item Scoring
                          </MDTypography>
                          <MDTypography variant="p" fontWeight="regular">
                            Configure possible scores for each assessment item
                          </MDTypography>{" "}
                          <MDBox
                            display="flex"
                            gap=".6rem"
                            alignItems="flex-start"
                          >
                            <DashboardButton
                              component={Link}
                              to={`/assessments/config/assessment-item-scoring`}
                              state={{ routeDictionary }}
                              btnText="Config"
                              btnIcon="settings"
                              textColor="white"
                              bgColor="success"
                            />
                          </MDBox>
                        </Grid>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              {/* types*/}
              <Grid item xs={12} lg={6}>
                <Card sx={{ height: "100%" }}>
                  <MDBox p={2} sx={{ height: "100%" }}>
                    <Grid
                      justifyContent="center"
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      sx={{ height: "100%" }}
                    >
                      <Grid item xs={12} lg={2}>
                        <img src={AssessmentTypesImage} />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        Assessment Types
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        Manage all your assessment types
                      </MDTypography>{" "}
                      <MDBox display="flex" gap=".6rem" alignItems="flex-start">
                        <DashboardButton
                          component={Link}
                          to={`/assessments/config/assessment-types`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="white"
                          bgColor="success"
                        />
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
              {/* end nodeware */}
            </>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default AssessmentConfig;
