import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "layouts/pages/users/new-user/components/FormField";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Formik, Form } from "formik";

function Index({
  open,
  onClose,
  onSubmitForReview,
  summaryOfChanges,
  setSummaryOfChanges,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitForm = async (values) => {
    onClose();
    onSubmitForReview();
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Submit Document For Review</DialogTitle>
      <Formik initialValues={{}} onSubmit={onSubmitForm}>
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="RejectPolicyForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                {/* <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="body1"
                    color="text"
                    fontWeight="regular"
                  >
                    Are you sure? Once published this document will be available
                    for adoption.
                  </MDTypography>
                </MDBox> */}
                <MDBox mt={0.75}>
                  <FormField
                    type="text"
                    label="Summary Of Changes"
                    name="summary_of_changes"
                    value={summaryOfChanges}
                    placeholder={""}
                    onChange={(e) => setSummaryOfChanges(e.target.value)}
                  />
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Submit
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default Index;
