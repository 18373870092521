import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import InviteAdminUsersFormikWrapper from "./InviteAdminUsersFormikWrapper";
import { inviteUserToCompany } from "features/company/companiesActions";
const objInitialValues = {
  txtEmailAddress: "",
};
const FormJWTSchema = Yup.object().shape({
  txtEmailAddress: Yup.string().required("Email address is required."),
});
const InviteAdminUsersForm = (props) => {
  const { setOpenInviteAdminUsersDialog } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [objInviteAdminUsersFormErrors, setInviteAdminUsersFormErrors] = useState({});

  const handleBack = () => {
    navigate("/setup-account/step-one");
  };

  const onSubmitForm = async (values) => {
    const objPostData = {
      email: values.txtEmailAddress,
      type: "peer_group_admin",
    };
    setInviteAdminUsersFormErrors({});
    setIsLoading(true);
    dispatch(inviteUserToCompany(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Your Peer Group Admin invite has been sent to the user successfully.");
          setOpenInviteAdminUsersDialog(false);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Please correct the errors.");
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        if (objErrors !== undefined) {
          setInviteAdminUsersFormErrors(objErrors);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      });
  };
  return (
    <Formik
      initialValues={objInitialValues}
      validationSchema={FormJWTSchema}
      onSubmit={onSubmitForm}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form id={"invite-admin-user-form"} autoComplete="off">
          <InviteAdminUsersFormikWrapper
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            handleBack={handleBack}
            objInviteAdminUsersFormErrors={objInviteAdminUsersFormErrors}
            isLoading={isLoading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default InviteAdminUsersForm;
