import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import Typography from "@mui/material/Typography";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { showMsg } from "utils/general";
import { saveRMFTogglesChanges } from "features/company/CompanyConfigActions.js";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import { FormControlLabel, IconButton, Switch, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useMaterialUIController } from "context";
import { saveDocumentsTogglesChanges } from "features/company/TrustCenterActions";

const DocumentsToggles = (props) => {
  const { objUser, fetchPublicData } = props;
  // const [globalRMFs, setGlobalRMFs] = useState([]);
  const [currentToggles, setCurrentToggles] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const fetchRMFs = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/trust-center/document-toggles`
      );

      const sortedToggles = res.data.current_toggles.sort((a, b) => {
        const first = a.owning_document.owner_policy.title.toLowerCase();
        const second = b.owning_document.owner_policy.title.toLowerCase();

        if (first < second) {
          return -1;
        }
        return 1;
      });

      // setGlobalRMFs(res.data.rmfs);
      setCurrentToggles(sortedToggles);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      showMsg("error", "Something went wrong, please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRMFs();
  }, []);

  const handleToggle = (i) => {
    currentToggles[i].toggle = currentToggles[i].toggle === "on" ? "off" : "on";
    setCurrentToggles([...currentToggles]);
  };

  const saveChanges = async () => {
    setLoading(true);
    dispatch(saveDocumentsTogglesChanges({ currentToggles }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setLoading(false);
        showMsg("success", "Changes saved successfully");
        fetchPublicData();
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
        console.error(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong");
      });
  };

  if (loading) {
    return (
      <MDBox p={2}>
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
        <p style={{ textAlign: "center" }}>Loading</p>
      </MDBox>
    );
  } else {
    return (
      <MDBox
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="space-between"
      >
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: 400,
            "& ul": { padding: 0 },
          }}
          subheader={<li />}
        >
          {currentToggles.map((tgle, i) => {
            return (
              <Card sx={{ marginBottom: "20px" }}>
                <ListItem key={i} disablePadding>
                  {/* <ListItemButton
                        role={undefined}
                        onClick={handleToggle(i)}
                        dense
                      > */}
                  {/* <ListItemIcon> */}
                  {/* <Checkbox
                            edge="start"
                            checked={tgle.toggle === "on"}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": i }}
                          /> */}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={tgle.toggle === "on"}
                        name={
                          tgle.owning_document
                            ? tgle.owning_document.owner_policy.title.replace(
                                /\s/g,
                                ""
                              )
                            : ""
                        }
                        sx={!darkMode ? { ml: 1, py: 1 } : { ml: 1 }}
                      />
                    }
                    label={tgle.owning_document.owner_policy.title}
                    key={tgle.id}
                    onChange={() => handleToggle(i)}
                  />
                  {/* </ListItemIcon> */}
                  {/* <ListItemText
                          id={i}
                          primary={tgle.owning_rmf.name}
                          secondary={tgle.toggle}
                        /> */}
                  {/* </ListItemButton> */}
                </ListItem>
              </Card>
            );
          })}
        </List>
        {/* </Card> */}
        <MDBox
          display="flex"
          alignItems="end"
          sx={{ width: "100%", marginTop: "2rem" }}
          flexDirection="row-reverse"
          gap={2}
        >
          <MDButton
            type="button"
            variant="gradient"
            color="success"
            sx={{ padding: "12px 12px" }}
            onClick={saveChanges}
          >
            Save changes
          </MDButton>
        </MDBox>
      </MDBox>
    );
  }
};
export default DocumentsToggles;
