import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AddApiKeysErrorList from "./AddApiKeysErrorList";

const AddApiKeysFormErrorsBlock = (props) => {
  const renderErrors = () =>
    props.objAddApiKeysFormErrors
      ? Object.entries(props.objAddApiKeysFormErrors).map(([key, value], i) => (
          <AddApiKeysErrorList key={key} value={value} />
        ))
      : null;
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity="error">
        {renderErrors(props.objAddApiKeysFormErrors)}
      </Alert>
    </Stack>
  );
};

export default AddApiKeysFormErrorsBlock;
