import React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import { showMsg } from "utils/general";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import { useCookies } from "react-cookie";
import { Formik, Form } from "formik";
import FormField from "layouts/pages/users/new-user/components/FormField";
import DashboardActionButton from "views/components/DashboardActionButton";
import { updateNewVersionInfo } from "features/company/superAdminActions";
import ToolTip from "views/components/Tooltip/ToolTip";
import { getNewVersionInfo } from "features/company/clientActions";
import { displayExceptionErrorMessage } from "utils/general";

let objInitiaNewVersionValues = {
  message: "",
  btnText: "",
};

const NewVersionFormJWTSchema = Yup.object().shape({
  message: Yup.string().required("Message is required."),
  btnText: Yup.string().required("Button Text is required."),
});

const SuperAdminConfigurables = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingNewVersionInfo, setIsUpdatingNewVersionInfo] =
    useState(false);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  const updateNewVersionMessage = (values) => {
    const objPostData = {
      message: values.message,
      btnText: values.btnText,
    };
    setIsUpdatingNewVersionInfo(true);
    dispatch(updateNewVersionInfo({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsUpdatingNewVersionInfo(false);
        loadNewVersionInfo();
        showMsg("success", "New Version Info updated successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUpdatingNewVersionInfo(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const loadNewVersionInfo = async () => {
    setIsUpdatingNewVersionInfo(true);
    dispatch(getNewVersionInfo())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;

        objInitiaNewVersionValues = {
          message: response.data.message.value,
          btnText: response.data.btnText.value,
        };
        setIsUpdatingNewVersionInfo(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUpdatingNewVersionInfo(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    loadNewVersionInfo();
  }, []);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox>
        <Grid spacing={2} container width="100%">
          {isLoading ? (
            <Grid item xs={12} lg={23}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <MDTypography variant="p" fontWeight="regular">
                      <LoadingSpinner />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <MDTypography
                fontWeight="bold"
                variant="h5"
                display="flex"
                alignItems="flex-start"
              >
                New Version Notice
                <ToolTip
                  info={
                    "Message and button text that appear when new version is realeased."
                  }
                />
              </MDTypography>
              <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
                {isUpdatingNewVersionInfo ? (
                  <LoadingSpinner subClass="text-center" size="lg" />
                ) : (
                  <Grid container width="100%">
                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                      <MDBox mb={2}>
                        <Formik
                          initialValues={objInitiaNewVersionValues}
                          validationSchema={NewVersionFormJWTSchema}
                          onSubmit={updateNewVersionMessage}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                          }) => (
                            <Form id="AddNewPostForm" autoComplete="off">
                              <Grid container>
                                <Grid item xs={12}>
                                  <FormField
                                    type="text"
                                    label="Enter Message"
                                    name="message"
                                    placeholder={"Message"}
                                    error={errors.message && touched.message}
                                    success={
                                      values.message.length > 0 &&
                                      !errors.message
                                    }
                                    variant="textarea"
                                    fullWidth
                                    multiline
                                    autoFocus
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormField
                                    type="text"
                                    label="Enter Button Text"
                                    name="btnText"
                                    placeholder={"Button Text"}
                                    error={errors.btnText && touched.btnText}
                                    success={
                                      values.btnText.length > 0 &&
                                      !errors.btnText
                                    }
                                    variant="standard"
                                    fullWidth
                                    autoFocus
                                  />
                                </Grid>
                              </Grid>
                              <DashboardActionButton
                                type="submit"
                                btnText="Save Changes"
                                btnIcon="save"
                                textColor="white"
                                bgColor="success"
                              />
                            </Form>
                          )}
                        </Formik>
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default SuperAdminConfigurables;
