// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector from "@mui/material/StepConnector";

const SignUpFunnelStepsHeader = (props) => {
  const { steps, activeStep } = props;
  return (
    <MDBox mx={2} mt={-3}>
      <Stepper
        connector={
          <StepConnector
            sx={{
              color: "#9cd29a",
            }}
          />
        }
        sx={{ background: "linear-gradient(195deg, #38b54a,#38b54a);" }}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              sx={{
                "& .MuiStepLabel-label": { color: "#9cd29a" },
                "& .MuiSvgIcon-root": {
                  color: "#9cd29a",
                  fill: "#9cd29a",
                  stroke: "#9cd29a",
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </MDBox>
  );
};

export default SignUpFunnelStepsHeader;
