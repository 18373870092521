
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
const CategoryNameBtnTr = (props) => {
  const { value, column, row } = props;
  return (
    <MDButton variant="text">
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default CategoryNameBtnTr;
