/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import * as Yup from "yup";

import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

import AcceptUserCompanyInviteFormFormikWrapper from "./AcceptUserCompanyInviteFormFormikWrapper";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import AcceptUserCompanyInviteFormErrorsBlock from "./AcceptUserCompanyInviteFormErrorsBlock";
import { acceptUserInvitationToCompany } from "features/company/companiesActions";
const objInitialValues = {
  name: "",
  last_name: "",
  password: "",
  password_confirmation: "",
};
const AcceptUserCompanyInviteFormJWTSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  last_name: Yup.string().required("Last Name is Required"),
  password: Yup.string()
    .min(8, "Password is too short!(min 8 characters are required)")
    .matches(/[a-z]+/, "Please add at least one lowercase character")
    .matches(/[A-Z]+/, "Please add at least uppercase character")
    // .matches(/[@$!%*#?&]+/, "Please add one special character")
    .matches(/\d+/, "Please add one number")
    .required("Password is required"),
  password_confirmation: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
const AcceptUserCompanyInviteForm = (props) => {
  const { invite_token } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [
    objAcceptUserCompanyInviteFormErrors,
    setObjAcceptUserCompanyInviteFormErrors,
  ] = useState({});
  const onSubmitForm = async (values) => {
    const objPostData = {
      ...values,
      token: invite_token,
    };
    setObjAcceptUserCompanyInviteFormErrors({});
    setIsLoading(true);
    dispatch(acceptUserInvitationToCompany(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here

        const response = originalPromiseResult;

        if (response.status === 201) {
          showMsg("success", "You have accepted the invitation successfully.");
          navigate("/sign-in");
        } else {
          showMsg(
            "error",
            "Unable to accept the invitation. Please try again!"
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Please correct the errors.");

        const objErrors = [];
        objErrors.push(
          rejectedValueOrSerializedError.response.data.message ?? ""
        );
        setObjAcceptUserCompanyInviteFormErrors(objErrors);
        // handle result here
      });
  };
  return (
    <Formik
      initialValues={objInitialValues}
      validationSchema={AcceptUserCompanyInviteFormJWTSchema}
      onSubmit={onSubmitForm}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form id={"AcceptUserCompanyInviteForm"} autoComplete="off">
          <AcceptUserCompanyInviteFormFormikWrapper
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
          />

          {Object.keys(objAcceptUserCompanyInviteFormErrors).length > 0 ? (
            <AcceptUserCompanyInviteFormErrorsBlock
              objAcceptUserCompanyInviteFormErrors={
                objAcceptUserCompanyInviteFormErrors
              }
            />
          ) : null}
          <MDBox mt={4} mb={1}>
            {isLoading === false ? (
              <MDButton type="submit" color="success" fullWidth>
                Accept Invitation
              </MDButton>
            ) : (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            )}
          </MDBox>
        </Form>
      )}
    </Formik>
  );
};

export default AcceptUserCompanyInviteForm;
