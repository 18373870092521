/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

export const inviteAuditor = createAsyncThunk(
  "company/inviteAuditor",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditors/invite`,
        {
          ...values.objPostData,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const acceptAuditorInvite = createAsyncThunk(
  "company/acceptAuditorInvite",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditors/accept-auditor-invite`,
        {
          token: values.objPostData.token,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getInvitedAuditors = createAsyncThunk(
  "company/fetchInvitedAuditors",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditors/invited/all`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getUnInvitedAuditors = createAsyncThunk(
  "company/fetchUnInvitedAuditors",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditors/uninvited/all`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAuditorReports = createAsyncThunk(
  "company/getAuditorReports",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditor-reports`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAssessments = createAsyncThunk(
  "company/getAssessments",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditor-reports/assessments/most-recently-completed`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// export const getGlobalSectionsToBeIncludedInSsp = createAsyncThunk(
//   "company/getOscalSectionsToBeIncludedInSsp",
//   async (values, thunkApi) => {
//     try {
//       const res = await axios.get(
//         `${BACKEND_API_URL}${VERSION_SERVER}/ssps/assessments/ssp-overview-sections`
//       );
//       return res;
//     } catch (error) {
//       return thunkApi.rejectWithValue(error);
//     }
//   }
// );

export const generateAuditorReport = createAsyncThunk(
  "company/generateSsp",
  async (values, thunkApi) => {
    // console.log(values);
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditor-reports`,
        {
          assessmentsToGenerateSspFrom: values.sections,
          name: values.sspName,
          introductionDescription: values.introductionDescription,
          selectedAuditor: values.selectedAuditor,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAuditorReport = createAsyncThunk(
  "company/fetchWisp",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditor-reports/details/${values.id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateAuditorRemarks = createAsyncThunk(
  "company/updateAuditorRemarks",
  async (values, thunkApi) => {
    // console.log(values);
    try {
      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditor-reports/control-item/update`,
        {
          assessmentEventQuestion: values.control,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateAuditorReportStatus = createAsyncThunk(
  "company/ updateAuditorReportStatus",
  async (values, thunkApi) => {
    try {
      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditor-reports/status/update`,
        {
          ccaObj: values.sspObj,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const _deleteAuditorReport = createAsyncThunk(
  "company/deleteWisp",
  async (values, thunkApi) => {
    try {
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditor-reports/${values.dataFromActionBtn.id}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPDF = createAsyncThunk(
  "policy/get-data-to-review-pdf",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/auditor-reports/pdf/${values.sspId}`,
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
