import { useEffect, useState, useRef } from "react";
import { Form, Formik } from "formik";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  saveGlobalTemplateChanges,
  publishGlobalTemplate,
  unPublishGlobalTemplate,
  discardDraft,
} from "features/company/superAdminActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import { useIdleTimer } from "react-idle-timer";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import EditTemplateFormikWrapper from "./EditTemplateFormikWrapper";

const EditTemplateDraft = (props) => {
  const { objUser, objTemplate, loadTemplate } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { templateId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [documentSectionsToSave, setDocumentSectionsToSave] = useState([]);
  const [editorDocument, setEditorDocument] = useState("");
  const [objInitialValues, setObjInitialValues] = useState({
    template_name: "",
    template_type: "",
    created_by: "",
    document: "",
    template_sections: [],
  });
  const [objAddNewCustomerFormErrors, setObjAddNewCustomerFormErrors] =
    useState({});
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [templateStatus, setTemplateStatus] = useState(null);
  const [savingSectionIndex, setSavingSectionIndex] = useState(null);
  const editorRef = useRef(null);
  const formRef = useRef();

  useEffect(() => {
    var tags = [];

    if (objTemplate.tags) {
      tags = objTemplate.tags.map((tg) => tg.name);
    }

    let sorted_rmfs = objTemplate.risk_management_frameworks ?? [];

    if (sorted_rmfs.length > 0) {
      sorted_rmfs = objTemplate.risk_management_frameworks.sort((a, b) => {
        const nameA = a.owning_rmf.name.toLowerCase();
        const nameB = b.owning_rmf.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }

    let document_sections = objTemplate.document_sections ?? [];
    if (document_sections.length > 0) {
      document_sections = document_sections.sort(
        (a, b) => a.owning_global_section.order - b.owning_global_section.order
      );
    }

    const __ObjInitialValues = {
      template_name: objTemplate.name ?? "",
      template_type: objTemplate.global_template_type ?? "",
      created_by: objTemplate.created_by ?? "",
      description: objTemplate.description ?? "",
      notes: objTemplate.notes ?? "",
      document: objTemplate?.document ?? "",
      tags: tags,
      risk_management_frameworks: sorted_rmfs ?? [],
      template_sections: document_sections,
    };

    if (
      typeof objTemplate.id !== "undefined" &&
      objTemplate.id !== null &&
      typeof objTemplate.document !== "undefined"
    ) {
      __ObjInitialValues.document = objTemplate.document;
    }
    setObjInitialValues(__ObjInitialValues);
    setDocumentSectionsToSave(__ObjInitialValues.template_sections ?? []);
    setEditorDocument(__ObjInitialValues.document);
    setTemplateStatus(objTemplate.status);
  }, [objTemplate]);

  const handleUpdateDocumentSectionsToSave = (field, index, newValue) => {
    let updatedSections = [...documentSectionsToSave];
    updatedSections[index][field] = newValue;
    setDocumentSectionsToSave(updatedSections);
  };

  const btnClickSectionSave = async (index, successCallback) => {
    setSavingSectionIndex(index);
    const objPostData = {
      name: formRef.current.values.template_name,
      type: formRef.current.values.template_type,
      tags: formRef.current.values.templateTags,
      description: formRef.current.values.description,
      notes: formRef.current.values.notes,
      rmfs: formRef.current.values.risk_management_frameworks,
      document: editorRef.current ? editorRef.current.getContent() : "-",
      updatedDocumentSections: [documentSectionsToSave[index]],
    };
    try {
      const response = await dispatch(
        saveGlobalTemplateChanges({ objPostData, objUser, templateId })
      ).unwrap();
      if (response.status === 201) {
        successCallback();
      }
    } catch (e) {
      console.log(e);
      showMsg("error", "Error saving template. Try again later");
    } finally {
      setSavingSectionIndex(null);
    }
  };

  const onSubmitForm = (currentValues) => {
    setIsLoading(true);
    handleAutoSave(
      editorRef.current,
      () => showMsg("success", "Template saved succesfully"),
      () => showMsg("error", "Error saving template. Try again later")
    );
  };

  const handleAutoSave = (editor, successCallback, failureCallback) => {
    const objPostData = {
      name: formRef.current.values.template_name,
      type: formRef.current.values.template_type,
      tags: formRef.current.values.templateTags,
      description: formRef.current.values.description,
      notes: formRef.current.values.notes,
      rmfs: formRef.current.values.risk_management_frameworks,
      document: editorDocument ?? editor.getContent(),
      updatedDocumentSections: [...documentSectionsToSave],
    };
    dispatch(saveGlobalTemplateChanges({ objPostData, objUser, templateId }))
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          successCallback();
          setObjInitialValues(formRef.current.values);
          setIsLoading(false);
        } else {
          failureCallback();
          setIsLoading(false);
        }
      })
      .catch(() => {
        failureCallback();
        setIsLoading(false);
      });
  };

  const publishTemplate = () => {
    setIsLoading(true);
    const objPostData = {
      name: formRef.current.values.template_name,
      type: formRef.current.values.template_type,
      tags: formRef.current.values.templateTags,
      description: formRef.current.values.description,
      notes: formRef.current.values.notes,
      rmfs: formRef.current.values.risk_management_frameworks,
      document: editorDocument ?? editorRef.current.getContent(),
      updatedDocumentSections: [...documentSectionsToSave],
    };

    dispatch(publishGlobalTemplate({ objPostData, objUser, templateId }))
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          showMsg("success", "Template has been published successfully");
          setTemplateStatus("Published");
          setObjInitialValues(formRef.current.values);
          setIsLoading(false);
        } else {
          showMsg("error", "Error publishing template. Try again later");
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        showMsg("error", "Error publishing template. Try again later");
      });
  };

  const unPublishTemplate = () => {
    setIsLoading(true);
    const objPostData = {
      name: formRef.current.values.template_name,
      type: formRef.current.values.template_type,
      tags: formRef.current.values.templateTags,
      description: formRef.current.values.description,
      notes: formRef.current.values.notes,
      risk_management_frameworks:
        formRef.current.values.risk_management_frameworks,
      document: editorDocument ?? editorRef.current.getContent(),
      updatedDocumentSections: [...documentSectionsToSave],
    };

    dispatch(unPublishGlobalTemplate({ objPostData, objUser, templateId }))
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          showMsg("success", "Template has been unpublished");
          setTemplateStatus("Draft");
          setObjInitialValues(formRef.current.values);
          setIsLoading(false);
        } else {
          showMsg("error", "Error unpublishing template. Try again later");
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        showMsg("error", "Error unpublishing template. Try again later");
      });
  };

  const discardGlobalTemplateDraft = () => {
    setIsLoading(true);
    const objPostData = {
      name: formRef.current.values.template_name,
      type: formRef.current.values.template_type,
      tags: formRef.current.values.templateTags,
      description: formRef.current.values.description,
      notes: formRef.current.values.notes,
      risk_management_frameworks:
        formRef.current.values.risk_management_frameworks,
      document: editorDocument ?? editorRef.current.getContent(),
      updatedDocumentSections: [...documentSectionsToSave],
    };

    dispatch(discardDraft({ objPostData, objUser, templateId }))
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          showMsg("success", "Current template draft has been discarded");
          window.location.reload();
          setIsLoading(false);
        } else {
          showMsg("error", "Error discarding template draft. Try again later");
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        showMsg("error", "Error discarding template draft. Try again later");
      });
  };

  return (
    <>
      {isLoadingTemplate === false &&
      Object.keys(objInitialValues).length > 0 &&
      objInitialValues.template_name !== "" ? (
        <Formik
          initialValues={objInitialValues}
          innerRef={formRef}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={"EditTemplateForm"} autoComplete="off">
              <MDBox>
                <EditTemplateFormikWrapper
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  templateStatus={templateStatus}
                  objStates={{}}
                  objTemplate={objTemplate}
                  documentSectionsToSave={documentSectionsToSave}
                  savingSectionIndex={savingSectionIndex}
                  btnClickSectionSave={btnClickSectionSave}
                  editorDocument={editorDocument}
                  setEditorDocument={setEditorDocument}
                  handleUpdateDocumentSectionsToSave={
                    handleUpdateDocumentSectionsToSave
                  }
                  handleAutoSave={handleAutoSave}
                  editorRef={editorRef}
                  mergeTags={objTemplate.merge_tags}
                />

                {isLoading === false ? (
                    <MDBox
                      px={5}
                      sx={{ margin: "0" }}
                      width="100%"
                      display="flex"
                      justifyContent="flex-start"
                    >
                      <MDButton
                        variant="gradient"
                        color="primary"
                        onClick={unPublishTemplate}
                        sx={{ padding: "12px 12px" }}
                      >
                        UNPUBLISH TEMPLATE
                      </MDButton>
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="warning"
                        onClick={discardGlobalTemplateDraft}
                        sx={{ padding: "12px 12px", marginLeft: "1rem" }}
                      >
                        DISCARD DRAFT
                      </MDButton>
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="dark"
                        sx={{ padding: "12px 12px", marginLeft: "1rem" }}
                      >
                        SAVE CHANGES
                      </MDButton>

                      <MDButton
                        color="success"
                        onClick={publishTemplate}
                        sx={{ padding: "12px 12px", marginLeft: "1rem" }}
                      >
                        PUBLISH TEMPLATE
                      </MDButton>
                    </MDBox>
                ) : (
                  <MDBox
                    px={5}
                    sx={{ margin: "0" }}
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                  >
                    <LoadingSpinner color="success" size="lg" />
                  </MDBox>
                )}
              </MDBox>
            </Form>
          )}
        </Formik>
      ) : (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      )}
    </>
  );
};

export default EditTemplateDraft;
