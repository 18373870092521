import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { validatePassword } from "features/company/superAdminActions";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Form, Formik } from "formik";
import { showMsg } from "utils/general";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const PasswordValidationFormJWTSchema = Yup.object().shape({
  password: Yup.string().required("Password is Required"),
});

const SuperAdminPasswordValidation = (props) => {
  const { objUser } = props;
  const [cookies, setCookie] = useCookies(["isAuthenticated"]);
  const [objInitialValues, setObjInitialValues] = useState({
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const onSubmitForm = async (values) => {
    setIsLoading(true);
    dispatch(validatePassword(values))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        showMsg("success", "Authentication successful");
        setCookie("isAuthenticated", "yes", { path: "/", maxAge: 3600 });
        navigate(-1, {
          state: { routeDictionary },
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid my={3} container width="100%">
        <Grid item xs={12} lg={6}>
          <Card sx={{ height: "100%" }}>
            <Formik
              initialValues={objInitialValues}
              validationSchema={PasswordValidationFormJWTSchema}
              onSubmit={onSubmitForm}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={"PasswordValidationForm"} autoComplete="off">
                  <MDBox p={3}>
                    <MDTypography
                      textAlign="left"
                      mb={3}
                      variant="h4"
                      fontWeight="bold"
                    >
                      Enter Your Super Admin Password To Proceed
                    </MDTypography>
                    <FormField
                      type="password"
                      label="Enter Your Password"
                      name="password"
                      value={values.password}
                      placeholder={"Password"}
                    />
                    {isLoading == false ? (
                      <MDButton
                        type="submit"
                        color="success"
                        sx={{ padding: "12px 20px", margin: "10px 5px" }}
                      >
                        Submit
                      </MDButton>
                    ) : (
                      <LoadingSpinner color="success" size="lg" />
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default SuperAdminPasswordValidation;
