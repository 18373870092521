import { useState } from "react";
import { useDispatch } from "react-redux";
import { getWispPDF } from "features/wisps/wispActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { displayExceptionErrorMessage } from "utils/general";

function useWispPDFDownloader() {
  const dispatch = useDispatch();
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);

  const downloadWispPDF = (objUser = null, wispId) => {
    setIsLoadingPDF(true);

    dispatch(getWispPDF({ objUser, wispId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingPDF(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${wispId}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingPDF(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  return { downloadWispPDF, isLoadingPDF };
}

export default useWispPDFDownloader;
