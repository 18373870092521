import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DefaultCell from "views/components/TableCells/DefaultCell";

const VersionNameBtnTr = (props) => {
  const { value, company_id, row, client_id, kbs_id, kbs_secret } = props;
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const btnOnClickVersionName = (row) => {
    navigate(
      `/kbs/open-document/${company_id}/${client_id}/${kbs_id}/${kbs_secret}/${row.linked_policy_id}/${row.policy_document_id}`,
      {
        state: { routeDictionary },
      }
    );
  };

  return (
    <MDButton
      variant="text"
      onClick={() => btnOnClickVersionName(row.original)}
    >
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

const RelatedDocumentsDatatable = ({
  canSearch,
  isSorted,
  noEndBorder,
  company_id,
  client_id,
  kbs_id,
  kbs_secret,
  rows,
}) => {
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  useEffect(() => {
    const columns = [
      {
        Header: "Title",
        accessor: "linked_policy.title",
        Cell: ({ value, row }) => (
          <VersionNameBtnTr
            row={row}
            company_id={company_id}
            client_id={client_id}
            kbs_id={kbs_id}
            kbs_secret={kbs_secret}
            value={value ?? ""}
          />
        ),
      },
      {
        Header: "Version",
        accessor: "version",
        // width: "25%",
        Cell: ({ value, row }) => (
          <VersionNameBtnTr
            value={value}
            row={row}
            company_id={company_id}
            client_id={client_id}
            kbs_id={kbs_id}
            kbs_secret={kbs_secret}
          />
        ),
      },
      {
        Header: "State",
        accessor: "state",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Owner",
        accessor: "owner",
        Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
      },
      {
        Header: "Last Updated",
        accessor: "updated_at",
        Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
      },
    ];
    const treatedRows = rows.map((row) => {
      const newRow = { ...row };
      newRow.version = `${row.major_version}.${row.minor_version}.${row.patch_version}`;
      newRow.published_at = row.published_at ?? "";
      return newRow;
    });
    setTableData({ columns, rows: treatedRows });
  }, [rows]);

  const tableInstance = useTable(
    {
      columns: tableData.columns,
      data: tableData.rows,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: rows.length,
    },
    useGlobalFilter,
    useSortBy
  );

  // Search input value state
  const [search, setSearch] = useState(tableInstance.globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    tableInstance.setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...tableInstance.getTableProps()}>
        <MDBox component="thead">
          {tableInstance.headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...tableInstance.getTableBodyProps()}>
          {tableInstance.rows.map((row, key) => {
            tableInstance.prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={
                      noEndBorder && tableInstance.rows.length - 1 === key
                    }
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

RelatedDocumentsDatatable.defaultProps = {
  canSearch: false,
  isSorted: true,
  noEndBorder: false,
};
export default RelatedDocumentsDatatable;
