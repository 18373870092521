import { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import { unwrapResult } from "@reduxjs/toolkit";
import Icon from "@mui/material/Icon";
import { deletePeerGroupPost } from "features/company/peerGroupActions";

function ActionCell({ value }) {
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    dispatch(deletePeerGroupPost({ post_id: value }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeleting(false);
          showMsg("success", "Post deleted successfully.");
              window.location.reload();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeleting(false);
        if (rejectedValueOrSerializedError?.response?.data?.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
        console.log(rejectedValueOrSerializedError);
      })
      .finally(() => {});
  };

  if (isDeleting) {
    return <LoadingSpinner subClass="text-center" color="success" size="lg" />;
  }
  return (
    <MDButton
      onClick={handleDelete}
      variant="gradient"
    >
      <MDBox color="error" display="flex" alignItems="center">
        <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
        <MDTypography
          variant="p"
          fontWeight="medium"
          color="error"
          sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
        >
          Archive
        </MDTypography>
      </MDBox>
    </MDButton>
  );
}

export default ActionCell;
