import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { validatePassword } from "features/company/CompanyConfigActions";

import * as Yup from "yup";

import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Form, Formik } from "formik";
import { showMsg } from "utils/general";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const PasswordValidationFormJWTSchema = Yup.object().shape({
  password: Yup.string().required("Password is Required"),
});

const PublicLinkPasswordValidation = (props) => {
  const [cookies, setCookie] = useCookies(["isValidated"]);
  const [objInitialValues, setObjInitialValues] = useState({
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  let { company_id, client_id } = useParams();

  const onSubmitForm = async (values) => {
    setIsLoading(true);
    let objPostData = {
      password: values.password,
      company_id,
      client_id,
    };
    dispatch(validatePassword(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        showMsg("success", "Authentication successful");
        setCookie("isValidated", client_id, { path: "/", maxAge: 3600 });
        navigate(-1, {
          state: { routeDictionary },
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
      });
  };

  return (
    <MDBox display={"flex"} justifyContent={"center"} pt={20}>
      <Card>
        <Formik
          initialValues={objInitialValues}
          validationSchema={PasswordValidationFormJWTSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={"PasswordValidationForm"} autoComplete="off">
              <MDBox px={8} py={3}>
                <MDTypography
                  textAlign="left"
                  variant="h4"
                  fontWeight="bold"
                  mb={1}
                >
                  Password Protected Content
                </MDTypography>
                <FormField
                  type="password"
                  label="Enter password to proceed"
                  name="password"
                  value={values.password}
                  placeholder={"Password"}
                />
                {isLoading == false ? (
                  <MDButton
                    type="submit"
                    color="success"
                    sx={{ padding: "12px 20px", margin: "10px 5px" }}
                  >
                    Proceed
                  </MDButton>
                ) : (
                  <LoadingSpinner color="success" size="lg" />
                )}
              </MDBox>
            </Form>
          )}
        </Formik>
      </Card>
    </MDBox>
  );
};

export default PublicLinkPasswordValidation;
