import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// @mui icons
import { useLocation, useNavigate } from "react-router-dom";
const PolicyNameBtnTr = (props) => {
  const { value, row } = props;

  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const btnOnClickClientName = (objClientData) => {
    routeDictionary[objClientData.element.id] = value;
    navigate(`/asset-lists/${objClientData.element.id}`, {
      state: { routeDictionary },
    });
  };

  return (
    <MDButton variant="text" onClick={() => btnOnClickClientName(row.original)}>
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default PolicyNameBtnTr;
