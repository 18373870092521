import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
// Data
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getRiskRegisterItems,
  getRiskRegisterListItems,
  createRiskRegisterList,
} from "features/company/riskRegisterActions";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import dataTableData from "views/pages/Dashboard/Customers/CustomersManagement/data/dataTableData";
import RiskRegisterTable from "./components/RiskRegisterTable/RiskRegisterTable";
import CreateNewItemDialog from "./components/RiskRegisterTable/createNewItemDialog";
import BulkDeleteDialog from "./components/RiskRegisterTable/bulkDeleteDialog";
import MDTypography from "components/MDTypography";
import { getRiskFindingsPDF } from "features/company/riskRegisterActions";
import { getRiskFindingsCSV } from "features/company/riskRegisterActions";

const RiskRegister = (props) => {
  const { objUser } = props;
  let { listId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [openNewItemDialog, setOpenNewItemDialog] = useState(false);
  const [openBulkDeleteDialog, setOpenBulkDeleteDialog] = useState(false);
  const [isFetchingPDF, setIsFetchingPDF] = useState(false);
  const [riskRegisterlist, setRiskRegisterList] = useState({});
  const [isgeneratingActionItems, setIsgeneratingActionItems] = useState(false);
  const [riskLevels, setRiskLevels] = useState([]);
  const [findingsToAdd, setFindingsToAdd] = useState([]);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [perPageRF, setPerPageRF] = useState(25);
  const [pageRF, setPageRF] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    loadMoreItems();
  }, [listId]);

  const loadMoreItems = () => {
    setIsLoading(true);
    dispatch(
      listId
        ? getRiskRegisterListItems({ objUser, listId })
        : getRiskRegisterItems({ objUser })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        const response = originalPromiseResult;
        const objRows = response.data.riskRegisterItems.map((objEvent) => {
          const objTr = {};
          objTr.eventName = objEvent.assessment_event_name;
          objTr.itemName = objEvent.item_name;
          objTr.riskLevel = objEvent.risk_level;
          objTr.priorityLevel = !objEvent.priority_level
            ? null
            : objEvent.priority_level < 6
            ? "Low"
            : objEvent.priority_level < 13
            ? "Medium"
            : objEvent.priority_level < 21
            ? "High"
            : objEvent.priority_level < 26
            ? "Very High"
            : null;
          objTr.will_take_action = objEvent.will_take_action;
          objTr.id = objEvent.id;
          return objTr;
        });
        const itemScores = response.data.itemScores.map((item) => {
          return item.name;
        });
        setRiskLevels(itemScores);
        if (response.data.riskRegisterList) {
          setRiskRegisterList(response.data.riskRegisterList);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onNewItem = () => {
    loadMoreItems();
    setOpenNewItemDialog(false);
  };

  const onBulkDeleteItem = () => {
    loadMoreItems();
    setOpenBulkDeleteDialog(false);
  };

  const openNewItemDialogHandler = () => {
    setOpenNewItemDialog(true);
  };

  const openBulkDeleteDialogHandler = () => {
    setOpenBulkDeleteDialog(true);
  };

  const generateActionsList = async () => {
    setIsgeneratingActionItems(true);
    let objPostData = {
      findingsToAdd,
    };
    dispatch(createRiskRegisterList({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Action Items generated");
          setFindingsToAdd([]);
          loadMoreItems();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized");
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        }
      })
      .finally(() => {
        setIsgeneratingActionItems(false);
      });
  };

  const printPage = (output) => {
    setIsFetchingPDF(true);
    dispatch(
      output == "pdf"
        ? getRiskFindingsPDF({
            objUser,
          })
        : getRiskFindingsCSV({
            objUser,
          })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingPDF(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Risk Findings.` + output); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // TODO handle download error
        setIsFetchingPDF(false);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <CreateNewItemDialog
        objUser={objUser}
        open={openNewItemDialog}
        onClose={() => setOpenNewItemDialog(false)}
        onSubmit={onNewItem}
        riskLevels={riskLevels}
      />
      <BulkDeleteDialog
        objUser={objUser}
        open={openBulkDeleteDialog}
        onClose={() => setOpenBulkDeleteDialog(false)}
        onSubmit={onBulkDeleteItem}
        findingsToAdd={findingsToAdd}
      />

      <MDBox>
        <MDBox
          display={listId ? "none" : "flex"}
          gap=".6rem"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
        >
          <MDBox display="flex" gap={2}>
            <DashboardActionButton
              action={openNewItemDialogHandler}
              btnText="Add New Item"
              btnIcon={"add"}
              textColor="white"
              bgColor="success"
              className="dashboard-button"
            />
            {objUser.user.role.name === "company_user" ||
              (objUser.user.role.name === "admin" && (
                <DashboardActionButton
                  action={openBulkDeleteDialogHandler}
                  btnText="Delete"
                  btnIcon={"delete"}
                  textColor="error"
                  bgColor="white"
                  className="dashboard-button"
                  buttonDisabled={findingsToAdd.length === 0}
                />
              ))}
          </MDBox>
          {isFetchingPDF ? (
            <LoadingSpinner subClass="text-center" color="success" size="sm" />
          ) : (
            <MDBox display="flex" gap={2}>
              <DashboardActionButton
                action={() => printPage("csv")}
                btnText="Export to CSV"
                btnIcon="print"
                textColor="white"
                bgColor="success"
                sx={{ marginTop: "0px !important" }}
              />
              <DashboardActionButton
                action={() => printPage("pdf")}
                btnText="Export to PDF"
                btnIcon="print"
                textColor="white"
                bgColor="success"
                sx={{ marginTop: "0px !important" }}
              />
            </MDBox>
          )}
        </MDBox>
        <MDBox
          display={listId && isLoading === false ? "flex" : "none"}
          gap=".6rem"
          alignItems="flex-start"
          mb={0}
        >
          <MDTypography
            sx={{ paddingTop: "1rem" }}
            fontWeight="bold"
            variant="h4"
            color="dark"
          >
            Action Items
          </MDTypography>
        </MDBox>

        <Card sx={{ marginTop: "16px" }}>
          {isLoading === false &&
          objTableData !== undefined &&
          objTableData.rows !== undefined &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <>
              <RiskRegisterTable
                table={objTableData}
                entriesPerPage={true}
                canSearch
                objUser={objUser}
                totalCount={totalCount}
                listItems={listId ? true : false}
                loadMoreItems={loadMoreItems}
                perPage={perPageRF}
                setPerPage={setPerPageRF}
                _page={pageRF}
                setPage={setPageRF}
                findingsToAdd={findingsToAdd}
                setFindingsToAdd={setFindingsToAdd}
              />
              <MDBox
                mb={2}
                display={listId ? "none" : "flex"}
                justifyContent={"center"}
              >
                {isgeneratingActionItems ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    onClick={() => generateActionsList()}
                    sx={{
                      padding: "10px 17px",
                      margin: "0px 5px",
                    }}
                  >
                    Generate Action Items
                  </MDButton>
                )}
              </MDBox>
            </>
          ) : (
            <Fragment>
              {isLoading ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>There are no Risk Register Items</p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default RiskRegister;
