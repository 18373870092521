/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { useConfirm } from "material-ui-confirm";
import { Fragment } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

const CustomerStatusBtnTr = (props) => {
  const {
    value,
    column,
    row,
    activateTheClient,
    isActivatingClient,
    deActivateTheClient,
    objSelectedClient,
    objSelectedClientRef,
    isDeActivatingClient,
    setIsDeActivatingClient,
    isDeletingClient,
    setIsDeletingClient,
  } = props;

  const confirm = useConfirm();
  const handleClick = (data) => {
    confirm({
      description: "Are you sure, that you want to activate this client?",
      confirmationText: "Activate",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        activateTheClient(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  const btnOnClickDeactivate = (data) => {
    confirm({
      description: "Are you sure, that you want to deactivate this client?",
      confirmationText: "Deactivate",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deActivateTheClient(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  if (value === "INACTIVE") {
    return (
      <Fragment>
        {isActivatingClient &&
        Object.keys(objSelectedClient).length > 0 &&
        row.original.element.id === objSelectedClient.element.id ? (
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
        ) : (
          <MDButton onClick={() => handleClick(row.original)}>
            <MDBox color="success" display="flex" alignItems="center">
              <Icon sx={{ fontWeight: "bold" }}>send</Icon>
              <MDTypography
                variant="p"
                fontWeight="medium"
                color="success"
                sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
              >
                Activate
              </MDTypography>
            </MDBox>
          </MDButton>
        )}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {isDeActivatingClient &&
        Object.keys(objSelectedClient).length > 0 &&
        row.original.element.id === objSelectedClient.element.id ? (
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
        ) : (
          <MDButton onClick={() => btnOnClickDeactivate(row.original)}>
            <MDBox color="error" display="flex" alignItems="center">
              <Icon sx={{ fontWeight: "bold" }}>cancel</Icon>
              <MDTypography
                variant="p"
                fontWeight="medium"
                color="error"
                sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
              >
                Deactivate
              </MDTypography>
            </MDBox>
          </MDButton>
        )}
      </Fragment>
    );
  }
};

export default CustomerStatusBtnTr;
