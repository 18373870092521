import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import { useMemo } from "react";

const StatusTr = (props) => {
  const { value, column, row } = props;

  /**
   * Note to other devs:
   * If you added a new param to the row object in the ShowClientContactsById.js file,
   * Please add it again in ClientContactsDataByIdTable.js line 208. I have zero idea on why it was done like that
   * :Gilbert
   */

  if (row.original.member === true) {
    if (row.original.role == "admin") {
      return <MDBadge badgeContent="MSP Admin" size="sm" color="dark" />;
    } else if (row.original.role == "company_user") {
      return <MDBadge badgeContent="MSP User" size="sm" color="dark" />;
    } else {
      return <MDBadge badgeContent="Client User" size="sm" color="success" />;
    }
  } else {
    return <MDBadge badgeContent="Contact" size="sm" color="info" />;
  }

  // return (
  //   <>
  //     {row.original.first_msp_contact === row.original.id ? (
  //       <MDBadge badgeContent="MSP Admin" size="sm" color="dark" />
  //     ) : (
  //       <>
  //         {row.original.member === true ? (
  //           <MDBadge badgeContent="MSP User" size="sm" color="dark" />
  //         ) : (
  //           <MDBadge badgeContent="Contact" size="sm" color="info" />
  //         )}
  //       </>
  //     )}
  //   </>
  // );
};

export default StatusTr;
