import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function ContactsListDialog({ open, onClose, contacts }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Knowledge Base Contacts </DialogTitle>

      <DialogContent dividers>
        <MDBox mb={0} mt={0}>
          <MDBox mt={0.75}>
            {contacts.map((contact, index) => (
              <MDTypography
                key={index}
                component="div"
                variant="body2"
                color="textPrimary"
                fontWeight="regular"
                sx={{ marginBottom: "8px" }}
              >
                {index + 1 + ". "} {contact.name}
              </MDTypography>
            ))}
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            type="button"
            variant="outlined"
            color="dark"
            sx={{ padding: "12px 12px" }}
            onClick={onClose}
          >
            Close
          </MDButton>
        </>
      </DialogActions>
    </Dialog>
  );
}
export default ContactsListDialog;
