// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography"; 
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import { TextField } from "@mui/material";

const PartnerFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue, roleFormFiller } = props;

  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h5"
          fontWeight="bold"
        >
          Partner Information
        </MDTypography>
        <MDBox>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            p={5}
            pt={2}
          >
            {(roleFormFiller === "Licenced Insurance Agent" ||
              roleFormFiller === "Internal IT") && (
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormField
                      type="text"
                      label="Agent Contact Name"
                      name="agentcontact"
                      value={values.agentcontact ?? ""}
                      placeholder={"Agent Contact Name"}
                      error={errors.agentcontact && touched.agentcontact}
                      success={
                        values.agentcontact.length > 0 && !errors.agentcontact
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      type="text"
                      label="Agent Contact Email"
                      name="agentContact"
                      value={values.agentContact ?? ""}
                      placeholder={"Agent Contact Email"}
                      error={errors.agentContact && touched.agentContact}
                      success={
                        values.agentContact?.length > 0 && !errors.agentContact
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                {(roleFormFiller === "Licenced Insurance Agent" ||
                  roleFormFiller === "Internal IT") && (
                  <Grid item xs={6}>
                    <FormField
                      type="text"
                      label="Agency Name"
                      name="agencyName"
                      value={values.agencyName ?? ""}
                      placeholder={"Agency Name"}
                      error={errors.agencyName && touched.agencyName}
                      success={
                        values.agencyName.length > 0 && !errors.agencyName
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormField
                        type="text"
                        label="Security Contact First Name"
                        name="MSPfirstcontact"
                        value={values.MSPfirstcontact ?? ""}
                        placeholder={"Security Contact Firar Name"}
                        error={
                          errors.MSPfirstcontact && touched.MSPfirstcontact
                        }
                        success={
                          values.MSPfirstcontact?.length > 0 &&
                          !errors.MSPfirstcontact
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormField
                        type="text"
                        label="Security Contact Last Name"
                        name="MSPlastcontact"
                        value={values.MSPlastcontact ?? ""}
                        placeholder={"Security Contact Last Name"}
                        error={errors.MSPlastcontact && touched.MSPlastcontact}
                        success={
                          values.MSPlastcontact?.length > 0 &&
                          !errors.MSPlastcontact
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormField
                    type="text"
                    label="Security Contact Email"
                    name="MSPemail"
                    value={values.MSPemail ?? ""}
                    placeholder={"Security Contact Email"}
                    error={errors.MSPemail && touched.MSPemail}
                    success={values.MSPemail.length > 0 && !errors.MSPemail}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Is the applicant's network security managed in-house or
                outsourced?
              </MDTypography>
              <label htmlFor={"MSPsource_In-House"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"In-House"}
                    name={"MSPsource"}
                    inputProps={{ "aria-label": "In-House" }}
                    onChange={() => {
                      setFieldValue("MSPsource", "In-House");
                    }}
                    id={"MSPsource_In-House"}
                    checked={values.MSPsource === "In-House"}
                    error={errors.MSPsource && touched.MSPsource}
                    success={values.MSPsource.length > 0 && !errors.MSPsource}
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      In-House
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              <label htmlFor={"MSPsource_Outsourced"}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    value={"Outsourced"}
                    name={"MSPsource"}
                    inputProps={{ "aria-label": "Outsourced" }}
                    onChange={() => {
                      setFieldValue("MSPsource", "Outsourced");
                    }}
                    id={"MSPsource_Outsourced"}
                    checked={values.MSPsource === "Outsourced"}
                    error={errors.MSPsource && touched.MSPsource}
                    success={values.MSPsource.length > 0 && !errors.MSPsource}
                  />
                  <MDBox sx={{ flexGrow: 1 }}>
                    <MDTypography variant="body2" fontWeight="regular">
                      Outsourced
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </label>
              {errors.MSPsource && touched.MSPsource ? (
                <FormHelperText error>{errors.MSPsource}</FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormField
                    type="text"
                    label="Security Contact Company Name"
                    name="securityContact"
                    value={values.securityContact ?? ""}
                    placeholder={"Security Contact Company Name"}
                    error={errors.securityContact && touched.securityContact}
                    success={
                      values.securityContact.length > 0 &&
                      !errors.securityContact
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormField
                    type="number"
                    label="Security Contact Phone Number"
                    name="MSPphone"
                    value={values.MSPphone ?? ""}
                    placeholder={"Security Contact Phone Number"}
                    error={errors.MSPphone && touched.MSPphone}
                    success={values.MSPphone.length > 0 && !errors.MSPphone}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <TextField
                label="Additional Notes"
                fullWidth
                multiline
                minRows={8}
                name="additionalNotes"
                value={values.additionalNotes ?? ""}
                placeholder={"Type here..."}
                onChange={(e) => {
                  setFieldValue("additionalNotes", e.target.value);
                }}
                error={errors.additionalNotes && touched.additionalNotes}
                success={
                  values.additionalNotes?.length > 0 && !errors.additionalNotes
                }
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default PartnerFormFormikWrapper;
