import { useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
// @mui material components

// import images
import orgIcon from "assets/images/icons/organization-icon.png";
import MDButton from "components/MDButton";
import { showMsg } from "utils/general";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from "@mui/material";
import TermsandConditions from "./TermsandConditions";

const SelectOrganizationType = (props) => {
  const navigate = useNavigate();
  const [isTosAccepted, setIsTosAccepted] = useState(false);
  const [isTosError, setIsTosError] = useState(false);
  const [open, setOpen] = useState(false);

  const btnOnClickRedirectToStepTwo = (step_type) => {
    if (isTosAccepted === false) {
      setIsTosError(true);
      showMsg("error", "Please accept the terms and conditions.");
      return false;
    }
    let redirectUrl = "/";
    if (step_type === "create_new_organization") {
      redirectUrl = "/setup-account/step-two";
      navigate(redirectUrl);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MDBox my={3} textAlign="center">
      <MDTypography mb={1} variant="h4" fontWeight="bold">
        Let’s get started!
      </MDTypography>
      <MDTypography textAlign="center" variant="body2" color="text">
        Create company
      </MDTypography>
      {/* Terms and conditions dialog */}
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="lg">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          TERMS OF SERVICE LAST UPDATE:{" "}
          <b style={{ color: "red" }}>March 19, 2024</b>
        </DialogTitle>
        <DialogContent dividers>
          <TermsandConditions />
        </DialogContent>
      </Dialog>
      {/* terms and conditions dialog end */}
      <MDButton
        onClick={() => btnOnClickRedirectToStepTwo("create_new_organization")}
        sx={{ display: "block", margin: "32px auto", padding: 0 }}
      >
        <MDBox
          border="1px solid #7B809A"
          borderRadius="xxl"
          p={5}
          mx="auto"
          width="fit-content"
        >
          <MDBox
            component="img"
            src={orgIcon}
            alt="Compliance Scorecard logo"
            sx={{ maxWidth: "125px" }}
          />
          <MDTypography variant="h4" fontWeight="bold">
            Team
          </MDTypography>
        </MDBox>
      </MDButton>
      <MDBox textAlign="center" alignItems="center" ml={-1} pt={2}>
        <Checkbox
          checked={isTosAccepted}
          onChange={(event) => {
            setIsTosError(false);
            setIsTosAccepted(event.target.checked);
          }}
        />

        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
        >
          &nbsp;&nbsp;I agree to the&nbsp;
        </MDTypography>
        <MDTypography
          component="a"
          variant="button"
          fontWeight="bold"
          color="success"
          textGradient
          onClick={handleClickOpen}
          sx={{ cursor: "pointer" }}
        >
          Terms and Conditions
        </MDTypography>
        {isTosError ? (
          <FormHelperText
            error
            sx={{ textAlign: "center" }}
            textAlign="center"
            alignItems="center"
          >
            Please accept the terms and conditions.
          </FormHelperText>
        ) : null}
      </MDBox>
      <MDBox display="flex" justifyContent="center" mt={2} pb={4}>
        <MDButton
          onClick={() => btnOnClickRedirectToStepTwo("create_new_organization")}
          type="button"
          // variant="gradient"
          color="success"
        >
          Proceed
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

export default SelectOrganizationType;
