import DefaultCell from "views/components/TableCells/DefaultCell";

const dataTableData = {
  columns: [
    {
      Header: "msp count",
      accessor: "mspCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "client count",
      accessor: "clientsCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "total accounts",
      accessor: "totalAccounts",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default dataTableData;
