import DefaultCell from "views/components/TableCells/DefaultCell";
import AuditLogsNameBtnTr from "./AuditLogsNameBtnTr";
const AuditLogsDataTableData = {
  columns: [
    {
      Header: "event",
      accessor: "description",
      Cell: ({ value, column, row }) => (
        <AuditLogsNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "user",
      accessor: "causer.name",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "date",
      accessor: "created_at",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default AuditLogsDataTableData;
