function configs(labels, colors, name, dataset, tooltip, palette) {
  labels.push("Not Started");
  colors.push("#eeeeee");

  return {
    data: {
      labels: labels,
      datasets: [
        {
          label: name,
          cutout: "50%",
          backgroundColor: colors,
          data: dataset,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
          labels: {
            color: palette?.text?.main,
            filter: (legendItem, chartData) =>
              legendItem.text !== "Not Started",
          },
        },
        tooltip: {
          position: "nearest",
          callbacks: {
            label: (item) => " " + (tooltip[item.dataIndex] || ""),
          },
        },
      },
    },
  };
}

export default configs;
