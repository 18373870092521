import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import { Formik, Form } from "formik";

function BackToDraftDialog({ open, onClose, submitBackToDraft }) {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitForm = async (values) => {
    submitBackToDraft();
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Back to Draft</DialogTitle>
      <Formik
        initialValues={{}}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="RejectPolicyForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="body1"
                    color="text"
                    fontWeight="regular"
                  >
                    Are you sure? Once this document goes back to draft all pending reviews will be cancelled.
                  </MDTypography>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="error"
                    sx={{ padding: "12px 12px" }}
                  >
                    Confirm
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner subClass="text-center" color="success" size="lg" />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default BackToDraftDialog;
