import { updatePoam } from "features/company/riskRegisterActions";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// @mui icons
import { Fragment, useState } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";

import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import { updateTaskListItem } from "features/company/taskListActions";

const TaskListItemPriorityLevelDropdown = (props) => {
  const { row, objUser, options, field, loadMoreItems } = props;
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState(row.original[field]);
  const dispatch = useDispatch();

  const update = (value) => {
    setLoading(true);
    let taskListItemId = row.original.id;
    const objData = {
      [field]: value.toLowerCase(),
      id: taskListItemId
    };

    dispatch(updateTaskListItem({ objUser, objData, taskListId: row.original?.task_list_id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 201) {
          setLoading(false);
          showMsg("success", "TaskListItem updated successfully");
          if (field == "status" && value == "Completed") {
            loadMoreItems();
          }
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.status === 401) {
          showMsg("error", "User is not authorized ");
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.message
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
          {loading ? (
            <LoadingSpinner subClass="text-center" color="success" size="sm" />
          ) : (
            <MDBox>
              <Autocomplete
                name="value"
                disableClearable
                value={currentValue ? currentValue.charAt(0).toUpperCase() + currentValue.slice(1) : ''}
                options={options}
                getOptionLabel={(option) => (option ? option : "")}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="" fullWidth />
                )}
                onChange={(event, value) => {
                  console.log(value, event.target.value);
                  setCurrentValue(value);
                  update(value);
                }}
              />
            </MDBox>
          )}
    </Fragment>
  );
};

export default TaskListItemPriorityLevelDropdown;
