// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useConfirm } from "material-ui-confirm";
import { shareAssessmentTemplate } from "features/company/assessmentsActions";
import { showMsg } from "utils/general";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { useMaterialUIController } from "context";

const TemplateShareBtnTr = (props) => {
  const { row, reloadFunc, objUser } = props;
  const [isSharing, setIsSharing] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const shareTemplateAction = async () => {
    try {
      await confirm({
        description: "Are you sure you want to share this template globally? ",
        confirmationButtonProps: {
          color: "success",
          variant: "contained",
          sx: {
            backgroundColor: "#4CAF50",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1b5e20",
              color: "#fff",
              boxShadow:
                "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
        cancellationButtonProps: {
          color: "error",
          variant: "contained",
          sx: {
            backgroundColor: "#d32f2f",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#d32f2f",
              color: "#fff",
              boxShadow:
                "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
      });
    } catch (error) {
      return;
    }

    setIsSharing(true);
    try {
      await dispatch(
        shareAssessmentTemplate({ objUser, templateId: row.original.id })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          showMsg("success", "Template share request created.");
          reloadFunc();
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsSharing(false);
          console.log(rejectedValueOrSerializedError.response);
          showMsg("error", "Error sharing template. Please, try again.");
        });
    } finally {
      setIsSharing(false);
    }
  };

  return isSharing ? (
    <LoadingSpinner subClass="text-center" color="success" size="sm" />
  ) : (
    <MDButton
      onClick={shareTemplateAction}
      variant="gradient"
      sx={{ marginLeft: "1rem" }}
      disabled={row.original.shareRequest || row.original.original_template_id}
    >
      <MDBox
        color={
          row.original.shareRequest || row.original.original_template_id
            ? ""
            : "success"
        }
        display="flex"
        alignItems="center"
      >
        <Icon sx={{ fontWeight: "bold", color: darkMode && "gray" }}>
          share
        </Icon>
        {/* <MDTypography
          variant="p"
          color={
            row.original.shareRequest || row.original.original_template_id
              ? ""
              : "success"
          }
          fontWeight="medium"
          sx={{
            lineHeight: 0,
            marginLeft: "0.5rem",
            color: darkMode && "gray",
          }}
        >
          Share Globally
        </MDTypography> */}
      </MDBox>
    </MDButton>
  );
};

export default TemplateShareBtnTr;
