export const types_to_desc = {
  POLICY_ADOPTERS: "Adopters",
  POLICY_REVIEWERS: "Reviewers",
  POLICY_APPROVERS: "Approvers",
  POLICY_LEGAL_REVIEWERS: "Legal",
  ASSESSMENT_AUDIENCE: "Assessment",
};

const desc_to_types = {};
Object.keys(types_to_desc).forEach((key) => {
  desc_to_types[types_to_desc[key]] = key;
});

export { desc_to_types };
