
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
const TemplateNameBtnTr = (props) => {
  const { value, row, onClickName } = props;

  return (
    <MDButton
      variant="text"
      onClick={() => onClickName(row.original.element)}
    >
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default TemplateNameBtnTr;
