import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

export const checkIfMSPHasConfiguredAnyConnectors = createAsyncThunk(
  "company/checkIfMSPHasConfiguredAnyConnectors",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/check-for-configured-asset-management-connectors`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createBlankAssetPolicy = createAsyncThunk(
  "company/assets/createBlankAssetPolicy",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/assets/create-blank`,
        values
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveApiKeys = createAsyncThunk(
  "company/saveConnectWiseApiKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/cybercns/keys?id=${values.company_id}`,
        values.values
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getCyberCNSApiKeys = createAsyncThunk(
  "company/getCyberCNSApiKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/cybercns/keys`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getCyberCNSClients = createAsyncThunk(
  "company/getCyberCNSClients",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/cybercns/fetch-clients`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveClientMapping = createAsyncThunk(
  "company/saveClientMapping",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/cybercns/save-client-mapping`,
        {
          existingC: values.existingC,
          selectedCyberCNSClientToMap: values.selectedCyberCNSClientToMap,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const removeClientMapping = createAsyncThunk(
  "company/removeClientMapping",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/cybercns/remove-client-mapping`,
        {
          selectedCyberCNSClientToRemove: values.cnsClient,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAllClientMappings = createAsyncThunk(
  "company/getAllClientMappings",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/cybercns/client-mappings/?per_page=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPreviousSyncInformation = createAsyncThunk(
  "company/getPreviousSyncInformation",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/cybercns/get-previous-sync-information`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveSyncFrequencySettingsForConnectSecureHardwareAssets =
  createAsyncThunk("company/saveSyncSettings", async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/cybercns/save-sync-settings`,
        {
          selectedSyncOption: values.selectedValue,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  });

export const getCyberCNSAssets = createAsyncThunk(
  "company/getCyberCNSAssets",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/cybercns/fetch-assets`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const importCyberCNSAssets = createAsyncThunk(
  "company/importCyberCNSAssets",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/cybercns/import-assets`,
        values
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const removeApiKeys = createAsyncThunk(
  "company/removeApiKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/cybercns/keys`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
