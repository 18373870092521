import DefaultCell from "views/components/TableCells/DefaultCell";
import CampaignNameBtrTr from "../../CreatePolicyDocumentStepAdoptionTable/CampaignNameBtrTr";

const dataTableData = (onClickName) => ({
  columns: [
    {
      Header: "campaign title",
      accessor: "campaign_name",
      Cell: ({ value, column, row }) => (
        <CampaignNameBtrTr
          value={value}
          column={column}
          row={row}
          onClick={onClickName}
        />
      ),
    },
    {
      Header: "date",
      accessor: "created_at",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "audience",
      accessor: "audience",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "adoption status",
      accessor: "adoption_status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
});

export default dataTableData;
