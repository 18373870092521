// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React Examples
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
// @mui icons
import { useParams } from "react-router-dom";
import AddNewAudienceForm from "./components/AddNewAudienceForm";

const AddNewAudience = (props) => {
  let { id } = useParams();
  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      {/* <Card> */}
      <Grid my={3} container justifyContent="center" alignItems="center" width="100%">
        <Grid item xs={12} lg={12}>
          <AddNewAudienceForm objUser={props.objUser} client_id={id} />
        </Grid>
      </Grid>
      {/* </Card> */}
    </DashboardLayout>
  );
};

export default AddNewAudience;
