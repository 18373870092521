import Grid from "@mui/material/Grid";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";

const BasicInfoFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue, objStates } = props;

  const handlePhoneNumberChange = (event) => {
    let inputValue = event.target.value;
    let previousValue = values.txtPhoneNumber;

    const numericValue = inputValue.replace(/[^\d]/g, ""); // Remove non-numeric characters

    if (numericValue.length === 10) {
      setFieldValue(
        "txtPhoneNumber",
        `${numericValue.slice(0, 3)}-${numericValue.slice(
          3,
          6
        )}-${numericValue.slice(6)}`
      );
    } else if (numericValue.length === 11) {
      setFieldValue(
        "txtPhoneNumber",
        `(${numericValue[0]}) ${numericValue.slice(1, 4)}-${numericValue.slice(
          4,
          7
        )}-${numericValue.slice(7)}`
      );
    } else if (numericValue.length > 11) {
      // Return the raw numeric value for any other length
      setFieldValue("txtPhoneNumber", previousValue);
    } else {
      setFieldValue("txtPhoneNumber", numericValue);
    }
  };
  return (
    <Fragment>
      <Grid container justifyContent="space-between" p={5} spacing={2}>
        <Grid item xs={12} md={6}>
          <FormField
            type="text"
            label="First Name"
            name="txtName"
            value={values.txtName}
            placeholder={"First Name"}
            error={errors.txtName && touched.txtName}
            success={values.txtName.length > 0 && !errors.txtName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormField
            type="text"
            label="Last Name"
            name="txtLastName"
            value={values.txtLastName}
            placeholder={"Last Name"}
            error={errors.txtLastName && touched.txtLastName}
            success={values.txtLastName.length > 0 && !errors.txtLastName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormField
            type="text"
            label="Phone Number"
            name="txtPhoneNumber"
            value={values.txtPhoneNumber}
            placeholder={"Phone Number"}
            error={errors.txtPhoneNumber && touched.txtPhoneNumber}
            success={values.txtPhoneNumber.length > 0 && !errors.txtPhoneNumber}
            onChange={handlePhoneNumberChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormField
            type="text"
            label="Email"
            name="txtEmail"
            value={values.txtEmail}
            placeholder={"Email"}
            error={errors.txtEmail && touched.txtEmail}
            success={values.txtEmail.length > 0 && !errors.txtEmail}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormField
            type="text"
            label="Role"
            name="txtRole"
            value={values.txtRole}
            placeholder={"Role"}
            error={errors.txtRole && touched.txtRole}
            success={values.txtRole.length > 0 && !errors.txtRole}
            disabled={true}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default BasicInfoFormFormikWrapper;
