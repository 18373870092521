/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";

function DashboardButton({
  className,
  disabled,
  btnText,
  btnIcon,
  textColor,
  bgColor,
  component,
  ...rest
}) {
  return (
    <MDButton
      component={component}
      {...rest}
      sx={{ padding: "8px 12px 7px", minHeight: "auto" }}
      color={bgColor}
      disabled={disabled}
      className={className}
    >
      <Icon style={{ transform: "scale(1.3)" }}>{btnIcon}</Icon>
      <MDTypography
        fontWeight="bold"
        sx={{ marginLeft: "0.75rem" }}
        color={textColor}
        variant="button"
      >
        {btnText}
      </MDTypography>
    </MDButton>
  );
}

export default DashboardButton;
