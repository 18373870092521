// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ContactsListDialog from "./ContactsListDialog";
// @mui icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Fragment, useState } from "react";

const KnowledgeBaseSharesActionBtnTr = (props) => {
  const {
    value,
    row,
  } = props;

  const [openContactsList, setOpenContactsList] = useState(false);

  const onClose = () => {
    setOpenContactsList(false);
  };

  return (
    <Fragment>
      <ContactsListDialog
        open={openContactsList}
        onClose={onClose}
        contacts={value}
      />

      <MDButton onClick={() => setOpenContactsList(true)}>
        <MDBox color="dark" display="flex" alignItems="center">
          <VisibilityIcon></VisibilityIcon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.dark"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            View
          </MDTypography>
        </MDBox>
      </MDButton>
    </Fragment>
  );
};

export default KnowledgeBaseSharesActionBtnTr;
