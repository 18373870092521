import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import dataTableData from "./components/DataTableData";
import { useDispatch } from "react-redux";
import { getAllPendingReviewsForUser } from "features/user/notificationActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import GlobalDatatable from "../../../../components/GlobalDatatable";
import { showMsg } from "utils/general";
import "./Styling.css";
import { Grid } from "@mui/material";
import { standardDateFormat } from "utils/general";
import ActionBtnTr from "./components/ActionBtnTr";

const PendingReviewsForUser = (props) => {
  const { objUser } = props;

  /**categories */
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const isClientUser =
    objUser.user && objUser.user.role
      ? objUser.user.role.name == "client_user"
      : false;

  const dispatch = useDispatch();

  useEffect(() => {
    loadPendingReviews(perPage, page);
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const roleBasedActions = (objUser) => {
    if (
      objUser.user.role.name === "company_user" ||
      objUser.user.role.name === "admin" ||
      (objUser.user.role.name === "client_user" &&
        objUser.user.company.can_download_pdfs === "yes")
    ) {
      return [
        {
          Header: "action",
          accessor: "action",
          // Cell: ({ value, column, row }) => {
          //   return <div onClick={() => console.log(row.original)}>Click Me</div>;
          // },
          Cell: ({ value, column, row }) => (
            <Fragment>
              <ActionBtnTr
                value={value}
                column={column}
                row={row}
                objUser={objUser}
              />
            </Fragment>
          ),
        },
      ];
    }
    return [];
  };

  const loadPendingReviews = (_per_page, _page) => {
    setIsLoadingMoreData(true);
    dispatch(getAllPendingReviewsForUser({ _per_page, _page }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreData(false);

        const response = originalPromiseResult;
        const objRows = [];
        const objList = response.data.pendingReviews;

        for (let index = 0; index < objList.length; index++) {
          const element = objList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.policy_id = element.policy_id;
          objTr.name = element.policy.title;
          objTr.company_name = element.policy.client.name;
          objTr.type = capitalizeFirstLetter(
            element.isSoleApproverReviewer ? "Review + Approval" : element.type
          );
          objTr.created_at = standardDateFormat(element.created_at);
          objTr.status = capitalizeFirstLetter(element.status);
          objTr.client_id = element.policy.client_id;
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMoreData(false);

        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        showMsg("error", "Something went wrong, please try again.");
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox>
        <Grid spacing={2} container width="100%">
          <Grid item xs={12} lg={12}>
            <Card sx={{ marginTop: "16px" }}>
              {isLoadingMoreData === false &&
              objTableData.rows.length > 0 &&
              totalCount > 0 ? (
                <GlobalDatatable
                  table={objTableData}
                  entriesPerPage={true}
                  canSearch
                  isClientUser={isClientUser}
                  totalCount={totalCount}
                  loadMoreCategories={loadPendingReviews}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  _page={page}
                  setPage={setPage}
                  setObjTableData={setObjTableData}
                  objUser={objUser}
                  roleBasedActions={() => roleBasedActions(objUser)}
                />
              ) : (
                <Fragment>
                  {isLoadingMoreData ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p className="empty-text-p" style={{ padding: "10px" }}>
                      Nothing pending to review
                    </p>
                  )}
                </Fragment>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default PendingReviewsForUser;
