// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import * as Yup from "yup";

import { useEffect, useState } from "react";

// formik components
import { Form, Formik } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
// Authentication layout components
// Other authentication methods components
// Images
import { unwrapResult } from "@reduxjs/toolkit";
import {
  saveApiKeys,
  getConnectorApiKeys,
  // getConnectorClients,
  // saveClientMapping,
  // removeClientMapping,
} from "features/nCentral/actions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import SaveApiKeysFormErrorsBlock from "./SaveApiKeysFormErrorsBlock";
import SaveApiKeysFormFormikWrapper from "./SaveApiKeysFormFormikWrapper";
import MDTypography from "components/MDTypography";
import { Box, textAlign } from "@mui/system";
import assetImageLogo from "assets/images/asset-preview.png";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import RouteIcon from "@mui/icons-material/Route";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, TextField, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useConfirm } from "material-ui-confirm";
import { removeApiKeys } from "features/nCentral/actions";

const SaveApiKeysFormJWTSchema = Yup.object().shape({
  url: Yup.string().required("Connection URL is required"),
  //customer_id: Yup.string().required("Customer id is required"),
  // client_id: Yup.string().required("Access key is required"),
  client_secrete: Yup.string().required("User token is required"),
});

const connector = "Ncentral";

const SaveApiKeysForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingKeys, setIsLoadingKeys] = useState(true);
  const [isFetchingClients, setIsFetchingClients] = useState(false);
  const [isMappingClient, setIsMappingClient] = useState("");
  const [isRemovingMapping, setIsRemovingMapping] = useState("");
  const company_id = props.objUser.user.company.id;

  const [objInitialValues, setObjInitialValues] = useState({
    url: "",
    //customer_id: "",
    // client_id: "",
    client_secrete: "",
  });
  const [openPolygonClientsDialog, setOpenPolygonClientsDialog] =
    useState(false);
  const [clientsResponseData, setClientsResponseData] = useState([]);
  const [clientsResponseDataBackup, setClientsResponseDataBackup] = useState(
    []
  );
  const [existingPolygonClients, setExistingPolygonClients] = useState([]);
  const [existingPolygonMappings, setExistingPolygonMappings] = useState([]);
  const [currentMappings, setCurrentMappings] = useState([]);
  const [selectedConnectorClientToMap, setSelectedConnectorClientToMap] =
    useState(null);

  const confirm = useConfirm();

  //clients search
  const [searchPhrase, setSearchPhrase] = useState("");

  const [objSaveApiKeysFormErrors, setObjSaveApiKeysFormErrors] = useState({});
  const onSubmitForm = async (values) => {
    setObjSaveApiKeysFormErrors({});
    setIsLoading(true);
    dispatch(saveApiKeys({ values, company_id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        if (response.status === 200) {
          let _objInitialValues = {
            url: response.data.url ?? "",
            // customer_id: response.data.api_company_id ?? "",
            //  client_id: response.data.key ?? "",
            client_secrete: response.data.secret ?? "",
          };
          setObjInitialValues(_objInitialValues);
          showMsg(
            "success",
            "Your " + connector + " Api Keys have been successfully saved."
          );
          // navigate("/clients");
        } else {
          console.error(connector + " API Keys:", response);
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.error(connector + " API Keys:", rejectedValueOrSerializedError);
        showMsg("error", "Please correct the errors.");
        const objErrors = rejectedValueOrSerializedError.response.data.errors;

        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error updating user");
        }
      });
  };

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = async () => {
    dispatch(getConnectorApiKeys())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        let _objInitialValues = {
          url: response.data.url ?? "",
          // customer_id: response.data.api_company_id ?? "",
          //  client_id: response.data.key ?? "",
          client_secrete: response.data.secret ?? "",
        };
        setObjInitialValues(_objInitialValues);
        setIsLoadingKeys(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingKeys(false);
      });
  };

  /**Also fetches current list of polygon clients */
  // const fetchConnectorClients = async () => {
  //   setIsFetchingClients(true);
  //   dispatch(getConnectorClients())
  //     .then(unwrapResult)
  //     .then((originalPromiseResult) => {
  //       const response = originalPromiseResult;
  //       const clientsResponseData = response.data.clientsToMap.Data;
  //       const polygonClients = response.data.polygonClients;
  //       //response.data.clientsToMap.total;

  //       setExistingPolygonClients(polygonClients);

  //       const existingMappings = polygonClients.map((client) => {
  //         return client.lion_guard_mapping
  //           ? parseInt(client.lion_guard_mapping.connector_client_id)
  //           : "Not mapped";
  //       });

  //       setExistingPolygonMappings(existingMappings);

  //       if (clientsResponseData.length > 0) {
  //         const sortedClientResponseData = clientsResponseData.sort((a, b) => {
  //           const nameA = a.Name.toLowerCase();
  //           const nameB = b.Name.toLowerCase();
  //           if (nameA < nameB) {
  //             return -1;
  //           }
  //           if (nameA > nameB) {
  //             return 1;
  //           }

  //           return 0;
  //         });
  //         setClientsResponseData(sortedClientResponseData);
  //         setClientsResponseDataBackup(sortedClientResponseData);
  //       } else {
  //         showMsg("info", "No environments were found");
  //       }

  //       setIsFetchingClients(false);
  //     })
  //     .catch((rejectedValueOrSerializedError) => {
  //       setIsFetchingClients(false);
  //       console.error(rejectedValueOrSerializedError);
  //       showMsg("error", "Error fetching environments from " + connector);
  //     });
  // };

  // const alreadyMappedClientName = (ConnectorClientId) => {
  //   const polyClient = existingPolygonClients.find((client) => {
  //     return (
  //       client?.lion_guard_mapping?.connector_client_id == ConnectorClientId
  //     );
  //   });
  //   return polyClient.name;
  // };

  //connector client search
  // const handleConnectorClientSearch = (searchValue) => {
  //   setSearchPhrase(searchValue);

  //   if (searchValue.trim() === "") {
  //     setClientsResponseData(clientsResponseDataBackup);
  //   } else {
  //     const filterResults = clientsResponseDataBackup.filter((item) => {
  //       return item.Name.toLowerCase().includes(searchValue.toLowerCase());
  //     });
  //     setClientsResponseData(
  //       filterResults.length === 0 ? clientsResponseDataBackup : filterResults
  //     );
  //   }
  // };

  // const mapSelectedClient = async (existingC) => {
  //   setIsMappingClient(existingC.id);
  //   dispatch(saveClientMapping({ existingC, selectedConnectorClientToMap }))
  //     .then(unwrapResult)
  //     .then((originalPromiseResult) => {
  //       showMsg(
  //         "success",
  //         "Mapping succesfully completed. Refreshing clients list"
  //       );
  //       setCurrentMappings((prev) => {
  //         return [
  //           ...prev,
  //           {
  //             existingC,
  //             selectedConnectorClientToMap,
  //           },
  //         ];
  //       });
  //       setIsMappingClient("");
  //       setClientsResponseData([]);
  //       fetchConnectorClients();
  //       onClose();
  //     })
  //     .catch((rejectedValueOrSerializedError) => {
  //       setIsMappingClient("");
  //       console.error(rejectedValueOrSerializedError);
  //       if (rejectedValueOrSerializedError.response.data.errors) {
  //         rejectedValueOrSerializedError.response.data.errors.forEach(
  //           (error) => {
  //             showMsg("error", error);
  //           }
  //         );
  //       } else if (rejectedValueOrSerializedError.response.data.message) {
  //         showMsg(
  //           "error",
  //           rejectedValueOrSerializedError.response.data.message
  //         );
  //       } else {
  //         showMsg("error", "Error mapping client");
  //       }
  //     });
  // };

  // const removeExistingMapping = async (cnsClient) => {
  //   setIsRemovingMapping(cnsClient.ID);
  //   dispatch(removeClientMapping({ cnsClient }))
  //     .then(unwrapResult)
  //     .then((originalPromiseResult) => {
  //       setIsRemovingMapping("");
  //       showMsg(
  //         "success",
  //         "Mapping succesfully removed. Refreshing clients list."
  //       );
  //       setClientsResponseData([]);
  //       fetchConnectorClients();
  //     })
  //     .catch((rejectedValueOrSerializedError) => {
  //       setIsRemovingMapping("");
  //       console.error(rejectedValueOrSerializedError);
  //       if (rejectedValueOrSerializedError.response.data.errors) {
  //         rejectedValueOrSerializedError.response.data.errors.forEach(
  //           (error) => {
  //             showMsg("error", error);
  //           }
  //         );
  //       } else if (rejectedValueOrSerializedError.response.data.message) {
  //         showMsg(
  //           "error",
  //           rejectedValueOrSerializedError.response.data.message
  //         );
  //       } else {
  //         showMsg("error", "Error removing current mapping");
  //       }
  //     });
  // };

  const openDialog = (cnctorClient) => {
    setSelectedConnectorClientToMap(cnctorClient);
    setOpenPolygonClientsDialog(true);
  };
  const onClose = () => {
    setOpenPolygonClientsDialog(false);
  };

  const disconnectConnection = async () => {
    setIsLoadingKeys(true);
    dispatch(removeApiKeys())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingKeys(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        if (response.status === 200) {
          let _objInitialValues = {
            url: "",
            //customer_id: "",
            // client_id: "",
            client_secrete: "",
          };
          setObjInitialValues(_objInitialValues);
          showMsg(
            "success",
            "Your" + connector + " connection have been successfully removed."
          );
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingKeys(false);
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong, please try again");
        // handle result here
      });
  };

  const btnOnClickDisconnect = () => {
    confirm({
      description: "Your " + connector + " API keys will be removed.",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        disconnectConnection();
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <>
      <Grid item xs={12} lg={6}>
        {isLoadingKeys === false ? (
          <Formik
            initialValues={objInitialValues}
            validationSchema={SaveApiKeysFormJWTSchema}
            onSubmit={onSubmitForm}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={"SaveApiKeysForm"} autoComplete="off">
                <Card sx={{ height: "100%" }}>
                  <MDBox>
                    <SaveApiKeysFormFormikWrapper
                      connector={connector}
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />

                    {Object.keys(objSaveApiKeysFormErrors).length > 0 ? (
                      <Grid
                        container
                        justifyContent="space-between"
                        pl={5}
                        pr={5}
                        pt={0}
                        pb={5}
                      >
                        <SaveApiKeysFormErrorsBlock
                          objSaveApiKeysFormErrors={objSaveApiKeysFormErrors}
                        />
                      </Grid>
                    ) : null}

                    <MDBox
                      sx={{ margin: "0 0 40px -40px" }}
                      width="100%"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      {isLoading === false ? (
                        <>
                          {objInitialValues.url && (
                            <MDButton
                              type="button"
                              // variant="gradient"
                              color="error"
                              sx={{ padding: "12px 20px", margin: "0px 5px" }}
                              onClick={btnOnClickDisconnect}
                            >
                              Disconnect
                            </MDButton>
                          )}

                          <MDButton
                            type="submit"
                            color="success"
                            sx={{ padding: "12px 20px", margin: "0px 5px" }}
                          >
                            Save
                          </MDButton>
                        </>
                      ) : (
                        <LoadingSpinner
                          subClass="text-center"
                          color="success"
                          size="lg"
                        />
                      )}
                    </MDBox>
                  </MDBox>
                </Card>
                {/* </MDBox> */}
              </Form>
            )}
          </Formik>
        ) : (
          <MDBox>
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
            <MDTypography sx={{ textAlign: "center" }}>
              Loading Keys
            </MDTypography>
          </MDBox>
        )}
      </Grid>
    </>
  );
};

export default SaveApiKeysForm;
