import { useLocation, Navigate } from "react-router-dom";
// Redirect to the previous step in the route
const RedirectPrevious = () => {
  const location = useLocation();
  const route = location.pathname.split("/").slice(1);
  const routeDictionary = location.state?.routeDictionary ?? {};
  const previous = "/" + route.slice(0, -1).join("/");
  return <Navigate to={previous} replace={true} state={ { routeDictionary } }/>;
};

export default RedirectPrevious;
