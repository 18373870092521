// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";

// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { Alert } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const SaveApiKeysFormFormikWrapper = (props) => {
  const { values, connector, errors, touched, setFieldValue } = props;
  //console.log(values);
  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h5"
          fontWeight="bold"
        >
          Add Your N-Central/N-Able API Keys
        </MDTypography>

        <MDBox mt={2}>
          <Grid container xs={12} justifyContent="space-between" px={5}>
            <Alert
              icon={<InfoIcon fontSize="inherit" />}
              sx={{ width: "100%", marginBottom: "20px" }}
              severity="success"
            >
              <a
                target="_blank"
                href="https://documentation.n-able.com/N-central/userguide/Content/User_Management/Role%20Based%20Permissions/role_based_permissions_create_APIuser.htm"
              >
                How to obtain your API URL and User Token
              </a>{" "}
            </Alert>

            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter API URL (without a trailing slash) "
                name="url"
                value={values.url}
                placeholder={`${connector} Base URL`}
                error={errors.url && touched.url}
                success={values.url.length > 0 && !errors.url}
              />
            </Grid>

            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="password"
                label="Enter Your User Token"
                name="client_secrete"
                value={values.client_secrete}
                placeholder={"User Token"}
                error={errors.client_secrete && touched.client_secrete}
                success={
                  values.client_secrete.length > 0 && !errors.client_secrete
                }
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default SaveApiKeysFormFormikWrapper;
