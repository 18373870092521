import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { Fragment, useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { TINYMC_API_KEY } from "components/utils/constants/misc";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getPolicies,
  getAudiences,
  createNewAdoptionCampaign,
} from "features/company/adoptionCampaignActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDTypography from "components/MDTypography";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { standardDateFormat } from "utils/general";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import borders from "assets/theme/base/borders";
import MergeTagsInfo from "views/components/MergeTagsInfo";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PoliciesManagementTable from "./PoliciesTable/ManagementTable";
import dataTableData from "./PoliciesTable/DataTableData";
import { useMaterialUIController } from "context";
import InfoIcon from "@mui/icons-material/Info";
import MDInput from "components/MDInput";

const Index = (props) => {
  const { borderWidth, borderColor } = borders;
  const navigate = useNavigate();
  const { objUser } = props;
  // const { id } = useParams();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const dispatch = useDispatch();
  const confirm = useConfirm();

  /**Policies table */
  const [isLoadingPolicies, setIsLoadingPolicies] = useState(false);
  const [trackedPolicies, setTrackedPolicies] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [objPoliciesTableData, setObjPoliciesTableData] =
    useState(dataTableData);
  const [totalPoliciesCount, setTotalPoliciesCount] = useState(0);
  const [perPagePolicy, setPerPagePolicy] = useState(100);
  const [pagePolicies, setPagePolicies] = useState(1);

  const [chooseOrderScreen, setChooseOrderScreen] = useState(false);
  const [step, setStep] = useState(1);

  /**re-order */
  const [sections, setSections] = useState([]);
  const [selectedIndexToSave, setSelectedIndexToSave] = useState(null);

  /**Generating WISP*/
  const [campaignName, setCampaignName] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");
  const [reminderTime, setReminderTime] = useState(null);
  const [open, setOpen] = useState(false);
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchPolicies();
    fetchAudiences();
  }, []);

  const fetchPolicies = (rowOriginal) => {
    setIsLoadingPolicies(true);
    dispatch(getPolicies({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingPolicies(false);
        const response = originalPromiseResult;

        const objRows = [];
        const objClientTemplatesList = response.data.policies;

        for (let index = 0; index < objClientTemplatesList.length; index++) {
          const element = objClientTemplatesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.title = element.title;
          objTr.category = element.category
            ? element.category.display_name
            : "";
          objTr.created_at = standardDateFormat(element.updated_at);
          objTr.selected = false;
          objTr.order = index + 1;
          objTr.element = element;
          objRows.push(objTr);
        }

        setTrackedPolicies(objRows);
        setTotalPoliciesCount(response.data.total);
        setObjPoliciesTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingPolicies(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  const fetchAudiences = (rowOriginal) => {
    dispatch(getAudiences({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setAudiences(response.data.audiences);
      })
      .catch((rejectedValueOrSerializedError) => {
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong when fetching audiences, please try again."
        );
      });
  };

  //   const navigateToCreateNewWisp = () => {
  //     navigate(`/wisps/new`);
  //   };

  const btnOnClickSelectAudience = () => {
    const currentPolicies = [...trackedPolicies];
    const selectedPols = currentPolicies.filter(
      (item) => item.selected === true
    );

    if (selectedPols.length > 0) {
      setSections(selectedPols);
      // setChooseOrderScreen(true);
      setStep(2);
    } else {
      showMsg("info", "Please select policies for adoption campaign");
    }
  };

  const btnOnClickOpenModalForInputtingWispName = () => {
    if (selectedAudience) {
      setOpen(true);
    } else {
      showMsg("info", "No audience selected");
    }
  };

  const btnOnClickCreateCampaign = () => {
    if (campaignName == "") {
      showMsg("info", "Please provide a title for this campaign");
      return false;
    } else {
      setIsCreatingCampaign(true);
      dispatch(
        createNewAdoptionCampaign({
          objUser,
          selectedAudience,
          campaignName,
          campaignDescription,
          reminderTime,
          sections,
        })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsLoadingPolicies(false);
          setIsCreatingCampaign(false);
          showMsg(
            "success",
            campaignName + " adoption campaign created successfully"
          );
          navigate("/adoption-campaigns");
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsCreatingCampaign(false);
          const objErrorRsp =
            rejectedValueOrSerializedError.response?.data?.message;
          if (objErrorRsp) {
            showMsg("error", objErrorRsp);
          } else {
            showMsg("error", "Something went wrong, please try again.");
          }
        });
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardBreadcrumbs />

        {/* Generate adoption campaign Dialog */}
        <Dialog open={open} onClose={onClose} fullWidth={true}>
          <DialogTitle sx={{ pb: 0 }}>Additional Campaign Details</DialogTitle>

          <DialogContent>
            <MDBox mt={2}>
              <TextField
                label="Enter Campaign Name"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  width: "100%",
                }}
                onChange={(e) => setCampaignName(e.target.value)}
                InputLabelProps={{
                  sx: {
                    fontWeight: 700,
                  },
                }}
                InputProps={{
                  sx: {
                    fontWeight: 700,
                  },
                }}
              />
            </MDBox>
            <MDBox mt={2}>
              <TextField
                label="Campaign Description"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  width: "100%",
                }}
                onChange={(e) => setCampaignDescription(e.target.value)}
                InputLabelProps={{
                  sx: {
                    fontWeight: 700,
                  },
                }}
                InputProps={{
                  sx: {
                    fontWeight: 700,
                  },
                }}
              />
            </MDBox>
            <MDBox mt={2}>
              <Autocomplete
                label="Reminder Time"
                options={["1 Day", "3 Days", "7 Days"]}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="Reminder Time"
                    fullWidth
                  />
                )}
                onChange={(event, value) => {
                  if (value === null || value === "") {
                    setReminderTime(null);
                  } else {
                    setReminderTime(value.charAt(0));
                  }
                }}
              />
            </MDBox>
          </DialogContent>
          <DialogActions>
            {isCreatingCampaign === false ? (
              <>
                <MDButton
                  type="submit"
                  onClick={() => btnOnClickCreateCampaign()}
                  color="success"
                  sx={{ padding: "12px 12px" }}
                >
                  Create Campaign
                </MDButton>
                <MDButton
                  type="button"
                  variant="outlined"
                  color="dark"
                  sx={{ padding: "12px 12px" }}
                  onClick={onClose}
                >
                  Cancel
                </MDButton>
              </>
            ) : (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            )}
          </DialogActions>
        </Dialog>

        {/* selecting policies */}
        <Grid
          container
          width="100%"
          display={step === 1 ? "block" : "none"}
          spacing={2}
        >
          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoadingPolicies ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <>
                  {totalPoliciesCount == 0 ? (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="p" fontWeight="regular">
                          You don't have any published policies yet
                        </MDTypography>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <MDBox
                        mb={2}
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                          paddingLeft: "2rem",
                          paddingTop: "2rem",
                          width: "100%",
                        }}
                      >
                        <MDTypography
                          // sx={{ backgroundColor: "red" }}
                          variant="p"
                          fontWeight="bold"
                          color="primary"
                        >
                          Step 1: Select policies for adoption campaign
                        </MDTypography>
                      </MDBox>
                      <PoliciesManagementTable
                        table={objPoliciesTableData}
                        entriesPerPage={true}
                        canSearch
                        totalCount={totalPoliciesCount}
                        // loadMoreRMF={loadMoreRMF}
                        // perPage={perPageRMF}
                        // setPerPage={setPerPageRMF}
                        _page={pagePolicies}
                        setPage={setPagePolicies}
                        // setObjRMFTableData={setObjPoliciesTableData}
                        setTrackedPolicies={setTrackedPolicies}
                        trackedPolicies={trackedPolicies}
                      />
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>

        {/* Selecting audience */}
        <Grid
          container
          width="100%"
          display={step === 2 ? "block" : "none"}
          spacing={2}
        >
          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
              }}
            >
              <MDBox
                mb={2}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                  paddingTop: "2rem",
                  width: "100%",
                }}
              >
                <MDTypography
                  variant="p"
                  fontWeight="bold"
                  color="primary"
                  sx={{ marginBottom: "1rem" }}
                >
                  Step 2: Select Audience for this campaign
                </MDTypography>
              </MDBox>

              <MDBox sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
                <Grid item xs={12} pt={0}>
                  <MDBox mb={3}>
                    <MDBox mb={2} display="inline-block">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize"
                      >
                        Audience
                      </MDTypography>
                    </MDBox>
                    <Autocomplete
                      // defaultValue={audiences.length > 0 ? audiences[0] : ""}
                      options={audiences}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <MDInput {...params} variant="standard" />
                      )}
                      onChange={(event, value) => {
                        if (value === null) {
                          setSelectedAudience("");
                        } else {
                          setSelectedAudience(value);
                        }
                      }}
                    />
                  </MDBox>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* buttons */}
        <MDBox
          sx={{
            position: "fixed",
            bottom: "3rem",
            right: "4rem",
            display: "flex",
            flexDirection: "column",
            width: "18%",
            gap: 2,
          }}
        >
          <MDButton
            color="info"
            onClick={() => btnOnClickSelectAudience()}
            sx={{
              padding: "20px",
              display: step === 1 ? "block" : "none",
            }}
          >
            Step 2: Add an audience
          </MDButton>
          <MDButton
            color="success"
            onClick={() => btnOnClickOpenModalForInputtingWispName()}
            sx={{
              padding: "20px",
              display: step === 2 ? "block" : "none",
            }}
          >
            Step 3: Create Adoption Campaign
          </MDButton>
          <MDButton
            color="primary"
            onClick={() => setStep(1)}
            sx={{
              padding: "20px",
              display: step !== 1 ? "block" : "none",
            }}
          >
            Back to policy selection
          </MDButton>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Index;
