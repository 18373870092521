import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";

import PageLayout from "views/containers/PageLayout";

import SignUpFunnelHeader from "../common/SignUpFunnelHeader";
import SignUpFunnelStepsHeader from "../common/SignUpFunnelStepsHeader";
import { getSteps } from "../common/StepsHelpers";
import BasicInfoFormSignup from "layouts/pages/account/AccountSettings/components/BasicInfo/BasicInfoFormSignup";

const SignUpUserDetails = (props) => {
  const { objUser } = props;
  const steps = getSteps();
  const currentStep = 1;

  return (
    <div>
      <PageLayout background="default">
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <SignUpFunnelHeader />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Grid item xs={12} lg={8}>
              <Card sx={{ height: "100%" }}>
                <SignUpFunnelStepsHeader
                  steps={steps}
                  activeStep={currentStep}
                />
                <MDBox p={3}>
                  <BasicInfoFormSignup
                    objUser={objUser}
                    redirectUrl="/setup-account/step-three"
                    title="User Details"
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </PageLayout>
    </div>
  );
};

export default SignUpUserDetails;
