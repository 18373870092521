// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// page layout components
import CoverLayout from "views/containers/CoverLayout";

// Images
import bgImage from "assets/images/polygon-website-backdrop.jpg";
import VerifyEmailAddressSection from "./components/VerifyEmailAddressSection";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { verifiyEmailAddress } from "features/user/userActions";
const VerifyEmailAddress = (props) => {
  const { objUser } = props;
  let { verification_token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [objVerifiyEmailAddressErrors, setObjVerifiyEmailAddressErrors] =
    useState({});

  useEffect(() => {
    const objPostData = { verification_token };
    setObjVerifiyEmailAddressErrors({});
    setIsLoading(true);
    dispatch(verifiyEmailAddress(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);

        const response = originalPromiseResult;

        if (response.data.status === "success") {
          setIsSuccess(true);
          setTimeout(() => {
            navigate("/setup-account/step-one");
          }, 5000);
        } else {
          setIsError(true);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);

        const objErrors = rejectedValueOrSerializedError.response.data.errors;

        if (
          objErrors &&
          objErrors.verification_token &&
          objErrors.verification_token !== undefined &&
          objErrors.verification_token[0] === "Email verified already"
        ) {
          setIsSuccess(true);
          setTimeout(() => {
            navigate("/setup-account/step-one");
          }, 5000);
        } else {
          setIsError(true);
        }
        // handle result here
      });
  }, []);

  return (
    <CoverLayout image={bgImage}>
      <MDBox display="flex" flexDirection="column" height="100%">
        <VerifyEmailAddressSection
          objUser={objUser}
          verification_token={verification_token}
          isLoading={isLoading}
          isError={isError}
          objVerifiyEmailAddressErrors={objVerifiyEmailAddressErrors}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
        />
      </MDBox>
    </CoverLayout>
  );
};

export default VerifyEmailAddress;
