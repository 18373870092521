import React, { useState, useEffect } from "react";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
  SortableTree,
  TreeItemComponentProps,
  SimpleTreeItemWrapper,
} from "dnd-kit-sortable-tree";
import DashboardActionButton from "views/components/DashboardActionButton";
import { Card, FormControlLabel, Checkbox } from "@mui/material";
import TaskListNewDialog from "./components/TaskListNewDialog";
import MDTypography from "components/MDTypography";
import TaskListItemNewDialog from "./components/TaskListItemNewDialog";

const initialTaskLists = [
  {
    id: "1",
    title: "TaskList 1",
    notes: "Notes for TaskList 1",
    items: [
      {
        id: "1",
        value: "TaskListItem 1",
        children: [
          { id: "4", value: "TaskListItem 4" },
          { id: "5", value: "TaskListItem 5" },
        ],
      },
    ],
  },
  {
    id: "2",
    title: "TaskList 2",
    notes: "Notes for TaskList 2",
    items: [
      {
        id: "2",
        value: "TaskListItem 2",
        children: [{ id: "6", value: "TaskListItem 6" }],
      },
      { id: "3", value: "TaskListItem 3", canHaveChildren: true },
    ],
  },
  {
    id: "3",
    title: "TaskList 3",
    notes: "Notes for TaskList 3",
    items: [],
  },
];

const TaskListNew = () => {
  const [taskLists, setTaskLists] = useState(initialTaskLists);
  const [selectedTaskList, setSelectedTaskList] = useState(null);
  const [taskListDialogOpen, setTaskListDialogOpen] = useState(false);
  const [taskListItemDialogOpen, setTaskListItemDialogOpen] = useState(false);
  const [currentTaskList, setCurrentTaskList] = useState(null);
  const [currentTaskListItem, setCurrentTaskListItem] = useState(null);
  const objUser = null;

  useEffect(() => {
    if (taskLists.length > 0) {
      setSelectedTaskList(taskLists[0]);
    }
  }, [taskLists]);

  const handleTaskListClick = (taskList) => {
    setSelectedTaskList(taskList);
  };

  /**
   * Handles reordering items within a single task list
   */
  const handleItemsChange = (newItems) => {
    setTaskLists((prevTaskLists) =>
      prevTaskLists.map((list) =>
        list.id === selectedTaskList.id ? { ...list, items: newItems } : list
      )
    );
  };

  const onTaskListDialogClose = () => {
    setCurrentTaskList(null);
    setTaskListDialogOpen(false);
  };

  const onTaskListDialogSubmit = (taskList) => {
    // Add your submit logic here
  };

  const onTaskListItemDialogClose = () => {
    setCurrentTaskListItem(null);
    setTaskListItemDialogOpen(false);
  };

  const onTaskListItemDialogSubmit = (taskList) => {
    // Add your submit logic here
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid container width="100%" paddingTop={1}>
        <Grid item xs={12} lg={12}>
          <MDBox
            display="flex"
            gap=".6rem"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mb={2}
          >
            <MDTypography variant="h4">Tasks List</MDTypography>
            <DashboardActionButton
              action={() => setTaskListDialogOpen(true)}
              btnText="Create New Task"
              btnIcon="add"
              textColor="white"
              bgColor="success"
            />
          </MDBox>
        </Grid>

        <Grid item xs={3}>
          <Card
            elevation={0}
            sx={{
              border: "none",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              boxShadow: "none",
            }}
          >
            <MDBox>
              {taskLists.length === 0 ? (
                <MDBox p={2} textAlign="center">
                  <strong>Please create a Task List</strong>
                </MDBox>
              ) : (
                taskLists.map((taskList) => (
                  <MDBox
                    key={taskList.id}
                    onClick={() => handleTaskListClick(taskList)}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      backgroundColor:
                        selectedTaskList?.id === taskList.id
                          ? "#fff"
                          : "#f0f0f0",
                    }}
                  >
                    {taskList.title}
                  </MDBox>
                ))
              )}
            </MDBox>
          </Card>
        </Grid>

        <Grid item xs={9}>
          <Card
            elevation={0}
            sx={{
              border: "none",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              boxShadow: "none",
            }}
          >
            {taskLists.length === 0 ? (
              <MDBox p={4} textAlign="center">
                <strong>Please create a Task List to get started</strong>
              </MDBox>
            ) : selectedTaskList ? (
              <MDBox marginLeft={2}>
                <p>{selectedTaskList.notes}</p>
                {selectedTaskList.items.length === 0 ? (
                  <MDBox p={2} textAlign="center">
                    <strong>Please add Task List Items</strong>
                  </MDBox>
                ) : (
                  <>
                    <SortableTree
                      items={selectedTaskList.items}
                      onItemsChanged={handleItemsChange}
                      TreeItemComponent={React.forwardRef((props, ref) => (
                        <SimpleTreeItemWrapper {...props} ref={ref}>
                          <Checkbox defaultChecked={false} />
                          <div>{props.item.value}</div>
                        </SimpleTreeItemWrapper>
                      ))}
                    />
                  </>
                )}
                <MDBox display="flex" justifyContent="end" m={2}>
                  <DashboardActionButton
                    action={() => setTaskListItemDialogOpen(true)}
                    btnText="Add New TaskList Item"
                    btnIcon="add"
                    textColor="white"
                    bgColor="success"
                  />
                </MDBox>
              </MDBox>
            ) : (
              <MDBox p={4} textAlign="center">
                <strong>Please select a Task List</strong>
              </MDBox>
            )}
          </Card>
        </Grid>
      </Grid>

      <TaskListNewDialog
        open={taskListDialogOpen}
        onClose={onTaskListDialogClose}
        onSubmit={onTaskListDialogSubmit}
        objUser={objUser}
        taskList={currentTaskList}
        taskLists={taskLists}
      />

      <TaskListItemNewDialog
        open={taskListItemDialogOpen}
        onClose={onTaskListItemDialogClose}
        onSubmit={onTaskListItemDialogSubmit}
        objUser={objUser}
        taskList={currentTaskListItem}
        taskLists={taskLists}
      />
    </DashboardLayout>
  );
};

export default TaskListNew;
