import Grid from "@mui/material/Grid";

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { fetchLog } from "features/company/auditActions";
import { displayExceptionErrorMessage } from "utils/general";

const ShowSubject = (props) => {
  const subject = props.value;
  const description =
    subject === null || typeof subject === "undefined"
      ? "Not applicable"
      : typeof subject.name !== "undefined"
      ? subject.name
      : typeof subject.title !== "undefined"
      ? subject.title
      : subject.id;
  return (
    <MDInput
      value={description}
      fullWidth
      variant="standard"
      label="Subject"
      disabled={true}
    />
  );
};

const ShowAuditLogs = () => {
  const { id } = useParams();
  const [isLoadingLog, setIsLoadingLog] = useState(false);
  const [logData, setLogData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoadingLog(true);
    dispatch(fetchLog({ id }))
      .then(unwrapResult)
      .then((result) => {
        setLogData(result.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      })
      .finally(() => setIsLoadingLog(false));
  }, []);
  
  let details = "";
  if (typeof logData.properties !== "undefined") {
    for(let key in logData.properties) {
      if (key === 'status') continue;
      details += `${key}: ${logData.properties[key]}\n`;
    }
  }

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid
        my={3}
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Grid item xs={12} lg={12}>
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox p={3}>
              <MDTypography variant="h5">Log details</MDTypography>
            </MDBox>
            {isLoadingLog ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            ) : (
              <Grid container spacing={3}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  p={5}
                >
                  <Grid item xs={12} md={12}>
                    <MDInput
                      value={logData.event}
                      variant="standard"
                      label="Event"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDInput
                      value={logData.description}
                      variant="standard"
                      fullWidth
                      label="Description"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      value={
                        logData.causer ? logData.causer.name : "Anonymous User"
                      }
                      fullWidth
                      variant="standard"
                      label="User"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ShowSubject value={logData.subject} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      value={
                        logData.properties?.status < 400 ? "Success" : "Failed"
                      }
                      fullWidth
                      variant="standard"
                      label="Result"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDInput
                      value={details}
                      variant="standard"
                      fullWidth
                      multiline
                      rows={6}
                      label="Details"
                      readOnly={true}
                    />
                  </Grid>
                </Grid>

                <MDBox
                  sx={{ margin: "0 0 40px -40px" }}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <MDButton
                    type="button"
                    onClick={() => navigate(-1)}
                    variant="gradient"
                    color="secondary"
                    sx={{ padding: "12px 82px" }}
                  >
                    Back
                  </MDButton>
                </MDBox>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ShowAuditLogs;
