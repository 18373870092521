import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import MUITooltip from "@mui/material/Tooltip";
import { fetchGamifycationStatsInsurance } from "features/company/companiesActions";
import { useTheme } from "@mui/material/styles";
import "./charts.css";
import GamePieChart from "./GamePieChart";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { DetailsPopup } from "./PolicyCharts";
import configs from "./configs/policy";
import { Link } from "react-router-dom";

Chart.register(ArcElement, Tooltip, Legend);

const chartLabels = [
  "Multi-factor authentication",
  "Security Awareness Training & Testing",
  "Backups",
  "Vulnerability",
  "Endpoint/Managed Detection & Response",
  "Uncompleted",
];
const bgColors = [
  "#0b2e4a",
  "#7a5195",
  "#ef5675",
  "#ffa600",
  "#6CB4EE",
  "#eeeeee",
];

const defaultChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      labels: {
        color: "white",
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          var dataset = context.dataset;
          var total = dataset.data.reduce(function (
            previousValue,
            currentValue
          ) {
            return previousValue + currentValue;
          });
          var currentValue = dataset.data[context.dataIndex];
          var percentage = ((currentValue / total) * 100).toFixed(2) + "%";
          return `${context.label}: ${percentage}`;
        },
      },
    },
  },
};

const notImportedData = {
  labels: ["No Data"],
  datasets: [
    {
      data: [100],
    },
  ],
};

const noFifthWallData = {
  labels: ["No Data"],
  datasets: [
    {
      data: [100],
    },
  ],
};

const defaultNotImportedOptions = {
  responsive: true,
  plugins: {
    legend: {
      labels: {
        color: "white",
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return "0";
        },
      },
    },
  },
};

export default function InsuranceCharts({ objUser }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [objGamifycationStats, setObjGamifycationStats] = useState([]);
  const [percent, setInsurancePercent] = useState(0);
  const [isMember, setIsMember] = useState(true);

  const chartOptions = useMemo(() => {
    const result = defaultChartOptions;
    result.plugins.legend.labels.color = theme.palette?.text?.main;
    return result;
  }, [theme.palette?.text?.main]);

  const notImportedOptions = useMemo(() => {
    const result = defaultNotImportedOptions;
    result.plugins.legend.labels.color = theme.palette?.text?.main;
    return result;
  }, [theme.palette?.text?.main]);

  const dispatch = useDispatch();
  const getStats = () => {
    dispatch(fetchGamifycationStatsInsurance({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        let data = originalPromiseResult.data;
        if (data.insuranceChecklist) {
          data.insuranceChecklist.uncompleted =
            360 -
            (data.insuranceChecklist.multiFactor +
              data.insuranceChecklist.securityAwareness +
              data.insuranceChecklist.backups +
              data.insuranceChecklist.vulnerability +
              data.insuranceChecklist.endpointDetection);
          let percentage =
            ((360 - data.insuranceChecklist.uncompleted) / 360) * 100;
          setInsurancePercent(percentage);
        }
        setIsMember(data.isFifthwallMember);
        setObjGamifycationStats(data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setObjGamifycationStats([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getStats();
  }, []);

  const charts = useMemo(() => {
    const pies = [];
    objGamifycationStats.rmfs?.forEach((objStat) => {
      const dataset = [
        (objStat.align[0] * 90) / objStat.align[1], // Alignment
        (objStat.authenticate[0] * 90) / objStat.authenticate[1], // Authorization
        (objStat.adopt[0] * 90) / objStat.adopt[1], // Adoption
        (objStat.assess[0] * 90) / objStat.assess[1], // Assessment
      ].map((value) => (isNaN(value) ? 0 : value));
      dataset.push(360 - dataset.reduce((acc, cur) => acc + cur, 0));
      const tooltip = [
        `Alignment: ${objStat.align[0]} policies out of ${objStat.align[1]} templates`,
        `Authorization: ${objStat.authenticate[0]} policies approved out of ${objStat.authenticate[1]}`,
        `Adoption: ${objStat.adopt[0]} policies adopted out of ${objStat.adopt[1]}`,
        `Assessment: ${objStat.assess[0]} policies with reminders out of ${objStat.assess[1]}`,
        `Uncompleted`,
      ];

      const { data, options } = configs(
        objStat.rmf,
        dataset,
        tooltip,
        theme.palette
      );
      if (objStat.rmf == "Cyber Insurance") {
        pies.push(
          <DetailsPopup
            key={objStat.rmf + "_popup"}
            rmf="Cyber Insurance RMF"
            details={objStat.details}
            detailsAlign={objStat.align[2] ?? {}}
            detailsAuthenticate={objStat.authenticate[2] ?? {}}
            detailsAdopt={objStat.adopt[2] ?? {}}
            detailsAssess={objStat.assess[2] ?? {}}
            objUser={objUser}
          >
            {({ onClick }) => (
              <GamePieChart
                key={objStat.rmf}
                data={data}
                options={options}
                title="Cyber Insurance RMF"
                onClickTitle={onClick}
              />
            )}
          </DetailsPopup>
        );
      }
    });
    return (
      <>
        {pies.length > 0 ? (
          pies
        ) : (
          <Grid item xs={12} sm={6} md={4} lg={3} pl={0} pt={0}>
            <MDTypography
              sx={{ marginBottom: "0.5rem" }}
              fontWeight="bold"
              variant="h5"
              color="dark"
              textAlign="center"
            >
              Cyber Insurance RMF{" "}
              <MUITooltip title="All policies belonging to Cyber Insurace RMF. If no data is displayed, import Cyber Insurance RMF template and create policy.">
                <IconButton>
                  <HelpOutlineIcon color="icon" fontSize="small" />
                </IconButton>
              </MUITooltip>
            </MDTypography>
            <Doughnut data={notImportedData} options={notImportedOptions} />
          </Grid>
        )}
      </>
    );
  }, [objGamifycationStats, theme.palette]);

  return (
    <Card mb={2}>
      <MDBox py={2} pr={2} pl={2}>
        <MDBox display="flex" px={0} pt={0}>
          <MDBox mt={0}>
            <MDTypography
              sx={{ marginBottom: "0.5rem" }}
              fontWeight="bold"
              variant="h5"
              color="dark"
            >
              Insurance Scorecard
            </MDTypography>
          </MDBox>
        </MDBox>

        {/* charts */}

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Grid container spacing={2} justifyContent="space-evenly">
            {/* Insurance Checklist */}
            {isMember && (
              <Grid item xs={12} sm={6} md={4} lg={3} pl={0} pt={0}>
                {percent == 0 ? (
                  <Link to={"/insurance"}>
                    <MDTypography
                      sx={{
                        marginBottom: "0.5rem",
                      }}
                      fontWeight="bold"
                      variant="h5"
                      textAlign="center"
                      color="dark"
                    >
                      Insurance Checklist{" "}
                      <MUITooltip title="If no data is displayed, visit Insurance Scorecard section and fill Fifthwall form">
                        <IconButton>
                          <HelpOutlineIcon color="icon" fontSize="small" />
                        </IconButton>
                      </MUITooltip>
                    </MDTypography>
                  </Link>
                ) : (
                  <MDTypography
                    sx={{
                      marginBottom: "0.5rem",
                    }}
                    fontWeight="bold"
                    variant="h5"
                    textAlign="center"
                    color="dark"
                  >
                    Insurance Checklist{" "}
                    <MUITooltip title="If no data is displayed, visit Insurance Scorecard section and fill Fifthwall form">
                      <IconButton>
                        <HelpOutlineIcon color="icon" fontSize="small" />
                      </IconButton>
                    </MUITooltip>
                  </MDTypography>
                )}

                {objGamifycationStats.insuranceChecklist ? (
                  <>
                    <Doughnut
                      data={{
                        labels: chartLabels,
                        datasets: [
                          {
                            data: [
                              objGamifycationStats.insuranceChecklist
                                .multiFactor,
                              objGamifycationStats.insuranceChecklist
                                .securityAwareness,
                              objGamifycationStats.insuranceChecklist.backups,
                              objGamifycationStats.insuranceChecklist
                                .vulnerability,
                              objGamifycationStats.insuranceChecklist
                                .endpointDetection,
                              objGamifycationStats.insuranceChecklist
                                .uncompleted,
                            ],
                            backgroundColor: bgColors,
                          },
                        ],
                      }}
                      options={chartOptions}
                    />
                    <MDTypography
                      sx={{ marginBottom: "0.5rem" }}
                      fontWeight="bold"
                      variant="h6"
                      textAlign="center"
                      color="dark"
                    >
                      {percent.toFixed(2)}%
                    </MDTypography>
                  </>
                ) : (
                  <Doughnut
                    data={noFifthWallData}
                    options={notImportedOptions}
                  />
                )}
              </Grid>
            )}

            {!isLoading && charts}
          </Grid>
        )}
      </MDBox>
    </Card>
  );
}
