import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

const PeerGroupScheduleDialog = (props) => {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
      <DialogTitle sx={{ pb: 0 }}>Peer Group Post Schedule</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Card
              position="relative"
              sx={{ padding: "10px", marginBottom: "1rem" }}
            >
              <iframe
                height="900"
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ-5r-VBeqMfMzWocfuY2XaEF4dzBZ18IHnSbFDfYPs5y5Fdu8qCnAIWezD245X1X6IhrQXiaolahd3/pubhtml?widget=true&amp;headers=false"
              ></iframe>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PeerGroupScheduleDialog;
