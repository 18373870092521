// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

import * as Yup from "yup";

import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";
import axios from "axios";
// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import EditCompanyUserForm from "./components/EditCompanyUserForm";

import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
import { authUpdateUser } from "features/user/userActions";
import { updateAuthUserData } from "features/user/userActions";
import { fetchCompanyUserById } from "features/company/companyUsersActions";
const EditCompanyUser = (props) => {
  const { objUser } = props;

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid my={3} container justifyContent="center" alignItems="center" width="100%">
        <Grid item xs={12} lg={12}>
          <EditCompanyUserForm objUser={props.objUser} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default EditCompanyUser;
