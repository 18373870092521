import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useConfirm } from "material-ui-confirm";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

const UsersManagementActionBtnTr = (props) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const {
    value,
    column,
    row,
    objSelectedUser,
    changeUserStatus,
    isDeletingUser,
    objUser,
  } = props;
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const btnOnClickChangeUserStatus = (data, status) => {
    let changedStatus = "deactivate";
    if (status === "Active") {
      changedStatus = "activate";
    }
    confirm({
      description: "Are you sure you want to " + changedStatus + " this user?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        changeUserStatus(data, status);
      })
      .catch(() => {
        /* ... */
      });
  };

  const handleClickEditUser = (objUserRow) => {
    routeDictionary[objUserRow.id] = objUserRow.user;
    navigate(`/msp-users/edit-user/${objUserRow.id}`, {
      state: { routeDictionary },
    });
  };
  return (
    <MDBox display="flex" gap="3rem" alignItems="center">
      {isDeletingUser &&
      Object.keys(objSelectedUser).length > 0 &&
      row.original.element.id === objSelectedUser.element.id ? (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      ) : (
        <MDButton
          onClick={() =>
            btnOnClickChangeUserStatus(
              row.original,
              `${row.original.status == "Active" ? "Inactive" : "Active"}`
            )
          }
          disabled={row.original.status === "Invited"}
          sx={{
            display: `${objUser.user.role.name != "admin" ? "none" : ""}`,
          }}
        >
          <MDBox color="error" display="flex" alignItems="center">
            {row.original.status == "Active" ? (
              <NoAccountsIcon />
            ) : (
              <AccountCircleIcon />
            )}
            <MDTypography
              variant="caption"
              fontWeight="medium"
              color="error"
              sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
            >
              {row.original.status == "Active" ? "Deactivate" : "Activate"}
            </MDTypography>
          </MDBox>
        </MDButton>
      )}
      <MDButton
        onClick={() => handleClickEditUser(row.original)}
        disabled={row.original.status === "Invited"}
      >
        <MDBox display="flex" alignItems="center">
          <Icon fontSize="small">create</Icon>
          <MDTypography
            variant="caption"
            fontWeight="medium"
            color="text.dark"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            Edit
          </MDTypography>
        </MDBox>
      </MDButton>
    </MDBox>
  );
};

export default UsersManagementActionBtnTr;
