// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";

const AcceptUserCompanyInviteFormFormikWrapper = (props) => {
  const { values, errors, touched } = props;
  return (
    <Fragment>
      <MDBox mb={2}>
        <FormField
          type="text"
          label="First Name"
          name="name"
          value={values.name}
          placeholder={"First Name"}
          error={errors.name && touched.name}
          success={values.name.length > 0 && !errors.name}
        />
      </MDBox>
      <MDBox mb={2}>
        <FormField
          type="text"
          label="Last Name"
          name="last_name"
          value={values.last_name}
          placeholder={"Last Name"}
          error={errors.last_name && touched.last_name}
          success={values.last_name.length > 0 && !errors._last_name}
        />
      </MDBox>
      <MDBox mb={2}>
        <FormField
          type="password"
          label="Password"
          name="password"
          value={values.password}
          placeholder={"Password"}
          error={errors.password && touched.password}
          success={values.password.length > 0 && !errors.password}
        />
      </MDBox>
      <MDBox mb={2}>
        <FormField
          type="password"
          label="Confirm Password"
          name="password_confirmation"
          value={values.password_confirmation}
          placeholder={"Confirm Password"}
          error={errors.password_confirmation && touched.password_confirmation}
          success={
            values.password_confirmation.length > 0 &&
            !errors.password_confirmation
          }
        />
      </MDBox>
    </Fragment>
  );
};

export default AcceptUserCompanyInviteFormFormikWrapper;
