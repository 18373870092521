/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
// import { POLICIES_SERVER } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

// const createPolicyURL = (objUser, client_id) => {
//   return POLICIES_SERVER.replace("{companyId}", objUser.user.company_id).replace(
//     "{clientId}",
//     client_id
//   );
// };

export const adoptPolicy = createAsyncThunk(
  "policy/adopt",
  async (values, thunkApi) => {
    try {
      const {
        // objUser,
        companyId,
        clientId,
        contactId,
        campaignId,
        adoptionSecret,
      } = values;
      // const this_policies_server = createPolicyURL(objUser, clientId);
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${companyId}/clients/${clientId}/campaigns/${campaignId}/adopt`,
        { contact_id: contactId, secret: adoptionSecret }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPolicyDocumentWithContactIdAndAdoptionSecrete =
  createAsyncThunk("policy-document/get", async (values, thunkApi) => {
    try {
      const {
        // objUser,
        companyId,
        clientId,
        contactId,
        campaignId,
        adoptionSecret,
      } = values;
      // const this_policies_server = createPolicyURL(objUser, clientId);
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${companyId}/clients/${clientId}/campaigns/${campaignId}/policy-document?contact_id=${contactId}&secret=${adoptionSecret}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  });

export const getPolicyDocumentWithContactIdAndGroupedCampaignId =
  createAsyncThunk(
    "policy-adoption-campaigns/get",
    async (values, thunkApi) => {
      try {
        const { companyId, clientId, contactId, groupedCampaignId } = values;
        // const this_policies_server = createPolicyURL(objUser, clientId);
        const res = await axios.get(
          `${BACKEND_API_URL}${VERSION_SERVER}/companies/${companyId}/clients/${clientId}/grouped-campaigns/${groupedCampaignId}?contact_id=${contactId}`
        );
        return res;
      } catch (error) {
        return thunkApi.rejectWithValue(error);
      }
    }
  );
