import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  createNewEvent,
  getAssessmentTemplates,
  getAssessmentEventVersionsCount,
} from "features/company/assessmentsActions.js";
import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage } from "formik";
const objInitialValues = { txtTemplateTitle: "", cloneInfo: 1 };
const AddNewTemplateFormJWTSchema = Yup.object().shape({
  drpTemplate: Yup.object()
    .shape({
      id: Yup.string().required(),
      label: Yup.string().required(),
    })
    .required("The template is required."),
});

function CreateNewEventDialog({ open, onClose, onSubmit, objUser }) {
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isSubmittingEvent, setIsSubmittingEvent] = useState(false);
  const [cloneDescription, setCloneDescription] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [isAskingToClone, setIsAskingToClone] = useState(false);

  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const objPostData = {
      templateId: values.drpTemplate.id,
    };
    setIsSubmittingEvent(true);

    /**
     * Check if there are any versions for this template
     * If there are, show popup dialog,
     * If there arent, just create the event
     */

    dispatch(getAssessmentEventVersionsCount({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;

        if (response.data.currentNumberOfAssessmentVersions > 0) {
          setIsSubmittingEvent(false);
          setIsAskingToClone(true);
          setCloneDescription(
            "A previous event for " +
              values.drpTemplate.label +
              " already exists. This will create version " +
              (response.data.currentNumberOfAssessmentVersions + 1) +
              ".0 of this event."
          );
          setSelectedTemplate(values.drpTemplate.id);
        } else {
          createEvent(objPostData, objUser);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized to create new event");
          setIsSubmittingEvent(false);
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
          setIsSubmittingEvent(false);
        }
      });
  };

  const clone = async (values) => {
    const objPostData = {
      templateId: selectedTemplate,
      cloneRecentEventInformation: values.cloneInfo,
    };
    createEvent(objPostData, objUser);
  };

  const onCloseCloneDialog = () => {
    setIsAskingToClone(false);
  };

  const createEvent = async (objPostData, objUser) => {
    setIsSubmittingEvent(true);
    dispatch(createNewEvent({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit({
            id: response.data.id,
            name: response.data.name,
            assessmentTemplateId: response.data.assessment_template_id,
          });
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized to create new event");
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response?.data?.message
          );
          showMsg(
            "error",
            rejectedValueOrSerializedError.response?.data?.error_code
          );
        }
      })
      .finally(() => {
        setIsSubmittingEvent(false);
      });
  };

  const loadTemplates = () => {
    setIsLoadingTemplates(true);
    dispatch(getAssessmentTemplates({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        const objTemplates = [];
        response.data.templates.forEach((objTemplate) => {
          if (objTemplate.is_global_template) {
            return;
          }
          const objTr = {};
          objTr.id = objTemplate.id;
          objTr.label = objTemplate.name;
          objTemplates.push(objTr);
        });
        setTemplates(objTemplates);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      })
      .finally(() => {
        setIsLoadingTemplates(false);
      });
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  return (
    <>
      {!isAskingToClone ? (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
          <DialogTitle sx={{ pb: 0 }}>Add New Document</DialogTitle>
          {isLoadingTemplates === true ? (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          ) : (
            <Formik
              initialValues={objInitialValues}
              validationSchema={AddNewTemplateFormJWTSchema}
              onSubmit={onSubmitForm}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id="AddNewTemplateForm" autoComplete="off">
                  <DialogContent>
                    <MDBox mb={0} mt={0}>
                      <Autocomplete
                        name="drpTemplate"
                        disableClearable
                        value={values.drpTemplate}
                        options={templates}
                        getOptionLabel={(option) =>
                          option ? option.label : ""
                        }
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label="Template"
                            fullWidth
                          />
                        )}
                        onChange={(event, value) => {
                          if (value === null) {
                            setFieldValue("drpTemplate", "");
                          } else {
                            setFieldValue("drpTemplate", value);
                          }
                        }}
                      />
                      <MDBox mt={0.75}>
                        <MDTypography
                          component="div"
                          variant="caption"
                          color="error"
                          fontWeight="regular"
                        >
                          <ErrorMessage name="drpTemplate" />
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </DialogContent>
                  <DialogActions>
                    {isSubmittingEvent === false ? (
                      <>
                        <MDButton
                          type="submit"
                          // variant="gradient"
                          color="success"
                          sx={{ padding: "12px 12px" }}
                        >
                          Start
                        </MDButton>
                        <MDButton
                          type="button"
                          variant="outlined"
                          color="dark"
                          sx={{ padding: "12px 12px" }}
                          onClick={onClose}
                        >
                          Cancel
                        </MDButton>
                      </>
                    ) : (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="lg"
                      />
                    )}
                  </DialogActions>
                </Form>
              )}
            </Formik>
          )}
        </Dialog>
      ) : (
        <Dialog open={open} onClose={onCloseCloneDialog} fullWidth={true}>
          <DialogTitle sx={{ pb: 0 }}>Warning</DialogTitle>
          <Formik initialValues={objInitialValues} onSubmit={clone}>
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id="CloneEventForm" autoComplete="off">
                <DialogContent>
                  <MDTypography>{cloneDescription}</MDTypography>
                  <MDBox mb={0} mt={0} ml={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.cloneInfo === 1}
                          name="cloneInfo"
                        />
                      }
                      label="Clone Most Recent Event Information"
                      onChange={() => {
                        if (values.cloneInfo === 1) {
                          setFieldValue("cloneInfo", 0);
                        } else {
                          setFieldValue("cloneInfo", 1);
                        }
                      }}
                    />
                  </MDBox>
                </DialogContent>
                <DialogActions>
                  {isSubmittingEvent === false ? (
                    <>
                      <MDButton
                        type="submit"
                        color="success"
                        sx={{ padding: "12px 12px" }}
                      >
                        Ok
                      </MDButton>
                      <MDButton
                        type="button"
                        variant="outlined"
                        color="dark"
                        sx={{ padding: "12px 12px" }}
                        onClick={onCloseCloneDialog}
                      >
                        Cancel
                      </MDButton>
                    </>
                  ) : (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  )}
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  );
}
export default CreateNewEventDialog;
