import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { getTaskListDetails } from "features/company/taskListActions";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Card, Grid } from "@mui/material";
import DashboardActionButton from "views/components/DashboardActionButton";
import dataTableData from "../TaskListItems/components/TaskListItemTable/DataTableData";
import {
  getTaskListItems,
  deleteTaskListItem,
} from "features/company/taskListActions";
import { displayExceptionErrorMessage } from "utils/general";
import TaskListItemTable from "../TaskListItems/components/TaskListItemTable/TaskListItemTable";
import TaskListItemDialog from "../TaskListItems/components/TaskListItemDialog";
import { companyUsersList } from "features/company/companiesActions";

function TaskListDetails(props) {
  const { objUser } = props;
  const { task_list_id } = useParams();
  const dispatch = useDispatch();
  const [taskList, setTaskList] = useState(null);
  const [isFetchingTaskList, setIsFetchingTaskList] = useState(false);
  const [isFetchingTaskListItems, setIsFetchingTaskListItems] = useState(true);
  const [taskListItemDialogOpen, setTaskListItemDialogOpen] = useState(false);
  const [id, setId] = useState(null);
  const [currentTaskListItem, setCurrentTaskListItem] = useState(null);
  const [taskListItemTableData, setTaskListItemTableData] =
    useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [taskListItemsUsers, setTaskListItemsUsers] = useState([]);

  useEffect(() => {
    setIsFetchingTaskList(true);
    dispatch(getTaskListDetails({ objUser, task_list_id }))
      .then(unwrapResult)
      .then((response) => {
        setTaskList(response.data);
        setIsFetchingTaskList(false);
      });
  }, [task_list_id, dispatch]);

  useEffect(() => {
    getListItems();
  }, [task_list_id]);

  const getListItems = () => {
    setIsFetchingTaskListItems(true);
    dispatch(getTaskListItems({ task_list_id, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        if (originalPromiseResult.status !== 200) {
          throw new Error("Get task list items request failed.");
        }
        const response = originalPromiseResult;
        if (response.data.task_list_items) {
          setTaskListItemTableData({
            rows: response.data.task_list_items,
          });
          setTotalCount(response.data.total);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        showMsg("error", "Something went wrong, please try again.");
      })
      .finally(() => {
        setIsFetchingTaskListItems(false);
      });
  };

  useEffect(() => {
    setIsFetchingUsers(true);
    const getUsers = async () => {
      try {
        const response = await dispatch(
          companyUsersList({
            objUser,
            _per_page: 1000,
            _page: 1,
          })
        );
        if (response.payload.status === 200) {
          setTaskListItemsUsers(response.payload.data.users);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      } catch (error) {
        showMsg("error", "Something went wrong, please try again");
      } finally {
        setIsFetchingUsers(false);
      }
    };
    getUsers();
  }, [dispatch, objUser]);

  const createNewTaskListItem = () => {
    setTaskListItemDialogOpen(true);
  };

  const onTaskListItemDialogClose = () => {
    setCurrentTaskListItem(null);
    setTaskListItemDialogOpen(false);
  };

  const onTaskListItemDialogSubmit = () => {
    setTaskListItemDialogOpen(false);
    setCurrentTaskListItem(null);
    getListItems();
  };

  const deleteObj = async (dataFromActionBtn) => {
    setIsFetchingTaskListItems(true);
    dispatch(deleteTaskListItem({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingTaskListItems(false);
        const response = originalPromiseResult;
        if (response.status === 200 || response.status === 201) {
          showMsg("success", "Campaign deleted");
        }
        getListItems();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingTaskListItems(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  if (isFetchingTaskList || taskList === null) {
    return <LoadingSpinner />;
  }

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        mb={3}
      >
        {/* Left Column: Title and Notes */}
        <MDBox>
          <MDTypography variant="h4" sx={{ fontWeight: "bold" }}>
            View TaskList {taskList ? `(${taskList.title})` : ""}
          </MDTypography>
          <MDTypography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
            Notes: {taskList.notes}
          </MDTypography>
        </MDBox>

        {/* Right Column: Due Date */}
        <MDBox>
          <MDTypography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "right", mt: 0.5 }}
          >
            Due Date: {taskList.due_date}
          </MDTypography>
        </MDBox>
      </MDBox>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ padding: 2, boxShadow: 3 }}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            ></MDBox>
            {isFetchingTaskListItems ? (
              <MDBox mt={2}>
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              </MDBox>
            ) : (
              <>
                {totalCount === 0 ? (
                  <MDBox sx={{ textAlign: "center", py: 4 }}>
                    <MDTypography variant="p" fontWeight="regular">
                      You haven't created any task items yet
                    </MDTypography>
                  </MDBox>
                ) : (
                  // Move the TaskListItemTable inside the Grid
                  <Grid item xs={12}>
                    <TaskListItemTable
                      table={taskListItemTableData}
                      entriesPerPage={true}
                      canSearch
                      objUser={objUser}
                      totalCount={totalCount}
                      taskListId={task_list_id}
                      perPage={perPage}
                      _page={page}
                      setPage={setPage}
                      deleteObj={deleteObj}
                      setCurrentTaskListItem={setCurrentTaskListItem}
                      setTaskListItemDialogOpen={setTaskListItemDialogOpen}
                      taskListItems={taskListItemTableData.rows}
                      taskListItemsUsers={taskListItemsUsers}
                      isFetchingUsers={isFetchingUsers}
                    />
                  </Grid>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
      <TaskListItemDialog
        open={taskListItemDialogOpen}
        onClose={onTaskListItemDialogClose}
        onSubmit={onTaskListItemDialogSubmit}
        objUser={objUser}
        taskListId={task_list_id}
        taskListItems={taskListItemTableData.rows}
        taskListItem={currentTaskListItem}
        taskListItemsUsers={taskListItemsUsers}
        isFetchingUsers={isFetchingUsers}
      />
    </DashboardLayout>
  );
}

export default TaskListDetails;
