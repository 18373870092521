/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import { AuthProvider } from "context/useAuth";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import store from "./features/storeConfig/store";

if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://23f23067a1aa454f8aa4ea7fe5c2a265@o4504555555782656.ingest.sentry.io/4504555934318592",
    integrations: [new BrowserTracing()],
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Auth0Provider
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
    cacheLocation="localstorage"
    useRefreshTokens={true}
    scope="idToken"
    responseType="token id_token"
  >
    <BrowserRouter>
      <AuthProvider>
        <MaterialUIControllerProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </MaterialUIControllerProvider>
      </AuthProvider>
    </BrowserRouter>
  </Auth0Provider>
);
