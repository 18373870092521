import DefaultCell from "views/components/TableCells/DefaultCell";
import NameBtnTr from "./NameBtnTr";
const DataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value, column, row }) => (
        <NameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Policy Packs",
      accessor: "current_rmfs",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default DataTableData;
