import { useState, useMemo, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { useMaterialUIController } from "context";
import "./newPost.css";
import PostDefaultLogo from "assets/images/icons/logo.png";

import {
  createPeerGroupPost,
  updatePeerGroupPost,
} from "features/company/peerGroupActions.js";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";

const objInitialValues = {
  txtPostTitle: "",
  txtDescription: "",
  txtStatus: "Draft",
  txtContentType: "Paid",
  txtVideoUrl: "",
};

const statusOptions = ["Draft", "Published", "Archived"];
const contentTypeOptions = ["Paid", "Free"];

const AddNewPostFormJWTSchema = Yup.object().shape({
  txtPostTitle: Yup.string().required("The Title is required."),
  txtDescription: Yup.string().required("The Description is required."),
  txtStatus: Yup.string().required("The Status is required."),
  txtContentType: Yup.string().required("The Content Type is required."),
  txtVideoUrl: Yup.string().trim().required("This field is required."),
});

function CreateNewPostDialog(props) {
  const { open, onClose, post, fetchPosts } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedCoverImage, setCoverImage] = useState();
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dispatch = useDispatch();
  const initialValues = useMemo(() => {
    if (post !== null) {
      return {
        id: post.id,
        txtPostTitle: post.title,
        txtDescription: post.description,
        txtStatus: post.status.charAt(0).toUpperCase() + post.status.slice(1),
        txtContentType: post.content_type ? post.content_type.charAt(0).toUpperCase() + post.content_type.slice(1) : "",
        txtVideoUrl: post.simple_video_url ?? post.video_url ?? "",
        videoProvider: post.video_provider,
      };
    }
    return objInitialValues;
  }, [post]);

  const isUpdate = useMemo(() => {
    return post !== null && post.id;
  }, [post]);

  useEffect(() => {
    setSelectedFiles([]);
    setFilesToDelete([]);
    setCoverImage(null);
  }, [post]);

  const removeFile = (fileId) => {
    setFilesToDelete([...filesToDelete, fileId]);
  };

  const imagePreview = useMemo(() => {
    if (!selectedCoverImage) {
      return;
    }
    const objectUrl = URL.createObjectURL(selectedCoverImage);
    // setImagePreview(objectUrl);
    return objectUrl;
  }, [selectedCoverImage]);

  const onSubmitForm = (values) => {
    setIsSaving(true);
    const objPostData = {
      title: values.txtPostTitle,
      description: values.txtDescription,
      status: values.txtStatus?.toLowerCase(),
      content_type: values.txtContentType?.toLowerCase(),
      video_url: values.txtVideoUrl,
      cover_image: selectedCoverImage,
      "files[]": selectedFiles,
      "files_to_delete[]": filesToDelete,
    };

    let func = createPeerGroupPost;
    if (isUpdate) {
      objPostData.id = post.id;
      func = updatePeerGroupPost;
    }

    dispatch(func({ objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Post created successfully.");
          fetchPosts();
          onClose();
        } else if (response.status === 200) {
          showMsg("success", "Post updated successfully.");
          fetchPosts();
          onClose();
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (rejectedValueOrSerializedError?.response?.data?.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else if (rejectedValueOrSerializedError?.response?.statusText) {
          showMsg("error", rejectedValueOrSerializedError.response.statusText);
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
        console.log(rejectedValueOrSerializedError);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>
        {isUpdate ? "Edit Post" : "Add New Post"}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={AddNewPostFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddNewPostForm" autoComplete="off">
            <DialogContent>
              {isUpdate && (
                <MDBox mb={1}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    fontWeight="regular"
                  >
                    Change Cover Image
                  </MDTypography>
                  <label htmlFor="profile">
                    <img
                      src={
                        imagePreview ||
                        post.cover_image?.file_path ||
                        PostDefaultLogo
                      }
                      alt="Cover"
                    />
                    <MDInput
                      accept="image/png, image/jpeg, image/gif"
                      id="profile"
                      type="file"
                      sx={{ display: "none" }}
                      onChange={(event) => {
                        setCoverImage(event.target.files[0]);
                      }}
                    />
                  </label>
                </MDBox>
              )}

              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Title"
                  name="txtPostTitle"
                  value={values.txtPostTitle}
                  placeholder={"Post title"}
                  error={errors.txtPostTitle && touched.txtPostTitle}
                  success={
                    values.txtPostTitle.length > 0 && !errors.txtPostTitle
                  }
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtPostTitle" />
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Description"
                  name="txtDescription"
                  value={values.txtDescription}
                  placeholder={"Post description"}
                  error={errors.txtDescription && touched.txtDescription}
                  success={
                    values.txtDescription.length > 0 && !errors.txtDescription
                  }
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtDescription" />
                  </MDTypography>
                </MDBox>
              </MDBox>
              {!isUpdate && (
                <MDBox mb={1} mt={1}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    fontWeight="regular"
                  >
                    Upload Cover Image
                  </MDTypography>
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    onChange={(event) => {
                      setCoverImage(event.target.files[0]);
                    }}
                  />
                </MDBox>
              )}
              <MDBox mb={0} mt={0}>
                <Autocomplete
                  disableClearable
                  id="txtStatus"
                  name="txtStatus"
                  value={values.txtStatus}
                  onChange={(event, newValue) => {
                    setFieldValue("txtStatus", newValue);
                  }}
                  options={statusOptions}
                  fullWidth
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      label="Status"
                      placeholder="Status"
                      variant="standard"
                      error={errors.txtStatus && touched.txtStatus}
                      success={values.txtStatus.length > 0 && !errors.txtStatus}
                    />
                  )}
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtStatus" />
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mb={0} mt={0}>
                <Autocomplete
                  disableClearable
                  id="txtContentType"
                  name="txtContentType"
                  value={values.txtContentType}
                  onChange={(event, newValue) => {
                    setFieldValue("txtContentType", newValue);
                  }}
                  options={contentTypeOptions}
                  fullWidth
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      label="Content Type"
                      placeholder="Content Type"
                      variant="standard"
                      error={errors.txtContentType && touched.txtContentType}
                      success={
                        values.txtContentType.length > 0 &&
                        !errors.txtContentType
                      }
                    />
                  )}
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtContentType" />
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label={
                    values.videoProvider === "custom"
                      ? "Video Embed Code"
                      : "Video URL"
                  }
                  name="txtVideoUrl"
                  value={values.txtVideoUrl}
                  placeholder={
                    values.videoProvider === "custom"
                      ? "Video Embed Code"
                      : "Video URL"
                  }
                  error={errors.txtVideoUrl && touched.txtVideoUrl}
                  success={
                    values.txtVideoUrl?.length > 0 && !errors.txtVideoUrl
                  }
                  as={MDInput}
                  multiline={values.videoProvider === "custom"}
                  rows={values.videoProvider === "custom" ? 3 : 1}
                  className={
                    darkMode
                      ? "dark-theme-video-url-input"
                      : "light-theme-video-url-input"
                  }
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtVideoUrl" />
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mb={0} mt={0}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.videoProvider === "custom"}
                      name="embededCode"
                    />
                  }
                  label="Embeded Code"
                  onChange={() => {
                    if (values.videoProvider === "custom") {
                      setFieldValue("videoProvider", "vimeo");
                    } else {
                      setFieldValue("videoProvider", "custom");
                    }
                    setFieldValue("txtVideoUrl", "");
                  }}
                />
              </MDBox>
              <MDBox mb={1} mt={1}>
                <MDTypography
                  component="div"
                  variant="caption"
                  fontWeight="regular"
                >
                  Upload files
                </MDTypography>
                <input
                  type="file"
                  multiple
                  onChange={(event) => {
                    setSelectedFiles(event.target.files);
                  }}
                />
              </MDBox>
              {post?.files?.length > 0 && (
                <MDBox mb={0} mt={0}>
                  {Array.from(post.files).map((file) => (
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      mb={1}
                      key={file.id}
                    >
                      <Tooltip title="Remove this file." placement="bottom">
                        <MDButton
                          variant="gradient"
                          color="error"
                          iconOnly={true}
                          size="small"
                          disabled={filesToDelete.includes(file.id)}
                          onClick={() => removeFile(file.id)}
                        >
                          <Icon>delete</Icon>
                        </MDButton>
                      </Tooltip>
                      <MDTypography
                        component="div"
                        variant="p"
                        fontWeight="regular"
                        color={
                          filesToDelete.includes(file.id) ? "error" : "text"
                        }
                        sx={{
                          textDecoration: filesToDelete.includes(file.id)
                            ? "line-through"
                            : "none",
                          marginLeft: "10px",
                        }}
                      >
                        {file.file_name}
                      </MDTypography>
                    </MDBox>
                  ))}
                </MDBox>
              )}
            </DialogContent>
            <DialogActions>
              {!isSaving ? (
                <>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    {!isUpdate ? "Add" : "Update"}
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

CreateNewPostDialog.defaultProps = {
  open: false,
  onClose: () => {},
  post: null,
};

export default CreateNewPostDialog;
