import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { unwrapResult } from "@reduxjs/toolkit";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { authUpdateUser } from "features/user/userActions";
import { Form, Formik } from "formik";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import * as Yup from "yup";
import BasicInfoFormFormikWrapper from "./BasicInfoFormFormikWrapper";
const _objInitialValues = {
  txtName: "",
  txtLastName: "",
  txtEmail: "",
  txtPhoneNumber: "",
  txtRole: "",
};
const BasicInfoFormJWTSchema = Yup.object().shape({
  txtName: Yup.string().required("Name is required."),
  txtLastName: Yup.string().required("Last name is required."),
  txtEmail: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email is required."),
  txtPhoneNumber: Yup.string()
    .min(10, "Must be at least 10 digits")
    .required("Phone number is required"),
  txtRole: Yup.string().required("Role is required."),
});
const BasicInfoForm = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [objInitialValues, setObjInitialValues] = useState(_objInitialValues);
  const [objStates, setObjStates] = useState(objUsStates);
  const [isLoading, setIsLoading] = useState(false);
  const [objBasicInfoFormErrors, setObjBasicInfoFormErrors] = useState({});

  const formatPhone = (inputValue) => {
    let previousValue = inputValue;

    if (inputValue) {
      const numericValue = inputValue.replace(/[^\d]/g, ""); // Remove non-numeric characters

      if (numericValue.length === 10) {
        return `${numericValue.slice(0, 3)}-${numericValue.slice(
          3,
          6
        )}-${numericValue.slice(6)}`;
      } else if (numericValue.length === 11) {
        return `(${numericValue[0]}) ${numericValue.slice(
          1,
          4
        )}-${numericValue.slice(4, 7)}-${numericValue.slice(7)}`;
      } else if (numericValue.length > 11) {
        return previousValue;
      } else {
        return numericValue;
      }
    } else {
      return inputValue;
    }
  };

  useEffect(() => {
    const __ObjInitialValues = {
      txtName: objUser.user.name ?? "",
      txtLastName: objUser.user.last_name ?? "",
      txtEmail: objUser.user.email,
      txtPhoneNumber: formatPhone(objUser.user.phone) ?? "",
      txtRole: objUser.user.role.display_name,
    };
    setObjInitialValues(__ObjInitialValues);
  }, []);

  const onSubmitForm = async (values) => {
    const objPostData = {
      name: values.txtName,
      last_name: values.txtLastName,
      phone: values.txtPhoneNumber,
      id: objUser.user.id,
    };
    setObjBasicInfoFormErrors({});
    setIsLoading(true);
    dispatch(authUpdateUser({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status == 200) {
          showMsg("success", "Your profile has been updated successfully.");
          if (props.redirectUrl) {
            navigate(props.redirectUrl);
          }
        } else {
          showMsg(
            "error",
            "Your profile could not be updated. Please correct errors and try again."
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Please correct the errors.");
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        setObjBasicInfoFormErrors(objErrors);
      });
  };

  if (
    Object.keys(objInitialValues).length === 0 ||
    objInitialValues.txtName === ""
  ) {
    return <LoadingSpinner subClass="text-center" color="success" size="lg" />;
  }
  return (
    <Formik
      initialValues={objInitialValues}
      validationSchema={BasicInfoFormJWTSchema}
      onSubmit={onSubmitForm}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDTypography variant="h5">
              {props.title ? props.title : "Basic Info"}
            </MDTypography>
          </MDBox>
          <Form id={"BasicInfoForm"} autoComplete="off" pb={3} px={3}>
            <Grid container spacing={3}>
              <BasicInfoFormFormikWrapper
                values={values}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
                objStates={objStates}
              />
              <MDBox
                sx={{ margin: "0 0 40px -40px" }}
                width="100%"
                display="flex"
                justifyContent="flex-end"
              >
                {isLoading === false ? (
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 82px" }}
                  >
                    Save
                  </MDButton>
                ) : (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                )}
              </MDBox>
            </Grid>
          </Form>
        </Card>
      )}
    </Formik>
  );
};

export default BasicInfoForm;
