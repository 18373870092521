import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { inviteAuditor } from "features/auditorReports/auditorReportActions";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import * as Yup from "yup";

import { Formik, Form, ErrorMessage, Field } from "formik";
import { TextField } from "@mui/material";
const objInitialValues = {
  txtEmail: "",
  txtFirstName: "",
  txtLastName: "",
};
const AddNewTemplateFormJWTSchema = Yup.object().shape({
  txtEmail: Yup.string().required("The Email is required."),
  txtFirstName: Yup.string().required("The First name is required."),
  txtLastName: Yup.string().required("The Last name is required."),
});

function InviteAuditorDialog({
  open,
  onClose,
  onSubmit,
  objUser,
  uninvitedAuditors,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [txtExpiration, setTxtExpiration] = useState(null);

  const onSubmitInviteAuditorForm = async (values) => {
    const objPostData = {
      email: values.txtEmail,
      firstName: values.txtFirstName,
      lastName: values.txtLastName,
      userExpiration: txtExpiration,
      invitedFromExistingAuditors: false,
    };
    runAuditorInvite(objPostData);
  };

  const inviteComplianceAuditors = (auditor) => {
    const objPostData = {
      email: auditor.email,
      firstName: auditor.first_name,
      lastName: auditor.last_name,
      userExpiration: txtExpiration,
      invitedFromExistingAuditors: true,
    };
    runAuditorInvite(objPostData);
  };

  const runAuditorInvite = (objPostData) => {
    setIsLoading(true);
    dispatch(inviteAuditor({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        showMsg(
          "success",
          "Invite has been sent successfully to the auditor through the email you provided"
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error inviting a new auditor");
        }
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Invite Your Auditor</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewTemplateFormJWTSchema}
        onSubmit={onSubmitInviteAuditorForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddNewTemplateForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={2} mt={2}>
                <DateTimePicker
                  label="Choose the user expiration date for auditor once invited (if applicable)"
                  disablePast={true}
                  value={txtExpiration}
                  onChange={(value) => {
                    setTxtExpiration(value);
                  }}
                  name="txtExpiration"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </MDBox>
              {/* form for sending request */}
              <MDBox sx={{ display: step === 1 ? "block" : "none" }}>
                <MDBox mb={0} mt={0}>
                  <Field
                    type="text"
                    label="Email"
                    name="txtEmail"
                    value={values.txtEmail}
                    placeholder={"Email"}
                    error={errors.txtEmail && touched.txtEmail}
                    success={values.txtEmail.length > 0 && !errors.txtEmail}
                    as={MDInput}
                    variant="standard"
                    fullWidth
                    autoFocus
                  />
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      <ErrorMessage name="txtEmail" />
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox mb={0} mt={0}>
                  <Field
                    type="text"
                    label="First Name"
                    name="txtFirstName"
                    value={values.txtFirstName}
                    placeholder={"First name"}
                    error={errors.txtFirstName && touched.txtFirstName}
                    success={
                      values.txtFirstName.length > 0 && !errors.txtFirstName
                    }
                    as={MDInput}
                    variant="standard"
                    fullWidth
                    autoFocus
                  />
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      <ErrorMessage name="txtFirstName" />
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox mb={0} mt={0}>
                  <Field
                    type="text"
                    label="Last Name"
                    name="txtLastName"
                    value={values.txtLastName}
                    placeholder={"Last name"}
                    error={errors.txtLastName && touched.txtLastName}
                    success={
                      values.txtLastName.length > 0 && !errors.txtLastName
                    }
                    as={MDInput}
                    variant="standard"
                    fullWidth
                    autoFocus
                  />
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      <ErrorMessage name="txtLastName" />
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>

              {/* inviting existing */}
              <MDBox sx={{ display: step === 2 ? "block" : "none" }}>
                {uninvitedAuditors.map((auditor) => {
                  return (
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                        backgroundColor: "#eee",
                        padding: "0.5rem",
                        borderRadius: "10px",
                      }}
                    >
                      <div>
                        <b>
                          {" "}
                          {auditor.first_name} {auditor.last_name}
                        </b>
                      </div>
                      <MDButton
                        color="success"
                        onClick={() => inviteComplianceAuditors(auditor)}
                        sx={{ padding: "12px 12px", marginRight: "10px" }}
                      >
                        Invite
                      </MDButton>
                    </MDBox>
                  );
                })}
              </MDBox>
            </DialogContent>
            <DialogActions>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <MDBox>
                  <MDTypography
                    fontWeight="bold"
                    sx={{
                      cursor: "pointer",
                      display: step === 1 ? "block" : "none",
                    }}
                    color="success"
                    variant="caption"
                    onClick={() => setStep(2)}
                  >
                    Don't have one? Select one
                  </MDTypography>
                  <MDTypography
                    fontWeight="bold"
                    sx={{
                      cursor: "pointer",
                      display: step === 2 ? "block" : "none",
                    }}
                    color="success"
                    variant="caption"
                    onClick={() => setStep(1)}
                  >
                    Have an auditor? Invite them
                  </MDTypography>
                </MDBox>
                <MDBox>
                  {isLoading === false ? (
                    <>
                      <MDButton
                        type="submit"
                        // variant="gradient"
                        color="success"
                        sx={{
                          padding: "12px 12px",
                          marginRight: "10px",
                          display: step === 1 ? "inline-block" : "none",
                        }}
                      >
                        Invite
                      </MDButton>
                      <MDButton
                        type="button"
                        variant="outlined"
                        color="dark"
                        sx={{ padding: "12px 12px" }}
                        onClick={onClose}
                      >
                        Cancel
                      </MDButton>
                    </>
                  ) : (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  )}
                </MDBox>
              </MDBox>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default InviteAuditorDialog;
