import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const MsGraphConnect = (props) => {
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const { objUser, target, scope } = props;
  const { client_id } = useParams();

  useEffect(() => {
    const msgraphOathState = JSON.stringify({
      routeDictionary,
      client_id,
      target,
    });

    const msgraphOathUrl =
      "https://login.microsoftonline.com/common/oauth2/v2.0/authorize" +
      `?client_id=${process.env.REACT_APP_MS_GRAPH_APP_ID}` +
      `&response_type=code` +
      `&response_mode=query` +
      `&state=${encodeURI(msgraphOathState)}` +
      `&scope=offline_access%20${scope.join("%20")}` +
      `&redirect_uri=${process.env.REACT_APP_MS_GRAPH_REDIRECT_URI_FOR_EMAIL}` +
      `&prompt=select_account`;
    window.location.href = msgraphOathUrl;
  }, []);

  return <div>index</div>;
};

const MsGraphEmailConnect = (props) => {
  return <MsGraphConnect {...props} target="email" scope={["mail.send"]} />;
};

const MsGraphAssetsConnect = (props) => {
  return (
    <MsGraphConnect
      {...props}
      target="assets"
      scope={[
        "device.read.all",
        "user.read.all",
        "reports.read.all",
        "UserAuthenticationMethod.read.all",
      ]}
    />
  );
};

export { MsGraphEmailConnect, MsGraphAssetsConnect };
