export const types_to_desc = {
  LEVEL_1: "1",
  LEVEL_2: "2",
  LEVEL_3: "3",
  LEVEL_4: "4",
  LEVEL_5: "5",
};

const desc_to_types = {};
Object.keys(types_to_desc).forEach((key) => {
  desc_to_types[types_to_desc[key]] = key;
});

export { desc_to_types };
