import DefaultCell from "views/components/TableCells/DefaultCell";
import PolicyNameBtnTr from "./PolicyNameBtnTr";
import { IconButton, Tooltip } from "@mui/material";

const PoliciesDataTableData = {
  columns: [
    {
      Header: "Title",
      accessor: "title",
      width: "25%",
      Cell: ({ value, column, row }) => (
        <PolicyNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Category",
      accessor: "category",
      width: "15%",
    },
    {
      Header: "Frameworks",
      accessor: "current_rmfs",
      width: "15%",
      Cell: ({ value }) => (
        <>
          <Tooltip title={value} placement="top-end">
            <DefaultCell
              value={value.length > 20 ? value.slice(0, 20) + "..." : value}
            />
            <IconButton
              sx={{ opacity: "0.4", fontSize: "1.2rem" }}
              pb={2}
            ></IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      Header: "Date created",
      accessor: "date_created",
      width: "30%",
    },
    {
      Header: "Created By",
      accessor: "creator_name",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ],
  rows: [],
};

export default PoliciesDataTableData;
