import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { fetchGamifycationStatsSAT } from "features/company/companiesActions";
import { useTheme } from "@mui/material/styles";
import "./charts.css";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(ArcElement, Tooltip, Legend);

const chartLabels = ["Pending", "Completed", "Overdue"];
const bgColors = ["#ffa600", "#38c273", "#e3352e"];

const defaultChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      labels: {
        color: "white",
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          var dataset = context.dataset;
          var total = dataset.data.reduce(function (
            previousValue,
            currentValue
          ) {
            return previousValue + currentValue;
          });
          var currentValue = dataset.data[context.dataIndex];
          var percentage = ((currentValue / total) * 100).toFixed(0) + "%";
          return `${context.label}: ${currentValue} of ${total} (${percentage})`;
        },
      },
    },
  },
};

export default function SatCharts({ objUser }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [objGamifycationStats, setObjGamifycationStats] = useState([]);
  const [percent, setInsurancePercent] = useState(0);
  const [isMember, setIsMember] = useState(true);

  const chartOptions = useMemo(() => {
    const result = defaultChartOptions;
    result.plugins.legend.labels.color = theme.palette?.text?.main;
    return result;
  }, [theme.palette?.text?.main]);

  const dispatch = useDispatch();
  const getStats = () => {
    dispatch(fetchGamifycationStatsSAT({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        let data = originalPromiseResult.data;
        setObjGamifycationStats(data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setObjGamifycationStats([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <>
      {objGamifycationStats.symbol != null && (
        <MDBox>
          <MDBox display="flex" px={0} pt={0}>
            <MDBox mt={0} pt={2} pl={2}>
              <MDTypography
                sx={{ marginBottom: "0.5rem" }}
                fontWeight="bold"
                variant="h5"
                color="dark"
              >
                Security Awareness Training Scorecard
              </MDTypography>
            </MDBox>
          </MDBox>

          {/* charts */}

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Grid container spacing={2}>
              {/* Symbol */}

              <Grid item xs={12} sm={6} md={4} lg={3} pl={0} pt={0} mt={2}>
                <Card>
                  <MDBox p={2}>
                    {/* <MDTypography
                    sx={{
                      marginBottom: "0.5rem",
                    }}
                    fontWeight="bold"
                    variant="h5"
                    textAlign="center"
                    color="dark"
                  >
                    Symbol{" "}
                  </MDTypography> */}
                    <MDTypography
                      // sx={{
                      //   marginBottom: "0.5rem",
                      // }}
                      fontWeight="bold"
                      variant="body2"
                      textAlign="center"
                      color="dark"
                    >
                      Symbol Training Overview
                    </MDTypography>
                    <Doughnut
                      data={{
                        labels: chartLabels,
                        datasets: [
                          {
                            data: [
                              objGamifycationStats.symbol.pendingAssignments,
                              objGamifycationStats.symbol.completedAssignments,
                              objGamifycationStats.symbol.overdueAssignments,
                            ],
                            backgroundColor: bgColors,
                          },
                        ],
                      }}
                      options={chartOptions}
                    />
                    <MDBox mt={3}>
                      <MDTypography
                        fontWeight="bold"
                        variant="body2"
                        color="dark"
                        textAlign="center"
                        sx={{ marginBottom: "1rem" }}
                      >
                        Symbol Phishing Simulation Stats
                      </MDTypography>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        {/* Emails Sent */}
                        <Grid item>
                          <MDTypography variant="caption" color="dark">
                            Emails Sent
                          </MDTypography>
                          <MDTypography
                            fontWeight="bold"
                            variant="h4"
                            textAlign="center"
                            color="dark"
                          >
                            {objGamifycationStats.symbol.emailsSent}
                          </MDTypography>
                        </Grid>

                        {/* Phish Clicks */}
                        <Grid item>
                          <MDTypography variant="caption" color="dark">
                            Phish Clicks
                          </MDTypography>
                          <MDTypography
                            fontWeight="bold"
                            variant="h4"
                            textAlign="center"
                            color="dark"
                          >
                            {objGamifycationStats.symbol.phishClicks}
                            {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                          </MDTypography>
                        </Grid>

                        {/* Shared Data */}
                        <Grid item>
                          <MDTypography variant="caption" color="dark">
                            Shared Data
                          </MDTypography>
                          <MDTypography
                            fontWeight="bold"
                            variant="h4"
                            textAlign="center"
                            color="dark"
                          >
                            {objGamifycationStats.symbol.sharedData}
                            {/* <sup
                            style={{
                              color: "green",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↓
                          </sup> */}
                          </MDTypography>
                        </Grid>

                        {/* Click Rate */}
                        <Grid item>
                          <MDTypography variant="caption" color="dark">
                            Click Rate
                          </MDTypography>
                          <MDTypography
                            fontWeight="bold"
                            variant="h4"
                            textAlign="center"
                            color="dark"
                          >
                            {objGamifycationStats.symbol.clickRate}%
                            {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                          </MDTypography>
                        </Grid>

                        {/* Click Factor */}
                        <Grid item>
                          <MDTypography variant="caption" color="dark">
                            Click Factor
                          </MDTypography>
                          <MDTypography
                            fontWeight="bold"
                            variant="h4"
                            textAlign="center"
                            color="dark"
                          >
                            {objGamifycationStats.symbol.clickFactor}x
                            <sup
                              style={{
                                color:
                                  objGamifycationStats.symbol.riskStatus ===
                                  "FAIR"
                                    ? "green"
                                    : "red",
                                fontSize: "12px",
                                marginLeft: "3px",
                              }}
                            >
                              {objGamifycationStats.symbol.riskStatus}
                            </sup>
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          )}
        </MDBox>
      )}
    </>
  );
}
