import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import "./AuthorizationTable.css";

const AuthorizationsTable = ({ tableData }) => {
  return (
    <Grid container justifyContent="start" mt={1} mb={3}>
      <Grid
        className="table-responsive-container"
        item
        xs={12}
        lg={12}
        xl={8}
        pt={0}
      >
        <Grid
          className="table-responsive-row header"
          container
          justifyContent="start"
          mt={1}
        >
          <Grid item xs={12} lg={12} xl={12} pt={0}>
            <Grid
              container
              justifyContent="start"
              columnSpacing={2}
              rowSpacing={2}
            >
              <Grid item xs={12} lg={4}>
                <MDBox mb={0}>
                  <MDBox mb={0}>
                    <MDTypography
                      className="table-responsive-header"
                      variant="body2"
                      fontWeight="bold"
                    >
                      Name
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>

              <Grid item xs={12} lg={4} pt={0}>
                <MDBox mb={0}>
                  <MDBox mb={0}>
                    <MDTypography
                      className="table-responsive-header"
                      variant="body2"
                      fontWeight="bold"
                    >
                      Type
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>

              <Grid item xs={12} lg={4} pt={0}>
                <MDBox mb={0}>
                  <MDBox mb={0}>
                    <MDTypography
                      className="table-responsive-header"
                      variant="body2"
                      fontWeight="bold"
                    >
                      Status
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>

              <Grid className="reviewer-reason" item xs={12} lg={12} pt={0}>
                <MDBox mb={0}>
                  <MDBox mb={0}>
                    <MDTypography variant="body2" fontWeight="bold">
                      Reason
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {tableData.map((val, key) => {
          return (
            <Grid
              key={key}
              className="table-responsive-row"
              container
              justifyContent="start"
              mt={0}
            >
              <Grid item xs={12} lg={12} xl={12} pt={0}>
                <Grid
                  container
                  justifyContent="start"
                  columnSpacing={2}
                  rowSpacing={2}
                >
                  <Grid item xs={12} lg={4}>
                    <MDBox mb={0}>
                      <MDBox mb={0}>
                        <MDTypography
                          className="table-responsive-header"
                          variant="body2"
                          fontWeight="bold"
                        >
                          Name
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="body2" color="text">
                        {val.name ?? ""}
                      </MDTypography>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} lg={4} pt={0}>
                    <MDBox mb={0}>
                      <MDBox mb={0}>
                        <MDTypography
                          className="table-responsive-header"
                          variant="body2"
                          fontWeight="bold"
                        >
                          Type
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="body2" color="text">
                        {val.type ?? ""}
                      </MDTypography>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} lg={4} pt={0}>
                    <MDBox mb={0}>
                      <MDBox mb={0}>
                        <MDTypography
                          className="table-responsive-header"
                          variant="body2"
                          fontWeight="bold"
                        >
                          Status
                        </MDTypography>
                      </MDBox>
                      <MDTypography
                        variant="body2"
                        color={val.status_color ?? "text"}
                        fontWeight="bold"
                      >
                        {val.status ?? ""}
                      </MDTypography>
                    </MDBox>
                  </Grid>

                  <Grid
                    className={
                      val.show_reason == true
                        ? "reviewer-reason show-reason"
                        : "reviewer-reason"
                    }
                    item
                    xs={12}
                    lg={12}
                    pt={0}
                  >
                    <MDBox mb={0}>
                      <MDBox mb={0}>
                        <MDTypography
                          className="reviewer-reason-header"
                          variant="body2"
                          fontWeight="bold"
                        >
                          Reason
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="body2" color="text">
                        {val.reason ?? ""}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default AuthorizationsTable;