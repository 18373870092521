import DefaultCell from "views/components/TableCells/DefaultCell";

const InvitesDataTableData = {
  columns: [
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Role",
      accessor: "role",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Invite Sent On",
      accessor: "created_at",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default InvitesDataTableData;
