import Card from "@mui/material/Card";
import Slider from "@mui/material/Slider";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showMsg } from "utils/general";
import { fetchSubscriptionPlansForGuest } from "features/company/companiesActions.js";
import "./SignUpPickAPlan.css";
import { Container } from "@mui/system";
import PageLayout from "views/containers/PageLayout";
import { Grid } from "@mui/material";
import ClientProfitCalculator from "./components";
//
const PricingPlansPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  /**lite */
  const [plan, setPlan] = useState(0);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({
    units: 0,
    status: "incomplete",
  });

  /**plus */
  const [plusPlan, setPlusPlan] = useState(0);
  const [plusSubscriptionPlans, setPlusSubscriptionPlans] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**lite */
  const marks = subscriptionPlans.map((plan, i) => {
    return {
      value: i,
      label: plan.value,
    };
  });

  /**plus */
  const marksPlus = plusSubscriptionPlans.map((plan, i) => {
    return {
      value: i,
      label: plan.value, //plan.value corresponds to the number of units
    };
  });

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchSubscriptionPlansForGuest({}))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const result = originalPromiseResult.data;

        const currentPlan = result.available_products.findIndex(
          (plan) => plan.units === result.current_units - 1
        );

        /**lite */
        setPlan(currentPlan === -1 ? 0 : currentPlan);
        setCurrentStatus({
          units: (result.current_units ?? 1) - 1,
          status: result.subscription_status ?? "incomplete",
        });
        setSubscriptionPlans(
          result.available_products.map((plan) => {
            return {
              name: plan.name,
              value: plan.units,
              price: plan.price,
              price_str: plan.price_str,
              price_x_client: plan.price_x_client,
              price_x_client_str: plan.price_x_client_str,
              monthly_price_id: plan.monthly_price_id,
            };
          })
        );

        /**plus */
        setPlusSubscriptionPlans(
          result.available_plus_products.map((plan) => {
            return {
              name: plan.name,
              value: plan.units,
              price: plan.price,
              price_str: plan.price_str,
              price_x_client: plan.price_x_client,
              price_x_client_str: plan.price_x_client_str,
              monthly_price_id: plan.monthly_price_id,
            };
          })
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (objErrorRsp?.status === 403) {
          showMsg("error", "You are not authorized to access this page.");
          navigate("/home");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  /**plus */
  const valuePlustext = (value) => {
    return plusSubscriptionPlans[value].value + " users";
  };

  const valuePlusLabelFormat = (value) => {
    return `${plusSubscriptionPlans[value].price_str}`;
  };

  return (
    <>
      <PageLayout>
        <MDBox
          minHeight="60vh"
          borderRadius="xl"
          m={2}
          pt={2}
          pb={4}
          sx={{ backgroundColor: "#e7edee", height: "auto" }}
        >
          <Container></Container>
          {isLoading || subscriptionPlans.length === 0 ? (
            <MDBox mb={4} sx={{ marginTop: "5rem" }}>
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            </MDBox>
          ) : (
            <>
              <MDBox sx={{ marginTop: "1rem" }}>
                <MDTypography
                  variant="body1"
                  mb={2}
                  sx={{ textAlign: "center", color: "#1A1A1A" }}
                >
                  How many clients do you want to support?
                </MDTypography>

                <Grid
                  spacing={2}
                  container
                  justifyContent="center"
                  width="100%"
                >
                  {/**plus */}
                  <Grid item xs={12} lg={5}>
                    <Card sx={{ height: "100%" }}>
                      <MDBox
                        className="card-header"
                        sx={{
                          backgroundColor: "#000",
                          borderTopLeftRadius: "0.75rem",
                          borderTopRightRadius: "0.75rem",
                        }}
                        px={2}
                        py={1}
                      >
                        <MDTypography
                          sx={{ color: "#fff" }}
                          variant="h5"
                          fontWeight="bold"
                        >
                          Compliance Scorecard Plus -{" "}
                          <em>Minimum 1 Year Commitment</em>
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        mt={4}
                      >
                        <Card
                          className="custom-slider hl-border bg-light"
                          sx={{ marginBottom: "16px" }}
                        >
                          <MDBox
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                            mb={0}
                            px={4}
                            py={2}
                          >
                            <MDBox className="slider-container">
                              <Slider
                                color="secondary"
                                aria-label="Choose your plan"
                                value={plusPlan}
                                onChange={(_, value) => setPlusPlan(value)}
                                valueLabelFormat={valuePlusLabelFormat}
                                getAriaValueText={valuePlustext}
                                step={null}
                                valueLabelDisplay="auto"
                                marks={marksPlus}
                                max={marksPlus.length - 1}
                              />
                            </MDBox>
                          </MDBox>
                        </Card>
                      </MDBox>
                      <MDBox mb={4} mt={1}>
                        <Card
                          className="custom-card custom-product-card hl-border-dark bg-light hl-shadow"
                          sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "0",
                            marginTop: "0",
                          }}
                        >
                          <MDBox
                            className="card-header bg-secondary"
                            px={2}
                            py={1}
                          >
                            <MDTypography
                              sx={{ color: "#FFFFFF" }}
                              variant="h5"
                              fontWeight="bold"
                            >
                              {plusSubscriptionPlans[plusPlan].name}
                            </MDTypography>
                          </MDBox>
                          <MDBox className="card-body" p={2}>
                            <span className="price-per-client-plus">
                              {
                                plusSubscriptionPlans[plusPlan]
                                  .price_x_client_str
                              }{" "}
                            </span>
                            <span className="price-client-label">
                              {" "}
                              / client
                            </span>
                            <span className="price-month">
                              {plusSubscriptionPlans[plusPlan].price_str}
                            </span>
                            <span className="price-month-label"> / mo</span>

                            <MDBox my={1}>
                              <MDTypography variant="h5" fontWeight="bold">
                                Compliance Scorecard Plus Plan Includes:
                              </MDTypography>
                            </MDBox>

                            <MDBox className="all-plans-include-section">
                              <ul className="list-unstyled mt-3 mb-4">
                                <li>
                                  <span>
                                    <i className="fa-sharp fa-solid fa-check"></i>
                                    &nbsp;
                                    <b>
                                      Always free internal use when on a paid
                                      plan:{" "}
                                    </b>{" "}
                                    Access our platform at no cost when you’re
                                    on any paid plan.
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i className="fa-sharp fa-solid fa-check"></i>
                                    &nbsp;{" "}
                                    <b>White Glove Onboarding (2 Sessions): </b>
                                    <ul style={{ marginBottom: 0 }}>
                                      <li>
                                        <b>Technical Onboarding:</b> Learn
                                        platform navigation, API setups, and
                                        more.{" "}
                                      </li>
                                      <li>
                                        <b>Sales and Market Strategy:</b>{" "}
                                        Collaborative planning for package
                                        development, pricing strategies, and
                                        profit optimization{" "}
                                      </li>
                                    </ul>
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i className="fa-sharp fa-solid fa-check"></i>
                                    &nbsp; <b>Assessment Scorecard: </b>
                                    <ul style={{ marginBottom: 0 }}>
                                      <li>
                                        <b>Customizable Assessments:</b> Create
                                        and import assessments, choose from
                                        pre-defined templates for standards like
                                        HIPAA, NIST, FTC, SOC2, and more.
                                      </li>
                                      <li>
                                        <b>Efficient Deployment: </b> Answer
                                        once, deploy to multiple clients with
                                        customization options at each endpoint.
                                      </li>
                                      <li>
                                        <b>Lifecycle Management: </b> Full
                                        control from creation to completion,
                                        including custom scoring and
                                        collaborative item assignment.
                                      </li>
                                      <li>
                                        <b>Advanced Analysis: </b> Track changes
                                        over time with comparative analytics,
                                        generate risk matrix reports, and system
                                        security plans.
                                      </li>
                                      <li>
                                        <b>Risk Management: </b> Set risk
                                        levels, assign gaps to a risk register,
                                        and create actionable plans and
                                        milestones.
                                      </li>
                                    </ul>
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i className="fa-sharp fa-solid fa-check"></i>
                                    &nbsp; <b>Policy Scorecard: </b>
                                    <ul style={{ marginBottom: 0 }}>
                                      <li>
                                        <b>Template Management:</b> Create and
                                        edit global templates, including over 30
                                        policy frameworks updated monthly.
                                      </li>
                                      <li>
                                        <b>Efficient Policy Deployment: </b>{" "}
                                        Write once, deploy universally.
                                      </li>
                                      <li>
                                        <b>Comprehensive Policy Tools: </b>{" "}
                                        Generate WISPs, manage workflows, review
                                        and approval processes, and utilize full
                                        WYSIWYG editing.
                                      </li>
                                    </ul>
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i
                                      className="fa fa-files-o"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;
                                    <b>
                                      Existing document updates as frameworks
                                      change:{" "}
                                    </b>{" "}
                                    Streamlined management of assets, written
                                    information security policies, and insurance
                                    compliance.
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i className="fa-sharp fa-solid fa-check"></i>
                                    &nbsp; <b>Platform Features: </b>
                                    <ul>
                                      <li>
                                        <b>Multi-Tenant Architecture:</b> Manage
                                        multiple clients over a single interface
                                      </li>
                                      <li>
                                        <b>Enhanced Security: </b>Single Sign-On
                                        (SSO), Multi-Factor Authentication
                                        (MFA), and Microsoft 365 integration.
                                      </li>
                                      <li>
                                        <b>Unlimited Access: </b> Support for
                                        unlimited users and version control with
                                        redlining capabilities.
                                      </li>
                                    </ul>
                                  </span>
                                </li>
                              </ul>
                            </MDBox>
                          </MDBox>
                          <MDBox className="card-footer" px={2} pb={2} pt={2}>
                            <a
                              href={window.location.origin + "/sign-up"}
                              target="_parent"
                            >
                              <MDButton
                                variant="contained"
                                color="success"
                                sx={{ width: "100%" }}
                              >
                                Get Started
                              </MDButton>
                            </a>
                          </MDBox>
                        </Card>
                      </MDBox>
                    </Card>
                  </Grid>
                  {/* endplus */}

                  {/* peer group */}
                  <Grid item xs={12} lg={6}>
                    <Card sx={{ height: "100%" }}>
                      <MDBox
                        className="card-header"
                        sx={{
                          backgroundColor: "#4d4c5c",
                          borderTopLeftRadius: "0.75rem",
                          borderTopRightRadius: "0.75rem",
                        }}
                        px={2}
                        py={1}
                      >
                        <MDTypography
                          sx={{ color: "#fff" }}
                          variant="h5"
                          fontWeight="bold"
                        >
                          Peer Group Plan
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        mt={4}
                      >
                        <stripe-pricing-table
                          pricing-table-id={
                            process.env.REACT_APP_SECONDARY_PICK_A_PLAN_TABLE_ID
                          }
                          publishable-key={
                            process.env
                              .REACT_APP_SECONDARY_STRIPE_PUBLISHABLE_KEY
                          }
                        ></stripe-pricing-table>
                      </MDBox>
                    </Card>
                  </Grid>
                  {/* end peer group */}
                  <ClientProfitCalculator />
                </Grid>
              </MDBox>
            </>
          )}
        </MDBox>
      </PageLayout>
    </>
  );
};

export default PricingPlansPage;
