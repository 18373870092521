import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { USER_SERVER } from "components/utils/constants/misc";

export const auth0VerifiyEmailAddress = createAsyncThunk(
  "user/auth0VerifiyEmailAddress",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${USER_SERVER}/users/verify-email`,
        values
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
