import DefaultCell from "views/components/TableCells/DefaultCell";
import TemplateNameBtnTr from "./TemplateNameBtnTr";
import ApiActionBtnTr from "./ApiActionBtnTr";

const DataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "name",
      //  width: "25%",
      // Cell: ({ value, column, row }) => (
      //   <TemplateNameBtnTr value={value} column={column} row={row} />
      // ),
      // Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Value",
      accessor: "value",
      // width: "30%",
    },
    {
      Header: "Expiry",
      accessor: "expiry",
      //  width: "30%",
      // Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      //accessor: "status",
      Cell: ({ value, column, row }) => (
        <ApiActionBtnTr value={value} column={column} row={row} />
      ),
    },
  ],
  rows: [],
};

export default DataTableData;
