import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardButton from "views/components/DashboardButtton";

function PeerGroupFileEmbed({ file }) {
  return (
    <Grid item xs={12} sm={8} md={8} pt={0}>
      <MDBox
        mb={0}
        sx={{
          height: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {file.file_type == "png" ||
        file.file_type == "jpeg" ||
        file.file_type == "jpg" ? (
          <img src={file.file_path} />
        ) : (
          <DashboardButton
            component="a"
            href={file.file_path}
            download
            btnIcon="download"
            btnText={`Download ${file.file_name}`}
            textColor="white"
            bgColor="success"
            sx={{ margin: "0" }}
          />
        )}
      </MDBox>
    </Grid>
  );
}

export default PeerGroupFileEmbed;
