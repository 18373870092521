// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { useNavigate } from "react-router-dom";
const ConnectWiseConfirmationSection = (props) => {
  const { objUser } = props;

  const navigate = useNavigate();
  const btnOnClickYesImportCustomers = () => {
    navigate("/connections/connectwise");
  };
  const btnOnClickNo = () => {
    navigate("/dashboard");
  };
  return (
    <Card>
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">
              Do you want to import your customers from ConnectWise?
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <MDButton
            variant="outlined"
            color="secondary"
            onClick={() => btnOnClickNo()}
          >
            No
          </MDButton>

          <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <MDButton
              color="success"
              sx={{ height: "100%" }}
              onClick={() => btnOnClickYesImportCustomers()}
            >
              Yes
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default ConnectWiseConfirmationSection;
