/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import {
  COMPANIES_SERVER,
  USER_SERVER,
  VERSION_SERVER,
} from "components/utils/constants/misc";

export const fetchCompanyUserById = createAsyncThunk(
  "company/fetchCompanyUserById",
  async (values, thunkApi) => {
    try {
      const { objUser, id } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/users/${id}`
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateCompanyUser = createAsyncThunk(
  "company/updateCompanyUser",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/users/update/from-msp-settings/${objPostData.id}`,
        objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const sendPasswordResetEmail = createAsyncThunk(
  "client/sendPasswordResetEmail",
  async (values, thunkApi) => {
    try {
      const { email } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/users/send-password-reset-email`,
        {
          email: email,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const resendMSPInvite = createAsyncThunk(
  "msp/resendMSPInvite",
  async (values, thunkApi) => {
    try {
      const { objUser, inviteId } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/reinvite-msp-user`,
        { invite_id: inviteId }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const cancelInvite = createAsyncThunk(
  "msp/cancelInvite",
  async (values, thunkApi) => {
    try {
      const { objUser, inviteId } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/cancel-invite`,
        { invite_id: inviteId }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateCurrentClientSelected = createAsyncThunk(
  "client/updateCurrentClientSelected",
  async (values, thunkApi) => {
    try {
      const { value } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/users/update-current-selected-client`,
        {
          value,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
