import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";

import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useLocation } from "react-router-dom";
import "./settings.css";

import ApiTokensImg from "assets/images/logos/api-tokens.png";
import AzureImg from "assets/images/logos/azure.png";
import ConnectWiseLogo from "assets/images/logos/connectwise-logo2.png";
import SymbolLogo from "assets/images/logos/symbol-logo.png";
import DashboardButton from "views/components/DashboardButtton";

const SettingsMain = (props) => {
  const { objUser } = props;
  const isClientUser = objUser.user.role.name === "client_user" ? true : false;
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDTypography fontWeight="bold" variant="h5">
        Connected Apps & Integrations
      </MDTypography>

      <MDBox>
        <Grid spacing={2} container width="100%">
          {!isClientUser ? (
            <Grid item xs={12} lg={6}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <Grid item xs={12} lg={2}>
                      <img src={ConnectWiseLogo} alt="Connectwise" />
                    </Grid>
                    <MDTypography variant="h5" fontWeight="bold">
                      Connectwise
                    </MDTypography>
                    <MDTypography variant="p" fontWeight="regular">
                      Sync your clients from your connectwise account
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="bold">
                      <DashboardButton
                        component={Link}
                        to={`/connections/connectwise`}
                        state={{ routeDictionary }}
                        btnText="Config"
                        btnIcon="settings"
                        textColor="white"
                        bgColor="success"
                      />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            ""
          )}

          <Grid item xs={12} lg={6}>
            <Card sx={{ marginTop: "16px" }}>
              <MDBox p={2}>
                <Grid>
                  <Grid item xs={12} lg={2}>
                    <img src={ApiTokensImg} alt="Api Tokens" />
                  </Grid>
                  <MDTypography variant="h5" fontWeight="bold">
                    API Tokens
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    Issue API tokens for your external applications
                  </MDTypography>
                  <MDTypography variant="h5" fontWeight="bold">
                    <DashboardButton
                      component={Link}
                      to={`/connections/api-keys`}
                      state={{ routeDictionary }}
                      btnText="Config"
                      btnIcon="settings"
                      textColor="white"
                      bgColor="success"
                    />
                  </MDTypography>
                </Grid>
              </MDBox>
            </Card>
          </Grid>

          {!isClientUser ? (
            <Grid item xs={12} lg={6}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <Grid item xs={12} lg={2}>
                      <img src={AzureImg} alt="Azure" />
                    </Grid>
                    <MDTypography variant="h5" fontWeight="bold">
                      Graph API Mail Delivery
                    </MDTypography>
                    <MDTypography variant="p" fontWeight="regular">
                      Use MS Graph API to send email messages
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="bold">
                      <DashboardButton
                        component={Link}
                        to={`/connections/msgraph/clients-to-configure`}
                        state={{ routeDictionary }}
                        btnText="Config"
                        btnIcon="settings"
                        textColor="white"
                        bgColor="success"
                      />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            ""
          )}

          {!isClientUser &&
          process.env.REACT_APP_BASE_URL != "https://go.compliancerisk.io" ? (
            <Grid item xs={12} lg={6}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <Grid item xs={12} lg={2}>
                      <img
                        src={SymbolLogo}
                        alt="Symbol"
                        style={{ minWidth: "300px" }}
                      />
                    </Grid>
                    <MDTypography variant="h5" fontWeight="bold">
                      Symbol
                    </MDTypography>
                    <MDTypography variant="p" fontWeight="regular">
                      Sync your clients from your symbol account
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="bold">
                      <DashboardButton
                        component={Link}
                        to={`/connections/symbol`}
                        state={{ routeDictionary }}
                        btnText="Config"
                        btnIcon="settings"
                        textColor="white"
                        bgColor="success"
                      />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default SettingsMain;
