import { useCallback, memo, useState, useMemo, useRef, Fragment } from "react";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SettingsIcon from "@mui/icons-material/Settings";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import DOMPurify from "dompurify";
import "./AssessmentQuestion.css";
import QuestionSettings from "./QuestionSettings";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import {
  InputLabel,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextareaAutosize,
  FormControl,
  Icon,
  TextField,
  Alert,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { showMsg } from "utils/general";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useMaterialUIController } from "context";
import { FileUpload, Label } from "@mui/icons-material";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  uploadEvidenceToOneDrive,
  uploadEvidenceToSharePoint,
} from "features/company/assessmentsActions";
import { unwrapResult } from "@reduxjs/toolkit";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  if (node.nodeName.toLowerCase() === "a") {
    node.setAttribute("rel", "noopener noreferrer");
    node.setAttribute("target", "_blank");
  }
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const controlOwnerAutocompleteGroupByFunction = (option) =>
  option.role === "client_user"
    ? "Client contacts"
    : option.role
    ? "MSP users"
    : "Other";
const controlOwnerAutocompleteIsEqualToFunction = (option, value) =>
  option.id === value;
const controlOwnerAutocompleteRenderInputFunction = (params) => (
  <MDInput {...params} variant="standard" label="Control Owner" fullWidth />
);

const AssessmentQuestion = memo(
  ({
    userList,
    policyList,
    question_id,
    item,
    control_id,
    explanation,
    scoring_instructions,
    options,
    answer,
    assigned_to_client,
    responsibility,
    vendor,
    tool,
    question_notes,
    evidence_location,
    implementation_statement,
    policy_statements,
    procedure_statements,
    control_owner_id,
    sprs_score,
    max_points,
    itemScores,
    onChange,
    onChangeAssign,
    onChangeResponsibility,
    onChangeStatements,
    disabled,
    key,
    objUser,
    event_question_id,
    evidenceLockers,
    evidence_location_type,
  }) => {
    const oneDriveUploadRef = useRef(null);
    const sharePointUploadRef = useRef(null);
    const [selectedTabs, setSelectedTabs] = useState({});
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [isUploadingToOneDrive, setIsUploadingToOneDrive] = useState(false);
    const [isUploadingToSharePoint, setIsUploadingToSharePoint] =
      useState(false);

    const handleOneDriveUploadClick = () => {
      if (oneDriveUploadRef.current) {
        oneDriveUploadRef.current.click();
      }
    };

    const handleSharePointUploadClick = () => {
      if (sharePointUploadRef.current) {
        sharePointUploadRef.current.click();
      }
    };

    const score = useMemo(() => {
      return (
        itemScores[
          options.find((option) => {
            return option.id === answer;
          })?.score
        ] ?? null
      );
    }, [options, answer, itemScores]);

    const controlOwnerAutocompleteGetLabelFunction = useCallback(
      (option) =>
        option?.name ?? userList.find((user) => user.id === option)?.name ?? "",
      [userList]
    );

    const controlOwnerAutocompleteOnChangeFunction = useCallback(
      (e, value) => {
        onChangeStatements(question_id, "control_owner_id", value?.id ?? "");
      },
      [question_id, onChangeStatements]
    );

    const sanitizedExplanation = useMemo(
      () => DOMPurify.sanitize(explanation ?? ""),
      [explanation]
    );

    const sanitizedItem = useMemo(() => DOMPurify.sanitize(item ?? ""), [item]);

    const sanitizedInstructions = DOMPurify.sanitize(
      scoring_instructions ?? ""
    );

    /**policy statement autocomplete */
    const formattedCurrentPolicyStatementValues = useMemo(() => {
      return policy_statements.map((policyId) => {
        return {
          id: policyId,
          title: policyList.find((policy) => policy.id === policyId)?.title,
        };
      });
    }, [policy_statements]);

    const policyStatementAutocompleteGetLabelFunction = useCallback(
      (option) =>
        option?.title ??
        policyList.find((policy) => policy.id === option)?.title ??
        "",
      [policyList]
    );

    const policyStatementsAutocompleteOnChangeFunction = useCallback(
      (e, value) => {
        let formattedValue = value.map((val) => {
          if (val.title) {
            return val.id;
          } else {
            return val;
          }
        });

        formattedValue = Array.from(new Set(formattedValue));
        onChangeStatements(question_id, "policy_statements", formattedValue);
      },
      [question_id, onChangeStatements]
    );

    /**procedure statement */
    const formattedCurrentProcedureStatementValues = useMemo(() => {
      return policyList.filter((policy) =>
        procedure_statements.includes(policy.id)
      );
    }, [procedure_statements]);

    const procedureStatementAutocompleteGetLabelFunction = useCallback(
      (option) =>
        option?.title ??
        policyList.find((policy) => policy.id === option)?.title ??
        "",
      [policyList]
    );

    const procedureStatementsAutocompleteOnChangeFunction = useCallback(
      (e, value) => {
        let formattedValue = value.map((val) => {
          if (val.title) {
            return val.id;
          } else {
            return val;
          }
        });

        formattedValue = Array.from(new Set(formattedValue));
        onChangeStatements(question_id, "procedure_statements", formattedValue);
      },
      [question_id, onChangeStatements]
    );

    const handleTabChange = (tabValue, newValue) => {
      setSelectedTabs((prevState) => ({
        ...prevState,
        [tabValue]: newValue,
      }));
    };

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const uploadOneDriveEvidence = async (e) => {
      if (!e.target.files[0]) {
        showMsg("error", "Please select a file to upload successfully");
        return;
      }
      setIsUploadingToOneDrive(true);
      setFile(e.target.files[0]);
      let selectedFile = e.target.files[0];
      dispatch(
        uploadEvidenceToOneDrive({ objUser, selectedFile, event_question_id })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setFile(null);
          const response = originalPromiseResult;
          showMsg("success", "Evidence uploaded");
          onChangeStatements(
            question_id,
            "evidence_location",
            response.data.evidence_link
          );
          onChangeStatements(
            question_id,
            "evidence_location_type",
            "file_path"
          );
          setIsUploadingToOneDrive(false);
        })
        .catch((err) => {
          setFile(null);
          setIsUploadingToOneDrive(false);
          console.error("cannot upload file:", err);
          showMsg("error", "Failed to upload evidence, please try again.");
          if (err.response.data.errors) {
            console.error("details:", err.response.data.errors);
            err.response.data.errors.forEach((error) => {
              showMsg("error", error);
            });
          } else {
          }
        });
    };

    const uploadSharePointEvidence = async (e) => {
      if (!e.target.files[0]) {
        showMsg("error", "Please select a file to upload successfully");
        return;
      }
      setIsUploadingToSharePoint(true);
      setFile(e.target.files[0]);
      let selectedFile = e.target.files[0];
      dispatch(
        uploadEvidenceToSharePoint({
          objUser,
          selectedFile,
          event_question_id,
        })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setFile(null);
          const response = originalPromiseResult;
          showMsg("success", "Evidence uploaded");
          onChangeStatements(
            question_id,
            "evidence_location",
            response.data.evidence_link
          );
          onChangeStatements(
            question_id,
            "evidence_location_type",
            "file_path"
          );
          setIsUploadingToSharePoint(false);
        })
        .catch((err) => {
          setFile(null);
          setIsUploadingToSharePoint(false);
          console.error("cannot upload file:", err);
          showMsg("error", "Failed to upload evidence, please try again.");
          if (err.response.data.errors) {
            console.error("details:", err.response.data.errors);

            err.response.data.errors.forEach((error) => {
              showMsg("error", error);
            });
          } else {
          }
        });
    };

    const onChangeEvidenceLocationTextInput = (text) => {
      onChangeStatements(question_id, "evidence_location", text);
      onChangeStatements(question_id, "evidence_location_type", "text");
    };

    return (
      <Accordion
        sx={
          darkMode
            ? {
                marginBottom: "10px",
                marginTop: "10px",
                backgroundColor: "#2f3958",
              }
            : { marginBottom: "10px", marginTop: "10px" }
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="icon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ position: "relative" }}
        >
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "flex-start",
              flexGrow: 1,
            }}
          >
            {control_id ? (
              <MDTypography
                id={question_id + "_enunciate"}
                dangerouslySetInnerHTML={{
                  __html: `${control_id}- ${sanitizedItem}`,
                }}
              ></MDTypography>
            ) : (
              <MDTypography
                id={question_id + "_enunciate"}
                dangerouslySetInnerHTML={{ __html: sanitizedItem }}
              ></MDTypography>
            )}
          </MDBox>
        </AccordionSummary>
        <AccordionDetails>
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              flexGrow: 1,
            }}
            mb={2}
          >
            {score !== null && (
              <Chip
                label={`Score: ${score.value} / ${score.possible_points}`}
                variant="outlined"
                sx={{
                  color: score.color,
                  borderColor: score.color,
                }}
              />
            )}
            {!!assigned_to_client && (
              <Chip
                onClick={() => onChangeAssign(question_id, false)}
                icon={<AssignmentIndIcon />}
                label="Unassign to client"
                variant="outlined"
                sx={{ color: "text.main" }}
              />
            )}
            {!assigned_to_client && (
              <Chip
                onClick={() => onChangeAssign(question_id, true)}
                icon={<AssignmentIndIcon />}
                label="Assign to client"
                variant="outlined"
                sx={{ color: "text.main" }}
              />
            )}
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Tabs
              value={selectedTabs[question_id] || question_id + "-question"}
              onChange={(event, newValue) =>
                handleTabChange(question_id, newValue)
              }
              centered
            >
              <Tab value={`${question_id}-question`} label="Question" />
              {(sanitizedExplanation.length > 0 ||
                sanitizedInstructions.length > 0) && (
                <Tab
                  value={`${question_id}-scoring`}
                  label="Supplemental Material"
                />
              )}

              <Tab
                value={`${question_id}-responsibility`}
                label="Responsibility"
              />
              <Tab value={`${question_id}-evidence`} label="Evidence" />
            </Tabs>
            <TabPanel
              value={`${question_id}-question`}
              index={selectedTabs[question_id] || question_id + "-question"}
            >
              <MDBox>
                <Stack spacing={1}>
                  {options.map((option) => (
                    <Paper key={option.id} elevation={0}>
                      <label htmlFor={option.id}>
                        <MDBox
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            alignItems: "center",
                            cursor: "pointer",
                            flexWrap: {
                              xs: "wrap",
                              md: "nowrap",
                            },
                          }}
                        >
                          <Radio
                            value={option.id}
                            name={question_id + "_answer"}
                            inputProps={{ "aria-label": option.option }}
                            onChange={() => onChange(question_id, option.id)}
                            id={option.id}
                            checked={answer === option.id}
                            disabled={disabled}
                          />
                          <MDBox sx={{ flexGrow: 1 }}>
                            <MDTypography variant="body2" fontWeight="regular">
                              {option.option}
                            </MDTypography>
                            <MDTypography
                              variant="caption"
                              color="info"
                              fontWeight="light"
                            >
                              {option.explanation ?? ""}
                            </MDTypography>
                          </MDBox>
                          <Chip
                            label={itemScores[option.score].name}
                            variant="outlined"
                            sx={{
                              backgroundColor: itemScores[option.score].color,
                              color: itemScores[option.score].text_color,
                            }}
                          />
                        </MDBox>
                      </label>
                    </Paper>
                  ))}
                </Stack>
              </MDBox>
            </TabPanel>
            {(sanitizedExplanation.length > 0 ||
              sanitizedInstructions.length > 0) && (
              <TabPanel
                value={`${question_id}-scoring`}
                index={selectedTabs[question_id] || question_id + "-question"}
              >
                {sanitizedExplanation.length > 0 ? (
                  <>
                    <MDTypography variant="h6" mt={3}>
                      Explanation
                    </MDTypography>
                    <MDTypography
                      paragraph
                      dangerouslySetInnerHTML={{
                        __html: sanitizedExplanation,
                      }}
                    ></MDTypography>
                  </>
                ) : (
                  ""
                )}

                {sanitizedInstructions.length > 0 ? (
                  <>
                    <MDTypography variant="h6">
                      Scoring Instructions
                    </MDTypography>

                    <MDTypography
                      paragraph
                      dangerouslySetInnerHTML={{
                        __html: sanitizedInstructions,
                      }}
                    ></MDTypography>
                  </>
                ) : (
                  ""
                )}
              </TabPanel>
            )}
            <TabPanel
              value={`${question_id}-responsibility`}
              index={selectedTabs[question_id] || question_id + "-question"}
            >
              <QuestionSettings
                onChangeAssign={onChangeAssign}
                onChangeResponsibility={onChangeResponsibility}
                question_id={question_id}
                assigned_to_client={assigned_to_client}
                responsibility={responsibility}
                vendor={vendor}
                tool={tool}
              />
            </TabPanel>
            <TabPanel
              value={`${question_id}-evidence`}
              index={selectedTabs[question_id] || question_id + "-question"}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box>
                    {max_points && (
                      <InputLabel
                        htmlFor="evidence-location"
                        style={{ color: "red" }}
                      >
                        *
                      </InputLabel>
                    )}
                    <MDBox
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <MDTypography>Evidence Location</MDTypography>
                      {evidence_location &&
                        evidence_location_type == "file_path" && (
                          <a
                            href={evidence_location}
                            target="_blank"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "5px",
                              color: "#38b54a",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            - Preview uploaded content
                            <FilePresentIcon />
                          </a>
                        )}
                    </MDBox>
                    <MDBox
                      mb={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <input
                        id="onedrive"
                        type="file"
                        onChange={uploadOneDriveEvidence}
                        style={{ display: "none" }}
                        ref={oneDriveUploadRef}
                      />
                      <input
                        id="sharepoint"
                        type="file"
                        onChange={uploadSharePointEvidence}
                        style={{ display: "none" }}
                        ref={sharePointUploadRef}
                      />
                      {(evidenceLockers?.onedrive ?? false) && (
                        <label>
                          <MDButton
                            variant="gradient"
                            onClick={handleOneDriveUploadClick}
                            color="info"
                            sx={{
                              padding: "5px 15px",
                              marginTop: "1rem",
                            }}
                            disabled={
                              !evidenceLockers.onedrive || isUploadingToOneDrive
                            }
                          >
                            {isUploadingToOneDrive ? (
                              <LoadingSpinner
                                subClass="text-center"
                                color="white"
                                size="md"
                              />
                            ) : (
                              <Fragment>
                                <Icon fontSize="large">
                                  <FileUpload />
                                </Icon>
                                <MDTypography
                                  fontWeight="bold"
                                  sx={{ marginLeft: "0.75rem" }}
                                  variant="button"
                                  color="white"
                                >
                                  Upload to OneDrive
                                </MDTypography>
                              </Fragment>
                            )}
                          </MDButton>
                        </label>
                      )}
                      {(evidenceLockers?.sharepoint ?? false) && (
                        <label>
                          <MDButton
                            variant="gradient"
                            onClick={handleSharePointUploadClick}
                            color="info"
                            sx={{
                              padding: "5px 15px",
                              marginLeft: "1rem",
                              marginRight: "1rem",
                              marginTop: "1rem",
                            }}
                            disabled={
                              !evidenceLockers.sharepoint ||
                              isUploadingToSharePoint
                            }
                          >
                            {isUploadingToSharePoint ? (
                              <LoadingSpinner
                                subClass="text-center"
                                color="white"
                                size="md"
                              />
                            ) : (
                              <Fragment>
                                <Icon fontSize="large">
                                  <FileUpload />
                                </Icon>
                                <MDTypography
                                  fontWeight="bold"
                                  sx={{ marginLeft: "0.75rem" }}
                                  variant="button"
                                  color="white"
                                >
                                  Upload to SharePoint
                                </MDTypography>
                              </Fragment>
                            )}
                          </MDButton>
                        </label>
                      )}
                      {(!evidenceLockers.sharepoint ||
                        !evidenceLockers.onedrive) && (
                        <MDBox display="flex" alignItems="center" gap={0.5}>
                          <SettingsIcon color="info" />
                          <Link to={`/evidence-locker`}>
                            <MDTypography
                              variant="p"
                              fontWeight="bold"
                              pl={0}
                              color="link"
                              sx={{
                                fontSize: "0.8rem",
                                marginBottom: "10px",
                              }}
                            >
                              Configure Evidence Lockers
                            </MDTypography>
                          </Link>
                        </MDBox>
                      )}
                    </MDBox>
                  </Box>
                  <MDBox>
                    <Alert
                      severity="warning"
                      sx={{ fontSize: "14px" }}
                      icon={<InfoIcon fontSize="inherit" />}
                    >
                      Compliance Scorecard suggests uploading the relevant
                      evidence file instead of typing a description.
                      Descriptions alone may not meet the stringent requirements
                      of most audits. To upload files, please configure an
                      Evidence Locker.
                    </Alert>
                  </MDBox>
                  {/* )} */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box display="flex" gap={1} mb={1} alignItems="center">
                    {max_points && (
                      <InputLabel
                        htmlFor="implementation_statement"
                        style={{ color: "red" }}
                      >
                        *
                      </InputLabel>
                    )}

                    <MDInput
                      label="Implementation Statement"
                      value={implementation_statement ?? ""}
                      onChange={(e) =>
                        onChangeStatements(
                          question_id,
                          "implementation_statement",
                          e.target.value
                        )
                      }
                      fullWidth={true}
                      variant={"standard"}
                      disabled={disabled}
                    />
                  </Box>
                  <Box display="flex" gap={1} alignItems="center">
                    <FormControl sx={{ width: "100%", marginTop: "18px" }}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={policyList}
                        getOptionLabel={
                          policyStatementAutocompleteGetLabelFunction
                        }
                        defaultValue={formattedCurrentPolicyStatementValues}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label={"Policy Statements"}
                            placeholder="Policy"
                          />
                        )}
                        onChange={policyStatementsAutocompleteOnChangeFunction}
                      />
                    </FormControl>
                  </Box>
                  <Box display="flex" gap={1} alignItems="center">
                    <FormControl sx={{ width: "100%", marginTop: "18px" }}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={policyList}
                        getOptionLabel={
                          procedureStatementAutocompleteGetLabelFunction
                        }
                        defaultValue={formattedCurrentProcedureStatementValues}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label={"Procedure Statements"}
                            placeholder="Procedure"
                          />
                        )}
                        onChange={
                          procedureStatementsAutocompleteOnChangeFunction
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>

                {/* Evidence Input Warning */}
                <Grid item xs={12} md={6}>
                  <MDInput
                    label="Evidence Location"
                    value={
                      evidence_location && evidence_location_type === "text"
                        ? evidence_location
                        : ""
                    }
                    onChange={(e) => {
                      onChangeEvidenceLocationTextInput(e.target.value);
                    }}
                    fullWidth={true}
                    variant={"standard"}
                    disabled={disabled}
                  />
                </Grid>

                {/* Control Owner */}
                <Grid item xs={12} md={6}>
                  <Box display="flex" gap={1} alignItems="center">
                    {max_points && (
                      <InputLabel
                        htmlFor="control_owner_id"
                        style={{ color: "red" }}
                      >
                        *
                      </InputLabel>
                    )}

                    <Autocomplete
                      disableClearable
                      value={control_owner_id ?? ""}
                      options={userList}
                      groupBy={controlOwnerAutocompleteGroupByFunction}
                      getOptionLabel={controlOwnerAutocompleteGetLabelFunction}
                      isOptionEqualToValue={
                        controlOwnerAutocompleteIsEqualToFunction
                      }
                      renderInput={controlOwnerAutocompleteRenderInputFunction}
                      disabled={disabled}
                      onChange={controlOwnerAutocompleteOnChangeFunction}
                      fullWidth={true}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} md={6}>
                  <Box display="flex" gap={1} alignItems="center">
                    <MDInput
                      label="Compliance Score (SPRS Score)"
                      value={sprs_score ?? ""}
                      onChange={(e) =>
                        onChangeStatements(
                          question_id,
                          "sprs_score",
                          e.target.value
                        )
                      }
                      fullWidth={true}
                      variant={"standard"}
                      disabled={disabled}
                      type="number"
                      inputProps={{
                        min: 0,
                        max: 10,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box gap={1} alignItems="center" mt={2}>
                    <Label sx={{ transform: "translateY(2px)" }}>Notes</Label>{" "}
                    Notes
                    <TextareaAutosize
                      label="Notes"
                      minRows={5}
                      style={{
                        width: "100%",
                        padding: "8px",
                        resize: "vertical",
                        border: "2px solid #e7edee",
                        marginTop: "10px",
                        fontFamily: "Roboto, sans-serif",
                      }}
                      value={question_notes ?? ""}
                      onChange={(e) =>
                        onChangeStatements(
                          question_id,
                          "question_notes",
                          e.target.value
                        )
                      }
                      fullWidth={true}
                      variant={"standard"}
                      disabled={disabled}
                    />
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
            <Divider />
          </MDBox>
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default AssessmentQuestion;
