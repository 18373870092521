import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

const connector = "symbol";

export const saveApiKeys = createAsyncThunk(
  "company/saveApiKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/keys?id=${values.company_id}`,
        values.values
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getConnectorApiKeys = createAsyncThunk(
  "company/ getConnectorApiKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/keys`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getConnectorClients = createAsyncThunk(
  "company/getConnectorClients",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/fetch-clients`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveClientMapping = createAsyncThunk(
  "company/saveClientMapping",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/save-client-mapping`,
        {
          existingC: values.existingC,
          selectedConnectorClientToMap: values.selectedConnectorClientToMap,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const removeClientMapping = createAsyncThunk(
  "company/removeClientMapping",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/remove-client-mapping`,
        {
          selectedConnectorClientToRemove: values.cnsClient,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAllClientMappings = createAsyncThunk(
  "company/getAllClientMappings",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/client-mappings/?per_page=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPreviousSyncInformation = createAsyncThunk(
  "company/getPreviousSyncInformation",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/get-previous-sync-information`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveSyncFrequencySettings = createAsyncThunk(
  "company/saveSyncSettings",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/save-sync-settings`,
        {
          selectedSyncOption: values.selectedValue,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getConnectorAssets = createAsyncThunk(
  "company/getConnectorAssets",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/fetch-assets`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const importConnectorAssets = createAsyncThunk(
  "company/importConnectorAssets",
  async (values, thunkApi) => {
    try {
      values = {
        id: values.id,
        totalAssetsFetched: values.totalAssetsFetched,
        assetData: values.modifiedAssetData,
      };
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/import-assets`,
        values
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const removeSymbolKeys = createAsyncThunk(
  "company/removeSymbolKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/keys`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);