import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";

import UsersManagementListCard from "./components/UsersManagementListCard/UsersManagementListCard";
const MSPUsersManagement = (props) => {
  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox>
        <UsersManagementListCard objUser={props.objUser} />
      </MDBox>
    </DashboardLayout>
  );
};

export default MSPUsersManagement;
