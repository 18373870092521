import React, { Fragment } from "react";
const AddNewAudienceFormErrorLi = (props) => {
  return (
    <Fragment>
      {props.value}
      <br />
    </Fragment>
  );
};
export default AddNewAudienceFormErrorLi;
