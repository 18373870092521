import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { fetchGamifycationStatsAssets } from "features/company/companiesActions";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Icon from "@mui/material/Icon";
import "./AssetTable.css";

export default function AssertTable({ objUser }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [objGamifycationStats, setObjGamifycationStats] = useState([]);
  const dispatch = useDispatch();

  const getStats = () => {
    dispatch(fetchGamifycationStatsAssets({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setObjGamifycationStats(originalPromiseResult.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setObjGamifycationStats([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getStats();
  }, []);

  return (
    <Card mb={2}>
      <MDBox py={2} pr={2} pl={2}>
        <MDBox display="flex" px={0} pt={0}>
          <MDBox mt={0}>
            <MDTypography
              sx={{ marginBottom: "0.5rem" }}
              fontWeight="bold"
              variant="h5"
              color="dark"
            >
              Asset Management Scorecard
            </MDTypography>
          </MDBox>
        </MDBox>

        {/* charts */}

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Grid container justifyContent="center" mt={2} mb={3}>
            <Grid className="table-responsive-container" item xs={12} pt={0}>
              <Grid
                className="table-responsive-row header"
                container
                justifyContent="start"
                mt={1}
              >
                <Grid item xs={12} lg={12} xl={12} pt={0}>
                  <Grid
                    container
                    justifyContent="start"
                    columnSpacing={2}
                    rowSpacing={2}
                  >
                    <Grid item xs={12} lg={2}>
                      <MDBox mb={0}>
                        <MDBox mb={0}>
                          <MDTypography
                            className="table-responsive-header"
                            variant="body2"
                            fontWeight="bold"
                          >
                            Asset Type
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} lg={2} pt={0}>
                      <MDBox mb={0}>
                        <MDBox mb={0}>
                          <MDTypography
                            className="table-responsive-header"
                            variant="body2"
                            fontWeight="bold"
                          >
                            Source
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} lg={2} pt={0}>
                      <MDBox mb={0}>
                        <MDBox mb={0}>
                          <MDTypography
                            className="table-responsive-header"
                            variant="body2"
                            fontWeight="bold"
                          >
                            Status
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} lg={2} pt={0}>
                      <MDBox mb={0}>
                        <MDBox mb={0}>
                          <MDTypography
                            className="table-responsive-header"
                            variant="body2"
                            fontWeight="bold"
                          >
                            Assets Imported
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={2} pt={0}>
                      <MDBox mb={0}>
                        <MDBox mb={0}>
                          <MDTypography
                            className="table-responsive-header"
                            variant="body2"
                            fontWeight="bold"
                          >
                            Date Created
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={2} pt={0}>
                      <MDBox mb={0}>
                        <MDBox mb={0}>
                          <MDTypography
                            className="table-responsive-header"
                            variant="body2"
                            fontWeight="bold"
                          >
                            Date Published
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {objGamifycationStats.map((policy) => (
                <Grid
                  className="table-responsive-row"
                  container
                  justifyContent="start"
                  mt={0}
                >
                  <Grid item xs={12} lg={12} xl={12} pt={0}>
                    <Grid
                      container
                      justifyContent="start"
                      columnSpacing={2}
                      rowSpacing={2}
                    >
                      <Grid item xs={12} lg={2}>
                        <MDBox mb={0}>
                          <MDBox mb={0}>
                            <MDTypography
                              className="table-responsive-header"
                              variant="body2"
                              fontWeight="bold"
                            >
                              Asset Type
                            </MDTypography>
                          </MDBox>
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            {policy.assetType ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} lg={2} pt={0}>
                        <MDBox mb={0}>
                          <MDBox mb={0}>
                            <MDTypography
                              className="table-responsive-header"
                              variant="body2"
                              fontWeight="bold"
                            >
                              Source
                            </MDTypography>
                          </MDBox>
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            {policy.source ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} lg={2} pt={0}>
                        <MDBox mb={0}>
                          <MDBox mb={0}>
                            <MDTypography
                              className="table-responsive-header"
                              variant="body2"
                              fontWeight="bold"
                            >
                              Status
                            </MDTypography>
                          </MDBox>
                          <MDTypography
                            variant="body2"
                            color={
                              policy.status == "Mapped" ? "warning" : "error"
                            }
                            fontWeight="regular"
                          >
                            {policy.status == "Created" ||
                            policy.status == "Approved" ||
                            policy.status == "Published" ? (
                              <Link to={`/asset-lists/${policy.policy_id}`}>
                                <MDBox display="flex" alignItems="center">
                                  <MDTypography color="success" mr="5px">
                                    {policy.status}
                                  </MDTypography>
                                  <Icon color="success">link</Icon>
                                </MDBox>
                              </Link>
                            ) : policy.status == "Mapped" ? (
                              <Link
                                to={
                                  policy.source == "ConnectSecure"
                                    ? "/asset-management/connectsecure"
                                    : policy.source == "Nodeware"
                                    ? "/asset-management/nodeware"
                                    : policy.source == "Liongard"
                                    ? "/asset-management/lionguard"
                                    : policy.source == "MS Graph"
                                    ? `/asset-management/msgraph/${policy.assetType}`
                                    : ""
                                }
                              >
                                <MDBox display="flex" alignItems="center">
                                  <MDTypography color="warning" mr="5px">
                                    {policy.status}
                                  </MDTypography>
                                  <Icon color="warning">link</Icon>
                                </MDBox>
                              </Link>
                            ) : policy.status == "Not Started" ? (
                              <Link
                                to={
                                  policy.assetType == "Hardware"
                                    ? "/assets/create-list/hardware"
                                    : policy.assetType == "Software"
                                    ? "/assets/create-list/software"
                                    : policy.assetType == "Data"
                                    ? "/assets/create-list/data"
                                    : policy.assetType == "Human"
                                    ? "/assets/create-list/human"
                                    : policy.assetType == "Network"
                                    ? "/assets/create-list/network"
                                    : policy.assetType == "Facility"
                                    ? "/assets/create-list/facility"
                                    : ""
                                }
                              >
                                <MDBox display="flex" alignItems="center">
                                  <MDTypography color="error" mr="5px">
                                    {policy.status}
                                  </MDTypography>
                                  <Icon color="error">link</Icon>
                                </MDBox>
                              </Link>
                            ) : (
                              ""
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={2} pt={0}>
                        <MDBox mb={0}>
                          <MDBox mb={0}>
                            <MDTypography
                              className="table-responsive-header"
                              variant="body2"
                              fontWeight="bold"
                            >
                              Assets Imported
                            </MDTypography>
                          </MDBox>
                          <MDTypography variant="body2" fontWeight="regular">
                            {policy.assetsImported ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={2} pt={0}>
                        <MDBox mb={0}>
                          <MDBox mb={0}>
                            <MDTypography
                              className="table-responsive-header"
                              variant="body2"
                              fontWeight="bold"
                            >
                              Date Created
                            </MDTypography>
                          </MDBox>
                          <MDTypography variant="body2" fontWeight="regular">
                            {policy.createdAt ?? "-"}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={2} pt={0}>
                        <MDBox mb={0}>
                          <MDBox mb={0}>
                            <MDTypography
                              className="table-responsive-header"
                              variant="body2"
                              fontWeight="bold"
                            >
                              Date Published
                            </MDTypography>
                          </MDBox>
                          <MDTypography variant="body2" fontWeight="regular">
                            {policy.datePublished ?? "-"}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </MDBox>
    </Card>
  );
}
