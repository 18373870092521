function configs(label, dataset, tooltip, palette) {
  return {
    data: {
      labels: [
        "Alignment",
        "Authorization",
        "Adoption",
        "Assessment",
        "Uncompleted",
      ],
      datasets: [
        {
          label: label,
          cutout: "50%",
          backgroundColor: [
            "#0b2e4a",
            "#7a5195",
            "#ef5675",
            "#ffa600",
            "#eeeeee",
          ],
          data: dataset,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
          labels: {
            color: palette?.text?.main,
          },
        },
        tooltip: {
          position: "nearest",
          callbacks: {
            label: (item) => " " + (tooltip[item.dataIndex] || ""),
          },
        },
      },
    },
  };
}

export default configs;
