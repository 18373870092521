import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";

function Pax8ManageBillingDialog({
  open,
  onClose,
}) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Leaving Our Website</DialogTitle>

      <DialogContent>
        <p>You are about to be redirected to Pax8's website to complete your action. Please note that this site operates independently of our company, and we cannot be held responsible for its content, privacy policies, or security practices.</p>
      </DialogContent>

      <DialogActions>
        <MDButton
          onClick={() => {
            window.open('https://app.pax8.com', '_blank');
            onClose();
          }}
          color="success"
          sx={{ padding: "12px 12px" }}
        >
          Proceed
        </MDButton>
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default Pax8ManageBillingDialog;
